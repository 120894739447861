import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ChatService } from '../../../../services/chat.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Message } from '../../../../models/message';
import { FirebaseAuthService } from '../../../../services/firebase-auth.service';
// import { ScrollToBottomDirective } from 'app/services/scroll.directive';
import { UserDataService } from 'app/services/user-data.service';
import { InitialiseService } from 'app/services/initialise.service';
import { Task } from 'app/models/task-model';
import { Department } from 'app/models/enterprise-model';
import { EntServiceService } from 'app/services/ent-service.service';
import { ProjServiceService } from 'app/services/proj-service.service';
import { WriteService } from 'app/services/writes.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
// import { Message } from '../model/message';

@Component({
  selector: 'app-col-chat',
  templateUrl: './col-chat.component.html',
  styleUrls: ['./col-chat.component.css']
})
export class ColChatComponent implements OnInit {

  @Input() height: string;
  @Input() width: string;
  // @ViewChild(ScrollToBottomDirective)
  // scroll: ScrollToBottomDirective;
  chat$: Observable<any>;
  messages: Message[] = [];
  task: Task;
  yut: { name: '', unit: '', rate: '' };
  department: Department;

  constructor(
    private cs: ChatService,
    // public chatService: ChatService,
    public auth: FirebaseAuthService,
    public usd: UserDataService,
    public modalService: NgbModal,
    public is: InitialiseService, public ps: ProjServiceService, public es: EntServiceService, public ws: WriteService ) {
    this.task = is.getTask();
   }

   ngOnInit() {
    const chatId = this.usd?.setContact.chatId;
    console.log(chatId);
    // TODO: first load already existing history
    // TODO: listen on changes
    // this.usd.noMgs.filter(msg => msg.)

    if (chatId) {
      let source;
      if (this.is.toolSet === 'Customers' || this.is.toolSet === 'client') {
        source = this.cs.getHistoryAlt(chatId);
        console.log('if getHistoryAlt', source);
        this.callbuild(source);
      } else {
        source = this.cs.getHistory(chatId);
        console.log('else getHistory', source);
        this.callbuild(source);
      }
    // } else {
    //   this.cs.create(this.usd?.setContact, 'Link')
    }
  }

  callbuild(source) {
    this.chat$ = this.cs.buildChat(source).pipe(
      tap(res => this.integrateNewMessages(res)),
      // tap(() => this.scrollBottom())
    );
    this.chat$.subscribe(dsc => {
      this.cs.chat$ = dsc;
    })
  }

  async source(chatId) {
    return await this.cs.getHistory(chatId);
  }

  async gs() {
    return await this.usd?.setContact.chatId;
  }

  private integrateNewMessages(chat) {
    const newMessages = chat.messages.filter(
      (newMessage: Message) =>
        !this.messages.some((message: Message) =>
          this.isSameMessage(message, newMessage)
        )
    );
    newMessages.forEach(msg => this.messages.push(msg));
    // this.messages.sort((a, b) => (b.createdAt.toISOString()).localeCompare(a.createdAt.toISOString()))
  }

  private isSameMessage(message: Message, newMessage: Message): boolean {
    return (
      message.content === newMessage.content && message.id === newMessage.id && message.createdAt.isSame(newMessage.createdAt)
    );
  }

  trackByCreated(msg) {
    return msg.createdAt;
  }

  private scrollBottom() {
    const element = document.getElementById('chatbody');

    // setTimeout(() => window.scrollTo(0,
    //   element.scrollTop = element.scrollHeight
    // ), 500);
  }

  getRate() {
    console.log(this.yut.unit);
    this.task.name = this.yut.name;
    // this.task.unit = this.yut.unit;
    this.task.rate = this.yut.rate;
  }
}
