import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { OpenBooksService } from './open-books.service';
import { UserDataService } from './user-data.service';
import * as moment from 'moment';
// const { MongoClient } = require('mongodb');
// import { MongoClient } from 'mongodb';
@Injectable({
  providedIn: 'root'
})
export class MongodbService {

  prInv: any[];
  inventory: any[];
  currentInv = null;
  currentIndex = -1;
  project: any;
  client: any;

  constructor(private https: HttpService, public usd: UserDataService) {
    // const url = 'mongodb://localhost:27017';

    // const url = 'mongodb+srv://coloursapp:IM0i4jy9ZjTRZeMh@cluster0.qqbjm.mongodb.net/properties?retryWrites=true&w=majority';
    // this.client = new MongoClient(url);
  }

  main(data: any[]) {
    // console.log(data.length);
    // data?.forEach(element => {
    //   // element.description = element.type.value + ' ' + String(element.productNo.name) + ' of ' + element.parent;
    //   if (!element.description) {
    //     element.description = element.type.value + ' ' + String(element.productNo.name) + ' of ' + element.parent;
    //     // console.log(element.description, element);
    //   } else {
    //     // console.log(element.description);
    //   }
    // });
    // this.prInv = data;
    // this.retrieveInventory();
    // return 'done.';
  }

  retrieveInventory(): void {
    // console.log(this.https.getAll());
    if(this.usd.setProject.id === this.onlyPj()) {
      if (this.inventory && this.inventory?.length > 0) {} else {
        this.https.getAll().subscribe(data => {
          if (Array.isArray(data)) {
            this.inventory = data || [];
            console.log(data.length);
            console.log(JSON.stringify(data));
            // this.syncData();
            this.mergeData();
          } else {
            // console.log(data);
          }
        },
        error => {
        // console.log(error);
        });
      }
    }
  }

  syncData() {
    // console.log(this.inventory.length, this.inventory);
    const approvedInv = this.inventory?.filter(x => x.approved === true);
    // console.log(approvedInv.length);
    approvedInv?.forEach(element => {
      // console.log(element.standNo, element);
      const resp = this.https.miningoSync(element.standNo);
      // console.log(resp);
    });
  }

  invUpdate(inv) {
    this.usd.afs.doc(`Projects/${inv.project.id}/inventory/${inv.id}`).update(inv).then(() => {
      this.usd.afs.doc(`inventory/${inv.id}`).update(inv).then(() => {/* console.log('Inv updated') */}).catch(er => console.log('Inv updated failed', er));
    }).catch(er => console.log(er));
  }

  mergeData() {
    if(this.usd.setProject.id === this.onlyPj()) {
      if (this.inventory?.length > 0) {
        const wrongPj = this.inventory.filter(x => x?.description?.includes('Khayalami Township') || x?.description?.includes('undefined'));
        wrongPj.forEach(x => {/* console.log(x.description);  */this.deleteWrg(x) });
        if (this.prInv?.length > 0) {
          for (let index = 0; index < this.prInv.length; index++) {
            const element = this.prInv[index];
            const invFound = this.inventory.find(x => x.snCode === element.id && Number(x.standNo) === element.productNo.name);
            if (!invFound) {
          // console.log(element.productNo.name, element.description);
              this.saveInventory(element);
            }
            if (invFound && invFound.approved === false && element?.approved.status === true) {
              this.updateInventory(element, invFound.id);
              this.https.miningoSync(element.standNo);
            }
            if (invFound && (!invFound?.status && invFound?.status === '')) {
              // console.log(this.prInv[index]?.SellStatus?.status, invFound.status);
            }
          }
          const resFnd = this.inventory.filter(x => x.status === 'Reserved');
          if (resFnd.length > 0) {
            for (let index = 0; index < resFnd.length; index++) {
              const element = resFnd[index];
              const afsFnd = this.prInv.find(x => x.id === element.snCode && x.productNo.name === Number(element.standNo));
              if (afsFnd && afsFnd.SellStatus.status !== 'Reserved') {
                afsFnd.SellStatus.status = 'Reserved';
                // afsFnd.SellStatus.updatedAt = new Date().toISOString();
                afsFnd.SellStatus.updatedAt = element.updatedAt;
                // console.log(afsFnd);
                // console.log(element);
                this.invUpdate(afsFnd);
              } else if(afsFnd.SellStatus.status === 'Reserved' && (moment().diff(moment(afsFnd.SellStatus.date), 'days') >= 4)) {
                  afsFnd.SellStatus.status = 'Available';
                  element.status = 'Available';
                  // console.log(afsFnd);
                  afsFnd.SellStatus.updatedAt = new Date().toISOString();
                  // console.log(element);
                  // this.stateInvUpdate(element.id, element);
              } else {}
            }
          }
          const soldFnd = this.prInv.filter(x => x.SellStatus?.status === 'Sold');
          if (soldFnd.length > 0) { /* console.log(soldFnd.length); */
            for (let index = 0; index < soldFnd.length; index++) {
              const element = soldFnd[index];
              // const afsFnd = this.inventory.find(x => x.id === element.snCode && x.productNo.name === Number(element.standNo));
              const afsFnd = this.inventory.find(x =>( x.snCode === element.id) && (Number(x.standNo) === element.productNo.name));
              if (afsFnd && afsFnd.status !== 'Sold') {
                afsFnd.status = 'Sold';
                // console.log(afsFnd);
                // console.log(element);
                this.stateInvUpdate(afsFnd.id, afsFnd);
              } // to continue from here 04.04.2023
            }
          }
        } else {
        }

      } else if (this.prInv?.length > 0) {
        for (let index = 0; index < this.prInv.length; index++) {
          const element = this.prInv[index];
          // console.log(element?.approved.status);              
          // element.description = element.type.value + ' ' + String(element.productNo.name) + ' of ' + element.parent;
          // console.log(element.description);
          this.saveInventory(element);
          if (index === this.prInv.length - 1) {
            this.retrieveInventory();
          }
        }
        // this.addData(this.prInv[0]);
      }
    }
  }

  updateMap(item) {
    // console.log(item);
    this.https.miningoSync(item.standNo);
  }

  addData(element: any) {
    throw new Error('Method not implemented.');
  }

  
  onlyPj() {
    return 'itnHEizBd3Yf7IAnRdPm'; // nyeredzi ridge project
    // return '6CvJkB7ylK67BNZgxMbt'; // CAD project
  }

  updateInventory(element, id): void {
    const data = {
      standNo: element.productNo.name, size: element.size.name, unit: element.unit.name, price: element.price || '',
      status: element?.SellStatus?.status || 'Available', snCode: element.id, description: element.description, approved: element?.approved.status
    };
    this.https.update(id, data)
      .subscribe(
        response => {
          console.log(response);
        },
        error => {
          console.log(error);
        });
      this.https.miningoSync(data.standNo);
  }

  stateInvUpdate(id, data){
    this.https.update(id, data).subscribe(
      response => {
        console.log(response);
      },
      error => {
        console.log(error);
      }
    );
    this.https.miningoSync(data.standNo);
  }

  saveInventory(element): void {
    const data = {
      standNo: element.productNo.name, size: element.size.name, unit: element.unit.name, price: element.price || '',
      status: element?.SellStatus?.status || 'Available', snCode: element.id, description: element.description, approved: element?.approved.status
    };

    if (element.project.id === this.usd.setProject.id) {
      // console.log(data);
      this.https.create(data).subscribe(
        response => {
          // console.log(response);
        },
        error => {
          console.log(error);
        });
    }
  }

  deleteWrg(inv) {
    // if (inv.project.id === this.usd.setProject.id) {
    // console.log(inv.description, inv);
    // this.https.delete(inv.id).subscribe(
    //   response => {
    //     console.log(response);
    //   },
    //   error => {
    //     console.log(error);
    //   });
  }

  checkData(element): void {
    const data = {
      standNo: element.productNo.name, size: element.size.name, unit: element.unit.name, price: element.price || '',
      status: element.status || '', snCode: element.id, description: element.description
    };
    const invFind = this.inventory.find(x => x.id === element.id);
    if (invFind) {
      if (!element.standNo !== invFind.standNo) { element.standNo = invFind.standNo }
      if (element.size !== invFind.size) { element.size = invFind.size }
      if (element.unit !== invFind.unit) { element.unit = invFind.unit }
      if (element.price !== invFind.price) { element.price = invFind.price }
      if (element.status !== invFind.status) { element.status = invFind.status }
      if (element.snCode !== invFind.snCode) { element.snCode = invFind.snCode }
      if (element.description !== invFind.description) { element.description = invFind.description }
    }

    // console.log(data);
    function fnx() {

    }
    // this.https.create(data)
    //   .subscribe(
    //     response => {
    //       console.log(response);
    //     },
    //     error => {
    //       console.log(error);
    //     });
  }

  refreshList(): void {
    // this.retrieveTutorials();
    this.retrieveInventory();
    this.currentInv = null;
    this.currentIndex = -1;
  }

  setInventory(tutorial, index): void {
    this.currentInv = tutorial;
    this.currentIndex = index;
  }

  removeAllInventory(): void {
    this.https.deleteAll()
      .subscribe(
        response => {
          console.log(response);
          this.refreshList();
        },
        error => {
          console.log(error);
        });
  }

  searchInventory(inventory, title): void {
    if (title) {
      let word = title; const results = [];
      inventory.forEach(inv => {
        if (inv.description) {
          inv.description = inv.description.toLowerCase();
          if (word !== '' || ' ') {
            word = word.toLowerCase();
            if ((inv.description).includes(word)) {
              inv.description = inv.description.charAt(0).toUpperCase() + inv.description.slice(1);
              results.push(inv);
              // console.log(results);
            }
          }
        }
        return results;
      });
    } else {
      this.refreshList();
    }
  }
}
