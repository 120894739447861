// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

// export const environment = {
//   production: false
// };

export const environment = {
  production: true,
  firebase: {
    apiKey: 'AIzaSyDlVk4yyfvfM8Z19BYDt3Zt_LO_I21td7g',
    authDomain: 'colors-a07e8.firebaseapp.com',
    databaseURL: 'https://colors-a07e8.firebaseio.com',
    projectId: 'colors-a07e8',
    storageBucket: 'colors-a07e8.appspot.com',
    messagingSenderId: '848651061453',
    appId: '1:848651061453:web:22b4198f5eb9609dc48184',
    measurementId: "G-JK53L04S9V",
    discoveryDocs: ['https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest'],
    scope: 'https://www.googleapis.com/auth/calendar',
    clientId: '848651061453-66vlp4g7aim4q85nrvgskovneaglqq19.apps.googleusercontent.com',
  },
  mySql: {
    host: 'mssql-132648-0.cloudclusters.net',
    port: '16808',
    ipaddress: '108.181.197.151',
    database : 'Southland_Corporate_Finance',
    user     : 'vj',
    password : 'Southland1'
  }
};
// apiKey: "AIzaSyDlVk4yyfvfM8Z19BYDt3Zt_LO_I21td7g",
//   authDomain: "colors-a07e8.firebaseapp.com",
//   databaseURL: "https://colors-a07e8.firebaseio.com",
//   projectId: "colors-a07e8",
//   storageBucket: "colors-a07e8.appspot.com",
//   messagingSenderId: "848651061453",
//   appId: "1:848651061453:web:22b4198f5eb9609dc48184",
//   measurementId: "G-JK53L04S9V"