import { Injectable } from '@angular/core';
import * as introJs from 'intro.js/intro.js';
import { InitialiseService } from './initialise.service';
import { UserDataService } from './user-data.service';

@Injectable({
  providedIn: 'root',
})
export class IntrojsService {
  introJS = null;
  constructor(public is: InitialiseService, private usd: UserDataService) {}

  tutotiral(n) {
    switch (n?.type) {
      case 'Personal':
        this.usd.showTools = true;
        console.log(this.usd.showTools);
        this.personalSetup();
        console.log(n);
        break;
      case 'My Contacts':
        this.usd.showTools = true;
        console.log(this.usd.showTools);
        this.myContacts();
        console.log(n);
        break;
      case 'Contact':
        this.usd.showTools = true;
        console.log(this.usd.showTools);
        this.personalContact();
        console.log(n);
        break;
      case 'Task Classification':
        this.usd.showTools = true;
        console.log(this.usd.showTools);
        console.log(n);
        break;
      case 'Enterprise List':
        this.usd.showTools = true;
        console.log(this.usd.showTools);
        this.enterpriseSetup();
        console.log(n);
        break;
      case 'Staff':
        this.usd.showTools = true;
        console.log(this.usd.showTools);
        this.enterpriseStaffSetup();
        console.log(n);
        break;
      case 'Staff Member':
        this.usd.showTools = true;
        console.log(this.usd.showTools);
        this.enterpriseStaffMember();
        console.log(n);
        break;
      case 'Departments':
        this.usd.showTools = true;
        console.log(this.usd.showTools);
        this.entDepartmentSetup();
        console.log(n);
        break;
      case 'Customers':
        this.usd.showTools = true;
        console.log(this.usd.showTools);
        this.enterpriseCustomerSetup();
        console.log(n);
        break;
      case 'Customer':
        this.usd.showTools = true;
        console.log(this.usd.showTools);
        this.enterpriseSpecificCustomer();
        console.log(n);
        break;
      case 'Enterprise':
        this.usd.showTools = true;
        console.log(this.usd.showTools);
        this.viewEnterpriseSetup();
        console.log(n);
        break;
      case 'Projects List':
        console.log(n);
        this.usd.showTools = true;
        console.log(this.usd.showTools);
        this.projectSetup();
        break;
      case 'Project':
        console.log(n);
        this.usd.showTools = true;
        console.log(this.usd.showTools);
        this.viewProjectSetup();
        break;
      case 'Project Companies':
        console.log(n);
        this.usd.showTools = true;
        console.log(this.usd.showTools);
        this.projectCompanySetup();
        break;
      case 'Project Company':
        console.log(n);
        this.usd.showTools = true;
        console.log(this.usd.showTools);
        this.projectCompanyWorkspace();
        break;
      case 'Team':
        console.log(n);
        this.usd.showTools = true;
        console.log(this.usd.showTools);
        this.projectTeamSetup();
        break;
      case 'Team Member':
        console.log(n);
        this.usd.showTools = true;
        console.log(this.usd.showTools);
        this.projectTeamWorkspace();
        break;
      default:
        console.log(n);
        break;
    }
  }

 // Colours Personal Setup
 personalSetup() {
  this.introJS = introJs();
  this.introJS
    .setOptions({
      steps: [

        {
          title: `<div class="col-11" style="color: #FFFFFF">
          <div class="mt-3 mx-auto row">
            <p></p>
            <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/setup.png"></div>
             <div class="col-9" style="line-height: 1.8;"><span> Set Up Your Personal Account</span></div>
             <p></p>
            </div>
          </div>`,
          element: document.querySelector('#personal'),
          intro: `
          <div class="col-9 mx-auto">
            <img src="./assets/tour/personal.png" alt="People"/><br><br>
          </div>`,
          position: 'bottom',
          onchange: () => {},
        },

        {
          title: `<div class="col-11" style="color: #FFFFFF">
         <div class="mt-3 mx-auto row">
           <p></p>
           <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/setup.png"></div>
            <div class="col-9" style="line-height: 1.8;"><span> Set Up Your Personal Account</span></div>
            <p></p>
           </div>
         </div>`,
         element: document.querySelector('#pHome'),
         intro: `
           <br>
           <b>Personal Home Timeline</b><br>
           <span>This is your Personal Space. Tasks you have created or tasks that were assigned to you are in this space.</span>
         `,
         position: 'bottom',
       },
        // step 3
        {
           title: `<div class="col-11" style="color: #FFFFFF">
          <div class="mt-3 mx-auto row">
            <p></p>
            <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/setup.png"></div>
             <div class="col-9" style="line-height: 1.8;"><span> Set Up Your Personal Account</span></div>
             <p></p>
            </div>
          </div>`,
          element: document.querySelector('#pEditProfile'),
          intro: `
            <br>
            <b>Personal Profile</b><br><br>
            <span>Hover on this and Click <b>Edit Profile</b> to edit your personal profile</span>
          `,
          position: 'bottom',
        },
        {
           title: `<div class="col-11" style="color: #FFFFFF">
          <div class="mt-3 mx-auto row">
            <p></p>
            <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/setup.png"></div>
             <div class="col-9" style="line-height: 1.8;"><span> Set Up Your Personal Account</span></div>
             <p></p>
            </div>
          </div>`,
          element: document.querySelector('#pEditProfile'),
          intro: `
            <img class="zoom"  src="./assets/tour/personalprofile.png" alt="People"/>
          `,
          position: 'top',
        },
        // step 3
         // step 7
        {
           title: `<div class="col-11" style="color: #FFFFFF">
          <div class="mt-3 mx-auto row">
            <p></p>
            <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/setup.png"></div>
             <div class="col-9" style="line-height: 1.8;"><span> Set Up Your Personal Account</span></div>
             <p></p>
            </div>
          </div>`,
          element: document.querySelector('#taskClass'),
          intro: `
          <br>
          <b>My Task Classifications</b><br>
          <span>
            Consider all the tasks that generally happen in your life and divide them into broad Task Classifications. Create these as your Task Classifications.
          </span>`,
          position: 'left',
        },
        {
           title: `<div class="col-11" style="color: #FFFFFF">
          <div class="mt-3 mx-auto row">
            <p></p>
            <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/setup.png"></div>
             <div class="col-9" style="line-height: 1.8;"><span> Set Up Your Personal Account</span></div>
             <p></p>
            </div>
          </div>`,
          element: document.querySelector('#taskClass'),
          intro: `
          <br>
          <b>My Task Classifications</b> <br>
          <img class="zoom"  src="./assets/tour/taskclassifications.png" alt="Task Classification"/><br><br>
          `,
          position: 'left',
        },
        {
           title: `<div class="col-11" style="color: #FFFFFF">
          <div class="mt-3 mx-auto row">
            <p></p>
            <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/setup.png"></div>
             <div class="col-9" style="line-height: 1.8;"><span> Set Up Your Personal Account</span></div>
             <p></p>
            </div>
          </div>`,
          element: document.querySelector('#myStandards'),
          intro: `
          <br>
          <b>Standards</b><br><br>
          <span>
           Add your Standard Tasks for example Frequency of Gym visits.
          </span>
          `,
          position: 'left',
        },
        {
           title: `<div class="col-11" style="color: #FFFFFF">
          <div class="mt-3 mx-auto row">
            <p></p>
            <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/setup.png"></div>
             <div class="col-9" style="line-height: 1.8;"><span> Set Up Your Personal Account</span></div>
             <p></p>
            </div>
          </div>`,
          element: document.querySelector('#myStandards'),
          intro: `
          <br>
          <b>Standards</b><br><br>
          <img class="zoom" src="./assets/tour/standard.png" alt="Standards"/><br><br>
          `,
          position: 'left',
        },
        // step 6
        {
           title: `<div class="col-11" style="color: #FFFFFF">
          <div class="mt-3 mx-auto row">
            <p></p>
            <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/setup.png"></div>
             <div class="col-9" style="line-height: 1.8;"><span> Set Up Your Personal Account</span></div>
             <p></p>
            </div>
          </div>`,
          element: document.querySelector('#myfinancials'),
          intro: `
          <br>
          <b>Financials</b><br>
          <span>
            Enter your Personal Projected Financials and from time to time enter your Actual Financials.
          </span>
          `,
          position: 'left',
          onexit: () => {
            // this.usd.showTools = false;
            // console.log(this.usd.showTools);
          },
        },
        {
           title: `<div class="col-11" style="color: #FFFFFF">
          <div class="mt-3 mx-auto row">
            <p></p>
            <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/setup.png"></div>
              <div class="col-9" style="line-height: 1.8;"><span> Set Up Your Personal Account</span></div>
              <p></p>
            </div>
          </div>
          `,
          element: document.querySelector('#myfinancials'),
          intro: `
          <br>
          <b>Financials</b><br>
          <img class="zoom" src="./assets/tour/pfinancials.png" alt="Financials"/><br><br>
          `,
          position: 'left',
        },
        {
           title: `<div class="col-11" style="color: #FFFFFF">
          <div class="mt-3 mx-auto row">
            <p></p>
            <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/goal.png"></div>
             <div class="col-9" style="line-height: 1.8;"><span> Plan Personal Tasks</span></div>
             <p></p>
            </div>
          </div>`,
          element: document.querySelector('#newTask'),
          intro: `
          <br>
          <b>Create a Task</b><br>
          <span>
           New task - click here to create a new task, adding it onto your Personal Home Timeline.
          </span>`,
          position: 'left',
        },
        {
          title: `<div class="col-11" style="color: #FFFFFF">
         <div class="mt-3 mx-auto row">
           <p></p>
           <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/implementation.png"></div>
            <div class="col-9" style="line-height: 1.8;"><span> Implement Personal Tasks</span></div>
            <p></p>
           </div>
         </div>`,
         element: document.querySelector('#taskComments'),
         intro: `
         <br>
         <b>Comment on a Task</b><br>
         <span>
          Click here to comment on your tasks or tasks that you are a participant.<br>
          <img class="zoom" src="./assets/tour/taskComment.png" alt="Task Comment"/>
         </span>`,
         position: 'bottom',
       },

      {
        title: `<div class="col-11" style="color: #FFFFFF">
       <div class="mt-3 mx-auto row">
         <p></p>
         <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/implementation.png"></div>
          <div class="col-9" style="line-height: 1.8;"><span> Implement Personal Tasks</span></div>
          <p></p>
         </div>
       </div>`,
       element: document.querySelector('#taskReallocate'),
       intro: `
       <br>
       <b>Re-allocate a Task</b><br>
       <span>
        Click here to re-allocate tasks to a new champion.<br>
        <img class="zoom" src="./assets/tour/reallocateTask.png" alt="Reallocate Task"/>
       </span>`,
       position: 'bottom',
     },

     {
      title: `<div class="col-11" style="color: #FFFFFF">
     <div class="mt-3 mx-auto row">
       <p></p>
       <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/implementation.png"></div>
        <div class="col-9" style="line-height: 1.8;"><span> Implement Personal Tasks</span></div>
        <p></p>
       </div>
     </div>`,
     element: document.querySelector('#attachFiles'),
     intro: `
     <br>
     <b>Attach File to a Task</b><br>
     <span>
      Click here to attach files to your tasks.<br>
      <img class="zoom" src="./assets/tour/attachFile.png" alt="Attach File"/>
     </span>`,
     position: 'bottom',
    },

    {
      title: `<div class="col-11" style="color: #FFFFFF">
      <div class="mt-3 mx-auto row">
       <p></p>
       <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/implementation.png"></div>
        <div class="col-9" style="line-height: 1.8;"><span> Implement Personal Tasks</span></div>
        <p></p>
       </div>
      </div>`,
     element: document.querySelector('#taskOptions'),
     intro: `
     <b>Edit Task Information</b><br>
     <span>
      Click here and select <b>Edit</b> to update task information.
     </span>
     <img class="zoom" src="./assets/tour/taskEdit.png" alt="Attach File"/><br>
     `,
     position: 'bottom',
    },
    {
      title: `<div class="col-11" style="color: #FFFFFF">
      <div class="mt-3 mx-auto row">
       <p></p>
       <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/implementation.png"></div>
        <div class="col-9" style="line-height: 1.8;"><span> Implement Personal Tasks</span></div>
        <p></p>
       </div>
      </div>`,
     element: document.querySelector('#taskOptions'),
     intro: `
     <br>
     <b>Editing Task information.</b><br>
     <img class="zoom" src="./assets/tour/editTask.png" alt="Edit Task"/>
     `,
     position: 'bottom',
    },

    {
      title: `<div class="col-11" style="color: #FFFFFF">
      <div class="mt-3 mx-auto row">
       <p></p>
       <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/implementation.png"></div>
        <div class="col-9" style="line-height: 1.8;"><span> Implement Personal Tasks</span></div>
        <p></p>
       </div>
      </div>`,
     element: document.querySelector('#taskOptions'),
     intro: `
     <b>Add Participant to a Task</b><br>
     <span>
      Click here and select <b>Participants</b> to add participants to a task.
     </span>
     <img class="zoom" src="./assets/tour/participant.png" alt="Attach File"/><br>
     `,
     position: 'bottom',
    },
    {
      title: `<div class="col-11" style="color: #FFFFFF">
      <div class="mt-3 mx-auto row">
       <p></p>
       <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/implementation.png"></div>
        <div class="col-9" style="line-height: 1.8;"><span> Implement Personal Tasks</span></div>
        <p></p>
       </div>
      </div>`,
     element: document.querySelector('#taskOptions'),
     intro: `
     <br>
     <b>Adding Participants to a task.</b><br>
     <img class="zoom" src="./assets/tour/taskParticipant.png" alt="Task Participant"/>
     `,
     position: 'bottom',
    },

    {
      title: `<div class="col-11" style="color: #FFFFFF">
      <div class="mt-3 mx-auto row">
       <p></p>
       <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/implementation.png"></div>
        <div class="col-9" style="line-height: 1.8;"><span> Implement Personal Tasks</span></div>
        <p></p>
       </div>
      </div>`,
     element: document.querySelector('#taskOptions'),
     intro: `
     <b>Add Equipment to a Task</b><br>
     <span>
      Click here and select <b>Equipment</b> to add equipment to a task.
     </span>
     <img class="zoom" src="./assets/tour/equipment.png" alt="Attach File"/>
     `,
     position: 'bottom',
    },
    {
      title: `<div class="col-11" style="color: #FFFFFF">
      <div class="mt-3 mx-auto row">
       <p></p>
       <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/implementation.png"></div>
        <div class="col-9" style="line-height: 1.8;"><span> Implement Personal Tasks</span></div>
        <p></p>
       </div>
      </div>`,
     element: document.querySelector('#taskOptions'),
     intro: `
     <br>
     <b>Adding Equipment to a task.</b><br>
     <img class="zoom" src="./assets/tour/taskEquipment.png" alt="Task Equipment"/>
     `,
     position: 'bottom',
    },
    {
      title: `<div class="col-11" style="color: #FFFFFF">
      <div class="mt-3 mx-auto row">
       <p></p>
       <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/implementation.png"></div>
        <div class="col-9" style="line-height: 1.8;"><span> Implement Personal Tasks</span></div>
        <p></p>
       </div>
      </div>`,
     element: document.querySelector('#taskOptions'),
     intro: `
     <b>Complete a Task</b><br>
     <span>
      Click here and select <b>Complete</b> to finish a task.
      Please make sure you have entered work hours and work done quantities for that task before completing it.
     </span>
     <img class="zoom" src="./assets/tour/complete.png" alt="Attach File"/>
     `,
     position: 'bottom',
    },
    {
      title: `<div class="col-11" style="color: #FFFFFF">
      <div class="mt-3 mx-auto row">
       <p></p>
       <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/implementation.png"></div>
        <div class="col-9" style="line-height: 1.8;"><span> Implement Personal Tasks</span></div>
        <p></p>
       </div>
      </div>`,
     element: document.querySelector('#taskOptions'),
     intro: `
     <br>
     <b>Completing a task.</b><br>
     <img class="zoom" src="./assets/tour/taskComplete.png" alt="Complete Task"/>
     `,
     position: 'bottom',
    },
    {
      title: `<div class="col-11" style="color: #FFFFFF">
      <div class="mt-3 mx-auto row">
        <p></p>
        <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/implementation.png"></div>
          <div class="col-9" style="line-height: 1.8;"><span>Implement Personal Tasks</span></div>
          <p></p>
        </div>
      </div>`,
      element: document.querySelector('#tickTask'),
      intro: `
      <br>
      <b>Add Task To Your Things To Do List</b><br>
      <span>
        To copy your task to your Things To Do List, tick the checkbox. The task will automatically be added onto your Things To Do List.
      </span>
      `,
      position: 'bottom',
     },
        {
          title: `<div class="col-11" style="color: #FFFFFF">
          <div class="mt-3 mx-auto row">
            <p></p>
            <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/implementation.png"></div>
             <div class="col-9" style="line-height: 1.8;"><span>Implement Personal Tasks</span></div>
             <p></p>
            </div>
          </div>`,
          element: document.querySelector('#myDiary'),
          intro: `
            <br>
            <b>To add task to Work Done and Time Check</b><br>
            <span>
            Click to view the Things To Do List. Once inside, click checkboxes to add a task to your diary.
            This diary will appear on pop ups in the form of the Time Check and Work Done pages.
            </span>`,
          position: 'left',
        },
        {
          title: `
          <div class="col-11" style="color: #FFFFFF">
          <div class="mt-3 mx-auto row">
            <p></p>
            <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/implementation.png"></div>
             <div class="col-9" style="line-height: 1.8;"><span>Implement Personal Tasks</span></div>
             <p></p>
            </div>
          </div>`,
          element: document.querySelector('#myDiary'),
          intro: `
            <br>
              <b>Things to Do List</b><br>
              <img class="zoom" src="./assets/tour/diary.png" alt="Diary"/><br><br>
              <span>
               Click checkboxes to select tasks onto your Time Check and Work Done Pop Up.
              </span>
            <br>
          `,
          position: 'left',
        },
        {
          title: `<div class="col-11" style="color: #FFFFFF">
          <div class="mt-3 mx-auto row">
            <p></p>
            <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/implementation.png"></div>
             <div class="col-9" style="line-height: 1.8;"><span>Implement Personal Tasks</span></div>
             <p></p>
            </div>
          </div>`,
          element: document.querySelector('#myWorkDone'),
          intro: `
          <br>
          <b>Work Done</b><br>
          <span>
            From time to time click this button in order to enter your actual work done quantites.
          </span><br><br>
          `,
          position: 'left',
        },
        {
          title: `<div class="col-11" style="color: #FFFFFF">
            <div class="mt-3 mx-auto row">
              <p></p>
              <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/implementation.png"></div>
              <div class="col-9" style="line-height: 1.8;"><span>Implement Personal Tasks</span></div>
              <p></p>
              </div>
            </div>
          `,
          element: document.querySelector('#myWorkDone'),
          intro: `
            <br>
            <b>Work Done</b><br>
            <span>
              Click on a task that you are working on, to enter your actual work done quantity for that task. In instances were this is a continous task, enter quantity done since previous entry.
            </span>
            <img class="zoom" src="./assets/tour/pworkdone.png" alt="Work Done"/><br><br>
          `,
          position: 'left',
        },
        {
          title: `<div class="col-11" style="color: #FFFFFF">
          <div class="mt-3 mx-auto row">
            <p></p>
            <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/implementation.png"></div>
             <div class="col-9" style="line-height: 1.8;"><span>Implement Personal Tasks</span></div>
             <p></p>
            </div>
          </div>`,
          element: document.querySelector('#myTimeCheck'),
          intro: `
          <br>
          <b>Time Check</b><br><br>
          <span>The Time Check Pop Up will help you automatically create your Time Sheet.
          The Time Check Pop Up carries all the tasks that are on your Diary for the day.
          Click on a task you are working on each time the Pop Up comes up.
          Time spent on a task will thus be automatically computed.
          </span><br>
          `,
          position: 'left',
        },
        {
          title: `<div class="col-11" style="color: #FFFFFF">
          <div class="mt-3 mx-auto row">
            <p></p>
            <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/implementation.png"></div>
             <div class="col-9" style="line-height: 1.8;"><span>Implement Personal Tasks</span></div>
             <p></p>
            </div>
          </div>`,
          element: document.querySelector('#myTimeCheck'),
          intro: `
            <br>
            <b>Time Check</b><br>
            <span>
              Each time this Diary Pop Up comes up every 30 minutes, simply click on the task that you are working on at that time, If you are working on task that is not part of your diary for the day simply click the Unplanned Task Button to enter the new Task
            </span>
            <img class="zoom" src="./assets/tour/ptimecheck.png" alt="Time Check"/><br><br>
          `,
          position: 'left',
        },

        {
          title: `
           <div class="col-11" style="color: #FFFFFF">
             <div class="mt-3 mx-auto row">
               <p></p>
               <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/setup.png"></div>
               <div class="col-9" style="line-height: 1.8;"><span> Set Up Your Personal Account</span></div>
               <p></p>
             </div>
           </div>`,
         element: document.querySelector('#myContact'),
         intro: `
         <br>
        <b>Contacts</b><br><br>
        <span>
         To add contacts, search from Colours or send email invite for persons that are not on Colours.
        </span>
        `,
         position: 'bottom',
       },
        // step 7
        {
          title: `<div class="col-11" style="color: #FFFFFF">
         <div class="mt-3 mx-auto row">
           <p></p>
           <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/setup.png"></div>
            <div class="col-9" style="line-height: 1.8;"><span> Set Up Your Personal Account</span></div>
            <p></p>
           </div>
         </div>`,
         element: document.querySelector('#myContact'),
         intro: `
         <img class="zoom" src="./assets/tour/pcontact.png" alt="People"/>
         `,
         position: 'bottom',
       },

        {
          title: `<div class="col-11" style="color: #FFFFFF">
          <div class="mt-3 mx-auto row">
            <p></p>
            <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/clipboard.png"></div>
             <div class="col-9" style="line-height: 1.8;"><span>Report Personal Activities</span></div>
             <p></p>
            </div>
          </div>`,
          element: document.querySelector('#personalDash'),
          intro: `
            <br>
            <b>Personal Reports</b><br><br>
            <span>
              Click to run and view your personal reports.
            </span>
          `,
          position: 'bottom',
        },

        {
          title: `
          <div class="col-11" style="color: #FFFFFF">
            <div class="mt-3 mx-auto row">
              <p></p>
              <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/clipboard.png"></div>
              <div class="col-9" style="line-height: 1.8;"><span>Report Personal Activities</span></div>
              <p></p>
            </div>
          </div>`,
          element: document.querySelector('#personalDash'),
          intro: `
          <b>Personal Reports</b><br><br>
          <img class="zoom" src="./assets/tour/preport.png" alt="Personal"/><br><br>
          `,
          position: 'bottom',
        },

        {
          title: `<div class="col-11" style="color: #FFFFFF">
          <div class="mt-3 mx-auto row">
            <p></p>
            <br><br>
            <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/setup.png"></div>
             <div class="col-9" style="line-height: 1.8;"><span>Help Menu</span></div>
             <p></p>
            </div>
          </div>`,
          element: document.querySelector('#openHelp'),
          intro: `
          <br>
          <div style="text-align: center">
          <b>Click here to re-run the guided tour.</b>
          </div>
          `,
          position: 'bottom',
        },

        {
          title: `<div class="col-11" style="color: #FFFFFF">
          <div class="mt-3 mx-auto row">
            <p></p>
            <br><br>
            <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/setup.png"></div>
             <div class="col-9" style="line-height: 1.8;"><span></span></div>
             <p></p>
            </div>
          </div>`,
          intro: `
          <h4 style="text-align: center; margin-top: 2em">
            <i class="cls-h4-font">End Of This Guided Tour!</i><br>
          </h4>
          `,
          position: 'bottom',
        },

      ],
    }).start();
}

// contacts page
myContacts() {
  const intros = introJs();
  intros.setOptions({
      showStepNumbers: false,
      showBullets: true,
      showProgress: false,
      steps: [
        {
          title: `<div class="col-11" style="color: #FFFFFF">
            <div class="mt-3 mx-auto row">
              <p></p>
              <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/setup.png"></div>
              <div class="col-9" style="line-height: 1.8;"><span> Set Up Your Personal Account</span></div>
              <p></p>
              </div>
            </div>`,
          element: document.querySelector('#addContact'),
          intro: `
          <br>
          <b>Add Personal Contacts</b><br><br>
          <span>
            Click to search and add your contacts that are on the Colours platform. Otherwise, click the invite button to send your contacts an invitation email to join Colours.
          </span>
         `,
          position: 'bottom',
        },

        {
          title: `<div class="col-11" style="color: #FFFFFF">
          <div class="mt-3 mx-auto row">
            <p></p>
            <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/setup.png"></div>
             <div class="col-9" style="line-height: 1.8;"><span> Set Up Your Personal Account</span></div>
             <p></p>
            </div>
          </div>`,
          element: document.querySelector('#inviteContact'),
          intro: `
          <br>
          <b>Invite Personal Contacts</b><br><br>
          <span>
            Click this button to send an email invitation to add your Contacts onto the Colours platform.
          </span>`,
          position: 'bottom',
        },
        {
          title: `<div class="col-11" style="color: #FFFFFF">
          <div class="mt-3 mx-auto row">
            <p></p>
            <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/setup.png"></div>
             <div class="col-9" style="line-height: 1.8;"><span> Set Up Your Personal Account</span></div>
             <p></p>
            </div>
          </div>`,
          element: document.querySelector('#contact'),
          intro: `
          <br>
          <b>Plan tasks for your Personal Contacts</b><br><br>
          <span>
            Click a name to go into your Contacts Tasks Home page. You can then create and alocate some tasks to this contact.
         </span>`,
          position: 'bottom',
        },
        {
          title: `<div class="col-11" style="color: #FFFFFF">
          <div class="mt-3 mx-auto row">
            <p></p>
            <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/setup.png"></div>
             <div class="col-9" style="line-height: 1.8;"><span> Set Up Your Personal Account</span></div>
             <p></p>
            </div>
          </div>`,
          element: document.querySelector('#openChat'),
          intro: `
          <br>
          <b>Chat with Your Contacts</b><br><br>
          <span>
            Click here to chat with your contacts.
          </span>`,
          position: 'left',
        },
        {
          title: `<div class="col-11" style="color: #FFFFFF">
          <div class="mt-3 mx-auto row">
            <p></p>
            <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/goal.png"></div>
             <div class="col-9" style="line-height: 1.8;"><span>Plan Personal Tasks</span></div>
             <p></p>
            </div>
          </div>`,
          element: document.querySelector('#newTask'),
          intro: `
          <br>
          <b>Create a Task</b><br><br>
          <span>
            Click here to create a new task for any one of your contacts. You can also create a task where you are the task champion.
          </span>`,
          position: 'left',
        },
        {
          title: `<div class="col-11" style="color: #FFFFFF">
          <div class="mt-3 mx-auto row">
            <p></p>
            <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/setup.png"></div>
             <div class="col-9" style="line-height: 1.8;"><span>Help Menu</span></div>
             <p></p>
            </div>
          </div>`,
          element: document.querySelector('#openHelp'),
          intro: `
          <br>
          <div style="text-align: center">
           <b>Click here to re-run the guided tour.</b>
          </div>
          `,
          position: 'bottom',
        },

        {
          title: `<div class="col-11" style="color: #FFFFFF">
          <div class="mt-3 mx-auto row">
            <p></p>
            <br><br>
            <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/setup.png"></div>
             <div class="col-9" style="line-height: 1.8;"><span></span></div>
             <p></p>
            </div>
          </div>`,
          intro: `
          <h4 style="text-align: center; margin-top: 2em">
            <i class="cls-h4-font">End Of This Guided Tour!</i><br>
          </h4>
          `,
          position: 'bottom',
        },

      ],
    }).start();

}

// specific contact
personalContact() {
  const intros = introJs();
  intros.setOptions({
      showStepNumbers: false,
      showBullets: true,
      showProgress: false,
      steps: [
        {
          title: `
            <div class="col-11" style="color: #FFFFFF">
              <div class="mt-3 mx-auto row">
                <p></p>
                <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/goal.png"></div>
                <div class="col-9" style="line-height: 1.8;"><span>Plan Personal Tasks</span></span></div>
                <p></p>
              </div>
            </div>`,
          element: document.querySelector('#contactHome'),
          intro: `
          <br>
          <b>Contact's Home Timeline</b><br>
          <span>
            Click here to view tasks that were created for you by your contact and or tasks you have assigned to your contact.
          </span>`,
          position: 'bottom',
        },
        {
          title: `
            <div class="col-11" style="color: #FFFFFF">
              <div class="mt-3 mx-auto row">
                <p></p>
                <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/goal.png"></div>
                <div class="col-9" style="line-height: 1.8;"><span>Plan Personal Tasks</span></span></div>
                <p></p>
              </div>
            </div>`,
          element: document.querySelector('#newTask'),
          intro: `
          <br>
          <b>Create a Task</b><br><br>
          <span>
            Click here to create a task for your Contact.
          </span>`,
          position: 'left',
        },
        {
          title: `
            <div class="col-11" style="color: #FFFFFF">
              <div class="mt-3 mx-auto row">
                <p></p>
                <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/goal.png"></div>
                <div class="col-9" style="line-height: 1.8;"><span>Plan Personal Tasks</span></span></div>
                <p></p>
              </div>
            </div>`,
          element: document.querySelector('#chat'),
          intro: `<br>
          <b>Chat with Your Contact</b><br><br>
          <span>
            Click to chat and also plan new tasks for this contact or yourself from the chat page.
          </span>`,
          position: 'left',
        },
        {
          title: `
            <div class="col-11" style="color: #FFFFFF">
              <div class="mt-3 mx-auto row">
                <p></p>
                <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/clipboard.png"></div>
                <div class="col-9" style="line-height: 1.8;"><span>Report Personal Activities</span></span></div>
                <p></p>
              </div>
            </div>`,
          element: document.querySelector('#contactDashboard'),
          intro: `
            <br>
            <b>View Your Contact's Personal Reports</b><br><br>
            <img class="zoom" src="./assets/tour/contactdash.png" alt="Report"/><br><br>
          `,
          position: 'left',
        },
        {
          title: `
            <div class="col-11" style="color: #FFFFFF">
              <div class="mt-3 mx-auto row">
                <p></p><br>
                <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/goal.png"></div>
                <div class="col-9" style="line-height: 1.8;"><span>Plan Personal Tasks</span></span></div>
                <p></p>
              </div>
            </div>`,
          element: document.querySelector('#openHelp'),
          intro: `
          <br>
          <div style="text-align: center">
           <b>Click here to re-run the guided tour.</b>
          </div>
          `,
          position: 'bottom',
        },
        {
          title: `<div class="col-11" style="color: #FFFFFF">
          <div class="mt-3 mx-auto row">
            <p></p>
            <br><br>
            <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/setup.png"></div>
             <div class="col-9" style="line-height: 1.8;"><span></span></div>
             <p></p>
            </div>
          </div>`,
          intro: `
          <h4 style="text-align: center; margin-top: 2em">
            <i class="cls-h4-font">End Of This Guided Tour!</i><br>
          </h4>
          `,
          position: 'bottom',
        },
      ],
    }).start();

}

 // main enterprises page setup
 enterpriseSetup() {
  this.introJS = introJs();
  this.introJS
    .setOptions({
      showStepNumbers: false,
      showBullets: true,
      showProgress: false,
      steps: [
        {
           title: `
            <div class="col-11" style="color: #FFFFFF">
              <div class="mt-3 mx-auto row">
                <p></p>
                <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/setup.png"></div>
                <div class="col-9" style="line-height: 1.8;"><span>Set Up Your Enterprise Account</span></div>
                <p></p>
              </div>
            </div>`,
          element: document.querySelector('#myEnterprise'),
          intro: `
          <div class="col-9 mx-auto">
            <img src="./assets/img/tour/enterprise.png" alt="People"/><br><br>
          </div>
          <span>You can create as many Enterprise Accounts as you need, this means as many companies as you want.</span>
        `,
          position: 'bottom',
        },

        // step 3
        {
          title: `
            <div class="col-11" style="color: #FFFFFF">
              <div class="mt-3 mx-auto row">
                <p></p>
                <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/setup.png"></div>
                <div class="col-9" style="line-height: 1.8;"><span> Set Up Your Enterprise Account</span></div>
                <p></p>
              </div>
            </div>`,
          element: document.querySelector('#entCreate'),
          intro: `
            <br>
            <b>Create a Company</b><br>
            <span>
              Once created this company will appear on your list of Enterprises below this button.
            </span>
          `,
        },
          // step 3
          {
            title: `
              <div class="col-11" style="color: #FFFFFF">
                <div class="mt-3 mx-auto row">
                  <p></p>
                  <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/setup.png"></div>
                  <div class="col-9" style="line-height: 1.8;"><span> Set Up Your Enterprise Account</span></div>
                  <p></p>
                </div>
              </div>
            `,
            element: document.querySelector('#entJoin'),
            intro: `
              <br>
              <b>Join a company</b><br><br>
              <span>
                This button allows you to join companies that have already been created by others on Colours platform.
              </span><br>
              <span>
                Once you have joined, that company will appear on your enterprise list.
              </span>
            `,
          },
        // step 4
        {
          title: `
          <div class="col-11" style="color: #FFFFFF">
            <div class="mt-3 mx-auto row">
              <p></p>
              <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/setup.png"></div>
              <div class="col-9" style="line-height: 1.8;"><span> Set Up Your Enterprise Account</span></div>
              <p></p>
            </div>
          </div>`,
          element: document.querySelector('#viewEnt'),
          intro: `
            <br>
            <b>Enter into Company's Workspace</b><br><br>
            <span>Click to get into the company you have created or joined.</span>
          `,
          position: 'bottom',
        },
         // step 4
        {
          title: `
          <div class="col-11" style="color: #FFFFFF">
            <div class="mt-3 mx-auto row">
              <p></p>
              <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/setup.png"></div>
              <div class="col-9" style="line-height: 1.8;"><span> Set Up Your Enterprise Account</span></div>
              <p></p>
            </div>
          </div>`,
          element: document.querySelector('#editEntInfo'),
          intro: `
            <br>
            <b>Edit Company profile</b><br><br>
            <span>Click here to edit or update this company's information.</span>
          `,
          position: 'bottom',
        },
         // step 4
         {
          title: `
            <div class="col-11" style="color: #FFFFFF">
              <div class="mt-3 mx-auto row">
                <p></p>
                <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/setup.png"></div>
                <div class="col-9" style="line-height: 1.8;"><span> Set Up Your Enterprise Account</span></div>
                <p></p>
              </div>
            </div>
          `,
          element: document.querySelector('#exitCompany'),
          intro: `
            <br>
            <b>Exit from a Company</b><br><br>
            <span>Click here to exit from this company.</span>
          `,
          position: 'bottom',
        },

        {
          title: `
          <div class="col-11" style="color: #FFFFFF">
            <div class="mt-3 mx-auto row">
              <p></p>
              <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/setup.png"></div>
              <div class="col-9" style="line-height: 1.8;"><span>Help Menu</span></div>
              <p></p>
            </div>
          </div>`,
          element: document.querySelector('#openHelp'),
          intro: `
          <br>
          <div style="text-align: center">
          <b>Click here to re-run the guided tour.</b>
          </div>

          `,
          position: 'bottom',
        },

        {
          title: `<div class="col-11" style="color: #FFFFFF">
          <div class="mt-3 mx-auto row">
            <p></p>
            <br><br>
            <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/setup.png"></div>
             <div class="col-9" style="line-height: 1.8;"><span></span></div>
             <p></p>
            </div>
          </div>`,
          intro: `
          <h4 style="text-align: center; margin-top: 2em">
            <i class="cls-h4-font">End Of This Guided Tour!</i><br>
          </h4>
          `,
          position: 'bottom',
        },

      ],
    }).start();
}

// Enterprise setup for a specific enterprise
viewEnterpriseSetup() {
  this.introJS = introJs();
  this.introJS.setOptions({
      showStepNumbers: false,
      showBullets: true,
      showProgress: false,
      steps: [

        {
          title: `
           <div class="col-11" style="color: #FFFFFF">
             <div class="mt-3 mx-auto row">
               <p></p>
               <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/setup.png"></div>
               <div class="col-9" style="line-height: 1.8;"><span>Set Up Your Enterprise Account</span></div>
               <p></p>
             </div>
           </div>`,
         element: document.querySelector('#entHome'),
         intro: `
         <b>Enterprise Timeline</b><br>
         <span>
          All Enterprise tasks you have created or that were created for you are listed in this space.
          This is where you can plan, implement and report your enterprise activities.
         </span>
        `,
         position: 'bottom',
       },

        {
          title: `
          <div class="col-11" style="color: #FFFFFF">
            <div class="mt-3 mx-auto row">
              <p></p>
              <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/setup.png"></div>
              <div class="col-9" style="line-height: 1.8;"><span> Set Up Your Enterprise Account</span></div>
              <p></p>
            </div>
          </div>`,
          element: document.querySelector('#editEntProfile'),
          intro: `
          <br>
          <b>Edit Company profile </b><br><br>
          <span>Click here to edit or update this company's profile.
          </span>
          `,
          position: 'bottom',
        },
        {
          title: `
          <div class="col-11" style="color: #FFFFFF">
            <div class="mt-3 mx-auto row">
              <p></p>
              <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/setup.png"></div>
              <div class="col-9" style="line-height: 1.8;"><span> Set Up Your Enterprise Account</span></div>
              <p></p>
            </div>
          </div>`,
          element: document.querySelector('#aboutEntInfo'),
          intro: `
            <br>
            <b>About Company</b><br><br>
            <span>Click to view the company's summary profile.</span>
          `,
          position: 'left',
        },
        // step 7
        {
          title: `
          <div class="col-11" style="color: #FFFFFF">
            <div class="mt-3 mx-auto row">
              <p></p>
              <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/setup.png"></div>
              <div class="col-9" style="line-height: 1.8;"><span> Set Up Your Enterprise Account</span></div>
              <p></p>
            </div>
          </div>`,
          element: document.querySelector('#entdepartments'),
          intro: `
          <br>
          <b>Company Departments</b><br><br>
          <span>Click create or view company departments.</span><br>
          <img class="zoom" src="./assets/tour/entdepartment.png"/>
          `,
          position: 'left',
        },
        // step 8
        {
          title: `
          <div class="col-11" style="color: #FFFFFF">
            <div class="mt-3 mx-auto row">
              <p></p>
              <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/setup.png"></div>
              <div class="col-9" style="line-height: 1.8;"><span> Set Up Your Enterprise Account</span></div>
              <p></p>
            </div>
          </div>`,
          element: document.querySelector('#entProjects'),
          intro: `
            <br>
            <b>Projects</b><br>
            <span>Click to create or join or view the projects in the company's projects register.</span>
          `,
          position: 'left',
        },
        // step 9
        {
          title: `
          <div class="col-11" style="color: #FFFFFF">
            <div class="mt-3 mx-auto row">
              <p></p>
              <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/setup.png"></div>
              <div class="col-9" style="line-height: 1.8;"><span> Set Up Your Enterprise Account</span></div>
              <p></p>
            </div>
          </div>`,
          element: document.querySelector('#entAssets'),
          intro: `
          <br>
          <b>Asset Register</b><br><br>
          <span>
           Click to create the company's Asset Register or to add new assets into the register.
          </span>
          `,
          position: 'left',
        },
        {
          title: `
          <div class="col-11" style="color: #FFFFFF">
            <div class="mt-3 mx-auto row">
              <p></p>
              <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/setup.png"></div>
              <div class="col-9" style="line-height: 1.8;"><span> Set Up Your Enterprise Account</span></div>
              <p></p>
            </div>
          </div>`,
          element: document.querySelector('#entAssets'),
          intro: `
          <br>
          <b>Asset Register</b><br><br>
          <img class="zoom" src="./assets/tour/entasset.png" />
          `,
          position: 'left',
        },
        // step 10
        {
          title: `
          <div class="col-11" style="color: #FFFFFF">
            <div class="mt-3 mx-auto row">
              <p></p>
              <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/setup.png"></div>
              <div class="col-9" style="line-height: 1.8;"><span> Set Up Your Enterprise Account</span></div>
              <p></p>
            </div>
          </div>`,
          element: document.querySelector('#entStandards'),
          intro: `
            <br>
            <b>Enterprise Standards</b> <br>
            <span>
              Click to create a list of the company's typical activities or Type of Tasks that the company does very often. These are type of tasks that are repeated regularly in the company e.g Reports, Drawings, Meetings, Concrete, Plastering, e.t.c
            </span>
          `,
          position: 'left',
        },
        {
          title: `
          <div class="col-11" style="color: #FFFFFF">
            <div class="mt-3 mx-auto row">
              <p></p>
              <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/setup.png"></div>
              <div class="col-9" style="line-height: 1.8;"><span> Set Up Your Enterprise Account</span></div>
              <p></p>
            </div>
          </div>`,
          element: document.querySelector('#entStandards'),
          intro: `
          <br>
          <b>Enterprise Standards</b> <br>
          <img class="zoom" src="./assets/tour/entstandards.png" />
          `,
          position: 'left',
        },
        // step 11
        {
          title: `
          <div class="col-11" style="color: #FFFFFF">
            <div class="mt-3 mx-auto row">
              <p></p>
              <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/setup.png"></div>
              <div class="col-9" style="line-height: 1.8;"><span> Set Up Your Enterprise Account</span></div>
              <p></p>
            </div>
          </div>`,
          element: document.querySelector('#entFinancials'),
          intro: `
          <br>
          <b>Enterprise Financials</b> <br><br>
          <span>
            Click to enter a company's Projected Financials and from time to time enter the Actual Financial Reports. These would be produced using a different system and then entered into a company's Colours Account.
          </span>
          `,
          position: 'left',
        },

        {
          title: `
          <div class="col-11" style="color: #FFFFFF">
            <div class="mt-3 mx-auto row">
              <p></p>
              <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/setup.png"></div>
              <div class="col-9" style="line-height: 1.8;"><span> Set Up Your Enterprise Account</span></div>
              <p></p>
            </div>
          </div>`,
          element: document.querySelector('#entFinancials'),
          intro: `
          <b>Enterprise Financials</b> <br>
          <img class="zoom" src="./assets/tour/entfinancials.png" />
          `,
          position: 'left',
        },

        {
          title: `
          <div class="col-11" style="color: #FFFFFF">
            <div class="mt-3 mx-auto row">
              <p></p>
              <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/goal.png"></div>
              <div class="col-9" style="line-height: 1.8;"><span>Plan Enterprise Tasks</span></div>
              <p></p>
            </div>
          </div>`,
          element: document.querySelector('#newTask'),
          intro: `
          <br>
          <b>Create a Task</b><br><br>
          <span>
           Click here to create a new enterprise task. <br>
           This task can be allocated to any staff from that company.
          </span>`,
          position: 'left',
        },
         // step 5
         {
          title: `
          <div class="col-11" style="color: #FFFFFF">
            <div class="mt-3 mx-auto row">
              <p></p>
              <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/setup.png"></div>
              <div class="col-9" style="line-height: 1.8;"><span> Set Up Your Enterprise Account</span></div>
              <p></p>
            </div>
          </div>`,
          element: document.querySelector('#entStaff'),
          intro: `
          <br>
          <b>Staff</b><br><br>
          <span>
            Click here to enter into the Staff Register Page. Once inside you can Add or Invite new Staff members into the company.
          </span>
          `,
          position: 'bottom',
        },
        // step 6
        {
          title: `
          <div class="col-11" style="color: #FFFFFF">
            <div class="mt-3 mx-auto row">
              <p></p>
              <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/setup.png"></div>
              <div class="col-9" style="line-height: 1.8;"><span> Set Up Your Enterprise Account</span></div>
              <p></p>
            </div>
          </div>`,
          element: document.querySelector('#entCustomer'),
          intro: `
          <br>
          <b>Enterprise Customers</b><br><br>
          <span>
            Click here to enter into the Company's Customers Register Page. Once inside you can Add or Invite more Customers into your company Register.
          </span>
          `,
          position: 'bottom',
        },

        {
          title: `
          <div class="col-11" style="color: #FFFFFF">
            <div class="mt-3 mx-auto row">
              <p></p>
              <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/clipboard.png"></div>
              <div class="col-9" style="line-height: 1.8;"><span>Report Enterprise Activities</span></div>
              <p></p>
            </div>
          </div>`,
          element: document.querySelector('#entDashboard'),
          intro: `
          <br>
          <b>Enterprise Reports</b><br><br>
          <span>
           Click to run or view your Enterprise Reports.
         </span>
         <img class="zoom" src="./assets/tour/entdash.png" />`,
          position: 'bottom',
        },
        {
          title: `
          <div class="col-11" style="color: #FFFFFF">
            <div class="mt-3 mx-auto row">
              <p></p>
              <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/setup.png"></div>
              <div class="col-9" style="line-height: 1.8;"><span>Help Menu</span></div>
              <p></p>
            </div>
          </div>`,
          element: document.querySelector('#openHelp'),
          intro: `
          <br>
          <div style="text-align: center">
           <b>Click here to re-run the guided tour.</b>
          </div>

          `,
          position: 'bottom',
        },

        {
          title: `<div class="col-11" style="color: #FFFFFF">
          <div class="mt-3 mx-auto row">
            <p></p>
            <br><br>
            <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/setup.png"></div>
             <div class="col-9" style="line-height: 1.8;"><span></span></div>
             <p></p>
            </div>
          </div>`,
          intro: `
          <h4 style="text-align: center; margin-top: 2em">
            <i class="cls-h4-font">End Of This Guided Tour!</i><br>
          </h4>
          `,
          position: 'bottom',
        },
      ],
    }).start();
}

// Enterprise setup for a specific enterprise
entDepartmentSetup() {
  this.introJS = introJs();
  this.introJS.setOptions({
      showStepNumbers: false,
      showBullets: true,
      showProgress: false,
      steps: [
        {
          title: `
          <div class="col-11" style="color: #FFFFFF">
            <div class="mt-3 mx-auto row">
              <p></p>
              <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/setup.png"></div>
              <div class="col-9" style="line-height: 1.8;"><span> Set Up Your Enterprise Account</span></div>
              <p></p>
            </div>
          </div>`,
          element: document.querySelector('#entdepartments'),
          intro: `
          <br>
          <b>Departments</b><br>
          <span>
          Click to create or view Company departments.
          </span>
          `,
          position: 'left',
        },
        // step 8
        {
          title: `
          <div class="col-11" style="color: #FFFFFF">
            <div class="mt-3 mx-auto row">
              <p></p>
              <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/setup.png"></div>
              <div class="col-9" style="line-height: 1.8;"><span> Set Up Your Enterprise Account</span></div>
              <p></p>
            </div>
          </div>`,
          element: document.querySelector('#addEntDept'),
          intro: `
          <br>
          <b>Add Department</b><br>
          <span>Click here to create a new department for the company.
          </span>
          `,
          position: 'left',
        },
        {
          title: `
          <div class="col-11" style="color: #FFFFFF">
            <div class="mt-3 mx-auto row">
              <p></p>
              <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/setup.png"></div>
              <div class="col-9" style="line-height: 1.8;"><span> Set Up Your Enterprise Account</span></div>
              <p></p>
            </div>
          </div>`,
          element: document.querySelector('#editEntDept'),
          intro: `
          <br>
          <b>Edit a department account</b><br><br>
          <span>
          Click here to edit or update this department's information.
         </span>
          `,
          position: 'left',
        },
        // step 11
        {
          title: `
          <div class="col-11" style="color: #FFFFFF">
            <div class="mt-3 mx-auto row">
              <p></p>
              <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/setup.png"></div>
              <div class="col-9" style="line-height: 1.8;"><span> Set Up Your Enterprise Account</span></div>
              <p></p>
            </div>
          </div>`,
          element: document.querySelector('#deleteEntDept'),
          intro: `
          <br>
          <b>Remove a department</b><br>
          <span>
           Click here to remove this department from the company's account.
          </span>
          `,
          position: 'left',
        },
        {
          title: `
          <div class="col-11" style="color: #FFFFFF">
            <div class="mt-3 mx-auto row">
              <p></p>
              <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/goal.png"></div>
              <div class="col-9" style="line-height: 1.8;"><span>Plan Enterprise Tasks</span></div>
              <p></p>
            </div>
          </div>`,
          element: document.querySelector('#viewEntDept'),
          intro: `
          <br>
          <b>Create a Task</b><br><br>
          <span>
            Plan Pepartmental Tasks. Click on the name of a department to get into the department's workspace.
          </span>
          `,
          position: 'left',
        },

        {
          title: `
          <div class="col-11" style="color: #FFFFFF">
            <div class="mt-3 mx-auto row">
              <p></p>
              <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/goal.png"></div>
              <div class="col-9" style="line-height: 1.8;"><span>Plan Enterprise Tasks</span></div>
              <p></p>
            </div>
          </div>`,
          element: document.querySelector('#newTask'),
          intro: `
          <br>
          <b>Create a Task</b><br><br>
          <span>
            Click here to create a new task for any one of your company's departments.
          </span>`,
          position: 'left',
        },
        {
          title: `
          <div class="col-11" style="color: #FFFFFF">
            <div class="mt-3 mx-auto row">
              <p></p>
              <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/setup.png"></div>
              <div class="col-9" style="line-height: 1.8;"><span> Help Menu</span></div>
              <p></p>
            </div>
          </div>`,
          element: document.querySelector('#openHelp'),
          intro: `
          <br>
          <div style="text-align: center">
          <b>Click here to re-run the guided tour.</b>
          </div>

          `,
          position: 'bottom',
        },

        {
          title: `<div class="col-11" style="color: #FFFFFF">
          <div class="mt-3 mx-auto row">
            <p></p>
            <br><br>
            <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/setup.png"></div>
             <div class="col-9" style="line-height: 1.8;"><span></span></div>
             <p></p>
            </div>
          </div>`,
          intro: `
          <h4 style="text-align: center; margin-top: 2em">
            <i class="cls-h4-font">End Of This Guided Tour!</i><br>
          </h4>
          `,
          position: 'bottom',
        },
      ],
    }).start();
}

// Ent Staff Members
enterpriseStaffSetup() {
  this.introJS = introJs();
  this.introJS
    .setOptions({
      showStepNumbers: false,
      showBullets: true,
      showProgress: false,
      steps: [
        // step 3
        {
          title: `
          <div class="col-11" style="color: #FFFFFF">
            <div class="mt-3 mx-auto row">
              <p></p>
              <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/setup.png"></div>
              <div class="col-9" style="line-height: 1.8;"><span>Setup Your Enterprise Account</span></div>
              <p></p>
            </div>
          </div>`,
          element: document.querySelector('#addEntStaff'),
          intro: `
          <br>
          <b>Add a Staff Member</b><br><br>
          <span>To add staff members to your company, search from Colours and select the staff member you want to add to the company.</span>
          `,
        },
          // step 3
          {
            title: `
            <div class="col-11" style="color: #FFFFFF">
              <div class="mt-3 mx-auto row">
                <p></p>
                <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/setup.png"></div>
                <div class="col-9" style="line-height: 1.8;"><span>Setup Your Enterprise Account</span></div>
                <p></p>
              </div>
            </div>`,
            element: document.querySelector('#inviteEntStaff'),
            intro: `
            <br>
            <b>Invite a Staff Member</b> <br><br>
            <span>
            Click this button to send an email invitation to add your staff members onto the Colours platform.
            Once they are on Colours, you can then add them to your company.
            </span>
            `,
          },
          {
            title: `
            <div class="col-11" style="color: #FFFFFF">
              <div class="mt-3 mx-auto row">
                <p></p>
                <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/setup.png"></div>
                <div class="col-9" style="line-height: 1.8;"><span>Setup Your Enterprise Account</span></div>
                <p></p>
              </div>
            </div>`,
            element: document.querySelector('#editEntStaff'),
            intro: `
            <br>
            <b>Edit Staff Member's information</b><br><br>
            <span>Click here to edit or update a staff member's information.
            </span>
            `,
            position: 'bottom',
          },
           // step 4
          {
            title: `
            <div class="col-11" style="color: #FFFFFF">
              <div class="mt-3 mx-auto row">
                <p></p>
                <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/setup.png"></div>
                <div class="col-9" style="line-height: 1.8;"><span>Setup Your Enterprise Account</span></div>
                <p></p>
              </div>
            </div>`,
            element: document.querySelector('#removeEntStaff'),
            intro: `
            <br>
            <b>Remove a Staff Member</b><br><br>
            <span>Click here to remove a staff member from the company.
            </span>
            `,
            position: 'bottom',
          },
          {
            title: `
            <div class="col-11" style="color: #FFFFFF">
              <div class="mt-3 mx-auto row">
                <p></p>
                <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/goal.png"></div>
                <div class="col-9" style="line-height: 1.8;"><span>Plan Enterprise Tasks</span></div>
                <p></p>
              </div>
            </div>`,
            element: document.querySelector('#messageEntStaff'),
            intro: `
            <br>
            <b>Chat with Your Staff Members</b><br><br>
            <span>Click to chat and plan new tasks for this staff member or yourself from the chat page.
            </span>
            `,
            position: 'bottom',
          },

        {
          title: `
            <div class="col-11" style="color: #FFFFFF">
              <div class="mt-3 mx-auto row">
                <p></p>
                <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/goal.png"></div>
                <div class="col-9" style="line-height: 1.8;"><span>Plan Enterprise Tasks</span></div>
                <p></p>
              </div>
            </div>`,
          element: document.querySelector('#viewEntStaff'),
          intro: `
          <br>
          <b>View Staff Member's workspace</b><br><br>
          <span>Click to get into this staff member's workspace.
          </span>
          `,
          position: 'bottom',
        },

        {
          title: `
            <div class="col-11" style="color: #FFFFFF">
              <div class="mt-3 mx-auto row">
                <p></p>
                <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/goal.png"></div>
                <div class="col-9" style="line-height: 1.8;"><span>Plan Enterprise Tasks</span></div>
                <p></p>
              </div>
            </div>`,
          element: document.querySelector('#newTask'),
          intro: `
          <b>Create a Task</b><br><br>
          <span>Click here to create a task for any one of your staff members from any company department.
          </span>
          `,
          position: 'bottom',
        },

        {
          title: `
          <div class="col-11" style="color: #FFFFFF">
            <div class="mt-3 mx-auto row">
              <p></p>
              <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/goal.png"></div>
              <div class="col-9" style="line-height: 1.8;"><span>Help Menu</span></div>
              <p></p>
            </div>
          </div>`,
          element: document.querySelector('#openHelp'),
          intro: `
          <br>
          <div style="text-align: center">
          <b>Click here to re-run the guided tour.</b>
          </div>

          `,
          position: 'bottom',
        },

        {
          title: `<div class="col-11" style="color: #FFFFFF">
          <div class="mt-3 mx-auto row">
            <p></p>
            <br><br>
            <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/setup.png"></div>
             <div class="col-9" style="line-height: 1.8;"><span></span></div>
             <p></p>
            </div>
          </div>`,
          intro: `
          <h4 style="text-align: center; margin-top: 2em">
            <i class="cls-h4-font">End Of This Guided Tour!</i><br>
          </h4>
          `,
          position: 'bottom',
        },

      ],
    }).start();
}

 // Selected Ent Staff Member
 enterpriseStaffMember() {
  this.introJS = introJs();
  this.introJS
    .setOptions({
      showStepNumbers: false,
      showBullets: true,
      showProgress: false,
      steps: [

        {
          title: `
           <div class="col-11" style="color: #FFFFFF">
             <div class="mt-3 mx-auto row">
               <p></p>
               <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/setup.png"></div>
               <div class="col-9" style="line-height: 1.8;"><span>Set Up Your Enterprise Account</span></div>
               <p></p>
             </div>
           </div>`,
         element: document.querySelector('#staffHome'),
         intro: `
         <b>Enterprise Staff Timeline</b><br>
         <span>
          All Enterprise tasks you have created for a staff member or that were created for you by another staff member are listed in this space.
          This is where you can plan tasks for this staff member and view enterprise reports of a staff member.
         </span>
        `,
         position: 'bottom',
       },

        {
          title: `
            <div class="col-11" style="color: #FFFFFF">
              <div class="mt-3 mx-auto row">
                <p></p>
                <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/goal.png"></div>
                <div class="col-9" style="line-height: 1.8;"><span>Plan Enterprise Tasks</span></div>
                <p></p>
              </div>
            </div>`,
          element: document.querySelector('#newTask'),
          intro: `
          <b>Create a Task</b><br><br>
          <span>Click here to create a new enterprise task.
         </span>
          `,
          position: 'bottom',
        },

        {
          title: `
            <div class="col-11" style="color: #FFFFFF">
              <div class="mt-3 mx-auto row">
                <p></p>
                <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/clipboard.png"></div>
                <div class="col-9" style="line-height: 1.8;"><span>Report Enterprise Activities</span></div>
                <p></p>
              </div>
            </div>`,
          element: document.querySelector('#entStaffDash'),
          intro: `
          <b>Click here to view enterprise staff member's reports</b><br><br>
          <img class="zoom" src="./assets/tour/staffdashboard.png" />
          `,
          position: 'bottom',
        },

        {
          title: `
          <div class="col-11" style="color: #FFFFFF">
            <div class="mt-3 mx-auto row">
              <p></p>
              <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/setup.png"></div>
              <div class="col-9" style="line-height: 1.8;"><span>Help Menu</span></div>
              <p></p>
            </div>
          </div>`,
          element: document.querySelector('#openHelp'),
          intro: `
          <br>
          <div style="text-align: center">
           <b>Click here to re-run the guided tour.</b>
          </div>

          `,
          position: 'bottom',
        },

        {
          title: `<div class="col-11" style="color: #FFFFFF">
          <div class="mt-3 mx-auto row">
            <p></p>
            <br><br>
            <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/setup.png"></div>
             <div class="col-9" style="line-height: 1.8;"><span></span></div>
             <p></p>
            </div>
          </div>`,
          intro: `
          <h4 style="text-align: center; margin-top: 2em">
            <i class="cls-h4-font">End Of This Guided Tour!</i><br>
          </h4>
          `,
          position: 'bottom',
        },

      ],
    }).start();
}

// Ent Customers
enterpriseCustomerSetup() {
  this.introJS = introJs();
  this.introJS
    .setOptions({
      showStepNumbers: false,
      showBullets: true,
      showProgress: false,
      steps: [
        // step 3
        {
          title: `
          <div class="col-11" style="color: #FFFFFF">
            <div class="mt-3 mx-auto row">
              <p></p>
              <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/setup.png"></div>
              <div class="col-9" style="line-height: 1.8;"><span> Set Up Your Enterprise Account</span></div>
              <p></p>
            </div>
          </div>`,
          element: document.querySelector('#addEntCustomer'),
          intro: `
          <br>
          <b>Add a Customer</b><br><br>
          <span>Search for a customer company from Colours and add it into your company's register.
          </span>
          `,
        },
          // step 3
        {
            title: `
            <div class="col-11" style="color: #FFFFFF">
            <div class="mt-3 mx-auto row">
              <p></p>
              <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/setup.png"></div>
              <div class="col-9" style="line-height: 1.8;"><span> Set Up Your Enterprise Account</span></div>
              <p></p>
            </div>
            </div>`,
            element: document.querySelector('#inviteEntCustomer'),
            intro: `
            <br>
            <b>Invite a Customer</b><br><br>
            <span>Send an email invitation for customer companies to join the Colours platform. Once customer companies are on Colours, you can add them into your company's register.
            </span>
            `,
        },
        // step 4
        {
          title: `
          <div class="col-11" style="color: #FFFFFF">
            <div class="mt-3 mx-auto row">
              <p></p>
              <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/setup.png"></div>
              <div class="col-9" style="line-height: 1.8;"><span> Set Up Your Enterprise Account</span></div>
              <p></p>
            </div>
          </div>`,
          element: document.querySelector('#viewEntCustomer'),
          intro: `
          <br>
          <b>View Customer's Workspace</b><br><br>
          <span>Click here to get into a Customer Account's workspace.
          </span>
          `,
          position: 'bottom',
        },
         // step 4
         {
          title: `
          <div class="col-11" style="color: #FFFFFF">
            <div class="mt-3 mx-auto row">
              <p></p>
              <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/setup.png"></div>
              <div class="col-9" style="line-height: 1.8;"><span> Set Up Your Enterprise Account</span></div>
              <p></p>
            </div>
          </div>`,
          element: document.querySelector('#addCustomerManagers'),
          intro: `
          <br>
          <b>Add Customer Account Manangers</b><br><br>
          <span>Click here to add or update your company's account managers for this customer.
          </span>
          `,
          position: 'bottom',
        },
         // step 4
        {
          title: `
          <div class="col-11" style="color: #FFFFFF">
            <div class="mt-3 mx-auto row">
              <p></p>
              <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/goal.png"></div>
              <div class="col-9" style="line-height: 1.8;"><span>Plan Enterprise Tasks</span></div>
              <p></p>
            </div>
          </div>`,
          element: document.querySelector('#newTask'),
          intro: `
          <br>
          <b>Create a Task</b><br><br>
          <span>Click here to create a task for any one of your company's customers.
          </span>
          `,
          position: 'top',
        },
        {
          title: `
          <div class="col-11" style="color: #FFFFFF">
            <div class="mt-3 mx-auto row">
              <p></p>
              <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/goal.png"></div>
              <div class="col-9" style="line-height: 1.8;"><span>Plan Enterprise Tasks</span></div>
              <p></p>
            </div>
          </div>`,
          element: document.querySelector('#newTask'),
          intro: `
          <br>
          <b>Create a Task</b><br><br>
          <img class="zoom" src="./assets/tour/customertask.png" alt="Customer"/><br><br>
          `,
          position: 'top',
        },

        {
          title: `
          <div class="col-11" style="color: #FFFFFF">
            <div class="mt-3 mx-auto row">
              <p></p>
              <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/setup.png"></div>
              <div class="col-9" style="line-height: 1.8;"><span>Help Menu</span></div>
              <p></p>
            </div>
          </div>`,
          element: document.querySelector('#openHelp'),
          intro: `
          <br>
          <div style="text-align: center">
           <b>Click here to re-run the guided tour.</b>
          </div>
          `,
          position: 'bottom',
        },

        {
          title: `<div class="col-11" style="color: #FFFFFF">
          <div class="mt-3 mx-auto row">
            <p></p>
            <br><br>
            <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/setup.png"></div>
             <div class="col-9" style="line-height: 1.8;"><span></span></div>
             <p></p>
            </div>
          </div>`,
          intro: `
          <h4 style="text-align: center; margin-top: 2em">
            <i class="cls-h4-font">End Of This Guided Tour!</i><br>
          </h4>
          `,
          position: 'bottom',
        },
      ],
    }).start();
}

// Selected Ent Customer
enterpriseSpecificCustomer() {
  this.introJS = introJs();
  this.introJS
    .setOptions({
      showStepNumbers: false,
      showBullets: true,
      showProgress: false,
      steps: [

        {
          title: `
           <div class="col-11" style="color: #FFFFFF">
             <div class="mt-3 mx-auto row">
               <p></p>
               <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/setup.png"></div>
               <div class="col-9" style="line-height: 1.8;"><span>Set Up Your Enterprise Account</span></div>
               <p></p>
             </div>
           </div>`,
         element: document.querySelector('#customerHome'),
         intro: `
         <b>Customer Home Timeline</b><br>
         <span>
          All Enterprise tasks you have created for a customer company or that were created for you by a customer are listed in this space.
          This is where you can plan, implement and report your enterprise activities between a customer and your company.
         </span>
         `,
         position: 'bottom',
       },
        // step 3
        {
          title: `
          <div class="col-11" style="color: #FFFFFF">
            <div class="mt-3 mx-auto row">
              <p></p>
              <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/goal.png"></div>
              <div class="col-9" style="line-height: 1.8;"><span>Plan Enterprise Tasks</span></div>
              <p></p>
            </div>
          </div>`,
          element: document.querySelector('#newTask'),
          intro: `
          <br>
          <b>Create a Task</b><br><br>
          <span>Click here to create a task for the customer.
         </span>
          `,
          position: 'top',
        },
        {
          title: `
          <div class="col-11" style="color: #FFFFFF">
            <div class="mt-3 mx-auto row">
              <p></p>
              <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/clipboard.png"></div>
              <div class="col-9" style="line-height: 1.8;"><span>Report Enterprise Activities</span></div>
              <p></p>
            </div>
          </div>`,
          element: document.querySelector('#customerDash'),
          intro: `
          <br>
          <b>View Customer Reports</b><br>
          <img class="zoom" src="./assets/tour/customerdash.png" alt="Customer"/>
          `,
        },
        {
          title: `
          <div class="col-11" style="color: #FFFFFF">
            <div class="mt-3 mx-auto row">
              <p></p>
              <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/setup.png"></div>
              <div class="col-9" style="line-height: 1.8;"><span>Help Menu</span></div>
              <p></p>
            </div>
          </div>`,
          element: document.querySelector('#openHelp'),
          intro: `
          <br>
          <div style="text-align: center">
           <b>Click here to re-run the guided tour.</b>
          </div>
          `,
          position: 'bottom',
        },

        {
          title: `<div class="col-11" style="color: #FFFFFF">
          <div class="mt-3 mx-auto row">
            <p></p>
            <br><br>
            <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/setup.png"></div>
             <div class="col-9" style="line-height: 1.8;"><span></span></div>
             <p></p>
            </div>
          </div>`,
          intro: `
          <h4 style="text-align: center; margin-top: 2em">
            <i class="cls-h4-font">End Of This Guided Tour!</i><br>
          </h4>
          `,
          position: 'bottom',
        },
      ],
    }).start();
}

// main enterprises page setup
projectSetup() {
  this.introJS = introJs();
  this.introJS
    .setOptions({
      showStepNumbers: false,
      showBullets: true,
      showProgress: false,
      steps: [
        {
          title: `<div class="col-11" style="color: #FFFFFF">
          <div class="mt-3 mx-auto row">
            <p></p>
            <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/setup.png"></div>
             <div class="col-9" style="line-height: 1.8;"><span>Set Up Your Project Account</span></div>
             <p></p>
            </div>
          </div>`,
          element: document.querySelector('#entProject'),
          intro: `
          <br>
          <div class="col">
            <img style="text-align:center" src="./assets/img/tour/project.png" alt="People"/><br><br>
          </div>`,
          tooltipPosition: 'bottom',
        },

        // step 3
        {
          title: `<div class="col-11" style="color: #FFFFFF">
          <div class="mt-3 mx-auto row">
            <p></p>
            <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/setup.png"></div>
             <div class="col-9" style="line-height: 1.8;"><span>Set Up Your Project Account</span></div>
             <p></p>
            </div>
          </div>`,
          element: document.querySelector('#createPrjct'),
          intro: `
            <br>
            <b>Create a Project</b><br><br>
            <span>
              Click this button to create a new Project.
              Once created, this project will appear on your list of Projects below this button.
              Once inside the project you shall be able to add tesm meambers for your company and also add other companies that are working on the project.
            </span>
          `,
          position: 'bottom',
        },
        // step 4
        {
          title: `<div class="col-11" style="color: #FFFFFF">
          <div class="mt-3 mx-auto row">
            <p></p>
            <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/setup.png"></div>
             <div class="col-9" style="line-height: 1.8;"><span>Set Up Your Project Account</span></div>
             <p></p>
            </div>
          </div>`,
          element: document.querySelector('#joinPrjct'),
          intro: `
          <br>
          <b>Join a Project</b><br><br>
          <span>
          This button allows your company to join projects that have already been created by others on the Colours platform.
          Once you have joined, that project will appear on your projects list.
          </span>
          `,
          position: 'bottom',
        },
        // step 5
        {
          title: `<div class="col-11" style="color: #FFFFFF">
          <div class="mt-3 mx-auto row">
            <p></p>
            <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/setup.png"></div>
             <div class="col-9" style="line-height: 1.8;"><span>Set Up Your Project Account</span></div>
             <p></p>
            </div>
          </div>`,
          element: document.querySelector('#viewPrjct'),
          intro: `
          <br>
          <b>Enter Project Workspace</b><br><br>
          <span>
            Click to enter into a Project's workspace.
          </span>
          `,
          position: 'bottom',
        },
        {
          title: `<div class="col-11" style="color: #FFFFFF">
          <div class="mt-3 mx-auto row">
            <p></p>
            <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/setup.png"></div>
             <div class="col-9" style="line-height: 1.8;"><span>Set Up Your Project Account</span></div>
             <p></p>
            </div>
          </div>`,
          element: document.querySelector('#exitPrjct'),
          intro: `
          <br>
          <b>Exit from a Project</b><br><br>
          <span>
           Click here to exit from this project as a company or an individual.
          </span>
          `,
          position: 'bottom',
        },
        {
          title: `<div class="col-11" style="color: #FFFFFF">
          <div class="mt-3 mx-auto row">
            <p></p>
            <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/setup.png"></div>
             <div class="col-9" style="line-height: 1.8;"><span>Set Up Your Project Account</span></div>
             <p></p>
            </div>
          </div>`,
          element: document.querySelector('#deletePrjct'),
          intro: `
          <br>
          <b>Delete a Project</b><br><br>
          <span>
            Click here to delete this project from Colours.
          </span>
          `,
          position: 'bottom',
        },

        {
          title: `<div class="col-11" style="color: #FFFFFF">
          <div class="mt-3 mx-auto row">
            <p></p>
            <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/setup.png"></div>
             <div class="col-9" style="line-height: 1.8;"><span>Help Menu</span></div>
             <p></p>
            </div>
          </div>`,
          element: document.querySelector('#openHelp'),
          intro: `
          <br>
          <div style="text-align: center">
           <b>Click here to re-run the guided tour.</b>
          </div>
          `,
          position: 'bottom',
        },

        {
          title: `<div class="col-11" style="color: #FFFFFF">
          <div class="mt-3 mx-auto row">
            <p></p>
            <br><br>
            <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/setup.png"></div>
             <div class="col-9" style="line-height: 1.8;"><span></span></div>
             <p></p>
            </div>
          </div>`,
          intro: `
          <h4 style="text-align: center; margin-top: 2em">
            <i class="cls-h4-font">End Of This Guided Tour!</i><br>
          </h4>
          `,
          position: 'bottom',
        },

      ],
    }).start();
}
// Setup for a selected project
viewProjectSetup() {
  this.introJS = introJs();
  this.introJS
    .setOptions({
      showStepNumbers: false,
      showBullets: true,
      showProgress: false,
      steps: [
        {
          title: `
           <div class="col-11" style="color: #FFFFFF">
             <div class="mt-3 mx-auto row">
               <p></p>
               <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/setup.png"></div>
               <div class="col-9" style="line-height: 1.8;"><span>Set Up Your Enterprise Account</span></div>
               <p></p>
             </div>
           </div>`,
         element: document.querySelector('#projectHome'),
         intro: `
         <b>Project Home Timeline</b><br>
         <span>
          All Projects tasks you have created or that were created for you are listed in this space.
          This is where you can plan, implement and report your project activities.
         </span>
        `,
         position: 'bottom',
       },

        // step 6
        {
          title: `<div class="col-11" style="color: #FFFFFF">
          <div class="mt-3 mx-auto row">
            <p></p>
            <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/setup.png"></div>
             <div class="col-9" style="line-height: 1.8;"><span>Set Up Your Project Account</span></div>
             <p></p>
            </div>
          </div>`,
          element: document.querySelector('#prjDescription'),
          intro: `
          <br>
          <b>Project Description</b><br><br>
          <span>
            A project description is the naming of project sections and project physical components.
            Click to add sub-sections which maybe components of a project life cycle's stages or actual physical structures of the project.
          </span>
          `,
          position: 'left',
        },
        {
          title: `<div class="col-11" style="color: #FFFFFF">
          <div class="mt-3 mx-auto row">
            <p></p>
            <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/setup.png"></div>
             <div class="col-9" style="line-height: 1.8;"><span>Set Up Your Project Account</span></div>
             <p></p>
            </div>
          </div>`,
          element: document.querySelector('#prjDescription'),
          intro: `
          <br>
          <b>Add Project Description</b><br><br>
          <img class="zoom" src="./assets/tour/prjdescription.png" />
          </span>
          `,
          position: 'left',
        },
        // step 7
        {
          title: `<div class="col-11" style="color: #FFFFFF">
          <div class="mt-3 mx-auto row">
            <p></p>
            <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/setup.png"></div>
             <div class="col-9" style="line-height: 1.8;"><span>Set Up Your Project Account</span></div>
             <p></p>
            </div>
          </div>`,
          element: document.querySelector('#prjCostFramework'),
          intro: `
          <br>
          <b>Cost Framework</b><br><br>
          <span>
          In this section, you enter the project budget cost framework. This forms the baseline against which actual quantities and costs will be measured.
          </span>
          `,
          position: 'left',
        },
        {
          title: `<div class="col-11" style="color: #FFFFFF">
          <div class="mt-3 mx-auto row">
            <p></p>
            <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/setup.png"></div>
             <div class="col-9" style="line-height: 1.8;"><span>Set Up Your Project Account</span></div>
             <p></p>
            </div>
          </div>`,
          element: document.querySelector('#prjCostFramework'),
          intro: `
          <br>
          <b>Add Project Cost Framework</b><br><br>
          <img class="zoom" src="./assets/tour/prjframework.png" />
          `,
          position: 'left',
        },
        // step 8
        {
          title: `<div class="col-11" style="color: #FFFFFF">
          <div class="mt-3 mx-auto row">
            <p></p>
            <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/setup.png"></div>
             <div class="col-9" style="line-height: 1.8;"><span>Set Up Your Project Account</span></div>
             <p></p>
            </div>
          </div>`,
          element: document.querySelector('#prjEquipment'),
          intro: `
          <br>
          <b>Equipment</b><br><br>
          <span>
          This section allows users to add equipment to the project. These would be coming from the company Asset Register comprises both owned and or hired equipment.
          </span>
          `,
          position: 'left',
        },
        // step 9
        {
          title: `<div class="col-11" style="color: #FFFFFF">
          <div class="mt-3 mx-auto row">
            <p></p>
            <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/setup.png"></div>
             <div class="col-9" style="line-height: 1.8;"><span>Set Up Your Project Account</span></div>
             <p></p>
            </div>
          </div>`,
          element: document.querySelector('#prjStandards'),
          intro: `
          <br>
          <b>Project Standards</b><br><br>
          <span>
          These are typical type of tasks that happen repeatedly within a company, to the extent that they translate into a company's standard activities. Examples of such are Concrete works, Plastering works, Carpentry works , Reports, Meetings,Drawings etc. These standards are the elements that are created or added as the itemised components of a cost framework or traditionally known as line items in a bill of quantities.
          </span>
          `,
          position: 'left',
        },
        {
          title: `<div class="col-11" style="color: #FFFFFF">
          <div class="mt-3 mx-auto row">
            <p></p>
            <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/setup.png"></div>
             <div class="col-9" style="line-height: 1.8;"><span>Set Up Your Project Account</span></div>
             <p></p>
            </div>
          </div>`,
          element: document.querySelector('#prjStandards'),
          intro: `
          <br>
          <b>Add project standards</b><br><br>
          <img class="zoom" src="./assets/tour/prjstandards.png" />
          `,
          position: 'left',
        },
        {
          title: `
          <div class="col-11" style="color: #FFFFFF">
            <div class="mt-3 mx-auto row">
              <p></p>
              <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/goal.png"></div>
              <div class="col-9" style="line-height: 1.8;"><span>Plan Project Tasks</span></div>
              <p></p>
            </div>
          </div>`,
          element: document.querySelector('#newTask'),
          intro: `
          <br>
          <b>Create a Task</b><br><br>
          <span>
           Click here to create a new project task.
          </span>`,
          position: 'left',
        },
        {
          title: `
          <div class="col-11" style="color: #FFFFFF">
            <div class="mt-3 mx-auto row">
              <p></p>
              <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/goal.png"></div>
              <div class="col-9" style="line-height: 1.8;"><span>Plan Project Tasks</span></div>
              <p></p>
            </div>
          </div>`,
          element: document.querySelector('#newTask'),
          intro: `
          <br>
          <b>Create a Task</b><br><br>
          <img class="zoom" src="./assets/tour/prjtask.png" />
          `,
          position: 'left',
        },

        {
          title: `<div class="col-11" style="color: #FFFFFF">
          <div class="mx-auto row">
            <p></p>
            <div class="col-2" style="padding-left: 0px;padding-right: 0px;"><img  src="./assets/tour/implementation.png"></div>
             <div class="col-9" style="line-height: 1.8;"><span>Implement Project Tasks</span></div>
             <p></p>
            </div>
          </div>`,
          element: document.querySelector('#tickPrjTask'),
          intro: `
          <b>Add Project Task to your Things To Do List</b><br>
          <span>
          To copy a task onto your Things To Do List, tick the task's checkbox. The task will automatically be added onto your Things To Do List.
          </span>
          `,
          position: 'bottom',
        },

        {
         title: `<div class="col-11" style="color: #FFFFFF">
          <div class="mt-3 mx-auto row">
            <p></p>
            <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/implementation.png"></div>
             <div class="col-9" style="line-height: 1.8;"><span>Implement Project Tasks</span></div>
             <p></p>
            </div>
          </div>`,
          element: document.querySelector('#myDiary'),
          intro: `
          <br>
          <b>Things To Do List</b><br><br>
          <span>
          Click here to go to your Things To Do List to add project tasks to work done and time check/diary.
          </span>`,
          position: 'left',
        },
        {
         title: `<div class="col-11" style="color: #FFFFFF">
          <div class="mt-3 mx-auto row">
            <p></p>
            <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/implementation.png"></div>
             <div class="col-9" style="line-height: 1.8;"><span>Implement Project Tasks</span></div>
             <p></p>
            </div>
          </div>`,
          element: document.querySelector('#myWorkDone'),
          intro: `
          <br>
          <b>Work Done</b><br><br>
          <span>
          From time to time, click this button to enter your actual work done quantities for the tasks. Click on the task that you are working on to enter your actual work done quantity for that task. In instances where the task is continuous, enter the quantity done since the previous entry.
          </span>
          `,
          position: 'left',
        },

        {
         title: `<div class="col-11" style="color: #FFFFFF">
          <div class="mt-3 mx-auto row">
            <p></p>
            <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/implementation.png"></div>
             <div class="col-9" style="line-height: 1.8;"><span>Implement Project Tasks</span></div>
             <p></p>
            </div>
          </div>`,
          element: document.querySelector('#myTimeCheck'),
          intro: `
          <br>
          <b>Time Check</b><br><br>
          <span>The Time Check will automatically help you to create your Time Sheets. Click on a task you are working on each time the time check pops up. Time spent on a task will thus be automatically computed.
          </span>`,
          position: 'left',
        },

        {
          title: `<div class="col-11" style="color: #FFFFFF">
          <div class="mt-3 mx-auto row">
            <p></p>
            <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/setup.png"></div>
             <div class="col-9" style="line-height: 1.8;"><span>Set Up Your Project Account</span></div>
             <p></p>
            </div>
          </div>`,
          element: document.querySelector('#prjOurteam'),
          intro: `
          <br>
          <b>Add Project Team</b><br><br>
          <span>
          <span>To add a team member to a project, conduct a search on the Colours platform or send an invitation by email.</span><br>
          </span><br>
          `,
          position: 'bottom',
        },

        {
          title: `<div class="col-11" style="color: #FFFFFF">
          <div class="mt-3 mx-auto row">
            <p></p>
            <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/setup.png"></div>
             <div class="col-9" style="line-height: 1.8;"><span>Set Up Your Project Account</span></div>
             <p></p>
            </div>
          </div>`,
          element: document.querySelector('#prjCompanies'),
          intro: `
          <br>
          <b>Add Companies to your project</b><br><br>
          <span>
          A project usually has several companies that are working on it.
          To add a company onto your project you have created, search the Colours platform and send an invitation to a company that is already on the Colours platform. For companies that are not on the Colours platform, send an invitation by email.
          </span><br>
          `,
          position: 'bottom',
        },

        {
          title: `
          <div class="col-11" style="color: #FFFFFF">
            <div class="mt-3 mx-auto row">
              <p></p>
              <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/clipboard.png"></div>
              <div class="col-9" style="line-height: 1.8;"><span>Report Project Activities</span></div>
              <p></p>
            </div>
          </div>`,
          element: document.querySelector('#prjDashboard'),
          intro: `
          <br>
          <b>View Whole Project Reports</b><br>
          <img class="zoom" src="./assets/tour/prjdashboard.png" />
          `,
          position: 'bottom',
        },

        {
          title: `<div class="col-11" style="color: #FFFFFF">
          <div class="mt-3 mx-auto row">
            <p></p>
            <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/setup.png"></div>
             <div class="col-9" style="line-height: 1.8;"><span>Help Menu</span></div>
             <p></p>
            </div>
          </div>`,
          element: document.querySelector('#openHelp'),
          intro: `
          <br>
          <div style="text-align: center">
           <b>Click here to re-run the guided tour.</b>
          </div>
          `,
          position: 'bottom',
        },

        {
          title: `<div class="col-11" style="color: #FFFFFF">
          <div class="mt-3 mx-auto row">
            <p></p>
            <br><br>
            <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/setup.png"></div>
             <div class="col-9" style="line-height: 1.8;"><span></span></div>
             <p></p>
            </div>
          </div>`,
          intro: `
          <h4 style="text-align: center; margin-top: 2em">
            <i class="cls-h4-font">End Of This Guided Tour!</i><br>
          </h4>
          `,
          position: 'bottom',
        },

      ],
    }).start();
}
// Our Team
projectTeamSetup() {
  const intros = introJs();
  intros.setOptions({
      showStepNumbers: false,
      showBullets: true,
      showProgress: false,
      steps: [
        {
          title: `<div class="col-11" style="color: #FFFFFF">
          <div class="mt-3 mx-auto row">
            <p></p>
            <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/setup.png"></div>
             <div class="col-9" style="line-height: 1.8;"><span>Set Up Your Project Account</span></div>
             <p></p>
            </div>
          </div>`,
          element: document.querySelector('#addTeamMember'),
          intro: `
          <br>
          <b>Add Project Team</b><br><br>
          <span>To add a team member to a project, conduct a search on the Colours platform or send an invitation by email.</span><br>
          `,
          position: 'bottom',
        },
        {
          title: `<div class="col-11" style="color: #FFFFFF">
          <div class="mt-3 mx-auto row">
            <p></p>
            <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/setup.png"></div>
             <div class="col-9" style="line-height: 1.8;"><span>Set Up Your Project Account</span></div>
             <p></p>
            </div>
          </div>`,
          element: document.querySelector('#inviteTeamMember'),
          intro: `
          <br>
          <b>Invite Project Team Members</b><br><br>
          <span>
          For team members that are not yet on the Colours platform, send them an invitation by email as provided for on this button.
          </span><br>
          `,
          position: 'bottom',
        },
        {
          title: `<div class="col-11" style="color: #FFFFFF">
          <div class="mt-3 mx-auto row">
            <p></p>
            <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/setup.png"></div>
             <div class="col-9" style="line-height: 1.8;"><span>Set Up Your Project Account</span></div>
             <p></p>
            </div>
          </div>`,
          element: document.querySelector('#editTeamMember'),
          intro: `
          <br>
          <b>Edit Team Member's information</b><br><br>
          <span>Click here to edit this team member's information.</span><br>
          `,
          position: 'bottom',
        },
        {
          title: `<div class="col-11" style="color: #FFFFFF">
          <div class="mt-3 mx-auto row">
            <p></p>
            <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/setup.png"></div>
             <div class="col-9" style="line-height: 1.8;"><span>Set Up Your Project Account</span></div>
             <p></p>
            </div>
          </div>`,
          element: document.querySelector('#removeTeamMember'),
          intro: `
          <br>
          <b>Remove Team Member</b><br><br>
          <span>Click here to remove this team member from the project.</span><br>
          `,
          position: 'bottom',
        },
        {
          title: `<div class="col-11" style="color: #FFFFFF">
          <div class="mt-3 mx-auto row">
            <p></p>
            <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/goal.png"></div>
             <div class="col-9" style="line-height: 1.8;"><span>Plan Project Tasks</span></div>
             <p></p>
            </div>
          </div>`,
          element: document.querySelector('#chatTeamMember'),
          intro: `
          <br>
          <b>Chat with Your Team Members</b><br><br>
          <span>Click here to chat with your team members. You are also able to automatically convert your chats into tasks. This way users do not have to scroll down several days of chats to identify tasks that have discussed between each other. Automatic conversion of components of chats into tasks means that a user can simply search and find tasks that they extracted from their previous chats.
          </span><br>
          `,
          position: 'bottom',
        },
        {
          title: `
          <div class="col-11" style="color: #FFFFFF">
            <div class="mt-3 mx-auto row">
              <p></p>
              <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/goal.png"></div>
              <div class="col-9" style="line-height: 1.8;"><span>Plan Project Tasks</span></div>
              <p></p>
            </div>
          </div>`,
          element: document.querySelector('#teamMember'),
          intro: `
          <br>
          <b>View Team Member's Workspace</b><br><br>
          <span>Click on the name of a team member, to get into team member's workspace.</span><br>
          `,
          position: 'bottom',
        },

        {
          title: `
          <div class="col-11" style="color: #FFFFFF">
            <div class="mt-3 mx-auto row">
              <p></p>
              <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/goal.png"></div>
              <div class="col-9" style="line-height: 1.8;"><span>Plan Project Tasks</span></div>
              <p></p>
            </div>
          </div>`,
          element: document.querySelector('#newTask'),
          intro: `
          <br>
          <b>Create a Task</b><br><br>
          <span>
           Click here to create a new project task for a team member.
          </span>`,
          position: 'left',
        },
        {
          title: `<div class="col-11" style="color: #FFFFFF">
          <div class="mt-3 mx-auto row">
            <p></p>
            <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/setup.png"></div>
             <div class="col-9" style="line-height: 1.8;"><span>Help Menu</span></div>
             <p></p>
            </div>
          </div>`,
          element: document.querySelector('#openHelp'),
          intro: `
          <br>
          <div style="text-align: center">
          <b>Click here to re-run the guided tour.</b>
          </div>

          `,
          position: 'bottom',
        },

        {
          title: `<div class="col-11" style="color: #FFFFFF">
          <div class="mt-3 mx-auto row">
            <p></p>
            <br><br>
            <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/setup.png"></div>
             <div class="col-9" style="line-height: 1.8;"><span></span></div>
             <p></p>
            </div>
          </div>`,
          intro: `
          <h4 style="text-align: center; margin-top: 2em">
            <i class="cls-h4-font">End Of This Guided Tour!</i><br>
          </h4>
          `,
          position: 'bottom',
        },
      ],
    }).start();
}
// specific team member
projectTeamWorkspace() {
  const intros = introJs();
  intros.setOptions({
      showStepNumbers: false,
      showBullets: true,
      showProgress: false,
      steps: [
        {
          title: `
           <div class="col-11" style="color: #FFFFFF">
             <div class="mt-3 mx-auto row">
               <p></p>
               <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/setup.png"></div>
               <div class="col-9" style="line-height: 1.8;"><span>Set Up Your Enterprise Account</span></div>
               <p></p>
             </div>
           </div>`,
         element: document.querySelector('#teamHome'),
         intro: `
         <b>Team Member Home Timeline</b><br>
         <span>
          All Projects tasks you have created for a team member or that were created for you by a team member are listed in this space.
          This is where you can plan tasks for a team member and view project reports of a team member.
         </span>
        `,
         position: 'bottom',
       },

        {
          title: `
          <div class="col-11" style="color: #FFFFFF">
            <div class="mt-3 mx-auto row">
              <p></p>
              <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/goal.png"></div>
              <div class="col-9" style="line-height: 1.8;"><span>Plan Project Tasks</span></div>
              <p></p>
            </div>
          </div>`,
          element: document.querySelector('#newTask'),
          intro: `
          <br>
          <b>Create a Task</b><br><br>
          <span>
           Click here to create a new project task for a team member.
         </span>`,
          position: 'left',
        },

        {
          title: `
            <div class="col-11" style="color: #FFFFFF">
              <div class="mt-3 mx-auto row">
                <p></p>
                <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/goal.png"></div>
                <div class="col-9" style="line-height: 1.8;"><span>Plan Personal Tasks</span></span></div>
                <p></p>
              </div>
            </div>`,
          element: document.querySelector('#chat'),
          intro: `<br>
          <b>Chat with Your Team Member</b><br><br>
          <span>
            Click to chat and also plan new tasks for this team member or yourself from the chat page.
          </span>`,
          position: 'left',
        },
        {
          title: `
          <div class="col-11" style="color: #FFFFFF">
            <div class="mt-3 mx-auto row">
              <p></p>
              <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/clipboard.png"></div>
              <div class="col-9" style="line-height: 1.8;"><span>Report Enterprise Activities</span></div>
              <p></p>
            </div>
          </div>`,
          element: document.querySelector('#memberDashboad'),
          intro: `
          <br>
          <b>View Team Member's project reports</b><br><br>
          <img class="zoom" src="./assets/tour/teamdash.png" />
          `,
          position: 'left',
        },
        {
          title: `<div class="col-11" style="color: #FFFFFF">
          <div class="mt-3 mx-auto row">
            <p></p>
            <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/setup.png"></div>
             <div class="col-9" style="line-height: 1.8;"><span>Help Menu</span></div>
             <p></p>
            </div>
          </div>`,
          element: document.querySelector('#openHelp'),
          intro: `
          <br>
          <div style="text-align: center">
          <b>Click here to re-run the guided tour.</b>
          </div>

          `,
          position: 'bottom',
        },

        {
          title: `<div class="col-11" style="color: #FFFFFF">
          <div class="mt-3 mx-auto row">
            <p></p>
            <br><br>
            <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/setup.png"></div>
             <div class="col-9" style="line-height: 1.8;"><span></span></div>
             <p></p>
            </div>
          </div>`,
          intro: `
          <h4 style="text-align: center; margin-top: 2em">
            <i class="cls-h4-font">End Of This Guided Tour!</i><br>
          </h4>
          `,
          position: 'bottom',
        },
      ],
    }).start();
}
// Project Companies
projectCompanySetup() {
  const intros = introJs();
  intros.setOptions({
      showStepNumbers: false,
      showBullets: true,
      showProgress: false,
      steps: [
        {
          title: `<div class="col-11" style="color: #FFFFFF">
          <div class="mt-3 mx-auto row">
            <p></p>
            <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/setup.png"></div>
             <div class="col-9" style="line-height: 1.8;"><span>Set Up Your Project Account</span></div>
             <p></p>
            </div>
          </div>`,
          element: document.querySelector('#addprjCompany'),
          intro: `
          <br>
          <b>Add Companies</b><br><br>
          <span>To add a company click the <b>Add Button</b> to search the Colours platform to find a company that is already on the platform. Once you have identified the company, send a system invitation from the platform. For companies that are not yet on the Colours platform click the <b>Invite Button</b> to send them an invitation by email.</span><br>
          `,
          position: 'bottom',
        },
        {
          title: `<div class="col-11" style="color: #FFFFFF">
          <div class="mt-3 mx-auto row">
            <p></p>
            <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/setup.png"></div>
             <div class="col-9" style="line-height: 1.8;"><span>Set Up Your Project Account</span></div>
             <p></p>
            </div>
          </div>`,
          element: document.querySelector('#viewPrjCompany'),
          intro: `
          <br>
          <b>Enter into a Company's Workspace</b><br><br>
          <span>Click on the name of a company to get into its workspace.</span><br>
          `,
          position: 'bottom',
        },

        {
          title: `<div class="col-11" style="color: #FFFFFF">
          <div class="mt-3 mx-auto row">
            <p></p>
            <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/setup.png"></div>
             <div class="col-9" style="line-height: 1.8;"><span>Set Up Your Project Account</span></div>
             <p></p>
            </div>
          </div>`,
          element: document.querySelector('#assignRole'),
          intro: `
          <br>
          <span>Click here to assign a company's role in a project.</span><br>
          <img class="zoom" src="./assets/tour/assignrole.png" />
          `,
          position: 'bottom',
        },

        {
          title: `
          <div class="col-11" style="color: #FFFFFF">
            <div class="mt-3 mx-auto row">
              <p></p>
              <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/goal.png"></div>
              <div class="col-9" style="line-height: 1.8;"><span>Plan Project Tasks</span></div>
              <p></p>
            </div>
          </div>`,
          element: document.querySelector('#newTask'),
          intro: `
          <br>
          <b>Create a Task</b><br><br>
          <img class="zoom" src="./assets/tour/comptask.png" />
          `,
          position: 'left',
        },

        {
           title: `<div class="col-11" style="color: #FFFFFF">
            <div class="mt-3 mx-auto row">
              <p></p>
              <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/setup.png"></div>
              <div class="col-9" style="line-height: 1.8;"><span>Help Menu</span></div>
              <p></p>
              </div>
            </div>`,
          element: document.querySelector('#openHelp'),
          intro: `
          <br>
          <div style="text-align: center">
           <b>Click here to re-run the guided tour.</b>
          </div>

          `,
          position: 'bottom',
        },

        {
          title: `<div class="col-11" style="color: #FFFFFF">
          <div class="mt-3 mx-auto row">
            <p></p>
            <br><br>
            <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/setup.png"></div>
             <div class="col-9" style="line-height: 1.8;"><span></span></div>
             <p></p>
            </div>
          </div>`,
          intro: `
          <h4 style="text-align: center; margin-top: 2em">
            <i class="cls-h4-font">End Of This Guided Tour!</i><br>
          </h4>
          `,
          position: 'bottom',
        },
      ],
    }).start();
}
// specific company
projectCompanyWorkspace() {
  const intros = introJs();
  intros.setOptions({
      showStepNumbers: false,
      showBullets: true,
      showProgress: false,
      steps: [
        {
          title: `
           <div class="col-11" style="color: #FFFFFF">
             <div class="mt-3 mx-auto row">
               <p></p>
               <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/setup.png"></div>
               <div class="col-9" style="line-height: 1.8;"><span>Set Up Your Enterprise Account</span></div>
               <p></p>
             </div>
           </div>`,
         element: document.querySelector('#companyHome'),
         intro: `
         <b>Company Home Timeline</b><br>
         <span>
          All Projects tasks you have created for a company or that were created for you by another company are listed in this space.
          This is where you can plan tasks for a specific company as well as viewing project reports of that company.
         </span>
        `,
         position: 'bottom',
       },
        {
          title: `
          <div class="col-11" style="color: #FFFFFF">
            <div class="mt-3 mx-auto row">
              <p></p>
              <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/goal.png"></div>
              <div class="col-9" style="line-height: 1.8;"><span>Plan Project Tasks</span></div>
              <p></p>
            </div>
          </div>`,
          element: document.querySelector('#prjChamp'),
          intro: `
          <br>
          <b>Project Champions</b><br><br>
          <span>
           Click here to issue tasks to this company's Project Champions.
          </span>`,
          position: 'bottom',
        },

        {
          title: `
          <div class="col-11" style="color: #FFFFFF">
            <div class="mt-3 mx-auto row">
              <p></p>
              <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/goal.png"></div>
              <div class="col-9" style="line-height: 1.8;"><span>Plan Project Tasks</span></div>
              <p></p>
            </div>
          </div>`,
          element: document.querySelector('#newTask'),
          intro: `
          <br>
          <b>Create a Task</b><br><br>
          <span>
           Click here to create a new task for a team member from this company.
          </span>`,
          position: 'left',
        },

        {
          title: `
          <div class="col-11" style="color: #FFFFFF">
            <div class="mt-3 mx-auto row">
              <p></p>
              <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/clipboard.png"></div>
              <div class="col-9" style="line-height: 1.8;"><span>Report Enterprise Activities</span></div>
              <p></p>
            </div>
          </div>`,
          element: document.querySelector('#prjCompdash'),
          intro: `
          <br>
          <b>View Company's Project Reports</b><br><br>
          <img class="zoom" src="./assets/tour/prjcompdash.png" />
          `,
          position: 'left',
        },

        {
          title: `
          <div class="col-11" style="color: #FFFFFF">
            <div class="mt-3 mx-auto row">
              <p></p>
              <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/goal.png"></div>
              <div class="col-9" style="line-height: 1.8;"><span>Help Menu</span></div>
              <p></p>
            </div>
          </div>`,
          element: document.querySelector('#openHelp'),
          intro: `
          <br>
          <div style="text-align: center">
          <b>Click here to re-run the guided tour.</b>
          </div>

          `,
          position: 'bottom',
        },

        {
          title: `<div class="col-11" style="color: #FFFFFF">
          <div class="mt-3 mx-auto row">
            <p></p>
            <br><br>
            <div class="col" style="padding-left: 0px;padding-right: 0px;margin-top: -0.8em;"><img src="./assets/tour/setup.png"></div>
             <div class="col-9" style="line-height: 1.8;"><span></span></div>
             <p></p>
            </div>
          </div>`,
          intro: `
          <h4 style="text-align: center; margin-top: 2em">
            <i class="cls-h4-font">End Of This Guided Tour!</i><br>
          </h4>
          `,
          position: 'bottom',
        },
      ],
    }).start();
}

}
