import { Component, Input, OnInit } from '@angular/core';
import { ChatService } from '../../../../services/chat.service';
import { User } from '../../../../models/user';
import { UserDataService } from 'app/services/user-data.service';

@Component({
  selector: 'app-typing-indicator',
  templateUrl: './typing-indicator.component.html',
  styleUrls: ['./typing-indicator.component.scss']
})
export class TypingIndComponent implements OnInit {
  @Input() user: User;
  @Input() typing: string[];

  constructor(private chatService: ChatService, public usd: UserDataService) {}

  ngOnInit() {}

  showTyping(typing, userId) {
    return typing.some(typerId => typerId !== userId);
  }

  getTypingUsersById(typing, userId) {
    const otherUserIds = typing.filter(typerId => typerId !== userId);
    let names: string = otherUserIds
      .map(typerId => this.getUserName(this.chatService.getUserById(typerId)))
      .join(', ');
    otherUserIds.length > 1
      ? (names = names + ' are typing...')
      : (names = names + ' is typing...');
    return names;
  }
  getUserName(user) {
    if (!user) {
      return null;
    }
    // return user.realName ? user.realName : user.displayName;
    return user.realName ? user.realName : user.name;
  }
}
