import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NavbarComponent } from './navbar.component';
import { TagInputModule } from 'ngx-chips';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { JwBootstrapSwitchNg2Module } from 'jw-bootstrap-switch-ng2';

@NgModule({
    imports: [ RouterModule, CommonModule, TagInputModule, NgbModule, FormsModule, FontAwesomeModule, JwBootstrapSwitchNg2Module],
    declarations: [ NavbarComponent ],
    exports: [ NavbarComponent ],
    schemas: [NO_ERRORS_SCHEMA]
})

export class NavbarModule {}
