/*!

 =========================================================
 * Colours v2
 =========================================================

 * Product Page: https://colours-dev.web.app/
 * Copyright 2020 Colours (https://colours-dev.web.app/)

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software. */
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

// platformBrowserDynamic().bootstrapModule(AppModule);
platformBrowserDynamic().bootstrapModule(AppModule)
.then(ref => {
  // Ensure Angular destroys itself on hot reloads.
  if (window['ngRef']) {
    window['ngRef'].destroy();
  }
  window['ngRef'] = ref;

  // Otherwise, log the boot error
})
.catch(err => console.error(err));
