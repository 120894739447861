import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
// import { HttpModule } from '@angular/http';
// import { MaterialModule } from './material.module';
import { CommonModule, CurrencyPipe, APP_BASE_HREF } from '@angular/common';
import { HttpClientModule, HttpClient } from '@angular/common/http'
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
// import { NgxPhotoEditorModule } from 'ngx-photo-editor';
import { AppComponent } from './app.component';

import { ToolPluginModule } from './shared/toolplugin/toolplugin.module';
import { SidebarModule } from './sidebar/sidebar.module';
import { FixedPluginModule } from './shared/fixedplugin/fixedplugin.module';
import { FooterModule } from './shared/footer/footer.module';
import { NavbarModule } from './shared/navbar/navbar.module';
import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';
import { AppRoutes } from './app.routing';
import { ColoursPanelComponent } from './layouts/colours-panel/colours-panel.component';
// 1. Import the libs you need
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule, SETTINGS } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { environment } from 'environments/environment';
import { NgSelectModule } from '@ng-select/ng-select';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ScrollToBottomDirective } from './services/scroll.directive';
// import { ChatPluginModule } from './shared/chatPlugin/chatPlugin.module';
import { TaskPluginModule } from './shared/taskplugin/taskplugin.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
// import { IconsModule } from './icons/icons.module';
import {NgxPhotoEditorModule} from "ngx-photo-editor";
// import { NgxFileDropModule } from 'ngx-file-drop';
// import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { NgxLoadingModule } from 'ngx-loading';
// import { GanttModule } from '';
export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

@NgModule({
    imports: [
        BrowserAnimationsModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        // MaterialModule,
        NgxLoadingModule.forRoot({}),
        NgSelectModule,
        FormsModule,
        FontAwesomeModule,
        RouterModule.forRoot(AppRoutes, {
            useHash: true
        }),
        NgxPhotoEditorModule,
        NgbModule,
        HttpClientModule,
        SidebarModule,
        NavbarModule,
        FooterModule,
        // IconsModule,
        FixedPluginModule,
        ToolPluginModule,
        // ChatPluginModule,
        TaskPluginModule,
        // 3. Initialize
        AngularFireModule.initializeApp(environment.firebase),
        AngularFirestoreModule.enablePersistence({
            synchronizeTabs: true
        }),
        // AngularFirestoreModule, // firestore
        AngularFireAuthModule, // auth
        AngularFireStorageModule, // storage
        AngularFireMessagingModule, // Messaging
        // NgxFileDropModule

    ],
    declarations: [
        AppComponent,
        AdminLayoutComponent,
        AuthLayoutComponent,
        ColoursPanelComponent,
        ScrollToBottomDirective,
    ],
    bootstrap: [AppComponent],
    providers: [
        CurrencyPipe,
    ],
    schemas: [NO_ERRORS_SCHEMA]
})

export class AppModule { }


/* 
              // "src/app/assets/css/logic.css",
              // "src/app/assets/css/slider.css",
              // "src/app/assets/css/standsModal.css",
              // "src/app/assets/css/styles.css",
              // "src/app/assets/owl/dist/assets/owl.carousel.min.css",
              // "src/app/assets/ol6/css/ol.css",
              // "src/app/assets/owl/dist/assets/owl.theme.default.min.css" */