import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ToolPluginComponent } from './toolplugin.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TagInputModule } from 'ngx-chips';
import { NgxPhotoEditorModule } from 'ngx-photo-editor';
import { ChatPluginComponent } from './chatPlugin/chatPlugin.component';
import { ColChatComponent } from './chatPlugin/col-chat/col-chat.component';
import { ChatMessageComponent } from './chatPlugin/chat-message/chat-message.component';
import { ChatHeaderComponent } from './chatPlugin/chat-header/chat-header.component';
import { TypingIndComponent } from './chatPlugin/typing-indicator/typing-indicator.component';
import { ChatContComponent } from './chatPlugin/chat-controls/chat-controls.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { JwBootstrapSwitchNg2Module } from 'jw-bootstrap-switch-ng2';


@NgModule({
    imports: [
        RouterModule, CommonModule, DragDropModule, NgbModule, TagInputModule, JwBootstrapSwitchNg2Module,
        // NgxPhotoEditorModule,
        FormsModule, ReactiveFormsModule, FontAwesomeModule, NgSelectModule
    ],
    declarations: [
        ToolPluginComponent,
        ChatPluginComponent,
        ColChatComponent,
        ChatMessageComponent,
        ChatHeaderComponent,
        TypingIndComponent,
        ChatContComponent
    ],
    exports: [ToolPluginComponent],
    schemas: [NO_ERRORS_SCHEMA]
})

export class ToolPluginModule { }
