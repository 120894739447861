2
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

// import {
//   Http,
//   Response,
//   RequestMethod,
//   RequestOptions,
//   Headers
// } from '@angular/http';

@Injectable({
  providedIn: 'root'
})

export class HttpService {
  test = 'How r u?';
  // private headers = new HttpHeaders().set('Content-Type', 'application/json', 'Access-Control-Allow-Origin': '*');
  headers = new HttpHeaders();
  // baseUrl = 'http://localhost:8080/api/inventory';
  // baseUrl = 'https://colours-webapp.herokuapp.com/api/inventory';
  baseUrl = 'https://col-mongodb-server.onrender.com/api/inventory';
  baseUrl2 = 'https://us-central1-colors-a07e8.cloudfunctions.net/newLoginClient';
  prjsUrlAlt = 'https://us-central1-colors-a07e8.cloudfunctions.net/getProjectsAlt';
  prjsUrl = 'https://us-central1-colors-a07e8.cloudfunctions.net/getProjects';
  constructor(public http: HttpClient) {
    this.headers = this.headers.set('Content-Type', 'application/json; charset=utf-8');
    this.headers = this.headers.set('Access-Control-Allow-Origin', '*');
  }
  
  sendCreds(params: any): Observable<any> {
    return this.http.post(this.baseUrl2, params, {headers: this.headers});
  }

  httpGet(url) {
    return this.http.get(url);
  }

  httpPost(url, { }) {
    return this.http.post(url, { name: 'Colours' });
  }
  
  localPost(url, data) {
    // return this.http.post(url, data);
    return this.http.post(url, data, { headers: this.headers});
  }

  getProjects(data) {
    console.log(data);
    // return this.http.post(this.prjsUrlAlt, data, {headers: this.headers});
    return this.http.post(this.prjsUrlAlt, data);
    // return this.http.get(this.prjsUrlAlt, data);
  }
  
  getAllProjects() {
    console.log();
    // return this.http.post(this.prjsUrl, data, {headers: this.headers});
    return this.http.get(this.prjsUrl);
    // return this.http.get(this.prjsUrl, data);
  }

  sendEmail(url, data) {
    console.log(data);
    return this.http.post(url, data, {headers: this.headers});
    // return this.http.post(url, data);
    // return this.http.get(url, data);
  }

  tasking(url, data) {
    console.log(data);
    return this.http.post(url, data, {headers: this.headers});
  }
  
  getUserData(url, data) {
    console.log(data);
    const exampleData = { project : {name: '', id: ''}, user : { name: '', unicode : '', email: '', phoneNumber: '' }}
    return this.http.post(url, data, {headers: this.headers});
  }

  ecopost(url, data) {
    console.log(data);
    return this.http.post(url, data, {headers: this.headers});
  }

  /* MongoDb */

  getAll(): Observable<any> {
    return this.http.get(this.baseUrl);
  }

  get(id): Observable<any> {
    return this.http.get(`${this.baseUrl}/${id}`);
  }

  create(data): Observable<any> {
    // console.log(data);
    return this.http.post(this.baseUrl, data);
  }

  update(id, data): Observable<any> {
    return this.http.put(`${this.baseUrl}/${id}`, data);
  }

  delete(id): Observable<any> {
    return this.http.delete(`${this.baseUrl}/${id}`);
  }

  deleteAll(): Observable<any> {
    return this.http.delete(this.baseUrl);
  }

  findByTitle(title): Observable<any> {
    return this.http.get(`${this.baseUrl}?title=${title}`);
  }

  miningoSync(data) {
    // return this.http.get(`https://miningo-properties.herokuapp.com/sync/${data.standNo}`);
    if(data) {
      console.log(data);
      return this.http.get(`https://miningo-southlands.up.railway.app/sync/${data}`);
    }
  }
}
