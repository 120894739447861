import { Component, Input } from '@angular/core';
import { ChatService } from 'app/services/chat.service';

@Component({
  selector: 'app-chat-header',
  templateUrl: './chat-header.component.html',
  styleUrls: ['./chat-header.component.scss']
})
export class ChatHeaderComponent {
  constructor( public cs: ChatService) {}
  // @Input() chatId: string;
}
