export const myData = {
    name: 'Alice',
    age: 30,
    occupation: 'Developer'
};

export const stands = [
  {
    id: "10003",
    area: "540",
    centx: 31.1,
    centy: -17.71,
    price: 32400,
    province: "Harare",
    road: true,
    stand_num: "10003",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "1001",
    area: "321",
    centx: 31.1,
    centy: -17.7,
    price: 19260,
    province: "Harare",
    road: true,
    stand_num: "1001",
    status: "available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "1002",
    area: "321",
    centx: 31.1,
    centy: -17.7,
    price: 19260,
    province: "Harare",
    road: true,
    stand_num: "1002",
    status: "available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2085",
    area: "790",
    centx: 31.09,
    centy: -17.7,
    price: 47400,
    province: "Harare",
    road: true,
    stand_num: "2085",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2086",
    area: "800",
    centx: 31.09,
    centy: -17.7,
    price: 48000,
    province: "Harare",
    road: true,
    stand_num: "2086",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2087",
    area: "800",
    centx: 31.09,
    centy: -17.7,
    price: 48000,
    province: "Harare",
    road: true,
    stand_num: "2087",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2088",
    area: "800",
    centx: 31.09,
    centy: -17.7,
    price: 48000,
    province: "Harare",
    road: true,
    stand_num: "2088",
    status: "sold",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2089",
    area: "800",
    centx: 31.09,
    centy: -17.7,
    price: 48000,
    province: "Harare",
    road: true,
    stand_num: "2089",
    status: "sold",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2090",
    area: "800",
    centx: 31.09,
    centy: -17.7,
    price: 48000,
    province: "Harare",
    road: true,
    stand_num: "2090",
    status: "reserved",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2091",
    area: "1033",
    centx: 31.09,
    centy: -17.7,
    price: 61980,
    province: "Harare",
    road: true,
    stand_num: "2091",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2092",
    area: "800",
    centx: 31.09,
    centy: -17.7,
    price: 48000,
    province: "Harare",
    road: true,
    stand_num: "2092",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2093",
    area: "800",
    centx: 31.09,
    centy: -17.7,
    price: 48000,
    province: "Harare",
    road: true,
    stand_num: "2093",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2094",
    area: "809",
    centx: 31.09,
    centy: -17.7,
    price: 48540,
    province: "Harare",
    road: true,
    stand_num: "2094",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2095",
    area: "814",
    centx: 31.09,
    centy: -17.7,
    price: 48840,
    province: "Harare",
    road: true,
    stand_num: "2095",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2096",
    area: "800",
    centx: 31.09,
    centy: -17.7,
    price: 48000,
    province: "Harare",
    road: true,
    stand_num: "2096",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2097",
    area: "1549",
    centx: 31.09,
    centy: -17.7,
    price: 92940,
    province: "Harare",
    road: true,
    stand_num: "2097",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2098",
    area: "800",
    centx: 31.09,
    centy: -17.7,
    price: 48000,
    province: "Harare",
    road: true,
    stand_num: "2098",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2099",
    area: "800",
    centx: 31.09,
    centy: -17.7,
    price: 48000,
    province: "Harare",
    road: true,
    stand_num: "2099",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2100",
    area: "1001",
    centx: 31.09,
    centy: -17.7,
    price: 60060,
    province: "Harare",
    road: true,
    stand_num: "2100",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2101",
    area: "1401",
    centx: 31.09,
    centy: -17.7,
    price: 84060,
    province: "Harare",
    road: true,
    stand_num: "2101",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2102",
    area: "1097",
    centx: 31.09,
    centy: -17.7,
    price: 65820,
    province: "Harare",
    road: true,
    stand_num: "2102",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2103",
    area: "953",
    centx: 31.09,
    centy: -17.7,
    price: 57180,
    province: "Harare",
    road: true,
    stand_num: "2103",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2104",
    area: "1187",
    centx: 31.09,
    centy: -17.7,
    price: 71220,
    province: "Harare",
    road: true,
    stand_num: "2104",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2105",
    area: "1070",
    centx: 31.09,
    centy: -17.7,
    price: 64200,
    province: "Harare",
    road: true,
    stand_num: "2105",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2106",
    area: "1193",
    centx: 31.09,
    centy: -17.7,
    price: 71580,
    province: "Harare",
    road: true,
    stand_num: "2106",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2107",
    area: "960",
    centx: 31.09,
    centy: -17.7,
    price: 57600,
    province: "Harare",
    road: true,
    stand_num: "2107",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2108",
    area: "1066",
    centx: 31.09,
    centy: -17.7,
    price: 63960,
    province: "Harare",
    road: true,
    stand_num: "2108",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2109",
    area: "706",
    centx: 31.09,
    centy: -17.7,
    price: 42360,
    province: "Harare",
    road: true,
    stand_num: "2109",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2110",
    area: "594",
    centx: 31.09,
    centy: -17.7,
    price: 35640,
    province: "Harare",
    road: true,
    stand_num: "2110",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2111",
    area: "593",
    centx: 31.09,
    centy: -17.7,
    price: 35580,
    province: "Harare",
    road: true,
    stand_num: "2111",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2112",
    area: "593",
    centx: 31.09,
    centy: -17.7,
    price: 35580,
    province: "Harare",
    road: true,
    stand_num: "2112",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2113",
    area: "593",
    centx: 31.09,
    centy: -17.7,
    price: 35580,
    province: "Harare",
    road: true,
    stand_num: "2113",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2114",
    area: "593",
    centx: 31.09,
    centy: -17.7,
    price: 35580,
    province: "Harare",
    road: true,
    stand_num: "2114",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2115",
    area: "706",
    centx: 31.09,
    centy: -17.7,
    price: 42360,
    province: "Harare",
    road: true,
    stand_num: "2115",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2116",
    area: "594",
    centx: 31.09,
    centy: -17.7,
    price: 35640,
    province: "Harare",
    road: true,
    stand_num: "2116",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2117",
    area: "594",
    centx: 31.09,
    centy: -17.7,
    price: 35640,
    province: "Harare",
    road: true,
    stand_num: "2117",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2118",
    area: "594",
    centx: 31.09,
    centy: -17.7,
    price: 35640,
    province: "Harare",
    road: true,
    stand_num: "2118",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2119",
    area: "594",
    centx: 31.09,
    centy: -17.7,
    price: 35640,
    province: "Harare",
    road: true,
    stand_num: "2119",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2120",
    area: "869",
    centx: 31.09,
    centy: -17.7,
    price: 52140,
    province: "Harare",
    road: true,
    stand_num: "2120",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2121",
    area: "873",
    centx: 31.09,
    centy: -17.7,
    price: 52380,
    province: "Harare",
    road: true,
    stand_num: "2121",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2122",
    area: "594",
    centx: 31.09,
    centy: -17.7,
    price: 35640,
    province: "Harare",
    road: true,
    stand_num: "2122",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2123",
    area: "594",
    centx: 31.09,
    centy: -17.7,
    price: 35640,
    province: "Harare",
    road: true,
    stand_num: "2123",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2124",
    area: "833",
    centx: 31.09,
    centy: -17.7,
    price: 49980,
    province: "Harare",
    road: true,
    stand_num: "2124",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2125",
    area: "832",
    centx: 31.09,
    centy: -17.7,
    price: 49920,
    province: "Harare",
    road: true,
    stand_num: "2125",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2126",
    area: "594",
    centx: 31.09,
    centy: -17.7,
    price: 35640,
    province: "Harare",
    road: true,
    stand_num: "2126",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2127",
    area: "594",
    centx: 31.09,
    centy: -17.7,
    price: 35640,
    province: "Harare",
    road: true,
    stand_num: "2127",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2128",
    area: "594",
    centx: 31.09,
    centy: -17.7,
    price: 35640,
    province: "Harare",
    road: true,
    stand_num: "2128",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2129",
    area: "706",
    centx: 31.09,
    centy: -17.7,
    price: 42360,
    province: "Harare",
    road: true,
    stand_num: "2129",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2130",
    area: "869",
    centx: 31.09,
    centy: -17.7,
    price: 52140,
    province: "Harare",
    road: true,
    stand_num: "2130",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2131",
    area: "594",
    centx: 31.09,
    centy: -17.7,
    price: 35640,
    province: "Harare",
    road: true,
    stand_num: "2131",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2132",
    area: "594",
    centx: 31.09,
    centy: -17.7,
    price: 35640,
    province: "Harare",
    road: true,
    stand_num: "2132",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2133",
    area: "594",
    centx: 31.09,
    centy: -17.7,
    price: 35640,
    province: "Harare",
    road: true,
    stand_num: "2133",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2134",
    area: "594",
    centx: 31.09,
    centy: -17.7,
    price: 35640,
    province: "Harare",
    road: true,
    stand_num: "2134",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2135",
    area: "701",
    centx: 31.09,
    centy: -17.7,
    price: 42060,
    province: "Harare",
    road: true,
    stand_num: "2135",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2136",
    area: "594",
    centx: 31.09,
    centy: -17.7,
    price: 35640,
    province: "Harare",
    road: true,
    stand_num: "2136",
    status: "available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2137",
    area: "594",
    centx: 31.09,
    centy: -17.7,
    price: 35640,
    province: "Harare",
    road: true,
    stand_num: "2137",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2139",
    area: "594",
    centx: 31.09,
    centy: -17.7,
    price: 35640,
    province: "Harare",
    road: true,
    stand_num: "2139",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2140",
    area: "594",
    centx: 31.09,
    centy: -17.7,
    price: 35640,
    province: "Harare",
    road: true,
    stand_num: "2140",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2141",
    area: "701",
    centx: 31.09,
    centy: -17.7,
    price: 42060,
    province: "Harare",
    road: true,
    stand_num: "2141",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2142",
    area: "707",
    centx: 31.09,
    centy: -17.7,
    price: 42420,
    province: "Harare",
    road: true,
    stand_num: "2142",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2143",
    area: "594",
    centx: 31.09,
    centy: -17.7,
    price: 35640,
    province: "Harare",
    road: true,
    stand_num: "2143",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2144",
    area: "594",
    centx: 31.09,
    centy: -17.7,
    price: 35640,
    province: "Harare",
    road: true,
    stand_num: "2144",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2145",
    area: "594",
    centx: 31.09,
    centy: -17.7,
    price: 35640,
    province: "Harare",
    road: true,
    stand_num: "2145",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2146",
    area: "832",
    centx: 31.09,
    centy: -17.7,
    price: 49920,
    province: "Harare",
    road: true,
    stand_num: "2146",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2147",
    area: "832",
    centx: 31.09,
    centy: -17.7,
    price: 49920,
    province: "Harare",
    road: true,
    stand_num: "2147",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2148",
    area: "594",
    centx: 31.09,
    centy: -17.7,
    price: 35640,
    province: "Harare",
    road: true,
    stand_num: "2148",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2149",
    area: "594",
    centx: 31.09,
    centy: -17.7,
    price: 35640,
    province: "Harare",
    road: true,
    stand_num: "2149",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2150",
    area: "873",
    centx: 31.09,
    centy: -17.7,
    price: 52380,
    province: "Harare",
    road: true,
    stand_num: "2150",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2151",
    area: "2553",
    centx: 31.09,
    centy: -17.7,
    price: 153180,
    province: "Harare",
    road: true,
    stand_num: "2151",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2152",
    area: "1470",
    centx: 31.09,
    centy: -17.7,
    price: 88200,
    province: "Harare",
    road: true,
    stand_num: "2152",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2153",
    area: "625",
    centx: 31.09,
    centy: -17.7,
    price: 37500,
    province: "Harare",
    road: true,
    stand_num: "2153",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2154",
    area: "594",
    centx: 31.09,
    centy: -17.7,
    price: 35640,
    province: "Harare",
    road: true,
    stand_num: "2154",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2155",
    area: "594",
    centx: 31.09,
    centy: -17.7,
    price: 35640,
    province: "Harare",
    road: true,
    stand_num: "2155",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2156",
    area: "594",
    centx: 31.09,
    centy: -17.7,
    price: 35640,
    province: "Harare",
    road: true,
    stand_num: "2156",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2157",
    area: "594",
    centx: 31.09,
    centy: -17.7,
    price: 35640,
    province: "Harare",
    road: true,
    stand_num: "2157",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2158",
    area: "594",
    centx: 31.09,
    centy: -17.7,
    price: 35640,
    province: "Harare",
    road: true,
    stand_num: "2158",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2159",
    area: "594",
    centx: 31.09,
    centy: -17.7,
    price: 35640,
    province: "Harare",
    road: true,
    stand_num: "2159",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2160",
    area: "594",
    centx: 31.09,
    centy: -17.7,
    price: 35640,
    province: "Harare",
    road: true,
    stand_num: "2160",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2161",
    area: "594",
    centx: 31.09,
    centy: -17.7,
    price: 35640,
    province: "Harare",
    road: true,
    stand_num: "2161",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2162",
    area: "594",
    centx: 31.09,
    centy: -17.7,
    price: 35640,
    province: "Harare",
    road: true,
    stand_num: "2162",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2163",
    area: "620",
    centx: 31.09,
    centy: -17.7,
    price: 37200,
    province: "Harare",
    road: true,
    stand_num: "2163",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2164",
    area: "687",
    centx: 31.09,
    centy: -17.7,
    price: 41220,
    province: "Harare",
    road: true,
    stand_num: "2164",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2165",
    area: "594",
    centx: 31.09,
    centy: -17.7,
    price: 35640,
    province: "Harare",
    road: true,
    stand_num: "2165",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2166",
    area: "594",
    centx: 31.09,
    centy: -17.7,
    price: 35640,
    province: "Harare",
    road: true,
    stand_num: "2166",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2167",
    area: "594",
    centx: 31.09,
    centy: -17.7,
    price: 35640,
    province: "Harare",
    road: true,
    stand_num: "2167",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2168",
    area: "594",
    centx: 31.09,
    centy: -17.7,
    price: 35640,
    province: "Harare",
    road: true,
    stand_num: "2168",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2169",
    area: "594",
    centx: 31.09,
    centy: -17.7,
    price: 35640,
    province: "Harare",
    road: true,
    stand_num: "2169",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2170",
    area: "594",
    centx: 31.09,
    centy: -17.7,
    price: 35640,
    province: "Harare",
    road: true,
    stand_num: "2170",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2171",
    area: "696",
    centx: 31.09,
    centy: -17.7,
    price: 41760,
    province: "Harare",
    road: true,
    stand_num: "2171",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2172",
    area: "857",
    centx: 31.09,
    centy: -17.7,
    price: 51420,
    province: "Harare",
    road: true,
    stand_num: "2172",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2173",
    area: "594",
    centx: 31.09,
    centy: -17.7,
    price: 35640,
    province: "Harare",
    road: true,
    stand_num: "2173",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2174",
    area: "594",
    centx: 31.09,
    centy: -17.7,
    price: 35640,
    province: "Harare",
    road: true,
    stand_num: "2174",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2175",
    area: "594",
    centx: 31.09,
    centy: -17.7,
    price: 35640,
    province: "Harare",
    road: true,
    stand_num: "2175",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2176",
    area: "594",
    centx: 31.09,
    centy: -17.7,
    price: 35640,
    province: "Harare",
    road: true,
    stand_num: "2176",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2177",
    area: "594",
    centx: 31.09,
    centy: -17.7,
    price: 35640,
    province: "Harare",
    road: true,
    stand_num: "2177",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2178",
    area: "594",
    centx: 31.09,
    centy: -17.7,
    price: 35640,
    province: "Harare",
    road: true,
    stand_num: "2178",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2179",
    area: "647",
    centx: 31.09,
    centy: -17.7,
    price: 38820,
    province: "Harare",
    road: true,
    stand_num: "2179",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2180",
    area: "647",
    centx: 31.09,
    centy: -17.7,
    price: 38820,
    province: "Harare",
    road: true,
    stand_num: "2180",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2181",
    area: "594",
    centx: 31.09,
    centy: -17.7,
    price: 35640,
    province: "Harare",
    road: true,
    stand_num: "2181",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2182",
    area: "594",
    centx: 31.09,
    centy: -17.7,
    price: 35640,
    province: "Harare",
    road: true,
    stand_num: "2182",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2183",
    area: "594",
    centx: 31.09,
    centy: -17.7,
    price: 35640,
    province: "Harare",
    road: true,
    stand_num: "2183",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2184",
    area: "594",
    centx: 31.09,
    centy: -17.7,
    price: 35640,
    province: "Harare",
    road: true,
    stand_num: "2184",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2185",
    area: "594",
    centx: 31.09,
    centy: -17.7,
    price: 35640,
    province: "Harare",
    road: true,
    stand_num: "2185",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2186",
    area: "594",
    centx: 31.09,
    centy: -17.7,
    price: 35640,
    province: "Harare",
    road: true,
    stand_num: "2186",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2187",
    area: "594",
    centx: 31.09,
    centy: -17.7,
    price: 35640,
    province: "Harare",
    road: true,
    stand_num: "2187",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2188",
    area: "729",
    centx: 31.09,
    centy: -17.7,
    price: 43740,
    province: "Harare",
    road: true,
    stand_num: "2188",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2189",
    area: "2694",
    centx: 31.09,
    centy: -17.7,
    price: 161640,
    province: "Harare",
    road: true,
    stand_num: "2189",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2190",
    area: "500",
    centx: 31.09,
    centy: -17.7,
    price: 30000,
    province: "Harare",
    road: true,
    stand_num: "2190",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2191",
    area: "500",
    centx: 31.09,
    centy: -17.7,
    price: 30000,
    province: "Harare",
    road: true,
    stand_num: "2191",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2192",
    area: "500",
    centx: 31.09,
    centy: -17.7,
    price: 30000,
    province: "Harare",
    road: true,
    stand_num: "2192",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2193",
    area: "500",
    centx: 31.09,
    centy: -17.7,
    price: 30000,
    province: "Harare",
    road: true,
    stand_num: "2193",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2194",
    area: "500",
    centx: 31.09,
    centy: -17.7,
    price: 30000,
    province: "Harare",
    road: true,
    stand_num: "2194",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2195",
    area: "500",
    centx: 31.09,
    centy: -17.7,
    price: 30000,
    province: "Harare",
    road: true,
    stand_num: "2195",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2196",
    area: "500",
    centx: 31.1,
    centy: -17.7,
    price: 30000,
    province: "Harare",
    road: true,
    stand_num: "2196",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2197",
    area: "500",
    centx: 31.1,
    centy: -17.7,
    price: 30000,
    province: "Harare",
    road: true,
    stand_num: "2197",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2198",
    area: "500",
    centx: 31.1,
    centy: -17.7,
    price: 30000,
    province: "Harare",
    road: true,
    stand_num: "2198",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2199",
    area: "500",
    centx: 31.1,
    centy: -17.7,
    price: 30000,
    province: "Harare",
    road: true,
    stand_num: "2199",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2200",
    area: "500",
    centx: 31.1,
    centy: -17.7,
    price: 30000,
    province: "Harare",
    road: true,
    stand_num: "2200",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2201",
    area: "500",
    centx: 31.1,
    centy: -17.7,
    price: 30000,
    province: "Harare",
    road: true,
    stand_num: "2201",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2202",
    area: "500",
    centx: 31.1,
    centy: -17.7,
    price: 30000,
    province: "Harare",
    road: true,
    stand_num: "2202",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2203",
    area: "597",
    centx: 31.1,
    centy: -17.7,
    price: 35820,
    province: "Harare",
    road: true,
    stand_num: "2203",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2204",
    area: "597",
    centx: 31.1,
    centy: -17.7,
    price: 35820,
    province: "Harare",
    road: true,
    stand_num: "2204",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2205",
    area: "500",
    centx: 31.1,
    centy: -17.7,
    price: 30000,
    province: "Harare",
    road: true,
    stand_num: "2205",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2206",
    area: "500",
    centx: 31.1,
    centy: -17.7,
    price: 30000,
    province: "Harare",
    road: true,
    stand_num: "2206",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2207",
    area: "500",
    centx: 31.1,
    centy: -17.7,
    price: 30000,
    province: "Harare",
    road: true,
    stand_num: "2207",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2208",
    area: "500",
    centx: 31.1,
    centy: -17.7,
    price: 30000,
    province: "Harare",
    road: true,
    stand_num: "2208",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2209",
    area: "500",
    centx: 31.1,
    centy: -17.7,
    price: 30000,
    province: "Harare",
    road: true,
    stand_num: "2209",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2210",
    area: "500",
    centx: 31.1,
    centy: -17.7,
    price: 30000,
    province: "Harare",
    road: true,
    stand_num: "2210",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2211",
    area: "500",
    centx: 31.1,
    centy: -17.7,
    price: 30000,
    province: "Harare",
    road: true,
    stand_num: "2211",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2212",
    area: "500",
    centx: 31.09,
    centy: -17.7,
    price: 30000,
    province: "Harare",
    road: true,
    stand_num: "2212",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2213",
    area: "500",
    centx: 31.09,
    centy: -17.7,
    price: 30000,
    province: "Harare",
    road: true,
    stand_num: "2213",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2214",
    area: "500",
    centx: 31.09,
    centy: -17.7,
    price: 30000,
    province: "Harare",
    road: true,
    stand_num: "2214",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2215",
    area: "500",
    centx: 31.09,
    centy: -17.7,
    price: 30000,
    province: "Harare",
    road: true,
    stand_num: "2215",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2216",
    area: "500",
    centx: 31.09,
    centy: -17.7,
    price: 30000,
    province: "Harare",
    road: true,
    stand_num: "2216",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2217",
    area: "500",
    centx: 31.09,
    centy: -17.7,
    price: 30000,
    province: "Harare",
    road: true,
    stand_num: "2217",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2218",
    area: "733",
    centx: 31.09,
    centy: -17.7,
    price: 43980,
    province: "Harare",
    road: true,
    stand_num: "2218",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2219",
    area: "616",
    centx: 31.09,
    centy: -17.7,
    price: 36960,
    province: "Harare",
    road: true,
    stand_num: "2219",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2220",
    area: "616",
    centx: 31.09,
    centy: -17.7,
    price: 36960,
    province: "Harare",
    road: true,
    stand_num: "2220",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2221",
    area: "616",
    centx: 31.09,
    centy: -17.7,
    price: 36960,
    province: "Harare",
    road: true,
    stand_num: "2221",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2222",
    area: "616",
    centx: 31.1,
    centy: -17.7,
    price: 36960,
    province: "Harare",
    road: true,
    stand_num: "2222",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2223",
    area: "616",
    centx: 31.1,
    centy: -17.7,
    price: 36960,
    province: "Harare",
    road: true,
    stand_num: "2223",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2224",
    area: "616",
    centx: 31.1,
    centy: -17.7,
    price: 36960,
    province: "Harare",
    road: true,
    stand_num: "2224",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2225",
    area: "616",
    centx: 31.1,
    centy: -17.7,
    price: 36960,
    province: "Harare",
    road: true,
    stand_num: "2225",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2226",
    area: "733",
    centx: 31.1,
    centy: -17.7,
    price: 43980,
    province: "Harare",
    road: true,
    stand_num: "2226",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2227",
    area: "738",
    centx: 31.1,
    centy: -17.7,
    price: 44280,
    province: "Harare",
    road: true,
    stand_num: "2227",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2228",
    area: "616",
    centx: 31.1,
    centy: -17.7,
    price: 36960,
    province: "Harare",
    road: true,
    stand_num: "2228",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2229",
    area: "616",
    centx: 31.1,
    centy: -17.7,
    price: 36960,
    province: "Harare",
    road: true,
    stand_num: "2229",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2230",
    area: "616",
    centx: 31.1,
    centy: -17.7,
    price: 36960,
    province: "Harare",
    road: true,
    stand_num: "2230",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2231",
    area: "616",
    centx: 31.1,
    centy: -17.7,
    price: 36960,
    province: "Harare",
    road: true,
    stand_num: "2231",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2232",
    area: "616",
    centx: 31.1,
    centy: -17.7,
    price: 36960,
    province: "Harare",
    road: true,
    stand_num: "2232",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2233",
    area: "616",
    centx: 31.09,
    centy: -17.7,
    price: 36960,
    province: "Harare",
    road: true,
    stand_num: "2233",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2234",
    area: "616",
    centx: 31.09,
    centy: -17.7,
    price: 36960,
    province: "Harare",
    road: true,
    stand_num: "2234",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2235",
    area: "738",
    centx: 31.09,
    centy: -17.7,
    price: 44280,
    province: "Harare",
    road: true,
    stand_num: "2235",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2236",
    area: "760",
    centx: 31.09,
    centy: -17.7,
    price: 45600,
    province: "Harare",
    road: true,
    stand_num: "2236",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2237",
    area: "594",
    centx: 31.09,
    centy: -17.7,
    price: 35640,
    province: "Harare",
    road: true,
    stand_num: "2237",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2238",
    area: "832",
    centx: 31.09,
    centy: -17.7,
    price: 49920,
    province: "Harare",
    road: true,
    stand_num: "2238",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2239",
    area: "833",
    centx: 31.09,
    centy: -17.7,
    price: 49980,
    province: "Harare",
    road: true,
    stand_num: "2239",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2240",
    area: "594",
    centx: 31.09,
    centy: -17.7,
    price: 35640,
    province: "Harare",
    road: true,
    stand_num: "2240",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2241",
    area: "594",
    centx: 31.09,
    centy: -17.7,
    price: 35640,
    province: "Harare",
    road: true,
    stand_num: "2241",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2242",
    area: "594",
    centx: 31.09,
    centy: -17.7,
    price: 35640,
    province: "Harare",
    road: true,
    stand_num: "2242",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2243",
    area: "594",
    centx: 31.1,
    centy: -17.7,
    price: 35640,
    province: "Harare",
    road: true,
    stand_num: "2243",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2244",
    area: "594",
    centx: 31.1,
    centy: -17.7,
    price: 35640,
    province: "Harare",
    road: true,
    stand_num: "2244",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2245",
    area: "594",
    centx: 31.1,
    centy: -17.7,
    price: 35640,
    province: "Harare",
    road: true,
    stand_num: "2245",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2246",
    area: "594",
    centx: 31.1,
    centy: -17.7,
    price: 35640,
    province: "Harare",
    road: true,
    stand_num: "2246",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2247",
    area: "594",
    centx: 31.1,
    centy: -17.7,
    price: 35640,
    province: "Harare",
    road: true,
    stand_num: "2247",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2248",
    area: "594",
    centx: 31.1,
    centy: -17.7,
    price: 35640,
    province: "Harare",
    road: true,
    stand_num: "2248",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2249",
    area: "833",
    centx: 31.1,
    centy: -17.7,
    price: 49980,
    province: "Harare",
    road: true,
    stand_num: "2249",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2250",
    area: "833",
    centx: 31.1,
    centy: -17.7,
    price: 49980,
    province: "Harare",
    road: true,
    stand_num: "2250",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2251",
    area: "594",
    centx: 31.1,
    centy: -17.7,
    price: 35640,
    province: "Harare",
    road: true,
    stand_num: "2251",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2252",
    area: "761",
    centx: 31.1,
    centy: -17.7,
    price: 45660,
    province: "Harare",
    road: true,
    stand_num: "2252",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2253",
    area: "755",
    centx: 31.1,
    centy: -17.7,
    price: 45300,
    province: "Harare",
    road: true,
    stand_num: "2253",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2254",
    area: "594",
    centx: 31.1,
    centy: -17.7,
    price: 35640,
    province: "Harare",
    road: true,
    stand_num: "2254",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2255",
    area: "594",
    centx: 31.1,
    centy: -17.7,
    price: 35640,
    province: "Harare",
    road: true,
    stand_num: "2255",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2256",
    area: "594",
    centx: 31.1,
    centy: -17.7,
    price: 35640,
    province: "Harare",
    road: true,
    stand_num: "2256",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2257",
    area: "701",
    centx: 31.1,
    centy: -17.7,
    price: 42060,
    province: "Harare",
    road: true,
    stand_num: "2257",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2258",
    area: "594",
    centx: 31.1,
    centy: -17.7,
    price: 35640,
    province: "Harare",
    road: true,
    stand_num: "2258",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2259",
    area: "594",
    centx: 31.1,
    centy: -17.7,
    price: 35640,
    province: "Harare",
    road: true,
    stand_num: "2259",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2260",
    area: "594",
    centx: 31.1,
    centy: -17.7,
    price: 35640,
    province: "Harare",
    road: true,
    stand_num: "2260",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2261",
    area: "594",
    centx: 31.1,
    centy: -17.7,
    price: 35640,
    province: "Harare",
    road: true,
    stand_num: "2261",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2262",
    area: "594",
    centx: 31.1,
    centy: -17.7,
    price: 35640,
    province: "Harare",
    road: true,
    stand_num: "2262",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2263",
    area: "594",
    centx: 31.1,
    centy: -17.7,
    price: 35640,
    province: "Harare",
    road: true,
    stand_num: "2263",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2264",
    area: "594",
    centx: 31.1,
    centy: -17.7,
    price: 35640,
    province: "Harare",
    road: true,
    stand_num: "2264",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2265",
    area: "594",
    centx: 31.1,
    centy: -17.7,
    price: 35640,
    province: "Harare",
    road: true,
    stand_num: "2265",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2266",
    area: "594",
    centx: 31.1,
    centy: -17.7,
    price: 35640,
    province: "Harare",
    road: true,
    stand_num: "2266",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2267",
    area: "594",
    centx: 31.09,
    centy: -17.7,
    price: 35640,
    province: "Harare",
    road: true,
    stand_num: "2267",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2268",
    area: "594",
    centx: 31.09,
    centy: -17.7,
    price: 35640,
    province: "Harare",
    road: true,
    stand_num: "2268",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2269",
    area: "594",
    centx: 31.09,
    centy: -17.7,
    price: 35640,
    province: "Harare",
    road: true,
    stand_num: "2269",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2270",
    area: "594",
    centx: 31.09,
    centy: -17.7,
    price: 35640,
    province: "Harare",
    road: true,
    stand_num: "2270",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2271",
    area: "701",
    centx: 31.09,
    centy: -17.7,
    price: 42060,
    province: "Harare",
    road: true,
    stand_num: "2271",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2272",
    area: "594",
    centx: 31.09,
    centy: -17.7,
    price: 35640,
    province: "Harare",
    road: true,
    stand_num: "2272",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2273",
    area: "594",
    centx: 31.09,
    centy: -17.7,
    price: 35640,
    province: "Harare",
    road: true,
    stand_num: "2273",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2274",
    area: "594",
    centx: 31.09,
    centy: -17.7,
    price: 35640,
    province: "Harare",
    road: true,
    stand_num: "2274",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2275",
    area: "755",
    centx: 31.09,
    centy: -17.7,
    price: 45300,
    province: "Harare",
    road: true,
    stand_num: "2275",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2276",
    area: "679",
    centx: 31.1,
    centy: -17.7,
    price: 40740,
    province: "Harare",
    road: true,
    stand_num: "2276",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2277",
    area: "594",
    centx: 31.1,
    centy: -17.7,
    price: 35640,
    province: "Harare",
    road: true,
    stand_num: "2277",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2278",
    area: "594",
    centx: 31.1,
    centy: -17.7,
    price: 35640,
    province: "Harare",
    road: true,
    stand_num: "2278",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2279",
    area: "594",
    centx: 31.1,
    centy: -17.7,
    price: 35640,
    province: "Harare",
    road: true,
    stand_num: "2279",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2280",
    area: "594",
    centx: 31.1,
    centy: -17.71,
    price: 35640,
    province: "Harare",
    road: true,
    stand_num: "2280",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2281",
    area: "702",
    centx: 31.1,
    centy: -17.71,
    price: 42120,
    province: "Harare",
    road: true,
    stand_num: "2281",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2282",
    area: "702",
    centx: 31.1,
    centy: -17.71,
    price: 42120,
    province: "Harare",
    road: true,
    stand_num: "2282",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2283",
    area: "594",
    centx: 31.1,
    centy: -17.71,
    price: 35640,
    province: "Harare",
    road: true,
    stand_num: "2283",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2284",
    area: "594",
    centx: 31.1,
    centy: -17.7,
    price: 35640,
    province: "Harare",
    road: true,
    stand_num: "2284",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2285",
    area: "594",
    centx: 31.1,
    centy: -17.7,
    price: 35640,
    province: "Harare",
    road: true,
    stand_num: "2285",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2286",
    area: "594",
    centx: 31.1,
    centy: -17.7,
    price: 35640,
    province: "Harare",
    road: true,
    stand_num: "2286",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2287",
    area: "679",
    centx: 31.1,
    centy: -17.7,
    price: 40740,
    province: "Harare",
    road: true,
    stand_num: "2287",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2288",
    area: "814",
    centx: 31.1,
    centy: -17.7,
    price: 48840,
    province: "Harare",
    road: true,
    stand_num: "2288",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2289",
    area: "594",
    centx: 31.1,
    centy: -17.7,
    price: 35640,
    province: "Harare",
    road: true,
    stand_num: "2289",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2290",
    area: "594",
    centx: 31.1,
    centy: -17.7,
    price: 35640,
    province: "Harare",
    road: true,
    stand_num: "2290",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2291",
    area: "594",
    centx: 31.1,
    centy: -17.7,
    price: 35640,
    province: "Harare",
    road: true,
    stand_num: "2291",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2292",
    area: "593",
    centx: 31.1,
    centy: -17.7,
    price: 35580,
    province: "Harare",
    road: true,
    stand_num: "2292",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2293",
    area: "594",
    centx: 31.1,
    centy: -17.7,
    price: 35640,
    province: "Harare",
    road: true,
    stand_num: "2293",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2294",
    area: "594",
    centx: 31.1,
    centy: -17.7,
    price: 35640,
    province: "Harare",
    road: true,
    stand_num: "2294",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2295",
    area: "594",
    centx: 31.1,
    centy: -17.7,
    price: 35640,
    province: "Harare",
    road: true,
    stand_num: "2295",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2296",
    area: "594",
    centx: 31.1,
    centy: -17.7,
    price: 35640,
    province: "Harare",
    road: true,
    stand_num: "2296",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2297",
    area: "594",
    centx: 31.1,
    centy: -17.7,
    price: 35640,
    province: "Harare",
    road: true,
    stand_num: "2297",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2298",
    area: "594",
    centx: 31.1,
    centy: -17.7,
    price: 35640,
    province: "Harare",
    road: true,
    stand_num: "2298",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2299",
    area: "594",
    centx: 31.1,
    centy: -17.7,
    price: 35640,
    province: "Harare",
    road: true,
    stand_num: "2299",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2300",
    area: "594",
    centx: 31.1,
    centy: -17.7,
    price: 35640,
    province: "Harare",
    road: true,
    stand_num: "2300",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2301",
    area: "594",
    centx: 31.1,
    centy: -17.7,
    price: 35640,
    province: "Harare",
    road: true,
    stand_num: "2301",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2302",
    area: "594",
    centx: 31.1,
    centy: -17.7,
    price: 35640,
    province: "Harare",
    road: true,
    stand_num: "2302",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2303",
    area: "594",
    centx: 31.1,
    centy: -17.7,
    price: 35640,
    province: "Harare",
    road: true,
    stand_num: "2303",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2304",
    area: "594",
    centx: 31.1,
    centy: -17.7,
    price: 35640,
    province: "Harare",
    road: true,
    stand_num: "2304",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2305",
    area: "594",
    centx: 31.1,
    centy: -17.7,
    price: 35640,
    province: "Harare",
    road: true,
    stand_num: "2305",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2306",
    area: "594",
    centx: 31.1,
    centy: -17.7,
    price: 35640,
    province: "Harare",
    road: true,
    stand_num: "2306",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2307",
    area: "594",
    centx: 31.1,
    centy: -17.7,
    price: 35640,
    province: "Harare",
    road: true,
    stand_num: "2307",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2308",
    area: "593",
    centx: 31.1,
    centy: -17.71,
    price: 35580,
    province: "Harare",
    road: true,
    stand_num: "2308",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2309",
    area: "598",
    centx: 31.1,
    centy: -17.71,
    price: 35880,
    province: "Harare",
    road: true,
    stand_num: "2309",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2310",
    area: "594",
    centx: 31.1,
    centy: -17.71,
    price: 35640,
    province: "Harare",
    road: true,
    stand_num: "2310",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2311",
    area: "594",
    centx: 31.1,
    centy: -17.71,
    price: 35640,
    province: "Harare",
    road: true,
    stand_num: "2311",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2312",
    area: "594",
    centx: 31.1,
    centy: -17.71,
    price: 35640,
    province: "Harare",
    road: true,
    stand_num: "2312",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2313",
    area: "594",
    centx: 31.1,
    centy: -17.71,
    price: 35640,
    province: "Harare",
    road: true,
    stand_num: "2313",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2314",
    area: "594",
    centx: 31.1,
    centy: -17.7,
    price: 35640,
    province: "Harare",
    road: true,
    stand_num: "2314",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2315",
    area: "594",
    centx: 31.1,
    centy: -17.7,
    price: 35640,
    province: "Harare",
    road: true,
    stand_num: "2315",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2316",
    area: "594",
    centx: 31.1,
    centy: -17.7,
    price: 35640,
    province: "Harare",
    road: true,
    stand_num: "2316",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2317",
    area: "594",
    centx: 31.1,
    centy: -17.7,
    price: 35640,
    province: "Harare",
    road: true,
    stand_num: "2317",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2318",
    area: "594",
    centx: 31.1,
    centy: -17.7,
    price: 35640,
    province: "Harare",
    road: true,
    stand_num: "2318",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2319",
    area: "594",
    centx: 31.1,
    centy: -17.7,
    price: 35640,
    province: "Harare",
    road: true,
    stand_num: "2319",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2320",
    area: "594",
    centx: 31.1,
    centy: -17.7,
    price: 35640,
    province: "Harare",
    road: true,
    stand_num: "2320",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2321",
    area: "594",
    centx: 31.1,
    centy: -17.7,
    price: 35640,
    province: "Harare",
    road: true,
    stand_num: "2321",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2322",
    area: "594",
    centx: 31.1,
    centy: -17.7,
    price: 35640,
    province: "Harare",
    road: true,
    stand_num: "2322",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2323",
    area: "724",
    centx: 31.1,
    centy: -17.7,
    price: 43440,
    province: "Harare",
    road: true,
    stand_num: "2323",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2324",
    area: "833",
    centx: 31.1,
    centy: -17.7,
    price: 49980,
    province: "Harare",
    road: true,
    stand_num: "2324",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2325",
    area: "594",
    centx: 31.1,
    centy: -17.7,
    price: 35640,
    province: "Harare",
    road: true,
    stand_num: "2325",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2326",
    area: "819",
    centx: 31.1,
    centy: -17.7,
    price: 49140,
    province: "Harare",
    road: true,
    stand_num: "2326",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2327",
    area: "707",
    centx: 31.1,
    centy: -17.7,
    price: 42420,
    province: "Harare",
    road: true,
    stand_num: "2327",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2328",
    area: "638",
    centx: 31.1,
    centy: -17.71,
    price: 38280,
    province: "Harare",
    road: true,
    stand_num: "2328",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2329",
    area: "638",
    centx: 31.1,
    centy: -17.71,
    price: 38280,
    province: "Harare",
    road: true,
    stand_num: "2329",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2330",
    area: "638",
    centx: 31.1,
    centy: -17.71,
    price: 38280,
    province: "Harare",
    road: true,
    stand_num: "2330",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2331",
    area: "638",
    centx: 31.1,
    centy: -17.71,
    price: 38280,
    province: "Harare",
    road: true,
    stand_num: "2331",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2332",
    area: "638",
    centx: 31.1,
    centy: -17.71,
    price: 38280,
    province: "Harare",
    road: true,
    stand_num: "2332",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2333",
    area: "638",
    centx: 31.1,
    centy: -17.71,
    price: 38280,
    province: "Harare",
    road: true,
    stand_num: "2333",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2334",
    area: "638",
    centx: 31.1,
    centy: -17.71,
    price: 38280,
    province: "Harare",
    road: true,
    stand_num: "2334",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2335",
    area: "638",
    centx: 31.1,
    centy: -17.71,
    price: 38280,
    province: "Harare",
    road: true,
    stand_num: "2335",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2336",
    area: "638",
    centx: 31.1,
    centy: -17.71,
    price: 38280,
    province: "Harare",
    road: true,
    stand_num: "2336",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2337",
    area: "707",
    centx: 31.1,
    centy: -17.71,
    price: 42420,
    province: "Harare",
    road: true,
    stand_num: "2337",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2338",
    area: "707",
    centx: 31.1,
    centy: -17.71,
    price: 42420,
    province: "Harare",
    road: true,
    stand_num: "2338",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2339",
    area: "638",
    centx: 31.1,
    centy: -17.71,
    price: 38280,
    province: "Harare",
    road: true,
    stand_num: "2339",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2340",
    area: "638",
    centx: 31.1,
    centy: -17.71,
    price: 38280,
    province: "Harare",
    road: true,
    stand_num: "2340",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2341",
    area: "638",
    centx: 31.1,
    centy: -17.71,
    price: 38280,
    province: "Harare",
    road: true,
    stand_num: "2341",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2342",
    area: "638",
    centx: 31.1,
    centy: -17.71,
    price: 38280,
    province: "Harare",
    road: true,
    stand_num: "2342",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2343",
    area: "638",
    centx: 31.1,
    centy: -17.71,
    price: 38280,
    province: "Harare",
    road: true,
    stand_num: "2343",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2344",
    area: "638",
    centx: 31.1,
    centy: -17.71,
    price: 38280,
    province: "Harare",
    road: true,
    stand_num: "2344",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2345",
    area: "638",
    centx: 31.1,
    centy: -17.7,
    price: 38280,
    province: "Harare",
    road: true,
    stand_num: "2345",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2346",
    area: "638",
    centx: 31.1,
    centy: -17.7,
    price: 38280,
    province: "Harare",
    road: true,
    stand_num: "2346",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2347",
    area: "638",
    centx: 31.1,
    centy: -17.7,
    price: 38280,
    province: "Harare",
    road: true,
    stand_num: "2347",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2348",
    area: "707",
    centx: 31.1,
    centy: -17.7,
    price: 42420,
    province: "Harare",
    road: true,
    stand_num: "2348",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2349",
    area: "752",
    centx: 31.1,
    centy: -17.71,
    price: 45120,
    province: "Harare",
    road: true,
    stand_num: "2349",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2350",
    area: "500",
    centx: 31.1,
    centy: -17.71,
    price: 30000,
    province: "Harare",
    road: true,
    stand_num: "2350",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2351",
    area: "500",
    centx: 31.1,
    centy: -17.71,
    price: 30000,
    province: "Harare",
    road: true,
    stand_num: "2351",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2352",
    area: "500",
    centx: 31.1,
    centy: -17.71,
    price: 30000,
    province: "Harare",
    road: true,
    stand_num: "2352",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2353",
    area: "500",
    centx: 31.1,
    centy: -17.71,
    price: 30000,
    province: "Harare",
    road: true,
    stand_num: "2353",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2354",
    area: "500",
    centx: 31.1,
    centy: -17.71,
    price: 30000,
    province: "Harare",
    road: true,
    stand_num: "2354",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2355",
    area: "500",
    centx: 31.1,
    centy: -17.71,
    price: 30000,
    province: "Harare",
    road: true,
    stand_num: "2355",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2356",
    area: "500",
    centx: 31.1,
    centy: -17.71,
    price: 30000,
    province: "Harare",
    road: true,
    stand_num: "2356",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2357",
    area: "500",
    centx: 31.1,
    centy: -17.71,
    price: 30000,
    province: "Harare",
    road: true,
    stand_num: "2357",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2358",
    area: "500",
    centx: 31.1,
    centy: -17.71,
    price: 30000,
    province: "Harare",
    road: true,
    stand_num: "2358",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2359",
    area: "500",
    centx: 31.1,
    centy: -17.71,
    price: 30000,
    province: "Harare",
    road: true,
    stand_num: "2359",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2360",
    area: "500",
    centx: 31.1,
    centy: -17.71,
    price: 30000,
    province: "Harare",
    road: true,
    stand_num: "2360",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2361",
    area: "500",
    centx: 31.1,
    centy: -17.71,
    price: 30000,
    province: "Harare",
    road: true,
    stand_num: "2361",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2362",
    area: "500",
    centx: 31.1,
    centy: -17.71,
    price: 30000,
    province: "Harare",
    road: true,
    stand_num: "2362",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2363",
    area: "500",
    centx: 31.1,
    centy: -17.71,
    price: 30000,
    province: "Harare",
    road: true,
    stand_num: "2363",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2364",
    area: "918",
    centx: 31.1,
    centy: -17.71,
    price: 55080,
    province: "Harare",
    road: true,
    stand_num: "2364",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2365",
    area: "750",
    centx: 31.1,
    centy: -17.71,
    price: 45000,
    province: "Harare",
    road: true,
    stand_num: "2365",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2366",
    area: "500",
    centx: 31.1,
    centy: -17.71,
    price: 30000,
    province: "Harare",
    road: true,
    stand_num: "2366",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2367",
    area: "582",
    centx: 31.1,
    centy: -17.71,
    price: 34920,
    province: "Harare",
    road: true,
    stand_num: "2367",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2368",
    area: "578",
    centx: 31.1,
    centy: -17.71,
    price: 34680,
    province: "Harare",
    road: true,
    stand_num: "2368",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2369",
    area: "500",
    centx: 31.1,
    centy: -17.71,
    price: 30000,
    province: "Harare",
    road: true,
    stand_num: "2369",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2370",
    area: "500",
    centx: 31.1,
    centy: -17.71,
    price: 30000,
    province: "Harare",
    road: true,
    stand_num: "2370",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2371",
    area: "500",
    centx: 31.1,
    centy: -17.71,
    price: 30000,
    province: "Harare",
    road: true,
    stand_num: "2371",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2372",
    area: "872",
    centx: 31.1,
    centy: -17.71,
    price: 52320,
    province: "Harare",
    road: true,
    stand_num: "2372",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2373",
    area: "500",
    centx: 31.1,
    centy: -17.71,
    price: 30000,
    province: "Harare",
    road: true,
    stand_num: "2373",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2374",
    area: "500",
    centx: 31.1,
    centy: -17.71,
    price: 30000,
    province: "Harare",
    road: true,
    stand_num: "2374",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2375",
    area: "500",
    centx: 31.1,
    centy: -17.71,
    price: 30000,
    province: "Harare",
    road: true,
    stand_num: "2375",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2376",
    area: "500",
    centx: 31.1,
    centy: -17.71,
    price: 30000,
    province: "Harare",
    road: true,
    stand_num: "2376",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2377",
    area: "500",
    centx: 31.1,
    centy: -17.71,
    price: 30000,
    province: "Harare",
    road: true,
    stand_num: "2377",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2378",
    area: "500",
    centx: 31.1,
    centy: -17.71,
    price: 30000,
    province: "Harare",
    road: true,
    stand_num: "2378",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2379",
    area: "500",
    centx: 31.1,
    centy: -17.71,
    price: 30000,
    province: "Harare",
    road: true,
    stand_num: "2379",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2380",
    area: "500",
    centx: 31.1,
    centy: -17.71,
    price: 30000,
    province: "Harare",
    road: true,
    stand_num: "2380",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2381",
    area: "500",
    centx: 31.1,
    centy: -17.71,
    price: 30000,
    province: "Harare",
    road: true,
    stand_num: "2381",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2382",
    area: "500",
    centx: 31.1,
    centy: -17.71,
    price: 30000,
    province: "Harare",
    road: true,
    stand_num: "2382",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2383",
    area: "500",
    centx: 31.1,
    centy: -17.71,
    price: 30000,
    province: "Harare",
    road: true,
    stand_num: "2383",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2384",
    area: "500",
    centx: 31.1,
    centy: -17.71,
    price: 30000,
    province: "Harare",
    road: true,
    stand_num: "2384",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2385",
    area: "500",
    centx: 31.1,
    centy: -17.71,
    price: 30000,
    province: "Harare",
    road: true,
    stand_num: "2385",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2386",
    area: "500",
    centx: 31.1,
    centy: -17.71,
    price: 30000,
    province: "Harare",
    road: true,
    stand_num: "2386",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2387",
    area: "500",
    centx: 31.1,
    centy: -17.71,
    price: 30000,
    province: "Harare",
    road: true,
    stand_num: "2387",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2388",
    area: "500",
    centx: 31.1,
    centy: -17.71,
    price: 30000,
    province: "Harare",
    road: true,
    stand_num: "2388",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2389",
    area: "747",
    centx: 31.1,
    centy: -17.71,
    price: 44820,
    province: "Harare",
    road: true,
    stand_num: "2389",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2390",
    area: "913",
    centx: 31.1,
    centy: -17.71,
    price: 54780,
    province: "Harare",
    road: true,
    stand_num: "2390",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2391",
    area: "500",
    centx: 31.1,
    centy: -17.71,
    price: 30000,
    province: "Harare",
    road: true,
    stand_num: "2391",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2392",
    area: "500",
    centx: 31.1,
    centy: -17.71,
    price: 30000,
    province: "Harare",
    road: true,
    stand_num: "2392",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2393",
    area: "500",
    centx: 31.1,
    centy: -17.71,
    price: 30000,
    province: "Harare",
    road: true,
    stand_num: "2393",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2394",
    area: "500",
    centx: 31.1,
    centy: -17.71,
    price: 30000,
    province: "Harare",
    road: true,
    stand_num: "2394",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2395",
    area: "500",
    centx: 31.1,
    centy: -17.71,
    price: 30000,
    province: "Harare",
    road: true,
    stand_num: "2395",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2396",
    area: "500",
    centx: 31.1,
    centy: -17.71,
    price: 30000,
    province: "Harare",
    road: true,
    stand_num: "2396",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2397",
    area: "500",
    centx: 31.1,
    centy: -17.71,
    price: 30000,
    province: "Harare",
    road: true,
    stand_num: "2397",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2398",
    area: "500",
    centx: 31.1,
    centy: -17.71,
    price: 30000,
    province: "Harare",
    road: true,
    stand_num: "2398",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2399",
    area: "500",
    centx: 31.1,
    centy: -17.71,
    price: 30000,
    province: "Harare",
    road: true,
    stand_num: "2399",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2400",
    area: "500",
    centx: 31.1,
    centy: -17.71,
    price: 30000,
    province: "Harare",
    road: true,
    stand_num: "2400",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2401",
    area: "500",
    centx: 31.1,
    centy: -17.71,
    price: 30000,
    province: "Harare",
    road: true,
    stand_num: "2401",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2402",
    area: "499",
    centx: 31.1,
    centy: -17.71,
    price: 29940,
    province: "Harare",
    road: true,
    stand_num: "2402",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2403",
    area: "694",
    centx: 31.1,
    centy: -17.71,
    price: 41640,
    province: "Harare",
    road: true,
    stand_num: "2403",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2404",
    area: "686",
    centx: 31.1,
    centy: -17.71,
    price: 41160,
    province: "Harare",
    road: true,
    stand_num: "2404",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2405",
    area: "500",
    centx: 31.1,
    centy: -17.71,
    price: 30000,
    province: "Harare",
    road: true,
    stand_num: "2405",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2406",
    area: "500",
    centx: 31.1,
    centy: -17.71,
    price: 30000,
    province: "Harare",
    road: true,
    stand_num: "2406",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2407",
    area: "500",
    centx: 31.1,
    centy: -17.71,
    price: 30000,
    province: "Harare",
    road: true,
    stand_num: "2407",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2408",
    area: "500",
    centx: 31.1,
    centy: -17.71,
    price: 30000,
    province: "Harare",
    road: true,
    stand_num: "2408",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2409",
    area: "500",
    centx: 31.1,
    centy: -17.71,
    price: 30000,
    province: "Harare",
    road: true,
    stand_num: "2409",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2411",
    area: "500",
    centx: 31.1,
    centy: -17.71,
    price: 30000,
    province: "Harare",
    road: true,
    stand_num: "2411",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2412",
    area: "500",
    centx: 31.1,
    centy: -17.71,
    price: 30000,
    province: "Harare",
    road: true,
    stand_num: "2412",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2413",
    area: "499",
    centx: 31.1,
    centy: -17.71,
    price: 29940,
    province: "Harare",
    road: true,
    stand_num: "2413",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2414",
    area: "499",
    centx: 31.1,
    centy: -17.71,
    price: 29940,
    province: "Harare",
    road: true,
    stand_num: "2414",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2415",
    area: "499",
    centx: 31.1,
    centy: -17.71,
    price: 29940,
    province: "Harare",
    road: true,
    stand_num: "2415",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2416",
    area: "510",
    centx: 31.1,
    centy: -17.71,
    price: 30600,
    province: "Harare",
    road: true,
    stand_num: "2416",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2914",
    area: "4267",
    centx: 31.1,
    centy: -17.7,
    price: 256020,
    province: "Harare",
    road: true,
    stand_num: "2914",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2915",
    area: "594",
    centx: 31.1,
    centy: -17.71,
    price: 35640,
    province: "Harare",
    road: true,
    stand_num: "2915",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2916",
    area: "594",
    centx: 31.1,
    centy: -17.71,
    price: 35640,
    province: "Harare",
    road: true,
    stand_num: "2916",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2917",
    area: "594",
    centx: 31.1,
    centy: -17.71,
    price: 35640,
    province: "Harare",
    road: true,
    stand_num: "2917",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2918",
    area: "594",
    centx: 31.1,
    centy: -17.71,
    price: 35640,
    province: "Harare",
    road: true,
    stand_num: "2918",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2919",
    area: "594",
    centx: 31.1,
    centy: -17.71,
    price: 35640,
    province: "Harare",
    road: true,
    stand_num: "2919",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2920",
    area: "594",
    centx: 31.1,
    centy: -17.71,
    price: 35640,
    province: "Harare",
    road: true,
    stand_num: "2920",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2921",
    area: "594",
    centx: 31.1,
    centy: -17.71,
    price: 35640,
    province: "Harare",
    road: true,
    stand_num: "2921",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2922",
    area: "594",
    centx: 31.1,
    centy: -17.71,
    price: 35640,
    province: "Harare",
    road: true,
    stand_num: "2922",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2923",
    area: "675",
    centx: 31.1,
    centy: -17.71,
    price: 40500,
    province: "Harare",
    road: true,
    stand_num: "2923",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2924",
    area: "637",
    centx: 31.1,
    centy: -17.71,
    price: 38220,
    province: "Harare",
    road: true,
    stand_num: "2924",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2925",
    area: "637",
    centx: 31.1,
    centy: -17.71,
    price: 38220,
    province: "Harare",
    road: true,
    stand_num: "2925",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2926",
    area: "675",
    centx: 31.1,
    centy: -17.71,
    price: 40500,
    province: "Harare",
    road: true,
    stand_num: "2926",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2927",
    area: "594",
    centx: 31.1,
    centy: -17.71,
    price: 35640,
    province: "Harare",
    road: true,
    stand_num: "2927",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2928",
    area: "594",
    centx: 31.1,
    centy: -17.71,
    price: 35640,
    province: "Harare",
    road: true,
    stand_num: "2928",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2929",
    area: "594",
    centx: 31.1,
    centy: -17.71,
    price: 35640,
    province: "Harare",
    road: true,
    stand_num: "2929",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2930",
    area: "594",
    centx: 31.1,
    centy: -17.71,
    price: 35640,
    province: "Harare",
    road: true,
    stand_num: "2930",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2931",
    area: "594",
    centx: 31.1,
    centy: -17.71,
    price: 35640,
    province: "Harare",
    road: true,
    stand_num: "2931",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2932",
    area: "594",
    centx: 31.1,
    centy: -17.71,
    price: 35640,
    province: "Harare",
    road: true,
    stand_num: "2932",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2933",
    area: "594",
    centx: 31.1,
    centy: -17.71,
    price: 35640,
    province: "Harare",
    road: true,
    stand_num: "2933",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2934",
    area: "594",
    centx: 31.1,
    centy: -17.71,
    price: 35640,
    province: "Harare",
    road: true,
    stand_num: "2934",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2935",
    area: "802",
    centx: 31.1,
    centy: -17.7,
    price: 48120,
    province: "Harare",
    road: true,
    stand_num: "2935",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2936",
    area: "802",
    centx: 31.1,
    centy: -17.7,
    price: 48120,
    province: "Harare",
    road: true,
    stand_num: "2936",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2937",
    area: "802",
    centx: 31.1,
    centy: -17.7,
    price: 48120,
    province: "Harare",
    road: true,
    stand_num: "2937",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2938",
    area: "802",
    centx: 31.1,
    centy: -17.7,
    price: 48120,
    province: "Harare",
    road: true,
    stand_num: "2938",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2939",
    area: "801",
    centx: 31.1,
    centy: -17.7,
    price: 48060,
    province: "Harare",
    road: true,
    stand_num: "2939",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2940",
    area: "801",
    centx: 31.1,
    centy: -17.7,
    price: 48060,
    province: "Harare",
    road: true,
    stand_num: "2940",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2941",
    area: "801",
    centx: 31.1,
    centy: -17.7,
    price: 48060,
    province: "Harare",
    road: true,
    stand_num: "2941",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2942",
    area: "801",
    centx: 31.1,
    centy: -17.7,
    price: 48060,
    province: "Harare",
    road: true,
    stand_num: "2942",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2943",
    area: "801",
    centx: 31.1,
    centy: -17.7,
    price: 48060,
    province: "Harare",
    road: true,
    stand_num: "2943",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2944",
    area: "801",
    centx: 31.1,
    centy: -17.7,
    price: 48060,
    province: "Harare",
    road: true,
    stand_num: "2944",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2945",
    area: "801",
    centx: 31.1,
    centy: -17.7,
    price: 48060,
    province: "Harare",
    road: true,
    stand_num: "2945",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2946",
    area: "801",
    centx: 31.1,
    centy: -17.7,
    price: 48060,
    province: "Harare",
    road: true,
    stand_num: "2946",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2947",
    area: "800",
    centx: 31.1,
    centy: -17.7,
    price: 48000,
    province: "Harare",
    road: true,
    stand_num: "2947",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2948",
    area: "800",
    centx: 31.1,
    centy: -17.7,
    price: 48000,
    province: "Harare",
    road: true,
    stand_num: "2948",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2949",
    area: "800",
    centx: 31.1,
    centy: -17.7,
    price: 48000,
    province: "Harare",
    road: true,
    stand_num: "2949",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2950",
    area: "5945",
    centx: 31.1,
    centy: -17.7,
    price: 356700,
    province: "Harare",
    road: true,
    stand_num: "2950",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2951",
    area: "800",
    centx: 31.1,
    centy: -17.7,
    price: 48000,
    province: "Harare",
    road: true,
    stand_num: "2951",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2952",
    area: "800",
    centx: 31.1,
    centy: -17.7,
    price: 48000,
    province: "Harare",
    road: true,
    stand_num: "2952",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2953",
    area: "800",
    centx: 31.1,
    centy: -17.7,
    price: 48000,
    province: "Harare",
    road: true,
    stand_num: "2953",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2954",
    area: "800",
    centx: 31.1,
    centy: -17.7,
    price: 48000,
    province: "Harare",
    road: true,
    stand_num: "2954",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2955",
    area: "800",
    centx: 31.1,
    centy: -17.7,
    price: 48000,
    province: "Harare",
    road: true,
    stand_num: "2955",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2956",
    area: "800",
    centx: 31.1,
    centy: -17.7,
    price: 48000,
    province: "Harare",
    road: true,
    stand_num: "2956",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2957",
    area: "800",
    centx: 31.1,
    centy: -17.7,
    price: 48000,
    province: "Harare",
    road: true,
    stand_num: "2957",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2958",
    area: "800",
    centx: 31.1,
    centy: -17.7,
    price: 48000,
    province: "Harare",
    road: true,
    stand_num: "2958",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2959",
    area: "800",
    centx: 31.1,
    centy: -17.7,
    price: 48000,
    province: "Harare",
    road: true,
    stand_num: "2959",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2960",
    area: "800",
    centx: 31.1,
    centy: -17.7,
    price: 48000,
    province: "Harare",
    road: true,
    stand_num: "2960",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2961",
    area: "800",
    centx: 31.09,
    centy: -17.7,
    price: 48000,
    province: "Harare",
    road: true,
    stand_num: "2961",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2962",
    area: "800",
    centx: 31.09,
    centy: -17.7,
    price: 48000,
    province: "Harare",
    road: true,
    stand_num: "2962",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2963",
    area: "800",
    centx: 31.09,
    centy: -17.7,
    price: 48000,
    province: "Harare",
    road: true,
    stand_num: "2963",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2964",
    area: "800",
    centx: 31.09,
    centy: -17.7,
    price: 48000,
    province: "Harare",
    road: true,
    stand_num: "2964",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2965",
    area: "797",
    centx: 31.09,
    centy: -17.7,
    price: 47820,
    province: "Harare",
    road: true,
    stand_num: "2965",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2966",
    area: "796",
    centx: 31.09,
    centy: -17.7,
    price: 47760,
    province: "Harare",
    road: true,
    stand_num: "2966",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2967",
    area: "796",
    centx: 31.09,
    centy: -17.7,
    price: 47760,
    province: "Harare",
    road: true,
    stand_num: "2967",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2968",
    area: "796",
    centx: 31.09,
    centy: -17.7,
    price: 47760,
    province: "Harare",
    road: true,
    stand_num: "2968",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2969",
    area: "796",
    centx: 31.09,
    centy: -17.7,
    price: 47760,
    province: "Harare",
    road: true,
    stand_num: "2969",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2970",
    area: "796",
    centx: 31.09,
    centy: -17.7,
    price: 47760,
    province: "Harare",
    road: true,
    stand_num: "2970",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2971",
    area: "796",
    centx: 31.09,
    centy: -17.7,
    price: 47760,
    province: "Harare",
    road: true,
    stand_num: "2971",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2972",
    area: "796",
    centx: 31.09,
    centy: -17.7,
    price: 47760,
    province: "Harare",
    road: true,
    stand_num: "2972",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2973",
    area: "796",
    centx: 31.09,
    centy: -17.7,
    price: 47760,
    province: "Harare",
    road: true,
    stand_num: "2973",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2974",
    area: "796",
    centx: 31.09,
    centy: -17.7,
    price: 47760,
    province: "Harare",
    road: true,
    stand_num: "2974",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2975",
    area: "796",
    centx: 31.09,
    centy: -17.7,
    price: 47760,
    province: "Harare",
    road: true,
    stand_num: "2975",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2976",
    area: "796",
    centx: 31.09,
    centy: -17.7,
    price: 47760,
    province: "Harare",
    road: true,
    stand_num: "2976",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2977",
    area: "796",
    centx: 31.09,
    centy: -17.7,
    price: 47760,
    province: "Harare",
    road: true,
    stand_num: "2977",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2978",
    area: "796",
    centx: 31.09,
    centy: -17.7,
    price: 47760,
    province: "Harare",
    road: true,
    stand_num: "2978",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2979",
    area: "796",
    centx: 31.09,
    centy: -17.7,
    price: 47760,
    province: "Harare",
    road: true,
    stand_num: "2979",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2980",
    area: "1069",
    centx: 31.09,
    centy: -17.7,
    price: 64140,
    province: "Harare",
    road: true,
    stand_num: "2980",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2981",
    area: "9613",
    centx: 31.09,
    centy: -17.7,
    price: 576780,
    province: "Harare",
    road: true,
    stand_num: "2981",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "2982",
    area: "1330",
    centx: 31.09,
    centy: -17.7,
    price: 79800,
    province: "Harare",
    road: true,
    stand_num: "2982",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
  {
    id: "4010",
    area: "500",
    centx: 31.1,
    centy: -17.71,
    price: 30000,
    province: "Harare",
    road: true,
    stand_num: "4010",
    status: "not_available",
    surburb: "Pomona",
    town: "Harare",
    water: true,
  },
];
