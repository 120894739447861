import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Department, ParticipantData, companyStaff, Enterprise, client } from 'app/models/enterprise-model';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { UserDataService } from './user-data.service';
import { RouteService } from './route.service';
import * as moment from 'moment';
import { Task } from 'app/models/task-model';
import { IntrojsService } from './introjs.service';

@Injectable({
  providedIn: 'root'
})

export class EntServiceService {
  dptStaff: any[];
  departments = [];
  projects = [];
  assets = [];
  hiredAssets = [];
  Rates = [];
  results;
  // staff: companyStaff[];
  staff: any[];
  clients: client[];
  myCompProfile: companyStaff;
  allUsers = [];
  allEntTasks: Observable<any[]>;
  setDepartment: any;
  allActions = [];
  BankTasks;
  ClientBankTasks;
  clientAllActions;
  outboundOut = [];
  clientAttens;
  allDptTask: { wksTasks: any; Upcoming: any[]; Outstanding: any[]; Current: any[]; ShortTerm: any[]; MediumTerm: any[]; LongTerm: any[]; };
  companyManagers: client;
  tasks: any[];
  staffTaskList: any[];
  staffTasks: any;
  trans: any[];
  usdFilteredtasks: any[];
  loading = false;

  constructor(public afs: AngularFirestore, private usd: UserDataService, public rs: RouteService, private tut: IntrojsService) {
    // this.checkProjects();
    let n: any;
    tut.tutotiral(n);
  }

  depts(id) {
    this.afs.collection('Enterprises').doc(id).collection<Department>('departments', ref => ref
      .orderBy('name', 'asc')).snapshotChanges().pipe(map(b => b.map(a => {
        const data = a.payload.doc.data() as any;
        data.id = a.payload.doc.id;
        return data;
      }))).subscribe(dpts => {
        dpts = dpts.filter(x => x !== null);
        this.departments = dpts;
      })
    return this.departments;
  }

  checkLegality(user) {
    const lgd = this.staff.find(usd => usd.id === this.usd.userId);
    if ((lgd?.hierarchy === 'Executive' && user?.hierarchy === 'Executive') || (lgd?.hierarchy === 'Executive' && user?.hierarchy === 'Middle management') || (lgd?.hierarchy === 'Executive' && user?.hierarchy === 'Operations') || (lgd?.hierarchy === 'Executive' && user?.hierarchy === 'None')  || (lgd?.hierarchy === 'Executive' && user?.hierarchy === '') || (lgd?.hierarchy === 'Executive' && !user?.hierarchy)) {
      this.rs.gotoStaff(user);
      this.clearView();
      console.log('Executive');
      return '';
    } else if ((lgd?.hierarchy === 'Middle management' && user?.hierarchy === 'Middle management') || (lgd?.hierarchy === 'Middle management' && user?.hierarchy === 'Operations') || (lgd?.hierarchy === 'Middle management' && user?.hierarchy === 'None')  || (lgd?.hierarchy === 'Middle management' && user?.hierarchy === '')) {
      this.rs.gotoStaff(user);
      this.clearView();
      console.log('Middle management');
      return '';
    } else if (lgd?.hierarchy === 'Operations' && user?.hierarchy === 'Operations') {
      this.rs.gotoStaff(user);
      this.clearView();
      console.log('Operations');
      return '';
    } else if (lgd?.hierarchy === 'Operations' && user?.hierarchy === '') {
      this.rs.gotoStaff(user);
      this.clearView();
      console.log('empty string');
      return '';
    } else {
      // console.log(lgd?.hierarchy, user?.hierarchy);
      this.clearView();
      return 'Access Denied';
    }
  }

  clearView() {
    setTimeout(() => {
      this.staff.forEach(element => {
        element.viewable = ''
      });
      this.usd.setContact.viewable = ''
    }, 4000);
  }

  getCompanyDpts(id) {
    let departments = [];
    this.afs.collection('Enterprises').doc(id).collection<Department>('departments', ref => ref
      .orderBy('name', 'asc')).snapshotChanges().pipe(map(b => b.map(a => {
        const data = a.payload.doc.data() as any;
        data.id = a.payload.doc.id;
        return data;
      }))).subscribe(dpts => {
        dpts = dpts.filter(x => x !== null);
        departments = dpts;
      })
    return departments;
  }

  getChampData(id, uid) {
    return this.afs.doc<ParticipantData>(`Enterprises/${id}/Participants/${uid}`).valueChanges().subscribe(staff => {
      return staff;
    });
  }

  getEntParts(id) {
    this.afs.collection<any>(`Enterprises/${id}/Participants`, ref => ref.orderBy('name', 'asc')).valueChanges()
      .subscribe(staff => {
        staff = staff.filter(x => x !== null);
        staff.forEach(element => {
          element.viewable = ''
        });
        this.staff = staff;
        const index = staff.findIndex(myCompProfile => myCompProfile.id === this.usd.userId);
        if (index > -1) {
          const value = staff[index];
          this.myCompProfile = value;
        }
        // console.log(staff);
      });
    // console.log(this.staff);
    return this.staff;
  }

  async checkProjects() {
    this.projects = this.projects.filter(x => x !== null);
    this.projects.forEach(pj => {
      // console.log(pj.name);
      const exist = this.usd.projects.find((item) => item.id === pj.id);
      const entExist = this.usd.projects.findIndex((item) => item.id === pj.id);
      if (exist) {
        // this.projects[entExist].join = true;
        pj.join = true;
        // console.log('true');
      } else {
        // console.log('false');
        // this.projects[entExist].join = false;
        pj.join = false;
      }
    });
  }

  colors(word, p) {
    this.afs.collection<any>('Users').valueChanges().subscribe(allUsers => {
      // this.afs.collection<any>('Users', ref => ref.where('name', '>=', word)).valueChanges().subscribe(allUsers => {
      if (p === 'Enterprise') {
        this.serchEntColors(allUsers, word);
      } else {
        console.log('non');
      }
    });
  }

  serchEntColors(allUsers, value) {
    let word = value; const results = [];
    allUsers.forEach(man => {
      if (man.name) {
        // man.name = man.name.toLowerCase();
        const name = man?.name?.toLowerCase();
        if (word !== '' || ' ') {
          word = word.toLowerCase();
          if (name?.includes(word)) {
            // man.name = man.name.charAt(0).toUpperCase() + man.name.slice(1);
            results.push(man);
            console.log(results);
            this.allUsers = results;
            const exist = this.staff.find((item) => item.id === man.id);
            if (exist) {
              man.join = true;
              console.log('true');
            } else {
              console.log('false');
              man.join = false;
            }
          }
        }
      }
      return this.allUsers;
    });
  }

  async getTaskData() {
    return await this.staff
  }

  
  updateTask(setTask) {
    // setTask.update = new Date().toISOString();
    console.log(`${setTask.description} added`)
    if (setTask.champion?.id) {
        // console.log('document updated');
        if (setTask.companyId !== '') {
          const compRefII = this.afs.doc(`tasks/${setTask.id}`);
          compRefII.update(setTask).then(() => {
            // console.log('document updated');
            if (setTask.champCompany && setTask.champCompany?.id !== '') {
              this.afs.doc(`tasks/${setTask.id}`).set(setTask).then(() => {
                // console.log('compChamp updated');
              }).catch(err => console.log(err));
            }
          }).catch((error) => {
            compRefII.set(setTask).then(() => {
            })
            // console.log('Error updating Completion, document does not exists trying Again', error);
          });
        }
        /* ----------------------- Set every Other Node --------------------------- */
        this.afs.collection('tasks').doc(setTask.id).update(setTask).then(() => {
          // console.log('document updated');
        }).catch((error) => {
          this.afs.collection('tasks').doc(setTask.id).set(setTask).then(() => {
          })
          // console.log('Error updating Completion, document does not exists trying Again', error);
        });
        /* --------------------- End Set every Other Node -------------------------- */
    }
  }
  
  getComptasks(x) {
    // console.log("yeah we initiated")
    const entDoc = this.usd.setCompany;
    const champComp = this.afs.collection<Task>(`tasks`, ref => ref.where('champCompany.id', '==', entDoc.id)).snapshotChanges().pipe(map(b => b.map(a => {
      const data = a.payload.doc.data() as any;
      if (data?.update === undefined) {
        if (data?.updatedOn !== undefined) {
          if (data?.updatedOn !== '') { data.update = moment(data?.updatedOn).toISOString();
          } else if (data.createdOn !== '') { data.update = moment(data.createdOn).toISOString();
          } else { data.update = moment().subtract(6, 'month').toISOString();
          }
        } else if (data.createdOn !== '') { data.update = moment(data.createdOn).toISOString();
        } else {data.update = moment().subtract(6, 'month').toISOString(); }
      }
      data.id = a.payload.doc.id;
      return data;
    })));
    const dsTasks = this.afs.collection<Task>(`tasks`, ref => ref.where('companyId', '==', entDoc.id))
      .snapshotChanges().pipe(map(b => b.map(a => {
        const data = a.payload.doc.data() as any;
        if (data?.update === undefined) {
          if (data?.updatedOn !== undefined) {
            if (data?.updatedOn !== '') { data.update = moment(data?.updatedOn).toISOString();
            } else if (data.createdOn !== '') { data.update = moment(data.createdOn).toISOString();
            } else { data.update = moment().subtract(6, 'month').toISOString();
            }
          } else if (data.createdOn !== '') { data.update = moment(data.createdOn).toISOString();
          } else {data.update = moment().subtract(6, 'month').toISOString(); }
        }
        data.id = a.payload.doc.id;
        return data;
      })));
      const obsAll = dsTasks.concat(champComp);
      this.allEntTasks = obsAll;
      obsAll.subscribe(tasks => {
        // tasks.filter(task => task.companyId === entDoc.id);
        tasks.forEach(element => {
          const tgs: any =  element;
          if (!tgs?.department || (tgs?.department === '')) {
            const champ = this.staff?.find(stf => stf.id === tgs?.champion?.id);
            if(champ) {
              tgs.department = champ?.department || '';
              tgs.departmentId = champ?.departmentId || '';
              element = tgs;
            }
          }
        });
        let wksTasks = tasks.filter(task => {
          const ind = task?.participants?.findIndex(man => man.id === this.usd.acc?.id);
          if (task?.byId === this.usd.acc?.id) {
            // console.log(task.champion.name, task.by, task.description);
            return task;
          } else if (task?.champion?.id === this.usd.acc?.id) {
            // console.log(task.champion.name, task.by, task.description);
            return task;
          } else if (ind > -1) {
            // console.log(task.champion.name, task.by, task.description);
            return task;
            // } else if (!task.champion || (task.champion.name === '' && task.champion.id === '') || task.champCompany.name !== '') {
          } else if (task?.champType === 'CompanyTask' && task?.champCompany?.name !== '') {
            // console.log(task.champion.name, task.by, task.description);
            return task;
          }
        });
        if (wksTasks?.length > 0) {
          this.getTaskData().then(stff => {
            wksTasks?.forEach(element => {
              // if (!element.supervisor) {
              if (element.champion && element.companyId) {
                const dmChamp = stff?.find(tas => tas.id === element?.champion?.id);
                if (dmChamp) {
                  if (dmChamp.supervisor) {
                    element.supervisor = dmChamp.supervisor;
                    // console.log(element.supervisor.name);
                  } else {
                    element.supervisor = { name: '', id: '' }
                    // console.log('No supervisor');
                  }
                }
              }
            });
          })
          this.BankTasks = this.usd.trackTasks(wksTasks);
          wksTasks = this.usd.trackTasks(wksTasks).wksTasks?.push(x);
          wksTasks = wksTasks?.filter(tas => tas.complete === false);
          wksTasks = wksTasks?.sort((a, b) => b?.update.localeCompare(a?.update));
          wksTasks = wksTasks?.sort((a, b) => b?.update.localeCompare(a?.update));
          this.tasks = wksTasks?.sort((a, b) => b?.update.localeCompare(a?.update));
          this.loading = true;
          this.checkupdate();
          this.allActions = this.tasks;
          wksTasks?.forEach(data => {
            // console.log(data.workStatus, data.startDate, data.startDate, data.name, data.description);
            // });
            const tsk = this.usd.allMyTasks?.find(tk => tk.id === data.id);
            const tskIndx = this.usd.allMyTasks.findIndex(tk => tk.id === data.id);
            if (tskIndx > -1) {
              data = tsk
            }
            const viewActionsRef = this.afs.collection('Enterprises').doc(entDoc.id);
            const projCollection = viewActionsRef.collection('projects');
            if (data.companyName !== '') {
              data.companyName = entDoc.name;
              // this.afs.doc<Task>(`Enterprises/${entDoc.id}/tasks/${data.id}`).update({ 'companyName': entDoc.name }).then(() => {
              //   //  console.log(data.name , data.description, 'companyName updated', setContact.name));
              //   if (data.companyId !== '') {
              //     data.companyId = entDoc.id;
              //     this.afs.doc<Task>(`Enterprises/${entDoc.id}/tasks/${data.id}`).update({ 'companyId': entDoc.id }).
              //       then(() => { })
              //     // console.log(data.name , data.description, 'companyId updated', setContact.name));
              //   }
              // })
              this.afs.doc<Task>(`tasks/${data.id}`).update({ 'companyName': entDoc.name }).then(() => {
                //  console.log(data.name , data.description, 'companyName updated', setContact.name));
                if (data.companyId !== '') {
                  data.companyId = entDoc.id;
                  this.afs.doc<Task>(`tasks/${data.id}`).update({ 'companyId': entDoc.id }).
                    then(() => { })
                  // console.log(data.name , data.description, 'companyId updated', setContact.name));
                }
              })
              if (data.projectId !== '' && data.projectName === '') {
                projCollection.doc(data.projectId).ref.get().then((prjDoc) => {
                  if (prjDoc.exists) {
                    data.projectName = prjDoc.data().name;
                  }
                }).catch(err => {
                  // console.log(err + ' ' + 'No Company Name found' + ' ' + data.name + ' ' + element.id);
                });
              }
            };
          });
        } else {
          this.loading = true;
          this.tasks = []
        }
      });
    }
    
  checkupdate() {
    this.usdFilteredtasks?.forEach(xtask => {
      if (this.tasks?.find(x => x.id === xtask.id)) {
        console.log(`Task ${xtask.description} exists in company`);
      } else {
        console.log(`Task ${xtask.description} not exist in company`);
        this.updateTask(xtask);
      }
    })
  }

}
