import { Injectable, Optional } from '@angular/core';
/* ng-firebase */
import * as firebase from 'firebase/app';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument, QueryDocumentSnapshot, QuerySnapshot } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireStorage } from '@angular/fire/storage';
import { auth } from 'firebase/app';
import 'firebase/firestore';
/* tools */
import * as moment from 'moment';
import { map, find, filter, tap } from 'rxjs/operators';
import { Observable, Subscription, fromEvent, of } from 'rxjs';
/* Models */
import { Applicant, classification, coloursUser, comment } from 'app/models/user-model';
import { ParticipantData, Enterprise, companyStaff, client } from 'app/models/enterprise-model';
import { Project, workItem } from 'app/models/project-model';
import { Router } from '@angular/router';
import { Task, MomentTask, Unit } from 'app/models/task-model';
import { FixAndUpdatesService } from './fix-and-updates.service';
import { InitialiseService } from './initialise.service';
import { ChatService } from './chat.service';
import { EntServiceService } from './ent-service.service';
import { ProjServiceService } from './proj-service.service';
import { UserDataBaseService } from './user-data-base.service';
import { ServicesConfig } from './services-config';
import { Networth } from 'app/models/finances-model';
import { NotificationService } from './notification.service';
import { HttpService } from './http.service';

declare var $: any;

@Injectable({
  providedIn: "root",
})
export class UserDataService extends UserDataBaseService {
  localeForm: string;
  momenyjs = moment;
  firebase = firebase;
  // user;
  doc;
  acc;
  champ;
  deleteTask: Task;
  public showChampSelect = false;
  champion: any;
  firstname: string;
  lastname: string;
  currentUser: string;
  setContact: any;
  setCompany: any;
  setProject: any;
  setClient: client;
  projectTeam: any;
  myDocument: AngularFirestoreDocument<any>;
  myData: ParticipantData;
  userId: string;
  classifications: classification[];
  myContacts: any[];
  projects: any[];
  enterprises: any[];
  weeklyTasks: Task[];
  roundTasks: any[] = [];
  OutstandingTasks: any[];
  CurrentTAsks: any[];
  UpcomingTAsks: any[];
  ShortTermTAsks: any[];
  LongTermTAsks: any[];
  MediumTermTAsks: any[];
  tasks: Observable<Task[]>;
  allMyTasks: Task[];
  myTasks: any[];
  homeTasks = [];
  allActions = [];
  stdArray = [];
  allStandards = [];
  diaryTasks = [];
  today2 = "";
  dayTasks = [];
  tasksComments: any[];
  lastCommentBy: any;
  selectTask: Task;
  setTask: Task;
  Rates = [];
  totalVarience: number;
  totalActualTime: number;
  totalPlannedTime: number;
  activeProjects: any[];
  activePrjs: any[];
  pprojects: any[];
  tak = [];
  pik = [];
  topTasks: any[];
  topEntTasks: any[];
  tasksTodo: any[];
  tasksCompleted: any[];
  priorityTasks: any[];
  results = [];
  DayActions = [];
  types = [];
  totalPriority: any = 0;
  maxProjProductivity: any = 0;
  tasksChamps: any[];
  peerTasks: any[];
  allUsers: any[] = [];
  taskset: Task;
  taskTools: any = [];
  task: any;
  setTrans: any;
  staff = [];
  oldParts = [];
  chatParts: any[];
  DsTasks: Observable<Task[]>;
  relResults = [];
  timSheets: Observable<any[]>;
  equipment: any[] = [];
  selectedEquipment: any[] = [];
  hiredEquipment: any[] = [];
  selectedHiredEquipment: any[] = [];
  assets: any[];
  hiredAssets: any[];
  addCompAssPlg = true;
  newHiredAsset = false;
  public AddEquip = false;
  tagItems: any[] = [];
  totalNotices: any[] = [];
  inicompltCus: any[] = [];
  Rts: any;
  allsections = [];
  editTask: Task;
  editTaskBtn = false;
  setDone: boolean;
  showList: boolean;
  showUnit: boolean;
  showsCal: boolean;
  showfCal: boolean;
  showInvType: boolean;
  deciplineIndex: string;
  totalQtyRatio = 0;
  completedIndex: string;
  outstandingIndex: string;
  rptEnterprises: any[];
  mNetworthData: any;
  NetworthData: Networth[];
  accDeciplineIndex: any;
  curNetworth: Networth;
  ManagersRequests: Applicant[];
  noMgs: any[] = [];
  userSubs: Subscription;
  workTaskNotices: any[] = [];
  public showTools = true;
  public mugCustomer = false;
  filtersLoaded = false;
  public _xdata: firebase.User;
  changList: unknown[];
  n: number;
  prjList: unknown[];
  entList: unknown[];
  trans: any[];
  moreProjectInfo: any;
  wksTasks: any[];
  stds: any;
  prDocRef: Observable<any>;
  transNoti: any[];
  taskallsections: any[];
  myCustomers: any[];
  otherReports: Observable<any[]>;
  setReport;
  transComments: any[];
  lastTransCommentBy;
  selectTrans;
  rootProject: any;
  onlineEvent: Observable<Event>;
  offlineEvent: Observable<Event>;
  subscriptions: Subscription[] = [];
  connectionStatusMessage: string;
  connectionStatus: string;
  ClientChat: string = '';
  showMsg: boolean;
  loading = false;
  // userSubs: any;
  hoverShow = true;
  syncNotice: boolean;
  supervisor: any;
  estaff = [];
  projAdminSet: Project;
  allprojects = [];
  auth: typeof firebase.auth;
  coloursUserDetails: auth.UserCredential;
  myprojects = [];
  reportData: any[] = [];
  taskFlag = '';
    docData: any;
  setSale: any;
    mainSize: number;
    mainShow: boolean;
    // moment;
    timeIso = 'YYYY-MM-DDTHH:mm:ss.SSS[Z]';
    public moment = moment;
    public regerror: { msg: any; show: boolean; };
    pathTitle: string;
  constructor(
    public afs: AngularFirestore, public afAuth: AngularFireAuth, private router: Router, private ffs: FixAndUpdatesService, private https: HttpService, private ns: NotificationService,
    public storage: AngularFireStorage, private is: InitialiseService, @Optional() config?: ServicesConfig
  ) {
    super();
    //  console.log(moment().subtract(1, 'years').format('LL'));
    //  console.log(moment().month(1).format('MMMM'));
    const mdxates = new Date().toISOString();
    console.log(moment(mdxates).localeData().longDateFormat('L').toLowerCase());
    
    // this.localeForm = moment().localeData().longDateFormat('L').toLowerCase();
    this.localeForm = moment().localeData().longDateFormat('L');
    this.auth = auth;
    this.setCompany = this.is.getSelectedCompany();
    this.projectTeam = this.is.getClient();
    this.setProject = this.is.getSelectedProject();
    this.setContact = is.initContact();
    this.setClient = is.getClient();
    this.champ = is.getUserChampion();
    this.setTask = is.getTask();
    this.editTask = this.selectTask = is.getTask();
    this.taskset = is.getTask();
    this.task = is.getTask();
    this.today2 = moment().format("L");
    this.deleteTask = is.getTask();
    this.projAdminSet = is.getSelectedProject();
    this.acc = { name: "", email: "", bus_email: "", id: "", phoneNumber: "",
      photoURL: "", address: "", city: "", nationality: "",
      nationalId: "", gender: "", dob: "", showTutorial: false,
    };
    this.trans = []
    this.allStandards = [];
    this.stdArray = [];
    this.dayTasks = [];
    this.allMyTasks = [];
    this.tasksTodo = [];
    this.tasksCompleted = [];
    this.diaryTasks = [];
    this.priorityTasks = [];
    this.UpcomingTAsks = [];
    this.OutstandingTasks = [];
    this.CurrentTAsks = [];
    this.ShortTermTAsks = [];
    this.MediumTermTAsks = [];
    this.LongTermTAsks = [];
    this.myContacts = [];
    this.projects = [];
    // this.myTasks = [];
    // this.champion = { name: '', email: '' };
    this.champion = is.getUserChampion();
    this.rptEnterprises = [];
    this.enterprises = [];
    this.activeProjects = [];
    this.noMgs = [];
    console.log(this.localeForm);
    afAuth.authState.subscribe((user) => {
      // if (user === null && !this.is.toolPlug) {
      if (user === null) {
        this.router.navigate(["/pages/login"]);
      } else {
        this._xdata = user;
        // console.log(user, user?.metadata?.creationTime);
        this.userId = user?.uid;
        if (this.myTasks && this.myTasks?.length >= 0) {} else {
          this.dataCall();
        }
      }
    });
  }

  dateFomart(x) {
    return moment(x, 'L').format('DD/MM/YYYY');
  }

  altdateFormatM(x) {
    // return moment(x, 'MM/DD/YYYY').format('DD/MM/YYYY');
    return moment(x, 'MM/DD/YYYY').format(this.localeForm);
  }
  
  altdateFormat(x) {
    // return moment(x, 'YYYY-MM-DD').format('DD/MM/YYYY');s
    return moment(x, 'YYYY-MM-DD').format(this.localeForm);
  }
  
  async getEntStaff(task) {
    console.log(task)
    if (task?.companyId) {
      if (task.companyId !== '') {
        const collRef = this.afs.collection<companyStaff>(`Enterprises/${task.companyId}/Participants`, ref => ref.orderBy('name', 'asc'));
        collRef.ref.get().then(data => {
          // data.docs.length;
          // arrSet.data = data.docs;
          this.estaff = [];
          data.docs.forEach(element => {
            const item = element.data();
            this.estaff.filter((man: any) => (man?.left && man?.left === false || !man?.left)).push(item);
          });
        })
      } else { this.estaff = [] }
    } else { this.estaff = [] }
  }

  over() {
    // console.log('Mouseover called');
    this.hoverShow = false;
  }

  out() {
    // console.log('Mouseout called');
    this.hoverShow = true;
  }
  

  viewCon() {
    this.showMsg = true;
    if (this.connectionStatus === "online") {
      setTimeout(() => {
        this.showMsg = false;
      }, 500);
    }
  }

  connectivity() {
    this.onlineEvent = fromEvent(window, "online");
    this.offlineEvent = fromEvent(window, "offline");
    this.subscriptions.push(
      this.onlineEvent.subscribe((e) => {
        this.connectionStatusMessage = "Back to online";
        this.connectionStatus = "online";
        // if (!this.acc?.onlineStatus || this.acc.onlineStatus === "offline") {
        //   this.acc.onlineStatus = "online";
        //   this.viewCon();
        //   this.myDocument.update({ onlineStatus: "online" }).then(() => {/* console.log("online") */}).catch(er => console.log(er));
        // }
        // console.log("online...");
      })
    );
    this.subscriptions.push(
      this.offlineEvent.subscribe((e) => {
        this.connectionStatusMessage = "Connection lost! You are not connected to internet";
        this.connectionStatus = "offline";
        // if (!this.acc?.onlineStatus || this.acc.onlineStatus === "online") {
        //   this.acc.onlineStatus = "offline";
        //   this.viewCon();
        //   this.myDocument.update({ onlineStatus: "offline" }).then(() => {/* console.log("offline") */}).catch(er => console.log(er));
        // }
      })
    );
  }

  init() {
    this.setDone = false;
    this.editTaskBtn = false;
    this.showList = true;
    this.showUnit = false;
    this.showsCal = false;
    this.showfCal = false;
    this.showInvType = false;
  }

  getPrjATaskSecs(prj) {
    // console.log(`getting secs`);
    let allSuper;
    this.afs.collection<any>(`Enterprises/${prj.companyId}/projects/${prj.id}/sections`, (ref) => ref.orderBy("no", "asc")).valueChanges().subscribe((sections) => {
      sections = sections.filter((x) => x !== null);
      if (sections.length > 0) {
        // console.log(sections.length);
        allSuper = sections;
        sections.forEach((element) => { element.No = String(element.no); });
        this.afs.collection(`Enterprises/${prj.companyId}/projects/${prj.id}/subSections`, (ref) => ref.orderBy("no", "asc")).valueChanges().subscribe((subs: any) => {
          subs = subs.filter((x) => x !== null);
          subs.forEach((elem) => {
            if (elem.sectionNo) {
              const secInd = sections.findIndex((workClass) => workClass.name === elem.sectionName);
              if (secInd > -1) {
                elem.sectionNo = sections[secInd].no;
                elem.No = String(elem.sectionNo + "." + elem.no);
              }
              elem.No = String(elem.sectionNo + "." + elem.no);
            }
          });
          // console.log(sections.length);
          this.taskallsections = sections.concat(subs).sort((a, b) => a.No.localeCompare(b.No));
          // console.log(this.allsections);
        });
      }
    });
  }

  selectingTask(t) {
    // console.log(t.description, t.name)
    this.selectTask = t;
  }

  getAllTools() {
    return this.hiredEquipment.concat(this.equipment);
  }

  allSelectedTools() {
    this.tagItems = [];
    this.task.equipment = this.selectedEquipment.concat(
      this.selectedHiredEquipment
    );
    this.task.equipment.forEach((element) => {
      const tag = {
        display: element.name,
        value: element.name,
        readonly: true,
      };
      this.tagItems.push(tag);
    });
    // console.log(this.task.equipment.length, this.task.equipment);
    // return this.selectedEquipment.concat(this.selectedHiredEquipment);
  }

  initReallocate() {
    this.n = 0;
    if (
      this.selectTask.companyId !== "" &&
      this.selectTask.companyName !== "" &&
      this.selectTask.projectId !== ""
    ) {
      // console.log('EnterPrise', this.selectTask.companyId, this.selectTask.companyName);
      // console.log('Project', this.selectTask.projectId, this.selectTask.projectName);
      this.afs.collection(`Projects/${this.selectTask.projectId}/enterprises/${this.selectTask.companyId}/labour`).valueChanges().subscribe((lbr) => {
        this.changList = lbr;
        this.prjList = lbr;
        this.n = 4;
      });
    }
    if (
      this.selectTask.companyId !== "" &&
      this.selectTask.companyName !== "" &&
      this.selectTask.projectId === ""
    ) {
      // console.log(this.selectTask.companyId, this.selectTask.companyName);
      this.afs
        .collection(`Enterprises/${this.selectTask.companyId}/Participants`)
        .valueChanges()
        .subscribe((lbr) => {
          this.changList = lbr;
          this.n = 3;
          this.entList = lbr;
        });
    }
    if (
      this.selectTask.companyId === "" &&
      this.selectTask.companyName === "" &&
      this.selectTask.projectId === ""
    ) {
      this.changList = this.myContacts;
      this.n = 2;
    }
  }

  getList() {
    const num = this.n;
    this.selectTask.champion = this.editTask.champion;
    // console.log(num);
    if (num === 2) {
      this.changList = this.myContacts;
    } else if (num === 3) {
      // console.log(this.selectTask.companyId, this.selectTask.companyName);
      this.changList = this.entList;
    } else if (num === 4) {
      // console.log('EnterPrise', this.selectTask.companyId, this.selectTask.companyName);
      // console.log('Project', this.selectTask.projectId, this.selectTask.projectName);
      this.changList = this.prjList;
    } else if (num === 1) {
      // console.log(num);
    } else {
    }
  }

  contactInfo(user) {
    this.afs
      .collection<any>("Users", (ref) => ref.where("name", "==", user))
      .valueChanges()
      .subscribe((users) => {
        const searchedUser = users.find((i) => i.name === user);
        this.afs
          .doc(`Users/${this.acc.id}/contacts/${searchedUser?.id}`)
          .ref.get()
          .then((udat) => {
            if (udat.exists) {
              if (udat.data().chatId) {
                searchedUser.chatId = udat.data().chatId;
              } else {
                this.chatCreate(searchedUser);
              }
            } else {
            }
          });
        this.setContact = searchedUser;
      });
    return this.setContact;
  }

  async chatCreate(part) {
    const { id } = await this.acc;
    const userRef = this.afs.doc(`Users/${id}/contacts/${part.id}`);
    try {
      if (part.id && this.acc.id) {
        const partRef = this.afs.doc(`Users/${part.id}/contacts/${this.acc.id}`
        );
        // const partRef = this.afs.collection('Users').doc(part.id).collection('contacts').doc(this.acc.id);
        const docRef = this.afs.collection("chats");
        // Init new chat data
        const data = {
          createdAt: firebase.firestore.Timestamp.now(),
          count: 0,
          messages: [],
          participants: [this.acc.id, part.id],
          ownerId: id,
          typing: [],
        };
        docRef.add(data).then((dat) => {
          partRef
            .update({ chatId: dat.id })
            .then(() => {
              /* console.log('Chat updated') */
            })
            .catch((er) => {
              const newContact = {
                email: this.acc.email,
                bus_email: this.acc.bus_email,
                name: this.acc.name,
                id: this.acc.id,
                chatId: dat.id,
                phoneNumber: this.acc.phoneNumber,
              };
              // console.log(newContact);
              partRef.ref.get().then((prt) => {
                if (prt.exists) {
                } else {
                  partRef.set(newContact).then(() => {
                    /* console.log('Chat Updated') */
                  });
                }
              });
              userRef.ref.get().then((usr) => {
                if (usr.exists) {
                  userRef.update({ chatId: dat.id }).then(() => {
                    // Route to new chat in chat component
                    // console.log('Chat created');
                    if (this.setContact.name) {
                    } else {
                      this.setContact = this.is.initContact();
                      return this.router.navigate(["chats", dat.id]);
                    }
                  });
                } else {
                  const newontact = {
                    email: part.email,
                    bus_email: part.bus_email,
                    name: part.name,
                    id: part.id,
                    chatId: dat.id,
                    phoneNumber: part.phoneNumber,
                  };
                  userRef.update(newontact).then(() => {});
                }
              });
            });
        });
      }
    } catch (error) {
      // console.log(error);
    }
  }

  companyInfo(ent) {
    this.afs
      .collection<Enterprise>("Enterprises", (ref) =>
        ref.where("name", "==", ent)
      )
      .valueChanges()
      .subscribe((enterprises) => {
        enterprises = enterprises.filter((x) => x !== null || undefined);
        const searchedUser = enterprises.find((i) => i.name === ent);
        // this.setContact = searchedUser;
        this.setCompany = searchedUser;
        // console.log(this.setCompany)
      });
    return this.setCompany;
  }

  projectInfo(proj) {
    this.afs
      .collection<Project>("Projects", (ref) => ref.where("name", "==", proj))
      .valueChanges()
      .subscribe((projects) => {
        projects = projects.filter((x) => x !== null || undefined);
        const project = projects.find((i) => i.name === proj);
        // this.setContact = project;
        this.setProject = project;
      });
    return this.setProject;
  }


  colors(word, p) {
    this.afs
      .collection<any>("Users")
      .valueChanges()
      .subscribe((allUsers) => {
        // this.afs.collection<any>('Users', ref => ref.where('name', '>=', word)).valueChanges().subscribe(allUsers => {
        if (p === "Personal") {
          this.serchColors(allUsers, word);
        } else {
          // console.log('non');
        }
      });
  }

  serchColors(allUsers, value) {
    let word = value;
    const results = [];
    allUsers.forEach((man) => {
      if (man.name) {
        // man.name = man.name.toLowerCase();
        const name = man?.name?.toLowerCase();
        if (word !== "" || " ") {
          word = word.toLowerCase();
          if (name?.includes(word)) {
            // man.name = man.name.charAt(0).toUpperCase() + man.name.slice(1);
            results.push(man);
            // console.log(results);
            this.allUsers = results;
            const exist = this.myContacts.find((item) => item.id === man.id);
            if (exist) {
              man.join = true;
              // console.log('true');
            } else {
              // console.log('false');
              man.join = false;
            }
          }
        }
      }
      return this.allUsers;
    });
  }

  taskInfo(id) {
    this.afs
      .doc(`tasks/${id}`)
      .ref.get()
      .then((doc) => {
        if (doc.exists) {
          // console.log(doc.data().name);
          this.afs
            .doc<Task>(`tasks/${id}`)
            .valueChanges()
            .subscribe((searchedTask) => {
              this.setTask = searchedTask;
              // console.log(searchedTask);
            });
        } else {
          this.afs
            .collectionGroup<Task>("tasks", (ref) => ref.where("id", "==", id))
            .valueChanges()
            .subscribe((tasks) => {
              const searchedTask = tasks.find((i) => i.id === id);
              this.setTask = searchedTask;
              if (tasks.length > 1) {
                this.afs
                  .doc<Task>(`tasks/${id}`)
                  .set(searchedTask)
                  .then(() => {
                    // console.log('Set to Root Tasks');
                  });
              } else {
                this.afs
                  .doc<Task>(`tasks/${id}`)
                  .set(searchedTask)
                  .then(() => {
                    // console.log('Set to Root Tasks');
                  });
              }
              // console.log(tasks, this.setTask.name);
            });
        }
      });
    return this.setTask;
  }

  logout() {
    this.afAuth.signOut().then(() => {
      this.trans = []
      this.activeProjects = [];
      this.topTasks = [];
      this.allStandards = [];
      this.stdArray = [];
      this.dayTasks = [];
      this.allMyTasks = [];
      this.tasksTodo = [];
      this.tasksCompleted = [];
      this.diaryTasks = [];
      this.priorityTasks = [];
      this.UpcomingTAsks = [];
      this.OutstandingTasks = [];
      this.CurrentTAsks = [];
      this.ShortTermTAsks = [];
      this.types = [];
      this.MediumTermTAsks = [];
      this.LongTermTAsks = [];
      this.myTasks = [];
      this.myContacts = [];
      this.projects = [];
      this.enterprises = [];
      this.rptEnterprises = [];
      this.userSubs.unsubscribe();
      this.router.navigate(["/pages/login"]);
    });
    this.router.navigate(["/pages/login"]);
  }

  dataCall() {
    this.callData();
    this.getUnits();
  }

  selectFile (file) {
    this.setTask?.docs.filter(x => x.downloadURL !== file.downloadURL);
    this.doc = file;
  }

  getUnits() {
    this.is.SIunits = [];
    this.afs.collection<Unit>('units').valueChanges().subscribe(refData => {
      this.is.SIunits = refData;
      this.getDublicates(this.is.SIunits);
    });
  }

  getDublicates(units) {
    for (let i = 0; i < units.length; i++) {
      const object = units[i];
      const dublicates = [];
      for (let index = 0; index < units.length; index++) {
        const element = units[index];
        // console.log(element.name, object.name, element.id, object.id, element.uid, object.uid);
        if ((object.name === element.name && (object.uid !== element.uid))) {
          dublicates.push(element);
          object.dublicates = dublicates;
        } else if ((object.name !== element.name) && (i > 0) && (units[i - 1]?.dublicates && units[i - 1]?.dublicates?.length > 0)) {
          const duby = units[i - 1];
        }
      }
      if (i === units.length - 1) {
        setTimeout(() => {
          if (object?.dublicates?.length > 1) {
            for (let c = 0; c < object?.dublicates.length; c++) {
              console.log('Deleting Dublicates', object?.dublicates?.length);
              const elt = object?.dublicates[c];
              console.log(elt.name, elt.uid, elt.dublicates);
              // console.log(object.name, elt.name, object.uid, elt.uid);
              this.afs.doc(`units/${elt?.uid}`).delete().then(() => {
                console.log(elt.id, 'deleted');
              }).catch(er => console.log(`delete failed, Error ${er}`));
            }
          }
        }, 200)
      }
    }
  }

  callData() {
    const myDocument = this.afs.doc<any>(`Users/${this.userId}`);
    this.myDocument = myDocument;
    this.getMyDoc(myDocument);
    this.getClassifications();
    this.getContact();
    this.getProjects();
    this.getCompanies();
    this.getCustomers();
    this.getTypeTasks();
    this.loading = false;
    return this.acc;
  }

  getMyDoc(myDocument) {
    myDocument.ref.get().then((dat) => {
      if (dat.exists) {
        this.docData = dat.data();
        const userSubs = this.myDocument.valueChanges().subscribe((userData) => {
        userData.email  = userData.email ?? ""; userData.address = userData.address ?? "";
        userData.phoneNumber = userData.phoneNumber ?? ""; userData.bus_email = userData.bus_email ?? "";
        if ((!userData?.bus_email && userData?.providerId) || (userData?.bus_email === "" && userData?.providerId)) {
          userData.bus_email = userData.email;
        }
        userData.nationalId = userData.nationalId ?? ""; userData.nationality = userData.nationality ?? "";
        userData.photoURL = userData.photoURL ?? ""; userData.userImg = userData.userImg ?? "";
        userData.gender = userData.gender ?? ""; userData.dob = userData.dob ?? ""; userData.city = "";
        if (userData?.showTutorial === undefined) {
          userData.showTutorial = false;
          this.updateTutStatus(userData.showTutorial);
        }
        if (userData?.creationTime === undefined) {
          userData.creationTime = this._xdata.metadata.creationTime;
          myDocument.update({ creationTime: this._xdata.metadata.creationTime }).then(() => {}).catch((er) => console.log(er));
        }
        if (userData?.lastSignInTime === undefined) {
          userData.lastSignInTime = this._xdata.metadata.lastSignInTime;
          myDocument.update({ lastSignInTime: this._xdata.metadata.lastSignInTime }).then(() => {}).catch((er) => console.log(er));
        }
        const myData: any = {
          name: userData?.name || "", email: userData?.email || "", bus_email: userData?.bus_email || userData?.email || "",
          id: userData?.id, dob: userData?.dob || "", phoneNumber: userData?.phoneNumber || "",
          gender: userData?.gender || "",photoURL: userData?.photoURL || userData?.userImg || "",
          address: userData?.address || "", city: userData?.city || "", nationality: userData?.nationality || "",
          nationalId: userData?.nationalId || "", profession: userData?.profession || [],
          qualifications: userData?.qualifications || [], jobHunted: userData?.jobHunted || "",
          workExperience: userData?.workExperience || 0, proQualifications: userData?.proQualifications || [],
          onlineStatus: userData.onlineStatus || "",  expectedSalary: userData?.expectedSalary || { value: 0,  currency: "", },
          taskNotice: userData?.taskNotice || "", showTutorial: userData?.showTutorial || false, creationTime: this._xdata.metadata.creationTime,
          lastSignInTime: this._xdata.metadata.lastSignInTime,
        };
        if (myData.dob) {
          myData.age = moment().diff(moment(myData.dob), "years");
        }
        myData.photoURL = myData.photoUR ?? ""; myData.email = (!myData.email && this._xdata.email) ? this._xdata.email : '';
        myData.id = myData.id ? myData.id : this._xdata.uid; this.acc = myData;
        // this.pub.acc = myData;
        const naming = this.acc.name.split(" ");
        if (this.acc.name && naming?.length > 0) {
          if (naming?.length > 2) {
            for (let index = 0; index < naming.length - 1; index++) {
              if (index === 0) {
                this.firstname = naming[0];
              } else {
                this.firstname += " " + naming[index];
              }
            }
            this.lastname = naming[naming.length - 1];
          } else if (naming?.length === 2) {
            this.firstname = naming[0];
            this.lastname = naming[naming.length - 1];
          } else if (naming?.length === 2) {
            this.firstname = naming[0];
            this.lastname = "";
          } else {
          }
        }
        this.getMyTasks();
        this.sortDate();
        this.notifications();
        this.getTransactions();
      });
        this.userSubs = userSubs;
      } else {
        this.confiGNoN();
      }
    });
  }

  updateTutStatus(showTutorial: any) {
    // throw new Error('Method not implemented.');
    this.myDocument
      .update({ showTutorial: showTutorial })
      .then(() => {
        console.log("showTutorial set to false");
      })
      .catch((er) => console.log(er));
  }

  getClassifications() {
    let value;
    const newClassification = {
      name: "Work",
      createdOn: new Date().toISOString(),
      id: "colourWorkId",
      plannedTime: "",
      actualTime: "",
      Varience: "",
    };
    const setClass = this.afs.doc(
      `Users/${this.userId}/classifications/${newClassification.id}`
    );
    this.afs
      .collection<classification>(
        `Users/${this.userId}/classifications`,
        (ref) => ref.orderBy("name", "asc")
      )
      .valueChanges()
      .subscribe((cls) => {
        cls = cls.filter((x) => x !== null || undefined);
        this.classifications = cls;
        const index = cls.findIndex((workClass) => workClass.name === "Work");
        if (index > -1) {
          value = cls[index].name;
        } else {
          if (value === newClassification.name) {
            setClass.update(newClassification);
          } else {
            setClass.set(newClassification);
          }
        }
        this.totalPlannedTime = this.totalActualTime = this.totalVarience = 0;
        let totalPlannedTime = 0;
        let totalActualTime = 0;
        let totalVarience = 0;
        cls.forEach((element) => {
          totalPlannedTime = totalPlannedTime + Number(element.plannedTime);
          this.totalPlannedTime = totalPlannedTime;
          // console.log('totalPlannedTime -->' + ' ' + totalPlannedTime);

          totalActualTime = totalActualTime + Number(element.actualTime);
          this.totalActualTime = totalActualTime;
          // console.log('totalActualTime -->' + ' ' + totalActualTime);

          totalVarience = +Number(element.Varience);
          this.totalVarience = totalVarience;
          // console.log('totalVarience -->' + ' ' + totalVarience);
        });
        // this.classArray.length;
      });
  }

  getTypeTasks() {
    this.afs.collection(`typeOfTasks`).valueChanges().subscribe((typs) => {
      typs = typs.filter((x) => x !== null || undefined);
      this.types = typs;
    });
  }

  sortData(x) {
    return x.sort((a, b) => moment(b?.update).diff(moment(a?.update)));
    // return x.sort((a, b) => moment(a?.update).diff(moment(b?.update)));
  }

  handleImgError = e => {
    e.target.src = this.ns.getImgs()
  }

  async getCompanies() {
    // console.log('getCompanies');
    const collRef = this.afs.collection<any>(`Users/${this.userId}/myenterprises`/* , (ref) => ref.orderBy("name", "asc") */);
    collRef.ref.get().then(data => {
      const ents = [];
      for (let i = 0; i < data.docs?.length; i++) {
      // otherReports?.data.forEach((x, i) => {
        const item = data.docs[i].data();
        ents.push(item);
        const element = item;
        if (element?.id && !element.hierarchy) {
          this.afs.doc<ParticipantData>(`Enterprises/${element.id}/Participants/${this.userId}`).ref.get().then((doc) => {
            if (doc.exists) {
              if (!doc.data().hierarchy) {
                element.hierarchy = "Operations";
                this.afs.doc<any>(`Users/${this.userId}/myenterprises/${element.id}`).update({ hierarchy: "Operations" });
              } else {
                element.hierarchy = doc.data().hierarchy;
              }
            }
          });
        }
        if (i === data.docs?.length - 1) {
          this.enterprises = this.sortData(ents.filter((x) => !x.read || x.read === false));
          this.rptEnterprises = this.sortData(ents.filter((x) => !x.read || x.read === false));
          // console.log('pjs', this.projects?.length, 'Enst' , this.enterprises?.length);
          this.projects.forEach(async prj => {
            const entFnd = await this.enterprises.find(ent => ent.id === prj.companyId)
            if(entFnd && (entFnd.name !== prj.companyName)) {
              // console.log(entFnd);
              prj.companyName = entFnd.name;
            }
          });
        }
        // console.log('getCompanies', ents?.length);
      }
    })
  }

  getCustomers() {
    this.afs.collection<any>(`Users/${this.userId}/myCustomers`, (ref) => ref.orderBy("name", "asc")).valueChanges().subscribe((ents) => {
      const msdArr = ents?.filter((a) => a.msgUpdate);
      const msdArrNot = ents?.filter((a) => !a.msgUpdate);
      console.log('msdArr', msdArr, 'msdArrNot', msdArrNot);
      const nots = msdArr?.sort((a, b) => moment(b?.msgUpdate).diff(moment(a?.msgUpdate))).concat(msdArrNot);
      this.myCustomers = nots.filter((x) => !x.read || x.read === false);
      this.checkSrTag();
    });
  }


  checkSrTag(){
    this.myCustomers.forEach(element => {
      if (element.srTag === undefined) {
        this.afs.doc<any>(`Enterprises/${element.id}`).ref.get().then(mycomp => {
          if (mycomp.exists) {
            const ds = mycomp.data();
            element.srTag = ds.srTag ? ds.srTag : "ent";
            this.afs.doc<any>(`Users/${this.userId}/myCustomers/${element.id}`).update({'srTag': element.srTag});
          } else {
            element.srTag = "ind";
            this.afs.doc<any>(`Users/${this.userId}/myCustomers/${element.id}`).update({'srTag': element.srTag});
          }
        })
      }
    });
  }

  async getProjects() {
    // console.log('Projects');
    const collRef = this.afs.collection<any>(`Users/${this.userId}/projects`, (ref) => ref.orderBy("name", "asc"));
    collRef.ref.get().then(data => {

      const pjs = [];
      for (let i = 0; i < data.docs?.length; i++) {
        const item = data.docs[i].data();
        pjs.push(item);
        if (i === data.docs?.length - 1) {
          // console.log('pjs', pjs?.length, 'Enst' ,this.enterprises?.length);
          Promise.all(this.enterprises).then(async cmps => {
            for (let i = 0; i < pjs?.length; i++) {
              const prj = pjs[i]; const pr = prj;
              const entFnd = await this.enterprises.find(ent => ent.id === prj.companyId)
              if ((!prj.type && prj.companyName !== "") || (!prj.type && prj.companyId !== "") || prj.companyId !== "") {
                prj.type = "Enterprise";
                this.afs.doc(`Users/${this.userId}/projects/${prj.id}`).update(prj);
              } else if ((!prj.type && prj.companyName === "") || (!prj.type && prj.companyId === "")) {
                prj.type = "Personal";
                this.afs.doc(`Users/${this.userId}/projects/${prj.id}`).update(prj);
              }
              if(entFnd && (prj.companyName !== entFnd.name)) {
                prj.companyName = entFnd.name;
              }
              if (!pr?.paymentRate && pr.companyId && pr.companyId !== "") {
                this.afs.doc<any>(`Projects/${pr.id}/enterprises/${pr.companyId}/labour/${this.userId}`).ref.get().then((lbrRef) => {
                  if(lbrRef.exists) {
                    // console.log(lbrRef.data().name);
                    const lbr = lbrRef.data();
                    if (lbr?.paymentRate) {
                      pr.paymentRate = lbr?.paymentRate;
                      this.afs.doc<any>(`Users/${this.userId}/projects/${pr.id}`).update({ paymentRate: lbr?.paymentRate })
                        // .then(() => console.log('My Project paymentRate Updated', pr.name))
                        .catch((er) => {
                          //  console.log('Project Doc Uodate failed', er);
                        });
                    } else {
                      pr.paymentRate = { rate: "", amount: 0 };
                    }
                  }
                });
              }
              if (!pr?.levelOfEffort && pr.companyId && pr.companyId !== "") {
                this.afs.doc<any>(`Projects/${pr.id}/enterprises/${pr.companyId}/labour/${this.userId}`).ref.get().then((lbrRef) => {
                  if (lbrRef.exists) {
                    // console.log(lbrRef.data().name);
                    const lbr = lbrRef.data();
                    if (lbr?.levelOfEffort) {
                      pr.levelOfEffort = lbr?.levelOfEffort;
                      this.afs
                        .doc<any>(`Users/${this.userId}/projects/${pr.id}`)
                        .update({ levelOfEffort: lbr?.levelOfEffort })
                        // .then(() => console.log('My Project levelOfEffort Updated', pr.name))
                        .catch((er) => {
                          //  console.log('Project Doc Uodate failed', er);
                        });
                    } else {
                      pr.levelOfEffort = 0;
                    }
                  }
                });
              }
              if (!pr?.prjJoinDate && pr.companyId && pr.companyId !== "") {
                this.afs.doc<any>(`Projects/${pr.id}/enterprises/${pr.companyId}/labour/${this.userId}`).ref.get().then((lbrRef) => {
                  if (lbrRef.exists) {
                    const lbr = lbrRef.data();
                    // console.log(pr.name, lbr.name, lbr?.prjJoinDate);
                    if (lbr?.prjJoinDate) {
                      pr.prjJoinDate = lbr?.prjJoinDate;
                      this.afs
                        .doc<any>(`Users/${this.userId}/projects/${pr.id}`)
                        .update({ prjJoinDate: lbr?.prjJoinDate })
                        // .then(() => console.log('My Project prjJoinDate Updated', pr.name))
                        .catch((er) => {
                          //  console.log('Project Doc Uodate failed', er);
                        });
                    } else {
                      pr.prjJoinDate = "";
                    }
                  }
                });
              }
            }
          })
          this.projects = pjs.filter((pr) => {
            if (!pr?.exit || (pr.exit === false)) {
              return pr;
            }
          });
          this.projects.sort((a, b) => moment(b?.update).diff(moment(a?.update)));
          if(!this.myprojects || this.myprojects?.length > 0) {} else {
            this.myprojects = this.projects;
          }
        }
      }
    })
  }

  async getMyInfo() {
    return await this.acc;
  }

  async getContact() {
    const collRef = this.afs.collection<any>(`Users/${this.userId}/contacts`, (ref) => ref.orderBy("name", "asc"));
    const conArray = collRef.valueChanges()
    conArray.subscribe(data => {
      // data.docs.length;
      console.log(data.length);
      let contactsS = [];
      contactsS = data?.filter((x) => (x !== null || undefined) || (x.name !== ''));
      data.forEach((element) => {
        if (!element?.msgUpdate) {
          element.msgUpdate = "";
        } else {
          // console.log(element.name, element.msgUpdate);
        }
        // console.log(element.name, element?.msgNo);
        element.id !== '' ? this.afs.collection("Users").doc(element.id).ref.get().then((tug) => {
          if (tug.exists) {
            element.name = tug.data().name; element.address = tug.data().address || "";
            element.nationalId = tug.data().nationalId || ""; element.nationality = tug.data().nationality || "";
            element.phoneNumber = tug.data().phoneNumber || ""; element.id = tug.data().id;
            element.photoURL = tug.data().photoURL || tug.data().userImg || ""; element.bus_email = tug.data().bus_email || "";
          } else {
            element.photoURL = ""; element.bus_email = "";
          }
        }) : '';
      });
      this.noMgs = data?.filter((user) => {
        if (user?.msgNo && user?.msgNo > 0) {
          return user;
        }
      });
      for (let i = 0; i < data.length; i++) {
        // const item = data;
        // contacts.push(item);
        if (i === data?.length - 1) {
          // const conts = contacts;
          console.log(this.noMgs.length, this.noMgs);
          this.totalNotices.concat(this.noMgs.length);
          const msdArr = contactsS?.filter((a) => a.msgUpdate);
          const msdArrNot = contactsS?.filter((a) => !a.msgUpdate);
          // console.log('msdArr', msdArr, 'msdArrNot', msdArrNot);
          this.myContacts = msdArr?.sort((a, b) => moment(b?.msgUpdate).diff(moment(a?.msgUpdate))).concat(msdArrNot);
          this.tasksChamps = contactsS;
          this.tasksChamps?.push(this.acc);
          this.tasksChamps = this.tasksChamps.filter((x) => x !== null || undefined);
        }
      }
      // arrSet.data = data.docs;
    })
    
  }

  confiGNoN() {
    //  console.log(`User Doc doesn't exist`, 'confiGNoN');
    let myData;
    // this.afAuth.authState.subscribe(user => {
    // console.log(user);
    if (this._xdata !== null) {
      const duser: any = {
        name: this._xdata.displayName || "",
        id: this._xdata.uid,
        email: this._xdata.email || "",
        bus_email: this._xdata.email || "",
        phoneNumber: this._xdata.phoneNumber || "",
        photoURL: this._xdata.photoURL || "",
        userImg: this._xdata.photoURL || "",
        address: "",
        nationality: "",
        nationalId: "",
        creationTime: this._xdata.metadata.creationTime,
        lastSignInTime: this._xdata.metadata.lastSignInTime,
        showTutorial: false,
      };
      myData = duser;
      this.acc = duser;
      this.getMyTasks();
      this.notifications();
      this.sortDate();
      this.userId = this._xdata.uid;
      this.myDocument.ref.get().then((refData) => {
        if (!refData.exists) {
          this.myDocument.update(duser).then(() => {
            //  console.log('user doc updated');
          });
        }
      });
    } else {
    }
    // })
    return myData;
  }

  updateAccount() {
    const user = {
      name: this.acc.name, address: this.acc.address || "", phoneNumber: this.acc.phoneNumber || "",
      bus_email: this.acc.bus_email || "", email: this.acc.email || "", nationality: this.acc.nationality || "",
      nationalId: this.acc.nationalId || "", userImg: this.acc.userImg || "", photoURL: this.acc.photoURL || "",
    };
    if (!user.userImg) { user.userImg = this.acc.photoURL; }
    this.afs.doc(`Users/${this.userId}`).update(user).then(() => {
      // console.log('user doc updated');
    });
  }

  async timeSheet() {
    const selectedDate = moment().format("DD-MM-YYYY");
    const collRef = this.afs.doc<any>(`Users/${this.userId}/TimeSheets/${selectedDate}`);
    collRef.ref.get().then(data => {
      // data.docs.length;
      this.estaff = [];
        const item = data?.data();
        if (isNaN(item?.deciplineIndex)) {
          this.accDeciplineIndex = 0;
        } else if (!isFinite(item?.deciplineIndex)) {
          this.accDeciplineIndex = 0;
        } else {}
    })
  }

  async daytoDate(tasksWorkedToday: any[]) {
    const tday = moment().format("LL");
    let workedHrs = 0;
    this.totalQtyRatio = 0;
    for (let index = 0; index < tasksWorkedToday.length; index++) {
      const task = tasksWorkedToday[index];
      this.todaysActuals(task);
      /* ------------------------ Todays Report ------------------------*/
      const todayFoundAut = task?.workHours?.filter(
        (day) => moment(day.time).format("LL") === tday
      );
      if (todayFoundAut && todayFoundAut.length > 0) {
        todayFoundAut.forEach((element) => {
          workedHrs += element.hours; /* console.log(workedHrs); */
        });
      }
      /* -------------------------  End of Todays Report ------------------------ */
    }
    const usdTasks = this.trackTasks(this.allMyTasks);
    this.completedIndex = (
      (this.tasksCompleted.length / this.allMyTasks.length) *
      100
    ).toFixed(2);
    this.outstandingIndex = (
      (usdTasks.Outstanding.filter((tas) => tas.complete === false).length /
        usdTasks.wksTasks.length) *
      100
    ).toFixed(2);
    //  console.log(this.completedIndex, this.outstandingIndex);
    setTimeout(() => {
      this.deciplineIndex = (
        ((workedHrs / 24) * 100 +
          (this.totalQtyRatio / tasksWorkedToday.length) * 100) /
        2
      ).toFixed(2);
      //  console.log(this.deciplineIndex, Number(this.deciplineIndex), 'stored', this.accDeciplineIndex, isNaN(this.accDeciplineIndex));
      if (
        isNaN(this.accDeciplineIndex) ||
        (!isNaN(Number(this.deciplineIndex)) && Number(this.deciplineIndex) > 0)
      ) {
        this.accDeciplineIndex = this.deciplineIndex;
        // console.log(this.deciplineIndex, 'stored', this.accDeciplineIndex);
      } else if (
        isNaN(this.accDeciplineIndex) &&
        isNaN(Number(this.deciplineIndex))
      ) {
        this.accDeciplineIndex = 0;
        // console.log(this.deciplineIndex, 'stored', this.accDeciplineIndex);
      } else if (
        !isFinite(this.accDeciplineIndex) &&
        !isFinite(Number(this.deciplineIndex)) &&
        Number(this.deciplineIndex) > 0
      ) {
        this.accDeciplineIndex = 0;
      } else {
      }
      const timeSheetPath = this.afs.doc<any>(
        `Users/${this.userId}/TimeSheets/${moment().format("DD-MM-YYYY")}`
      );
      timeSheetPath.ref.get().then((timeSheet) => {
        if (timeSheet.exists) {
          const tData = timeSheet.data();
          if (
            tData.deciplineIndex &&
            !isNaN(Number(this.deciplineIndex)) &&
            Number(tData.deciplineIndex) !== Number(this.deciplineIndex)
          ) {
            // console.log('1st condition', this.deciplineIndex, 'stored', tData.deciplineIndex);
            tData.deciplineIndex = this.deciplineIndex;
            timeSheetPath.update({ deciplineIndex: this.deciplineIndex });
          } else if (
            tData.deciplineIndex &&
            !isNaN(Number(tData.deciplineIndex)) &&
            !isNaN(Number(this.deciplineIndex)) &&
            Number(tData.deciplineIndex) === Number(this.deciplineIndex)
          ) {
            // console.log('2nd condition', this.deciplineIndex, 'stored', tData.deciplineIndex)
          } else if (
            !tData.deciplineIndex &&
            !isNaN(Number(tData.deciplineIndex))
          ) {
            // console.log('3rd condition', this.deciplineIndex, 'stored', tData.deciplineIndex);
            timeSheetPath.update({ deciplineIndex: this.deciplineIndex });
          } else {
            // console.log('4th condition', this.deciplineIndex, 'stored', tData.deciplineIndex);
          }
        } else {
          const timesheetDocId = String(moment().format("DD-MM-YYYY"));
          const timeData = {
            name: timesheetDocId,
            id: timesheetDocId,
            deciplineIndex: this.deciplineIndex,
          };
          timeSheetPath
            .set(timeData)
            // .then(() => console.log('TimeSheet Doc Added'))
            .catch((er) => console.log(er));
        }
      });
    }, 3000);
  }

  async todaysActuals(task) {
    const ystday = moment().format("L");
    if (task.targetQty && Number(task.targetQty) > 0) {
    const collRef = this.afs.collection(`tasks/${task.id}/actionActuals`);
    collRef.ref.get().then(data => {
      data.docs.length;
      const acts = [];
        for (let i = 0; i < data.docs?.length; i++) {
          const item = data.docs[i].data();
          acts.push(item);
          if (i === data.docs?.length - 1) {
            if (moment(item.updateTime).format("L") === ystday) {
              if (item.qty !== null) {
                task.valueEarnedPerDay = item.qty * task.rate;
                this.totalQtyRatio += item.qty / Number(task.targetQty);
                // console.log(task.name, this.totalQtyRatio);
              } else {
                // console.log(task.name, "isn't planned for in the cost frame work");
              }
            }
          }
        }
      // arrSet.data = data.docs;
    })
    } else {
      this.totalQtyRatio += 0.6;
    }
  }

  async getMyTasks() {
    this.timeSheet();
    this.afs.collection(`Users/${this.userId}/myStandards`).snapshotChanges()
      .pipe(map((b) => b.map((a) => {
            const data = a.payload.doc.data() as any;
            if (data.startTime === undefined) {
              data.startTime = "";
            }
            if (data.update === undefined) {
              if (data.UpdatedOn !== undefined) {
                if (data.UpdatedOn !== "") { data.update = moment(data.UpdatedOn).toISOString();
                } else { data.update = moment().subtract(6, "month").toISOString(); }
              } else if (data.createdOn !== "") { data.update = moment(data.createdOn).toISOString();
              } else { data.update = moment().subtract(6, "month").toISOString(); }
            }
            data.tag = "std";
            data.id = a.payload.doc.id;
            return data;
          })
        )
      )
      .subscribe((stds: any) => {
        this.stds = stds;
        stds = stds.filter((x) => x !== null || undefined);
        this.stdArray = [];
        this.allStandards = stds;
        this.groupStds(stds);
      });
    const DsTasks = this.myDocument.collection<any>("tasks")
    // .valueChanges();
    
    const collRef = this.myDocument.collection<any>("tasks");
    collRef.valueChanges().subscribe(allTasks => {
      const withoutIds = allTasks.filter(xs => xs.id === '');
      withoutIds.forEach(et => {
        DsTasks.ref.where('description', '==', et.description).where('name', '==', et.name).where('createdOn', '==', et.createdOn).where('byId', '==', et.byId).get().then(refData => {
          if (refData.docs?.length > 0) {
            refData.docs.forEach(docRef => {
              if (docRef.exists) {
                console.log(docRef.data(), docRef.id);
                const dsFns = docRef.data();
                /* const fndtask =  */allTasks.find(tsk => ((tsk.createdOn === dsFns.createdOn) && tsk?.description === dsFns?.description)).id = docRef?.id;
                // fndtask ?? fndtask.id = docRef?.id;
                collRef.doc(docRef.id).update({'id': docRef.id}).then(() => console.log(dsFns.description, 'Id updated')).catch(er => console.log('failed to update id', er));
              }
            })
          }
        })
        
      });
      // console.log(withoutIds);
      this.managetasks(allTasks);
    })
  }

  managetasks(tsksArr) {
    console.log('tsksArr', tsksArr?.length);
    tsksArr.forEach((element) => {
      element.tag = "sub";
      if (element.complete === undefined) {
        element.complete === false;
      }
      element.update = element?.update || moment(element?.UpdatedOn).toISOString() || moment(element?.createdOn).toISOString() || ''
      element.tag = "sub";
      element.workStatus = "";
      if (element.complete === undefined) {
        element.complete === false;
      }
      if (element.update === undefined) {
        if (element.UpdatedOn !== undefined) {
          if (element.UpdatedOn !== "") {
            element.update = moment(element.UpdatedOn).toISOString();
          } else {
            element.update = moment().subtract(6, "month").toISOString();
          }
        } else if (element.createdOn !== "") {
          element.update = moment(element.createdOn).toISOString();
        } else {
          element.update = moment().subtract(6, "month").toISOString();
        }
      }
      if (!element?.id || element?.id === "") {
        if (element?.description && element?.description !== "") {
          console.log(`${element?.description}, ${element?.id || "hapana"}`);
          // this.syncNotice = true;
          this.correctTaskId(element).subscribe((tasksFnd) => {element = tasksFnd; console.log(element)});
        }
      }
      // element = this.identifyUndefined(element);
    });
    const today = moment().format("L");
    const eTasks = tsksArr.filter((task) => {
      const ind = task.participants?.findIndex(
        (man) => man.id === this.acc?.id
      );
      if (task.byId === this.acc?.id) {
        return task;
      } else if (task.champion?.id === this.acc?.id) {
        return task;
      } else if (ind > -1) {
        return task;
      } else {
        // task.nomine = true;
        // console.log(task.name || task.description, task.id);
      }
    });
    // console.log('My tasks', eTasks?.length)
    this.priorityProjects(this.projects, eTasks);
    this.tasksCompleted = eTasks.filter((tas) => tas.complete === true);
    this.tasksTodo = eTasks.filter((tas) => tas.complete === false || tas.complete === undefined);
    const arr1 = this.tasksCompleted;
    const arr2 = this.tasksTodo;

    // const array3 = eTasks.filter(obj => (!arr2.some(({id}) => obj.id === id) && (!arr1.some(({id}) => obj.id === id))));
    // console.log(array3);
    this.allMyTasks = eTasks;
    this.filtersLoaded = true;
    this.daytoDate(
      this.allMyTasks.filter((task) => {
        const pr = task?.workHours?.find((wrk) => {
          if (moment(wrk.time).format("LL") === moment().format("LL")) {
            // console.log(moment(wrk.time).format('LL'), moment().format('LL'))
            return task;
          }
        });
        if (task?.workHours && pr) {
          return task;
        }
      })
    );
    const wksTasks = eTasks.filter((tas) => tas.complete === false);
    this.wksTasks = wksTasks;
    this.doDiary(wksTasks);
    this.UpcomingTAsks = [];
    this.OutstandingTasks = [];
    this.CurrentTAsks = [];
    this.ShortTermTAsks = [];
    this.MediumTermTAsks = [];
    this.LongTermTAsks = [];
    const wkasks: any[] = this.trackMyTasks(wksTasks);
    const workTaskNotices = [];
    wkasks.forEach((element) => {
      if (moment(element.startDate).isSame(today)) {
        // console.log(element.name);
        const upDate = moment(element.update).format("L");
        const dd = new Date().toISOString();
        if (moment(upDate).isSame(today)) {
          // console.log('same it update earlier on');
          if (element.priority === undefined) {
            const priority = {
              orderNo: 0,
              set: true,
            };
            if (element.id && element.id !== "") {
              this.afs
                .doc(`Users/${this.userId}/tasks/${element.id}`)
                .update({ update: dd, priority: priority })
                .then(() => {
                  // console.log(element.name, element.description, 'update: date string updated');
                });
            }
          }
        } else if (!moment(upDate).isSame(today)) {
          element.update = dd;
          if (element.priority === undefined) {
            const priority = {
              orderNo: 0,
              set: true,
            };
            element.priority = priority;
            this.afs
              .doc(`Users/${this.userId}/tasks/${element.id}`)
              .update({ update: dd, priority: priority })
              .then(() => {
                // console.log(element.name, element.description, 'update: date string updated');
              });
          }
          if (element.complete === false) {
            if (element.startTime !== "") {
              element.startTime = "";
              this.afs
                .doc(`Users/${this.userId}/tasks/${element.id}`)
                .update({ startTime: "" })
                .then(() => {
                  // console.log(element.name, element.description, 'update: date string updated');
                });
            }
            if (element.finishTime !== "") {
              element.finishTime = "";
              this.afs
                .doc(`Users/${this.userId}/tasks/${element.id}`)
                .update({ finishTime: "" })
                .then(() => {
                  // console.log(element.name, element.description, 'update: date string updated');
                });
            }
            if (element.targetQty !== "") {
              element.targetQty = "";
              this.afs
                .doc(`Users/${this.userId}/tasks/${element.id}`)
                .update({ targetQty: "" })
                .then(() => {
                  // console.log(element.name, element.description, 'update: date string updated');
                });
            }
          }
        }
      }
      if (!element.supervisor) {
        if (element.champion && element.companyId) {
          this.afs
            .doc<companyStaff>(
              `Enterprises/${element.companyId}/Participants/${element.champion.id}`
            )
            .ref.get()
            .then((doc) => {
              if (doc.exists) {
                if (doc.data().supervisor) {
                  element.supervisor = doc.data().supervisor;
                } else {
                  element.supervisor = { name: "", id: "" };
                }
              }
            });
        }
      }
      // console.log(data.workStatus, data.startDate, data.startDate, data.name, data.description);
      const compCollection = this.afs.collection("Enterprises");
      const projCollection = this.myDocument.collection("projects");
      // checking if the attributes are there { project/ enterprise Champ and department }
      // console.log(element.update)
      if (element.type === "planned") {
        // if (element.type === 'planned' || element.champion.id === this.acc.id) {
        if (!element.update) {
          element.update = element.UpdatedOn || element.createdOn || '';
        }
        if (element.classification) {
          if (
            element.classification.name === "Work" &&
            element.companyId !== ""
          ) {
            if (element.companyId !== "" && element.companyName === "") {
              compCollection
                .doc(element.companyId)
                .ref.get()
                .then((cmp) => {
                  if (cmp.exists) {
                    element.companyName = cmp.data().name;
                  }
                })
                .catch((err) => {
                  // console.log(err + ' ' + 'No Company Name found' + ' ' + element.name + ' ' + element.id);
                });
            }
            if (element.projectId !== "" && element.projectName === "") {
              projCollection
                .doc(element.projectId)
                .ref.get()
                .then((prjDoc) => {
                  if (prjDoc.exists) {
                    element.projectName = prjDoc.data().name;
                  }
                })
                .catch((err) => {
                  // console.log(err + ' ' + 'No Company Name found' + ' ' + element.name + ' ' + element.id);
                });
            }
          } else if (element.projectId !== "" && element.projectName === "") {
            projCollection
              .doc(element.projectId)
              .ref.get()
              .then((prjDoc) => {
                if (prjDoc.exists) {
                  element.projectName = prjDoc.data().name;
                }
              })
              .catch((err) => {
                // console.log(err + ' ' + 'No Company Name found' + ' ' + element.name + ' ' + element.id);
              });
          }
        }
      }
      if (
        element?.classification?.name === "Work" &&
        element.companyId === ""
      ) {
        workTaskNotices.push(element);
      }
      // console.log(taskNotices);
      this.workTaskNotices = workTaskNotices;
    });
    this.priorityData(wkasks);
    this.priorityEntTasks(wkasks);
    // this.myTasks = [];
    this.allActions = wkasks;
    this.loading = true;
    if(wkasks?.length > 0){
      this.myTasks = wkasks;
      this.myTasks = this.myTasks?.sort((a, b) => b?.update?.localeCompare(a?.update));
    } else {
      this.myTasks = [];
    }
  }

  correctTaskId(x: Task) {
    // console.log(this.userId);
    const singleDoc = this.afs.collection<any>(`Users/${this.userId}/tasks`, (ref) =>
        ref.where("description", "==", x?.description).where('UpdatedOn', '==', x?.UpdatedOn).where('byId', '==', x?.byId)
      ).snapshotChanges().pipe(
        // tap(),
        // tap(docs => console.log("docs", docs)),
        map(val => (val.length > 0 ? val[0] : null))
      );
      // console.log(singleDoc);
    return singleDoc;
  }

  correctTaskIdarxh(x: Task) {
    // console.log(this.userId);
    let xamended;
    const singleDoc = this.afs.collection(`Users/${this.userId}/tasks`, (ref) =>
        ref.where("description", "==", x?.description).where('UpdatedOn', '==', x?.UpdatedOn)
      ).snapshotChanges().pipe(
        tap(docs => console.log("docs", docs)),
        map(val => (val.length > 0 ? val[0] : null))
      );
      singleDoc.subscribe((tasksFnd) =>   xamended = tasksFnd);
      console.log(singleDoc);
    return singleDoc;
  }

  setUpdatedTask(x, setTask: any) {
    // setTask.update = new Date().toISOString();
    // console.log('the actionItem -->' + setTask.name);
    if (!setTask.id && setTask.id === "") {
    } else {
      if (setTask.champion?.id) {
        this.afs
          .doc(`Users/${this.acc?.id}/tasks/${setTask.id}`)
          .update(setTask)
          .then(() => {
            // console.log('document updated');
            /* ----------------------- Set every Other Node --------------------------- */
            if (setTask.byId !== setTask.champion.id) {
              this.afs
                .doc(`Users/${setTask.byId}/tasks/${setTask.id}`)
                .update(setTask)
                .then(() => {
                  // console.log('byId document updated');
                })
                .catch((error) => {
                  // console.log('Error byId document updating Completion, document does not exists trying Again', error);
                  this.afs
                    .doc(`Users/${setTask.byId}/tasks/${setTask.id}`)
                    .set(setTask)
                    .then(() => {
                      // console.log('document updated');
                    });
                });
            }
            if (setTask.participants?.length > 0) {
              setTask.participants.forEach((element) => {
                this.afs
                  .doc(`Users/${element.id}/tasks/${setTask.id}`)
                  .update(setTask)
                  .then(() => {
                    // console.log('supervisor.id document updated');
                  })
                  .catch((error) => {
                    // console.log('Error byId document updating Completion, document does not exists trying Again', error);
                    this.afs
                      .doc(`Users/${element.id}/tasks/${setTask.id}`)
                      .set(setTask)
                      .then(() => {
                        // console.log('document updated');
                      });
                  });
              });
            }
            if (setTask.supervisor.id !== "") {
              this.afs
                .doc(`Users/${setTask.supervisor.id}/tasks/${setTask.id}`)
                .update(setTask)
                .then(() => {
                  // console.log('supervisor.id document updated');
                })
                .catch((error) => {
                  // console.log('Error byId document updating Completion, document does not exists trying Again', error);
                  this.afs
                    .doc(`Users/${setTask.supervisor.id}/tasks/${setTask.id}`)
                    .set(setTask)
                    .then(() => {
                      // console.log('document updated');
                    });
                });
            }
            this.afs
              .collection("tasks")
              .doc(setTask.id)
              .update({
                id: setTask.id,
                description: setTask.description,
                name: setTask.name,
                companyName: setTask.companyName,
                companyId: setTask.companyId,
                projectName: setTask.projectName,
                projectId: setTask.projectId,
                section: setTask.section,
                startDate: setTask.startDate,
                endDate: setTask.endDate,
              })
              .then(() => {
                // console.log('document updated');
              })
              .catch((error) => {
                // console.log('Error updating Completion, document does not exists trying Again', error);
                this.afs
                  .collection("tasks")
                  .doc(setTask.id)
                  .set(setTask)
                  .then(() => {
                    // console.log('document updated');
                  });
              });
            /* --------------------- End Set every Other Node -------------------------- */
          })
          .catch((error) => {
            // console.log('Error updating Completion, document does not exists trying Again', error);
            this.afs
              .doc(`Users/${this.acc?.id}/tasks/${setTask.id}`)
              .set(setTask)
              .then(() => {
                // console.log('Re-added done');
                /* ----------------------- Set every Other Node --------------------------- */
                if (setTask.byId !== setTask.champion.id) {
                  const part = {
                    name: setTask.by,
                    id: setTask.byId,
                  };
                  this.afs
                    .doc(`Users/${setTask.byId}/tasks/${setTask.id}`)
                    .update(setTask)
                    .then(() => {
                      // console.log('byId document updated');
                    })
                    .catch((errors) => {
                      // console.log('Error byId document updating Completion, document does not exists trying Again', errors);
                      this.afs
                        .doc(`Users/${setTask.byId}/tasks/${setTask.id}`)
                        .set(setTask)
                        .then(() => {
                          // console.log('document updated');
                        });
                    });
                }
                if (setTask.participants?.length > 0) {
                  setTask.participants.forEach((element) => {
                    this.afs
                      .doc(`Users/${element.id}/tasks/${setTask.id}`)
                      .update(setTask)
                      .then(() => {
                        // console.log('supervisor.id document updated');
                      })
                      .catch((errors) => {
                        // console.log('Error byId document updating Completion, document does not exists trying Again', errors);
                        this.afs
                          .doc(`Users/${element.id}/tasks/${setTask.id}`)
                          .set(setTask)
                          .then(() => {
                            // console.log('document updated');
                          });
                      });
                  });
                }
                if (setTask.supervisor && setTask.supervisor.id !== "") {
                  this.afs
                    .doc(`Users/${setTask.supervisor.id}/tasks/${setTask.id}`)
                    .update(setTask)
                    .then(() => {
                      // console.log('supervisor.id document updated');
                    })
                    .catch((errors) => {
                      // console.log('Error byId document updating Completion, document does not exists trying Again', errors);
                      this.afs
                        .doc(
                          `Users/${setTask.supervisor.id}/tasks/${setTask.id}`
                        )
                        .set(setTask)
                        .then(() => {
                          // console.log('document updated');
                        });
                    });
                }
                this.afs
                  .collection("tasks")
                  .doc(setTask.id)
                  .update({
                    description: setTask.description,
                    name: setTask.name,
                    companyName: setTask.companyName,
                    companyId: setTask.companyId,
                    projectName: setTask.projectName,
                    projectId: setTask.projectId,
                    section: setTask.section,
                    startDate: setTask.startDate,
                    endDate: setTask.endDate,
                  })
                  .then(() => {
                    // console.log('document updated');
                  })
                  .catch((errors) => {
                    // console.log('Error updating Completion, document does not exists trying Again', errors);
                    this.afs
                      .collection("tasks")
                      .doc(setTask.id)
                      .set(setTask)
                      .then(() => {
                        // console.log('document updated');
                      });
                  });
              })
              .catch((errz) => console.log("err", errz));
          });
      }
    }
  }

  autoPriorityRed(tsk) {
    const task = tsk;
    if (!tsk?.priority) {
      task.priority = {
        orderNo: 0,
        set: false,
      };
    }
    // console.log(task.name, task.description, 'to be priority.set = false', task.champion.name);
    task.priority.set = false;
    task.selectedWork = false;
    if (task.tag === "std") {
      this.afs
        .doc(`Users/${this.userId}/myStandards/${task.id}`)
        .update(task)
        .then(() => {
          // console.log(task.name, task.description, task.period, 'update: date string updated');
        });
    } else if (task.tag === "sub") {
      if (task.champion.id === this.userId) {
        this.updateTask(task);
      } else {
        this.afs
          .doc(`Users/${this.userId}/tasks/${task.id}`)
          .update(task)
          .then(() => {
            // console.log(task.name, task.description, 'update: orderList update string updated');
          });
      }
    }
    return task;
  }

  priorityUpdate(task) {
    // console.log(task.name, task.description, 'to be priority set');
    if (task.tag === "std") {
      this.afs
        .doc(`Users/${this.userId}/myStandards/${task.id}`)
        .update(task)
        .then(() => {
          // console.log(task.name, task.description, task.period, 'update: date string updated');
        });
    } else if (task.tag === "sub") {
      this.updateTask(task);

      // this.afs.doc(`Users/${this.userId}/tasks/${task.id}`).update(task).then(() => {
      //   console.log(task.name, task.description, 'update: orderList update string updated');
      // })
    }
    return task;
  }

  updateTask(setTask) {
    setTask.update = new Date().toISOString();
    if (setTask.champion?.id) {
      this.afs
        .doc(`Users/${setTask.champion?.id}/tasks/${setTask.id}`)
        .update(setTask)
        .then(() => {
          // console.log('document updated');
          /* ----------------------- Set every Other Node --------------------------- */
          this.afs
            .collection("tasks")
            .doc(setTask.id)
            .update(setTask)
            .then(() => {
              // console.log('document updated');
            })
            .catch((error) => {
              this.afs
                .collection("tasks")
                .doc(setTask.id)
                .set(setTask)
                .then(() => {});
              // console.log('Error updating Completion, document does not exists trying Again', error);
            });
          if (setTask.byId !== setTask.champion.id) {
            this.afs
              .doc(`Users/${setTask.byId}/tasks/${setTask.id}`)
              .update(setTask)
              .then(() => {
                // console.log('document updated');
              })
              .catch((error) => {
                this.afs
                  .doc(`Users/${setTask.byId}/tasks/${setTask.id}`)
                  .set(setTask)
                  .then(() => {});
                // console.log('Error updating Completion, document does not exists trying Again', error);
              });
          }
          if (
            this.userId !== setTask.byId &&
            this.userId !== setTask.champion.id
          ) {
            this.afs
              .doc(`Users/${this.userId}/tasks/${setTask.id}`)
              .update(setTask)
              .then(() => {
                // console.log('document updated');
              })
              .catch((error) => {
                this.afs
                  .doc(`Users/${this.userId}/tasks/${setTask.id}`)
                  .set(setTask)
                  .then(() => {});
                // console.log('Error updating Completion, document does not exists trying Again', error);
              });
          }
          if (setTask.participants?.length > 0) {
            setTask.participants.forEach((element) => {
              this.afs
                .doc(`Users/${element.id}/tasks/${setTask.id}`)
                .update(setTask)
                .then(() => {
                  // console.log('document updated');
                })
                .catch((error) => {
                  this.afs
                    .doc(`Users/${element.id}/tasks/${setTask.id}`)
                    .set(setTask)
                    .then(() => {});
                  // console.log('Error updating Completion, document does not exists trying Again', error);
                });
            });
          }
          if (
            setTask.supervisor.id !== "" &&
            setTask.supervisor.id !== setTask.byId
          ) {
            this.afs
              .doc(`Users/${setTask.supervisor.id}/tasks/${setTask.id}`)
              .update(setTask)
              .then(() => {
                // console.log('document updated');
              })
              .catch((error) => {
                this.afs
                  .doc(`Users/${setTask.supervisor.id}/tasks/${setTask.id}`)
                  .set(setTask)
                  .then(() => {});
                // console.log('Error updating Completion, document does not exists trying Again', error);
              });
          }
          /* --------------------- End Set every Other Node -------------------------- */
        })
        .catch((error) => {
          // console.log('Error updating Completion, document does not exists trying Again', error);
        });
    }
  }

  updateStd(data) {
    const priority = {
      orderNo: 0,
      set: true,
    };
    data.priority = priority;
    this.afs
      .doc(`Users/${this.userId}/myStandards/${data.id}`)
      .update(data)
      .then(() => {
        // console.log(data.name, data.period, 'update: date string updated');
      });
  }

  checkdiary(tasks: Task[]) {
    // console.log('checking Diary');
    tasks.forEach((element) => {
      if (element.champion.id === this.userId) {
        const diaryTsk = this.diaryTasks.find((tsk) => (tsk.id === element.id));
        if (diaryTsk) {
          return diaryTsk;
        }
      } else {
        if (element.champion.id !== "") {
          this.afs
            .doc(`Users/${element.champion.id}/tasks/${element.id}`)
            .ref.get()
            .then((tskData) => {
              if (tskData.exists) {
                return tskData.data() as Task;
              }
            });
        } else {
          return element;
        }
      }
    });
    tasks.filter((tk) => tk.complete === false);
    return tasks;
  }

  screenDayTasks() {
    const day = moment().format("L");
    this.dayTasks.forEach((element: any) => {
      // console.log(element.description, element?.day?.hours, element?.day?.id, day);
      if (element?.day?.hours && moment(element?.day?.id).format("L") !== day) {
        // console.log('1st If', element.description || element.name, element?.day?.hours, element?.day?.id, day);
        element.day = { id: "", hours: 0 };
      } else if (
        element?.day?.hours &&
        moment(element?.day?.id).format("L") === day
      ) {
        // console.log('2nd If', element.description || element.name, element?.day?.hours, element?.day?.id, day);
      } else {
        // console.log('else', element.description || element.name, element?.day?.hours, element?.day?.id, day);
        element.day = { id: "", hours: 0 };
      }
    });
  }

  doDiary(wksTasks) {
    // data.selectedWork = true;
    const tosetFalse = wksTasks.filter(
      (tas) => tas.selectedWork === true && tas.priority?.set === false
    );
    if (tosetFalse.length > 0) {
      tosetFalse.forEach((element) => {
        this.afs
          .doc(`Users/${this.userId}/tasks/${element.id}`)
          .update({ selectedWork: false });
      });
    }
    this.dayTasks = this.stdArray.concat(wksTasks)
      .filter((tas) => tas.selectedWork === true && tas.priority?.set === true)
      .sort((a, b) => a.startTime?.localeCompare(b.startTime));
    this.screenDayTasks();
    // this.priorityTasks = wksTasks.filter(tas => tas.priority !== undefined && tas.champion?.id === this.userId);
    this.priorityTasks = wksTasks.filter((tas) => tas.priority !== undefined);
    this.priorityTasks = this.priorityTasks.concat(this.stdArray).filter((tas) => tas.priority.set === true);
    this.diaryTasks = this.priorityTasks;
    Promise.all(this.diaryTasks).then(() => {
      this.diaryTasks.forEach((element) => {
        if (moment(element.update).format("L") !== moment().format("L")) {
          element.update = new Date().toISOString();
          if (element.tag === "std") {
            this.diaryStandard(element);
          } else {
            this.diaryTask(element);
          }
        }
        this.diaryTasks = this.dp(this.diaryTasks);
      });
      if (this.diaryTasks.length > 10) {
        // console.log('diaryTasks:', this.diaryTasks);
        for (let index = 10; index < this.diaryTasks.length; index++) {
          const element = this.diaryTasks[index];
          this.autoPriorityRed(element);
        }
      }
    });
  }

  diaryStandard(element) {
    if (element.targetQty !== "" && element.startTime !== "" && element.finishTime !== "") {
      element.targetQty = ""; element.startTime = ""; element.finishTime = "";
      this.afs.doc(`Users/${this.userId}/myStandards/${element.id}`).update(element);
    } else if (element.startTime !== "" || element.finishTime !== "") {
      element.startTime = element.finishTime = "";
      this.afs.doc(`Users/${this.userId}/myStandards/${element.id}`).update(element);
    } else if (element.targetQty !== "") {
      element.targetQty = "";
      this.afs.doc(`Users/${this.userId}/myStandards/${element.id}`).update(element);
    }
  }

  diaryTask(element) {
    if (element.targetQty !== "" && element.startTime !== "" && element.finishTime !== "") {
      element.targetQty = element.startTime = element.finishTime = "";
      this.afs.doc(`Users/${this.userId}/tasks/${element.id}`).update(element);
    } else if (element.startTime !== "" || element.finishTime !== "") {
      element.startTime = element.finishTime = "";
      this.afs.doc(`Users/${this.userId}/tasks/${element.id}`).update(element);
    } else if (element.targetQty !== "") {
      element.targetQty = "";
      this.afs.doc(`Users/${this.userId}/tasks/${element.id}`).update(element);
    }
  }

  dp(items) {
    // console.log('now Sorting');
    items = items.sort((a, b) => a.priority.orderNo - b.priority.orderNo);
    items.forEach((element) => {
      const indx = items.findIndex((tsk) => tsk.id === element.id);
      element.priority.orderNo = indx;
    });
    return items;
  }

  runDiary() {
    this.doDiary(this.wksTasks);
    this.groupStds(this.stds);
  }

  afsSetTask(task) {
    if (task.tag === "sub") {
      this.afs
        .doc(`Users/${this.userId}/tasks/${task.id}`)
        .update(task)
        .then(() => {
          // console.log(task.description, task.name, task.priority.orderNo, 'update: task Order Updated string updated');
        });
    } else if (task.tag === "std") {
      this.afs
        .doc(`Users/${this.userId}/myStandards/${task.id}`)
        .update(task)
        .then(() => {
          // console.log(task.name, task.priority.orderNo, 'update: std Order Updated string updated');
        });
    }
  }

  trackMyTasks(tasks) {
    this.UpcomingTAsks = [];
    this.OutstandingTasks = [];
    this.CurrentTAsks = [];
    this.ShortTermTAsks = [];
    this.MediumTermTAsks = [];
    this.LongTermTAsks = [];
    // const Tasks = tasks.filter(x => x.complete === false);
    const Tasks = tasks;
    Tasks.forEach((element) => {
      const today = moment().format("L");
      const tday = moment();
      // checking task schedule
      const endDate = moment(element.endDate, "L").format("L");
      const startDate = moment(element.startDate, "L").format("L");
      if (element.startDate) {
        if (
          moment(startDate).isSameOrBefore(today) &&
          moment(endDate).isSameOrAfter(today)
        ) {
          // currentWorkItems
          element.workStatus = "#F9F2BA";
          this.CurrentTAsks.push(element);
          // console.log('CurrentTAsks' , element.description || element.name , element.champion.name);
        }
        // outstanding tasks
        if (moment(endDate).isBefore(today)) {
          element.workStatus = "#FFCECE";
          this.OutstandingTasks.push(element);
          this.OutstandingTasks.forEach((ts) => {
            if (ts.endDate && ts.endDate !== "") {
              ts.delayDays = moment().diff(moment(ts.startDate, "L"), "days");
              if (10 < ts.days && ts.days <= 35) {
                ts.delayDays = `${moment().diff(moment(ts.startDate, "L"),"weeks")} week(s)`;
              } else if (36 < ts.days && ts.days <= 365) {
                ts.delayDays = `${moment().diff(moment(ts.startDate, "L"), "months")} month(s)`;
              } else if (ts.days > 365) {
                ts.delayDays = `${moment().diff(moment(ts.startDate, "L"), "years")} year(s)`;
              } else {
                ts.delayDays = `${moment().diff(moment(ts.startDate, "L"), "days")} day(s)`;
              }
            } else {
              element.delayDays = "No dates set";
            }
            // console.log('OutstandingTasks' , element.description || element.name , element.champion.name);
          });
        }
        // Upcoming tasks
        if (moment(startDate).isAfter(today)) {
          element.workStatus = "#D1E5F3";
          // console.log('UpcomingTAsks' , element.description || element.name , element.champion.name);
          this.UpcomingTAsks.push(element);
          this.UpcomingTAsks.forEach((ts) => {
            if (ts.endDate && ts.endDate !== "") {
              ts.delayDays = moment().to(moment(ts.startDate));
            } else {
              ts.delayDays = "No dates set";
            }
          });
          if (moment(startDate).isSameOrBefore(tday.add(3, "month"))) {
            this.ShortTermTAsks.push(element);
          } else if (moment(startDate).isSameOrBefore(tday.add(12, "month"))) {
            this.MediumTermTAsks.push(element);
          } else if (moment(startDate).isAfter(tday.add(12, "month"))) {
            this.LongTermTAsks.push(element);
            // console.log('long term Tasks' + ' ' + this.LongTermTAsks);
          }
        }
      }
    });
    const wksTasks = Tasks;
    return wksTasks;
  }

  trackTasks(Tasks) {
    const UpcomingTAsks = [];
    const OutstandingTasks = [];
    const CurrentTAsks = [];
    const ShortTermTAsks = [];
    const MediumTermTAsks = [];
    const LongTermTAsks = [];
    Tasks.forEach((element) => {
      const today = moment().format("L");
      const tday = moment();
      // checking task schedule
      const endDate = moment(element.endDate, "L").format("L");
      const startDate = moment(element.startDate, "L").format("L");
      if (element.startDate) {
        if (
          moment(startDate).isSameOrBefore(today) &&
          moment(endDate).isSameOrAfter(today)
        ) {
          // currentWorkItems
          element.workStatus = "#F9F2BA";
          CurrentTAsks.push(element);
          // console.log('CurrentTAsks' , element.description || element.name , element.champion.name);
        }
        // outstanding tasks
        if (moment(endDate).isBefore(today)) {
          element.workStatus = "#FFCECE";
          OutstandingTasks.push(element);
          OutstandingTasks.forEach((ts) => {
            if (ts.endDate && ts.endDate !== "") {
              ts.delayDays = moment().diff(moment(ts.startDate, "L"), "days");
              if (10 < ts.days && ts.days <= 35) {
                ts.delayDays = `${moment().diff(moment(ts.startDate, "L"), "weeks")} week(s)`;
              } else if (36 < ts.days && ts.days <= 365) {
                ts.delayDays = `${moment().diff(moment(ts.startDate, "L"),"months")} month(s)`;
              } else if (ts.days > 365) {
                ts.delayDays = `${moment().diff(moment(ts.startDate, "L"), "years")} year(s)`;
              } else {
                ts.delayDays = `${moment().diff(moment(ts.startDate, "L"), "days" )} day(s)`;
              }
            } else {
              element.delayDays = "No dates set";
            }
            // element.workStatus = '#FFCECE';
          });
          // console.log('OutstandingTasks' , element.description || element.name , element.champion.name);
        }
        // Upcoming tasks
        if (moment(startDate).isAfter(today)) {
          element.workStatus = "#D1E5F3";
          // console.log('UpcomingTAsks' , element.description || element.name , element.champion.name);
          UpcomingTAsks.push(element);
          if (moment(startDate, "L").isSameOrBefore(tday.add(3, "month"))) {
            ShortTermTAsks.push(element);
          } else if (moment(startDate, "L").isSameOrBefore(tday.add(12, "month"))) {
            MediumTermTAsks.push(element);
          } else if (moment(startDate, "L").isAfter(tday.add(12, "month"))) {
            LongTermTAsks.push(element);
            // console.log('long term Tasks' + ' ' + this.LongTermTAsks);
          }
        }
      }
    });
    const wksTasks = Tasks;
    const Upcoming = UpcomingTAsks;
    const Outstanding = OutstandingTasks;
    const Current = CurrentTAsks;
    const ShortTerm = ShortTermTAsks;
    const MediumTerm = MediumTermTAsks;
    const LongTerm = LongTermTAsks;

    // return wksTasks;
    return {
      wksTasks: wksTasks,
      Upcoming: Upcoming,
      Outstanding: Outstanding,
      Current: Current,
      ShortTerm: ShortTerm,
      MediumTerm: MediumTerm,
      LongTerm: LongTerm,
    };
  }

  async getPeers(task) {
    // console.log(task)
    // const dt = moment(task.update, 'YYYY-MM-DDTHH:mm:ss.SSS').subtract(1, 'days').format('YYYY-MM-DD');
    const dt = moment(task.update, "YYYY-MM-DDTHH:mm:ss.SSS").format(
      "YYYY-MM-DD"
    );
    // console.log(dt, task.update);
    if (task.companyId && task.projectId) {
      this.afs.collection<any>(`Projects/${task.projectId}/tasks`, (ref) => ref.where("name", "==", task.name).where("update", ">=", dt))
      
      const collRef = this.afs.collection<any>(`Projects/${task.projectId}/tasks`, (ref) => ref.where("name", "==", task.name).where("update", ">=", dt));
      collRef.ref.get().then(data => {
        data.docs.length;
        const tasks = [];
          for (let i = 0; i < data.docs?.length; i++) {
            if (i === data.docs?.length - 1) {
              const item = data.docs[0].data();
              tasks.push(item);
            }
            if (i === data.docs?.length - 1) {
              this.getPeerTasks(tasks);
            }
          }
      })

    } else if (task.companyId) {
      const collRef = this.afs.collection<any>(`Enterprises/${task.companyId}/tasks`, (ref) => ref.where("name", "==", task.name).where("update", ">=", dt));
      collRef.ref.get().then(data => {
        data.docs.length;
        const tasks = [];
        for (let i = 0; i < data.docs?.length; i++) {
          if (i === data.docs?.length - 1) {
            const item = data.docs[0].data();
            tasks.push(item);
          }
          if (i === data.docs?.length - 1) {
            this.getPeerTasks(tasks);
          }
        }
      })
    }
  }

  getPeerTasks(tasks) {
    const peerTasks: any[] = [];
    this.peerTasks = [];
    tasks = tasks.filter((x) => x !== null || undefined);
    tasks.forEach((element) => {
      if (!element.lastEntry) {
        this.afs.doc<any>(`Users/${element.champion.id}/tasks/${element.id}`)
          .ref.get()
          .then((ac) => {
            element = ac.data();
          });
      }
      peerTasks.push(element);
      this.peerTasks = peerTasks.sort((a, b) =>
        (b?.updateTime || b?.update).localeCompare(
          a?.updateTime || a?.update
        )
      );
    });
  }

  groupStds(stds) {
    this.stdArray = [];
    const today = moment().format("L");
    const day = moment().format("ddd"),
      month = moment().format("MMM"),
      year = moment().format("YYYY"),
      tday = moment().format("L"),
      Q = moment().format("Qo");
    // weekdaysShort: Array(7)
    // console.log(dayNum, ' ', day);
    const dd = new Date().toISOString();
    stds.forEach((data) => {
      const priority = {
        orderNo: 0,
        set: true,
      };
      const upDate = moment(data.update).format("L");
      if (data.period === "daily") {
        // console.log(data.name, data.period, upDate, today);
        if (data.priority === undefined) {
          this.updateStd(data);
          data.priority = priority;
          data.orderNo = data.priority.orderNo;
        }
        // if (upDate !== today) {
        if (!moment(upDate, "MM/DD/YYYY").isSame(moment(today, "MM/DD/YYYY"))) {
          // console.log(data.name, data.period, 'different dates', 'upDate', upDate, 'today', today);
          data.update = today;
          // console.log(data.name, data.period, 'to update');
          data.update = dd;
          data.priority = priority;
          // data.selectedWork = true;
          data.targetQty = "";
          data.start = "";
          data.end = "";
          this.afs
            .doc(`Users/${this.userId}/myStandards/${data.id}`)
            .update(data)
            .then(() => {
              // console.log(data.name, data.period, 'update: date string updated');
            });
        } else {
          // console.log(data.name, data.period, 'Same dates', 'upDate', upDate, 'today', today);
        }
        this.stdArray.push(data);
      } else if (data.period === "weekly") {
        // console.log(data.name, data.period);
        if (data.weekly?.mon === true && day === "Mon") {
          if (data.priority === undefined) {
            this.updateStd(data);
            data.priority = priority;
            data.orderNo = data.priority.orderNo;
          }
          this.stdArray.push(data);
          if (!moment(upDate).isSame(today)) {
            data.update = dd;
            data.priority = priority;
            // data.selectedWork = true;
            this.afs
              .doc(`Users/${this.userId}/myStandards/${data.id}`)
              .update(data)
              .then(() => {
                // console.log(data.name, data.period, 'Mon', data.weekly?.mon, 'day', day, 'update: date string updated');
              });
          }
        } else if (data.weekly?.tue === true && day === "Tue") {
          // console.log(data.name, data.period);
          if (data.priority === undefined) {
            this.updateStd(data);
            data.priority = priority;
            data.orderNo = data.priority.orderNo;
          }
          this.stdArray.push(data);
          if (!moment(upDate).isSame(today)) {
            data.update = dd;
            data.priority = priority;
            // data.selectedWork = true;
            this.afs
              .doc(`Users/${this.userId}/myStandards/${data.id}`)
              .update(data)
              .then(() => {
                // console.log(data.name, data.period, 'Tue', data.weekly?.mon, 'day', day, 'update: date string updated');
              });
          }
        } else if (data.weekly?.wed === true && day === "Wed") {
          // console.log(data.name, data.period);
          if (data.priority === undefined) {
            this.updateStd(data);
            data.priority = priority;
            data.orderNo = data.priority.orderNo;
          }
          this.stdArray.push(data);
          if (!moment(upDate).isSame(today)) {
            data.update = dd;
            data.priority = priority;
            // data.selectedWork = true;
            this.afs
              .doc(`Users/${this.userId}/myStandards/${data.id}`)
              .update(data)
              .then(() => {
                // console.log(data.name, data.period, 'Wed', data.weekly?.mon, 'day', day, 'update: date string updated');
              });
          }
        } else if (data.weekly?.thu === true && day === "Thu") {
          // console.log(data.name, data.period);
          if (data.priority === undefined) {
            this.updateStd(data);
            data.priority = priority;
            data.orderNo = data.priority.orderNo;
          }
          this.stdArray.push(data);
          if (!moment(upDate).isSame(today)) {
            data.update = dd;
            data.priority = priority;
            // data.selectedWork = true;
            this.afs
              .doc(`Users/${this.userId}/myStandards/${data.id}`)
              .update(data)
              .then(() => {
                // console.log(data.name, data.period, 'Thu', data.weekly?.mon, 'day', day, 'update: date string updated');
              });
          }
        } else if (data.weekly?.fri === true && day === "Fri") {
          // console.log(data.name, data.period);
          if (data.priority === undefined) {
            this.updateStd(data);
            data.priority = priority;
            data.orderNo = data.priority.orderNo;
          }
          this.stdArray.push(data);
          if (!moment(upDate).isSame(today)) {
            data.update = dd;
            data.priority = priority;
            // data.selectedWork = true;
            this.afs
              .doc(`Users/${this.userId}/myStandards/${data.id}`)
              .update(data)
              .then(() => {
                // console.log(data.name, data.period, 'Fri', data.weekly?.mon, 'day', day, 'update: date string updated');
              });
          }
        } else if (data.weekly?.sat === true && day === "Sat") {
          // console.log(data.name, data.period);
          if (data.priority === undefined) {
            this.updateStd(data);
            data.priority = priority;
            data.orderNo = data.priority.orderNo;
          }
          this.stdArray.push(data);
          if (!moment(upDate).isSame(today)) {
            data.update = dd;
            data.priority = priority;
            // data.selectedWork = true;
            this.afs
              .doc(`Users/${this.userId}/myStandards/${data.id}`)
              .update(data)
              .then(() => {
                // console.log(data.name, data.period, 'Sat', data.weekly?.mon, 'day', day, 'update: date string updated');
              });
          }
        } else if (data.weekly?.sun === true && day === "Sun") {
          // console.log(data.name, data.period);
          if (data.priority === undefined) {
            this.updateStd(data);
            data.priority = priority;
            data.orderNo = data.priority.orderNo;
          }
          this.stdArray.push(data);
          if (!moment(upDate).isSame(today)) {
            data.update = dd;
            data.priority = priority;
            // data.selectedWork = true;
            this.afs
              .doc(`Users/${this.userId}/myStandards/${data.id}`)
              .update(data)
              .then(() => {
                // console.log(data.name, data.period, 'Sun', data.weekly?.mon, 'day', day, 'update: date string updated');
              });
          }
        } else {
          if (data?.priority?.set) {
            data.priority.set = false;
            this.afs
              .doc(`Users/${this.userId}/myStandards/${data.id}`)
              .update(data)
              .then(() => {
                // console.log(data.name, data.period, 'Sun', data.weekly?.mon, 'day', day, 'update: date string updated');
              });
          }
        }
      } else if (data.period === "monthly") {
        data.startDate = moment(data.monthlyDate, "YYYY/MM/DD").format("L");
        // console.log(data.name, data.period, data.startDate);
        const datMon = moment(data.monthlyDate).format("MMM"),
          datDate = moment(data.monthlyDate).format("L");
        // console.log(data.name, data.period, 'month', month, 'datMon', datMon, 'tday', tday,
        //   'datDate', datDate, 'data.monthlyDate', data.monthlyDate, 'update: date string updated');
        if (
          data.monthlyDate &&
          data.monthlyDate !== "" &&
          month === datMon &&
          tday === datDate
        ) {
          // console.log(data.name, data.period, 'month', month, 'datMon', datMon, 'tday', tday, 'datDate', datDate,
          //  'data.monthlyDate', data.monthlyDate, 'update: date string updated');
          if (data.priority === undefined) {
            this.updateStd(data);
            data.priority = priority;
            data.orderNo = data.priority.orderNo;
          }
          this.stdArray.push(data);
          // console.log(this.stdArray);
          if (!moment(upDate).isSame(today)) {
            data.update = dd;
            data.priority = priority;
            // data.selectedWork = true;
            this.afs
              .doc(`Users/${this.userId}/myStandards/${data.id}`)
              .update(data)
              .then(() => {
                // console.log(data.name, data.period, 'month', month, 'datMon', datMon, 'tday', tday, 'datDate', datDate,
                // 'data.monthlyDate', data.monthlyDate, 'update: date string updated');
              });
          }
        } else {
          if (data.priority.set) {
            data.priority.set = false;
            this.afs
              .doc(`Users/${this.userId}/myStandards/${data.id}`)
              .update(data)
              .then(() => {
                // console.log(data.name, data.period, 'month', month, 'datMon', datMon, 'tday', tday,
                //   'datDate', datDate, 'data.monthlyDate', data.monthlyDate, 'update: date string updated');
              });
          }
        }
      } else if (data.period === "quarterly") {
        // console.log(data.name);
        data.quarter1 = moment(data.quarter1, "YYYY/MM/DD").format("L");
        data.quarter2 = moment(data.quarter2, "YYYY/MM/DD").format("L");
        data.quarter3 = moment(data.quarter3, "YYYY/MM/DD").format("L");
        data.quarter4 = moment(data.quarter4, "YYYY/MM/DD").format("L");
        // console.log(data.quarter1, data.quarter2, data.quarter3, data.quarter4);
        const datQ = moment().format("Qo"),
          datDate = moment(data.yearlyDate).format("L");
        if (
          data.quarter1 &&
          data.quarter1 !== "" &&
          Q === datQ &&
          tday === datDate
        ) {
          // console.log(data.name, data.period);
          if (data.priority === undefined) {
            this.updateStd(data);
            data.priority = priority;
            data.orderNo = data.priority.orderNo;
          }
          this.stdArray.push(data);
          if (!moment(upDate).isSame(today)) {
            data.update = dd;
            data.priority = priority;
            // data.selectedWork = true;
            this.afs
              .doc(`Users/${this.userId}/myStandards/${data.id}`)
              .update(data)
              .then(() => {
                // console.log(data.name, data.period, 'datDate', datDate, 'datQ', datQ, 'data.quarter1',
                //   data.quarter1, 'update: date string updated');
              });
          }
        } else if (
          data.quarter2 &&
          data.quarter2 !== "" &&
          Q === datQ &&
          tday === datDate
        ) {
          // console.log(data.name, data.period);
          if (data.priority === undefined) {
            this.updateStd(data);
            data.priority = priority;
            data.orderNo = data.priority.orderNo;
          }
          this.stdArray.push(data);
          if (!moment(upDate).isSame(today)) {
            data.update = dd;
            data.priority = priority;
            // data.selectedWork = true;
            this.afs
              .doc(`Users/${this.userId}/myStandards/${data.id}`)
              .update(data)
              .then(() => {
                // console.log(data.name, data.period, 'datDate', datDate, 'datQ', datQ, 'data.quarter2',
                //   data.quarter2, 'update: date string updated');
              });
          }
        } else if (
          data.quarter3 &&
          data.quarter3 !== "" &&
          Q === datQ &&
          tday === datDate
        ) {
          // console.log(data.name, data.period);
          if (data.priority === undefined) {
            this.updateStd(data);
            data.priority = priority;
            data.orderNo = data.priority.orderNo;
          }
          this.stdArray.push(data);
          if (!moment(upDate).isSame(today)) {
            data.update = dd;
            data.priority = priority;
            // data.selectedWork = true;
            this.afs
              .doc(`Users/${this.userId}/myStandards/${data.id}`)
              .update(data)
              .then(() => {
                // console.log(data.name, data.period, 'datDate', datDate, 'datQ', datQ, 'data.quarter3',
                //   data.quarter3, 'update: date string updated');
              });
          }
        } else if (
          data.quarter4 &&
          data.quarter4 !== "" &&
          Q === datQ &&
          tday === datDate
        ) {
          // console.log(data.name, data.period);
          if (data.priority === undefined) {
            this.updateStd(data);
            data.priority = priority;
            data.orderNo = data.priority.orderNo;
          }
          this.stdArray.push(data);
          if (!moment(upDate).isSame(today)) {
            data.update = dd;
            data.priority = priority;
            // data.selectedWork = true;
            this.afs
              .doc(`Users/${this.userId}/myStandards/${data.id}`)
              .update(data)
              .then(() => {
                // console.log(data.name, data.period, 'datDate', datDate, 'datQ', datQ, 'data.quarter4',
                //   data.quarter4, 'update: date string updated');
              });
          }
        } else {
          if (data.priority.set) {
            data.priority.set = false;
            this.afs
              .doc(`Users/${this.userId}/myStandards/${data.id}`)
              .update(data)
              .then(() => {
                // console.log(data.name, data.period, 'datDate', datDate, 'datQ', datQ,
                //   'datDate', datDate, 'update: date string updated');
              });
          }
        }
      } else if (data.period === "yearly") {
        data.yearlyDate = moment(data.yearlyDate, "YYYY/MM/DD").format("L");
        // console.log(data.name, data.period, data.yearlyDate);
        const datYr = moment(data.yearlyDate).format("YYYY"),
          datDate = moment(data.yearlyDate).format("L");
        if (data.yearlyDate !== "" && year === datYr && tday === datDate) {
          if (data.priority === undefined) {
            this.updateStd(data);
            data.priority = priority;
            data.orderNo = data.priority.orderNo;
          }
          this.stdArray.push(data);
          if (!moment(upDate).isSame(today)) {
            data.update = dd;
            data.priority = priority;
            // data.selectedWork = true;
            this.afs
              .doc(`Users/${this.userId}/myStandards/${data.id}`)
              .update(data)
              .then(() => {
                // console.log(data.name, data.period, 'datDate', datDate, 'datYr', datYr, 'data.yearlyDate',
                //   data.yearlyDate, 'update: date string updated');
              });
          }
        } else {
          if (data.priority.set) {
            data.priority.set = false;
            this.afs
              .doc(`Users/${this.userId}/myStandards/${data.id}`)
              .update(data)
              .then(() => {
                /* console.log(data.name, data.period, 'datDate', datDate, 'datYr', datYr, 'data.yearlyDate',
                data.yearlyDate, 'update: date string updated'); */
              });
          }
        }
      }
    });
  }

  getParticipants(task) {
    this.taskset = task;
    this.oldParts = task.participants;
    if (!task.companyId && !task.projectId) {
      this.staff = this.myContacts;
      /* console.log(this.staff, '1st If statement'); */
    } else if (task.companyId && task.projectId) {
      this.afs.collection(
          `Projects/${task.projectId}/enterprises/${task.companyId}/labour`,
          (ref) => ref.orderBy("name", "asc")
        )
        .valueChanges()
        .subscribe((labour) => {
          this.staff =
            labour; /* console.log(this.staff, '2nd if statement'); */
        });
    } else if (task.companyId && !task.projectId) {
      this.afs
        .collection(`Enterprises/${task.companyId}/Participants`, (ref) =>
          ref.orderBy("name", "asc")
        )
        .valueChanges()
        .subscribe((staff) => {
          this.staff = staff; /* console.log(this.staff, '3rd if Statement'); */
        });
    }
  }

  getEquipment(task) {
    this.selectedEquipment = [];
    this.selectedHiredEquipment = [];
    this.taskset = task;
    if (task.equipment) {
      this.selectedHiredEquipment = task.equipment.filter(
        (plant) => plant.type === "hired"
      );
      this.selectedEquipment = task.equipment.filter((plant) => {
        if (!plant.type || plant.type === "owned") {
          return plant;
        }
      });
    }
    if (task.companyId) {
      this.afs
        .collection<any>(`Enterprises/${task.companyId}/assets`, (ref) =>
          ref.orderBy("name", "asc")
        )
        .valueChanges()
        .subscribe((equipment) => {
          equipment.forEach((element) => {
            if (!element.type) {
              element.type = "owned";
              this.afs
                .doc(`Enterprises/${task.companyId}/assets/${element.id}`)
                .update({ type: "owned" })
                // .then(() => console.log('Equipment Type updated', element.name))
                .catch((error) => console.log(error));
            }
          });
          this.equipment =
            equipment; /*  console.log(this.equipment, 'Company equipment'); */
        });
      this.afs
        .collection<any>(`Enterprises/${task.companyId}/hiredAssets`, (ref) =>
          ref.orderBy("name", "asc")
        )
        .valueChanges()
        .subscribe((equipment) => {
          equipment.forEach((element) => {
            if (!element.type) {
              element.type = "hired";
              this.afs
                .doc(`Enterprises/${task.companyId}/hiredAssets/${element.id}`)
                .update({ type: "hired" })
                // .then(() => console.log('Equipment Type updated', element.name))
                .catch((error) => console.log(error));
            }
          });
          this.hiredEquipment = equipment;
          //  console.log(this.hiredEquipment, 'Hired company Equipment');
        });
    }
  }

  updateDiaryList(task, n) {
    const priority = {
      orderNo: n,
      set: true,
    };
    task.priority = priority;
    this.afs
      .doc(`Users/${this.userId}/tasks/${task.id}`)
      .update({ priority: priority })
      .then(() => {
        // console.log(task.name, task.description, 'update: orderList update string updated');
      });
  }

  dailyDiary(actions: any[]) {
    const viewDayActions = actions.filter(
      (it) =>
        moment(it.startDate).isSameOrBefore(this.today2) &&
        it.selectedWeekly === false
    );
    const mTasks = actions.filter((tas) => tas.selectedWeekly === true);
    return viewDayActions
      .concat(mTasks)
      .sort((a, b) => b.update.localeCompare(a.update));
  }

  searchWord(value: string) {
    let word = value;
    const results = [];
    this.myTasks.forEach((tsk) => {
      if (tsk.description) {
        const description = tsk.description.toLowerCase();
        if (word !== "" || " ") {
          word = word.toLowerCase();
          if (description.includes(word)) {
            // tsk.description = tsk.description.charAt(0).toUpperCase() + tsk.description.slice(1);
            results.push(tsk);
            // console.log(results);
            this.results = results;
          }
        }
      } else if (tsk.name) {
        const name = tsk.name.toLowerCase();
        if (word !== "" || " ") {
          word = word.toLowerCase();
          if (name.includes(word)) {
            // tsk.name = tsk.name.charAt(0).toUpperCase() + tsk.name.slice(1);
            results.push(tsk);
            // console.log(results);
            this.results = results;
          }
        }
      }
      return this.results;
    });
  }

  searchresult(value) {
    this.relResults = [];
    if (value !== "") {
      this.afs
        .collection<ParticipantData>("Users", (ref) =>
          ref.where("name", ">=", value)
        )
        .valueChanges()
        .subscribe((users) => {
          this.layWord(users, value);
        });
    }
  }

  layWord(coll: any[], value) {
    let word = value;
    const results = [];
    coll.forEach((man) => {
      // man.name = man.name.toLowerCase();
      const name = man?.name?.toLowerCase();
      if (word !== "" || " ") {
        word = word.toLowerCase();
        if (name?.includes(word)) {
          // man.name = man.name.charAt(0).toUpperCase() + man.name.slice(1);
          this.relResults = results;
          const usrIndex = this.myContacts.findIndex((us) => us.id === man.id);
          if (usrIndex > -1) {
            man.join = true;
            results.push(man);
            // console.log('true');
          } else {
            // console.log('false');
            man.join = false;
            results.push(man);
          }
        }
      }
      return this.relResults;
    });
  }

  notifications() {
    this.totalNotices = [];
    const all = this.afs.collection<any>(
      `Users/${this.userId}/allNotice`,
      (ref) => ref.where("read", "==", false)
    );
    all.valueChanges().subscribe((data) => {
      data = data.filter((x) => x != null);
      const ids = data.map((o) => o.id);
      const filtered = data.filter(
        ({ id }, index) => !ids.includes(id, index + 1)
      );
      this.totalNotices = filtered;
      // const newNot = data.find(x => x.read === false);
      const newNots = data.findIndex((x) => x.read === false);

      // if(newNot) { this.ns.showNotification('allNotice', 'top', 'right', newNot); }
      if (newNots > -1) {
        this.ns.showNotification("allNotice", "top", "right", "NewNots");
      }
      filtered.forEach((element) => {
        console.log(element.noti);
        // this.totalNotices.push(element);
        if (element?.notRing === false) {
          /* call ring notice () */
          //   this.ns.showNotification('allNotice', 'top', 'right', element);
          all.doc(element.id).update({ notRing: true });
        }
        if (
          element.noti === "ProjectRequests" &&
          element.company.roles &&
          element.company.roles.length > 0
        ) {
          console.log(element);
          if (typeof element.company.roles[0] === "object") {
            const newArr = [];
            element.company.roles.forEach((rol) => {
              const newStr = rol.display;
              rol = newStr;
              newArr.push(rol);
            });
            // console.log(newArr);
            Promise.all(newArr).then((dataRauya) => {
              // console.log('dataRauya', dataRauya);
              element.company.roles = dataRauya;
            });
            // console.log(element);
            all
              .doc(element.dataId)
              .update({ "company.roles": newArr })
              .then(() => {
                /* console.log('Role Structure Raita') */
              })
              .catch((er) => console.log("Raramba", er));
          }
        }
        // element.noti = 'ProjectRequests';
      });
      // this.taskNotices = data.filter(x => x.read === false);
      // this.totalNotices.concat(data);
      // console.log('taskNotification read', data);
    });
    // this.inicompltCus;
  }

  tasksComs(task) {
    // console.log(task.name, task.description)
    this.selectTask = task;
    this.afs
      .collection<comment>(`tasks/${task.id}/comments`, (ref) =>
        ref.orderBy("createdOn", "desc")
      )
      .valueChanges()
      .subscribe((coms) => {
        coms = coms.filter((x) => x !== null || undefined);
        this.tasksComments = coms;
        this.lastCommentBy = coms[coms.length - 1];
      });
  }

  transComs(trans) {
    // console.log(task.name, task.description)
    this.selectTrans = trans;
    this.afs
      .collection<comment>(`tasks/${trans.id}/comments`, (ref) =>
        ref.orderBy("createdOn", "desc")
      )
      .valueChanges()
      .subscribe((coms) => {
        coms = coms.filter((x) => x !== null || undefined);
        this.transComments = coms;
        this.lastTransCommentBy = coms[coms.length - 1];
      });
  }

  writingToProjects() {
    // console.log('writingToProjects');
    this.afs
      .collection("Projects")
      .snapshotChanges()
      .pipe(
        map((b) =>
          b.map((a) => {
            const data = a.payload.doc.data() as any;
            data.id = a.payload.doc.id;
            return data;
          })
        )
      )
      .subscribe((yur) => {
        yur.forEach((element) => {
          const entRef = this.afs
            .collection("Projects")
            .doc(element.id)
            .collection("enterprises")
            .snapshotChanges()
            .pipe(
              map((b) =>
                b.map((a) => {
                  const data = a.payload.doc.data() as any;
                  data.id = a.payload.doc.id;
                  return data;
                })
              )
            );
          this.sortCompanies(element, entRef);
        });
      });
  }

  sortCompanies(prj, companies: Observable<any[]>) {
    // console.log('In sortCompanies');
    companies = companies.filter((x) => x !== null || undefined);
    companies.subscribe((entArray) => {
      entArray.forEach((element) => {
        prj.companyId = element.id;
        prj.companyName = element.name;
        const dref2 = this.afs
          .collection("Projects")
          .doc(prj.id)
          .collection("enterprises")
          .doc(prj.companyId)
          .collection("sections")
          .snapshotChanges()
          .pipe(
            map((b) =>
              b.map((a) => {
                const data = a.payload.doc.data() as any;
                data.id = a.payload.doc.id;
                return data;
              })
            )
          );
        this.getSections(prj, dref2);
      });
    });
  }

  getSections(prj, sections: Observable<any[]>) {
    // console.log('In getSections');
    // sections.subscribe(data => {
    //   this.postAdSection(prj, data);
    // })
  }

  postAdSection(project, sections) {
    // console.log('In postAdSection');
    const projectId = project.id;
    // this.classesArray = [];
    // console.log('Adding Sections');
    const sect1 = {
      name: "Project Initiation and Preparation",
      createdOn: new Date().toISOString(),
      type: "superSection",
      id: "projectInitialization",
      no: 1,
      projectId: projectId,
      projectName: project.name,
      companyId: project.companyId,
      companyName: project.companyName,
      Bills: null,
    };
    const sect5 = {
      name: "Project Fund Raising",
      createdOn: new Date().toISOString(),
      type: "superSection",
      id: "projectfunding",
      no: 2,
      projectId: projectId,
      projectName: project.name,
      companyId: project.companyId,
      companyName: project.companyName,
      Bills: null,
    };
    const sect2 = {
      name: "Implementation Stage",
      createdOn: new Date().toISOString(),
      type: "superSection",
      id: "projectImplementation",
      no: 3,
      projectId: projectId,
      projectName: project.name,
      companyId: project.companyId,
      companyName: project.companyName,
      Bills: null,
    };
    const sect3 = {
      name: "Operation and Maintenance",
      createdOn: new Date().toISOString(),
      type: "superSection",
      id: "operationMaintenance",
      no: 3,
      projectId: projectId,
      projectName: project.name,
      companyId: project.companyId,
      companyName: project.companyName,
      Bills: null,
    };
    const sect4 = {
      name: "Divestment",
      createdOn: new Date().toISOString(),
      type: "superSection",
      id: "projectDivestiment",
      no: 5,
      projectId: projectId,
      projectName: project.name,
      companyId: project.companyId,
      companyName: project.companyName,
      Bills: null,
    };
    let value1, value2, value3, value4, value5;
    const dref2 = this.afs
      .collection("Projects")
      .doc(projectId)
      .collection("enterprises")
      .doc(project.companyId)
      .collection("sections");
    const entRef = this.afs
      .collection("Enterprises")
      .doc(project.companyId)
      .collection("projects")
      .doc(projectId)
      .collection("sections");
    // console.log(sections);
    const index1 = sections.findIndex(
      (workClass) => workClass.name === sect1.name
    );
    if (index1 > -1) {
      value1 = sections[index1].name;
    } else {
      if (value1 === sect1.name) {
        // console.log(value1 + ' ' + 'found, isNotSet');
        // dref2.doc(sect1.id).set(sect1);
        // entRef.doc(sect1.id).set(sect1);
      } else {
        dref2.doc(sect1.id).set(sect1);
        entRef.doc(sect1.id).set(sect1);
        // console.log(sect1.name + ' ' + 'isSet');
      }
    }
    const index5 = sections.findIndex(
      (workClass) => workClass.name === sect5.name
    );
    if (index5 > -1) {
      value5 = sections[index5].name;
    } else {
      if (value5 === sect5.name) {
        // console.log(value5 + ' ' + 'found, isNotSet');
        // dref2.doc(sect5.id).set(sect5);
        // entRef.doc(sect5.id).set(sect5);
        // console.log(sect5.name + ' ' + 'isNotSet');
      } else {
        dref2.doc(sect5.id).set(sect5);
        entRef.doc(sect5.id).set(sect5);
        // myProRef.doc(sect2.id).set(sect2);
        // console.log(value5 + ' ' + 'isSet');
      }
    }
    const index2 = sections.findIndex(
      (workClass) => workClass.name === sect2.name
    );
    if (index2 > -1) {
      value2 = sections[index2].name;
    } else {
      if (value2 === sect2.name) {
        // console.log(value2 + ' ' + 'found, isNotSet');
        // dref2.doc(sect2.id).set(sect2);
        // entRef.doc(sect2.id).set(sect2);
      } else {
        dref2.doc(sect2.id).set(sect2);
        entRef.doc(sect2.id).set(sect2);
        // myProRef.doc(sect2.id).set(sect2);
        // console.log(sect2.name + ' ' + 'isSet');
      }
    }
    const index3 = sections.findIndex(
      (workClass) => workClass.name === sect3.name
    );
    if (index3 > -1) {
      value3 = sections[index3].name;
    } else {
      if (value3 === sect3.name) {
        // console.log(value3 + ' ' + 'found, isNotSet');
        // dref2.doc(sect3.id).set(sect3);
        // entRef.doc(sect3.id).set(sect3);
      } else {
        dref2.doc(sect3.id).set(sect3);
        entRef.doc(sect3.id).set(sect3);
        // myProRef.doc(sect3.id).set(sect3);
        // console.log(sect3.name + ' ' + 'isSet, ');
      }
    }
    const index4 = sections.findIndex(
      (workClass) => workClass.name === sect4.name
    );
    if (index4 > -1) {
      value4 = sections[index4].name;
    } else {
      if (value4 === sect4.name) {
        // console.log(value4 + ' ' + 'found, isNotSet');
        // dref2.doc(sect4.id).set(sect4);
        // entRef.doc(sect4.id).set(sect4);
        // setClass.update(sect4);
      } else {
        dref2.doc(sect4.id).set(sect4);
        entRef.doc(sect4.id).set(sect4);
        // myProRef.doc(sect4.id).set(sect4);
        // console.log(sect4.name + ' ' + 'isSet');
      }
    }
  }

  calc(data) {
    let totalHours = 0;
    const tday = moment().format("L");
    const weekTime = moment().subtract(31, "d").format("L");
    if (data !== undefined) {
      data.forEach((item) => {
        const Subwrk = [];
        if (item.workHours !== null && item.workHours !== undefined) {
          if (item.workHours) {
            // item.workHours = item.workHours.filter(task => {
            item.workHours.filter((task) => {
              if (moment(task.time).isBetween(weekTime, tday)) {
                Subwrk.push(task);
                item.totalHours += task.hours;
                totalHours += task.hours;
                return task;
              }
            });
          }
        }
      });
    } else {
      totalHours = 0;
    }
    return totalHours;
  }

  checkActive(data) {
    let totalHours = 0;
    // let totalQty = 0;
    let rtValue: any;
    const tday = moment().format("L");
    const weekTime = moment().subtract(31, "d").format("L");
    if (data !== undefined) {
      data.forEach((item) => {
        const Subwrk = [];
        if (item.workHours !== null && item.workHours !== undefined) {
          if (item.workHours) {
            // item.workHours = item.workHours.filter(task => {}
            item.workHours.filter((task) => {
              if (moment(task.time).isBetween(weekTime, tday)) {
                Subwrk.push(task);
                item.totalHours += task.hours;
                totalHours += task.hours;
                return task;
              }
            });
          }
          // } else if (item?.targetQty && item?.targetQty > 0) {
        }
      });
    } else {
      totalHours = 0;
    }
    // if (totalQty) {
    //   return true;
    // } else
    if (totalHours) {
      rtValue = true;
      return rtValue;
    }
    return totalHours;
  }

  getSubtask(actions, uArr) {
    // console.log('Now getSubtask');
    let totalSubtask = 0;
    actions.forEach((sub) => {
      sub.quantity = 0;
      sub.amount = 0;
      if (sub.actuals !== undefined) {
        for (let index = 0; index < sub.actuals.length; index++) {
          const eleent = sub.actuals[index];
          if (
            moment(sub.actuals[index].updateTime).format("L") ===
            moment().format("L")
          ) {
            sub.quantity += eleent.qty;
            // console.log('qty', eleent.qty);
          }
        } // Stp Here
      } else {
        sub.quantity = 0;
      }
      //  RPCPD =  Revenue per capita per day
      const i = uArr.findIndex((ratewk) => ratewk.name === sub.name);
      if (i > -1) {
        // console.log(uArr[i].name);
        sub.rate = uArr[i].rate;
        if (sub.actuals === undefined) {
          // console.log('stuff is undefined hence rate = 1');
          sub.amount = sub.quantity * 1;
          totalSubtask += sub.amount;
        } else {
          sub.amount = sub.quantity * Number(sub.rate);
          // tt += sub.amount;
          totalSubtask += sub.amount;
        }
      } else {
      }
    });
    return totalSubtask;
  }

  setDs(proj) {
    // console.log(proj.name);
    const compId = proj.companyId;
    const projId = proj.id;
    // console.log(compId, projId);
    let productivityPerDay = 0,
      TotalCost = 0,
      RPCPD = 0,
      CostPerCapita = 0;
    if (projId !== "") {
      if (compId) {
        this.afs
          .collection("Projects")
          .doc(projId)
          .collection("enterprises")
          .doc(compId)
          .collection("WeeklyActions")
          .valueChanges()
          .subscribe((actions) => {
            this.afs
              .collection("Enterprises")
              .doc(compId)
              .collection("Rates")
              .valueChanges()
              .subscribe((Rates) => {
                // Rates = rates;
                this.afs
                  .collection("Projects")
                  .doc(projId)
                  .collection("enterprises")
                  .doc(compId)
                  .collection("labour")
                  .valueChanges()
                  .subscribe((labr) => {
                    // console.log('worker');
                    // console.log(labr);

                    const totallabour = labr.length;
                    const totalRevenue = this.getSubtask(actions, Rates);
                    labr.forEach((elem) => {
                      TotalCost += elem.cost;
                      CostPerCapita = TotalCost / totallabour;
                      RPCPD = totalRevenue / totallabour;
                    });
                    productivityPerDay = this.productivityPerDay(
                      CostPerCapita,
                      RPCPD
                    );
                    const PP = {
                      name: proj.name,
                      id: proj.id,
                      value: productivityPerDay,
                      // value: (100 - productivityPerDay)
                    };
                    this.tak.push(PP);
                    Promise.all(this.tak).then((values) => {
                      this.doAdds(values);
                    });
                  });
              });
          });
      }
    }
  }

  doAdds(data) {
    // console.log(data);
    this.pprojects = [];
    // let count = 80;
    const bigDats = [];
    let bigDats2 = [];
    data.forEach((element) => {
      const count = 0;
      // console.log(element.value);
      if (!isNaN(element.value)) {
        element.value = count;
      } else if (!isFinite(element.value)) {
        element.value = 100;
      } else if (element.value < 0) {
        element.value = 0;
      } else {
        /* console.log(element.value); */
      }
      bigDats.push(element);

      bigDats2 = bigDats.sort((a, b) => {
        return b.value - a.value;
      });
      const PP = { name: "ProjectA", value: 2 },
        PP1 = { name: "ProjectB", value: 2 },
        PP2 = { name: "ProjectC", value: 2 },
        PP3 = { name: "ProjectD", value: 2 },
        PP4 = { name: "ProjectE", value: 2 };
      if (
        bigDats2[0] !== undefined &&
        bigDats2[1] === undefined &&
        bigDats2[2] === undefined &&
        bigDats2[3] === undefined &&
        bigDats2[4] === undefined
      ) {
        const p0 = bigDats2[0];
        const top5 = [p0];
        this.pprojects = top5;
      } else if (
        bigDats2[0] &&
        bigDats2[1] !== undefined &&
        bigDats2[2] === undefined &&
        bigDats2[3] === undefined &&
        bigDats2[4] === undefined
      ) {
        const p0 = bigDats2[0],
          p1 = bigDats2[1];
        const top5 = [p0, p1];
        this.pprojects = top5;
      } else if (
        bigDats2[0] &&
        bigDats2[1] &&
        bigDats2[2] !== undefined &&
        bigDats2[3] === undefined &&
        bigDats2[4] === undefined
      ) {
        const p0 = bigDats2[0],
          p1 = bigDats2[1],
          p2 = bigDats2[2];
        const top5 = [p0, p1, p2];
        this.pprojects = top5;
      } else if (
        bigDats2[0] &&
        bigDats2[1] &&
        bigDats2[2] &&
        bigDats2[3] !== undefined &&
        bigDats2[4] === undefined
      ) {
        const p0 = bigDats2[0],
          p1 = bigDats2[1],
          p2 = bigDats2[2],
          p3 = bigDats2[3];
        const top5 = [p0, p1, p2, p3];
        this.pprojects = top5;
      } else if (
        bigDats2[0] &&
        bigDats2[1] &&
        bigDats2[2] &&
        bigDats2[3] &&
        bigDats2[4] !== undefined
      ) {
        const p0 = bigDats2[0],
          p1 = bigDats2[1],
          p2 = bigDats2[2],
          p3 = bigDats2[3],
          p4 = bigDats2[4];
        const top5 = [p0, p1, p2, p3, p4];
        this.pprojects = top5;
      } else {
        const p0 = PP,
          p1 = PP1,
          p2 = PP2,
          p3 = PP3,
          p4 = PP4;
        const top5 = [p0, p1, p2, p3];
        this.pprojects = top5;
      }
    });
  }

  productivityPerDay(CostPerCapita, RPCPD) {
    // return ((RPCPD / CostPerCapita) * 100)
    let bn = 0;
    if (
      (RPCPD === 0 && CostPerCapita === 0) ||
      (RPCPD === 0 && CostPerCapita !== 0) ||
      (RPCPD !== 0 && CostPerCapita === 0) ||
      (RPCPD !== 0 && Number.isNaN(CostPerCapita))
    ) {
      bn = 0;
    } else {
      // bn = (100 - ((RPCPD / CostPerCapita) * 100))
      bn = 100 - (CostPerCapita / RPCPD) * 100;
    }
    return bn;
  }

  async priorityProjects(projects, ghg) {
    // console.log(projects);
    projects.forEach((element) => {
      this.setDs(element);
      const myTasks = ghg.filter((tas) => tas.projectId === element.id);
      // console.log(myTasks);
      this.swavData(element, myTasks);
      // this.activePjs(element, myTasks);
    });
    this.activePrjs = projects.filter((pj) =>
      moment(pj.update).isBetween(moment().subtract(30, "days"), moment())
    );
  }

  // activePjs(proj, actions) {
  //   // console.log(proj.name, actions);
  //   const mm = {
  //     'name': proj.name,
  //     'id': proj.id,
  //     'value': this.calc(actions)
  //   };
  //   // this.pik = [];
  //   // console.log(this.pik.length);
  //   if (this.pik.length > 0) {
  //     // const i = this.pik.findIndex(a => a.name === proj.name);
  //     const i = this.pik.findIndex(a => a.id === proj.id);
  //     if (i > -1) {
  //       this.pik[i].value = mm.value;
  //       console.log(this.pik[i].value, i);
  //     } else {
  //       // console.log(mm.value);
  //       this.pik.push(mm);
  //     }
  //   } else {
  //     this.pik.push(mm);
  //     // console.log(mm.value);
  //   }
  // }

  swavData(proj, actions) {
    // console.log(proj.name, actions);
    const mm = {
      name: proj.name,
      id: proj.id,
      value: this.calc(actions),
    };
    // this.pik = [];
    // console.log(this.pik.length);
    if (this.pik.length > 0) {
      const i = this.pik.findIndex((a) => a.name === proj.name);
      if (i > -1) {
        this.pik[i].value = mm.value;
        // console.log(this.pik[i].value);
      } else {
        // console.log(mm.value);
        this.pik.push(mm);
      }
    } else {
      this.pik.push(mm);
      // console.log(mm.value);
    }
    Promise.all(this.pik).then((values) => {
      // console.log(values);
      return this.splitData(values);
    });
  }

  splitData(bigDats) {
    // this.prjData(bigDats);
    // console.log('splitData');
    this.activeProjects = [];
    let topData = [];
    // const bigDats = [];
    let bigDats2 = [];
    const allbigDats2 = bigDats.sort((a, b) => {
      return b.value - a.value;
    }).filter(x => x != undefined);
    bigDats2 = allbigDats2;
    const PP = { name: "ProjectA", value: 1 },
    PP1 = { name: "ProjectB", value: 1 },
    PP2 = { name: "ProjectC", value: 1 },
    PP3 = { name: "ProjectD", value: 1 },
    PP4 = { name: "ProjectE", value: 1 };
    if (bigDats2.length >= 5) {
      // console.log('Pjs =>', bigDats2.length);
      bigDats2 = bigDats2.slice(0, 6);
      this.allprojects = allbigDats2.filter((x) => x !== null || undefined).sort((a, b) => moment(b?.update).diff(moment(a?.update)));
    } else if (bigDats2.length < 5 && bigDats2.length > 0) {
      // console.log('Pjs <', bigDats2.length);
      bigDats2 = bigDats2.slice(0, bigDats2.length)
      this.allprojects = bigDats2.sort((a, b) => moment(b?.update).diff(moment(a?.update)));
    } else {
      // console.log('No Pjs <', bigDats2.length);
      const p0 = PP, p1 = PP1, p2 = PP2, p3 = PP3, p4 = PP4;
      bigDats2 = [p0, p1, p2, p3, p4];
    }
    this.maxProjProductivity = 0;
    topData = bigDats2.filter(xc => xc.value && xc.value > 0);
    topData.forEach(x => this.maxProjProductivity += x.value || 0);
    for (let i = 0; i < bigDats2.length; i++) {
      const element = bigDats2[i];
      this.activeProjects = bigDats2;
      this.calPer(element, this.maxProjProductivity);
    }
    return this.activeProjects;
  }

  prjData(bigDats) {
    this.allprojects = [];
    let bigDats2 = [];
    bigDats2 = bigDats.sort((a, b) => {
      return b.value - a.value;
    });
    bigDats2 = bigDats.filter((x) => x !== null || undefined);
    this.allprojects = bigDats2.sort((a, b) => moment(b?.update).diff(moment(a?.update)));;
  }

  calPer(n, tot) {
    const perc = Math.round((n.value / tot) * 100)
    // console.log(`${n.name}, val: ${n.value} total: ${tot} perc: ${perc}`);
    n.perc = perc;
  }

  priorityData(entries) {
    let bigSCS = [];
    // let fed5 = [];
    const weekTime = moment().subtract(14, "d").format("L");
    const tday = moment().format("L");
    entries.forEach((element) => {
      if (element.companyId !== "") {
        if (element.workHours !== undefined) {
          if (element.workHours !== null) {
            element.workHoursRpt = element.workHours.filter((task) => {
              // element.workHours.filter(task => {
              if (moment(task.time).isBetween(weekTime, tday)) {
                return task;
              }
            });
            if (element.workHoursRpt.length >= 0) {
              element.No = element.workHoursRpt.length || 0;
              bigSCS.push(element);
              bigSCS = bigSCS.sort((a, b) => {
                return b.No - a.No;
              });
              /* loop function */
              const top5dynamic = [];
              if (element?.No && element?.No !== undefined) {
                // console.log(element?.No);
                for (let index = 0; index < 6; index++) {
                  const element = bigSCS[index];
                  // console.log(index);
                  if (index === 0) {
                    this.totalPriority = 0;
                  }
                  if (element) {
                    top5dynamic.push(element);
                  }
                  this.totalPriority += element?.No || 0;
                  top5dynamic.filter(
                    (item, index) => top5dynamic.indexOf(item) !== index
                  );
                  this.topTasks = top5dynamic;
                  top5dynamic.forEach((em) => {
                    this.calComPer(em, this.totalPriority);
                  });
                }
              }
              /* end loop */
            }
          }
        }
      }
    });
    // })
    return this.topTasks;
  }

  priorityEntTasks(entries) {
    let bigSCStasks = [];
    const ime = moment().year();
    const weekTime = moment().subtract(30, "d").format("L");
    const tday = moment().format("L");
    entries.forEach((element) => {
      if (element.companyId !== "") {
        if (element.workHours !== undefined) {
          if (element.workHours !== null) {
            element.workHoursRpt = element.workHours.filter((task) => {
              if (moment(task.time).isBetween(weekTime, tday)) {
                return task;
              }
            });
            if (element.workHoursRpt.length >= 0) {
              element.No = element.workHoursRpt.length;
              bigSCStasks.push(element);
              // console.log(bigSCStasks);
              bigSCStasks = bigSCStasks.sort((a, b) => {
                return b.No - a.No;
              });
            }
            this.topEntTasks = bigSCStasks;
          }
        }
      }
    });
    return this.topEntTasks;
  }

  calComPer(n, tot) {
    n.perc = Math.round((n.No / tot) * 100);
  }

  doIt(vb) {
    const getDm = [];
    vb.forEach((act) => {
      const weekTime = moment().subtract(14, "d").format("L");
      const Atime = moment(act.time).format("L");
      if (moment(Atime).isBefore(weekTime)) {
        getDm.push(act);
      }
    });
    return getDm.length;
  }

  getCompAssets() {
    this.getCompanyAssets(this.task.companyId).subscribe((yu) => {
      if (yu.length > 0) {
        this.assets = yu;
      } else {
        this.assets = [];
      }
    });
    this.getCompanyHiredAssets(this.task.companyId).subscribe((yu) => {
      if (yu.length > 0) {
        this.hiredAssets = yu;
      } else {
        this.hiredAssets = [];
      }
    });
  }

  getCompanyAssets(companyId) {
    return this.afs
      .collection("Enterprises")
      .doc(companyId)
      .collection("assets")
      .snapshotChanges()
      .pipe(
        map((b) =>
          b.map((a) => {
            const data = a.payload.doc.data() as any;
            data.id = a.payload.doc.id;
            return data;
          })
        )
      );
  }

  getCompanyHiredAssets(companyId) {
    return this.afs
      .collection("Enterprises")
      .doc(companyId)
      .collection("hiredAssets")
      .snapshotChanges()
      .pipe(
        map((b) =>
          b.map((a) => {
            const data = a.payload.doc.data() as any;
            data.id = a.payload.doc.id;
            return data;
          })
        )
      );
  }

  getData() {
    if (this.editTask.projectId !== "" && this.editTask.companyId !== "") {
      this.afs
        .collection(
          `Projects/${this.editTask.projectId}/enterprises/${this.editTask.companyId}/Rates`,
          (ref) => ref.orderBy("name", "asc")
        )
        .valueChanges()
        .subscribe((rates) => {
          this.Rts = rates;
          // console.log(rates);
        });
      const prj = {
        id: this.editTask.projectId,
        name: this.editTask.projectName,
        companyId: this.editTask.companyId,
        companyName: this.editTask.companyName,
      };
      this.getPrjATaskSecs(prj);
    } else if (this.editTask.companyId) {
      this.afs
        .collection(`Enterprises/${this.editTask.companyId}/Rates`, (ref) =>
          ref.orderBy("name", "asc")
        )
        .valueChanges()
        .subscribe((rates) => {
          this.Rts = rates;
          // console.log(rates);
        });
    } else {
      this.Rts = [{ name: "No Type of Works", id: "No Type of Works" }];
    }
  }

  showSection(section) {
    // console.log(section);
  }

  getTimeSheetReport(task, cmp) {
    let workHours: any[];
    if (task.champion.id) {
      // console.log('getTimeSheetReport', cmp.id);
      this.afs
        .collection(`Users/${cmp.id}/tasks/${task.id}/workTime/`)
        .valueChanges()
        .subscribe((tskHours) => {
          if (tskHours.length > 0) {
            // console.log(task.description, tskHours);
            task.workHours = tskHours;
            workHours = tskHours;
            this.afs
              .doc(`Users/${cmp.id}/tasks/${task.id}`)
              .update({ workHours: tskHours })
              .then(() => {
                // console.log('Updated', task.description, task.id, tskHours);
              })
              .catch((er) => {
                // console.log(er);
                this.afs
                  .doc(`Users/${cmp.id}/tasks/${task.id}`)
                  .update({ workHours: tskHours });
              });
          }
        });
    }
    return workHours;
  }

  // tslint:disable-next-line: use-life-cycle-interface
  ngOnInit(): void {
    const nav = window.history;
    if (this.is.module === "transactions") {
      // this.getTransactions();
      console.log("Rfreshing");
    }
    // console.clear();
  }
  /* -------------------- Financial Statements ----------------------- */

  /* ---------------- Changes in Net Worth ------------------- */

  sortDate() {
    const years = [];
    const months = [];
    this.NetworthData = [];
    this.mNetworthData = [];
    for (let i = 0; i < 5; i++) {
      // console.log(i, 'Years', moment().add(i, 'years').format('YYYY'), 'Months', moment().add(i, 'months').format('MMMM'));
      years.push(moment().add(i, "years").format("YYYY"));
      this.getYearlytNetworth(moment().add(i, "years").year());
    }
    for (let i = 0; i < 5; i++) {
      // console.log(i, 'Years', moment().format('YYYY'), 'Months', moment().add(i, 'months').format('MMMM'));
      if (
        moment().add(i, "months").format("YYYY") === moment().format("YYYY")
      ) {
        // console.log(moment().add(i, 'months').format('YYYY') === moment().format('YYYY'));
        months.push(moment().add(i, "months").format("MMMM"));
        this.geMonthlytNetworth(
          moment().year(),
          moment().add(i, "months").format("MMMM")
        );
      }
    }
  }

  getYearlytNetworth(year) {
    this.afs
      .doc<Networth>(`Users/${this.acc?.id}/Networth/${year}`)
      .valueChanges()
      .subscribe((doc) => {
        if (doc) {
          // console.log(doc);
          const eq: any = doc;
          // this.Networth = eq;
          if (!this.NetworthData.find((i) => i.id === year)) {
            this.NetworthData.push(eq);
          }
        } else {
          const st = String(year) + "-01" + "-01";
          const end = String(year) + "-12" + "-31";
          this.addNetworth(year, st, end);
        }
        // console.log(this.NetworthData.length, this.NetworthData, this.NetworthData[0]);
        this.curNetworth = this.NetworthData[0];
      });
  }

  geMonthlytNetworth(year, month) {
    this.afs
      .doc<Networth>(`Users/${this.acc?.id}/Networth/${year}/Months/${month}`)
      .valueChanges()
      .subscribe((doc) => {
        if (doc) {
          // console.log(doc);
          const eq: any = doc;
          // this.mNetworth = eq;
          if (!this.mNetworthData.find((i) => i.id === month)) {
            this.mNetworthData.push(eq);
          }
        } else {
          const st = year + "-" + String(1 + moment(month).month()) + "-01";
          const end =
            year +
            "-" +
            String(1 + moment(month).month()) +
            "-" +
            String(moment().daysInMonth());
          this.addmNetworth(year, month, st, end);
        }
        // console.log(this.mNetworthData.length, this.mNetworthData);
      });
  }

  addNetworth(year, iii, jjj) {
    // console.log(year, iii, jjj);
    const Part: ParticipantData = {
      name: this._xdata.displayName,
      id: this._xdata.uid,
      email: "",
      bus_email: "",
      phoneNumber: "",
      photoURL: "",
      address: "",
      nationalId: "",
      nationality: "",
    };
    let Nwth: Networth;
    if (iii && jjj) {
      Nwth = {
        name: year,
        id: year,
        createdOn: new Date().toISOString(),
        updatedOn: new Date().toISOString(),
        createdBy: Part,
        startDate: iii,
        endDate: jjj,
        openBal: { name: "Openning NetWorth Balance", value: 0 },
        NetProfit: { name: "NetProfit for this Period", value: 0 },
        NRG: { name: "Networth Revaluation  Gain for This Period", value: 0 },
        CloseBal: { name: "Netwroth Closing Balance", value: 0 },
      };
    }
    const docName = year;
    this.afs
      .doc<Networth>(`Users/${this.acc?.id}/Networth/${docName}`)
      .ref.get()
      .then((doc) => {
        if (doc.exists) {
          //  console.log('Document for' + ' ' + docName + 'already exits, are trying to crete for a
          // different year? pick date for a different year');
        } else if (iii && jjj) {
          this.afs
            .doc<Networth>(`Users/${this.acc?.id}/Networth/${Nwth.id}`)
            .set(Nwth)
            .then(() => {
              //  console.log('new ChangesInEquity created');
            })
            .catch((err) => {
              //  console.log('No ChangesInEquity created', err);
            });
        } else {
          this.afs
            .doc<Networth>(`Users/${this.acc?.id}/Networth/${Nwth.id}`)
            .set(Nwth)
            .then(() => {
              //  console.log('new ChangesInEquity created');
            })
            .catch((err) => {
              //  console.log('No ChangesInEquity created', err);
            });
        }
      });
  }

  addmNetworth(year, month, iii, jjj) {
    //  console.log(iii, jjj);
    const Part: ParticipantData = {
      name: this._xdata.displayName,
      id: this._xdata.uid,
      email: "",
      bus_email: "",
      phoneNumber: "",
      photoURL: "",
      address: "",
      nationalId: "",
      nationality: "",
    };
    let Nwth;
    if (iii && jjj) {
      Nwth = {
        name: month,
        year: year,
        id: month,
        createdOn: new Date().toISOString(),
        updatedOn: new Date().toISOString(),
        createdBy: Part,
        startDate: iii,
        endDate: jjj,
        openBal: { name: "Openning NetWorth Balance", value: 0 },
        NetProfit: { name: "NetProfit for this Period", value: 0 },
        NRG: { name: "Networth Revaluation  Gain for This Period", value: 0 },
        CloseBal: { name: "Netwroth Closing Balance", value: 0 },
      };
    }
    const docName = month;
    this.afs
      .doc<Networth>(`Users/${this.acc?.id}/Networth/${year}/Months/${month}`)
      .ref.get()
      .then((doc) => {
        if (doc.exists) {
          //  console.log('Document for' + ' ' + docName + 'already exits,
          // are trying to crete for a different year? pick date for a different year');
        } else if (iii && jjj !== undefined) {
          this.afs
            .doc<Networth>(
              `Users/${this.acc?.id}/Networth/${Nwth.year}/Months/${Nwth.id}`
            )
            .set(Nwth)
            .then(() => {
              //  console.log('new Changes In Networth created');
            })
            .catch((err) => {
              //  console.log('No Changes In Networth created', err);
            });
        } else {
          this.afs
            .doc<Networth>(
              `Users/${this.acc?.id}/Networth/${Nwth.year}/Months/${Nwth.id}`
            )
            .set(Nwth)
            .then(() => {
              //  console.log('new Changes In Networth created');
            })
            .catch((err) => {
              //  console.log('No Changes In Networth created', err);
            });
        }
      });
  }
  /* ------------- End of Changes In Net Worth --------------- */


  /* ------------------ Data reading Algos ------------------- */

  async moduleTest(x: any, info: string, queryType: string) {
    // let data: any;
    switch (this.is.module) {
      case 'transactions': {
        return this.queryLive(x, info, queryType);
        // break;
      }
      case 'tasking': {
        return this.queryLive(x, info, queryType);
        // break;
      }
      default: {
        // const sfs = await this.queryOnce(x, info, queryType);
        return await this.queryOnce(x, info, queryType);;
      }
    }
    // return data;
  }

  queryLive(x: any, info: string, queryType: string,) {
    if (queryType === 'Arr') {
      return this.arrliveRead(x, info);
    } else {
      return this.docliveRead(x, info);
    }
  }

  async queryOnce(x: any, info: string, queryType: string) {
    if (queryType === 'Arr') {
      return await this.arrreadOncex(x, info);
    } else {
      return await this.docreadOnce(x, info);
    }
  }

  arrliveRead(x: AngularFirestoreCollection<any>, info: string) {
    if (info === 'valueChanges') {
      let arrSet = {
        type: 'Observable',
        data: x.valueChanges()
      };
      return arrSet;
    }
    if (info === 'snapshotChanges') {
        let arrSet = {
          type: 'Observable',
          data: x.snapshotChanges().pipe(
            map(b => b.map(a => {
              const data = a.payload.doc.data() as any;
              data.id = a.payload.doc.id;
              return data;
            })))
        };
        return arrSet;
    }
  }

  arrreadOncex(x: AngularFirestoreCollection<any>, info: string) {
    let arrSet: {
      type: string,
      data: QueryDocumentSnapshot<firebase.firestore.DocumentData>[],
    };
    
    x.ref.get().then(data => {
      data.docs.length;
      arrSet = {
        type: 'QuerySnapshot',
        data: data.docs,
      };
      // arrSet.data = data.docs;
    })
    return arrSet;
  }

  docliveRead(x: AngularFirestoreDocument<any>, info: string) {
    if (info === 'docChanges') {
      let arrSet = {
        type: 'Observable',
        data: x.valueChanges()
      };
      return arrSet;
    }
    if (info === 'docChanges') {
      let arrSet = {
        type: 'docObservable',
        data: x.snapshotChanges().pipe(
          map(a => {
            const data = a.payload.data() as any;
            data.id = a.payload.id;
            return data;
          }))
      };
      return arrSet;
    }
  }

  docreadOnce(x: AngularFirestoreDocument<any>, info: string) {
    let arrSet = {
      type: 'DocumentData',
      data: undefined,
    };
    x.ref.get().then(element => {
      arrSet.data = element.data();
    })
    return arrSet;
  }

  /* -----------------------Transactions------------------------- */

  getTransactions() {
    this.is.module = "transactions";
    this.afs.collection<any>(`Users/${this.userId}/transactions`, 
       ref => ref.orderBy('createdOn', "asc").limitToLast(30)
      )
      .valueChanges().subscribe((dsData) => {
        this.trans = dsData;
        const lessNumb = dsData.slice(0, (1 / 2) * dsData.length);
        lessNumb
          .filter(
            (x) =>
              x?.name?.includes("Phase 2") ||
              x?.product?.name?.includes("Phase 2") ||
              x?.product?.product?.name?.includes("Phase 2")
          )
          .forEach((x) => {
            if (x?.product?.parent?.includes("Phase 2")) {
              x.product.parent = this.renamePhases(x.product.parent);
              x.product.name = x?.product?.type?.value +  " " + String(x?.product?.productNo?.name) + " of " + x.product?.parent;
              x.product.description = x.product.name;
              x.name = x.product.name;
              x.description = x.product.name;
              if(x.id) {
                this.afs.doc<any>(`Users/${this.userId}/transactions/${x.id}`).update(x);
              }
            }
          });
        this.trans = lessNumb.slice(0, (1 / 2) * lessNumb.length);
        setTimeout(() => {
          dsData.filter((x) => x?.name?.includes("Phase 2") || x?.product?.name?.includes("Phase 2") || x?.product?.product?.name?.includes("Phase 2"))
            .forEach((x) => {
              if (x?.product?.parent?.includes("Phase 2")) {
                x.product.parent = this.renamePhases(x.product.parent);
                x.product.name = x?.product?.type?.value + " " + String(x?.product?.productNo?.name) + " of " + x.product?.parent;
                x.product.description = x.product.name;
                x.name = x.product.name;
                x.description = x.product.name;
                if(x.id) {
                  this.afs.doc<any>(`Users/${this.userId}/transactions/${x.id}`).update(x);
                }
              }
            });
          this.trans = dsData;
        }, 15000);
        dsData.forEach((x) => {
          if (x.component === "Sale" && (!x.product.rate || x.product.rate === "")) {
            x.product.rate = x.rate.name;
            if (x.Initiated && x.Initiated.by.name !== "" && !x.Initiated.by.photoURL) {
              x.Initiated.by.photoURL = this.getImg(x.Initiated?.by.id);
            }
            if (x.Checked && x.Checked.by.name !== "" && !x.Checked.by.photoURL) {
              x.Checked.by.photoURL = this.getImg(x.Checked?.by.id);
            }
            if (x.Approved && x.Approved.bame !== "" && !x.Approved.by.photoURL) {
              x.Approved.by.photoURL = this.getImg(x.Approved?.by.id);
            }
          } else if (!x?.component && (x?.duct?.transAction === "newInventory" || x?.product?.transAction === "checkInventory" || x?.product?.transAction === "apprvInventory")
          ) {
            if (x.initiated && x.initiated.name !== "" && !x.initiated.by.photoURL) {
              x.initiated.by.photoURL = this.getImg(x.initiated?.by.id);
            }
            if (x.checked && x.checked.by.e !== "" && !x.checked.by.photoURL) {
              x.checked.by.photoURL = this.getImg(x.checked?.by.id);
            }
            if (x.approved && x.approved.bame !== "" && !x.approved.by.photoURL) {
              x.approved.by.photoURL = this.getImg(x.approved?.by.id);
            }
          }
        });
      });
  }

  getImg(uid) {
    return this.myContacts?.find((u) => u.id === uid)?.photoURL || "";
  }

  renamePhases(descr: string) {
    let legalDescription = "";
    if (descr.includes("Phase 2 of Stand 655 Pomona Township")) {
      legalDescription = descr.split("Phase 2 of Stand 655 Pomona Township").join("Stand 655 of Pomona Township");
    } else if (descr.includes("Phase 2 of Stand 662 Pomona Township")) {
      legalDescription = descr.split("Phase 2 of Stand 662 Pomona Township").join("Stand 662 of Pomona Township");
    } else if (descr.includes("Phase 2 of Stand 655 of Pomona Township")) {
      legalDescription = descr.split("Phase 2 of").join("");
    } else if (descr.includes("Phase 2 of Stand 662 of Pomona Township")) {
      legalDescription = descr.split("Phase 2 of").join("");
    } else {
      legalDescription = descr;
    }
    return legalDescription;
  }

  async getDataRpts(rpt) {
    const collRef = this.afs.collection(`Users/${this.setContact?.id}/reports`);
    collRef.ref.get().then(data => {
      data.docs.length;
        const reports = [];
        for (let i = 0; i < data.docs?.length; i++) {
          const item = data.docs[0].data();
          reports.push(item);
          if (i === data.docs?.length - 1) {
            this.otherReports = of(reports);
            this.routeRpt(rpt);
          }
        }
    })
  }

  routeRpt(rpt) {
    console.log(rpt);
    this.router.navigate(["./task24-7/report/" + rpt]);
  }

  onlyPj() {
    return "itnHEizBd3Yf7IAnRdPm"; // Nyeredzi Project
    // return '6CvJkB7ylK67BNZgxMbt'; // CAD project
  }

  findRemUndefined(){
    const removeUndefinedValuesFromObject = <T>(obj: T): T => {
      Object.keys(obj).forEach((key) => obj[key] === undefined && delete obj[key]);
      return obj;
    };
    const data = {
      name: "John",
      age: undefined,
      address: {
        city: "New York",
        state: undefined,
      },
      hobbies: ["reading", undefined, "traveling"],
    };
    
    const cleanData = removeUndefinedValuesFromObject(data);
    console.log(cleanData);
  }

  identifyUndefinedArr(data){
    return data.filter(elem => {
      const identifyUndefinedValuesFromObject = <T>(obj: T): T => {
        Object.keys(obj).forEach((key) => {
          // if(obj[key] === undefined) {
          //   console.log(obj[key], key);
          // }
          obj[key] === undefined && delete obj[key]});
        return obj;
      };
      const undfneData = identifyUndefinedValuesFromObject(elem);
      // console.log(undfneData);
      return undfneData
    })
  }

  checkPart(task) {
    const fnd = task?.Participants?.find((x) => x.id === this.userId);
    if (fnd) {
      console.log(fnd);
      return true;
    } else if (task.champion.id === this.userId) {
      // console.log(task.champion);
      return true;
    } else {
      return false;
    }
  }
}
