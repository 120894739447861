import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { CurrencyPipe } from '@angular/common';
import { InitialiseService } from './initialise.service';
import { UserDataService } from './user-data.service';
import { ProjServiceService } from './proj-service.service';
import { agentProduct, compTans, doc, transaction, transCancel } from 'app/models/user';
import * as moment from 'moment';
// import * as firebase from 'firebase/app';
import { map, find, finalize, concatMap } from 'rxjs/operators';
import { MongodbService } from './mongodb.service';
import { WriteService } from './writes.service';
import { RouteService } from './route.service';
import { Invoice } from 'app/models/finances-model';
import { MySqlService } from './mysql.service';
import { Labour } from 'app/models/enterprise-model';
import { PublicService } from './public.service';
import { rate } from 'app/models/task-model';
import { NotificationService } from './notification.service';

@Injectable({
  providedIn: 'root'
})
export class OpenBooksService {
  transArr:any[];
  tranInfo: any;
  inventory: any;
  transaction: transaction;
  transCancel: transCancel;
  saletrans: compTans;
  trans: transaction;
  setInventory: any;
  setParty: any;
  party: any;
  parties = ['Party1', 'Party2', 'Party3', 'Party4'];
  prjParties: any;
  prjCompany: any;
  inventoryList: any[] = [];
  data: any[];
  transType: any;
  setProject: any;
  projectData: { id: string; name: string; rates: any[], getInventory: any[]; getTeams: any[]; getLabour: Labour[]; getClients: any[]; salesTrans: any[]; prjTrans: any[]; companyId: any; companyName: any; getProductTypes: any[]; getPhases: any[]; };
  searchForm = false;
  txtValue = '';
  error: { er: boolean; msg: string; };
  entInvent: any;
  suppliers: any[];
  partyInv: any[];
  availableInv: any[];
  setProduct;
  myAcc: any;
  wInventory: Observable<any[]>;
  obsTrans: any[];
  ProductQty: any = { name: '0', value: 0, unit: '' };
  type = { name: '', id: '', unit: '', value: '' };
  pdType: any = { name: '', id: '', unit: '', value: '', createdOn: '', createdBy: {} };
  selectedTrans: any = '';
  newInventory: any[];
  selectedCustomer;
  payement: any;
  transParties: any[] = [];
  uni: any = { name: '', id: '', unicode: '' };
  payTransaction: compTans;
  idError: { alreadyExists: boolean; };
  obsTransArr: any[];
  results: any[];
  lisaniHack: string;
  realArr: any[];
  prodsAvail: any;
  cusCart: any[];
  public newComment = false;
  // public editComment = false
  Comment = { name: '', id: '', by: { id: '', name: '' }, createdOn: '', photoUrl: '' };
  value = '';
  mdbCount;
  searchValue;
  saleResults: any[];
  url: any;
  flag: string = '';
  uploadPercent: number;
  downloadURL: any;
  saletransArr: any[];
  viewDoc;
  loading = false;
  Investors: any[];
  Contractors: any[];
  ent: any;
  projectParty: any;
  projectInv: Observable<any[]>;
  rootProject: any;
  rootManagers: any;
  custProducts: any[];
  invoice: Invoice;
  customerInv: any[];
  agentInvent: agentProduct;
  list: any = {};
  agents: any[];
  salesTrans: any[];
  dublicatesEntry: any[];
  sec = '';
  mainParties: any[];
  labour: Labour[];
  dublicateSalesTrns: any[] = [];
  salesData: Observable<any[]>;
  allPrjTrans: Observable<any[]>;
  contractorsOb: Observable<any[]>;
  investorsOb: Observable<any[]>;
  customerList: any;
  transactions: any[];
  cusProducts: any;
  setCus: any;
  currentIndex: number;
  customerData: {};
  cusList: any[];
  sqlData: any[];
  doc: any;
  team: any[];
  clients: any[];
  prjInventory: any;
  oldParty: any;
  patiesInv: any[];
  oldCustomers: any;
  allSqlData: any;
  sql: Observable<any>;
  labourBool = false;
  supplierBool = false;
  inventoryBool = false;
  cusdoc: any;
  myTrans: any;
  setSale: any;
  alltransdata: any;
  partTrans: any[];
  approved: { productNo: boolean, parent: boolean, unit: boolean, size: boolean } 
  verify: { productNo: boolean, parent: boolean, unit: boolean, size: boolean, closed: boolean } 
  paymentDue = { amount: 0, sign: 1 };
  totaBalance = { amount: 0, sign: 1 };
  sign: number = 1;
  totaBsign: number = 1;
  totalssign: number = 1;
  stdoc: any;
  rawData: any[];
  setCustomer: any;

  // doc: any;
  constructor(public is: InitialiseService, public mysqlService: MySqlService, private usd: UserDataService, public rs: RouteService, public ps: ProjServiceService, private mdb: MongodbService, private ns: NotificationService, private currencyPipe: CurrencyPipe, public pub: PublicService) {
    // this.inventory
    this.approved = { productNo: false, parent: false, unit: false, size: false };
    this.verify = { productNo: false, parent: false, unit: false, size: false, closed: false };
    this.setInventory = is.initInventory();
    this.setProject = is.initOpenBksPrj();
    this.entInvent = is.initInventory();
    this.agentInvent = is.initAgentInventory();
    this.transaction = is.initTrans();
    this.payTransaction = is.initTransPay();
    this.payement = is.initPayment();
    this.saletrans = is.initSale();
    this.transCancel = is.initCancelSale();
    this.invoice = is.initInvoice();
    // this.entInvent.contractSum = { name: '', value: 0 }
    this.party = { name: '', id: '', control: '', initValue: 0, exitValue: 0, exitInventory: 0, exitQuantity: 0 };
    this.setParty = { name: '', id: '', control: '', initValue: 0, exitValue: 0, exitInventory: 0, exitQuantity: 0 };
    this.lisaniHack = '';
    this.txtValue = '';
  }

  
  initTeam() {
    if (this.is.SIunits) {
      
    
    // Promise.all(this.is.SIunits).then(allUnits => {
      // if(allUnits === undefined) {
      //   this.usd.getUnits();
      //   setTimeout(() => {
      //     this.initTeam()
      //   },3)
      // } else {
        this.party = this.is.getClient();
        this.party.entryValue = 0;
        this.party.exitValue = 0;
        this.party.structuralRole = {};
        this.party.serviceRole = {};
        this.party.invQuantity = { value: 0, unit: this.is.SIunits?.length > 0 ? this.is.SIunits[0].id : this.is.allunits?.length > 0 ? this.is.allunits[0].id : ''};
      }
    // })
  }

  approvePartyInv(item) {
    this.setInventory = item;
    this.approved = { productNo: false, parent: false, unit: false, size: false };
  }

  initapproveInv() {
    this.approved = { productNo: false, parent: false, unit: false, size: false };
  }

  
  initVerifyInv(item) {
    this.setInventory = item;
    this.verify = { productNo: false, parent: false, unit: false, size: false, closed: false };
  }

  selectTrans(x) {
    console.log(typeof(x.product.size));
    if (typeof(x.product.size) === 'number') {
      x.product.size = { verified: true, approved: true, name: x.product.size }
    }
    this.trans = x;
    this.saletrans = x;
    this.transaction = x
    console.log(x);
  }

  invoiceInit() {
    this.invoice = this.is.initInvoice();
    const initBank = {
      name: 'Bank Name', accountNo: 'Acc No',
      branch: { name: 'Bank Branch if any', code: 'Branch Code if any' },
      address: 'Bank physical address', swiftCode: 'Swift Code', currency: 'Used Currency'
    };
    this.invoice.by = { name: this.usd?.acc?.name, id: this.usd?.acc?.id }
    this.invoice.company = {
      name: this.usd?.setProject?.companyName, id: this.usd?.setProject?.companyId, bank: this.is.initInvoice()?.company?.bank || initBank, telephone: this.usd?.setCompany?.telephone || '',
      logoURL: this.usd?.setCompany?.logoURL, contact: this.usd?.setCompany?.contactPerson, address: this.usd?.setCompany?.address || ''
    }
    this.invoice.project = { name: this.usd.setProject.name || '', id: this.usd.setProject.id || '' }
    this.invoice.date = new Date().toISOString();
    // this.invoice.transType = 'Invoice';
    // this.invoice.No = 'Invoice';
    if(this.usd?.projectTeam && this.usd?.projectTeam?.name !== '') {
      this.invoice.customer = [this.usd?.projectTeam];
    }
    for (let i = 0; i < this.invoice.customer.length; i++) {
      const element = this.invoice.customer[i];
      if (i === 0) {
        this.invoice.name = `Invoice to ${element?.name}`;
      } else if ((this.invoice.customer.length) > 1 && (i + 1 !== this.invoice.customer.length)) {
        this.invoice.name = this.invoice.name + `, ${element?.name}`;
      } else {
        this.invoice.name = this.invoice.name + `& ${element?.name}`;
      }
    }
  }

  imgProcess(trans) {
    if ((trans?.component === 'Sale') && (trans?.approved?.by?.photoURL || trans?.checked?.by?.photoURL || trans?.initiated?.by?.photoURL)) {
      console.log('Sale', trans?.approved?.by?.photoURL ? 'Approver' + ' ' + trans?.approved?.by?.photoURL || trans?.checked?.by?.photoURL ? 'Checker' + ' ' + trans?.checked?.by?.photoURL : 'Initiator' + ' ' + trans?.initiated?.by?.photoURL: 'none' );
      // return trans?.approved?.by?.photoURL ?? trans?.checked?.by?.photoURL ?? trans?.initiated?.by?.photoURL
      return trans?.Approved?.by?.photoURL || trans?.Checked.by?.photoURL || trans?.Initiated?.by?.photoURL
      // } else if (condition) {
      // } else if (condition) {
      // } else if (condition) {
      // } else if (condition) {
      // } else if (condition) {
    } else {
      // console.log('component', trans?.component, trans);
      return './assets/img/faces/avatar.png';
    }
  }

  getClients() {
    console.log('getting Clients');
    // this.usd.afs.collection<any>(`Projects/${this.setProject.id}/clients`, ref => ref.where('party.id', '==', this.setProject.companyId)).valueChanges().subscribe(pteam => {
    this.usd.afs.collection<any>(`Projects/${this.setProject.id}/clients`).valueChanges().subscribe(pteam => {
      // if (!pteam || pteam.length === 0) {
      //   this.usd.afs.collection<any>(`Projects/${this.setProject.id}/clients`).valueChanges()
      //   // this.usd.afs.collection<any>(`Projects/${this.setProject.id}/enterprises/${this.setProject.companyId}/clients`).valueChanges()
      //   .subscribe(team => {
      //     this.clientInfoProcessing(team);
      //   });
      // } else {
      // const fg = pteam.filter(v => v.name === 'Titus Matiza');
      // console.log(fg);
      this.clientInfoProcessing(pteam);
      // }
    });
  }

  clientInfoProcessing(pteam) {
    console.log(pteam.length);
    for (let i = 0; i < pteam.length; i++) {
      const element = pteam[i];
      if (!element?.party || element.party === undefined) {
        const party = {
          name: this.setProject.companyName,
          id: this.setProject.companyId
        }
        this.usd.afs.doc<any>(`Projects/${this.setProject.id}/clients/${element.id}`).update({'party': party}).then(() => console.log('party updated', party.name)).catch(er => console.log('Failed to update Error', er))
      }
      if (!element?.public || element?.public === false || element.public === undefined) {
        this.usd.afs.doc<any>(`Projects/${this.setProject.id}/clients/${element.id}`).update({'public': true}).then(() => console.log('Projects clients updated')).catch(er => console.log('Projects clients Failed to update Error', er))
        this.usd.afs.doc<any>(`Users/${element.id}`).update({'public': true}).then(() => console.log('Users public updated')).catch(er => console.log('Users Failed to update Error', er))
      }
    }
    console.log('getting Clients', pteam?.length);
    this.ps.clients = pteam;
    this.clients = pteam;
    this.projectData.getClients = pteam;
    const prIndex = this.usd?.myprojects?.findIndex(x => x.id === this.projectData.id);
    if(prIndex > -1) {
      // this.usd.allprojects[prIndex].getClients = pteam;
      this.usd.myprojects[prIndex].getClients = pteam;
    }
    pteam.forEach(stf => {
      stf.cmpType = 'clients';
      if(stf.srTag === 'ind' && (!stf.bus_email || stf.bus_email === '')){
        stf.bus_email = stf.email;
      }
      this.usd.afs.doc<any>(`Enterprises/${stf.id}`).ref.get().then(sRef => {
        if (sRef.exists) {
          if (sRef.data().logoURL) {
            stf.logoURL = sRef.data().logoURL;
          } else {
            stf.hierarchy = ''
          }
        }
      })
    });
  }
  
  checkLabour(man: Labour) {
    // console.log(man);
    if (!man.bus_email) {
      // console.log('checking missing bus_email');
      this.usd.afs.doc(`Enterprises/${this.setProject.companyId}/Participants/${man.id}`).ref.get().then(entUser => {
        if (entUser.exists) {
          if (!man.bus_email) {
            if (entUser.data().bus_email) {
              // console.log('Adding bus_email', entUser.data().bus_email);
              man.bus_email = entUser.data().bus_email;
              this.usd.afs.doc(`Projects/${this.setProject.id}/enterprises/${this.setProject.companyId}/labour/${man.id}`).update({'bus_email': man.bus_email});
            } else {
              man.bus_email = ''
            }
          }  else {
            man.bus_email = ''
          }
          if (!man.phoneNumber) {
            if (entUser.data().phoneNumber) {
              // console.log('Adding phoneNumber', entUser.data().phoneNumber);
              man.phoneNumber = entUser.data().phoneNumber;
              this.usd.afs.doc(`Projects/${this.setProject.id}/enterprises/${this.setProject.companyId}/labour/${man.id}`).update({'phoneNumber': man.phoneNumber});
            } else {
              man.phoneNumber = ''
            }
          }
          if (!man.photoURL) {
            if (entUser.data().photoURL) {
              // console.log('Adding photoURL', entUser.data().photoURL);
              man.photoURL = entUser.data().photoURL;
              this.usd.afs.doc(`Projects/${this.setProject.id}/enterprises/${this.setProject.companyId}/labour/${man.id}`).update({'photoURL': man.photoURL});
            } else {
              man.photoURL = ''
            }
          }
          if (!man.address) {
            if (entUser.data().address) {
              // console.log('Adding address', entUser.data().address);
              man.address = entUser.data().address;
              this.usd.afs.doc(`Projects/${this.setProject.id}/enterprises/${this.setProject.companyId}/labour/${man.id}`).update({'address': man.address});
            } else {
              man.address = ''
            }
          }
          if (!man.nationality) {
            if (entUser.data().nationality) {
              // console.log('Adding nationality', entUser.data().nationality);
              man.nationality = entUser.data().nationality;
              this.usd.afs.doc(`Projects/${this.setProject.id}/enterprises/${this.setProject.companyId}/labour/${man.id}`).update({'nationality': man.nationality});
            } else {
              man.nationality = ''
            }
          }
          if (!man.nationalId) {
            if (entUser.data().nationalId) {
              // console.log('adding nationalId', entUser.data().nationalId);
              man.nationalId = entUser.data().nationalId;
              this.usd.afs.doc(`Projects/${this.setProject.id}/enterprises/${this.setProject.companyId}/labour/${man.id}`).update(man);
            } else {
              man.nationalId = ''
            }
          }
        }
      })
    }
    return man;
  }

  getLabour() {
    console.log('getting labour', this.setProject.companyId);
    this.usd.afs.collection<any>(`Projects/${this.setProject.id}/enterprises/${this.setProject.companyId}/labour`).valueChanges().subscribe(labour => {
        labour = labour.filter(man => ((man?.left && man?.left === false) || (!man?.left)));
        // console.log(labour);
        this.labour = labour;
        this.ps.labour = this.labour;
        this.projectData.getLabour = labour;
        this.labourBool = true; console.log('labour set')
        labour.forEach(element => {
            // console.log(element.name);
          element.companyId = this.setProject.companyId;
          if (!element.bus_email || !element.phoneNumber || !element.photoURL || !element.address || !element.nationality || !element.nationalId) {
            // console.log('checking missing fields')
            element = this.checkLabour(element);
          }
          if (!element.photoURL) { element.photoURL = '' }
          this.usd.afs.doc<any>(`Enterprises/${this.setProject.companyId}/Participants/${element.id}`).ref.get().then(sRef => {
            if (sRef.exists) {
              if (sRef.data().hierarchy) {
                element.hierarchy = sRef.data().hierarchy;
              } else {
                element.hierarchy = ''
              }
            }
          })
          element.viewable = '';
          if (!element?.paymentRate) {
            element.paymentRate = { rate: '', amount: 0 };
            // console.log('Payment rates', stf?.paymentRate);
          }
          element.photoURL = this.usd.myContacts.find(xuf => xuf.id === element.id)?.photoURL;
        })
        const prIndex = this.usd?.myprojects?.findIndex(x => x.id === this.projectData.id);
        if(prIndex > -1) {
          // this.usd.allprojects[prIndex].getLabour = labour;
          this.usd.myprojects[prIndex].getLabour = labour;
        }
      });
  }
  
  testStructuralRole(){
    const teamFnd = this.ps.team.find(xuf => xuf.id === this.setProject.companyId);
    if (!teamFnd || !teamFnd?.structuralRole?.name || teamFnd?.structuralRole?.name === 'Public') {
      if (this.setProject.id === this.usd.onlyPj()) {
        // console.log(this.setProject.id, this.usd.onlyPj());
        this.rs.gotoClientDash();
      }
    } else {
      if (this.setProject.byId === teamFnd?.champion?.id) {
        // console.log(this.setProject.id, this.usd.onlyPj());
      } 
    }
  }
  
  groupParties(team:any[]){
    const ent = team.find(tm => tm.id === this.usd.setProject.companyId);
    this.pub.ent = ent;
    this.ent = ent;
    this.patiesInv = [];
    const Investors = team.filter(tm => tm?.structuralRole?.id === 'maincontructsilent') || [];
    const contractors = team.filter(tm => tm?.structuralRole?.id === 'maincontructactive') || [];
    // console.log('Investors', Investors, 'contractors', contractors);
    this.Investors = Investors;
    this.Contractors = contractors;
    this.Investors = Investors;
    this.Contractors = contractors;
    
    this.investorsOb = of(Investors);
    this.contractorsOb = of(contractors);
    this.mainParties = Investors.concat(contractors);
    for (let i = 0; i < team.length; i++) {
      const element = team[i];
      const entFnd = this.data.find(inv => inv.party.id === element.id);
      if (entFnd) {
        this.patiesInv.push(element);
      }
    }
  }

  getRates(company) {
    if(this.usd.setProject.id && this.usd.setProject.id !== '') {
      this.usd.afs.collection(`Projects/${this.usd.setProject.id}/enterprises/${company.id}/Rates`, ref => ref.orderBy('name', 'asc'))
        .valueChanges().subscribe((rates: rate[]) => {
          const cmpIndex = this.team.findIndex(cmp => cmp.id === company.id);
          if (cmpIndex > -1) {
            this.team[cmpIndex].pRates = rates;
            if (this.team[cmpIndex].id === this.setProject.companyId) {
              this.ps.rates = rates;
              this.ps.Rts = Observable.of(rates);
              this.projectData.rates = rates;
              const prIndex = this.usd?.myprojects?.findIndex(x => x.id === this.projectData.id);
              if(prIndex > -1) {
                // this.usd.allprojects[prIndex].rates = rates;
                this.usd.myprojects[prIndex].rates = rates;
              }
            }
          }
        });
    }
  }
  
  getTeams() {
    this.usd.afs.collection<any>(`Projects/${this.setProject.id}/enterprises`, ref => ref.orderBy('name', 'asc')).valueChanges()
      .subscribe(team => {
        this.team = team;
        const dsEnt = team.find(x => x.id === this.setProject.companyId); 
        this.projectData.getTeams = team;
        const prIndex = this.usd?.myprojects?.findIndex(x => x.id === this.projectData.id);
        if(prIndex > -1) {
          this.usd.myprojects[prIndex].getTeams = team;
        }
        this.ent = dsEnt;
        this.setProject.company = dsEnt;
        this.pub.ent = dsEnt;
        this.is.ent = dsEnt;
        // console.log(this.pub.ent);
        this.ps.team = team;
        team.forEach(element => {
          this.usd.afs.doc<any>(`Enterprises/${element.id}`).ref.get().then(docRef => {
            if (docRef.exists) {
              const entData = docRef.data() as any;
              // console.log(entData.name, 'out');
              if(element.name !== entData.name) {
                // console.log(entData.name, 'in');
                element.name = entData.name;
                this.usd.afs.doc<any>(`Projects/${this.setProject.id}/enterprises/${entData.id}`).update({'name' : entData.name}).then(() => console.log('Team updated'));
              }
              if((element?.logoURL !== '') && (element?.logoURL !== entData?.logoURL)) {
                element.logoURL = entData.logoURL;
                this.usd.afs.doc<any>(`Projects/${this.setProject.id}/enterprises/${entData.id}`).update({'logoURL' : entData.logoURL}).then(() => console.log('Team updated'));
              }
            }
          })
          element.cmpType = 'Party';
          this.groupParties(team);
          this.getRates(element);
          // if (element.managers) {
          //   element.managers.forEach(xuf => {
          //     if (xuf && xuf?.booksRole) {
          //       const pathRef = this.usd.afs.doc(`Projects/${this.setProject.id}/enterprises/${this.setProject.companyId}/labour/${xuf.id}`);
          //       pathRef.ref.get().then(dataB => {
          //         if (dataB.exists && dataB.data()?.booksRole) {
          //           console.log(xuf?.booksRole);
                    
          //         } else if (dataB.exists && (!dataB.data()?.booksRole || dataB.data()?.booksRole === '')) {
          //           pathRef.update({ 'booksRole': xuf?.booksRole }).then(() => console.log(`${xuf.name}'s booksRole updated`)).catch( er => console.log(`${xuf.name} to join project first from ${this.setProject.companyName}'s projects register`, er));
          //         } else { 
          //           // pathRef.set(xuf).then(() => console.log(`${xuf.name}'s booksRole updated`)).catch( er => console.log('failed', er));
          //         }
          //       })
          //     } else {
          //       this.usd.afs.doc(`Enterprises/${this.setProject.companyId}/Participants/${xuf.id}`).ref.get().then(dataB =>{
          //         if (dataB.exists && dataB.data()?.booksRole) {
          //           xuf.booksRole = dataB.data()?.booksRole;
          //         } else { xuf.booksRole = 'None' }
          //       })
          //     }
          //   });
          // }
        });
        this.ps.getProjectTeam();
        this.testStructuralRole();
        this.rootManagers = team?.find(ent => ent.structuralRole?.id === 'maincontructactive')?.managers;
        const foundCmp = this.ps?.team.find(xuf => xuf.id === this.setProject.companyId);
        const foundMe: any = foundCmp?.managers?.find(xuf => xuf.id === this.usd.acc.id);
        this.ps.managers = foundCmp?.managers || [];
        // console.log(this.ps.managers);
        if (foundCmp){
          this.usd.setCompany.structuralRole = foundCmp?.structuralRole || '';
          // console.log(this.usd.setCompany.structuralRole, foundCmp?.structuralRole);
        }
        if (foundMe && foundMe?.booksRole) {
          console.log('Role', foundMe?.booksRole);
          this.usd.acc.booksRole = foundMe?.booksRole;
          this.usd?.acc?.booksRole
          this.myAcc = foundMe;
          this.pub.acc = this.myAcc;
          this.ps.myAcc = this.myAcc;
          this.myAcc = this.myAcc;
        } else {
          this.usd.acc.booksRole = 'None';
          this.myAcc = foundMe;
          this.myAcc = {
            booksRole: 'None',
            email: foundMe?.email || '',
            bus_email: foundMe?.bus_email || '',
            name: foundMe?.name || '',
            id: foundMe?.id || ''
          }
          this.ps.myAcc = this.myAcc;
          this.myAcc = this.myAcc;
        }
        // console.log(this.ps.team);
      });
  }

  checkerTest(x) {
    if (this.myAcc?.booksRole === 'Checker') {
      if (!x?.product?.checked?.status) {
        // console.log(x?.product?.checked?.status);
        if (x?.product?.initiated?.by.id !== this.usd.userId) {
          // console.log(x?.product?.initiated?.by.id, this.usd.userId);
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  approverTest(x) {
    if (this.myAcc?.booksRole === 'Approver') {
      if (!x?.product?.approved?.status && x?.product?.checked?.status) {
        // console.log('Approved',x?.product?.approved?.status, 'Checked', x?.product?.checked?.status);
        if ((x?.product?.initiated?.by.id !== this.usd.userId) && (x?.product?.checked?.by.id !== this.usd.userId)) {
          // console.log(x?.product?.approved?.status);
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
  
  prjTrans() {
      this.allPrjTrans = this.usd.afs.collection<any>(`Projects/${this.setProject?.id}/transactions`, ref => ref?.where('company.id', '==', this.setProject.companyId)).valueChanges();
      return this.allPrjTrans;
    }

  setTrans(sale) {
    // console.log('setTrans');
    this.transCancel = sale;
    this.oldCustomers = sale.SellStatus || sale.parties;
    const cod = sale?.docs ? sale?.docs[0] : this.is.initDoc();
    console.log(cod);
    this.openDoc(cod, 0);
    this.searchValue = '';
    this.nextDoc('fnc int');
  }

  nextDoc(par) {
    // console.log(par);
    if (this.viewDoc?.no === 1 && this.transCancel?.docs?.length === this.viewDoc?.no) {
      this.viewDoc.pigBack = false;
      this.viewDoc.pigFwd = false;
    } else if (this.viewDoc?.no === 1 && this.transCancel?.docs?.length > this.viewDoc?.no) {
      this.viewDoc.pigBack = false;
      this.viewDoc.pigFwd = true;
      this.docNav(par);
    } else if (this.viewDoc?.no > 1 && this.viewDoc?.no < this.transCancel?.docs?.length) { /* this.viewDoc?.no > 1 &&  */
      this.viewDoc.pigBack = true;
      this.viewDoc.pigFwd = true;
      this.docNav(par);
    } else if (this.transCancel?.docs?.length > 1 && this.viewDoc?.no === this.transCancel?.docs?.length) {
      this.viewDoc.pigBack = true;
      this.viewDoc.pigFwd = false;
      this.docNav(par);
    } else { }
  }

  docNav(par) {
    const i = this.viewDoc?.no - 1;
    if (par === 'bk') {
      const x = i - 1;
      this.openDoc(this.transCancel?.docs[x], x);
    } else if (par === 'fd') {
      const x = i + 1;
      this.openDoc(this.transCancel?.docs[x], x);
    } else { console.log(par); }
  }

  openDoc(par: doc, i: number) {
    this.viewDoc = par;
    this.viewDoc.no = i + 1;
    this.loading = true;
    setTimeout(() => { this.loading = false }, 1500);
  }

  setTransParty() {
    this.payTransaction = this.is.initTransPay();
    this.payTransaction.parties = [this.usd.projectTeam];
    this.getSaleInventory();
  }

  initInventory() {
    this.inventory = this.is.initInventory(); // console.log(this.inventory);
    this.inventory.project = { name: this.setProject?.name, id: this.setProject?.id };
    this.inventory.type.value = this.getinvType();
    this.inventory.unit.name = this.ps?.productTypes[0]?.unit || this.is.initProd().unit || this.initUnit();/*  this.setProject?.invQuantity.unit ||  */
    this.inventory.parent = this.lisaniHack;
    this.idError = {
      alreadyExists: false
    }
    this.revisePrjQuantity();
  }

  checkSale() {

  }

  updateReInv() {
    const docRef = this.usd.afs.doc<any>(`Projects/${this.setProject.id}`);
    docRef.ref.get().then(prj => {
      if (prj.data().remQuantity.name !== this.setProject?.invQuantity.name) {
        docRef.update({ 'remQuantity': this.setProject?.invQuantity });
      }
    })
  }

  inInventory() {
    this.inventory = this.is.initInventory();
    this.inventory.type.name = this.ps?.productTypes[0]?.name || this.is.initProd().name;
    this.inventory.unit.name = this.ps?.productTypes[0]?.unit || this.is.initProd().unit;
    this.inventory.parent = this.lisaniHack;
    // this.checkPrData();
    this.revisePrjQuantity();
  }

  checkPrData() {
    this.usd.afs.doc<any>(`Projects/${this.setProject.id}`).ref.get().then(data => {
      if (data.exists) {
        const prDoc = data.data();
        prDoc.invQuantity.name = String(prDoc.invQuantity?.value);
        prDoc.invQuantity = { name: String(prDoc.invQuantity?.value), value: prDoc.invQuantity?.value || 0, unit: prDoc.invQuantity?.unit || this.initUnit() };
        // console.log('remQuantity', prDoc.remQuantity, 'invQuantity', prDoc.invQuantity);
        prDoc.invQuantity = { name: prDoc.remQuantity?.name || String(prDoc.remQuantity?.value) || 0, value: prDoc.remQuantity?.value || 0, unit: prDoc.remQuantity?.unit || prDoc.invQuantity?.unit || this.initUnit() };
        this.setProject = data.data();
      }
    })
  }

  setLegals() {
    this.lisaniHack = this.inventory.parent;
  }

  revisePrjQuantity() {
    this.usd.prDocRef.subscribe(prDoc => {
      if (prDoc && prDoc.invQuantity === undefined) {
        prDoc.invQuantity = { name: '0', value: 0, unit: '' };
      } else { this.ProductQty = prDoc.invQuantity }
      if (this.data && this.data.length === 0) {
        this.setProject.remQuantity = prDoc?.invQuantity;
        this.setProject.remQuantity.name = String(this.setProject.remQuantity.value);
        this.inventory.prjQuantity = this.setProject?.remQuantity; // console.log('prjQuantity', this.inventory.prjQuantity, this.setProject?.remQuantity);
        this.updateReInv();
      }
    })
    if (this.lisaniHack && this.lisaniHack !== '') {
      this.inventory.parent = this.lisaniHack;
    } else if (this.setProject?.legalDescription.length > 0 && !this.inventory.parent) {
      this.inventory.parent = this.setProject.legalDescription[0];
    }
    // this.inventory.prjQuantity = this.setProject?.remQuantity || this.setProject?.invQuantity; console.log('prjQuantity', this.inventory.prjQuantity, this.setProject?.remQuantity);
    const fa = this.setProject?.remQuantity;
    this.inventory.prjQuantity = fa; // console.log('prjQuantity', this.inventory.prjQuantity, this.setProject?.remQuantity);
    this.calQuantity();
  }

  calQuantity() {
    if (this.data && this.data.length > 0) {
      const fa = this.setProject?.invQuantity;
      this.inventory.prjQuantity = fa;
      this.data.forEach(element => {
        this.revQuantity(element);
      });
    } else {
      const remValue = this.setProject.remQuantity;
      this.inventory.prjQuantity.name = remValue.name;
    }
  }

  revQuantity(inventory) {
    if (inventory.size.name) {
      this.inventory.prjQuantity.name = this.inventory.prjQuantity.name;
      this.inventory.prjQuantity.name = String(Number(this.inventory.prjQuantity.name) - Number(inventory.size.name));
    }
  }

  newQuantity(inventory) {
    // this.checkPrData();
    this.revisePrjQuantity();
    const fa = Number(this.setProject.remQuantity.name);
    if (inventory?.size.name) {
      if (Number(this.inventory.prjQuantity.name) >= Number(inventory.size.name)) {
        this.error = { er: false, msg: 'No Error' };
        this.inventory.prjQuantity.name = String(fa - inventory.size.name);
      } else {
        this.error = { er: true, msg: 'You have exhausted Project Quantity' }
      }
    } else {
      this.inventory.prjQuantity.name = this.setProject.remQuantity.name;
    }
  }

  getConst() {
    const remValue = this.setProject.invQuantity.value;
    return remValue;
  }

  getinvType() {
    let fndType;
    let fndType2 = this.ps.productTypes[0]?.name ?? this.ps.productTypes[0]?.value;
    fndType = fndType2 ?? this.setProject?.productType ?? this.is.inventoryTypes[0]?.type ? this.is.inventoryTypes[0]?.type : '';
    return fndType;
  }

  initUnit(): any {
    const unit = (this.is.SIunits || this.is.allunits)[0];
    if (unit) {
      return unit.id
    }
  }

  // numberForm(nbm: string) {
  //   const count = (nbm.match(/,/g) || []).length;
  //   let word = nbm.replace('$', '').replace('.00', '')
  //   // console.log(word, count);
  //   for (let index = 0; index < count; index++) {
  //     word = word.replace(',', '');
  //     if (index + 1 === count) {
  //       // console.log(Number(word), count);
  //       return word;
  //     }
  //   }
  // }

  renamePhases(descr: string) {
    let legalDescription = '';
    if (descr.includes('Phase 2 of Stand 655 Pomona Township')) {
      legalDescription = descr.split('Phase 2 of Stand 655 Pomona Township').join('Stand 655 of Pomona Township');
    } else if (descr.includes('Phase 2 of Stand 662 Pomona Township')) {
      legalDescription = descr.split('Phase 2 of Stand 662 Pomona Township').join('Stand 662 of Pomona Township');
    } else if (descr.includes('Phase 2 of Stand 655 of Pomona Township')) {
      legalDescription = descr.split('Phase 2 of').join('');
    } else if (descr.includes('Phase 2 of Stand 662 of Pomona Township')) {
      legalDescription = descr.split('Phase 2 of').join('');
    } else {
      legalDescription = descr;
    }
    // console.log(legalDescription);
    return legalDescription
  }

  removeInventory(inv) {
    if (this.flag === 'ActualDs') {
      this.inventoryList = this.inventoryList.filter(item => item.id !== inv.id);
    } else if (this.flag === 'searchDs') {
      this.results = this.results.filter(item => item.id !== inv.id);
    } else { }
  }

  phaseOut(ds) {
    let dsd: string = '';
    if (dsd?.includes('Phase 2')) {
      dsd = ds?.replace('Phase 2 of ', ' ');
      // return dsd;
      if (dsd.includes('655 Pomona Township')) {
        dsd = 'Stand 655 of Pomona Township';
        return dsd;
      } else if (dsd.includes('662 Pomona Township')) {
        dsd = 'Stand 662 of Pomona Township';
        return dsd;
      } else {
        return ds;
      }
    } else {
      return ds;
    }
  }

  logSetInventory() {
    // console.log(this.entInvent);
  }

  getAgents() {
    this.agents = this.ps.team.filter(a => a.structuralRole?.id === 'advisor-agent');
  }

  returnInventory() {
    this.projectInv = this.usd.afs.collection<any>(`Projects/${this.setProject.id}/inventory`).valueChanges();
    return this.projectInv;
  }

  async dataCall() {
    const inventory = await this.data;
    try {
      if (inventory?.length > 0) {
        this.data.forEach(element => {
          if(element?.party) {
            element.party.name = this.getPartyData(element?.party);
            // console.log(element.party.name, this.projectData?.getTeams);
          }
          if(element?.agent) {
            element.agent.name = this.getPartyData(element?.agent);
          }
          if (element.parent.includes('Phase 2')) {
            element.parent = this.renamePhases(element.parent);
            element.description = element.type.value + ' ' + String(element.productNo.name) + ' of ' + element?.parent;
            element.name = element.description;
            this.checkAllTrans(element);
            console.log(element.name, element.parent, element, 'Phase 2');
            this.invUpdate(element);
          }
        });
        this.inventoryList = this.data;
        this.projectData.getInventory = this.data;
        const prIndex = this.usd?.myprojects?.findIndex(x => x.id === this.projectData.id);
        if(prIndex > -1) {
          this.usd.myprojects[prIndex].getInventory = this.data;
        }
      }
    } catch (er) { console.log('Faile to Get Data') }
  }

  dsDover() {
    this.partyInv = [];
    this.getInventory();
    return this.getCmpInv();
  }

  getCmpInv() {
    console.log(this.usd.projectTeam.id);
    const partyInv = this?.data.filter(inv => ((inv?.party?.id === this.usd.projectTeam.id) || (inv?.agent?.id === this.usd.projectTeam.id)));
    this.partyInv = partyInv;
    return partyInv;
  }

  async checkAllTrans(x) {
    // const xfiltered = this.obsTrans?.filter(i => (i.product && i.product.productNo.name === x.productNo.name));
    const eData = await this.obsTrans;
    try {
      const tnData = eData?.filter(i => (i?.product && (i.product.productNo.name === x.productNo.name)));
      if (tnData.length > 0) {
        for (let i = 0; i < tnData.length; i++) {
          const elem = tnData[i];
          // this.ws.updatetrans(x);
          elem.product = x;
          elem.name = x.name;
          elem.description = x.name;
          if (!elem.product.party || !elem.product.party?.name || elem.product.party?.name === '') {
            elem.product.party = x?.party;
          }

          if((x.SellStatus?.status ==='Reserved') && (!x.SellStatus?.tid || x.SellStatus?.tid === '') && (elem.product.productNo.name === x.productNo.name)){
            // console.log(x.SellStatus, elem.product.SellStatus, elem.id);
            // this.checkReserveTid(elem, x);
          }
          // this.updatetrans(elem);
        }
      }
    } catch (error) {
      // console.log(error);
    }
  }

  checkReserveTid(trn, obj){
    const prjRef = this.usd.afs.collection(`Projects/${obj.project.id}/inventory`);
    const invRef = this.usd.afs.collection('inventory');
    prjRef.doc(obj.id).update({ 'product.SellStatus.tid': trn.id }).then(() => {/* console.log('tid updated') */ }).catch(er => console.log(er));
    invRef.doc(obj.id).update({ 'product.SellStatus.tid': trn.id }).then(() => {/* console.log('tid updated') */ }).catch(er => console.log(er));
  }

  updatetrans(data) {
    // console.log(data.name, data);
    const inRef = this.usd.afs.doc(`Users/${this.usd.userId}/transactions/${data.id}`);
    if(data.product.initiated?.by.id) {
      this.usd.afs.doc(`Users/${data?.product?.initiated?.by?.id}/transactions/${data.id}`).update(data);
    }
    if(data.product.checked?.by.id) {
      this.usd.afs.doc(`Users/${data?.product?.checked?.by?.id}/transactions/${data.id}`).update(data);
    }
    if(data.product.approved?.by.id) {
      this.usd.afs.doc(`Users/${data?.product?.approved?.by?.id}/transactions/${data.id}`).update(data);
    }
    if(data.Initiated?.by.id) {
      this.usd.afs.doc(`Users/${data?.Initiated?.by?.id}/transactions/${data.id}`).update(data);
    }
    if(data.Checked?.by.id) {
      this.usd.afs.doc(`Users/${data?.product?.Checked?.by?.id}/transactions/${data.id}`).update(data);
    }
    if(data.Approved?.by.id) {
      this.usd.afs.doc(`Users/${data?.product?.Approved?.by?.id}/transactions/${data.id}`).update(data);
    }
    this.usd.afs.doc(`Transactions/${data.id}`).set(data);
    this.entTransactions(data);
    this.prjtransactions(data);
  }
  
  entTransactions(data) {
    // console.log(data);
    if ((this.usd.setCompany.id && this.usd.setCompany.id !== '' && (data?.id && data?.id !== ''))) {
      this.usd.afs.doc(`Enterprises/${this.usd.setCompany.id}/transactions/${data?.id}`).set(data).then(() => {
        /* console.log('Enterprise transactions set'); */
      }).catch(er => console.log(er));
    }
  }
  
  prjtransactions(data) {
    // console.log(data);
    if (data.project && data.project?.id !== '') {
      this.usd.afs.doc(`Projects/${this.usd.setProject.id}/transactions/${data?.id}`).set(data).then(() => {
        /* console.log('Projects transactions set'); */
      }).catch(er => console.log(er));
    }
  }
  
  resetSale(x) {
    const SellStatus = {
      status: 'Available', payment: '', customer: [], date: '', reservations: [], by: { name: '', id: '' }, tid: ''
    }
    const invRef = this.usd.afs.doc(`inventory/${x.id}`);
    const prjRef = this.usd.afs.doc(`Projects/${x.project.id}/inventory/${x.id}`);
    invRef.update({'SellStatus' : SellStatus, 'parties' : []});
    prjRef.update({'SellStatus' : SellStatus, 'parties' : []});
    this.ns.showNotification('SaleNotFnd', 'top', 'right', x);
  }

  fixSize(x) {
    const setSize = x.size.name;
    // console.log(setSize, x);
    x.id ? this.usd.afs.doc(`inventory/${x.id}`).update({'size' : setSize}).then(() => console.log('updated')).catch(er => console.log('failed to update', er)) : 'No Id';
    x.id ? this.usd.afs.doc(`Projects/${x.project.id}/inventory/${x.id}`).update({'size' : setSize}).then(() => console.log('updated')).catch(er => console.log('failed to update', er)) : 'No Id';
  }

  fixtrans(x) {
      x.id ? this.usd.afs.doc(`Transactions/${x.id}`).update(x).then(() => console.log('updated')).catch(er => console.log('failed to update', er)) : 'No Id';
  }


  public getInventory() {
    this.refreshList();
    console.log('getInventory');
    const wInventory = this.returnInventory();
    this.wInventory = wInventory;
    wInventory.subscribe(dta => {
      dta = this.sortData(dta);
      if (dta && dta.length > 0) {
        dta.forEach(element => {
          if(element?.party) {
            element.party.name = this.getPartyData(element?.party);
            // console.log(element.party.name, this.projectData?.getTeams);
          } else if(!element.party && (!element.party.name || element.party.name === undefined)){
            const setParty: any = this.setParty;
            setParty.initiated = { by: { name: '', id: '' }, status: false, date: '' };
            setParty.checked = { by: { name: '', id: '' }, status: false, date: '' };
            setParty.approved = { by: { name: '', id: '' }, status: false, date: '' };
            element.party = setParty;
          }
          if(element?.agent) {
            element.agent.name = this.getPartyData(element?.agent);
          }
          const LegalName = element.type.value + ' ' + String(element.productNo.name) + ' of ' + element.parent;
          if (!element.description) {
            element.description = LegalName;
            element.name = LegalName;
          }
          if (element.description !== LegalName) {
            element.description = LegalName;
            element.name = LegalName;
          }
          // console.log(element.productNo.name, element.project);
          if (element.id && (!element.project || element.project?.name === '')) {
            console.log(element, 'Inventory Pr update');
            this.usd.afs.doc<any>(`Projects/${this.setProject.id}/inventory/${element.id}`).update({'project.id': this.usd.setProject.id, 'project.name': this.usd.setProject.name})
          }
          if (!element.id || element.id === '') {
            console.log(element.id);
            this.correctId(element);
          }
          // else {
          //   console.log('else Root')
          //   this.usd.afs.doc(`inventory/${element.id}`).ref.get().then(dr => {
          //     if(!dr.exists) {
          //       this.usd.afs.doc(`inventory/${element.id}`).set(element).catch(er => {
          //         console.log('else root inventory set failed', er)
          //       });
          //     }
          //   })
          // }
          this.getSaleInv();
        });
      }
      // console.log(dta.length, dta);
      // this.dataChecker(dta); haipo
      
      this.data = dta;
      this.projectData.getInventory = dta;
      this.inventoryBool = true;
      const prIndex = this.usd?.myprojects?.findIndex(x => x.id === this.projectData.id);
      if(prIndex > -1) {
        this.usd.myprojects[prIndex].getInventory = dta;
      }
      this.findDublicates();
      // this.dataCall();
      if (this.usd?.onlyPj() === this.usd?.setProject?.id) {
        do {
          setTimeout(() => {
            // this.mdb.main(dta);
          }, 60000)
        } while (this.mdbCount <= 0);
      }
      this.getSold();
      this.updateTransData();
      this.getAvailableInv();
    });
    this.getTransTypes();
  }

  correctId(element) {
    console.log('Correcting', element.id);
    this.usd.afs.collection<any>(`Projects/${this.setProject.id}/inventory`, ref => ref.where('productNo.name', '==', element.productNo.name)).snapshotChanges().pipe(
    map(b => b.map(a => {
      const data = a.payload.doc.data() as any;
      data.id = a.payload.doc.id;
      element.id = data.id;
      return data;
    }))).subscribe(async invfound => {
      if (invfound.length > 0) {
        const found = await invfound.find(chinhu => chinhu.productNo.name === element.productNo.name && chinhu.project.name === element.project.name);
        if (found && ((found.id !== undefined) || found.id !== '')) {
          if(found.project.id === this.setProject.id) {
            this.usd.afs.doc<any>(`Projects/${this.setProject.id}/inventory/${found.id}`).update({ 'id': found.id }).then(() => {
              this.usd.afs.doc(`inventory/${element.id}`).update({ 'id': found.id }).catch(er => {
                console.log('root inventory update failed', 'inventory: ', found, er);
                console.log('root set next', 'inventory: ', found,);
                this.usd.afs.doc(`inventory/${element.id}`).set(found).catch(er => {
                  console.log('root inventory set failed', er)
                });
              });
            }).catch(er => console.log('Projects inventory update failed', er));
          }
        }
      }
    })
  }

  
  osTrans(trans) {
    // console.log(trans?.length, trans ? trans[0]?.company.id: 'No transactions', this.project.companyId, trans ? trans[0]?.project.id: 'No transactions', this.project.id)
    this.obsTransArr = trans;
    this.trans = trans;
    this.obsTrans = trans;
    this.projectData.prjTrans = trans;
    this.loading = true;
  }

  managetrans(trans, id) {
    const dsTrns = trans.sort((a, b) => (b?.Approved?.date || b?.Checked?.date || b?.createdOn).localeCompare( a?.Approved?.date || a?.Checked?.date || a?.createdOn));
    const cmpTrns = dsTrns.filter(trn => trn.company.id === this.ent?.id || this.ent?.id);
    const cmpprojecttrans = cmpTrns.filter(trn => trn.project.id === id || this.setProject.id);
    const projecttrans = dsTrns.filter(trn => trn.project.id === id || this.setProject.id);
    // const dsTrns = das;
    //  console.log('getData()');'trans', trans?.length); /* trans */
    //  console.log('getData()');'Inv', this.data?.length);
    if (this.setProject.company?.structuralRole?.id !== 'maincontructactive' || this.setProject.project?.company?.structuralRole?.id !== 'maincontructactive') {
    //  console.log('getData()');this.project?.company?.structuralRole?.name, 'Ent is not maincontructactive');
      this.osTrans(cmpprojecttrans);
      // return cmpTrns;
    } else {
      //  console.log('getData()');this.prj?.company?.structuralRole?.name || this.project?.company?.structuralRole?.name, 'Ent is maincontructactive');
      this.osTrans(projecttrans);
      // return projecttrans;
    } 
    this.salesTrans = [];
    this.salesTrans = dsTrns.filter(trn => trn?.component === 'Sale');
    this.projectData.salesTrans = this.salesTrans;
    dsTrns.forEach(element => {
      if(element?.party) {
        element.party.name = this.getPartyData(element?.party) || element?.party?.name
      }
      if(element?.product?.party) {
        element.product.party.name = this.getPartyData(element?.product?.party) || element?.product?.party?.name
      }
      if(element?.product?.agent) {
        element.product.agent.name = this.getPartyData(element?.product?.agent) || element?.product?.agent?.name
      }
      if (!element?.component && (element?.product?.transAction === 'newInventory' || element?.product?.transAction === 'checkInventory' || element?.product?.transAction === 'apprvInventory')) {
        if (element.initiated && element.initiated.by.name !== '' && !element.initiated.by.photoURL) {
          element.initiated.by.photoURL = this.getImg(element.initiated?.by.id)
        }
        if (element.checked && element.checked.by.name !== '' && !element.checked.by.photoURL) {
          element.checked.by.photoURL = this.getImg(element.checked?.by.id)
        }
        if (element.approved && element.approved.by.name !== '' && !element.approved.by.photoURL) {
          element.approved.by.photoURL = this.getImg(element.approved?.by.id)
        }
      }
    })
  }

  async queryOtherTrans(id) {
    //  console.log('getData()');'id', id);
       this.otherTrans(id).subscribe(rawData => {
        this.rawData.concat(rawData);
        // console.log('lol', this.rawData?.length, this.rawData);
        this.projectData ? this.projectData.prjTrans = this.rawData: '';
        this.transArr = this.rawData;
        this.allPrjTrans = of(this.rawData);
        this.allPrjTrans = this.allPrjTrans;
        this.rawData[0]?.project.id === id ? this.managetrans(this.rawData, id): '';
        return this.allPrjTrans;
       })
     }
  
  async queryTrans(id) {
    // console.log('getData()', 'id', id, this.setProject?.id);
    if (id && id !== '') {
      // if (this.setProject?.id && this.setProject?.id !== '') {
    // console.log('getData()', 'id', id, this.setProject?.id);
    this.sales(id).subscribe(rawData => {
      // const data = rawData.filter(x => ((x.project.id === this.setProject.id) || (x?.projectId === this.setProject?.id))); /* (x.company.id === this.prj.companyId) || (x.company.id === this.prj?.company?.id) && */
      const data = rawData.filter(x => x.project.id === id); /* (x.company.id === this.prj.companyId) || (x.company.id === this.prj?.company?.id) && */
      // this.priceAdjust(data);
      // console.log(rawData?.length, rawData);
      this.rawData = this.checkphased(data);
      this.projectData ? this.projectData.salesTrans = this.rawData: '';
      this.projectData ? this.projectData.prjTrans = this.rawData: '';
      this.allPrjTrans = of(this.rawData);
      this.allPrjTrans = this.allPrjTrans;
      this.transArr = this.rawData;
      this.loading = true;
      this.rawData[0]?.project?.id === id || this.managetrans(this.rawData, id);
      this.queryOtherTrans(id);
      data.forEach(element => {
        if (element?.component === 'Sale' && element?.Approved.by.name !== '' && !element?.Approved.by?.photoURL) {
          element.Approved.by.photoURL = this.usd.myContacts.find(u => u.id === element?.Approved.by.id)?.photoURL;
        } else if (element?.component === 'Sale' && element?.Checked.by.name !== '' && !element?.Checked.by?.photoURL) {
          element.Checked.by.photoURL = this.usd.myContacts.find(u => u.id === element?.Checked.by.id)?.photoURL;
        } else if (element?.component === 'Sale' && element?.Initiated.by.name !== '' && !element?.Initiated.by?.photoURL) {
          element.Initiated.by.photoURL = this.usd.myContacts.find(u => u.id === element?.Initiated.by.id)?.photoURL;
        } else if (!element?.component) {} else {
          // console.log('getData()');'component', element?.component, element);
          // return './assets/img/faces/avatar.png';
        }
      });
      return this.allPrjTrans;
    })
    }
    // return await this.allPrjTrans;
  }

  typeSet(s) {
    return String(s);
  }

  getParty(x) {
    return this.data.find(d => d.id === x.id).party?.name || ''
  }

  updateTransData() {
    const dataTrans = this.obsTrans;
    this.obsTransArr = dataTrans;
    this.data.forEach(data => {
      if(data?.party) {
        data.party.name = this.getPartyData(data?.party);
        // console.log(element.party.name, this.projectData?.getTeams);
      }
      if(data?.agent) {
        data.agent.name = this.getPartyData(data?.agent);
      }
      data.description = (data?.type?.value + ' ' + data?.productNo?.name + ' of ' + data.parent || '');
      // console.log(data?.name, 'desc', data.description);
      this.obsTransArr?.find((trn, i) => {
        if (trn.product && (trn.product.productNo?.name === data?.productNo?.name) && (data?.productNo?.name === trn?.product?.productNo?.name) && (trn?.product?.transAction) !== 'editInv') {
          this.obsTransArr[i].name = this.obsTransArr[i].product.name || data?.name;
          this.obsTransArr[i].product.description = this.obsTransArr[i].product.name || data?.name;
        }
      });
      
      const saleDocs = this.obsTransArr?.filter((trn: compTans, i) => trn?.component === 'Sale');
      if (saleDocs && saleDocs.length > 0) {
        const doc = saleDocs?.find((trn: compTans) => (trn.product.productNo.name === data.productNo.name));
        if (doc && doc.amount && (!data.amount || !data.Amount)) {
          data.amount = doc.amount;
          data.Amount = doc.amount;
        }
        if (doc && doc.date && (!data.saledate)) {
          data.saledate = doc.date;
        }
        if (doc && doc?.parties.length > 0 && data?.parties?.length === 0) {
          // console.log('doc not Fnd', doc, 'parties', data.parties.length);
          data.parties = data.parties.length;
        }
        if (!doc && data?.parties && data?.parties?.length > 0) {
          data.parties = [];
          // console.log('doc not Fnd', doc, 'parties', data.parties.length);
        } else if (doc && data?.parties && data.parties.length > 0) {
          // console.log('doc not Fnd', doc, 'parties', data.parties.length);
        } else {
          // console.log('doc found', doc, 'parties', data.parties.length);
        }
      }
      if (!data?.tid) {
        const doc = this.obsTransArr?.find((trn, i) => {
          if (trn.product && (trn.product?.productNo?.name === data.productNo.name) && (trn?.product?.transAction !== 'editInv')) {
            return trn
          } else if (trn.product?.productNo?.name === data.productNo.name && trn?.product?.transAction === 'editInv') {
            return trn;
          }
          if (trn.product?.productNo?.name === data.productNo.name && trn?.parties?.length > 0 && (!data.parties || data.parties?.length === 0)) {
            this.usd.afs.doc(`inventory/${trn.product.id}`).update({ 'parties': trn.parties, 'tid': trn.id }).then(() => console.log('root updated_inventory'));
            this.usd.afs.doc(`Projects/${trn.product.project.id}/inventory/${trn.product.id}`).update({ 'parties': trn.parties, 'tid': trn.id }).then(() => console.log('root updated_inventory'));
          }
        });
        if (doc) {
          const tid = doc?.id;
          data.tid = tid;
          if ((tid && (tid !== undefined)) && (data?.id)) {
            this.usd.afs.doc(`inventory/${data?.id}`).update({ 'tid': tid })
              .then(() => console.log(`root inv ${data.productNo.name}, tid updated`)).catch(er => console.log(`root inv ${data.productNo.name}, tid update failed`, er));
            this.usd.afs.doc(`Projects/${data.project.id}/inventory/${data.id}`).update({ 'tid': tid })
              .then(() => console.log(`prj inv ${data.productNo.name}, tid updated`)).catch(er => console.log(`prj inv ${data.productNo.name}, tid update failed`, er));
          } else if (!data?.id) {
            // console.log(data);
            this.usd.afs.collection(`Projects/${data.project.id}/inventory/`, ref => ref.where('createdOn', '==', data.createdOn)).snapshotChanges().pipe(
              // this.usd.afs.collection(`inventory`, ref => ref.where('createdOn', '==', data.createdOn )).snapshotChanges().pipe(
              map(b => b.map(a => {
                const data = a.payload.doc.data() as any;
                // console.log(data);
                data.id = a.payload.doc.id;
                return data;
              }))).subscribe(invfound => {
                if (invfound.length === 1) {
                  // console.log(invfound);
                  data.id = invfound[0].id;
                  if (data.id) {
                    this.usd.afs.doc(`inventory/${data?.id}`).update({ 'tid': tid, 'id': invfound[0]?.id }).then(() => console.log('inventory updated')).catch(er => console.log(`root inv ${data.productNo.name}, inventory failed`, er));
                    this.usd.afs.doc(`inventory/${invfound[0]?.id}`).update({ 'tid': tid, 'id': invfound[0]?.id }).then(() => console.log('inventory updated 2')).catch(er => console.log(`root inv ${data.productNo.name}, tid update failed`, er));
                  }
                } else {
                  // console.log(invfound.length, invfound);
                }
              })
            // .ref.get().then(fndData => {
            //   if(fndData.docs?.length === 1) {
            //     console.log(fndData.docs);
            //   }
            // })
          }
        } else { }
      }
    });
    this.projectData.getInventory = this.data;
    const prIndex = this.usd?.myprojects?.findIndex(x => x.id === this.projectData.id);
    if(prIndex > -1) {
      this.usd.myprojects[prIndex].getInventory = this.data;
    }
    this.obsTransArr?.forEach(data => {
      const trnFound = this.data.find(da => da.tid === data.id);
      const trnIndex = this.data.findIndex(da => da.tid === data.id);
      if (trnFound && trnFound.product?.checked.status === false && data.checked.status === true) {
        this.obsTransArr[trnIndex].product.checked.status = data.checked.status;
      }
      if (trnFound && trnFound?.product?.approved.status === false && data.approved.status === true) {
        this.obsTransArr[trnIndex].product.approved.status = data.approved.status;
      }
    })
    this.getSaleTrans();
  }

  dataChecker(data: any[]) {
    var uniq = data
      .map((objData) => {
        return { count: 1, name: objData.productNo.name + ' 0f ' + objData.parent };
      }).reduce((a, b) => {
        a[b.name] = (a[b.name] || 0) + b.count
        return a
      }, {})

    var duplicates = Object.keys(uniq).filter((a) => uniq[a] > 1)
    // console.log('duplicates', duplicates);
  }

  findDublicates() {
    this.dublicatesEntry = [];
    var uniq = this.data
      .map((objData) => {
        return { count: 1, name: objData.name };
      }).reduce((a, b) => {
        a[b.name] = (a[b.name] || 0) + b.count
        return a
      }, {})

    var duplicates = Object.keys(uniq).filter((a) => uniq[a] > 1);
    if (duplicates.length > 0) {
      for (let i = 0; i < duplicates.length; i++) {
        const elem = duplicates[i];
        const fnd = this.data.find(obj => obj.name === elem)
        // console.log(elem, fnd);
        this.dublicatesEntry.push(fnd);
        // console.log(this.dublicatesEntry?.length, 'dublicates');
        // this.results = this.dublicatesEntry;
      }
      return this.dublicatesEntry;
    }
    // return this.dublicatesEntry;
    // console.log('duplicates', duplicates);
  }

  dublicateSalesArch() {
    this.dublicateSalesTrns = [];
    var uniq = this.salesTrans
      .map((objData) => {
        return { count: 1, name: objData.name };
      }).reduce((a, b) => {
        a[b.name] = (a[b.name] || 0) + b.count
        return a
      }, {})

    var duplicates = Object.keys(uniq).filter((a) => uniq[a] > 1);
    if (duplicates.length > 0) {
      for (let i = 0; i < duplicates.length; i++) {
        const elem = duplicates[i];
        const fnd = this.salesTrans.find(obj => obj.name === elem)
        this.dublicateSalesTrns.push(fnd);
      }
      return this.dublicateSalesTrns;
    }
    // return this.dublicatesEntry;
    // console.log('duplicates', duplicates);
  }

  dublicateSales() {
    this.dublicateSalesTrns = [];
    var uniq = this.salesTrans
      .map((objData) => {
        return { count: 1, name: objData.name };/*  customer: objData.parties[0].name */
      }).reduce((a, b) => {
        a[b.name] = (a[b.name] || 0) + b.count;
        // console.log(a);
        return a
      }, {})

    var duplicates = Object.keys(uniq).filter((a) => uniq[a] > 1);
    if (duplicates.length > 0) {
      for (let i = 0; i < duplicates.length; i++) {
        const elem = duplicates[i];
        const fnd = this.salesTrans.find(obj => obj.name === elem)
        // console.log(elem, fnd);
        this.dublicateSalesTrns.push(fnd);
        // this.results = this.dublicatesEntry;
      }
      return this.dublicateSalesTrns?.length > 1 ? this.dublicateSalesTrns : [];
    }
    // return this.dublicatesEntry;
    // console.log('duplicates', duplicates);
  }

  dataCheckerArch(data: any[]) {
    var uniq = data
      .map((objData) => {
        return { count: 1, name: objData.productNo.name + ' 0f ' + objData.parent };
      }).reduce((a, b) => {
        a[b.name] = (a[b.name] || 0) + b.count
        return a
      }, {})

    var duplicates = Object.keys(uniq).filter((a) => uniq[a] > 1)
    // console.log('duplicates', duplicates);
  }

  sortData(inv: any[]) {
    return inv.sort((a, b) => b?.createdOn.localeCompare(a?.createdOn));
  }

  sortInv(inv: any[], call) {
    let data = [];
    switch (call) {
      case 'productNo1st': {
        //statements; 
        data = inv.sort((a, b) => a.productNo.name - b?.productNo.name);
        break;
      }
      case 'productNolst': {
        //statements; 
        data = inv.sort((a, b) => b.productNo.name - a.productNo.name);
        break;
      }
      case 'Date1st': {
        //statements; 
        data = inv.sort((a, b) => a?.createdOn.localeCompare(b?.createdOn));
        break;
      }
      case 'Datelst': {
        //statements; 
        data = inv.sort((a, b) => b?.createdOn.localeCompare(a?.createdOn));
        break;
      }
      default: {
        //statements; 
        data = inv.sort((a, b) => b?.createdOn.localeCompare(a?.createdOn));
        break;
      }
    }
    return data;
  }

  saleStatus(inv: any[], call) {
    let data = [];
    switch (call) {
      case 'initiated': {
        data = inv.filter(a => ((a.Initiated.status === true) && (a.Checked.status === false)));
        // console.log(data.length);
        break;
      }
      case 'checked': {
        data = inv.filter(a => ((a.Checked.status === true) && (a.Approved.status === false)));
        // console.log(data.length);
        break;
      }
      case 'approved': {
        data = inv.filter(a => (a.Approved.status === true));
        // console.log(data.length);
        break;
      }
      case 'dublicates': {
        data = this.dublicateSales();
        //  console.log(data.length);
        break;
      }
      case 'team': {
        data = inv.filter(a => (a?.product?.party?.name === this.sec));
        //  console.log(data.length);
        break;
      }
      default: {
        data = inv;
        break;
      }
    }
    return data;
  }

  sortTrans(inv: any[], call) {
    let data;
    data = [];
    switch (call) {
      case 'productNo1st': {
        //statements; 
        data = inv.sort((a, b) => a.product.productNo.name - b?.product.productNo.name);
        break;
      }
      case 'productNolst': {
        //statements; 
        data = inv.sort((a, b) => b.product.productNo.name - a.product.productNo.name);
        break;
      }
      case 'Date1st': {
        // statements; 
        data = inv.sort((a, b) => a?.createdOn.localeCompare(b?.createdOn));
        break;
      }
      case 'Datelst': {
        // statements; 
        data = inv.sort((a, b) => b?.createdOn.localeCompare(a?.createdOn));
        break;
      }
      default: {
        // statements; 
        data = inv.sort((a, b) => b?.createdOn.localeCompare(a?.createdOn));
        break;
      }
    }
    // })
    return data;
  }

  showData(x, invdata) {
    this.sec = x;
    if (x === 'initiated') {
      this.value = 'Initiated Inventory';
      return this.valueGot(this.value, this.sec, invdata);
    } else if (x === 'All Sales' || x === 'All Inventory') {
      this.value = 'All';
      return this.results = invdata;
    } else if (x === 'checked') {
      this.value = 'Checked Inventory';
      return this.valueGot(this.value, this.sec, invdata);
    } else if (x === 'approved') {
      this.value = 'Approved Inventory';
      return this.valueGot(this.value, this.sec, invdata);
    } else if (x === 'dublicates') {
      this.value = 'Inventory Dublicates';
      return this.valueGot(this.value, this.sec, invdata);
    } else if (this.mainParties.find(item => item.name === this.sec)) {
      this.value = `${this.sec}`;
      return this.valueGot(this.value, 'team', invdata);
    } else if (this.agents.find(item => item.name === this.sec)) {
      this.value = `${this.sec}`;
      return this.valueGot(this.value, 'agent', invdata);
    } else { 
      return this.valueGot(this.value, this.sec, invdata);
    }
  }

  async valueGot(inhu, act, invdata) {
    console.log(inhu);
    // this.results = this.statusInv(this.inventoryList, act);
    // const call = act;
    // this.results = this.statusInv(invdata, act);
    return this.statusInv(invdata, act);
    // console.log(this.results?.length);
  }
  
  statusInv(trans: any[], call) {
    let teamFnd;
    if((call === 'team') || (call ===  'agent')){
      teamFnd = this.ps.team.find(cmp => cmp.name === this.sec);
    };
    console.log(this.sec, teamFnd?.name);
    let data = [];
    switch (call) {
      case 'initiated': {
        data = trans.filter(a => ((a.initiated.status === true) && (a.checked.status === false)));
        this.results = data;
        // return data;
        break;
      }
      case 'checked': {
        data = trans.filter(a => ((a.checked.status === true) && (a.approved.status === false)));
        this.results = data;
        // return data;
        break;
      }
      case 'approved': {
        data = trans.filter(a => (a.approved.status === true));
        this.results = data;
        // return data;
        break;
      }
      case 'dublicates': {
        data = this.findDublicates();
        this.results = data;
        // return data;
        break;
      }
      case 'team': {
        // data = teamFnd ? trans.filter(a => (a?.party?.id === teamFnd?.id)): [];
        if (teamFnd) {
          data = trans.filter(a => (a?.party?.id === teamFnd?.id)); /*  || [] */
          console.log(data?.length);
          this.results = data;
        }
        // return data;
        break;
      }
      case 'agent': {
        console.log('agent');
        // data = teamFnd ? trans.filter(a => (a?.agent?.id === teamFnd?.id)): [];
        if (teamFnd) {
          data = trans.filter(a => (a?.agent?.id === teamFnd?.id));/*  || [] */
          console.log(data?.length);
          this.results = data;
          // return data;
        }
        break;
      }
      default: {
        data = trans;
        break;
      }
    }
  }

  getPartyData(x) {
    if((this.projectData?.getTeams && this.projectData?.getTeams?.length > 0) && x?.id) {
      return this.projectData?.getTeams?.find(cmp => cmp.id === x?.id)?.name || x.name;
    }
    if((this.projectData?.getClients && this.projectData?.getClients?.length > 0) && x?.id) {
      this.projectData?.getClients?.find(cmp => cmp.id === x?.id)?.name || x.name;
    }
  }

  getTransTypes() {
    this.transType = this.usd.afs.collection<any>(`Projects/${this.setProject.id}/transType`).valueChanges();
  }

  typeSetting() {
    // console.log(this.type);
    // this.transaction.type = { name: this.pdType.name, verified: false, approved: false }
    // console.log(this.data?.filter(inv => inv.type?.value === this.type?.value));
  }

  productType() {
    // console.log(this.pdType);
    // this.transaction.type = { name: this.pdType.name, verified: false, approved: false }
    // console.log(this.data?.filter(inv => inv.type?.value === this.pdType?.type));
    this.newInventory = this.data?.filter(inv => inv.type?.value === this.pdType?.type);
  }

  saleInventory(item) {
    this.initPayTrans();
    this.transaction.product = item;
  }

  getSaleInventory() {
    this.initPayTrans();
    this.newInventory = this.data?.filter(inv => {
      if (inv.initiated?.status && inv.checked?.status && inv.approved?.status) {
        return inv;
      }
    });
  }

  getCustomerDs(x: any[]) {
    this.cusCart = [];
    // const transad = this.realArr.filter(chiitiko => { chiitiko.parties.find(n => n.id === x.id); console.log(chiitiko.parties) });
    // console.log('Cus ', x.length, transad.length, '/', this.realArr.length);
    // Promise.all(transad).then(result => {
    //   result.forEach(element => {
    //     this.cusCart.push(element.product);
    //     // console.log(this.cusCart.length);
    //   });
    // })
    this.getCusProducts(x);
  }

  getCusProducts(cus: any[]) {
    this.custProducts = [];
    cus.forEach(element => {
      const transad = this.data?.filter(chinhu => {
        const munhuFnd = chinhu?.SellStatus?.customer?.find(n => {
          // console.log(n.id, cus);
          if (n.id === element.id) {
            return n
          }
        });
        // console.log(chinhu?.SellStatus?.customer, cus, munhuFnd);
        if (munhuFnd) {
          return chinhu;
        };
      });
      this.payTransaction.product = this.is.initInventory();
      // console.log('Cus ', cus.length, transad.length, '/', this.data.length);
      this.custProducts = transad;
    });
  }

  payMethod() {
    // console.log(this.transaction);    /* this.transaction.paymentMethod */
  }

  getTransParties() {
    this.transParties = [];
    const cmps: any[] = this.ps.team; cmps?.forEach(cmp => cmp.srTag = 'Enterprise');
    const emps: any[] = this.ps.labour; emps?.forEach(emp => emp.srTag = 'Individual');
    const customers: any[] = this.ps.clients; customers?.forEach(cus => cus.srTag = 'Enterprise');
    const suppliers: any[] = this.ps.suppliers; suppliers?.forEach(sup => sup.srTag = 'Enterprise');
    this.transParties = suppliers || [];
    customers?.forEach(element => {
      const testData = this.transParties?.find(chinhu => chinhu.id === element.id);
      if (!testData) {
        this.transParties.push(element)
      }
    });
    emps?.forEach(element => {
      const testData = this.transParties?.find(chinhu => chinhu.id === element.id);
      if (!testData) {
        this.transParties.push(element)
      }
    });
    cmps?.forEach(element => {
      const testData = this.transParties?.find(chinhu => chinhu.id === element.id);
      if (!testData) {
        this.transParties.push(element)
      }
    });

  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.data = [];

  }

  check(bn) {
    const invData: string = bn.productNo.name;
    let db = this.data;
    if (invData) {
      let result = db?.findIndex((i) => (i.productNo.name === invData && i.parent === bn.parent)) !== -1 ? { alreadyExists: true } : null;
      this.idError = result;
      if (result) {
        return result.alreadyExists;
      }
    }
  }


  checkArch(bn) {
    const paremail = bn.email;
    const invData: string = bn.productNo.name;
    let db = this.data;
    if (invData) {
      let result;
      if (db.find(item => item.email === paremail)) {
        result = true;
      } else {
        result = null;
      }
      this.idError = result;
      return result;
    } else {

    }
  }

  initEntInventory() {
    // this.getContructSum();
    this.getAvailableInv();
    this.setProduct = null;
    const setParty = this.usd.projectTeam;
    this.setParty = setParty;
    if (!this.setParty.remQuantity) {
      this.setParty.remQuantity = this.setParty.invQuantity;
    }
    this.entInvent = this.is.initEntInventory(); // console.log(this.entInvent);
    this.entInvent.entQuantity = this.setParty?.exitValue;
    this.entInvent.party = { name: this.usd.projectTeam.name, id: this.usd.projectTeam.id };
    this.entInvent.unit.name = this.initUnit();
    // this.reviseEntQuantity();
  }


  initInventoryEnt() {
    // this.getContructSum();
    this.getAvailableInv();
    // this.setProduct = null;
    const setParty = this.usd.projectTeam;
    this.setParty = setParty;
    this.entInvent.entQuantity = this.setParty?.exitValue;
    this.entInvent.party = { name: this.usd.projectTeam.name, id: this.usd.projectTeam.id };
    this.entInvent.unit.name = this.initUnit();
    // this.reviseEntQuantity();
  }

  getAvailableInv() {
    this.data.forEach(element => {
      if (!element.party) {
        element.party = {};
      }
    });
    const data = this.data.filter(inv => inv.initiated?.status === true && inv.checked?.status === true && inv.approved?.status === true); // console.log(data.length, data);
    const finalData = data.filter(inv => !inv.party || !inv.party?.name); // console.log(finalData.length, finalData);
    this.availableInv = finalData;
    return data;
  }

  getSaleTrans() {
    const transa = this.obsTrans;
    const realArr = transa?.filter(chiitiko => (chiitiko?.component === 'Sale'));
    Promise.all(transa).then(() => {
      realArr?.forEach(chiitiko => {
        if (!chiitiko.Initiated) {
          chiitiko.Initiated = {
            by: { name: chiitiko.by.name, id: chiitiko.by.id },
            status: true, date: chiitiko.createdOn
          }
        }
        if (!chiitiko.Checked) {
          chiitiko.Checked = {
            by: { name: '', id: '' }, status: false, date: ''
          }
        }
        if (!chiitiko.Approved) {
          chiitiko.Approved = {
            by: { name: '', id: '' }, status: false, date: ''
          }
        }
        // if (chiitiko.Approved?.status === true) {
        const inv = this.data?.find(invdata => invdata.productNo.name === chiitiko.product.productNo.name);
        if (inv && (!inv?.SellStatus?.status || inv?.SellStatus?.status !== 'Sold')) {
          inv.SellStatus = {
            status: 'Sold', payment: 'Pending ...', customer: chiitiko.parties, date: chiitiko.date, reservations: []
          }
          this.updateInventory(inv);
        }
        // }
      })
      this.realArr = realArr;
    }).catch(er => console.log(er));
  }

  reserveInv(inv, cus) {
    inv.SellStatus = {
      status: 'Reserved', payment: 'Pending ...', customer: inv.SellStatus?.customer, date: new Date().toISOString(), reservations: [], by: { name: this.usd.acc.name, id: this.usd.userId }
    }
    if(cus && (cus?.name && cus !== '')) {
      inv.SellStatus.customer = [cus];
    } else {
      // console.log(inv.SellStatus.customer?.length);
    }
    this.updateInventory(inv)
  }

  updateInventory(inv) {
    // console.log('inventory', inv);
    inv.name = inv.type.value + ' ' + String(inv.productNo.name) + ' of ' + inv.parent;
    this.usd.afs.doc(`Projects/${inv.project.id}/inventory/${inv.id}`).update(inv).then(() => {
      this.usd.afs.doc(`inventory/${inv.id}`).update(inv).then(() => console.log('Inv updated')).catch(er => console.log('Inv updated failed', er));
    }).catch(er => console.log(er));
  }

  getSaleInv() {
    const data = this.data?.filter(inv => inv.initiated?.status === true && inv.checked?.status === true && inv.approved?.status === true);
    const finalData = data?.filter(inv => !inv?.parties || inv?.parties?.length === 0);
    const finalData2 = data?.filter(inv => !inv?.SellStatus.customer || inv?.SellStatus?.customer?.length === 0);
    this.prodsAvail = finalData || finalData2;
  }


  getSold() {
    const data = this.data.filter(inv => inv.SellStatus.status === 'Sold');
    data.forEach(i => {
      const trnFnd = this.salesTrans?.find(x => {
        if (i?.productNo?.name === x.product?.productNo?.name || i?.productNo?.name === x.product?.productNo?.name) {
          return x;
        } else if(x.id === i.tid) {
          return x;
        } else {

        }
      });
      if (trnFnd) {
        i.amount = trnFnd.amount;
      } else {
        this.createSaleTrans(i);
      }
    });
    // const inv = trans.product;
    // inv.SellStatus = {
    //   status: 'Sold',  payment: 'Pending ...',  customer: trans.parties,  date: trans.date
    // }
    // this.updateInventory(inv);
    let finalData = data.filter(inv => inv?.parties && inv?.parties?.length > 0);
    const dataG = this.realArr;
    const prodsAvail = [];
    if (dataG) {
      finalData.forEach(x => {
        const dataFnd = dataG.find(item => item?.product.productNo.name === x.productNo.name);
        if (dataFnd) {
          x.parties = dataFnd?.parties || [];
          prodsAvail.push(x);
          finalData = prodsAvail;
        }
        // console.log(prodsAvail?.length)
      })
    }
    return finalData;
  }

  createSaleTrans(x) {
    const prod: any = x;
    // prod.product.rate = '';
    const data: compTans = {
      read: false, noti: 'inventryNotice',
      name: prod?.type?.value || '' + prod?.productNo?.name || '' + ' of ' + prod?.parent || '', id: '',
      project: prod?.project, company: prod?.company, by: { name: '', id: '' },
      saleType: prod?.saleType || '', product: prod || '', parties: prod?.parties || prod?.SellStatus?.customer || [],
      rate: { name: prod?.rate?.name || '', verified: false, approved: false },
      amount: { cost: prod?.amount?.cost || '', paid: prod?.amount?.paid  || '', balance: prod?.amount?.balance  || '', currency: prod?.amount?.currency },
      transType: prod?.transType, transClass: prod?.transClass || '', extrainfo: prod.extrainfo || '',
      date: prod.date || '', component: 'Sale', paymentMethod: '', products: [prod],
      amountInLieu: prod?.amountInLieu || 0, quatityInLieu: prod?.quatityInLieu || 0,
      Initiated: { by: { name: this.usd.acc.name, id: this.usd.userId }, status: true, date: new Date().toISOString() },
      Checked: { by: { name: '', id: '' }, status: false, date: '' }, Approved: { by: { name: '', id: '' }, status: false, date: '' },
      createdOn: new Date().toISOString()
    };
    this.salesTrans.push(data)
    // console.log(data);
  }

  reviseEntQuantity() {
    // this.entInvent.entQuantity = this.setParty?.remQuantity || this.setParty?.invQuantity; console.log('entQuantity', this.entInvent.entQuantity, this.setParty?.remQuantity);
    const fa = this.setParty?.remQuantity;
    this.entInvent.entQuantity = fa; // console.log('entQuantity', this.entInvent.entQuantity, this.setParty?.remQuantity);
    if (this.partyInv && this.partyInv.length === 0) {
      this.setParty.remQuantity = this.setParty?.invQuantity;
      this.setParty.remQuantity.name = String(this.setParty.remQuantity.value);
      this.entInvent.entQuantity = this.setParty?.remQuantity; // console.log('entQuantity', this.entInvent.entQuantity, this.setParty?.remQuantity);
    }
    this.calEntQuantity();
  }

  calEntQuantity() {
    // console.log('entQuantity', this.setParty.invQuantity, 'remQuantity', this.setParty.remQuantity.value);
    // console.log(this.entInvent.entQuantity);
    if (this.data && this.data.length > 0) {
      const fa = this.setParty?.invQuantity;
      this.entInvent.entQuantity = fa;

      // this.data.forEach(element => {
      //   this.revEntQuantity(element);
      // });
    } else {
      const remValue = this.setParty.remQuantity;
      this.entInvent.entQuantity.name = remValue.name;
    }
  }

  revEntQuantity(inventory) {
    if (inventory.size.name) {
      this.entInvent.entQuantity.name = String(Number(this.entInvent.entQuantity.name) - Number(inventory.size.name));
    }
  }


  invUpdate(inv) {
    if (inv?.id) {
      this.usd.afs.doc(`Projects/${inv.project.id}/inventory/${inv?.id}`).update({ 'description': inv.description, 'parent' : inv.parent , 'name' : inv.name }).then(() => {
        console.log(inv.description, 'update done')
        this.usd.afs.doc(`inventory/${inv.id}`).update({ 'description': inv.description, 'parent' : inv.parent , 'name' : inv.name }).then(() => console.log('Inv updated'))
        .catch(er => console.log('Inv updated failed', er));
      }).catch(er => console.log(er));
    } else {
      // console.log(inv.id, inv);
    }
  }

  newEntQuantity() {
    // this.checkPrData();
    // this.reviseEntQuantity();
    const fa = Number(this.setParty.remQuantity.name);
    if (this.entInvent?.size.name) {
      if (Number(this.entInvent.entQuantity.name) >= Number(this.entInvent.size.name)) {
        this.error = { er: false, msg: 'No Error' };
        this.entInvent.entQuantity.name = String(fa - this.entInvent.size.name);
      } else {
        this.error = { er: true, msg: 'You have exhausted Project Quantity' };
      }
    } else {
      this.entInvent.entQuantity.name = this.setParty.remQuantity.name;
    }
  }

  showPro(da) {
    if (da) {
      this.entInvent.id = da.id; this.entInvent.project = da.project; this.entInvent.parent = da.parent;
      this.entInvent.rate = { name: '', verified: false, approved: false };
      da.rate = this.entInvent.rate;
      this.entInvent.productNo = { name: da.productNo.name, verified: false, approved: false };
      da.productNo = this.entInvent.productNo;
      this.entInvent.size = { name: da.size.name, verified: false, approved: false };
      da.size = this.entInvent.size;
      this.entInvent.prjQuantity = { value: da.prjQuantity.value, name: da.prjQuantity.name, unit: da.unit };
      this.entInvent.Amount = da.Amount || 0; this.entInvent.SellStatus = {}; this.entInvent.type = da.type;
      return da;
      // this.newEntQuantity();
    } else {
      this.initEntInventory();
    }
    // console.log(da, this.setProduct, this.availableInv);
  };

  initShareStock(da) {
    this.entInvent = this.is.initEntInventory(); // console.log(this.entInvent); 
    // this.entInvent = da; // console.log(this.entInvent); 
    // console.log(da);
    this.entInvent = {
      project: da.project, company: da.company, footprint: da.footprint, parent: da.parent, contractSum: da.contractSum, rate: da.rate, productNo: da.productNo, size: da.size, prjQuantity: da.prjQuantity,
      unit: da.unit, parties: da.parties, party: da.party, Amount: da.Amount, SellStatus: da.SellStatus, type: da.type, entQuantity: da.entQuantity, id: da.id, participants: da.participants, transAction: da.transAction, by: da.by,
      initiated: da.initiated, checked: da.checked, approved: da.approved, createdOn: da.createdOn, update: da.update, name: da.name, description: da.description
    }
    // if (da) {
    // this.entInvent.id = da.id; this.entInvent.project = da.project; this.entInvent.parent = da.parent;
    // this.entInvent.rate = { name: '', verified: false, approved: false };
    // this.entInvent.productNo = { name: da.productNo.name, verified: false, approved: false };
    // this.entInvent.size = { name: da.size.name, verified: false, approved: false };
    // this.entInvent.prjQuantity = { value: da.prjQuantity.value, name: da.prjQuantity.name, unit: da.unit }; 
    // this.entInvent.Amount = da.Amount || 0; this.entInvent.SellStatus = {}; this.entInvent.type = da.type;
    // this.newEntQuantity();
    // } else {
    //   this.initEntInventory();
    // }
    // console.log(da, this.setProduct, this.availableInv);
  };


  initAgentStock(da) {
    // this.entInvent = this.is.initAgentInventory(); // console.log(this.entInvent); 
    // this.agentInvent = da; // console.log(this.entInvent); 
    // console.log(da);
    this.agentInvent = {
      agent: this.is.initAgentInventory().agent, project: da.project, company: da.company, footprint: da.footprint, parent: da.parent, contractSum: da.contractSum, rate: da.rate, productNo: da.productNo, size: da.size, prjQuantity: da.prjQuantity,
      unit: da.unit, parties: da.parties, party: da.party, Amount: da.Amount, SellStatus: da.SellStatus, type: da.type, entQuantity: da.entQuantity, id: da.id, participants: da.participants, transAction: da.transAction, by: da.by,
      initiated: da.initiated, checked: da.checked, approved: da.approved, createdOn: da.createdOn, update: da.update, name: da.name, description: da.description
    }; // console.log(this.entInvent); 
    this.agentInvent.agent = {
      id: '',
      name: '',
      allocationDate: '',
      reAllocationDate: ''
    };
    if((!this.agentInvent.footprint || this.agentInvent.footprint?.length === 0) && !da.agent) { 
      this.agentInvent.footprint = [];
    } else { 
      this.agentInvent.footprint.push(da.agent);
    }
  };

  initTrans() {
    this.getTransParties();
    this.transaction = this.is.initTrans();
    if (this.is?.toolSet === 'Project' || this.is?.toolSet === 'proj-team' || this.is?.toolSet === 'Project-Supplier') {
      this.transaction.company = {
        name: this.usd.setProject.companyName || '', id: this.usd.setProject.companyId || ''
      }
      this.transaction.project = {
        name: this.usd.setProject.name || '', id: this.usd.setProject.id || '',
      }
    }
    if (this.is?.toolSet === 'Enterprise') {
      this.transaction.company = {
        name: this.usd.setCompany.name, id: this.usd.setCompany.id
      }
      this.transaction.project = { name: '', id: '' }
    }
    if (this.is?.toolSet === 'Personal') {
      this.transaction.company = { name: '', id: '' }
      this.transaction.project = { name: '', id: '' }
    }
    this.uni = { name: '', id: '', unicode: '' };
  }

  initPayTrans() {
    this.getTransParties();
    this.transaction = this.is.initTransPay();
    this.payTransaction = this.is.initTransPay();
    this.transaction.product.name = '';
    this.transaction.project = {
      name: this.usd.setProject.name, id: this.usd.setProject.id,
    }
    this.payTransaction.project = {
      name: this.usd.setProject.name, id: this.usd.setProject.id,
    }
    this.transaction.company = {
      name: this.usd.setProject.companyName || '', id: this.usd.setProject.companyId || ''
    }
    this.payTransaction.project = {
      name: this.usd.setProject.name, id: this.usd.setProject.id,
    }
    this.uni = { name: '', id: '', unicode: '' };
  }

  calPayment(element, amount) {
    amount = this.currencyPipe.transform(amount, '$');
    element.target.value = amount;
    console.log(amount);
  }

  numbPayment(amount) {
    amount = this.currencyPipe.transform(amount, '$');
    console.log(amount);
  }


  moneyString(amount) {
    if ((typeof amount !== 'number') && amount !== null) {
      if (amount?.includes('$')) {
        const num = this.is.numberForm(amount);
        return this.currencyPipe?.transform(Number(num), '$');
      } else {
        return this.currencyPipe?.transform(Number(amount), '$');
      }
    } else {
      return this.currencyPipe?.transform(amount, '$');
    }
  }

  payCalculations() {
    this.payement.amountDue
  }

  transCalculations() {
    // console.log(this.transaction?.rate.name);
    (this.transaction?.product.size.name && this.transaction?.rate.name) ? this.transaction.amount.cost = this.moneyString(String(Number(this.transaction?.rate.name) * Number(this.transaction?.product.size.name))) : "0.00";
    // this.transaction.amount.cost = this.moneyString(this.transaction.amount.cost);
  }

  transRate() {
    // console.log(this.transaction?.rate.name);
    (this.transaction?.product?.size?.name && this.transaction?.amount?.cost) ? this.transaction.rate.name = String(Number(this.is.numberForm(this.transaction?.amount?.cost)) / Number(this.transaction?.product.size.name)) : "0.00";
  }

  saleCalculations() {
    console.log(this.saletrans?.rate.name);
    (this.saletrans?.product.size.name && this.saletrans?.rate.name) ? this.saletrans.amount.cost = this.moneyString(String(Number(this.saletrans?.rate.name) * Number(this.saletrans?.product.size.name))) : "0.00";
    // this.saletrans.amount.cost = this.moneyString(this.saletrans.amount.cost);
  }

  saleRate() {
    // console.log(this.saletrans?.rate.name);
    (this.saletrans?.product?.size?.name && this.saletrans?.amount?.cost) ? this.saletrans.rate.name = String(Number(this.is.numberForm(this.saletrans?.amount?.cost)) / Number(this.saletrans?.product.size.name)) : "0.00";
  }

  calAmountInLeu() {
    let paid = 0;
    for (let index = 0; index < this.payTransaction.products.length; index++) {
      const element = this.payTransaction.products[index];
      paid += Number(this.payTransaction?.rate.name) * Number(element.size.name);
      // console.log(paid, Number(this.payTransaction?.rate.name) * Number(element.size.name));
    }
    this.payTransaction.amount.paid = paid;
  }

  calQuantityInLeu() {
    this.payTransaction.quatityInLieu.value = ((Number(this.is.numberForm(this.payTransaction.amountInLieu)) / Number(this.payTransaction?.rate.name)).toFixed(2)).toString();
  }

  getImg(uid) {
    return this.labour?.find(u => u.id === uid)?.photoURL || '';
  }

  popResults(results, inv) {
    const fndInd = results.findIndex(x => x.id === inv.id);
    if (fndInd === -1) {
      results.push(inv);
      this.results = results;
    }
  }

  searchTrans(transactions, title) {
    if (title) {
      let word = title; const results = [];
      word = word.toLowerCase();
      if (word !== '' || ' ') {
        transactions.forEach(inv => {
          if (inv?.parties?.length > 0 || inv?.product?.parties?.length > 0) {
            if ((inv?.parties?.length > 0) /* && (n) */) { /* console.log(n); */
              inv?.parties?.forEach(element => {
                if (element?.name.toLowerCase()?.includes(word)) {
                  this.popResults(results, inv);
                }
              });
            } else if ((inv?.product?.parties?.length > 0)/*  && (m.length > 0) */) {
              inv?.product?.parties?.forEach(element => {
                if (element?.name?.toLowerCase()?.includes(word)) {
                  this.popResults(results, inv);
                }
              });
            }
          }
          if (inv?.name) {
            const invName = inv.name.toLowerCase();
            if ((invName).includes(word)) {
              this.popResults(results, inv);
            }
          } 
          if (inv?.description) {
            const invName = inv.description.toLowerCase();
            if ((invName).includes(word)) {
              this.popResults(results, inv);
            }
          }
          if (inv.component) {
            const prodNo = String(inv.component).toLowerCase();
            if ((prodNo).includes(word)) {
              this.popResults(results, inv);
            }
          }
          if (inv?.product?.productNo?.name) {
            const prodNo = String(inv.product.productNo.name).toLowerCase();
            if ((prodNo).includes(word)) {
              this.popResults(results, inv);
            }
          }
        });
      }
      return this.results;
    }
  }

  searchInventory(transactions, title) {
    if (title) {
      let word = title; const results = [];
      transactions.forEach(inv => {
        if (inv.productNo.name) {
          const prodNo = String(inv.productNo.name).toLowerCase()
          if (word !== '' || ' ') {
            word = word.toLowerCase();
            if ((prodNo).includes(word)) {
              // console.log(inv.description);
              results.push(inv);
              // console.log(results);
              this.results = results;
            }
          }
        } else if (inv.size.name) {
          const size = String(inv.size.name).toLowerCase()
          if (word !== '' || ' ') {
            word = word.toLowerCase();
            if ((size).includes(word)) {
              // console.log(inv.description);
              results.push(inv);
              // console.log(results);
              this.results = results;
            }
          }
        } else if (inv.name) {
          inv.name = inv.name.toLowerCase();
          if (word !== '' || ' ') {
            word = word.toLowerCase();
            if ((inv.name).includes(word)) {
              inv.name = inv.name.charAt(0).toUpperCase() + inv.name.slice(1);
              // console.log(inv.name);
              results.push(inv);
              // console.log(results);
              this.results = results;
            }
          }
        } else if (inv.description) {
          inv.description = inv.description.toLowerCase();
          if (word !== '' || ' ') {
            word = word.toLowerCase();
            if ((inv.description).includes(word)) {
              inv.description = inv.description.charAt(0).toUpperCase() + inv.description.slice(1);
              // console.log(inv.description);
              results.push(inv);
              // console.log(results);
              this.results = results;
            }
          }
        } else if (inv?.parties?.length > 0 && (inv?.parties?.filter(i => i?.name?.includes(word)))) {
          results.push(inv); this.results = results;
        } else if (inv?.agent && (inv?.agent?.name?.includes(word))) {
          results.push(inv); this.results = results;
        }
        return this.results;
      });
      return this.results;
    }
  }

  comment(trans) {
    this.newComment = !this.newComment;
    // console.log(this.Comment);
    if (this.Comment.name !== '') {
      this.Comment.createdOn = new Date().toISOString();
      this.Comment.by = { name: this.usd.acc.name, id: this.usd.acc?.id };
      // console.log(this.Comment);
      const docRef = this.usd.afs.collection(`Transactions/${trans.id}/comments`);
      docRef.add(this.Comment).then(doc => {
        // this.transCount(trans);
        docRef.doc(doc.id).update({ 'id': doc.id }).catch(error => {
          // console.log('Error capture', error)
        });
        // console.log('comment submitted');
        this.Comment = { name: '', id: '', by: { name: '', id: '' }, createdOn: '', photoUrl: '' };
      })
      if (this.Comment.by.id !== trans.by?.id) {
        const part = { name: trans.champion?.name, id: trans.champion?.id }
        // console.log(part?.name, 'comment notified');
        this.notifyPartsTaskComment(part, trans, this.Comment);
      }
      // if (task.participants?.length !== 0) {
      if (trans.parties && trans.parties.length > 1) {
        // console.log(trans.participants);
        trans.participants.forEach(element => {
          if (element.id !== this.Comment.by.id && element.id !== trans.by.id) {
            this.notifyPartsTaskComment(element, trans, this.Comment);
            // console.log(element?.name, 'Participants comment notified');
          }
        });
      }
    } else {
      // console.log('No comments found');
    }
  }

  ackTransComment(trans) {
    trans.read = true;
    this.usd.afs.doc(`Users/${this.usd.acc.id}/TransNotice/${trans.id}`).update({ 'read': true }).then(() => {
      // this.rs.viewTask(task);
      this.usd.tasksComs(trans);
      // this.tsk.task = task;
      // this.rs.commentTask(task);
    });
  }

  notifyPartsTaskComment(part, task, comment) {
    task.read = false;
    task.notRing = false;
    task.noticeDate = moment().toISOString();
    task.comment = comment;
    this.usd.afs.doc(`Users/${part?.id}/TransNotice/${task.id}`).set(task).then(() => { }).catch(er => {
      // console.log(part.name, 'cooms updated')
    });
  }

  searchProperty(value) {
    let word = value;
    const words = this?.data.filter(wor => ((wor.name.toLowerCase()).includes(word.toLowerCase())));
    // console.log(words);
    word = word.toLowerCase();
    if (word && value.length > 0) {
      // words.forEach(element => {
      //   if(element.parties && element.parties.length > 0) {
      //     element.partiesInitials = [];
      //     for (let index = 0; index < element.parties.length; index++) {
      //       element.partiesInitials.push(this.getInitials(element.parties[index].name));
      //     }
      //   }
      // });
      this.results = words;
      this.callphased();
    } else {
      this.results = [];
    }
  }

  callphased() {
    const phasedDescrpt = this.results.filter(x => (x?.name.includes('Phase 2') || x?.product?.name?.includes('Phase 2') || x?.product?.product?.name?.includes('Phase 2')));
    phasedDescrpt.forEach(element => {
      element.parent = this.renamePhases(element.parent);
      element.description = element.type.value + ' ' + String(element.productNo.name) + ' of ' + element?.parent;
      element.name = element.description;
    });
  }

  checkphased(results) {
      for (let x = 0; x < results.length; x++) {
        const parent = this.renamePhases(results[x].parent || results[x]?.product?.parent);
        if(((results[x]?.parent !== undefined) || (results[x]?.product?.parent !== undefined)) && (results[x]?.name.includes('Phase 2') || results[x]?.product?.name?.includes('Phase 2') || results[x]?.product?.product?.name?.includes('Phase 2'))) {
          if (results[x]?.product?.type?.value && results[x]?.product?.productNo?.name && parent) {          
            results[x].description = results[x]?.product?.type.value + ' ' + String(results[x].product.productNo.name) + ' of ' + parent;
            results[x].product.name = results[x].description;
            results[x].product.description = results[x].description;
          }
        }
      }
    // })
    return results;
  }

  getInitials(name) {
    const fullName = name.split(' ');
    const initials = fullName.shift()?.charAt(0) + fullName.pop()?.charAt(0);
    return initials.toUpperCase();
  }

  
  otherTrans(id) {
    const data = this.usd.afs.collection<any>(`Transactions`, ref => ref.where('project.id', '==', id)).snapshotChanges().pipe(
      map(b => b.map(a => {
        const data = a.payload.doc.data() as any;
        if (data.id === '') {
          data.id = a.payload.doc.id;
        }
        return data;
      })));
    // .valueChanges();
    // const data = this.usd.afs.collection<any>(`Projects/${id || this.usd.setProject?.id}/transactions`).valueChanges();
    return data;
    // return this.yourFunction(id);
  }

  // async yourFunction(id){
  //   const Ref0 = this.usd.afs.collection<any>(`Projects/${id || this.usd.setProject?.id}/transactions`);
  //   const students = ['component', , ...];
  //   const data = this.usd.afs.collection<any>(`Projects/${id || this.usd.setProject?.id}/transactions`, (ref: any) => students.reduce(
  //       (r: any, student: any) => r.where(`students.${student}`, '!=', 'Sale')
  //       , ref)
  //   // ).valueChanges({ idField: 'id' });
  //   ).valueChanges();
  // }


  sales(id) {
    // this.salesData = this.usd.afs.collection<any>(`Projects/${id || this.usd.setProject?.id}/transactions`, ref => ref?.where('component', '==', 'Sale').orderBy('createdOn', 'desc')).valueChanges();
    this.salesData = this.usd.afs.collection<any>(`Transactions`, ref => ref.where('project.id', '==', id).where('component', '==', 'Sale').orderBy('date', 'desc')).snapshotChanges().pipe(
      map(b => b.map(a => {
        const data = a.payload.doc.data() as any;
        data.id = a.payload.doc.id;
        return data;
      })));
    // .valueChanges();
    return this.salesData;
  }

  saleSet(inv) {
    // this.saleResults.push(trns);
      const fndInd = this.saleResults.findIndex(x => x.id === inv.id);
      if (fndInd === -1) {
        this.saleResults.push(inv);
        // this.saleResults = results;
      } else {
        // console.log(this.saleResults[fndInd].name);
      }
  }

  searchSales(arr) {
    let word = this.searchValue;
    word = word.toLowerCase();
    this.saleResults = [];
    if (word !== '' || ' ') {
      arr.forEach(trns => {
        const part = trns?.parties?.find(x => x?.name.toLowerCase().includes(word));
        if (part) {
          this.saleSet(trns);
        }
        if (trns?.name) {
          const name = trns?.name.toLowerCase();
          if (name.includes(word)) {
            this.saleSet(trns);
          }
        }
        if (trns.product?.productNo?.name) {
          const prodNo = String(trns.product.productNo.name).toLowerCase();
          if ((prodNo).includes(word)) {
            this.saleSet(trns);
          }
        }
        if (trns?.product?.size?.name) {
          const size = String(trns?.product?.size?.name).toLowerCase();
          if ((size).includes(word)) {
            this.saleSet(trns);
          }
        }
        if (trns?.description) {
          const name = trns.description.toLowerCase();
          if (name.includes(word)) {
            this.saleSet(trns);
          }
        }
      })
    }
  }

  searchSalesArch(arr) {
    let word = this.searchValue;
    let results = [];
    if (word) {
      // const results = [];
      for (let i = 0; i < arr.length; i++) {
        const trns = arr[i];
        if (trns.name || trns.product.productNo.name) {
          const prodNo = String(trns.name || trns.product.productNo.name).toLowerCase()
          if (word !== '' || ' ') {
            word = word.toLowerCase();
            if ((prodNo).includes(word)) {
              results.push(trns);
              this.saleResults = results;
            }
          }
        } else if (trns.size.name) {
          const size = String(trns.size.name).toLowerCase()
          if (word !== '' || ' ') {
            word = word.toLowerCase();
            if ((size).includes(word)) {
              results.push(trns);
              this.saleResults = results;
            }
          }
        } else if (trns.name) {
          trns.name = trns.name.toLowerCase();
          if (word !== '' || ' ') {
            word = word.toLowerCase();
            if ((trns.name).includes(word)) {
              trns.name = trns.name.charAt(0).toUpperCase() + trns.name.slice(1);
              results.push(trns);
              this.saleResults = results;
            }
          }
        } else if (trns.description) {
          trns.description = trns.description.toLowerCase();
          if (word !== '' || ' ') {
            word = word.toLowerCase();
            if ((trns.description).includes(word)) {
              trns.description = trns.description.charAt(0).toUpperCase() + trns.description.slice(1);
              // console.log(trns.description);
              results.push(trns);
              // console.log(results);
              this.saleResults = results;
            }
          }
        }
        return this.saleResults;
        // });
      }
      return this.saleResults;
    }
    // arr.forEach(trns => {
    // if (trns?.parties?.length > 0) {
    // trns?.parties?.forEach(element => {
    //   if (element.name) {
    //       trns.name = trns.name.toLowerCase();
    //       if (word !== '' || ' ') {
    //         word = word.toLowerCase();
    //         if ((trns.name).includes(word)) {
    //           trns.name = trns.name.charAt(0).toUpperCase() + trns.name.slice(1);
    //           // console.log(trns.name);
    //           results.push(trns);
    //           // console.log(results);
    //           this.saleResults = results;
    //         }
    //       }
    //     }
    // });
    // const hn = trns?.parties?.find(i => i?.name?.toLowerCase()?.includes(word));
    // if(hn?.length > 0) {
    //   console.log(trns.name);
    //   results.push(trns);
    //   this.saleResults = results;
    // }
    // } 
    // arr?.forEach(element => {
    //   if (element?.name.toLowerCase()?.includes(word?.toLowerCase())) {
    //     results.push(element);
    //     this.saleResults = results;
    //   }
    // });

    // const resultsfiltered = arr.filter(x => x?.name.toLowerCase()?.includes(word?.toLowerCase()));

  }
  uploadImages(event, action) {
    // const project = this.usd.setProject;
    const trns: any = this.transCancel;
    if (event === undefined) { } else {
      const file = event.target.files[0];
      console.log(file);
      const filePath = `Transactions/${trns?.id}/documnts/${file.name}`;
      const afsPath = `Transactions/${trns?.id}`;
      // console.log(filePath);
      const transStore = this.usd.storage.upload(filePath, file);
      // observe percentage changes
      // console.log('get %');
      transStore.percentageChanges().subscribe((num: number) => {
        this.uploadPercent = Math.round(num);
        // console.log(this.uploadPercent);
      });
      // get notified when the download URL is available
      transStore.then(() => {
        // console.log('get url');
        const fileRef = this.usd.storage.ref(filePath);
        transStore.snapshotChanges().pipe(
          finalize(() => {
            // console.log('Done');
            this.downloadURL = fileRef.getDownloadURL();
            this.downloadURL.subscribe(uj => {
              const str = uj
              // console.log(str);
              const dsFile: doc = { name: file.name, date: new Date().toString(), src: str, id: '', action: action, by: { id: this.usd.userId, name: this.usd.acc.name } };
              if (trns?.docs && trns?.docs?.length > 0) {
                this.usd.afs.doc(afsPath).update({
                  'docs': this.usd.firebase.firestore.FieldValue.arrayUnion(dsFile)
                }).then(() => {
                  this.transCancel?.docs.push(dsFile);
                  this.updatePsntrans(this.transCancel);
                  if (this.uploadPercent === 100) {
                    setTimeout(() => {
                      this.uploadPercent = null;
                      // this.rs.routeRpt(n);
                    }, 15)
                  }
                })
              } else {
                const arrDocs = [dsFile];
                this.usd.afs.doc(afsPath).update({ 'docs': arrDocs }).then(() => {
                  this.transCancel.docs = [dsFile];
                  this.updatePsntrans(this.transCancel);
                  if (this.uploadPercent === 100) {
                    setTimeout(() => {
                      this.uploadPercent = null;
                      // this.rs.routeRpt(n);
                    }, 15)
                  }
                })
              }
            })
            // console.log(this.downloadURL);
          })
        ).subscribe()
      })
    }
  }

  deleteFile(t, doc) {
    const afsPath = `Transactions/${t?.id}`;
    // const transStore = this.usd.storage.ref(filePath).delete();
    if (t?.docs && t?.docs?.length > 0) {
      this.usd.afs.doc(afsPath).update({
        'docs': this.usd.firebase.firestore.FieldValue.arrayRemove(doc)
      }).then(() => {
        this.transCancel?.docs.splice(this.transCancel?.docs.findIndex(v => v.name === doc.name), 1);
        this.usd.storage.storage.refFromURL(t.src).delete();
        this.updatePsntrans(this.transCancel);
      })
    }
    // Firebase.storage().ref().child(fileLocation).delete();
  }

  updatePsntrans(data) {
    /* console.log(act); */
    const tranRef = this.usd.afs.doc(`Users/${data.by.id}/transactions/${data.id}`);
    tranRef.update(data).then(() => {
      this.usd.afs.doc(`Transactions/${data.id}`).update(data).then(() => {/* console.log('rootTrans set successfully') */ }).catch(er => console.log('failed to set rootTrans'))
      if (data?.company?.id) {
        this.updateentTransactions(data);
      }
      if (data?.project?.id) {
        this.updateprjtransactions(data);
      }
      if (data?.parties?.length > 0) {
        this.updateParties(data);
      }
      this.usd.afs.doc(`Users/${this.usd.userId}/transactions/${data.id}`).update(data).then(() => { }).catch(er => console.log(er));
      if (data.Checked?.by?.id) {
        this.usd.afs.doc(`Users/${data.Checked?.by?.id}/transactions/${data.id}`).update(data).then(() => { }).catch(er => console.log(er));
      }
      if (data.Approved?.by?.id) {
        this.usd.afs.doc(`Users/${data.Approved?.by?.id}/transactions/${data.id}`).update(data).then(() => { }).catch(er => console.log(er));
      }
    }).catch(er => console.log(er));
  }

  updateParties(data) {
    if (data?.parties && data?.parties?.length > 0) {
      data?.oldParties?.forEach(element => {
        // const maillist = [];
        if (element.srTag === 'ind') {
          this.cancelManagersTrans(element, data);
          // maillist.push(element.bus_email || element.email);
        }
        if (element.srTag === 'ent') {
          if (element?.contactPerson?.name !== '' && element?.contactPerson?.id && element?.contactPerson?.id !== '' && (element?.contactPerson?.email !== '' || element?.contactPerson?.bus_email !== '')) {
            this.cancelManagersTrans(element?.contactPerson, data);
            // maillist.push(element.bus_email || element.email);
          }
          if (element?.champion?.name !== '' && element?.champion?.id && element?.champion?.id !== '' && (element?.champion?.email !== '' || element?.champion?.bus_email !== '')) {
            this.cancelManagersTrans(element?.champion, data);
            // maillist.push(element.bus_email || element.email);
          }
        }
        // Promise.all(maillist).then(() => {
        //   const dataMail: any = data;
        //   dataMail.maillist = maillist;
        //   this.transmailGroup(data);
        // })
      })
    }
  }

  cancelManagersTrans(element, data) {
    if (this.usd.userId !== element?.id && element?.id) {
      data.read === false;
      this.usd.afs.doc(`Users/${element?.id}/transactions/${data.id}`).update(data).then(() => {
      }).catch(er => { });
    }
  }
  /* Project */

  updateprjtransactions(data) {
    // console.log(data);
    if (data.project && data.project?.id !== '') {
      this.usd.afs.doc(`Projects/${data.project?.id}/transactions/${data?.id}`).update(data).then(() => {
        /* console.log('Projects transactions set'); */
      }).catch(er => console.log(er));
    }
  }

  /* Enterprise */

  updateentTransactions(data) {
    // console.log(data);
    this.usd.afs.doc(`Enterprises/${data.company.id}/transactions/${data?.id}`).update(data).then(() => {
      /* console.log('Enterprise transactions set'); */
    }).catch(er => console.log(er));
  }

  realocate(inv, outParty) {
    this.oldParty = outParty;
    this.entInvent = inv;
    this.entInvent.party = {};
    outParty.reAllocationDate = new Date().toISOString();
    if (!this.entInvent.oldPartyList) {
      this.entInvent.oldPartyList = [outParty];
    } else {
      this.entInvent.oldPartyList.push(outParty);
    }
  }

  setInvParty() {
    this.entInvent.party = this.setParty;
  }

  genInvoice() {
    const inv: Invoice = {
      name: '', createdOn: '',
      id: '', date: '', invNo: '',
      by: { name: '', id: '' },
      customer: [{ address: '', email: '', bus_email: '', name: '', phoneNumber: '', photoUrl: '' }],
      company: {
        address: '', name: '', telephone: '', contact: {}, logoURL: '', id: '',
        bank: { accountNo: '', branch: { name: '', code: '' }, address: '', swiftCode: '', currency: '' }
      },
      products: [],
      project: { name: '', id: '' },
      prevBalance: { value: 0, name: 'Previous Balance' }, totalBalanceDue: { value: 0, name: 'Total Balance Due' },
      payDueDate: { value: '', name: 'Payment Due Date' },
      newCharges: { date: '', value: 0, name: 'New Charges' },
      instalment: { date: '', value: 0, name: 'Monthly Instalment' },
      interest: [],
      // interest: { date: '', value:  0, name: '', description: ''},
      totalAmount: { value: 0, name: 'Total Amount' },
      subTotal: { value: 0, name: 'Subtotal' },
      discount: { value: 0, name: 'Discount' },
      taxRate: { value: 0, name: 'Tax Rate', rate: 0 },
      total: { value: 0, name: 'Total' }, notes: ''
    }
    // console.log(inv);
  }

  
/* sql functions */
retrieve() {
  this.setCus = { name: '', inventory: [], transData: [] };
    if (this.allSqlData && this.allSqlData.length > 0) {
      console.log('Data already retrieved');
    } else {
      var org_codes = ['SR', 'SOUTHLR'];
      console.log('Requesting Sql');
      this.mysqlService.getAllTransactions({ proj_code: 'NYR', org_code: org_codes }).subscribe(
        (data) => {
          if (data && data.length > 0) {
            this.allSqlData = data;
            console.log('Sql doc count:', data.length);
            // this.getCustomerNames(data);
          } else {
            console.log('No data retrieved');
          }
        },
        (error) => {
          console.error('An error occurred:', error);
        }
      );
    }
}

customertrans(stands) {
    this.setCus = { name: '', inventory: [], transData: [] };
    if (this.sqlData && this.sqlData?.length > 0) {
      const hh = (this.sqlData[0].transData.filter((o) => stands.some((x) => o.name?.includes(String(x)))));
      if (hh.length > 0) {
        console.log('Data already retrieved', hh);
      } else {
        console.log('Requesting Sql 1');
        this.requstTrans(stands);
      }
    } else {
      console.log('Requesting Sql 2');
      this.requstTrans(stands);
    }
}

requstTrans(stands) {
  var southland_code = ['SR', 'SOUTHLR'];
  this.customerData = {};
  this.cusList = [];
  this.cusProducts = [];
  this.sqlData = [];
  // this.mysqlService.gettrans({ proj_code: 'NYR', org_code: southland_code, stands: stands }).subscribe(
  this.mysqlService.gettrans({ proj_code: 'NYR', stands: stands }).subscribe(
    (data) => {
      if (data && data.length > 0) {
        this.allSqlData = data;
        console.log('Sql doc count:', data.length);
        this.getCustomerNames(data);
      } else {
        console.log('No data retrieved');
        this.customerData = {};
        this.cusList = [];
        this.cusProducts = [];
      }
    },
    (error) => {
      console.error('An error occurred:', error);
    }
  );
}

getCustomerNames(newData: any) {
  console.log("TRans length:", newData.length);
  for (let i = 0; i < newData.length; i++) {
    const el = newData[i];
    newData[i].date = el.Date;
    newData[i].ddate = moment(el.Date).format('ll') ;
    newData[i].amount = { cost: (el.Document_Type === 'CUSTOMER') ? Number(el.Amount): ((el.Document_Type === 'INTEREST RECEIVABLE') || el.Description?.toLowerCase().includes('interest')) ? Number(el.Amount): 0.00, paid: (el.Document_Type === 'FUNDS RECEIVED') ? Number(el.Amount) : 0.00, balance: 0.00, currency: el.Currency };
    this.getCustomer(el, i, newData);
  }
}

getCustomer(element, i, dat) {
  // console.log(element.date , element);
  const cus: any = {
    name: element.Transaction_Account_Name,
    code: element.Transaction_Account_Code,
    inventory: [],
    transData: []
  };

  const fnd = this.cusList.find((x) => x.code === element.Transaction_Account_Code);
  if (fnd) {
    // console.log(this.cusList);
  } else {
    this.cusList.push(cus);
  }
  if(i === dat?.length-1) {
    this.sqlData = this.getCustomerDetails(this.cusList, dat);
  }
}

refreshList() {
  // this.retrieve();
  this.setCus =  { name: '', inventory: [], transData: [] };
  this.currentIndex = -1;
}

setTransActive(transaction, index): void {
  this.setCus = transaction;
  this.currentIndex = index;
  this.getStatement(transaction.name)
  // console.log(this.setCus,this.currentIndex);
}

getCustrans() {}


async getSqlClient() {
  const fndClient = await this.sqlData.find(client => {
    const intsect = this.intersect(client.inventory, this.doc.products);
    if(intsect && intsect?.length > 0) {
      return client;
    }
  });
  return fndClient;
}

intersect(a, b) {
  return a.filter(Set.prototype.has, new Set(b));
}

// Example:
// console.log(intersect([1,2,3], [2,3,4,5]));
getStatement(name): void {
  this.mysqlService.get(name)
    .subscribe(
      data => {
        this.setCus = data;
        // console.log("Receiving data from SQL: " + data );
      },
      error => {
        console.log(error);
      });
}

filterTransactions(dat: any) {
  this.customerData = {};
  this.cusList = [];
  this.cusProducts = [];
  const newData = dat.filter(u => u?.Transaction_Class && u?.Transaction_Class === "Accounts Receivable");
  // console.log("Total Customer Transactions: ", newData);
  for (let i = 0; i < newData.length; i++) {
    const el = newData[i];
    newData[i].date = el.Date;
    newData[i].amount = { cost: (el.Document_Type === 'CUSTOMER') ? Number(el.Amount): ((el.Document_Type === 'INTEREST RECEIVABLE') || el.Description?.toLowerCase().includes('interest')) ? Number(el.Amount): 0.00, paid: (el.Document_Type === 'FUNDS RECEIVED') ? Number(el.Amount) : 0.00, balance: 0.00, currency: el.Currency };
    // if (el.Document_Type === 'CUSTOMER') {
    //   newData[i].description = Number(el.Amount);
    // }
    // if (el.Document_Type === 'INTEREST RECEIVABLE') {
    //   newData[i].description = Number(el.Amount);
    // }
    // if (el.Document_Type === 'FUNDS RECEIVED') {
    //   newData[i].description = Number(el.Amount);
    // }
    // console.log(newData[i]);
    if (el?.Transaction_Class && el?.Transaction_Class === "Accounts Receivable") {
      this.getCustomer(el, i, newData);
    }
  }
}


getInventryData(element) {
  let prodNumber = element.Transaction_Account_Code.split('/', 3);
  if (element.Transaction_Account_Code) {
    const fndNumber = this.cusProducts.find((x) => x.number === prodNumber[2]);
    if (fndNumber) {
      return this.cusProducts;
    } else if (!fndNumber) {
      const inventoryElem = {
        number: prodNumber[2],
        customer: element.Transaction_Account_Name,
        acc_code: element.Transaction_Account_Code,
      };
      this.cusProducts.push(inventoryElem);
      return this.cusProducts;
    }

  }
}

getProductDetails(customers: any[], trans: any[]) {
  customers.forEach((elem) => {
    elem.inventory = [];
    trans.forEach((item) => {
      if (item.Transaction_Account_Code && (item.Transaction_Account_Code === elem.code)) {
        elem.inventory = this.getInventryData(item).filter((ele) => ele.acc_code === elem.acc_code)
      }
    });
  });
  return customers;
}

checkSign(num, n) {
  const sign = Math.sign(num);
  // console.log(sign);
  if (sign === -1) {
    this.appendSign(n, sign);
    return num * sign;
  } else {
    this.appendSign(n, sign);
    return num
  }
}

appendSign(n, sign) {
  switch (n) {
    case 'totals':
        // console.log("totals");
        this.totalssign = sign;
        break;
    case 'totaB':
        // console.log("totaB");
        this.totaBsign = sign;
        break;
    case 'paymentDue':
        // console.log("paymentDue");
        this.sign = sign;
        break;
    default:
        // console.log("The number is Zero");
  }
}

getCustomerDetails(x: any[], trans: any[]) {
  this.getProductDetails(x, trans);
  for (let i = 0; i < x.length; i++) {
    const elem = x[i];
    if (i === 0){
      this.setCus = elem;
      this.currentIndex = i;
    }
    elem.transData = [];
    trans.forEach((item) => {
      // console.log(item);
      let prodNumber = item?.Transaction_Account_Code?.split('/', 3);
      if (item.Transaction_Account_Code === elem.code) {
        const name = 'Interest' + ' for ' + moment(item.date).format('MMMM YYYY');
        const desc = (item.Document_Type === 'CUSTOMER') ? `Invoice for ${prodNumber[2]} ${item.Description}`: ((item.Document_Type === 'INTEREST RECEIVABLE') || 
        item.Description?.toLowerCase().includes('interest')) ? name : (item.Document_Type === 'FUNDS RECEIVED') ? `Payment for ${prodNumber[2]} ${item.Description}`: item.Description;
        let transElem: any = {
          name: desc, amountArch: item.Amount, date: item.Date, month: item.Month, description: desc, document_Type:  item.Document_Type, transaction_Code: item.Transaction_Account_Code,
          entryId: item.Entry_ID, project: { name: this.usd.setProject.name, id: this.usd.setProject.id }, amount: item.amount, paymentMethod: 'SQL sync', products: [prodNumber[2]], totals: { paid: 0, cost: 0, bal: 0 }, 
        }
        if(item.Document_Type === 'FUNDS RECEIVED'){
          transElem.Received_from = item.Received_from; transElem.Received_by = item.Received_By
          transElem.receiptNo = item.Receipt_Number; 
          transElem.productNo = prodNumber[2];
        }
        if(item.Document_Type === 'CUSTOMER'){
          transElem.invNo = item?.Document_No; transElem.period = item?.Months_to_Pay; transElem.tax = item?.VAT_Percentage;
          transElem.payTodate = item?.Paid_to_Date; transElem.invNo = item?.Document_No; transElem.prodSize = item?.Stand_Size;
          transElem.productNo = item?.Stand_Number; transElem.Quantity = item?.Quantity; transElem.Unit = item?.Unit; 
          transElem.Unit_Cost = item?.Unit_Cost; transElem.Unit_Amount = item?.Unit_Amount; transElem.Instalment = item?.Instalment;
          transElem.Purchase_Price = item?.Purchase_Price;
        }
        elem.transData.push(transElem);
      }
    });
  }
  return x;
}
/* End of sql functions */
}
