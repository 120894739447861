import { Component, OnInit, Renderer2, ViewChild, ElementRef, Directive, AfterViewInit, HostListener, TemplateRef, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { ROUTES } from '../.././sidebar/sidebar.component';
import { Router, ActivatedRoute, NavigationEnd, NavigationStart } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { Subscription } from 'rxjs/Subscription';
import { UserDataService } from 'app/services/user-data.service';
import { Observable } from 'rxjs/Rx';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
// import { AngularFireAuth } from '@angular/ fire/auth';
import { Applicant, coloursUser } from 'app/models/user-model';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Enterprise, ParticipantData, projectRole } from 'app/models/enterprise-model';
import { InitialiseService } from 'app/services/initialise.service';
import { Task } from 'app/models/task-model';
import { map } from 'rxjs/operators';
import { Project } from 'app/models/project-model';
import { AngularFireAuth } from '@angular/fire/auth';
import { NotificationService } from 'app/services/notification.service';
import { WriteService } from 'app/services/writes.service';
import { EntServiceService } from 'app/services/ent-service.service';
import { IconsService } from 'app/services/icons.service';
import { RouteService } from 'app/services/route.service';
import { IntrojsService } from 'app/services/introjs.service';
import { TaskService } from 'app/services/task.service';
// import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap'; /* NgbActiveModal , */
// import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
// import { faCoffee } from '/@fortawesome/free-solid-svg-icons';

declare var $: any;

const misc: any = {
    navbar_menu_visible: 0,
    active_collapse: true,
    disabled_collapse_init: 0,
}

@Component({
    moduleId: module.id,
    // tslint:disable-next-line: component-selector
    styleUrls: ['./navbar.component.css'],
    selector: 'app-navbar',
    templateUrl: 'navbar.component.html'
})

export class NavbarComponent implements OnInit {
    private listTitles: any[];
    location: Location;
    private nativeElement: Node;
    private toggleButton;
    private sidebarVisible: boolean;
    private _router: Subscription;
    public open = false;
    public ird = false;

    @ViewChild('tempTutorial') tempTutorial: TemplateRef<any>;
    @ViewChild('app-navbar', { static: false }) button;
    setContact = true;
    counter$: any;
    subscription: any;
    timedstamp: number;
    message: any;
    userId: string; user: any;
    myDocument: AngularFirestoreDocument<coloursUser>;
    acc: ParticipantData;
    taskNotices: Observable<Task[]>;
    compRequests: Observable<Applicant[]>;
    projInvitations: Observable<Applicant[]>;
    projRequests: Observable<Applicant[]>;
    notificationNo: boolean;
    taskSent: any;
    applicant: any;
    reqName: string;
    coloursReq: any;
    connectingProject: Project;
    connectingCompany: any;
    enterpriseInvited: Applicant;
    requestingProject: Project;
    requestingCompany: projectRole;
    company: Enterprise;
    selectedProject: Observable<Project>;
    roles = [];
    page2 = false;
    page1 = true;
    applie: any;
    appNoty: any;
    appLabNoty: any;
    teamAs: any;
    cus_applie: any;
    newStaff: any;
    exitComp: any;
    exitApp: any;
    setDeclinee: any;
    teamAccept: any;
    appAprv: any;
    exitNotice: any;
    prjDelt: any;
    exitAccept: any;
    nonStaff: any;
    exitStaff: any;
    delinedApp: any;
    taskInfo: any;
    nudg: any;
    // totalNotices;
    public side = false
    randomColor: string;
    teamChamp: any;
    managerReq: any;
    // public viewNots = 'false';
    tutMod: any;
    installAppNots: number;
    msgColor: string;
    exitCpmNot: any;
    removeNot: any;
    leftStaff: any;
    cmpExit: any;
    domain = {
        dataText: '',
        type: ''
    };
    bulb: string;
    pathCount: string;
    titlee: string;
    checked: boolean;
    state_with_icons = true;
    animate = true;
    pastPages: any[] = [];

    constructor(location: Location, private element: ElementRef, private afs: AngularFirestore, public ico: IconsService,
        private router: Router, public usd: UserDataService, public afAuth: AngularFireAuth, public ns: NotificationService
        , public is: InitialiseService, public ws: WriteService, public es: EntServiceService, public rs: RouteService,
        private changeDetectorRef: ChangeDetectorRef, public modalService: NgbModal, public tut: IntrojsService, public tsk: TaskService) {
            // console.log(window.outerWidth);
        // localStorage.setItem(this.viewNots, 'false');
        // this.viewNots = localStorage.getItem(this.viewNots);
        this.location = location;
        this.nativeElement = element.nativeElement;
        this.sidebarVisible = false;
        this.taskSent = is.getTask();
        this.applicant = {
            company: is.initCompwithRoles(), department: is.getDeptInit(), dataId: '',
            email: '', bus_email: '', id: '', name: '', phoneNumber: '',
            project: is.getSelectedProject(), photoURL: '', address: '', nationalId: '', nationality: ''
        };
        this.coloursReq = {
            company: is.initCompwithRoles(), department: is.getDeptInit(), dataId: '', email: '',
            bus_email: '', id: '', name: '', phoneNumber: '', structuralRole: '',
            project: is.getSelectedProject(), photoURL: '', address: '', nationalId: '', nationality: ''
        };
        this.enterpriseInvited = {
            company: is.initCompwithRoles(), department: is.getDeptInit(), dataId: '', email: '',
            bus_email: '', id: '', name: '', phoneNumber: '',
            project: is.getSelectedProject(), photoURL: '', address: '', nationalId: '', nationality: ''
        };
        // this.userProject = is.getSelectedProject();
        this.connectingProject = is.getSelectedProject();
        this.connectingCompany = is.initCompwithRoles();
        this.connectingCompany.structuralRole = '';
        this.requestingProject = is.getSelectedProject();
        this.requestingCompany = is.initCompwithRoles();
    }

    onChange($event) {
        console.log($event); this.ws.tutorialOnOff($event.currentValue);
        const newVal = String($event.currentValue)
        sessionStorage.setItem('showTut', newVal);
        // updateTutStatus()

    }

    checkRole() {
        // console.log(this.coloursReq.company.structuralRole);
    }

    setTaskDetail(task) {
        // console.log(task);
        this.taskInfo = task;
    }

    setTaskNudge(nudg) {
        // console.log(nudg);
        this.nudg = nudg;
    }

    setTaskSent(task) {
        this.taskSent = task;
    }

    selectUser(man: Applicant) {
        console.log(man);
        // console.log('for company');
        this.applicant = man;
    }

    selectRemoveNots(man: any) {
        // console.log(man);
        // console.log('for company');
        this.removeNot = man;
    }

    selectExitInitUser(man: any) {
        // console.log(man);
        // console.log('for company');
        this.exitCpmNot = man;
    }

    selectUserP(man: Applicant) {
        console.log(man);
        // console.log('for Project');
        this.reqName = man.name;
        this.coloursReq = man;
        // console.log(this.coloursReq);
        this.connectingProject = man.project;
        this.connectingCompany = man.company;
    }

    selectUserPIC(man: Applicant) {
        // console.log(man);
        // console.log('Enterprise invited to Project');
        // this.appUser = man;
        this.enterpriseInvited = man;
        // console.log(this.enterpriseInvited);
        this.requestingProject = man.project;
        this.requestingCompany = man.company;
    }

    prjDeltor(noti) {
        this.prjDelt = noti;
    }

    selectCustomer(app) {
        this.cus_applie = app;
    }

    setManRequest(app) {
        this.managerReq = app;
    }

    setLeftStaff(app) {
        this.leftStaff = app;
    }

    selectCus(app) {
        this.applie = app;
    }

    selectAppNot(app) {
        console.log(app);
        this.appNoty = app;
    }

    setLabAppNot(app) {
        this.appLabNoty = app;
    }

    setTeamAs(app) {
        this.teamAs = app;
        // console.log(this.teamAs);
    }

    champSet(app) {
        this.teamChamp = app;
        // console.log(this.teamChamp);
    }

    setStaff(app) {
        console.log(app)
        this.newStaff = app;
    }

    setDelcine(app) {
        this.setDeclinee = app;
    }

    setTeamAccepted(app) {
        this.teamAccept = app;
    }

    setAccepted(app) {
        this.exitAccept = app;
    }

    setExitAp(app) {
        this.exitApp = app;
    }

    setExCmp(app) {
        this.cmpExit = app;
    }

    setEntExitAp(app) {
        this.exitComp = app;
    }

    setNonStaff(app) {
        this.nonStaff = app;
    }

    setExitNots(app) {
        // console.log(app);
        this.exitStaff = app;
    }

    setRecieptor(app) {
        this.appAprv = app;
    }

    setDeclined(app) {
        this.delinedApp = app;
    }

    exitNots(app) {
        this.exitNotice = app;
    }

    nxtPage() {
        this.page2 = true;
        this.page1 = false;
    }

    bckPage() {
        this.page2 = false;
        this.page1 = true;
    }

    resetForm() {
        this.applicant = {
            company: this.is.initCompwithRoles(), department: this.is.getDeptInit(), dataId: '', email: '',
            bus_email: '', id: '', name: '', phoneNumber: '', project: this.is.getSelectedProject(), photoURL: '', address: '',
            nationalId: '', nationality: ''
        };
        this.company = {
            name: '', by: '', byId: '', createdOn: '', id: '', bus_email: '', location: '', sector: '', participants: null, logoURL: '',
            champion: null, address: '', telephone: '', services: null, taxDocument: '', HnSDocument: '', IndustrialSectorDocument: '',
            updatedStatus: false, targetMonthlyIncome: '', actualMonthlyIncome: '', balanceSheet: '', actualAnnualIncome: '',
            targetAnnualIncome: ''
        }
    }

    acceptTask() {
        this.myDocument.collection('allNotice').doc(this.taskSent.id).delete().then(() => {
            this.taskSent = this.is.getTask();
            this.ns.showNotification('acceptTask', 'top', 'right', this.taskSent);
        });
    }

    checkCreds() {
        this.afAuth.authState.subscribe(user => {
            // console.log(user);
            if (!user) {
                this.router.navigate(['/pages/login']);
            }
        })
    }

    ngOnInit() {
        this.is.toolPlug = true;
        this.checkCreds();
        this.getNotice();
        this.listTitles = ROUTES.filter(listTitle => listTitle);
        // console.log(this.usd.myData);
        const navbar: HTMLElement = this.element.nativeElement;
        const body = document.getElementsByTagName('body')[0];
        this.toggleButton = navbar.getElementsByClassName('navbar-toggler')[0];
        if (body.classList.contains('sidebar-mini')) {
            misc.sidebar_mini_active = true;
        }
        this._router = this.router.events.filter(event => event instanceof NavigationEnd).subscribe((event: NavigationEnd) => {
            const $layer = document.getElementsByClassName('close-layer')[0];
            if ($layer) {
                $layer.remove();
            }
        });
        this.state_with_icons = this.usd.acc?.showTutorial;
        this.counter$ = Observable.interval(1000).map((x) => {
            return Math.floor((this.timedstamp - new Date().getTime()) / 1000);
        });

        this.subscription = this.counter$.subscribe((x) => this.message = this.dhms());
        // console.log(this.message);
        window.onunload = function () {
            sessionStorage.removeItem('key');
        }
        
    }

    countWords(str) {
        str = str.replace(/(^\s*)|(\s*$)/gi,"");
        str = str.replace(/[ ]{2,}/gi," ");
        str = str.replace(/\n /,"\n");
        str = str.replace(/\n /,"\n");
        str = str.replace(/ > /g, ' ')
        return str.split(' ').length;
    }

    getMaxWidth() {
        const n = this.getTitle().length;
        const min = 75;
        const max = 150;
        if (n > min) {
            // console.log((((max - (n - min)) / max) * 100));
            return ((((max - (n - min)) / max) * 100).toString() + '%');
        }
    }

    getFlex() {
        const n = this.getTitle().length;
        const min = 60;
        const max = 150;
        let flex = 0;
        if (n > min) {
            // console.log((((max - (n - min)) / max) * 100));
            if ((((max - (n - min)) / max) * 100) > 79.5) {
                if (window.outerWidth < 1920) {
                    // flex = 60;
                    // console.log(window.outerWidth);
                    if ((((max - (n - min)) / max) * 100) > 50) {
                        flex = 60;
                    } else {
                        flex = 50;
                    }
                    return ('0 ' + '0 ' + flex.toString() + '%');
                } else {
                    if ((((max - (n - min)) / max) * 100) > 80) {
                        flex = 79.5;
                    } else {
                        flex = 90.5;
                    }
                    // console.log(flex);
                    if (this.is.toolSet === 'Enterprise' || this.is.toolSet === 'Personal') {
                        return `-1 0 ${flex.toString()}%`;
                    } else {
                        return `0 0 ${flex.toString()}%`;
                    }
                }
            } else {
                flex = (((max - (n - min)) / max) * 100);
            }
        } else {
            if (window.outerWidth < 1920) {
                // flex = 60;
                 if (n > 50) {
                    // console.log(window.outerWidth);
                    if ((((max - (n - min)) / max) * 100) > 52) {
                        flex = 78;
                    } else {
                        flex = 50;
                    }
                }
                return ('0 ' + '0 ' + flex.toString() + '%');
            } else {
                // flex = (((max - (n - min)) / max) * 100);
                // if (this.is.toolSet === 'Enterprise' || this.is.toolSet === 'Personal') {
                //     return `-1 0 ${flex.toString()}%`;
                // } else {
                //     return `0 0 ${flex.toString()}%`;
                // }
            }
            // console.log(flex);
        }
    }

    getNotice() {
        if ((this.usd?.noMgs.length + this.usd.totalNotices.length) > 0) { 
                const num = (this.usd?.noMgs.length + this.usd.totalNotices.length);
            if (this.installAppNots && num && this.installAppNots > num) {
                sessionStorage.setItem('notices', '4');
                this.installAppNots = Number(sessionStorage.getItem('notices'));
                if (!this.installAppNots) {
                    sessionStorage.setItem('notices', '2');
                }
                console.log(this.installAppNots);
            } else if (this.installAppNots && num && this.installAppNots < num) {

            } else {

            }
        }
        if (window.history.length > 1) {
            this.ird = true;
        } else {
            this.ird = false;
        }
    }

    dhms() {
        this.timedstamp += 1;
        const d = new Date();
        d.getMilliseconds()

        const nHrs = Math.floor(d.getHours());
        const nMin = Math.floor(d.getMinutes());
        const nSecs = Math.floor(d.getSeconds());
        const millSecs = Math.floor(d.getMilliseconds());
        this.is.millSecs = millSecs;
        // console.log(millSecs);
        if (nSecs % 0.25 === 0) {
            // if (millSecs % 50 === 0) {
            this.randomColor = '#' + Math.floor(Math.random() * 16777215).toString(16);
            this.msgColor = '#' + Math.floor(Math.random() * 16777000).toString(16);
            this.bulb = '#' + Math.floor(Math.random() * 20777000).toString(16);
            this.is.randomColor = this.randomColor;
            this.side = !this.side
            this.is.side = !this.side
        }
        return [nHrs + ':', nMin + ':', nSecs].join(' ');
    }

    check() {
        console.log();
        
    }

    minimizeSidebar() {
        const body = document.getElementsByTagName('body')[0];

        if (misc.sidebar_mini_active === true) {
            body.classList.remove('sidebar-mini');
            misc.sidebar_mini_active = false;

        } else {
            setTimeout(function () {
                body.classList.add('sidebar-mini');

                misc.sidebar_mini_active = true;
            }, 300);
        }
        // we simulate the window Resize so the charts will get updated in realtime.
        const simulateWindowResize = setInterval(function () {
            window.dispatchEvent(new Event('resize'));
        }, 180);

        // we stop the simulation of Window Resize after the animations are completed
        setTimeout(function () {
            clearInterval(simulateWindowResize);
        }, 1000);
    }

    isMobileMenu() {
        if (window.outerWidth < 991) {
            return false;
        }
        return true;
    }

    sidebarOpen() {
        const toggleButton = this.toggleButton;
        const html = document.getElementsByTagName('html')[0];
        setTimeout(function () {
            toggleButton.classList.add('toggled');
        }, 500);
        const mainPanel = <HTMLElement>document.getElementsByClassName('main-panel')[0];
        if (window.innerWidth < 991) {
            mainPanel.style.position = 'fixed';
        }
        html.classList.add('nav-open');
        this.sidebarVisible = true;
    }

    sidebarClose() {
        const html = document.getElementsByTagName('html')[0];
        this.toggleButton?.classList?.remove('toggled');
        this.sidebarVisible = false;
        html.classList.remove('nav-open');
        const mainPanel = <HTMLElement>document.getElementsByClassName('main-panel')[0];
        if (window.innerWidth < 991) {
            setTimeout(function () {
                mainPanel.style.position = '';
            }, 500);
        }
    }

    sidebarToggle() {
        if (this.sidebarVisible === false) {
            this.sidebarOpen();
        } else {
            this.sidebarClose();
        }
    }

    // openTut(template: TemplateRef<any>) {
    openTut(data) {
        // console.log(this.countWords(this.getTitle()));
        this.afAuth.authState.subscribe(user => {
            // console.log(user);
            if (user !== null) {
                if (this.is.module === 'transactions') {
                } else if (this.is.module === 'tasking') {
                    // console.log(this.usd.acc?.showTutorial);
                    if (data === 'btn') {
                        this.tutMod = this.modalService.open(this.tempTutorial, { size: 'sm' });
                    } else {
                        if (this.usd.acc?.showTutorial === true) {
                            setTimeout(() => {
                                console.log('openTut');
                                this.tutMod = this.modalService.open(this.tempTutorial, { size: 'sm' });
                            }, 20000)
                        }
                    }
                } else {}
            } else {
                this.router.navigate(['/pages/login']);
            }
        })
    }

    ngAfterViewInit(): void {
        // let self = this;
        let self = this.is.toolPlug;
        // self.changeDetectorRef.detectChanges();
    }

    handleImgError = e => {
        e.target.src = './assets/img/faces/avatar.png';
        
        // this.ns.getImgs()
    }
    
    getTitle() {
        let titlee = this.location.prepareExternalUrl(this.location.path());
        // this.addHistory(titlee);
        this.is.location = titlee;
        if ((titlee).includes('report')) {
            // console.log(titlee);
            this.is.toolPlug = false;
        } else {
            this.is.toolPlug = true;
        }
        if ((titlee).includes('Party Inventory')) {
            this.is.updatePartyTimes = true;
        } else {
            this.is.updatePartyTimes = false;
        }
        // console.log(this.is.toolPlug);
        if((titlee).includes('task24-7')) {
            this.is.module === 'tasking';
        } else if((titlee).includes('openbooks')){
            this.is.module === 'transactions';
        }
        const parent_from_url = titlee.split('/')[1];
        const nextUrl = titlee.split('/')[2];
        if (titlee.charAt(0) === '#') {
            titlee = titlee.slice(1);
        }
        this.is.onChat = false;
        if (nextUrl === 'client') {
            this.is.module = 'tasking';
            this.is.chatSet = false;
            this.setContact = false;
            this.domain.type = 'Customers';
            this.is.toolSet = 'client';
            // console.log('client');
            return 'Client' + ' > ' + this.usd.setClient?.name;
        } else if (parent_from_url === 'openbooks') {
            this.is.module = 'transactions';
            // return this.transtitle(parent_from_url);
            return this.transtitle('Customer Care', titlee);
            // return this.transtitle('Open Books 360°', titlee);
        } else if (parent_from_url === 'chats') {
            this.is.chatSet = false;
            this.setContact = true;
            this.is.onChat = true;
            this.is.domain = 'openbooks';
            return this.usd.setContact.name + ' Chat';
            // return 'Chat with' + ' ' + this.usd.setContact.name;
        } else if (parent_from_url === 'task24-7') {
            // console.log(parent_from_url);
            this.is.module = 'tasking';
            this.is.domain = 'task24-7';
            return this.task24title('Task24/7', titlee);
        } else {
            for (let item = 0; item < this.listTitles.length; item++) {
                const parent = this.listTitles[item];
                this.is.toolSet = '';
                this.is.chatSet = false;
                this.setContact = true;
                // console.log(parent.title);
                return parent.title;
            } 
        }
        // console.log(parent_from_url, titlee);        
    }

    task24title(titlee, tt) {
        this.is.domain = 'task24-7';
        this.rs.domain = 'task24-7';
        for (let item = 0; item < this.listTitles.length; item++) {
            const parent = this.listTitles[item];
            if (parent.path === tt) {
                this.is.toolSet = 'Personal';
                this.is.chatSet = false;
                this.setContact = true;
                // console.log(parent.title);
                return parent.title;
            } else if (parent.children) {
                // console.log(parent.children);
                const children_from_url = tt.split('/')[2];
                // console.log(children_from_url, this.usd?.setContact?.chatId);
                if (this.usd.setCompany?.id && children_from_url === this.usd.setCompany.id) {
                    this.is.toolSet = 'Enterprise';
                    this.is.chatSet = false;
                    this.setContact = false;
                    return this.usd.setCompany.name + ' ' + 'Financials';
                } else {
                    for (let current = 0; current < parent.children.length; current++) {
                        if (parent.children[current].path === children_from_url) {
                            // console.log(parent.title, tt);
                            if (parent.children[current].title === 'name user') {
                                this.is.toolSet = 'Personal';
                                this.is.chatSet = true;
                                this.setContact = false;
                                const title = 'My Contacts' + ' > ' + this.usd.setContact?.name;
                                if (this.domain.type !== 'Contact' && this.is.toolSet === 'Personal') {
                                    this.domain.type = 'Contact';
                                    this.openTut('');
                                }
                                return title;
                            } else if (parent.children[current].title === 'Chat') {
                                console.log('Chat');
                                this.is.toolSet = this.is.toolSet;
                                this.is.chatSet = false;
                                this.setContact = true;
                                this.is.onChat = true;
                                const title = 'Chat' + ' to ' + this.usd.setContact?.name;
                                return title;
                            } else if (parent.children[current].title === 'name task') {
                                this.is.toolSet = 'Personal';
                                this.is.toolPlug = false;
                                this.is.chatSet = false;
                                this.setContact = false;
                                let title = ''
                                if (this.usd.setTask?.description) {
                                    title = this.usd.setTask?.description;
                                } else {
                                    title = this.usd.setTask?.name;
                                }
                                return title;
                            } else if (parent.children[current].title === 'name proj') {
                                // console.log('proj');
                                this.is.toolSet = 'Project';
                                if (this.domain.type !== 'Project' && this.is.toolSet === 'Project') {
                                    this.domain.type = 'Project';
                                    this.openTut('');
                                }
                                this.is.chatSet = false;
                                this.setContact = false;
                                return this.usd.setProject.companyName + ' > Projects > ' + this.usd.setProject?.name;
                            }  else if (parent.children[current].title === 'financial-actuals') {
                                this.is.toolSet = 'Enterprise';
                                this.is.chatSet = false;
                                this.setContact = false;
                                return this.usd.setCompany.name + ' ' + 'Financial actuals';
                            } else if (parent.children[current].title === 'projected-financial') {
                                this.is.toolSet = 'Enterprise';
                                this.is.chatSet = false;
                                this.setContact = false;
                                return this.usd.setCompany.name + ' ' + 'Projected Financial Statements';
                            } else if (parent.children[current].title === 'Enterprise Financials') {
                                this.is.toolSet = 'Enterprise';
                                this.is.chatSet = false;
                                this.setContact = false;
                                return this.usd.setCompany.name + ' ' + 'Financial Statements';
                            } else if (parent.children[current].title === 'Description') {
                                this.is.toolSet = 'Project';
                                this.is.chatSet = false;
                                this.setContact = false;
                                return this.usd.setProject?.name + ' > ' + parent.children[current].title;
                            } else if (parent.children[current].title === 'Prj-cus') {
                                // this.is.toolSet = 'Project';
                                console.log('Prj-cus');
                                this.is.chatSet = false;
                                this.setContact = false;
                                this.domain.type = 'Customers';
                                this.is.toolSet = 'client';
                                console.log(this.rs.domain, 'Customers');
                                return this.usd.setProject?.name + ' > ' + ' Customer ' + ' > ' + (this.usd.setProject.guest === true) ? this.usd.acc.name : (this.usd.setClient?.name && this.usd.setClient?.name !== '') ? this.usd.setClient?.name : (this.usd.projectTeam?.name && this.usd.projectTeam?.name !== '') ? this.usd.projectTeam.name : '' + ' > Dashboard';
                                // return this.usd.setProject?.name + ' > ' + parent.children[current].title;
                            } else if (parent.children[current].title === 'Project Standards') {
                                this.is.toolSet = 'Project';
                                this.is.chatSet = false;
                                this.setContact = false;
                                return this.usd.setProject?.name + ' > ' + 'Standards';
                            }  else if (parent.children[current].title === 'Cost framework') {
                                this.is.toolSet = 'Project';
                                this.is.chatSet = false;
                                this.setContact = false;
                                return this.usd.setProject?.name + ' > ' + parent.children[current].title;
                            } else if (parent.children[current].title === 'Project Program') {
                                this.is.toolSet = 'Project';
                                this.is.chatSet = false;
                                this.setContact = false;
                                return this.usd.setProject?.name + ' > ' + parent.children[current].title;
                            } else if (parent.children[current].title === 'Equipment') {
                                this.is.toolSet = 'Project';
                                this.is.chatSet = false;
                                this.setContact = false;
                                return this.usd.setProject?.name + ' > ' + parent.children[current].title;
                            } else if (parent.children[current].title === 'name corp') {
                                this.is.toolSet = 'Enterprise';
                                if (this.domain.type !== 'Enterprise' && this.is.toolSet === 'Enterprise') {
                                    this.domain.type = 'Enterprise';
                                    this.openTut('');
                                }
                                this.is.chatSet = false;
                                this.setContact = false;
                                return this.usd.setCompany?.name;
                            } else if (parent.children[current].title === 'Customers') {
                                this.is.toolSet = 'Customers';
                                if (this.domain.type !== 'Customers' && this.is.toolSet === 'Customers') {
                                    this.domain.type = 'Customers';
                                    this.openTut('');
                                }
                                this.is.chatSet = false;
                                this.setContact = false;
                                let title = this.usd.setCompany?.name;
                                if (this.usd.setCompany.name !== '') {
                                    title = this.usd.setCompany.name + ' > ' + parent.children[current].title;
                                }
                                return title;
                            } else if (parent.children[current].title === 'Staff') {
                                this.is.toolSet = 'Enterprise';
                                if (this.domain.type !== 'Staff' && this.is.toolSet === 'Enterprise') {
                                    this.domain.type = 'Staff';
                                    this.openTut('');
                                }
                                this.is.chatSet = false;
                                this.setContact = false;
                                let title = this.usd.setCompany?.name;
                                if (this.usd.setCompany.name !== '') {
                                    title = this.usd.setCompany.name + ' > ' + parent.children[current].title;
                                }
                                return title;
                            } else if (parent.children[current].title === 'Departments') {
                                this.is.toolSet = 'Enterprise';
                                if (this.domain.type !== 'Departments' && this.is.toolSet === 'Enterprise') {
                                    this.domain.type = 'Departments';
                                    this.openTut('');
                                }
                                this.is.chatSet = false;
                                this.setContact = false;
                                return this.usd.setCompany?.name + ' > ' + parent.children[current].title;
                            } else if (parent.children[current].title === 'Department') {
                                this.is.toolSet = 'Enterprise';
                                this.is.chatSet = false;
                                this.setContact = false;
                                return this.usd.setCompany?.name + ' > ' + 'Departments' + ' > ' + this.es.setDepartment.name;
                            } else if (parent.children[current].title === 'Enterprise Projects') {
                                this.is.toolSet = 'Enterprise';
                                this.is.chatSet = false;
                                this.setContact = false;
                                return this.usd.setCompany?.name + ' > ' + 'Projects';
                            } else if (parent.children[current].title === 'Assets') {
                                this.is.toolSet = 'Enterprise';
                                this.is.chatSet = false;
                                this.setContact = false;
                                return this.usd.setCompany?.name + ' > ' + parent.children[current].title;
                            } else if (parent.children[current].title === 'Enterprise Standards') {
                                this.is.toolSet = 'Enterprise';
                                this.is.chatSet = false;
                                this.setContact = false;
                                return this.usd.setCompany?.name + ' > ' + 'Standards';
                            } else if (parent.children[current].title === 'Company Dashboard') {
                                this.is.toolSet = 'Enterprise';
                                this.is.chatSet = false;
                                this.setContact = false;
                                return this.usd.setCompany?.name + ' > ' + 'Dashboard';
                            } else if (parent.children[current].title === 'name customer') {
                                this.is.toolSet = 'client';
                                this.is.chatSet = false;
                                this.setContact = false;
                                if (this.domain.type !== 'Customer' && this.is.toolSet === 'client') {
                                    this.domain.type = 'Customer';
                                    this.openTut('');
                                }
                                return this.usd.setCompany?.name + ' > ' + ' Customer ' + ' > ' + this.usd.setClient?.name + ' > Home';
                            } else if (parent.children[current].title === 'Customer Managers') {
                                this.is.toolSet = 'client';
                                this.is.chatSet = false;
                                this.setContact = false;
                                // return this.usd.setCompany?.name + ' > ' + ' Customer ' + ' > ' + ((this.usd.setProject.guest === true) ? this.usd.acc.name : (this.usd.setClient?.name && this.usd.setClient?.name !== '') ? this.usd.setClient?.name : (this.usd.projectTeam?.name && this.usd.projectTeam?.name !== '') ? this.usd.projectTeam.name : '') + ' > Managers';
                                return 'Relationship Managers';
                            } else if (parent.children[current].title === 'Customer Dashboard') {
                                console.log('Customer Dashboard');
                                this.is.toolSet = 'client';
                                this.is.chatSet = false;
                                this.setContact = false;
                                // return this.usd.setCompany?.name + ' > ' + ' Customer ' + ' > ' + ((this.usd.setProject.guest === true) ? this.usd.acc.name : (this.usd.setClient?.name && this.usd.setClient?.name !== '') ? this.usd.setClient?.name : (this.usd.projectTeam?.name && this.usd.projectTeam?.name !== '') ? this.usd.projectTeam.name : '') + ' > Dashboard';
                                return ((this.usd.setProject.guest === true) ? this.usd.acc.name : (this.usd.setClient?.name && this.usd.setClient?.name !== '') ? this.usd.setClient?.name : (this.usd.projectTeam?.name && this.usd.projectTeam?.name !== '') ? this.usd.projectTeam.name : '') + ' > Dashboard';
                            } else if (parent.children[current].title === 'name proj-cmp') {
                                this.is.toolSet = 'proj-team';
                                this.is.chatSet = false;
                                this.setContact = false;
                                if (this.domain.type !== 'Project Company' && this.is.toolSet === 'proj-team') {
                                    this.domain.type = 'Project Company';
                                    this.openTut('');
                                }
                                return this.usd.setProject.companyName + ' > Projects > ' + this.usd.setProject?.name + ' > '
                                + ' Company ' + ' > '
                                + this.usd.projectTeam?.name + ' > Home';
                            } else if (parent.children[current].title === 'Project Companies') {
                                // this.is.toolSet = 'proj-team';
                                this.is.toolSet = 'Project Companies';
                                if (this.domain.type !== 'Project Companies' && this.is.toolSet === 'Project Companies') {
                                    this.domain.type = 'Project Companies';
                                    this.openTut('');
                                }
                                this.is.chatSet = false;
                                this.setContact = false;
                                return this.usd.setProject.companyName + ' > Projects > ' + this.usd.setProject?.name + ' > '
                                + ' Companies';
                            } else if (parent.children[current].title === 'Project Dashboard') {
                                this.is.toolSet = 'Project';
                                this.is.chatSet = false;
                                this.setContact = false;
                                return this.usd.setProject.companyName + ' > Projects > ' + this.usd.setProject?.name + ' > '
                                + ' Dashboard';
                            } else if (parent.children[current].title === 'Team Dashboard') {
                                this.is.toolSet = 'proj-team';
                                this.is.chatSet = false;
                                this.setContact = false;
                                return this.usd.setProject.companyName + ' > Projects > ' + this.usd.setProject?.name + ' > '
                                + ' Company ' + ' > ' + this.usd.projectTeam?.name + ' > Dashboard';
                            } else if (parent.children[current].title === 'Team Champions') {
                                this.is.toolSet = 'proj-team';
                                this.is.chatSet = false;
                                this.setContact = false;
                                return this.usd.setProject.companyName + ' > Projects > ' + this.usd.setProject?.name + ' > '
                                + ' Company ' + ' > ' + this.usd.projectTeam?.name + ' > Champions';
                            } else if (parent.children[current].title === 'name ent-user') {
                                this.is.toolSet = 'Enterprise';
                                this.is.chatSet = true;
                                this.setContact = false;
                                if (this.domain.type !== 'Staff Member' && this.is.toolSet === 'Enterprise') {
                                    this.domain.type = 'Staff Member';
                                    this.openTut('');
                                }
                                return this.usd.setCompany?.name + ' > ' + this.usd.setContact?.name;
                            } else if (parent.children[current].title === 'name ent-manager') {
                                this.is.toolSet = 'client';
                                this.is.chatSet = true;
                                this.setContact = false;
                                return this.usd.setCompany?.name + ' > ' + ' Customer ' + ' > ' + this.usd.setClient?.name + ' > '
                                + this.usd.setContact?.name;
                            } else if (parent.children[current].title === 'Send Invitation') {
                                this.is.chatSet = false;
                                this.setContact = false;
                                const title = parent.children[current].title;
                                return title;
                            } else if (parent.children[current].title === 'Customer Invitation') {
                                this.is.toolSet = 'Enterprise';
                                this.is.chatSet = false;
                                this.setContact = false;
                                const title = this.usd.setCompany?.name + ' > ' + parent.children[current].title;
                                return title;
                            } else if (parent.children[current].title === 'Team Invitation') {
                                this.is.toolSet = 'Project';
                                this.is.chatSet = false;
                                this.setContact = false;
                                let title = parent.children[current].title;
                                if (this.usd.setProject.name !== '') {
                                    title = this.usd.setProject.name + ' > ' + title;
                                }
                                return title;
                            } else if (parent.children[current].title === 'Companies') {
                                this.is.toolSet = 'Project';
                                this.is.chatSet = false;
                                this.setContact = false;
                                if (this.domain.type !== 'Companies' && this.is.toolSet === 'Project') {
                                    this.domain.type = 'Companies';
                                    this.openTut('');
                                }
                                let title = parent.children[current].title;
                                if (this.usd.setProject.name !== '') {
                                    title = this.usd.setProject.name + ' > ' + parent.children[current].title;
                                }
                                return title;
                            } else if (parent.children[current].title === 'Project Dashboard') {
                                this.is.toolSet = 'Project';
                                this.is.chatSet = false;
                                this.setContact = false;
                                let title = parent.children[current].title;
                                if (this.usd.setProject.name !== '') {
                                    title = this.usd.setProject.name + ' > ' + parent.children[current].title;
                                }
                                return title;
                            } else if (parent.children[current].title === 'Team') {
                                this.is.toolSet = 'Project';
                                this.is.chatSet = false;
                                this.setContact = false;
                                if (this.domain.type !== 'Team' && this.is.toolSet === 'Project') {
                                    this.domain.type = 'Team';
                                    this.openTut('');
                                }
                                let title = parent.children[current].title;
                                if (this.usd.setProject.name !== '') {
                                    title = this.usd.setProject.name + ' > ' + this.usd.setProject.companyName + ' > '
                                    + parent.children[current].title;
                                }
                                return title;
                            } else if (parent.children[current].title === 'name proj-user') {
                                this.is.chatSet = true;
                                this.is.toolSet = 'Project';
                                this.setContact = false;
                                if (this.domain.type !== 'Team Member' && this.is.toolSet === 'Project') {
                                    this.domain.type = 'Team Member';
                                    this.openTut('');
                                }
                                return this.usd.setProject?.name + ' > ' + this.usd.setContact?.name;
                            } else if (parent.children[current].title === 'name team-user') {
                                this.is.chatSet = true;
                                this.is.toolSet = 'Project';
                                this.setContact = false;
                                return this.usd.setProject?.name + ' > ' + ' Company ' + ' > ' + this.usd.projectTeam?.name + ' > '
                                + this.usd.setContact?.name;
                            } else if (parent.children[current].title === 'My Diary') {
                                this.is.chatSet = false;
                                this.setContact = true;
                                this.is.toolSet = 'Personal';
                                const title = parent.children[current].title;
                                return title;
                            } else if (parent.children[current].title === 'Projects') {
                                this.is.chatSet = false;
                                this.setContact = true;
                                this.is.toolSet = 'Personal';
                                if (this.domain.type !== 'Projects List' && this.is.toolSet === 'Personal') {
                                    this.domain.type = 'Projects List';
                                    this.openTut('');
                                }
                                // const title = parent.title + '-' + 'Colours Project';
                                const title = titlee + '-' + 'Colours Project';
                                return title;
                            } else if (parent.children[current].title === 'Enterprises') {
                                this.is.toolSet = 'Personal';
                                if (this.domain.type !== 'Enterprise List' && this.is.toolSet === 'Personal') {
                                    this.domain.type = 'Enterprise List';
                                    this.openTut('');
                                }
                                this.is.chatSet = false;
                                this.setContact = true;
                                // const title = parent.title + '-' + 'Colours Enterprise';
                                const title = titlee + '-' + 'Colours Enterprise';
                                return title;
                            } else if (parent.children[current].title === 'My Classifications') {
                                this.is.toolSet = 'Personal';
                                this.is.chatSet = false;
                                this.setContact = true;
                                const title = 'Tasks 24/7 - Colours Personal';
                                return title;
                            } else if (parent.children[current].title === 'My Standards') {
                                this.is.toolSet = 'Personal';
                                this.is.chatSet = false;
                                this.setContact = true;
                                const title = 'Tasks 24/7 - Colours Personal';
                                return title;
                            } else if (parent.children[current].title === 'My Tasks') {
                                // this.setSessionStorage(parent.children[current].title);
                                this.titlee = 'My Tasks';
                                this.is.toolSet = 'Personal';
                                if (this.domain.type !== 'Personal' && this.is.toolSet === 'Personal') {
                                    this.domain.type = 'Personal';
                                    this.openTut('');
                                }
                                this.is.chatSet = false;
                                this.setContact = true;
                                const title = 'Tasks 24/7 - Colours Personal';
                                return title;
                            } else if (parent.children[current].title === 'My Contacts') {
                                this.is.toolSet = 'Personal';
                                if (this.domain.type !== 'My Contacts' && this.is.toolSet === 'Personal') {
                                    this.domain.type = 'My Contacts';
                                    this.openTut('');
                                }
                                this.is.chatSet = false;
                                this.setContact = true;
                                const title = parent.children[current].title;
                                return title;
                            } else if (parent.children[current].title === 'My Dashboard') {
                                this.is.toolSet = 'Personal';
                                this.is.chatSet = false;
                                this.setContact = true;
                                const title = 'Tasks 24/7 - Colours Personal';
                                return title;
                            } else {
                                this.is.toolSet = 'Personal';
                                this.is.chatSet = false;
                                this.setContact = true;
                                return parent.children[current].title;
                            }
                        }
                    }
                }
            }
        }
        return 'Colours';
    }

    transtitle(titlee, tt) {
        for (let item = 0; item < this.listTitles.length; item++) {
            const parent = this.listTitles[item];
            if (parent.path === tt) {
                this.is.toolSet = 'Personal';
                this.is.chatSet = false;
                this.setContact = true;
                // console.log(parent.title);
                return parent.title;
            } else if (parent.children) {
                // console.log(parent.children);
                const children_from_url = tt.split('/')[2];
                // console.log(children_from_url);
                if (this.usd.setContact?.chatId && children_from_url === this.usd.setContact.chatId) {
                    // this.is.toolSet = 'Chats';
                    this.is.chatSet = false;
                    this.setContact = true;
                    this.is.onChat = true;
                    return this.usd.setContact.name+ ' Chat';
                    // return 'Chat with' + ' ' + this.usd.setContact.name;
                } else if (this.usd.setCompany?.id && children_from_url === this.usd.setCompany.id) {
                    this.is.toolSet = 'Enterprise';
                    this.is.chatSet = false;
                    this.setContact = false;
                    return this.usd.setCompany.name + ' ' + 'Financials';
                } else {
                    for (let current = 0; current < parent.children.length; current++) {
                        // console.log(parent.title);
                        if (parent.children[current].path === children_from_url) {
                            if (parent.children[current].title === 'name user') {
                                this.is.toolSet = 'Personal';
                                this.is.chatSet = true;
                                this.setContact = false;
                                const title = 'My Contacts' + ' > ' + this.usd.setContact?.name;
                                if (this.domain.type !== 'Contact' && this.is.toolSet === 'Personal') {
                                    this.domain.type = 'Contact';
                                    this.openTut('');
                                }
                                return title;
                            } else if (parent.children[current].title === 'Chat') {
                                this.is.toolSet = this.is.toolSet;
                                this.is.chatSet = false;
                                this.setContact = true;
                                this.is.onChat = true;
                                const title = 'Chat' + ' to ' + this.usd.setContact?.name;
                                return title;
                            } else if (parent.children[current].title === 'name task') {
                                this.is.toolSet = 'Personal';
                                this.is.toolPlug = false;
                                this.is.chatSet = false;
                                this.setContact = false;
                                let title = ''
                                if (this.usd.setTask?.description) {
                                    title = this.usd.setTask?.description;
                                } else {
                                    title = this.usd.setTask?.name;
                                }
                                return title;
                            } else if (parent.children[current].title === 'name proj') {
                                this.is.toolSet = 'Project';
                                // console.log(this.is.toolSet);
                                if (this.domain.type !== 'Project' && this.is.toolSet === 'Project') {
                                    this.domain.type = 'Project';
                                    this.openTut('');
                                }
                                this.is.chatSet = false;
                                this.setContact = false;
                                return this.usd.setProject.companyName + ' > Projects > ' + this.usd.setProject?.name;
                            } else if (parent.children[current].title === 'Description') {
                                this.is.toolSet = 'Project';
                                this.is.chatSet = false;
                                this.setContact = false;
                                return this.usd.setProject?.name + ' > ' + parent.children[current].title;
                            } else if (parent.children[current].title === 'Project Standards') {
                                this.is.toolSet = 'Project';
                                this.is.chatSet = false;
                                this.setContact = false;
                                return this.usd.setProject?.name + ' > ' + 'Standards';
                            } else if (parent.children[current].title === 'Prj-cus') {
                                // this.is.toolSet = 'Project';
                                this.is.chatSet = false;
                                this.setContact = false;
                                this.is.domain = 'openbooks';
                                this.rs.domain = 'openbooks';
                                // console.log(this.rs.domain, this.is.domain);                                
                                this.domain.type = 'Customers';
                                this.is.toolSet = 'client';
                                // return this.usd.setProject?.name;
                                // console.log("Customers", 'meeeeee');
                                this.usd.pathTitle = ((this.usd.setProject.guest === true) ? this.usd.setProject?.name + ' > ' + ' Customer ' + ' > ' + ((this.usd.setProject.guest === true) ? this.usd.acc.name : (this.usd.setClient?.name && this.usd.setClient?.name !== '') ? this.usd.setClient?.name : (this.usd.projectTeam?.name && this.usd.projectTeam?.name !== '') ? this.usd.projectTeam.name : '') + '': '');
                                return this.usd.setProject?.name + ' > ' + ' Customer ' + ' > ' + ((this.usd.setProject.guest === true) ? this.usd.acc.name : (this.usd.setClient?.name && this.usd.setClient?.name !== '') ? this.usd.setClient?.name : (this.usd.projectTeam?.name && this.usd.projectTeam?.name !== '') ? this.usd.projectTeam.name : '') + '';
                                // return this.usd.setProject?.name + ' > ' + ' Customer ' + ' > ' + (this.usd.setProject.guest === true) ? this.usd.acc.name : (this.usd.setClient?.name && this.usd.setClient?.name !== '') ? this.usd.setClient?.name : (this.usd.projectTeam?.name && this.usd.projectTeam?.name !== '') ? this.usd.projectTeam.name : '' + ' > Dashboard';
                                // return this.usd.setProject?.name + ' > ' + parent.children[current].title;
                            } else if (parent.children[current].title === 'Equipment') {
                                this.is.toolSet = 'Project';
                                this.is.chatSet = false;
                                this.setContact = false;
                                return this.usd.setProject?.name + ' > ' + parent.children[current].title;
                            } else if (parent.children[current].title === 'name corp') {
                                this.is.toolSet = 'Enterprise';
                                if (this.domain.type !== 'Enterprise' && this.is.toolSet === 'Enterprise') {
                                    this.domain.type = 'Enterprise';
                                    this.openTut('');
                                }
                                this.is.chatSet = false;
                                this.setContact = false;
                                return this.usd.setCompany?.name;
                            } else if (parent.children[current].title === 'Customers') {
                                this.is.toolSet = 'Customers';
                                if (this.domain.type !== 'Customers' && this.is.toolSet === 'Customers') {
                                    this.domain.type = 'Customers';
                                    // this.openTut('');
                                    if((titlee).includes('project-customer')) {
                                    } else if((titlee).includes('openbooks')) {}
                                }
                                this.is.chatSet = false;
                                this.setContact = false;
                                let title = this.usd.setProject?.name;
                                if (this.usd.setProject.name !== '') {
                                    title = this.usd.setProject.name + ' > ' + parent.children[current].title;
                                    console.log('Cus', title)
                                    return title;
                                }
                            } else if (parent.children[current].title === 'Project Supplier') {
                                this.is.toolSet = 'Project-Supplier';
                                // console.log(this.is.toolSet);
                                if (this.domain.type !== 'Customers' && this.is.toolSet === 'Customers') {
                                    this.domain.type = 'Customers';
                                }
                                // console.log('ooooooooooooooooooooooo');
                                this.is.chatSet = false;
                                this.setContact = false;
                                let title = this.usd.setCompany?.name;
                                if (this.usd.setCompany.name !== '') {
                                    title = this.usd.setCompany.name + ' > ' + parent.children[current].title;
                                }
                                return title;
                            } else if (parent.children[current].title === 'Project Inventory') {
                                this.is.toolSet = 'Project';
                                this.is.chatSet = false;
                                this.setContact = false;
                                let title = this.usd.setProject.companyName + ' > Projects > ' + this.usd.setProject?.name + ' > Inventory';
                                return title;
                            } else if (parent.children[current].title === 'Project Inventory Dashboard') {
                                this.is.toolSet = 'Project';
                                this.is.chatSet = false;
                                this.setContact = false;
                                let title = this.usd.setProject.companyName + ' > Projects > ' + this.usd.setProject?.name + ' > Inventory Dashboard';
                                return title;
                            } else if (parent.children[current].title === 'Staff') {
                                this.is.toolSet = 'Enterprise';
                                if (this.domain.type !== 'Staff' && this.is.toolSet === 'Enterprise') {
                                    this.domain.type = 'Staff';
                                    this.openTut('');
                                }
                                this.is.chatSet = false;
                                this.setContact = false;
                                let title = this.usd.setCompany?.name;
                                if (this.usd.setCompany.name !== '') {
                                    title = this.usd.setCompany.name + ' > ' + parent.children[current].title;
                                }
                                return title;
                            } else if (parent.children[current].title === 'Departments') {
                                this.is.toolSet = 'Enterprise';
                                if (this.domain.type !== 'Departments' && this.is.toolSet === 'Enterprise') {
                                    this.domain.type = 'Departments';
                                    this.openTut('');
                                }
                                this.is.chatSet = false;
                                this.setContact = false;
                                return this.usd.setCompany?.name + ' > ' + parent.children[current].title;
                            } else if (parent.children[current].title === 'Department') {
                                this.is.toolSet = 'Enterprise';
                                this.is.chatSet = false;
                                this.setContact = false;
                                return this.usd.setCompany?.name + ' > ' + 'Departments' + ' > ' + this.es.setDepartment.name;
                            } else if (parent.children[current].title === 'Enterprise Projects') {
                                this.is.toolSet = 'Enterprise';
                                this.is.chatSet = false;
                                this.setContact = false;
                                return this.usd.setCompany?.name + ' > ' + 'Projects';
                            } else if (parent.children[current].title === 'Assets') {
                                this.is.toolSet = 'Enterprise';
                                this.is.chatSet = false;
                                this.setContact = false;
                                return this.usd.setCompany?.name + ' > ' + parent.children[current].title;
                            } else if (parent.children[current].title === 'Enterprise Standards') {
                                this.is.toolSet = 'Enterprise';
                                this.is.chatSet = false;
                                this.setContact = false;
                                return this.usd.setCompany?.name + ' > ' + 'Standards';
                            } else if (parent.children[current].title === 'Company Dashboard') {
                                this.is.toolSet = 'Enterprise';
                                this.is.chatSet = false;
                                this.setContact = false;
                                return this.usd.setCompany?.name + ' > ' + 'Dashboard';
                            } else if (parent.children[current].title === 'name customer') {
                                this.is.toolSet = 'client';
                                this.is.chatSet = false;
                                this.setContact = false;
                                if (this.domain.type !== 'Customer' && this.is.toolSet === 'client') {
                                    this.domain.type = 'Customer';
                                    this.openTut('');
                                }
                                return this.usd.setCompany?.name + ' > ' + ' Customer ' + ' > ' + this.usd.setClient?.name + ' > Home';
                            } else if (parent.children[current].title === 'Customer Managers') {
                                this.is.toolSet = 'client';
                                this.is.chatSet = false;
                                this.setContact = false;
                                // return this.usd.setCompany?.name + ' > ' + ' Customer ' + ' > ' + ((this.usd.setProject.guest === true) ? this.usd.acc.name : (this.usd.setClient?.name && this.usd.setClient?.name !== '') ? this.usd.setClient?.name : (this.usd.projectTeam?.name && this.usd.projectTeam?.name !== '') ? this.usd.projectTeam.name : '') + ' > Managers';
                                return 'Relationship Managers';
                            } else if (parent.children[current].title === 'Customer Dashboard') {
                                // console.log('Customer Dashboard 2');
                                this.is.toolSet = 'client';
                                this.is.chatSet = false;
                                this.setContact = false;
                                // return this.usd.setCompany?.name + ' > ' + ' Customer ' + ' > ' + this.usd.setClient?.name + ' > Dashboard';
                                // return this.usd.setCompany?.name + ' > ' + ' Customer ' + ' > ' + ((this.usd.setProject.guest === true) ? this.usd.acc.name : (this.usd.setClient?.name && this.usd.setClient?.name !== '') ? this.usd.setClient?.name : (this.usd.projectTeam?.name && this.usd.projectTeam?.name !== '') ? this.usd.projectTeam.name : '') + ' > Dashboard';
                                return ((this.usd.setProject.guest === true) ? this.usd.acc.name : (this.usd.setClient?.name && this.usd.setClient?.name !== '') ? this.usd.setClient?.name : (this.usd.projectTeam?.name && this.usd.projectTeam?.name !== '') ? this.usd.projectTeam.name : '') + ' > Dashboard';
                            } else if (parent.children[current].title === 'name proj-cmp') {
                                this.is.toolSet = 'proj-team';
                                this.is.chatSet = false;
                                this.setContact = false;
                                if (this.domain.type !== 'Project Company' && this.is.toolSet === 'proj-team') {
                                    this.domain.type = 'Project Company';
                                    this.openTut('');
                                }
                                return this.usd.setProject.companyName + ' > Projects > ' + this.usd.setProject?.name + ' > '
                                + ' Company ' + ' > ' + this.usd.projectTeam?.name + ' > Home';
                            } else if (parent.children[current].title === 'Project Companies') {
                                this.is.toolSet = 'proj-team';
                                // this.is.toolSet = 'Project Companies';
                                if (this.domain.type !== 'Project Companies' && this.is.toolSet === 'Project Companies') {
                                    this.domain.type = 'Project Companies';
                                    // this.openTut('');
                                }
                                this.is.chatSet = false;
                                this.setContact = false;
                                return this.usd.setProject.companyName + ' > Projects > ' + this.usd.setProject?.name + ' > '
                                + ' Companies';
                            } else if (parent.children[current].title === 'Project Dashboard') {
                                this.is.toolSet = 'Project';
                                this.is.chatSet = false;
                                this.setContact = false;
                                return this.usd.setProject.companyName + ' > Projects > ' + this.usd.setProject?.name + ' > '
                                + ' Dashboard';
                            } else if (parent.children[current].title === 'Team Dashboard') {
                                this.is.toolSet = 'proj-team';
                                this.is.chatSet = false;
                                this.setContact = false;
                                return this.usd.setProject.companyName + ' > Projects > ' + this.usd.setProject?.name + ' > '
                                + ' Company ' + ' > ' + this.usd.projectTeam?.name + ' > Dashboard';
                            } else if (parent.children[current].title === 'Team Champions') {
                                this.is.toolSet = 'proj-team';
                                this.is.chatSet = false;
                                this.setContact = false;
                                return this.usd.setProject.companyName + ' > Projects > ' + this.usd.setProject?.name + ' > '
                                + ' Company ' + ' > ' + this.usd.projectTeam?.name + ' > Champions';
                            } else if (parent.children[current].title === 'name ent-user') {
                                this.is.toolSet = 'Enterprise';
                                this.is.chatSet = true;
                                this.setContact = false;
                                if (this.domain.type !== 'Staff Member' && this.is.toolSet === 'Enterprise') {
                                    this.domain.type = 'Staff Member';
                                    this.openTut('');
                                }
                                return this.usd.setCompany?.name + ' > ' + this.usd.setContact?.name;
                            } else if (parent.children[current].title === 'name ent-manager') {
                                this.is.toolSet = 'client';
                                this.is.chatSet = true;
                                this.setContact = false;
                                return this.usd.setCompany?.name + ' > ' + ' Customer ' + ' > ' + this.usd.setClient?.name + ' > '
                                + this.usd.setContact?.name;
                            } else if (parent.children[current].title === 'Send Invitation') {
                                this.is.chatSet = false;
                                this.setContact = false;
                                const title = parent.children[current].title;
                                return title;
                            } else if (parent.children[current].title === 'Customer Invitation') {
                                this.is.toolSet = 'Enterprise';
                                this.is.chatSet = false;
                                this.setContact = false;
                                const title = this.usd.setCompany?.name + ' > ' + parent.children[current].title;
                                return title;
                            } else if (parent.children[current].title === 'Team Invitation') {
                                this.is.toolSet = 'Project';
                                this.is.chatSet = false;
                                this.setContact = false;
                                let title = parent.children[current].title;
                                if (this.usd.setProject.name !== '') {
                                    title = this.usd.setProject.name + ' > ' + title;
                                }
                                return title;
                            } else if (parent.children[current].title === 'Companies') {
                                this.is.toolSet = 'Project';
                                this.is.chatSet = false;
                                this.setContact = false;
                                if (this.domain.type !== 'Companies' && this.is.toolSet === 'Project') {
                                    this.domain.type = 'Companies';
                                    this.openTut('');
                                }
                                let title = parent.children[current].title;
                                if (this.usd.setProject.name !== '') {
                                    title = this.usd.setProject.name + ' > ' + parent.children[current].title;
                                }
                                return title;
                            } else if (parent.children[current].title === 'Project Dashboard') {
                                this.is.toolSet = 'Project';
                                this.is.chatSet = false;
                                this.setContact = false;
                                let title = parent.children[current].title;
                                if (this.usd.setProject.name !== '') {
                                    title = this.usd.setProject.name + ' > ' + parent.children[current].title;
                                }
                                return title;
                            } else if (parent.children[current].title === 'Team') {
                                this.is.toolSet = 'Project';
                                this.is.chatSet = false;
                                this.setContact = false;
                                if (this.domain.type !== 'Team' && this.is.toolSet === 'Project') {
                                    this.domain.type = 'Team';
                                    this.openTut('');
                                }
                                let title = parent.children[current].title;
                                if (this.usd.setProject.name !== '') {
                                    title = this.usd.setProject.name + ' > ' + this.usd.setProject.companyName + ' > '
                                    + parent.children[current].title;
                                }
                                return title;
                            } else if (parent.children[current].title === 'name proj-user') {
                                this.is.chatSet = true;
                                this.is.toolSet = 'Project';
                                this.setContact = false;
                                if (this.domain.type !== 'Team Member' && this.is.toolSet === 'Project') {
                                    this.domain.type = 'Team Member';
                                    this.openTut('');
                                }
                                return this.usd.setProject?.name + ' > ' + this.usd.setContact?.name;
                            } else if (parent.children[current].title === 'name team-user') {
                                this.is.chatSet = true;
                                this.is.toolSet = 'Project';
                                this.setContact = false;
                                return this.usd.setProject?.name + ' > ' + ' Company ' + ' > ' + this.usd.projectTeam?.name + ' > '
                                + this.usd.setContact?.name;
                            } else if (parent.children[current].title === 'My Diary') {
                                this.is.chatSet = false;
                                this.setContact = true;
                                this.is.toolSet = 'Personal';
                                const title = parent.children[current].title;
                                return title;
                            } else if (parent.children[current].title === 'Projects') {
                                this.is.chatSet = false;
                                this.setContact = true;
                                this.is.toolSet = 'Personal';
                                if (this.domain.type !== 'Projects List' && this.is.toolSet === 'Personal') {
                                    this.domain.type = 'Projects List';
                                    this.openTut('');
                                }
                                // const title = parent.title + '-' + 'Colours Project';
                                const title = titlee + '-' + 'Colours Project';
                                return title;
                            } else if (parent.children[current].title === 'Enterprises') {
                                this.is.toolSet = 'Personal';
                                if (this.domain.type !== 'Enterprise List' && this.is.toolSet === 'Personal') {
                                    this.domain.type = 'Enterprise List';
                                    this.openTut('');
                                }
                                this.is.chatSet = false;
                                this.setContact = true;
                                // const title = parent.title + '-' + 'Colours Enterprise';
                                const title = titlee + '-' + 'Colours Enterprise';
                                return title;
                            } else if (parent.children[current].title === 'My Classifications') {
                                this.is.toolSet = 'Personal';
                                this.is.chatSet = false;
                                this.setContact = true;
                                const title = 'Tasks 24/7 - Colours Personal';
                                return title;
                            } else if (parent.children[current].title === 'My Standards') {
                                this.is.toolSet = 'Personal';
                                this.is.chatSet = false;
                                this.setContact = true;
                                const title = 'Tasks 24/7 - Colours Personal';
                                return title;
                            } else if (parent.children[current].title === 'My Tasks') {
                                // this.setSessionStorage(parent.children[current].title);
                                this.titlee = 'My Tasks';
                                this.is.toolSet = 'Personal';
                                if (this.domain.type !== 'Personal' && this.is.toolSet === 'Personal') {
                                    this.domain.type = 'Personal';
                                    this.openTut('');
                                }
                                this.is.chatSet = false;
                                this.setContact = true;
                                const title = 'Tasks 24/7 - Colours Personal';
                                return title;
                            } else if (parent.children[current].title === 'My Contacts') {
                                this.is.toolSet = 'Personal';
                                if (this.domain.type !== 'My Contacts' && this.is.toolSet === 'Personal') {
                                    this.domain.type = 'My Contacts';
                                    this.openTut('');
                                }
                                this.is.chatSet = false;
                                this.setContact = true;
                                const title = parent.children[current].title;
                                return title;
                            } else if (parent.children[current].title === 'My Dashboard') {
                                this.is.toolSet = 'Personal';
                                this.is.chatSet = false;
                                this.setContact = true;
                                const title = 'Tasks 24/7 - Colours Personal';
                                return title;
                            } else {
                                this.is.toolSet = 'Personal';
                                this.is.chatSet = false;
                                this.setContact = true;
                                return parent.children[current].title;
                            }
                        }
                    }
                }
            }
        }
        return 'Colours';
    }

    addHistory(x) {
        !this.pastPages.find(i => i === x) ? this.pastPages.push(x) : this.pastPages = this.pastPages.filter(i => i !== x); this.pastPages.push(x);
        this.checkPage(x);
    }

    checkPage(x) {
        let snd = this.pastPages[this.pastPages?.length-2];
        if(x.includes('jschart')) {
            if(snd.includes('jschart')) {this.router.navigate(['/dashboard']).then(() => {
            setTimeout(() => {
                this.router.navigate([`${snd}`])
            }, 1000)
            })}
            this.usd.mainSize = 20; this.usd.mainShow = false;
        } else {
            this.usd.mainSize = 100; this.usd.mainShow = true;
    }
    }

    public setSessionStorage() {
        const pathCount = localStorage.getItem('showTut');
        if (!pathCount) {
            localStorage.setItem('showTut', 'true');
            console.log(pathCount);
        } else if (pathCount === 'true') {
            console.log(pathCount);
            return pathCount;
        } else if (pathCount === 'false') {
            console.log(pathCount);
            return pathCount;
        } else {
            console.log(pathCount);
        }
    }

//   public setSessionStorage() {
//     sessionStorage.setItem('notices', '4');
//     this.installAppNots = Number(sessionStorage.getItem('notices'));
//     if (!this.installAppNots) {
//         sessionStorage.setItem('notices', '2');
//     }
//     console.log(this.installAppNots);
// }

    getPath() {
        return this.location.prepareExternalUrl(this.location.path());
    }

    goToTasks24() {
        this.router.navigate(['/task24-7']);
    }
}
