import { Component, Input, OnInit } from '@angular/core';
import { Message } from '../../../../models/message';
import { User } from '../../../../models/user';
import * as moment from 'moment';
import { InitialiseService } from 'app/services/initialise.service';
import { Task } from 'app/models/task-model';
import { WriteService } from 'app/services/writes.service';
import { UserDataService } from 'app/services/user-data.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationService } from 'app/services/notification.service';

@Component({
  selector: 'app-chat-message',
  templateUrl: './chat-message.component.html',
  styleUrls: ['./chat-message.component.scss']
})

export class ChatMessageComponent implements OnInit {
  @Input() msg: Message;
  @Input() predecessor: Message;
  @Input() user: User;
  @Input() allowsReply = false;
  task: Task;

  constructor( public is: InitialiseService, public modalService: NgbModal, public ws: WriteService, public usd: UserDataService, public ns: NotificationService) {
    this.task = is.getTask();
  }

  ngOnInit() {}

  handleImgError = e => {
    e.target.src = this.ns.getImgs()
 }

  getDateDivider(msg: Message): string {
    if (!msg.createdAt) {
      return null;
    }
    return msg.createdAt.format('l');
  }

  getUserName(user: User): string {
    if (!user) {
      return null;
    }
    // return user.displayName;
    // console.log(user.name);
    return user.name;
  }

  getCreatedDate(msg: Message): string {
    if (!msg.createdAt) {
      return null;
    }
    return msg.createdAt.format('LT');
  }

  isPredecessorSameAuthor(): boolean {
    if (!this.predecessor) {
      return false;
    }
    return this.predecessor.id === this.msg.id;
  }

  isTemporalClose(): boolean {
    if (!this.predecessor) {
      return true;
    }

    const duration = moment.duration(
      this.msg.createdAt.diff(this.predecessor.createdAt)
    );
    return duration.asMinutes() <= 1;
  }

  isPreviousMessageFromOtherDay() {
    if (!this.predecessor) {
      return true;
    }
    const prevDate = this.predecessor.createdAt.day();
    const date = this.msg.createdAt.day();
    return prevDate !== date;
  }

  champ(id) {
    const gt = this.usd.chatParts.find(ts => ts.id === id);
    if (gt) {
      this.usd.task.champion = gt;
    }
  }
}
