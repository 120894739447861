import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

/* tools */
import * as moment from 'moment';
import { map, find } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Enterprise } from 'app/models/enterprise-model';

@Injectable({
  providedIn: 'root'
})
export class FixAndUpdatesService {

  constructor(public afs: AngularFirestore) {
    // this.fixsAndUpdates()
  }

  fixsAndUpdates() {
    // this.personalFindAndDrop();
    // this.projectsFindAndDrop();
    // this.findAndDrop();
    // this.findAndDropNots();
  }

  findAndDrop() {
    let count = 0
    this.afs.collection<any>('Users').valueChanges().subscribe(users => {
      users.forEach(element => {
        this.afs.collection('Users').doc(element.id).collection<any>('WeeklyActions').valueChanges().subscribe(acts => {
          // this.afs.collection('Users').doc(element.id).collection<any>('actionItems').valueChanges().subscribe(acts => {
          acts.forEach(act => {
            // if (act.name === '') {
            count++;
            this.afs.collection('tasks').doc(act.id).ref.get().then(dod => {
              if (dod.exists) {
                // console.log(count, ' ', act.name, ' ', 'already exists', element.name);
              } else {
                this.afs.collection('tasks').doc(act.id).set(act).then(() => {
                  // console.log(count, ' ', 'Added for', ' ', act.name, ' ', 'for', element.name);
                });
              }
            })
          });
        })
      });
    })
  }

  findAndDropNots() {
    let count = 0
    this.afs.collection<any>('Users')
    // .valueChanges()
    .snapshotChanges()
          .pipe(map(b => b.map(a => {
            const data = a.payload.doc.data() as any;
            data.id = a.payload.doc.id;
            return data;
          })))
    .subscribe(users => {
        users.forEach(element => {
          count++;
          this.afs.collection(`Users/${element.id}/tasks/`).snapshotChanges()
          .pipe(map(b => b.map(a => {
            const data = a.payload.doc.data() as any;
            data.id = a.payload.doc.id;
            return data;
          })))
          // .valueChanges()
          .subscribe(tsks => {
            this.getUserNtsks(element, tsks, count);
          })
          // this.notifications(element, count);
        });
      })
  }

  getUserNtsks(user, tsks: any[], count ) {
    const priority = {
      orderNo: 0,
      set: false
    }
    let cu = 0
    // console.log(user, tsks.length, 'nth user', count);
    tsks.forEach(element => {
      cu += 1
      // console.log('task', cu);
      if (element.priority === undefined) {
        element.priority = priority;
        this.taskPriority(user, element, count, cu);
      }
    });
  }

  taskPriority(user, task, userNo, tskNo) {
    this.afs.doc(`Users/${user.id}/tasks/${task.id}`).update(task).then(() => {
      console.log(task.name, task.description, 'priority updated');
    console.log(user.name, task.description, 'tskNo', tskNo, 'nth user', userNo);

    })
  }

  notifications(item, i) {
    console.log(item.name, item.id, i);
    this.afs.collection(`Users/${item.id}/taskNotification`)
      .valueChanges().subscribe(data => {
        if (data.length > 0) {
          data.forEach((element: any) => {
            this.afs.doc(`Users/${item.id}/taskNotification/${element.id}`).ref.get().then(ds => {
              if (ds.exists) {
                if (ds.data().read) { } else {
                  this.afs.doc(`Users/${item.id}/taskNotification/${element.id}`).update({ 'read': false })
                    .then(() => console.log('updated'))
                }
              }
            })
          });
        }
      });
    this.afs.collection(`Users/${item.id}/ProjectRequests`)
      .valueChanges().subscribe(data => {
        if (data.length > 0) {
          data.forEach((element: any) => {
            this.afs.doc(`Users/${item.id}/ProjectRequests/${element.id}`).ref.get().then(ds => {
              if (ds.exists) {
                if (ds.data().read) { } else {
                  this.afs.doc(`Users/${item.id}/ProjectRequests/${element.id}`).update({ 'read': false })
                    .then(() => console.log('updated'))
                }
              }
            })
          });
        }
      });
    this.afs.collection(`Users/${item.id}/EnterprisesRequests`)
      .valueChanges().subscribe(data => {
        if (data.length > 0) {
          data.forEach((element: any) => {
            this.afs.doc(`Users/${item.id}/EnterprisesRequests/${element.id}`).ref.get().then(ds => {
              if (ds.exists) {
                if (ds.data().read) { } else {
                  this.afs.doc(`Users/${item.id}/EnterprisesRequests/${element.id}`).update({ 'read': false })
                    .then(() => console.log('updated'))
                }
              }
            })
          });
        }
      });
    this.afs.collection(`Users/${item.id}/companyRemoveReq`)
      .valueChanges().subscribe(data => {
        if (data.length > 0) {
          data.forEach((element: any) => {
            this.afs.doc(`Users/${item.id}/companyRemoveReq/${element.id}`).ref.get().then(ds => {
              if (ds.exists) {
                if (ds.data().read) { } else {
                  this.afs.doc(`Users/${item.id}/companyRemoveReq/${element.id}`).update({ 'read': false })
                    .then(() => console.log('updated'))
                }
              }
            })
          });
        }
      });
    this.afs.collection(`Users/${item.id}/companyDeclines`)
      .valueChanges().subscribe(data => {
        if (data.length > 0) {
          data.forEach((element: any) => {
            this.afs.doc(`Users/${item.id}/companyDeclines/${element.id}`).ref.get().then(ds => {
              if (ds.exists) {
                if (ds.data().read) { } else {
                  this.afs.doc(`Users/${item.id}/companyDeclines/${element.id}`).update({ 'read': false })
                    .then(() => console.log('updated'))
                }
              }
            })
          });
        }
      });
    this.afs.collection(`Users/${item.id}/employeeExits`)
      .valueChanges().subscribe(data => {
        if (data.length > 0) {
          data.forEach((element: any) => {
            this.afs.doc(`Users/${item.id}/employeeExits/${element.id}`).ref.get().then(ds => {
              if (ds.exists) {
                if (ds.data().read) { } else {
                  this.afs.doc(`Users/${item.id}/employeeExits/${element.id}`).update({ 'read': false })
                    .then(() => console.log('updated'))
                }
              }
            })
          });
        }
      });
    this.afs.collection(`Users/${item.id}/companyRemApproval`)
      .valueChanges().subscribe(data => {
        if (data.length > 0) {
          data.forEach((element: any) => {
            this.afs.doc(`Users/${item.id}/companyRemApproval/${element.id}`).ref.get().then(ds => {
              if (ds.exists) {
                if (ds.data().read) { } else {
                  this.afs.doc(`Users/${item.id}/companyRemApproval/${element.id}`).update({ 'read': false })
                    .then(() => console.log('updated'))
                }
              }
            })
          });
        }
      });
  }

  projectsFindAndDrop() {
    let count = 0
    this.afs.collection<any>('Projects', ref => ref.orderBy('name', 'asc')).snapshotChanges().pipe(
      map(b => b.map(a => {
        const data = a.payload.doc.data() as Enterprise;
        data.id = a.payload.doc.id;
        return data;
      }))
    ).subscribe(pjs => {
      pjs.forEach(element => {
        this.afs.collection('Projects').doc(element.id).collection<any>('WeeklyActions', ref => ref.orderBy('name', 'asc'))
          .snapshotChanges().pipe(map(b => b.map(a => {
            const data = a.payload.doc.data() as any;
            data.id = a.payload.doc.id;
            return data;
          }))
          ).subscribe(acts => {
            acts.forEach(act => {
              console.log(count, ' ', act.name, 'Project', element.name);
              act.taskType = 'sub'
              count++;
              this.afs.collection('Projects').doc(element.id).collection('tasks').doc(act.id).ref.get().then(dod => {
                if (dod.exists) {
                  console.log(act.name, ' ', 'already exists', element.name);
                } else {
                  this.afs.collection('Projects').doc(element.id).collection('tasks').doc(act.id).set(act).then(() => {
                    console.log('Added', ' ', act.name, ' ', 'for', element.name);
                  });
                }
              })
            });
          })
      });
    })
  }

  personalFindAndDrop() {
    let count = 0
    this.afs.collection<any>('Users', ref => ref.orderBy('name', 'asc')).snapshotChanges().pipe(
      map(b => b.map(a => {
        const data = a.payload.doc.data() as Enterprise;
        const id = a.payload.doc.id;
        return data;
      }))
    ).subscribe(pjs => {
      pjs.forEach(element => {
        this.afs.collection('Users').doc(element.id).collection<any>('WeeklyActions', ref => ref.orderBy('name', 'asc'))
          .snapshotChanges().pipe(map(b => b.map(a => {
            const data = a.payload.doc.data() as any;
            data.id = a.payload.doc.id;
            return data;
          }))
          ).subscribe(acts => {
            acts.forEach(act => {
              console.log(count, ' ', act.name, 'User', element.name);
              act.taskType = 'sub'
              count++;
              this.afs.collection('Users').doc(element.id).collection('tasks').doc(act.id).ref.get().then(dod => {
                if (dod.exists) {
                  console.log(act.name, ' ', 'already exists', ' ', element.name);
                } else {
                  this.afs.collection('Users').doc(element.id).collection('tasks').doc(act.id).set(act).then(() => {
                    console.log('Added', ' ', act.name, ' ', 'for', element.name);
                  });
                }
              })
            });
          })
      });
    })
  }
}
