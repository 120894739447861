export const data = [
    {
        Account_Name: "Terence Kapuya-ZINARA",
        Stand_No: "2091",
        Size: "1033",
        Purchase_Price: "54749"
    },
    {
        Account_Name: "Nomvuyo Hillary Madziro",
        Stand_No: "2092",
        Size: "800",
        Purchase_Price: "41600"
    },
    {
        Account_Name: "Trymore Saini & Kudzai Matiza",
        Stand_No: "2093",
        Size: "800",
        Purchase_Price: "44000"
    },
    {
        Account_Name: "kudakwashe Raphael Manimanzi",
        Stand_No: "2096",
        Size: "800",
        Purchase_Price: "40000"
    },
    {
        Account_Name: "Robb Tapiwanashe Chiwoyo-Supplier",
        Stand_No: "2098",
        Size: "800",
        Purchase_Price: "33600"
    },
    {
        Account_Name: "Shelter Chiwoyo",
        Stand_No: "2099",
        Size: "800",
        Purchase_Price: "41328"
    },
    {
        Account_Name: "Wishes Mauwa-ZINARA",
        Stand_No: "2100",
        Size: "1001",
        Purchase_Price: "53053"
    },
    {
        Account_Name: "Gamuchirai Masoka",
        Stand_No: "2105",
        Size: "1070",
        Purchase_Price: "53500"
    },
    {
        Account_Name: "Gilfren Moyo & Olivia K Kuimba",
        Stand_No: "2106",
        Size: "1193",
        Purchase_Price: "64442"
    },
    {
        Account_Name: "N Tembo & L Bonongwa",
        Stand_No: "2108",
        Size: "1066",
        Purchase_Price: "56498"
    },
    {
        Account_Name: "Kudzayi Shawatu",
        Stand_No: "2109",
        Size: "706",
        Purchase_Price: "37418"
    },
    {
        Account_Name: "Zilda Gwandu",
        Stand_No: "2110",
        Size: "594",
        Purchase_Price: "35640"
    },
    {
        Account_Name: "BERAKAH TRUST-SUPPLIER",
        Stand_No: "2111",
        Size: "594",
        Purchase_Price: "44550"
    },
    {
        Account_Name: "Paradzayi Tagwireyi & Annah Zivumbwa",
        Stand_No: "2112",
        Size: "594",
        Purchase_Price: "33264"
    },
    {
        Account_Name: "Ian Meza & Ruby Ndondo",
        Stand_No: "2113",
        Size: "594",
        Purchase_Price: "33264"
    },
    {
        Account_Name: "Jeane Ruva Makoni",
        Stand_No: "2114",
        Size: "594",
        Purchase_Price: "33264"
    },
    {
        Account_Name: "Trymore Muderere & Asalder Hamandishe",
        Stand_No: "2120",
        Size: "868",
        Purchase_Price: "36456"
    },
    {
        Account_Name: "Derick Rubatsiro Kambarami",
        Stand_No: "2121",
        Size: "873",
        Purchase_Price: "43650"
    },
    {
        Account_Name: "Runyararo Cassidy Nyandoro",
        Stand_No: "2124",
        Size: "832",
        Purchase_Price: "37440"
    },
    {
        Account_Name: "Edmore and Kundai Chibwana",
        Stand_No: "2125",
        Size: "833",
        Purchase_Price: "58310"
    },
    {
        Account_Name: "Tendai Mugabe",
        Stand_No: "2127",
        Size: "500",
        Purchase_Price: "30000"
    },
    {
        Account_Name: "Kudzayi Shawatu",
        Stand_No: "2129",
        Size: "706",
        Purchase_Price: "37418"
    },
    {
        Account_Name: "Patson Ndlovu & Rejoice Blessing Ndlovu",
        Stand_No: "2130",
        Size: "868",
        Purchase_Price: "42532"
    },
    {
        Account_Name: "MALVERN MUNGATE & SUSAN TIYANI",
        Stand_No: "2131",
        Size: "594",
        Purchase_Price: "43362"
    },
    {
        Account_Name: "Pathias Tatenda Maigurira",
        Stand_No: "2132",
        Size: "594",
        Purchase_Price: "33264"
    },
    {
        Account_Name: "DR Einstein Genesis Masanga",
        Stand_No: "2133",
        Size: "594",
        Purchase_Price: "33264"
    },
    {
        Account_Name: "Christopher Solopa",
        Stand_No: "2134",
        Size: "594",
        Purchase_Price: "38610"
    },
    {
        Account_Name: "Natalia Levendale",
        Stand_No: "2135",
        Size: "701",
        Purchase_Price: "35050"
    },
    {
        Account_Name: "Jaensch Dorcas Mutede",
        Stand_No: "2136",
        Size: "594",
        Purchase_Price: "33264"
    },
    {
        Account_Name: "Munyaradzi   George & Rujeko Zvakadiwa Panget ex B",
        Stand_No: "2141",
        Size: "701",
        Purchase_Price: "39256"
    },
    {
        Account_Name: "Satchmore Tapera Manyande",
        Stand_No: "2142",
        Size: "706",
        Purchase_Price: "42360"
    },
    {
        Account_Name: "Caroline Mbofana",
        Stand_No: "2146",
        Size: "833",
        Purchase_Price: "46648"
    },
    {
        Account_Name: "Advance Majiwa & Kerima Musona",
        Stand_No: "2147",
        Size: "832",
        Purchase_Price: "46592"
    },
    {
        Account_Name: "Lawrence Chando",
        Stand_No: "2150",
        Size: "874",
        Purchase_Price: "45448"
    },
    {
        Account_Name: "Melody Rudaviro Nyajeka & Abraham Saratel Musevenz",
        Stand_No: "2152",
        Size: "1470",
        Purchase_Price: "76440"
    },
    {
        Account_Name: "Loretta Muzondi (Equipment World)",
        Stand_No: "2153",
        Size: "626",
        Purchase_Price: "30000"
    },
    {
        Account_Name: "Charles Macdonald & Itai Kahari",
        Stand_No: "2155",
        Size: "594",
        Purchase_Price: "28000"
    },
    {
        Account_Name: "Wilbert Marimo & TafadzwaChaitezvi",
        Stand_No: "2156",
        Size: "594",
        Purchase_Price: "31482"
    },
    {
        Account_Name: "Tichaona Tanyanyiwa and Noma Tanyanyiwa",
        Stand_No: "2157",
        Size: "594",
        Purchase_Price: "28000"
    },
    {
        Account_Name: "Hamunyare Relax Chinosengwa",
        Stand_No: "2158",
        Size: "594",
        Purchase_Price: "32670"
    },
    {
        Account_Name: "Sekai Charangwa",
        Stand_No: "2159",
        Size: "594",
        Purchase_Price: "35640"
    },
    {
        Account_Name: "Itai Noble Jongwe Masuka",
        Stand_No: "2161",
        Size: "594",
        Purchase_Price: "38610"
    },
    {
        Account_Name: "Elizabeth Njenje & Allan Hazvinryi Maruma",
        Stand_No: "2162",
        Size: "594",
        Purchase_Price: "35640"
    },
    {
        Account_Name: "Nomathemba Primrose Ndebele",
        Stand_No: "2164",
        Size: "687",
        Purchase_Price: "35728"
    },
    {
        Account_Name: "Angeline Mupinda",
        Stand_No: "2165",
        Size: "600",
        Purchase_Price: "28500"
    },
    {
        Account_Name: "Marshal Mutata",
        Stand_No: "2166",
        Size: "594",
        Purchase_Price: "33264"
    },
    {
        Account_Name: "Kevin  S Mutete& Kudzai P Mutete",
        Stand_No: "2169",
        Size: "594",
        Purchase_Price: "33264"
    },
    {
        Account_Name: "Margaret Kwekweza",
        Stand_No: "2170",
        Size: "594",
        Purchase_Price: "31482"
    },
    {
        Account_Name: "",
        Stand_No: "2171",
        Size: "696",
        Purchase_Price: "0"
    },
    {
        Account_Name: "Isyank Trust",
        Stand_No: "2172",
        Size: "857",
        Purchase_Price: "47992"
    },
    {
        Account_Name: "Sheena Getrude Havadi",
        Stand_No: "2174",
        Size: "594",
        Purchase_Price: "32670"
    },
    {
        Account_Name: "Hilda Machisa",
        Stand_No: "2175",
        Size: "594",
        Purchase_Price: "33264"
    },
    {
        Account_Name: "Marvin Makomo & Rutendo Sherekete",
        Stand_No: "2179",
        Size: "647",
        Purchase_Price: "36323"
    },
    {
        Account_Name: "PENELOPE MUTANGADURA",
        Stand_No: "2180",
        Size: "647",
        Purchase_Price: "36232"
    },
    {
        Account_Name: "Nyarai & Nhlalo Mlilo",
        Stand_No: "2185",
        Size: "594",
        Purchase_Price: "33264"
    },
    {
        Account_Name: "Trymore Muderere",
        Stand_No: "2188",
        Size: "729",
        Purchase_Price: "30618"
    },
    {
        Account_Name: "Tasungurwa July & Apolonia July",
        Stand_No: "2190",
        Size: "500",
        Purchase_Price: "28000"
    },
    {
        Account_Name: "Takudzwa M. Nyamukura",
        Stand_No: "2191",
        Size: "500",
        Purchase_Price: "28000"
    },
    {
        Account_Name: "THEOPHILUS MANDLA MUTSIGWA & RUDO MAKOMBE",
        Stand_No: "2192",
        Size: "500",
        Purchase_Price: "28000"
    },
    {
        Account_Name: "Faith V. Moyo",
        Stand_No: "2193",
        Size: "500",
        Purchase_Price: "28000"
    },
    {
        Account_Name: "Murambadoro Kurauone & Musatyira Judith",
        Stand_No: "2194",
        Size: "500",
        Purchase_Price: "28000"
    },
    {
        Account_Name: "Einstein G. Masanga",
        Stand_No: "2195",
        Size: "500",
        Purchase_Price: "28000"
    },
    {
        Account_Name: "Stephen & Mercy Marodza",
        Stand_No: "2196",
        Size: "500",
        Purchase_Price: "28000"
    },
    {
        Account_Name: "Dessie Wurayayi",
        Stand_No: "2197",
        Size: "500",
        Purchase_Price: "28000"
    },
    {
        Account_Name: "Maxwell Chatindo",
        Stand_No: "2198",
        Size: "500",
        Purchase_Price: "28000"
    },
    {
        Account_Name: "Tafadzwa Chirima",
        Stand_No: "2199",
        Size: "500",
        Purchase_Price: "28000"
    },
    {
        Account_Name: "Loice Makami",
        Stand_No: "2200",
        Size: "500",
        Purchase_Price: "28000"
    },
    {
        Account_Name: "Diana Chiridza",
        Stand_No: "2201",
        Size: "500",
        Purchase_Price: "28000"
    },
    {
        Account_Name: "NETSAI MIRINDA CHIRINDA ",
        Stand_No: "2202",
        Size: "500",
        Purchase_Price: "35000"
    },
    {
        Account_Name: "Joseph B & Sarudzai B Musiiwa",
        Stand_No: "2203",
        Size: "597",
        Purchase_Price: "28656"
    },
    {
        Account_Name: "The Mbatsi and Kin Trust",
        Stand_No: "2205",
        Size: "500",
        Purchase_Price: "18500"
    },
    {
        Account_Name: "The Mbatsi and Kin Trust-From Landowner",
        Stand_No: "2205",
        Size: "500",
        Purchase_Price: "18500"
    },
    {
        Account_Name: "Pilani Tlou",
        Stand_No: "2206",
        Size: "500",
        Purchase_Price: "28000"
    },
    {
        Account_Name: "(Kudakwashe Barnabas Sekere & Natasha Kerina MURAP",
        Stand_No: "2207",
        Size: "500",
        Purchase_Price: "28000"
    },
    {
        Account_Name: "Clemence MhangaraI & Zandile Sibanda",
        Stand_No: "2208",
        Size: "500",
        Purchase_Price: "28000"
    },
    {
        Account_Name: "Elson Maramba",
        Stand_No: "2209",
        Size: "500",
        Purchase_Price: "28000"
    },
    {
        Account_Name: "Tobias Mangwana",
        Stand_No: "2210",
        Size: "500",
        Purchase_Price: "28000"
    },
    {
        Account_Name: "Advocate Tawanda Chisunga",
        Stand_No: "2211",
        Size: "500",
        Purchase_Price: "27500"
    },
    {
        Account_Name: "Bridget Dongo",
        Stand_No: "2213",
        Size: "500",
        Purchase_Price: "18500"
    },
    {
        Account_Name: "Bridget Dongo",
        Stand_No: "2213",
        Size: "500",
        Purchase_Price: "18500"
    },
    {
        Account_Name: "Getrude,Lynda  Makumbe& Lorraine Sibanda",
        Stand_No: "2214",
        Size: "500",
        Purchase_Price: "28000"
    },
    {
        Account_Name: "Farai Chitaukire",
        Stand_No: "2215",
        Size: "500",
        Purchase_Price: "28000"
    },
    {
        Account_Name: "Muchenje Family Trust",
        Stand_No: "2216",
        Size: "500",
        Purchase_Price: "28000"
    },
    {
        Account_Name: "Simbarashe & Jenniffer Mudawapi",
        Stand_No: "2217",
        Size: "500",
        Purchase_Price: "28000"
    },
    {
        Account_Name: "Lorraine Rudo Zhandire Bhebhe-Bancabc",
        Stand_No: "2218",
        Size: "734",
        Purchase_Price: "51380"
    },
    {
        Account_Name: "Bonface & Rugare Mazuru",
        Stand_No: "2219",
        Size: "616",
        Purchase_Price: "36990"
    },
    {
        Account_Name: "Rohaz Family Trust",
        Stand_No: "2220",
        Size: "616",
        Purchase_Price: "34496"
    },
    {
        Account_Name: "P & B Mutizira",
        Stand_No: "2221",
        Size: "594",
        Purchase_Price: "34496"
    },
    {
        Account_Name: "Sibusisiwe Melusi",
        Stand_No: "2222",
        Size: "616",
        Purchase_Price: "34496"
    },
    {
        Account_Name: "Mucool Trust",
        Stand_No: "2223",
        Size: "616",
        Purchase_Price: "32648"
    },
    {
        Account_Name: "Mucool Trust",
        Stand_No: "2224",
        Size: "616",
        Purchase_Price: "32648"
    },
    {
        Account_Name: "Chido & Paidamoyo Chadoka",
        Stand_No: "2225",
        Size: "616",
        Purchase_Price: "34496"
    },
    {
        Account_Name: "leonard Nyamutsamba",
        Stand_No: "2227",
        Size: "738",
        Purchase_Price: "41328"
    },
    {
        Account_Name: "Tafadzwa Talent Tipih",
        Stand_No: "2228",
        Size: "616",
        Purchase_Price: "34496"
    },
    {
        Account_Name: "Sazilinah Makumbe",
        Stand_No: "2229",
        Size: "616",
        Purchase_Price: "34496"
    },
    {
        Account_Name: "Inyasha Trust-Diesel",
        Stand_No: "2230",
        Size: "616",
        Purchase_Price: "18480"
    },
    {
        Account_Name: "Inyasha Trust-Diesel",
        Stand_No: "2231",
        Size: "616",
        Purchase_Price: "18480"
    },
    {
        Account_Name: "Inyasha Trust-Diesel",
        Stand_No: "2232",
        Size: "616",
        Purchase_Price: "18480"
    },
    {
        Account_Name: "Inyasha Trust-Diesel",
        Stand_No: "2233",
        Size: "638",
        Purchase_Price: "18480"
    },
    {
        Account_Name: "Inyasha Trust-Diesel",
        Stand_No: "2234",
        Size: "616",
        Purchase_Price: "18480"
    },
    {
        Account_Name: "LEONARD NYAMUTSAMBA",
        Stand_No: "2235",
        Size: "738",
        Purchase_Price: "41328"
    },
    {
        Account_Name: "BANCABC",
        Stand_No: "2252",
        Size: "0",
        Purchase_Price: "0"
    },
    {
        Account_Name: "Rita Saurombe",
        Stand_No: "2261",
        Size: "594",
        Purchase_Price: "33264"
    },
    {
        Account_Name: "TRUST & CATRIONA KARUMAZONDO",
        Stand_No: "2262",
        Size: "594",
        Purchase_Price: "33264"
    },
    {
        Account_Name: "Charles Matauya",
        Stand_No: "2263",
        Size: "594",
        Purchase_Price: "33264"
    },
    {
        Account_Name: "Fara & Chipo Katsande",
        Stand_No: "2264",
        Size: "500",
        Purchase_Price: "33264"
    },
    {
        Account_Name: "Ishmel Gwede & Caroline Kudzai Nzvere",
        Stand_No: "2265",
        Size: "594",
        Purchase_Price: "33264"
    },
    {
        Account_Name: "Tanyaradzwa Makombe",
        Stand_No: "2266",
        Size: "594",
        Purchase_Price: "33264"
    },
    {
        Account_Name: "TAWANDA KUDZAI CHIRUMBWANA",
        Stand_No: "2268",
        Size: "594",
        Purchase_Price: "41580"
    },
    {
        Account_Name: "Thombizodwa Dangala-MODUS",
        Stand_No: "2269",
        Size: "594",
        Purchase_Price: "33264"
    },
    {
        Account_Name: "Mafion Shito & Ropafadzo Zhou",
        Stand_No: "2270",
        Size: "594",
        Purchase_Price: "31482"
    },
    {
        Account_Name: "Jeane Ruva Makoni",
        Stand_No: "2271",
        Size: "701",
        Purchase_Price: "38756"
    },
    {
        Account_Name: "RUNESU OBIAS VARAIGWAI",
        Stand_No: "2274",
        Size: "594",
        Purchase_Price: "41580"
    },
    {
        Account_Name: "Azironi Mhari & Thabeth Sanangura",
        Stand_No: "2276",
        Size: "680",
        Purchase_Price: "38080"
    },
    {
        Account_Name: "Trust Kudangirana",
        Stand_No: "2281",
        Size: "594",
        Purchase_Price: "41580"
    },
    {
        Account_Name: "Simon Maxwell Musona-(Plumber shamba)",
        Stand_No: "2283",
        Size: "594",
        Purchase_Price: "27000"
    },
    {
        Account_Name: "(Gumiso Trust)-Transearth",
        Stand_No: "2284",
        Size: "594",
        Purchase_Price: "34000"
    },
    {
        Account_Name: "Justice Chivandire",
        Stand_No: "2285",
        Size: "594",
        Purchase_Price: "33264"
    },
    {
        Account_Name: "Phillipah Mawaraidzo Rambanapasi",
        Stand_No: "2286",
        Size: "594",
        Purchase_Price: "33264"
    },
    {
        Account_Name: "Alice Chipo Nyamugama",
        Stand_No: "2287",
        Size: "680",
        Purchase_Price: "44200"
    },
    {
        Account_Name: "Yvonne T. Chifamba",
        Stand_No: "2309",
        Size: "599",
        Purchase_Price: "28752"
    },
    {
        Account_Name: "",
        Stand_No: "2325",
        Size: "0",
        Purchase_Price: "0"
    },
    {
        Account_Name: "Falt Family Trust",
        Stand_No: "2327",
        Size: "707",
        Purchase_Price: "44000"
    },
    {
        Account_Name: "Inyasha Trust-Diesel",
        Stand_No: "2328",
        Size: "638",
        Purchase_Price: "19140"
    },
    {
        Account_Name: "Inyasha Trust-Diesel",
        Stand_No: "2329",
        Size: "638",
        Purchase_Price: "19140"
    },
    {
        Account_Name: "Shelter Chiwoyo-supplier",
        Stand_No: "2335",
        Size: "738",
        Purchase_Price: "30996"
    },
    {
        Account_Name: "Benjamin Mhlanga and Chipo Makutya",
        Stand_No: "2336",
        Size: "638",
        Purchase_Price: "35728"
    },
    {
        Account_Name: "Tawanda Marenga-ZINARA",
        Stand_No: "2337",
        Size: "707",
        Purchase_Price: "42420"
    },
    {
        Account_Name: "Morris Chekai Hove",
        Stand_No: "2338",
        Size: "707",
        Purchase_Price: "35350"
    },
    {
        Account_Name: "Richard Kanhema",
        Stand_No: "2339",
        Size: "638",
        Purchase_Price: "35728"
    },
    {
        Account_Name: "Elphanos & Loice Zvizvai",
        Stand_No: "2340",
        Size: "638",
        Purchase_Price: "38280"
    },
    // {
    //     Account_Name: "The Lina Shoko Family Trust",
    //     Stand_No: "2341",
    //     Size: "638",
    //     Purchase_Price: "44600"
    // },
    // {
    //     Account_Name: "Tafadzwa Joel Ngorima",
    //     Stand_No: "2341",
    //     Size: "638",
    //     Purchase_Price: "35728"
    // },
    {
        Account_Name: "Phillipah Mawaraidzo Rambanapasi",
        Stand_No: "2342",
        Size: "638",
        Purchase_Price: "35728"
    },
    {
        Account_Name: "Inyasha Trust-Diesel",
        Stand_No: "2346",
        Size: "638",
        Purchase_Price: "19140"
    },
    {
        Account_Name: "Inyasha Trust-Diesel",
        Stand_No: "2347",
        Size: "638",
        Purchase_Price: "19140"
    },
    {
        Account_Name: "Richard & Elizabeth Chakweya",
        Stand_No: "2348",
        Size: "707",
        Purchase_Price: "35350"
    },
    {
        Account_Name: "CLEOPATRA NYASHA MATANHIRE",
        Stand_No: "2350",
        Size: "500",
        Purchase_Price: "18500"
    },
    {
        Account_Name: "Panganai Artwell Madara",
        Stand_No: "2351",
        Size: "500",
        Purchase_Price: "28000"
    },
    {
        Account_Name: "Lillian Rakafa & Robert Chikombora",
        Stand_No: "2352",
        Size: "500",
        Purchase_Price: "30000"
    },
    {
        Account_Name: "Bernard & Margaret Mberi",
        Stand_No: "2353",
        Size: "500",
        Purchase_Price: "28000"
    },
    {
        Account_Name: "Renwick & Nicollete A. Wachenyuka",
        Stand_No: "2355",
        Size: "500",
        Purchase_Price: "28000"
    },
    {
        Account_Name: "Mupita Munyaradzi & Gatsi Abigal June",
        Stand_No: "2356",
        Size: "500",
        Purchase_Price: "26000"
    },
    {
        Account_Name: "Mubayi Ephraim Tariro",
        Stand_No: "2360",
        Size: "500",
        Purchase_Price: "30000"
    },
    {
        Account_Name: "Bright Munyaradzi Maliti",
        Stand_No: "2363",
        Size: "500",
        Purchase_Price: "30000"
    },
    {
        Account_Name: "Sheila Rusere",
        Stand_No: "2368",
        Size: "578",
        Purchase_Price: "32368"
    },
    {
        Account_Name: "Charity Takaendesa",
        Stand_No: "2369",
        Size: "500",
        Purchase_Price: "26000"
    },
    {
        Account_Name: "Linda Chekai",
        Stand_No: "2373",
        Size: "500",
        Purchase_Price: "27000"
    },
    {
        Account_Name: "Yeukai Maurren Mashingaidze",
        Stand_No: "2380",
        Size: "500",
        Purchase_Price: "20000"
    },
    {
        Account_Name: "Lorencia Chigweshe",
        Stand_No: "2383",
        Size: "500",
        Purchase_Price: "28000"
    },
    {
        Account_Name: "Duncan M Kiara & Loice G T Gurupira",
        Stand_No: "2384",
        Size: "500",
        Purchase_Price: "26000"
    },
    {
        Account_Name: "Bernadette T Gurupira",
        Stand_No: "2385",
        Size: "500",
        Purchase_Price: "26000"
    },
    {
        Account_Name: "Lewis G M Gurupira & Natsai R Gurupira",
        Stand_No: "2386",
        Size: "500",
        Purchase_Price: "26000"
    },
    {
        Account_Name: "Rudo Zimani & Monica Jera",
        Stand_No: "2387",
        Size: "500",
        Purchase_Price: "20000"
    },
    {
        Account_Name: "The Nyamatsatse  Family Trust",
        Stand_No: "2388",
        Size: "500",
        Purchase_Price: "20000"
    },
    {
        Account_Name: "Tendai Mugabe",
        Stand_No: "2391",
        Size: "500",
        Purchase_Price: "30000"
    },
    {
        Account_Name: "Mafudzi Blessing Chipoyera",
        Stand_No: "2392",
        Size: "500",
        Purchase_Price: "30000"
    },
    {
        Account_Name: "Elvis Kanyira",
        Stand_No: "2393",
        Size: "500",
        Purchase_Price: "30000"
    },
    {
        Account_Name: "VIEW MUZITE",
        Stand_No: "2394",
        Size: "500",
        Purchase_Price: "30000"
    },
    {
        Account_Name: "Wilson and Getrude Mutekede",
        Stand_No: "395A",
        Size: "500",
        Purchase_Price: "36500"
    },
    {
        Account_Name: "Chiedza Elizabeth Chagaka",
        Stand_No: "2395",
        Size: "500",
        Purchase_Price: "32500"
    },
    {
        Account_Name: "Barbaka Kadyaridzire",
        Stand_No: "2396",
        Size: "500",
        Purchase_Price: "30000"
    },
    {
        Account_Name: "Tolerence Mlambo",
        Stand_No: "2397",
        Size: "500",
        Purchase_Price: "32500"
    },
    {
        Account_Name: "Talent Chihuri & Progress Mukwenje",
        Stand_No: "2398",
        Size: "500",
        Purchase_Price: "30000"
    },
    {
        Account_Name: "Vaida Kamuzizwa",
        Stand_No: "2399",
        Size: "500",
        Purchase_Price: "30000"
    },
    {
        Account_Name: "Peter Hunguru",
        Stand_No: "2400",
        Size: "500",
        Purchase_Price: "30000"
    },
    {
        Account_Name: "Maslin Nyamande-Supplier",
        Stand_No: "2401",
        Size: "500",
        Purchase_Price: "30000"
    },
    {
        Account_Name: "Chenjerai Kelvin & Kudakwashe N Medzai",
        Stand_No: "2402",
        Size: "500",
        Purchase_Price: "30000"
    },
    {
        Account_Name: "Jackson Shadaya",
        Stand_No: "2403",
        Size: "696",
        Purchase_Price: "38976"
    },
    {
        Account_Name: "Shadreck Pfende",
        Stand_No: "2404",
        Size: "696",
        Purchase_Price: "41760"
    },
    {
        Account_Name: "Thelma Mutete",
        Stand_No: "2406",
        Size: "500",
        Purchase_Price: "36500"
    },
    {
        Account_Name: "Tawanda Lancelot Chitima",
        Stand_No: "2407",
        Size: "500",
        Purchase_Price: "32500"
    },
    {
        Account_Name: "TITUS MATIZA",
        Stand_No: "2408",
        Size: "500",
        Purchase_Price: "23811"
    },
    {
        Account_Name: "NEBSON & RUDO TECLA MUPUNGA",
        Stand_No: "2409",
        Size: "500",
        Purchase_Price: "37500"
    },
    {
        Account_Name: "Trish Jasi",
        Stand_No: "2410",
        Size: "500",
        Purchase_Price: "37500"
    },
    {
        Account_Name: "Flavian T Machimbike",
        Stand_No: "2411",
        Size: "500",
        Purchase_Price: "30000"
    },
    {
        Account_Name: "Muunyaradzi Marara",
        Stand_No: "2412",
        Size: "500",
        Purchase_Price: "30000"
    },
    {
        Account_Name: "Christain Ndoro",
        Stand_No: "2413",
        Size: "500",
        Purchase_Price: "32500"
    },
    {
        Account_Name: "TARIRO MROYIWA (PURCHASE OF JEEP)",
        Stand_No: "2414",
        Size: "500",
        Purchase_Price: "37500"
    },
    {
        Account_Name: "Faith Ruvimbo Chimbambo",
        Stand_No: "2416",
        Size: "500",
        Purchase_Price: "30000"
    },
    {
        Account_Name: "MIRACLE GIANNA SITHOLE & FAN XUHANG",
        Stand_No: "2417",
        Size: "365",
        Purchase_Price: "27375"
    },
    {
        Account_Name: "Fanzanne Nyamayaro Family Trust Represented by F Nyamayaro",
        Stand_No: "2424",
        Size: "500",
        Purchase_Price: "37500"
    },
    {
        Account_Name: "Blessing Mugocha & Alexinah Dembedza",
        Stand_No: "2425",
        Size: "507",
        Purchase_Price: "38025"
    },
    {
        Account_Name: "Joel Blazio Masuwa & Sekai Mawire",
        Stand_No: "2431",
        Size: "614",
        Purchase_Price: "36840"
    },
    {
        Account_Name: "Obert Gonye",
        Stand_No: "2439",
        Size: "710",
        Purchase_Price: "46150"
    },
    {
        Account_Name: "Sydney Phiri",
        Stand_No: "2446",
        Size: "575",
        Purchase_Price: "34500"
    },
    {
        Account_Name: "Vimbai Chitakunye",
        Stand_No: "2447",
        Size: "500",
        Purchase_Price: "30000"
    },
    // {
    //     Account_Name: "Priveledge and Lorna Ndlovu",
    //     Stand_No: "2449",
    //     Size: "500",
    //     Purchase_Price: "32500"
    // },
    // {
    //     Account_Name: "Welby & Rumbidzai Paul Nyamanza",
    //     Stand_No: "2449",
    //     Size: "500",
    //     Purchase_Price: "30000"
    // },
    {
        Account_Name: "Welby & Rumbidzai Paul Nyamanza",
        Stand_No: "2454",
        Size: "500",
        Purchase_Price: "30000"
    },
    {
        Account_Name: "",
        Stand_No: "2455",
        Size: "500",
        Purchase_Price: "30000"
    },
    {
        Account_Name: "SHARON MUNDAWARO",
        Stand_No: "2456",
        Size: "500",
        Purchase_Price: "32500"
    },
    {
        Account_Name: "R M Family Trust",
        Stand_No: "2459",
        Size: "599",
        Purchase_Price: "35940"
    },
    {
        Account_Name: "R M Family Trust",
        Stand_No: "2460",
        Size: "554",
        Purchase_Price: "33240"
    },
    {
        Account_Name: "EMMROL TRUST",
        Stand_No: "2464",
        Size: "711",
        Purchase_Price: "53325"
    },
    {
        Account_Name: "FELISTAS MANDIZVIDZA",
        Stand_No: "2472",
        Size: "639",
        Purchase_Price: "38340"
    },
    {
        Account_Name: "Gracious Chikozho",
        Stand_No: "2480",
        Size: "500",
        Purchase_Price: "36000"
    },
    {
        Account_Name: "Blessing Emmanuel & Caroline Kamutema",
        Stand_No: "2481",
        Size: "500",
        Purchase_Price: "30000"
    },
    {
        Account_Name: "GRACIOUS CHIKOZHO ",
        Stand_No: "2484",
        Size: "500",
        Purchase_Price: "36000"
    },
    {
        Account_Name: "Tauya & Hildah Moyo",
        Stand_No: "2485",
        Size: "500",
        Purchase_Price: "32500"
    },
    {
        Account_Name: "Olivia Nyaradzo Tsikayi",
        Stand_No: "2486",
        Size: "500",
        Purchase_Price: "31720"
    },
    {
        Account_Name: "Delight Makeyi Wadyajena",
        Stand_No: "2487",
        Size: "500",
        Purchase_Price: "32500"
    },
    {
        Account_Name: "Edmore Taruvinga",
        Stand_No: "2488",
        Size: "500",
        Purchase_Price: "32500"
    },
    {
        Account_Name: "Munyaradzi Innocent Muzanamombe",
        Stand_No: "2489",
        Size: "500",
        Purchase_Price: "30000"
    },
    {
        Account_Name: "Levina Trust",
        Stand_No: "2491",
        Size: "829",
        Purchase_Price: "33160"
    },
    {
        Account_Name: "Isabelle Tatenda Kunzinya",
        Stand_No: "2492",
        Size: "677",
        Purchase_Price: "44682"
    },
    {
        Account_Name: "",
        Stand_No: "2493",
        Size: "560",
        Purchase_Price: "0"
    },
    {
        Account_Name: "Tauya & Hildah Moyo",
        Stand_No: "2494",
        Size: "500",
        Purchase_Price: "32500"
    },
    {
        Account_Name: "Michel Choto",
        Stand_No: "2495",
        Size: "500",
        Purchase_Price: "32500"
    },
    // {
    //     Account_Name: "Fungayi Tundu Spencer Mumme and Matthew Spencer Mumme",
    //     Stand_No: "2496",
    //     Size: "500",
    //     Purchase_Price: "37500"
    // },
    // {
    //     Account_Name: "Michel Choto",
    //     Stand_No: "2496",
    //     Size: "500",
    //     Purchase_Price: "32500"
    // },
    {
        Account_Name: "DELIGHT MAKEYI WADYAJENA",
        Stand_No: "2499",
        Size: "534",
        Purchase_Price: "34150"
    },
    {
        Account_Name: "Darlington Nyabinde & Pamela",
        Stand_No: "2506",
        Size: "512",
        Purchase_Price: "33280"
    },
    {
        Account_Name: "Mavis Musa",
        Stand_No: "2509",
        Size: "503",
        Purchase_Price: "37725"
    },
    {
        Account_Name: "Rufaro Mandy Muzengi",
        Stand_No: "2510",
        Size: "500",
        Purchase_Price: "35000"
    },
    {
        Account_Name: "Tisungane Family Trust",
        Stand_No: "2511",
        Size: "500",
        Purchase_Price: "35000"
    },
    {
        Account_Name: "Augustine Kupakwashe Makani",
        Stand_No: "2514",
        Size: "500",
        Purchase_Price: "35000"
    },
    {
        Account_Name: "Amos Mahundi",
        Stand_No: "2516",
        Size: "500",
        Purchase_Price: "32500"
    },
    {
        Account_Name: "LIVINGSTONE KAZIZI",
        Stand_No: "2517",
        Size: "514",
        Purchase_Price: "51400"
    },
    {
        Account_Name: "Dewberry Incorporated rep Samantha Shayanewako",
        Stand_No: "2518",
        Size: "514",
        Purchase_Price: "35980"
    },
    {
        Account_Name: "BRUCE LEE DHAURE",
        Stand_No: "2520",
        Size: "450",
        Purchase_Price: "33750"
    },
    {
        Account_Name: "",
        Stand_No: "2530",
        Size: "500",
        Purchase_Price: "0"
    },
    {
        Account_Name: "Eric Rekayi Maunze & Melody Editor Muunze",
        Stand_No: "2533",
        Size: "500",
        Purchase_Price: "33500"
    },
    {
        Account_Name: "MARTHA T CHIRIPASI & KELVIN T KUTSAWA",
        Stand_No: "2534",
        Size: "500",
        Purchase_Price: "27000"
    },
    {
        Account_Name: "Debra Nyathi",
        Stand_No: "2537",
        Size: "500",
        Purchase_Price: "35000"
    },
    {
        Account_Name: "Joseph K Nyaruwata",
        Stand_No: "2538",
        Size: "500",
        Purchase_Price: "33000"
    },
    {
        Account_Name: "Joyce Machinguta",
        Stand_No: "2539",
        Size: "500",
        Purchase_Price: "33000"
    },
    {
        Account_Name: "MIETANI CHAUKE",
        Stand_No: "2542",
        Size: "568",
        Purchase_Price: "39750"
    },
    {
        Account_Name: "Tapiwa Tarugarira & Mose Manyumbu",
        Stand_No: "2543",
        Size: "436",
        Purchase_Price: "32700"
    },
    {
        Account_Name: "Margaret Kanyai",
        Stand_No: "2544",
        Size: "518",
        Purchase_Price: "36260"
    },
    {
        Account_Name: "Emmanuel Mbengo",
        Stand_No: "2546",
        Size: "519",
        Purchase_Price: "38295"
    },
    {
        Account_Name: "clayton Mushati",
        Stand_No: "2550",
        Size: "515",
        Purchase_Price: "36050"
    },
    {
        Account_Name: "Dr Einstein Genesis Masanga",
        Stand_No: "2555",
        Size: "644",
        Purchase_Price: "38640"
    },
    {
        Account_Name: "ISYANK TRUST AND ABIGAIL TAHWA",
        Stand_No: "2558",
        Size: "500",
        Purchase_Price: "37500"
    },
    {
        Account_Name: "RALPH MARIME AND RUMBIDZAI V. CHENGAOSE",
        Stand_No: "2559",
        Size: "500",
        Purchase_Price: "37500"
    },
    {
        Account_Name: "TAWANDA & TALENT RUKUNI",
        Stand_No: "2563",
        Size: "500",
        Purchase_Price: "32500"
    },
    {
        Account_Name: "Gilbert Muhwati",
        Stand_No: "2564",
        Size: "500",
        Purchase_Price: "35000"
    },
    {
        Account_Name: "Patience Musa",
        Stand_No: "2566",
        Size: "697",
        Purchase_Price: "37000"
    },
    {
        Account_Name: "Grace Judith Family Trust",
        Stand_No: "2567",
        Size: "500",
        Purchase_Price: "35000"
    },
    {
        Account_Name: "Collins & Tinashe Mahadzva",
        Stand_No: "2568",
        Size: "500",
        Purchase_Price: "35000"
    },
    {
        Account_Name: "Reason Sibanda",
        Stand_No: "2572",
        Size: "500",
        Purchase_Price: "35000"
    },
    {
        Account_Name: "Reason Sibanda",
        Stand_No: "2573",
        Size: "500",
        Purchase_Price: "35000"
    },
    {
        Account_Name: "",
        Stand_No: "2574",
        Size: "500",
        Purchase_Price: "0"
    },
    {
        Account_Name: "Tsitsi Adelaide Mabvumbe",
        Stand_No: "2575",
        Size: "500",
        Purchase_Price: "32500"
    },
    {
        Account_Name: "Kelvin & Kudzai Mutete",
        Stand_No: "2576",
        Size: "500",
        Purchase_Price: "28000"
    },
    {
        Account_Name: "tinashe zenda & motion v zenda",
        Stand_No: "2577",
        Size: "500",
        Purchase_Price: "35000"
    },
    // {
    //     Account_Name: "Stanley Shaninga",
    //     Stand_No: "2578",
    //     Size: "500",
    //     Purchase_Price: "35000"
    // },
    // {
    //     Account_Name: "Wellington Mandizvidza",
    //     Stand_No: "2578",
    //     Size: "500",
    //     Purchase_Price: "25000"
    // },
    {
        Account_Name: "Zvikomborero Rodney Nyamakura and Rutendo Maria Guvamatanga",
        Stand_No: "2579",
        Size: "512",
        Purchase_Price: "38400"
    },
    {
        Account_Name: "Zvikomborero Rodney Nyamakura and Rutendo Maria Guvamatanga",
        Stand_No: "2580",
        Size: "569",
        Purchase_Price: "42675"
    },
    {
        Account_Name: "Elizabeth A Maphosa",
        Stand_No: "2581",
        Size: "548",
        Purchase_Price: "38360"
    },
    {
        Account_Name: "Masline Nyamande",
        Stand_No: "2582",
        Size: "504",
        Purchase_Price: "30000"
    },
    {
        Account_Name: "SARUDZAI FAMILY TRUST",
        Stand_No: "2583",
        Size: "500",
        Purchase_Price: "30000"
    },
    {
        Account_Name: "Lizinnet Gororo",
        Stand_No: "2584",
        Size: "500",
        Purchase_Price: "30000"
    },
    {
        Account_Name: "",
        Stand_No: "2585",
        Size: "0",
        Purchase_Price: "0"
    },
    {
        Account_Name: "Charity Memory Chishawa",
        Stand_No: "2587",
        Size: "500",
        Purchase_Price: "32350"
    },
    {
        Account_Name: "Tafadzwa Lansana Mupfurutsa",
        Stand_No: "2588",
        Size: "513",
        Purchase_Price: "30780"
    },
    {
        Account_Name: "GODWIN JNR SHAYNE MUZAMHINDO",
        Stand_No: "2589",
        Size: "513",
        Purchase_Price: "38475"
    },
    {
        Account_Name: "Batsirai Chembere",
        Stand_No: "2589",
        Size: "513",
        Purchase_Price: "30780"
    },
    {
        Account_Name: "Sydney Chivambe & Hellen Phiri",
        Stand_No: "2590",
        Size: "500",
        Purchase_Price: "35000"
    },
    {
        Account_Name: "Clayton Mushati",
        Stand_No: "2591",
        Size: "500",
        Purchase_Price: "35000"
    },
    {
        Account_Name: "Gailord Chidindi",
        Stand_No: "2592",
        Size: "500",
        Purchase_Price: "37500"
    },
    {
        Account_Name: "Virginia Wushe",
        Stand_No: "2593",
        Size: "500",
        Purchase_Price: "32500"
    },
    {
        Account_Name: "SUCCESS KAMJOMA-SPECIAL CONSDERATION",
        Stand_No: "2594",
        Size: "500",
        Purchase_Price: "25000"
    },
    {
        Account_Name: "Success kamjoma",
        Stand_No: "2594",
        Size: "500",
        Purchase_Price: "25000"
    },
    {
        Account_Name: "SOPHIA NYAMUDEZA",
        Stand_No: "2596",
        Size: "590",
        Purchase_Price: "41300"
    },
    {
        Account_Name: "Richard Mapfumo-Phase 2",
        Stand_No: "2597",
        Size: "500",
        Purchase_Price: "30000"
    },
    {
        Account_Name: "Tungamirai C and Cheryl N Mhuka",
        Stand_No: "2604",
        Size: "386",
        Purchase_Price: "28950"
    },
    {
        Account_Name: "Molly Nyembezi Chiororo",
        Stand_No: "2618",
        Size: "548",
        Purchase_Price: "38360"
    },
    {
        Account_Name: "Bryton and Rudo Nyabereka",
        Stand_No: "2619",
        Size: "529",
        Purchase_Price: "39675"
    },
    {
        Account_Name: "Rumbidza Muzwidziwa & Tamuka Bvuma",
        Stand_No: "2620",
        Size: "500",
        Purchase_Price: "35000"
    },
    {
        Account_Name: "Tonderai",
        Stand_No: "2635",
        Size: "500",
        Purchase_Price: "25000"
    },
    {
        Account_Name: "MICHEAL KUDAKWASHE TANATSWA KUSEKA-SPECIAL CONSDERATION",
        Stand_No: "2636",
        Size: "500",
        Purchase_Price: "25000"
    },
    {
        Account_Name: "Michael Kuseka-Special Consideration",
        Stand_No: "2636",
        Size: "500",
        Purchase_Price: "25000"
    },
    {
        Account_Name: "Tabeth Sikireta & Pfungwa Sikireta",
        Stand_No: "2637",
        Size: "540",
        Purchase_Price: "37800"
    },
    {
        Account_Name: "Lorraine Manda",
        Stand_No: "2639",
        Size: "500",
        Purchase_Price: "35000"
    },
    {
        Account_Name: "Mapfumo & Rosina Matimba",
        Stand_No: "2640",
        Size: "500",
        Purchase_Price: "35000"
    },
    {
        Account_Name: "Wellington Mungai",
        Stand_No: "2641",
        Size: "680",
        Purchase_Price: "31110"
    },
    {
        Account_Name: "MALVERN MATONGO",
        Stand_No: "2643",
        Size: "509",
        Purchase_Price: "38175"
    },
    {
        Account_Name: "Benny Dextor Pophiwa",
        Stand_No: "2653",
        Size: "500",
        Purchase_Price: "35000"
    },
    {
        Account_Name: "TICHAONA BOSWELL MSWAZI & DOLPHINY ZIYAMBI",
        Stand_No: "2655",
        Size: "620",
        Purchase_Price: "43400"
    },
    {
        Account_Name: "TICHAONA BOSWELL & DOPHINY ZIYAMBI",
        Stand_No: "2656",
        Size: "469",
        Purchase_Price: "32830"
    },
    {
        Account_Name: "Juliet Lizzy Homodza",
        Stand_No: "2657",
        Size: "500",
        Purchase_Price: "35000"
    },
    {
        Account_Name: "Sibongile Rukazhanga",
        Stand_No: "2658",
        Size: "513",
        Purchase_Price: "35910"
    },
    {
        Account_Name: "Tirivani Brian Chimbani",
        Stand_No: "2659",
        Size: "515",
        Purchase_Price: "35910"
    },
    {
        Account_Name: "Tamangani Brighton",
        Stand_No: "2660",
        Size: "549",
        Purchase_Price: "32940"
    },
    {
        Account_Name: "Clement Nengomasha & Rutendo S. Chivheya",
        Stand_No: "2666",
        Size: "595",
        Purchase_Price: "42245"
    },
    {
        Account_Name: "Bianca Dzwairo",
        Stand_No: "2669",
        Size: "500",
        Purchase_Price: "35000"
    },
    {
        Account_Name: "Aryella Foundation",
        Stand_No: "2670",
        Size: "500",
        Purchase_Price: "37500"
    },
    {
        Account_Name: "ARYELLA FOUNDATION ",
        Stand_No: "2671",
        Size: "549",
        Purchase_Price: "41175"
    },
    {
        Account_Name: "Joel Blazio Masuwa & Sekai Mawire",
        Stand_No: "2676",
        Size: "556",
        Purchase_Price: "70200"
    },
    {
        Account_Name: "Elvis M Matiza",
        Stand_No: "2691",
        Size: "500",
        Purchase_Price: "22500"
    },
    {
        Account_Name: "Tennyson Chifamba",
        Stand_No: "2692",
        Size: "500",
        Purchase_Price: "32500"
    },
    {
        Account_Name: "RAYMOND MADZIVANYIKA",
        Stand_No: "2697",
        Size: "500",
        Purchase_Price: "35000"
    },
    {
        Account_Name: "Cyril kudakwashe & Sandra Mary Muhwati",
        Stand_No: "2701",
        Size: "500",
        Purchase_Price: "35000"
    },
    {
        Account_Name: "Goliath & Otalia Gwese",
        Stand_No: "2705",
        Size: "500",
        Purchase_Price: "37500"
    },
    {
        Account_Name: "Virginia Vushe",
        Stand_No: "2706",
        Size: "500",
        Purchase_Price: "35000"
    },
    {
        Account_Name: "Tavon Family Trust",
        Stand_No: "2729",
        Size: "556",
        Purchase_Price: "41700"
    },
    {
        Account_Name: "Hope,Tarisai Marere & Esther Antonio",
        Stand_No: "2736",
        Size: "557",
        Purchase_Price: "30078"
    },
    {
        Account_Name: "Hope,Tarisai Marere & Esther Antonio",
        Stand_No: "2738",
        Size: "500",
        Purchase_Price: "27000"
    },
    {
        Account_Name: "Evis M Matiza",
        Stand_No: "2739",
        Size: "500",
        Purchase_Price: "22500"
    },
    {
        Account_Name: "Evis M Matiza",
        Stand_No: "2740",
        Size: "500",
        Purchase_Price: "22500"
    },
    {
        Account_Name: "Hilton Felix Magunje & Rachel Tafadzwa Charu",
        Stand_No: "2743",
        Size: "500",
        Purchase_Price: "35000"
    },
    {
        Account_Name: "Sabada Dube & Lynnette Tarisai Mupopoma",
        Stand_No: "2744",
        Size: "500",
        Purchase_Price: "35000"
    },
    {
        Account_Name: "Collins & Tinashe Mahadzva",
        Stand_No: "2745",
        Size: "500",
        Purchase_Price: "35000"
    },
    {
        Account_Name: "Hope,Tarisai Marere & Esther Antonio",
        Stand_No: "2747",
        Size: "557",
        Purchase_Price: "30078"
    },
    {
        Account_Name: "Onias A & Ngonidzashe M M Dodzo",
        Stand_No: "2751",
        Size: "500",
        Purchase_Price: "35000"
    },
    {
        Account_Name: "Wilfred Mapfuise",
        Stand_No: "2752",
        Size: "551",
        Purchase_Price: "41325"
    },
    {
        Account_Name: "Richard Makurumidze",
        Stand_No: "2753",
        Size: "661",
        Purchase_Price: "39660"
    },
    {
        Account_Name: "Gerald & Portia Ndhlumbi",
        Stand_No: "2755",
        Size: "500",
        Purchase_Price: "35000"
    },
    {
        Account_Name: "Tsungirirai Marufu",
        Stand_No: "2756",
        Size: "500",
        Purchase_Price: "35000"
    },
    {
        Account_Name: "SPENCER MAKUVAZA",
        Stand_No: "2759",
        Size: "500",
        Purchase_Price: "25000"
    },
    {
        Account_Name: "Bridget Chenai Bake",
        Stand_No: "2762",
        Size: "516",
        Purchase_Price: "37500"
    },
    {
        Account_Name: "The MK Bungu Trust",
        Stand_No: "2767",
        Size: "500",
        Purchase_Price: "37500"
    },
    {
        Account_Name: "Zvita Rosemary Mandu",
        Stand_No: "2772",
        Size: "557",
        Purchase_Price: "41775"
    },
    {
        Account_Name: "Dr Faith Vhenekai Moyo",
        Stand_No: "2777",
        Size: "629",
        Purchase_Price: "37740"
    },
    {
        Account_Name: "Jane Chikore",
        Stand_No: "2781",
        Size: "557",
        Purchase_Price: "39000"
    },
    {
        Account_Name: "Covernant Of Love Full Gospel Church",
        Stand_No: "2785",
        Size: "2102",
        Purchase_Price: "147140"
    },
    {
        Account_Name: "Hope Tariro & Wellington Nyamande",
        Stand_No: "2791",
        Size: "505",
        Purchase_Price: "35350"
    },
    {
        Account_Name: "Shaillon Chiswa-Supplier",
        Stand_No: "2793",
        Size: "545",
        Purchase_Price: "23435"
    },
    {
        Account_Name: "Tavona Vision Chinaka",
        Stand_No: "2796",
        Size: "500",
        Purchase_Price: "32500"
    },
    {
        Account_Name: "Emily Makota-Supplier",
        Stand_No: "2797",
        Size: "500",
        Purchase_Price: "32500"
    },
    {
        Account_Name: "MUKUNDI AIDEN CHAKAWUYA",
        Stand_No: "2798",
        Size: "500",
        Purchase_Price: "37500"
    },
    {
        Account_Name: "Paidamoyo A. Shambare",
        Stand_No: "2800",
        Size: "542",
        Purchase_Price: "40650"
    },
    {
        Account_Name: "Tavonga E and Thelma Gwatidzo",
        Stand_No: "2801",
        Size: "500",
        Purchase_Price: "37500"
    },
    {
        Account_Name: "Nyasha Chirinda",
        Stand_No: "2805",
        Size: "501",
        Purchase_Price: "37575"
    },
    {
        Account_Name: "Chiedza Mataka Mtetwa",
        Stand_No: "2808",
        Size: "642",
        Purchase_Price: "32100"
    },
    {
        Account_Name: "COLLIN KURUMBA",
        Stand_No: "2809",
        Size: "500",
        Purchase_Price: "37500"
    },
    {
        Account_Name: "LEARNMORE MUTEMARINGA ",
        Stand_No: "2810",
        Size: "500",
        Purchase_Price: "37500"
    },
    {
        Account_Name: "Loice and Ernest Simbarashe Murungu",
        Stand_No: "2811",
        Size: "500",
        Purchase_Price: "27500"
    },
    {
        Account_Name: "Loice Murungu-(Supplier Rulom Steel)",
        Stand_No: "2812",
        Size: "500",
        Purchase_Price: "27500"
    },
    {
        Account_Name: "Rudo Kayombo",
        Stand_No: "2813",
        Size: "654",
        Purchase_Price: "49050"
    },
    {
        Account_Name: "RTK Trust - Rumbidzai Pairamanzi",
        Stand_No: "2814",
        Size: "655",
        Purchase_Price: "0"
    },
    {
        Account_Name: "MUNYARADZI & CANDICE CHALENE NYAMUDA",
        Stand_No: "2819",
        Size: "628",
        Purchase_Price: "32100"
    },
    {
        Account_Name: "Ann Yolanda Sam",
        Stand_No: "2827",
        Size: "597",
        Purchase_Price: "39000"
    },
    {
        Account_Name: "CHARITY KUNDIONA AND CONDON KUNDIONA ",
        Stand_No: "2831",
        Size: "543",
        Purchase_Price: "40725"
    },
    {
        Account_Name: "Valentine Musengezi",
        Stand_No: "2848",
        Size: "539",
        Purchase_Price: "40425"
    },
    {
        Account_Name: "Bruce & Cecilia Fadzai Lusenge",
        Stand_No: "2849",
        Size: "539",
        Purchase_Price: "40425"
    },
    {
        Account_Name: "Anna Tatenda Magaya",
        Stand_No: "2850",
        Size: "531",
        Purchase_Price: "39825"
    },
    {
        Account_Name: "TAKUNDA MAKUGUETE",
        Stand_No: "2857",
        Size: "584",
        Purchase_Price: "37960"
    },
    {
        Account_Name: "FELISTARS MANDIZVIDZA ",
        Stand_No: "2858",
        Size: "519",
        Purchase_Price: "44115"
    },
    {
        Account_Name: "GODFREY & REGINA MANENJI",
        Stand_No: "2865",
        Size: "548",
        Purchase_Price: "41100"
    },
    {
        Account_Name: "Lawrence Murinzi-Supplier",
        Stand_No: "2866",
        Size: "541",
        Purchase_Price: "35165"
    },
    {
        Account_Name: "NYARADZO GROUP STAFF PENSION FUND",
        Stand_No: "2869",
        Size: "532",
        Purchase_Price: "39900"
    },
    {
        Account_Name: "NYARADZO GROUP STAFF PENSION FUND",
        Stand_No: "2870",
        Size: "533",
        Purchase_Price: "37310"
    },
    {
        Account_Name: "Nyaradzo Group Staff Pension Fund",
        Stand_No: "2877",
        Size: "500",
        Purchase_Price: "37500"
    },
    {
        Account_Name: "Nyaradzo Pensions Agents",
        Stand_No: "2878",
        Size: "515",
        Purchase_Price: "33750"
    },
    {
        Account_Name: "Tendai and Katarina Panaishe",
        Stand_No: "2879",
        Size: "465",
        Purchase_Price: "34875"
    },
    {
        Account_Name: "Alvin Jenje & Denise Jenje",
        Stand_No: "2882",
        Size: "597",
        Purchase_Price: "44775"
    },
    {
        Account_Name: "BETHEL FUMAI MUTYATYU",
        Stand_No: "2884",
        Size: "542",
        Purchase_Price: "35230"
    },
    {
        Account_Name: "TATENDA MAKUCHETE",
        Stand_No: "2885",
        Size: "495",
        Purchase_Price: "37125"
    },
    {
        Account_Name: "Evans Chitate(equipment world)",
        Stand_No: "2915",
        Size: "594",
        Purchase_Price: "33264"
    },
    {
        Account_Name: "Dr Faith Vhenekai Moyo",
        Stand_No: "2916",
        Size: "594",
        Purchase_Price: "33264"
    },
    {
        Account_Name: "Jesman P. Chimutanda",
        Stand_No: "2917",
        Size: "594",
        Purchase_Price: "28500"
    },
    {
        Account_Name: "Shelter & Farai Aarchbald Bhilla",
        Stand_No: "2918",
        Size: "594",
        Purchase_Price: "28500"
    },
    {
        Account_Name: "Antony & Josephine Chisada",
        Stand_No: "2919",
        Size: "594",
        Purchase_Price: "28512"
    },
    // {
    //     Account_Name: "Ringisai Batiya",
    //     Stand_No: "2920",
    //     Size: "594",
    //     Purchase_Price: "44550"
    // },
    // {
    //     Account_Name: "Denmark & Abgirl Mugutso",
    //     Stand_No: "2920",
    //     Size: "594",
    //     Purchase_Price: "28500"
    // },
    {
        Account_Name: "Zola Eugine Bhebhe & Charity Sally Dube",
        Stand_No: "2921",
        Size: "594",
        Purchase_Price: "35640"
    },
    {
        Account_Name: "M & E Trust",
        Stand_No: "2922",
        Size: "594",
        Purchase_Price: "28500"
    },
    {
        Account_Name: "M & E Trust",
        Stand_No: "2923",
        Size: "594",
        Purchase_Price: "28500"
    },
    {
        Account_Name: "Luckson & Felistas Mahaso",
        Stand_No: "2924",
        Size: "594",
        Purchase_Price: "26730"
    },
    // {
    //     Account_Name: "Evis Monica Matiza-Supplier",
    //     Stand_No: "2925",
    //     Size: "594",
    //     Purchase_Price: "24948"
    // },
    // {
    //     Account_Name: "Nicolette Mangisi",
    //     Stand_No: "2925",
    //     Size: "594",
    //     Purchase_Price: "33264"
    // },
    {
        Account_Name: "Courage S. Mashavave",
        Stand_No: "2926",
        Size: "594",
        Purchase_Price: "28500"
    },
    {
        Account_Name: "Chiriwo & Chamza",
        Stand_No: "2927",
        Size: "594",
        Purchase_Price: "33264"
    },
    {
        Account_Name: "Mbonis Tshuma",
        Stand_No: "2928",
        Size: "594",
        Purchase_Price: "33264"
    },
    {
        Account_Name: "Courage S. Mashavave",
        Stand_No: "2929",
        Size: "594",
        Purchase_Price: "33264"
    },
    {
        Account_Name: "George & Judith Chaumba",
        Stand_No: "2930",
        Size: "594",
        Purchase_Price: "33264"
    },
    {
        Account_Name: "MCGREGORY MANGWIO AND TAFADZWA SIMBINI",
        Stand_No: "2931",
        Size: "594",
        Purchase_Price: "33264"
    },
    {
        Account_Name: "Charity Tafadzwa Chiutsi",
        Stand_No: "2932",
        Size: "594",
        Purchase_Price: "34452"
    },
    {
        Account_Name: "Netsayi Loveness Chitowa",
        Stand_No: "2933",
        Size: "594",
        Purchase_Price: "33264"
    },
    {
        Account_Name: "Jenkins Brainworks Family Trust",
        Stand_No: "2934",
        Size: "594",
        Purchase_Price: "34452"
    },
    {
        Account_Name: "Rebecca Tatenda Tonhorai & Daassin Kalombo(transea",
        Stand_No: "2935",
        Size: "802",
        Purchase_Price: "40000"
    },
    {
        Account_Name: "Titus Matiza",
        Stand_No: "2936",
        Size: "802",
        Purchase_Price: "36090"
    },
    // {
    //     Account_Name: "Violet Mudzimba",
    //     Stand_No: "2937",
    //     Size: "802",
    //     Purchase_Price: "48120"
    // },
    // {
    //     Account_Name: "Tinei Mashinge & Judith Mashinge",
    //     Stand_No: "2937",
    //     Size: "802",
    //     Purchase_Price: "44912"
    // },
    {
        Account_Name: "Linda Vaida Gorejena",
        Stand_No: "2938",
        Size: "802",
        Purchase_Price: "44912"
    },
    {
        Account_Name: "Solution Gates",
        Stand_No: "2939",
        Size: "802",
        Purchase_Price: "0"
    },
    {
        Account_Name: "Prodex",
        Stand_No: "2945",
        Size: "801",
        Purchase_Price: "0"
    },
    {
        Account_Name: "Tonderayi Munhando & Vencia Manhando",
        Stand_No: "2947",
        Size: "801",
        Purchase_Price: "41652"
    },
    {
        Account_Name: "Prodex",
        Stand_No: "2948",
        Size: "800",
        Purchase_Price: "0"
    },
    {
        Account_Name: "Chikuni Ashwean",
        Stand_No: "2949",
        Size: "800",
        Purchase_Price: "41600"
    },
    {
        Account_Name: "Chenjerai Kelvin Medzai",
        Stand_No: "2951",
        Size: "800",
        Purchase_Price: "48000"
    },
    {
        Account_Name: "Kudakwashe Yvonne Mufuka",
        Stand_No: "2952",
        Size: "800",
        Purchase_Price: "44800"
    },
    {
        Account_Name: "Charity Memory Chishawa",
        Stand_No: "2953",
        Size: "800",
        Purchase_Price: "42400"
    },
    {
        Account_Name: "kelvin Tapuwanashe Tengwana & Tariro Loise Banda",
        Stand_No: "2954",
        Size: "799",
        Purchase_Price: "44744"
    },
    {
        Account_Name: "Sandra Rosewitter Matimba",
        Stand_No: "2955",
        Size: "799",
        Purchase_Price: "44744"
    },
    {
        Account_Name: "kudakwashe Gariwe & Michelle Vinyu",
        Stand_No: "2956",
        Size: "799",
        Purchase_Price: "44744"
    },
    {
        Account_Name: "Richard Mapfumo & Angela Chirombo",
        Stand_No: "2957",
        Size: "799",
        Purchase_Price: "44744"
    },
    {
        Account_Name: "Justice Takawira Chiona",
        Stand_No: "2958",
        Size: "799",
        Purchase_Price: "44744"
    },
    {
        Account_Name: "Shelter Chiwoyo",
        Stand_No: "2959",
        Size: "799",
        Purchase_Price: "33558"
    },
    {
        Account_Name: "VIRGINIA MUTSAWASHE RWAFA",
        Stand_No: "2963",
        Size: "798",
        Purchase_Price: "47880"
    },
    {
        Account_Name: "Misheck Chinhema",
        Stand_No: "2965",
        Size: "798",
        Purchase_Price: "39102"
    },
    {
        Account_Name: "Masline Nyamandi",
        Stand_No: "2966",
        Size: "798",
        Purchase_Price: "36000"
    },
    {
        Account_Name: "Privilage Nyarai Zanamwe",
        Stand_No: "2968",
        Size: "798",
        Purchase_Price: "44688"
    },
    {
        Account_Name: "Munyaradzi Mazivisa",
        Stand_No: "2969",
        Size: "798",
        Purchase_Price: "0"
    },
    {
        Account_Name: "Musoni",
        Stand_No: "2970",
        Size: "798",
        Purchase_Price: "4692240"
    },
    {
        Account_Name: "SIMON CHAGWEDA",
        Stand_No: "2971",
        Size: "797",
        Purchase_Price: "55790"
    },
    {
        Account_Name: "RUTENDO MBERIKWAZVO",
        Stand_No: "2972",
        Size: "797",
        Purchase_Price: "55790"
    },
    {
        Account_Name: "KUDAKWASHE MAGUTA",
        Stand_No: "2973",
        Size: "797",
        Purchase_Price: "55790"
    },
    {
        Account_Name: "ERICK RUWONA",
        Stand_No: "2976",
        Size: "797",
        Purchase_Price: "55790"
    },
    {
        Account_Name: "GERALD NYONI",
        Stand_No: "2977",
        Size: "797",
        Purchase_Price: "55790"
    },
    {
        Account_Name: "ROSEMARY T. SAMHEMBERE",
        Stand_No: "2978",
        Size: "798",
        Purchase_Price: "26301"
    },
    {
        Account_Name: "Anele Thulani Moyo & Vimbainashe Sandra Goredema",
        Stand_No: "2980",
        Size: "1070",
        Purchase_Price: "50290"
    },
    {
        Account_Name: "(Tichaona Tanyanyiwa)Majoka& Mpofu",
        Stand_No: "2984",
        Size: "594",
        Purchase_Price: "28000"
    },
    {
        Account_Name: "Precious Taziveyi",
        Stand_No: "2985",
        Size: "594",
        Purchase_Price: "25542"
    },
    {
        Account_Name: "Esau Grader",
        Stand_No: "2986",
        Size: "594",
        Purchase_Price: "25542"
    },
    {
        Account_Name: "Cecile K Jacovides",
        Stand_No: "2987",
        Size: "594",
        Purchase_Price: "15440"
    },
    {
        Account_Name: "Munashe M Kazangarare",
        Stand_No: "2988",
        Size: "594",
        Purchase_Price: "15440"
    },
    {
        Account_Name: "Lucia Chingwaru",
        Stand_No: "2989",
        Size: "594",
        Purchase_Price: "23760"
    },
    {
        Account_Name: "Tanaka Hlahla",
        Stand_No: "2990",
        Size: "594",
        Purchase_Price: "25542"
    },
    {
        Account_Name: "William M Chigogwana",
        Stand_No: "2991",
        Size: "594",
        Purchase_Price: "25542"
    },
    {
        Account_Name: "Esther Ndhlovu",
        Stand_No: "2992",
        Size: "594",
        Purchase_Price: "25542"
    },
    {
        Account_Name: "Winnet Chimombe-ESAU",
        Stand_No: "2993",
        Size: "594",
        Purchase_Price: "16632"
    },
    {
        Account_Name: "Seresina Muzozvina",
        Stand_No: "2994",
        Size: "594",
        Purchase_Price: "33264"
    },
    {
        Account_Name: "TENDAI MUGADZAWETA & MELODY GUNDANI",
        Stand_No: "2995",
        Size: "620",
        Purchase_Price: "34720"
    },
    {
        Account_Name: "Esau Fuel",
        Stand_No: "3023",
        Size: "687",
        Purchase_Price: "19236"
    },
    {
        Account_Name: "Charity Chido Makawa",
        Stand_No: "3024",
        Size: "594",
        Purchase_Price: "21978"
    },
    {
        Account_Name: "Delma Chigumba",
        Stand_No: "3025",
        Size: "594",
        Purchase_Price: "11880"
    },
    {
        Account_Name: "Clara Nyatondo",
        Stand_No: "3026",
        Size: "594",
        Purchase_Price: "11880"
    },
    {
        Account_Name: "Rue Family Trust",
        Stand_No: "3027",
        Size: "594",
        Purchase_Price: "33264"
    },
    {
        Account_Name: "Inyasha Trust-Diesel",
        Stand_No: "3028",
        Size: "594",
        Purchase_Price: "26730"
    },
    {
        Account_Name: "Claire Fadzai Tsanga",
        Stand_No: "3035",
        Size: "685",
        Purchase_Price: "36990"
    },
    {
        Account_Name: "MAMBO INCORPORATED (PVT) LTD-SUPPLIER",
        Stand_No: "3111",
        Size: "504",
        Purchase_Price: "30240"
    },
    {
        Account_Name: "Tawanda Allen Mushayi",
        Stand_No: "3285",
        Size: "576",
        Purchase_Price: "33000"
    },
    {
        Account_Name: "Cain and Ericah Tiwirirayi Muzuva",
        Stand_No: "3286",
        Size: "500",
        Purchase_Price: "32500"
    },
    {
        Account_Name: "Hilda Paidamoyo Mabhoni",
        Stand_No: "3287",
        Size: "500",
        Purchase_Price: "37500"
    },
    {
        Account_Name: "Knowledge Tichaona Zibo",
        Stand_No: "3300",
        Size: "522",
        Purchase_Price: "39150"
    },
    {
        Account_Name: "Fungai Mawadze",
        Stand_No: "3301",
        Size: "504",
        Purchase_Price: "37800"
    },
    {
        Account_Name: "Tinashe Mapfiza",
        Stand_No: "3303",
        Size: "683",
        Purchase_Price: "51225"
    },
    {
        Account_Name: "Divine V & Leslie J Chimenya",
        Stand_No: "3304",
        Size: "506",
        Purchase_Price: "37950"
    },
    {
        Account_Name: "Precious Esther Shoko",
        Stand_No: "3305",
        Size: "528",
        Purchase_Price: "39600"
    },
    {
        Account_Name: "GERT JOHANNES NEL",
        Stand_No: "3307",
        Size: "500",
        Purchase_Price: "35000"
    },
    {
        Account_Name: "GERT JOHANNES NEL",
        Stand_No: "3308",
        Size: "500",
        Purchase_Price: "35000"
    },
    {
        Account_Name: "TITUS MATIZA-SUPPLIER",
        Stand_No: "3313",
        Size: "400",
        Purchase_Price: "19049"
    },
    {
        Account_Name: "Gertrude Marabada",
        Stand_No: "3314",
        Size: "400",
        Purchase_Price: "30000"
    },
    {
        Account_Name: "ELLEN RUFURWOKUDA AND OLADIPUPO SUNDAY AKINFAYE",
        Stand_No: "3324",
        Size: "500",
        Purchase_Price: "27500"
    },
    {
        Account_Name: "ELLEN RUFURWOKUDA AND OLADIPUPO SUNDAY AKINFAYE ",
        Stand_No: "3325",
        Size: "500",
        Purchase_Price: "27500"
    },
    {
        Account_Name: "MICHAEL MUDIWA SHAUN CHAWATAMA",
        Stand_No: "3344",
        Size: "500",
        Purchase_Price: "36500"
    },
    {
        Account_Name: "ZVIKOMBORERO SHANNON MAGARA",
        Stand_No: "3345",
        Size: "500",
        Purchase_Price: "36500"
    },
    {
        Account_Name: "REGEDZAI PERESUH",
        Stand_No: "3346",
        Size: "500",
        Purchase_Price: "37500"
    },
    {
        Account_Name: "Linington and Tafadzwa Pauline Dutiroe",
        Stand_No: "3348",
        Size: "539",
        Purchase_Price: "40425"
    },
    {
        Account_Name: "NIKKI MAGUNDA",
        Stand_No: "3355",
        Size: "500",
        Purchase_Price: "37500"
    },
    {
        Account_Name: "Hanei Investments Pvt Ltd",
        Stand_No: "3357",
        Size: "535",
        Purchase_Price: "40125"
    },
    {
        Account_Name: "Struggle Tatenda Makawa",
        Stand_No: "3358",
        Size: "445",
        Purchase_Price: "33375"
    },
    {
        Account_Name: "OBEY MUNEMO",
        Stand_No: "3360",
        Size: "445",
        Purchase_Price: "33375"
    },
    {
        Account_Name: "IGNATIUS & NANDY GOMBERA-(TIROSH-SUPPLIER)",
        Stand_No: "3361",
        Size: "434",
        Purchase_Price: "30380"
    },
    {
        Account_Name: "DARLINGTON & LINDA  HUKUIMWE",
        Stand_No: "3375",
        Size: "553",
        Purchase_Price: "41475"
    },
    {
        Account_Name: "Sasiru Pvt Ltd",
        Stand_No: "3379",
        Size: "500",
        Purchase_Price: "37500"
    },
    {
        Account_Name: "Sasiru Pvt Ltd",
        Stand_No: "3380",
        Size: "500",
        Purchase_Price: "37500"
    },
    {
        Account_Name: "Sasiru Pvt Ltd",
        Stand_No: "3381",
        Size: "513",
        Purchase_Price: "38475"
    },
    {
        Account_Name: "Sasiru Pvt Ltd",
        Stand_No: "3385",
        Size: "500",
        Purchase_Price: "37500"
    },
    {
        Account_Name: "Sasiru Pvt Ltd",
        Stand_No: "3386",
        Size: "500",
        Purchase_Price: "37500"
    },
    {
        Account_Name: "BRIAN & SHAMISO B MUFARACHISI",
        Stand_No: "3395",
        Size: "750",
        Purchase_Price: "56250"
    },
    {
        Account_Name: "SARAH JAVANGWE FAMILY TRUST -SUPPLIER",
        Stand_No: "3396",
        Size: "600",
        Purchase_Price: "36000"
    },
    {
        Account_Name: "ELLEN RUFURWOKUDA",
        Stand_No: "3418",
        Size: "500",
        Purchase_Price: "30000"
    },
    {
        Account_Name: "ELLEN RUFURWOKUDA",
        Stand_No: "3419",
        Size: "500",
        Purchase_Price: "30000"
    },
    {
        Account_Name: "ELLEN RUFURWOKUDA",
        Stand_No: "3420",
        Size: "500",
        Purchase_Price: "30000"
    },
    {
        Account_Name: "ELLEN RUFURWOKUDA",
        Stand_No: "3421",
        Size: "486",
        Purchase_Price: "29160"
    },
    {
        Account_Name: "ELLEN RUFURWOKUDA",
        Stand_No: "3422",
        Size: "647",
        Purchase_Price: "38820"
    },
    {
        Account_Name: "GARIKAI DZIMUNYA ",
        Stand_No: "3467",
        Size: "500",
        Purchase_Price: "42500"
    },
    {
        Account_Name: "MORRIS CHEKAI HOVE",
        Stand_No: "3470",
        Size: "300",
        Purchase_Price: "6000"
    },
    {
        Account_Name: "KUMBUKANI CHIKAPA PHIRI",
        Stand_No: "3475",
        Size: "0",
        Purchase_Price: "37500"
    },
    {
        Account_Name: "EVERSON MAGUNDA",
        Stand_No: "3477",
        Size: "504",
        Purchase_Price: "37800"
    },
    {
        Account_Name: "The Haven Family Trust",
        Stand_No: "3492",
        Size: "500",
        Purchase_Price: "37500"
    },
    {
        Account_Name: "",
        Stand_No: "4123",
        Size: "1037",
        Purchase_Price: "0"
    },
    {
        Account_Name: "",
        Stand_No: "2545",
        Size: "469",
        Purchase_Price: "35175"
    },
    {
        Account_Name: "SIMUKIRAI JUSTINE MAKWALA",
        Stand_No: "4124",
        Size: "761",
        Purchase_Price: "57075"
    },
    {
        Account_Name: "Tanaka Peter Chikawata and Precious Mashamba",
        Stand_No: "4254",
        Size: "778",
        Purchase_Price: "58350"
    },
    {
        Account_Name: "George & Ivy Sithole",
        Stand_No: "4257",
        Size: "584",
        Purchase_Price: "43800"
    },
    {
        Account_Name: "Forbes Veremu & Menia Mapurazi",
        Stand_No: "4260",
        Size: "509",
        Purchase_Price: "37175"
    },
    {
        Account_Name: "ETINOS MUDZAMBA ",
        Stand_No: "4262",
        Size: "510",
        Purchase_Price: "38250"
    },
    {
        Account_Name: "Tanhatu Investments T/A Modercon Development",
        Stand_No: "4264",
        Size: "535",
        Purchase_Price: "36750"
    },
    {
        Account_Name: "Trymore Jephrate Zindoga",
        Stand_No: "4267",
        Size: "491",
        Purchase_Price: "34370"
    },
    {
        Account_Name: "BRILLIANT NYARADZO MUSEKIWA",
        Stand_No: "4276",
        Size: "514",
        Purchase_Price: "38550"
    },
    {
        Account_Name: "Kudzaishe Anisha Zongororo",
        Stand_No: "4277",
        Size: "500",
        Purchase_Price: "35000"
    },
    {
        Account_Name: "Marshal Takawira Guhwa",
        Stand_No: "4280",
        Size: "596",
        Purchase_Price: "44700"
    },
    {
        Account_Name: "Wellington Mandizvidza",
        Stand_No: "4281",
        Size: "500",
        Purchase_Price: "37500"
    },
    {
        Account_Name: "Carol Ncube",
        Stand_No: "4287",
        Size: "562",
        Purchase_Price: "39340"
    },
    {
        Account_Name: "LIKONGE FAMILY TRUST -SUPPLIER",
        Stand_No: "4290",
        Size: "549",
        Purchase_Price: "41175"
    },
    {
        Account_Name: "Knowledge Tafirenyika and Memory Zvenyika",
        Stand_No: "4291",
        Size: "549",
        Purchase_Price: "41175"
    },
    {
        Account_Name: "Emmanuel Mawuka",
        Stand_No: "4293",
        Size: "612",
        Purchase_Price: "45900"
    },
    {
        Account_Name: "DEWBERRY INCORPORATED",
        Stand_No: "4294",
        Size: "655",
        Purchase_Price: "45850"
    },
    {
        Account_Name: "MATINDAKU FAMILY TRUST -SUPPLIER",
        Stand_No: "4298",
        Size: "598",
        Purchase_Price: "38870"
    },
    {
        Account_Name: "PRINCE TAPIWA CHINJEKURE",
        Stand_No: "4309",
        Size: "531",
        Purchase_Price: "39825"
    },
    {
        Account_Name: "DR Petros Chimbunde",
        Stand_No: "4310",
        Size: "500",
        Purchase_Price: "35000"
    },
    {
        Account_Name: "DR Petros Chimbunde",
        Stand_No: "4311",
        Size: "517",
        Purchase_Price: "36190"
    },
    {
        Account_Name: "Prince Gumbo",
        Stand_No: "4312",
        Size: "510",
        Purchase_Price: "38250"
    },
    {
        Account_Name: "Aaron & Chenai Moyana",
        Stand_No: "4315",
        Size: "429",
        Purchase_Price: "32175"
    },
    {
        Account_Name: "Hilda Rutendo Gumunyu Manatsa",
        Stand_No: "4316",
        Size: "639",
        Purchase_Price: "44220"
    },
    {
        Account_Name: "Tonderai & Prudence Matopodzi",
        Stand_No: "4317",
        Size: "500",
        Purchase_Price: "35000"
    },
    {
        Account_Name: "OBERT JIRI ",
        Stand_No: "4318",
        Size: "496",
        Purchase_Price: "49600"
    },
    // {
    //     Account_Name: "Leonard Musariri",
    //     Stand_No: "4319",
    //     Size: "750",
    //     Purchase_Price: "56250"
    // },
    // {
    //     Account_Name: "Mandlaenkosi & Praise Matshanda",
    //     Stand_No: "4319",
    //     Size: "758",
    //     Purchase_Price: "53060"
    // },
    {
        Account_Name: "IGNATIUS GOMBERA & NANDY GOMBERA",
        Stand_No: "4320",
        Size: "654",
        Purchase_Price: "49050"
    },
    {
        Account_Name: "Barry Jabulani Mandhla Makulu",
        Stand_No: "4321",
        Size: "529",
        Purchase_Price: "39150"
    },
    {
        Account_Name: "Alice Ndoudzaani Mapfiza",
        Stand_No: "4323",
        Size: "560",
        Purchase_Price: "38080"
    },
    {
        Account_Name: "Hilda Rutendo Gumunyu Manatsa",
        Stand_No: "4324",
        Size: "675",
        Purchase_Price: "45225"
    },
    {
        Account_Name: "Tawanda Parawira & Tariro Chaumba",
        Stand_No: "4325",
        Size: "508",
        Purchase_Price: "33528"
    },
    {
        Account_Name: "TINOFARA KAGURA",
        Stand_No: "4328",
        Size: "612",
        Purchase_Price: "45900"
    },
    {
        Account_Name: "Edwin Zimunga & Jane Mutasa",
        Stand_No: "4340",
        Size: "506",
        Purchase_Price: "32890"
    },
    {
        Account_Name: "Gerald Mutumhe",
        Stand_No: "4341",
        Size: "500",
        Purchase_Price: "25000"
    },
    {
        Account_Name: "BLESSING NYASHA GWENZI AND NYASHA MAREDZA",
        Stand_No: "4342",
        Size: "527",
        Purchase_Price: "39525"
    },
    {
        Account_Name: "NDABEZINHLE MNKANDLA",
        Stand_No: "4344",
        Size: "597",
        Purchase_Price: "35820"
    },
    {
        Account_Name: "Tracy Fungai Chiseya",
        Stand_No: "4345",
        Size: "692",
        Purchase_Price: "41520"
    },
    {
        Account_Name: "Itai Manyere",
        Stand_No: "4348",
        Size: "500",
        Purchase_Price: "37500"
    },
    {
        Account_Name: "Anaya Trust- Chiedza Jaravaza",
        Stand_No: "4350",
        Size: "461",
        Purchase_Price: "34575"
    },
    {
        Account_Name: "Mukundi Aiden & Leslie M Chakawuya",
        Stand_No: "4351",
        Size: "491",
        Purchase_Price: "36825"
    },
    {
        Account_Name: "Romeo Chatereza and Promise Progress Gapare",
        Stand_No: "4352",
        Size: "538",
        Purchase_Price: "40350"
    },
    {
        Account_Name: "FERDINAND ZUZE AND KAREN TSITSI MAJIRA ",
        Stand_No: "4355",
        Size: "566",
        Purchase_Price: "56600"
    },
    {
        Account_Name: "Cancrit Enterprises PVT LTD",
        Stand_No: "4358",
        Size: "811",
        Purchase_Price: "58932"
    },
    {
        Account_Name: "Cancrit Enterprises P/L Rep T.M. Dzvairo",
        Stand_No: "4360",
        Size: "465",
        Purchase_Price: "33480"
    },
    {
        Account_Name: "CHRISTIAN NYASHA NDORO",
        Stand_No: "4362",
        Size: "427",
        Purchase_Price: "27755"
    },
    {
        Account_Name: "VENTECK FAMILY TRUST",
        Stand_No: "4364",
        Size: "811",
        Purchase_Price: "60825"
    },
    {
        Account_Name: "RENIAS & DESDERIA MUKARO",
        Stand_No: "4366",
        Size: "802",
        Purchase_Price: "53500"
    },
    {
        Account_Name: "ZVANYADZA NORLEEN CHIGWIDA AND WRIGHT SARAPO MAHLUNGE",
        Stand_No: "4371",
        Size: "499",
        Purchase_Price: "37425"
    },{
        Account_Name: "",
        Stand_No: "4390",
        Size: "461",
        Purchase_Price: "0"
    },
    {
        Account_Name: "Tatenda Bornface Mujuru",
        Stand_No: "4397",
        Size: "512",
        Purchase_Price: "33280"
    },
    {
        Account_Name: "L T Mtsambiwa Legacy Trust",
        Stand_No: "4399",
        Size: "500",
        Purchase_Price: "37500"
    },
    {
        Account_Name: "Tafadzwa And Winnet Mushangwe",
        Stand_No: "4400",
        Size: "537",
        Purchase_Price: "38395.50"
    },
    {
        Account_Name: "NICOLA NDORO",
        Stand_No: "4402",
        Size: "625",
        Purchase_Price: "46875"
    },
    {
        Account_Name: "Kundai & Edmore Chibwana",
        Stand_No: "4403",
        Size: "572",
        Purchase_Price: "42900"
    },
    {
        Account_Name: "Thembalami Kudra & Nyarai Moyo",
        Stand_No: "4405",
        Size: "500",
        Purchase_Price: "37500"
    },
    {
        Account_Name: "COSMAS MBAMBE AND LUCY MBAMBE",
        Stand_No: "4406",
        Size: "794",
        Purchase_Price: "51610"
    },
    {
        Account_Name: "Nyasha Matsanga & Tinotenda T. Rushwaya",
        Stand_No: "4408",
        Size: "551",
        Purchase_Price: "41325"
    },
    {
        Account_Name: "RUFGAR INVESTMENTS",
        Stand_No: "4410",
        Size: "0",
        Purchase_Price: "86652"
    },
    {
        Account_Name: "Bright and Carla Gumbochuma",
        Stand_No: "4411",
        Size: "505",
        Purchase_Price: "37875"
    },
    {
        Account_Name: "CHATHEBERT MUDHUNGUYO",
        Stand_No: "4412",
        Size: "500",
        Purchase_Price: "37500"
    },
    {
        Account_Name: "Bridget Horwe",
        Stand_No: "4413",
        Size: "500",
        Purchase_Price: "37500"
    },
    {
        Account_Name: "TINASHE YOLANDA JANE SHAMU",
        Stand_No: "4422",
        Size: "500",
        Purchase_Price: "37500"
    },
    {
        Account_Name: "Liberty and Clara Katayi",
        Stand_No: "4424",
        Size: "714",
        Purchase_Price: "53550"
    },
    {
        Account_Name: "Moreblessing Muyambo",
        Stand_No: "4435",
        Size: "501",
        Purchase_Price: "37575"
    },
    {
        Account_Name: "Jeane Ruva Makoni and Jessica Chiedza Shumba",
        Stand_No: "4436",
        Size: "500",
        Purchase_Price: "37500"
    },
    {
        Account_Name: "MARSHAL  & NYASHA MASHINGAIDZE",
        Stand_No: "4438",
        Size: "509",
        Purchase_Price: "38175"
    },
    {
        Account_Name: "Cecilia Chirenga",
        Stand_No: "4441",
        Size: "479",
        Purchase_Price: "31135"
    },
    {
        Account_Name: "Keith Kangayi & Familiar Kangayi",
        Stand_No: "4445",
        Size: "586",
        Purchase_Price: "43950"
    },
    {
        Account_Name: "ASHTON BUMHIRA",
        Stand_No: "4447",
        Size: "601",
        Purchase_Price: "45075"
    },
    {
        Account_Name: "SAMAKI FAMILY TRUST",
        Stand_No: "4500",
        Size: "507",
        Purchase_Price: "38025"
    },
    {
        Account_Name: "Craig Chibaya",
        Stand_No: "4525",
        Size: "504",
        Purchase_Price: "35280"
    },
    {
        Account_Name: "SAMSON KWIRIRAI MANHANGA",
        Stand_No: "4527",
        Size: "629",
        Purchase_Price: "47175"
    },
    {
        Account_Name: "TENDAI CHIGAVAZIRA",
        Stand_No: "4528",
        Size: "496",
        Purchase_Price: "37200"
    },
]