import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TaskPluginComponent } from './taskplugin.component';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { TagInputModule } from 'ngx-chips';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxDocViewerModule } from 'ngx-doc-viewer';

@NgModule({
  imports: [
    RouterModule,
    CommonModule,
    NgSelectModule,
    TagInputModule,
    NgbModule,
    FormsModule,
    NgxDocViewerModule
  ],
  declarations: [TaskPluginComponent],
  exports: [TaskPluginComponent],
  schemas: [NO_ERRORS_SCHEMA],
})
export class TaskPluginModule {
  guf(yd) {
    console.log(yd);
  }
}
