import { Component, ElementRef, HostListener, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { InitialiseService } from 'app/services/initialise.service';
import { Task, actionActualData, actualData, Unit } from 'app/models/task-model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserDataService } from 'app/services/user-data.service';
import Swal from 'sweetalert2';
import { CdkDragDrop, moveItemInArray, CdkDrag,
    // CdkDrop,
    CdkDragStart, transferArrayItem } from '@angular/cdk/drag-drop';
/* tools */
import * as moment from 'moment';
import { map, find } from 'rxjs/operators';
import { Observable, Subscription, fromEvent } from 'rxjs';

import { WriteService } from 'app/services/writes.service';
import { asset, Department, Enterprise, Labour } from 'app/models/enterprise-model';
import { Project } from 'app/models/project-model';
import { ProjServiceService } from 'app/services/proj-service.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { EntServiceService } from 'app/services/ent-service.service';
import { IconsService } from 'app/services/icons.service';
import { TaskService } from 'app/services/task.service';
import { RouteService } from 'app/services/route.service';
import { CroppedEvent } from 'ngx-photo-editor';
import { IntrojsService } from 'app/services/introjs.service';
import { OpenBooksService } from 'app/services/open-books.service';
import { CurrencyPipe } from '@angular/common';
import { NotificationService } from 'app/services/notification.service';
import {CropperComponent} from 'angular-cropperjs';
import mergeImages from 'merge-images';
declare var $: any;

@Component({
    moduleId: module.id,
    selector: 'app-toolplugin',
    templateUrl: 'toolplugin.component.html',
    styleUrls: ['./toolplugin.component.css']
})

export class ToolPluginComponent implements OnInit {
    nTask: any;
    task: Task;
    setFor = '';
    @ViewChild('temp') temp: TemplateRef<any>;
    @ViewChild('template1') template1: TemplateRef<any>;
    @ViewChild('template2') template2: TemplateRef<any>;
    bodyElement: HTMLElement = document.body;
    modalRef: any;
    modalRef2: any;
    counter$: any;
    subscription: any;
    timedstamp: number;
    message: any;
    cloakTime = '';
    nSecs: number;
    nHrs: number;
    nMin: number;
    future: Date;
    actionData: actionActualData;
    setTask: Task;
    dmData: actualData;
    public setunattended = false;
    public settingPriority = false;
    public openEquip = false;
    taskset: Task;
    setEnterprise: Enterprise;
    setProject: Project;
    showsCal: boolean;
    showfCal: boolean;
    startDate: string;
    endDate: string;
    setSub: Task;
    setDone = false;
    setParts = false;
    setEdit = false;
    showList = true;
    showUnit = false;
    toDelete = false;
    newUplannedTask = false;
    newPopTask = false;
    newDiaryTask = false;
    Rts = [];
    yut: {
        name: '',
        unit: '',
        rate: ''
    }
    todo = [
        'Get to work',
        'Pick up groceries',
        'Go home',
        'Fall asleep',
        'Walk Dog',
        'Stretch',
        'Code Stuff',
        'Drag Stuff',
        'Drop Stuff',
        'Run',
        'Walk'
    ];
    staff = [];
    oldParts = [];
    oldMans = [];
    unit: { name: '', id: '' };
    uni: any;
    department: Department;
    messageTag: string;
    msgErr: boolean;
    // public showList = true;
    public newUnitfrm = false;
    public showColUsers = true;
    public showEmaiCheck = true;
    public showListe = true;
    public showListp = true;
    public showsetUser = false;
    public addDpt = false;
    // champion;
    value = '';
    setContact;
    classification;
    addClass;
    public fromClient = false;
    setCompany: any;
    public showChampSelect = false;
    teamLabour: Labour[];
    focus;
    focus1;
    focus2;
    chatModalRef: any;
    newEquip = false;
    hiredAsset: asset;
    newAsset: asset;
    addCompAsset = true;
    newHiredAsset = false;
    postpendHours = false;
    totalhours: number;
    imageChangedEvent: any;
    base64: any;
    setDomain = false;
    editForm = false;
    domain = {
        dataText: '',
        type: ''
    };
    viewDescForm = false;
    newDescription: string = '';
    setDesc = '';
    descWarning = false;
    newProd;
    addProductType = false;
    startDateError: { status: boolean; msg: string; };
    endDateError: { status: boolean; msg: string; };
    onlineEvent: Observable<Event>;
    offlineEvent: Observable<Event>;

    imageUrl: any;
    imageUrls = [];
    cropperRes: string;
    showCropper: boolean;
    savedImg: boolean;
    resizedBase64: any;
    cropperConfig: object = {
        movable: true,
        scalable: true,
        zoomable: true,
        viewMode: 2,
        checkCrossOrigin: true
    };
    text = '';
    downloadLink = '';
    cropperResults = [];
    mergedRes: any;
    state_with_icons = true;
    animate = true;
    @ViewChild('angularCropper') public angularCropper: CropperComponent;
    @ViewChild("canvasEl") canvasEl: ElementRef;
    private context: CanvasRenderingContext2D;
  
    constructor(public is: InitialiseService, public modalService: NgbModal, public usd: UserDataService, public ws: WriteService,
        public rs: RouteService, public ps: ProjServiceService, public afs: AngularFirestore, public es: EntServiceService, public ns: NotificationService,
        public ico: IconsService, public tsk: TaskService, public tut: IntrojsService, public ob: OpenBooksService, private currencyPipe : CurrencyPipe) {
        this.setFor = 'Project';
        this.usd.connectivity();
        this.newProd = is.initRate();
        this.newProd.amount = null;
        this.taskset = is.getTask();
        this.setSub = is.getTask();
        this.nTask  = is.getTask();
        this.task = is.getTask();
        this.dmData = { updateTime: '', qty: null, by: '', byId: '' };
        this.actionData = { name: '', time: '', actionId: '', id: '', actuals: null };
        this.usd.task = is.getTask();
        // this.champion = is.getUserChampion()
        this.usd.task.champion = usd.acc;
        this.classification = is.getClassInit();
        this.usd.task.champCompany = { name: '', id: '' };
        this.startDateError = { status: false, msg: '' };
        this.endDateError = { status: false, msg: '' };
        // this.addSelf('NoSect');
    }

    // ngAfterViewInit() {
    //     this.context = (this.canvasEl
    //     .nativeElement as HTMLCanvasElement).getContext("2d");
    //     this.draw(''); HP spector
    // }

    private draw(src: string) {
        this.context.clearRect(0, 0, (this.canvasEl.nativeElement as HTMLCanvasElement).width, (this.canvasEl.nativeElement as HTMLCanvasElement).height);
        const img = new Image();
        img.src = src;
        img.onload = () => {
        const newW = img.width > 700 && img.height > 700 ? img.width / 3 : img.width;
        const newH = img.height > 700 && img.width > 700 ? img.height / 3 : img.height;
        (this.canvasEl.nativeElement as HTMLCanvasElement).width = newW;
        (this.canvasEl.nativeElement as HTMLCanvasElement).height = newH;
        this.context.font = "30px Arial";
        this.context.textBaseline = "middle";
        this.context.textAlign = "center";
        this.context.drawImage(img, 0, 0, newW, newH);
        this.context.fillText(this.text, newW / 2, newH / 2);
        this.downloadLink = this.canvasEl.nativeElement.toDataURL("image/jpg");
        this.cropperResults.push(this.downloadLink);
        }
    }

    
    onChange($event) {
        console.log($event); 
        // this.ws.tutorialOnOff($event.currentValue);
        const newVal = String($event.currentValue)
        // sessionStorage.setItem('showTut', newVal);
        console.log(newVal);
        // updateTutStatus()

    }

    onFileSelected(event) {
        const that = this;
        if (event.target.files && event.target.files[0]) {
        for (let i = 0; i < event.target.files.length; i++) {
            const reader = new FileReader();
            that.showCropper = false;
            reader.onload = (eventCurr: ProgressEvent) => {
            that.imageUrls.push((<FileReader>eventCurr.target).result);
            };
            reader.readAsDataURL(event.target.files[i]);
        }
        }
    }

    selectImg(i: number) {
        this.refreshCrop(this.imageUrls[i]);
    }

    refreshCrop(img) {
        this.imageUrl = img;
        this.showCropper = true;
    }

    cropendImage(event) {
        this.cropperRes = this.angularCropper.cropper.getCroppedCanvas().toDataURL('image/jpeg');
    }

    readyImage(event) {
        this.cropperRes = this.angularCropper.cropper.getCroppedCanvas().toDataURL('image/jpeg');
    }

    rotate(turn) {
        turn = turn === 'left' ? -45 : 45;
        this.angularCropper.cropper.rotate(turn);
        this.cropperRes = this.angularCropper.cropper.getCroppedCanvas().toDataURL('image/jpeg');
    }

    destroy(event) {
        this.angularCropper.cropper.destroy();
    }

    zoomManual() {
        this.cropperRes = this.angularCropper.cropper.getCroppedCanvas().toDataURL('image/jpeg');
    }

    zoom(status) {
        status = status === 'positive' ? 0.1 : -0.1;
        this.angularCropper.cropper.zoom(status);
        this.cropperRes = this.angularCropper.cropper.getCroppedCanvas().toDataURL('image/jpeg');
    }

    move(offsetX, offsetY) {
        this.angularCropper.cropper.move(offsetX, offsetY);
        this.cropperRes = this.angularCropper.cropper.getCroppedCanvas().toDataURL('image/jpeg');
    }

    scale(offset) {
        if (offset === 'x') {
        this.angularCropper.cropper.scaleX(-1);
        } else {
        this.angularCropper.cropper.scaleY(-1);
        }
        this.cropperRes = this.angularCropper.cropper.getCroppedCanvas().toDataURL('image/jpeg');
    }

    clear() {
        this.angularCropper.cropper.clear();
        this.cropperRes = this.angularCropper.cropper.getCroppedCanvas().toDataURL('image/jpeg');
    }

    disable() {
        this.angularCropper.cropper.disable();
        this.cropperRes = this.angularCropper.cropper.getCroppedCanvas().toDataURL('image/jpeg');
    }

    enable() {
        this.angularCropper.cropper.enable();
        this.cropperRes = this.angularCropper.cropper.getCroppedCanvas().toDataURL('image/jpeg');
    }

    reset() {
        this.angularCropper.cropper.reset();
        this.cropperRes = this.angularCropper.cropper.getCroppedCanvas().toDataURL('image/jpeg');
    }

    saveImg() {
        this.savedImg = true;
        this.draw(this.cropperRes);
    }

    mergeAll() {
        mergeImages(this.cropperResults).then(b64 => this.mergedRes = b64);
    }

    mouseOver() {
        // this.usd.showTools = true;
        // console.log('IN');
    }

    mouseOut() {
        // console.log('OUT');
        // this.usd.showTools = false;
    }

    pause() { }

    
    checkDates() {
        console.log(this.setSub.startDate);
        console.log(this.setSub.endDate);
        if (moment(this.setSub.endDate).isBefore(moment(this.setSub.startDate))) {
        moment(this.setSub.startDate);
        this.startDateError = {
            status: true, msg: "date can't be after Finish date"
        };
        } else { this.startDateError = { status: false, msg: '' }; }
        if (moment(this.setSub.startDate).isAfter(moment(this.setSub.endDate))) {
        console.log(moment(this.setSub.startDate).diff(moment(this.setSub.endDate)));
        this.endDateError = {
            status: true,  msg: 'select a date after Start date'
        };
        } else { this.endDateError = { status: false, msg: '' }; }
    }

    restore() {
        setTimeout(() => {
            this.setDone = false;
            this.setEdit = false;
            this.showList = true;
            this.showUnit = false;
            this.toDelete = false;
            this.setunattended = false;
        }, 2000);
    }

    fileChangeEvent(event: any) {
        this.imageChangedEvent = event;
        const file = event.target.files[0];
        console.log(file);
    }

    imageCropped(event: CroppedEvent) {
        this.base64 = event.base64;
        // console.log(this.base64);
        const filedDoc = event.file;
        console.log(filedDoc);
        this.ws.uploadEntLogo(filedDoc);
    }

    nit(unit) {
        console.log(unit);
    }

    // getData() {
    //     if (this.setSub.projectId !== '' && this.setSub.companyId !== '') {
    //         this.afs.collection(`Projects/${this.setSub.projectId}/enterprises/${this.setSub.companyId}/Rates`,
    //             ref => ref.orderBy('name', 'asc')).valueChanges().subscribe(rates => {
    //                 this.Rts = rates;
    //                 console.log(rates);
    //             });
    //     } else if (this.setSub.companyId) {
    //         this.afs.collection(`Enterprises/${this.setSub.companyId}/Rates`,
    //             ref => ref.orderBy('name', 'asc')).valueChanges().subscribe(rates => {
    //                 this.Rts = rates;
    //                 console.log(rates);
    //             });
    //     } else {
    //         this.Rts = [{ name: 'No Type of Works', id: 'No Type of Works' }]
    //     }
    // }

    getParticipants(task: Task) {
        this.taskset = task
        this.oldParts = task.participants;
        if (!task.companyId && !task.projectId) {
            this.staff = this.usd.myContacts;
            console.log(this.staff, '1st If statement');
        } else if (task.companyId && task.projectId) {
            this.afs.collection(`Projects/${task.projectId}/enterprises/${task.companyId}/labour`, ref => ref.orderBy('name', 'asc'))
                .valueChanges().subscribe(labour => { this.staff = labour; console.log(this.staff, '2nd if statement'); });
        } else if (task.companyId && !task.projectId) {
            this.afs.collection(`Enterprises/${task.companyId}/Participants`, ref => ref.orderBy('name', 'asc')).valueChanges()
                .subscribe(staff => { this.staff = staff; console.log(this.staff, '3rd if Statement'); });
        }
    }

    getRatePop() {
        console.log(this.yut.unit);
        this.setSub.name = this.yut.name;
        this.setSub.unit = this.yut.unit;
        this.setSub.rate = this.yut.rate;
    }

    actSet() {
        console.log(this.setSub.startDate);
        console.log(this.setSub);
        if (this.setSub.startDate !== '') {
            this.showsCal = true;
        } else {
            this.showsCal = false;
        }
        if (this.setSub.endDate !== '') {
            this.showfCal = true;
        } else {
            this.showfCal = false;
        }
    }

    setComplete() {
        this.ws.setComplete(this.taskset);
        this.setDone = !this.setDone;
    }

    setEditedSub() {
        if (this.setEnterprise) {
            this.setSub.companyId = this.setEnterprise.id;
            this.setSub.companyName = this.setEnterprise.name;
        }
        if (this.setProject) {
            this.setSub.projectId = this.setProject.id;
            this.setSub.projectName = this.setProject.name;
        }
        // this.ws.setEditedSub(this.setSub, startDate, endDate);
        this.ws.setEditedSub(this.setSub);
    }

    saveActual(actual: actualData, setTask) {
        actual.updateTime = moment().toString();
        console.log(actual);
        return this.ws.saveActual(actual, setTask, this.usd.taskset);
    }

    selectTask(item: Task) {
        console.log(item.projectId, item.projectName);
        this.setTask = item;
    }

    public openModal(template: TemplateRef<any>) {
        this.modalRef = this.modalService.open(template);
        // this.modalService.dismissAll()
        this.usd.myDocument.ref.get().then(() => {
            this.playAudio();
        })
    }

    openLgg(template: TemplateRef<any>) {
        this.modalRef2 = this.modalService.open(template, { size: 'lg' })
    }

    openChat(template: TemplateRef<any>) {
        this.chatModalRef = this.modalService.open(template, { size: 'lg' });
    }

    openModal2(template: TemplateRef<any>) {
        this.modalRef = this.modalService.open(template, { size: 'lg' });
        // this.usd.myDocument.ref.get().then(() => {
        //     this.playAudio();
        // })
    }

    unattendedModal() {
        setTimeout(() => {
            if (this.setunattended) {

            } else {
                this.ws.unattendedModal();
            }
        }, 12000);
    }

    upDateTime(task) {
        this.setunattended = true;
        this.ws.upDateTime(task);
    }

    updateAction(workAction: Task) {
        this.ws.updateAction(workAction);
    }

    /* Tasking functions */

    addSelfarch() {
        this.is.showEmaiCheck = true;
        this.postpendHours = false;
        this.usd.AddEquip = false;
        this.usd.selectedHiredEquipment = [];
        this.usd.selectedEquipment = [];
        // this.newAss = this.is.initAsset();
        // this.hiredAss = this.is.initAsset();
        this.newAsset = this.is.initAsset();
        this.hiredAsset = this.is.initAsset();
        this.usd.addCompAssPlg = true;
        this.usd.newHiredAsset = false;
        this.classification = this.is.getClassInit();
        this.usd.task = {
            name: '', description: '', update: '', champion: {
                name: '', phoneNumber: '', by: '', byId: '', createdOn: '',
                bus_email: '', id: '', department: '', departmentId: '', photoURL: '', address: '', nationalId: '', nationality: '',
                hierarchy: '', supervisor: { name: '', id: '' }
            }, projectName: '', supervisor: { id: '', name: '' }, department: '', startTime: '', finishTime: '',
            rate: '', type: '', UpdatedOn: '', selectedWeekWork: false, selectedWork: false, departmentId: '', startDate: '',
            endDate: '', by: '', createdOn: '', projectId: '', byId: '', projectType: '', toDos: [], companyName: '', companyId: '',
            trade: '', section: null, complete: false, id: '', participants: [], status: '', docs: [], selectedWeekly: false,
            classification: null, unit: '', workStatus: '', targetQty: '', actualData: [], actualQty: 0, amount: 0
        };
        this.usd.task.champCompany = { name: '', id: '' };
        if (this.is.toolSet === 'Personal') {
            const index = this.usd?.tasksChamps?.findIndex(man => man.id === this.usd.acc.id);
            if(this.usd.tasksChamps === undefined) {this.usd.tasksChamps = []}
            if (index > -1) { } else {
                if (this.usd.acc.id) {
                    if (this.usd.tasksChamps?.length === 0) {
                        this.usd.tasksChamps = [this.usd.acc];
                    } else {
                        console.log(this.usd.tasksChamps);
                        this.usd.tasksChamps.push(this.usd.acc);
                    }
                } else {
                    setTimeout(() => {
                        if (this.usd.acc.id) {
                            if (this.usd.tasksChamps?.length === 0) {
                                this.usd.tasksChamps = [this.usd.acc];
                            } else {
                                console.log(this.usd.tasksChamps);
                                this.usd.tasksChamps.push(this.usd.acc);
                            }
                        } 
                    }, 3000);
                }
            }
            this.classification = this.is.getClassInit();
            this.usd.task.champion = this.usd.acc;
            if (this.is.chatSet) {
                this.pageChampion();
            }
            // console.log(this.usd.task.champion);
        } else if (this.is?.toolSet === 'Enterprise') {
            this.usd.task.companyId = this.usd.setCompany.id;
            this.usd.task.companyName = this.usd.setCompany.name;
            this.usd.task.champion = this.is.companystaff;
            if (this.is.chatSet) {
                // this.pageChampion();
                // }
                console.log(this.usd.task.champion);
            }
        } else if (this.is?.toolSet === 'Project') {
            this.usd.task.projectId = this.usd.setProject.id;
            this.usd.task.projectName = this.usd.setProject.name;
            this.usd.task.companyId = this.usd.setProject.companyId;
            this.usd.task.companyName = this.usd.setProject.companyName;
            this.usd.task.champion = this.is.companystaff;
            if (this.is.chatSet) {
                // this.pageChampion();
            }
            console.log(this.usd.task.champion);
            // }
        } else if (this.is?.toolSet === 'proj-team') {
            console.log('proj-team');
            this.setCompany = { name: this.usd.setProject.companyName, id: this.usd.setProject.companyId };
            this.usd.task.champCompany = this.usd.projectTeam;
            const indx = this.usd.enterprises.findIndex(cmp => cmp.id === this.usd.projectTeam.id);
            if (indx > -1) {
                this.usd.showChampSelect = true;
                this.ps.getTeamLabour(this.usd.projectTeam).subscribe(labour => {
                    this.teamLabour = labour;
                })
            } else {
                // this.usd.showChampSelect = false;
                this.usd.showChampSelect = true;
                this.teamLabour = this.usd.projectTeam?.managers;
            }
            this.usd.task.department = '';
            this.usd.task.departmentId = '';
            this.usd.task.projectId = this.usd.setProject.id;
            this.usd.task.projectName = this.usd.setProject.name;
            this.usd.task.companyId = this.usd.setProject.companyId;
            this.usd.task.companyName = this.usd.setProject.companyName;
            this.usd.task.champion = this.is.companystaff;
            if (this.is.chatSet) {
                // this.pageChampion();
            }
            console.log(this.usd.task.champion);
            // }
        } else if (this.is?.toolSet === 'client') {
            this.usd.task.champCompany = { name: this.usd.setClient?.name, id: this.usd.setClient?.id }
            this.usd.task.companyId = this.usd.setCompany.id;
            this.usd.task.companyName = this.usd.setCompany.name;
            this.usd.task.champion = this.is.companystaff;
            if (this.is.chatSet) {
                // this.pageChampion();
            }
            console.log(this.usd.task.champion);
        }
        // this.champion = { name: '', email: '' };
        this.usd.champion = { name: '', email: '' };
        this.showColUsers = true; this.showEmaiCheck = true; this.newUnitfrm = false;
        this.fromClient = false;
        this.unit = { name: '', id: '' };
    }

    setChamp(comp) {
        this.usd.task.champCompany = { name: comp?.name, id: comp?.id }
    }

    pageChampion() {
        this.usd.task.champion = this.usd.setContact;
    }

    getRate() {
        console.log(this.yut.unit);
        this.usd.task.name = this.yut.name;
        // this.usd.task.unit = this.yut.unit;
        if (this.yut.rate) {
            this.usd.task.rate = this.yut.rate;
        } else {
            this.usd.task.rate = '';
        }
    }

    getUnit(unit) {
        this.usd.task.unit = unit.id;
    }

    /* End of tasking Functions */

    ngOnInit() {
        this.future = new Date();
        this.counter$ = Observable.interval(1000).map((x) => {
            return Math.floor((this.timedstamp - new Date().getTime()) / 1000);
        });
        this.subscription = this.counter$.subscribe((x) => this.message = this.dhms(x));
        // console.log(this.message);

        const $sidebar = $('.sidebar');
        const $off_canvas_sidebar = $('.off-canvas-sidebar');
        const window_width = window.outerWidth;

        if (window_width > 767) {
            if ($('.fixed-plugin .dropdown').hasClass('show-dropdown')) {
                $('.fixed-plugin .dropdown').addClass('open');
            }

        }

        $('.fixed-plugin a').click(function (event) {
            if ($(this).hasClass('switch-trigger')) {
                if (event.stopPropagation) {
                    event.stopPropagation();
                } else if (window.event) {
                    window.event.cancelBubble = true;
                }
            }
        });

        $('.fixed-plugin .background-color span').click(function () {
            $(this).siblings().removeClass('active');
            $(this).addClass('active');

            const new_color = $(this).data('color');
            if ($sidebar.length !== 0) {
                $sidebar.attr('data-color', new_color);
            }

            if ($off_canvas_sidebar.length !== 0) {
                $off_canvas_sidebar.attr('data-color', new_color);
            }
        });

        $('.fixed-plugin .active-color span').click(function () {
            $(this).siblings().removeClass('active');
            $(this).addClass('active');

            const new_color = $(this).data('color');

            if ($sidebar.length !== 0) {
                $sidebar.attr('data-active-color', new_color);
            }

            if ($off_canvas_sidebar.length !== 0) {
                $off_canvas_sidebar.attr('data-active-color', new_color);
            }
        });
        this.checkHours();
        
      }
    
      ngOnDestroy(): void {
        /**
        * Unsubscribe all subscriptions to avoid memory leak
        */
        this.usd?.subscriptions.forEach(subscription => subscription.unsubscribe());
      }

    checkHours() {
        this.totalhours = 0;
        this.usd.dayTasks.forEach(tsk => this.totalhours += tsk.day.hours);

    }

    dhms(t) {
        this.timedstamp += 1;
        // console.clear();
        this.mygetTime();
        const time = moment().format('LTS')
        return time;
    }

    async mygetTime() {
        const d = new Date();
        const nMin = d.getMinutes();
        const nSecs = d.getSeconds();
        this.nSecs = Math.floor(nSecs);
        this.nMin = Math.floor(nMin);
        this.cloakTime = moment().format('HH:mm:ss');
        // this.cloakTime = [this.nHrs + ':', this.nMin + ':', this.nSecs].join(' ');
        this.usd.afAuth.authState.subscribe(() => {
            // if (this.nSecs === 0) {
            if (this.nMin % 30 === 0 && this.nSecs === 0) {
                this.setunattended = false;
                this.playAudio();
                // this.openLgg(this.template2);
                this.postpendHours = true;
                this.openLgg(this.template2);
                console.log('timing 2');

                this.unattendedModal();
            };
        })
        
        this.usd.connectivity();
        return this.cloakTime;
    }

    playAudio() {
        const audio = new Audio();
        audio.src = '../../../assets/audio/nfl.mp3';
        audio.load();
        audio.play();
    }

    playIntoAudio() {
        const audio = new Audio();
        audio.src = '../../../assets/audio/lg.mp3';
        audio.load();
        audio.play();
    }

    dragStart(event: CdkDragStart) {
        // this.bodyElement.classList.add('inheritCursors');
        this.bodyElement.style.cursor = 'grabbing';
    }

    onDrop(event: CdkDragDrop<any[]>) {
        this.bodyElement.style.cursor = 'pointer';
        moveItemInArray(this.usd.diaryTasks, event.previousIndex, event.currentIndex);
        // this.ws.addOrder(this.usd.diaryTasks[event.currentIndex], event.currentIndex);
        console.log(this.usd.diaryTasks[event.currentIndex]?.description || this.usd.diaryTasks[event.currentIndex]?.name, event.currentIndex);
        this.usd.diaryTasks.sort((a,b) => a?.orderNo - b?.orderNo);
        // this.ws.orderTasks(this.usd.diaryTasks);
    }

    saveDiary() {
        this.ws.orderTasks(this.usd.diaryTasks);
        this.ns.showNotification("ThingsToDoUpdated", "top", "right", "Success");
    }

    transformInitSum(element) {
        this.usd.setProject.initSum = this.currencyPipe.transform(this.usd.setProject.initSum, '$');
        element.target.value = this.usd.setProject.initSum;
    }

    transformExitSum(element) {
        this.usd.setProject.exitSum = this.currencyPipe.transform(this.usd.setProject.exitSum, '$');
        element.target.value = this.usd.setProject.exitSum;
    }

    transformRate(element) {
        this.ob.inventory.rate.value = this.currencyPipe.transform(this.ob.inventory.rate.value, '$');
        element.target.value = this.ob.inventory.rate.value;
    }
    
    transformContSum(element) {
        this.usd.setProject.contractSum = this.currencyPipe.transform(this.usd.setProject.contractSum, '$');
        element.target.value = this.usd.setProject.contractSum;
    }

    transformLieuAmount(element) {
        this.ob.payTransaction.amountInLieu = this.currencyPipe.transform(this.ob.payTransaction.amountInLieu, '$');
        element.target.value = this.ob.payTransaction.amountInLieu;
    }

    prjUpdate() {
        const inRef = this.afs.doc(`Projects/${this.usd.setProject.id}`);
        inRef.update({ 'phase': this.usd.setProject.phase }).then(() => console.log('phase updated')).catch(er => console.log(er));
        inRef.update({ 'initSum': this.usd.setProject.initSum }).then(() => console.log('initSum updated')).catch(er => console.log(er));
        inRef.update({ 'exitSum': this.usd.setProject.exitSum }).then(() => console.log('exitSum updated')).catch(er => console.log(er));
        inRef.update({ 'contractSum': this.usd.setProject.contractSum }).then(() => console.log('contractSum updated')).catch(er => console.log(er));
    }
}
