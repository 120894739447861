import { Injectable, Optional } from '@angular/core';
import { Task, actualData, actionActualData } from 'app/models/task-model';
// import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
// import { AngularFireStorage } from '@angular/fire/storage';
import { HttpClient } from '@angular/common/http';
// import * as firebase from 'firebase/app';
import { ServicesConfig } from './services-config';
import { Enterprise, ParticipantData } from 'app/models/enterprise-model';
import { InitialiseService } from './initialise.service';
import { UserDataService } from './user-data.service';
import * as moment from 'moment';
import { map, find, finalize, findIndex } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationService } from './notification.service';
import { WriteBaseService } from './write-base.service';
import { Project, Section, subSection, superSections } from 'app/models/project-model';
import { EntServiceService } from './ent-service.service';
import { ProjServiceService } from './proj-service.service';
import { HttpService } from './http.service';
import { string } from '@amcharts/amcharts4/core';
import { RouteService } from './route.service';
import { TaskService } from './task.service';
import { Applicant } from 'app/models/user-model';
import { OpenBooksService } from './open-books.service';
import { agentFootprint, compTans, Product, transaction } from 'app/models/user';
import { MongodbService } from './mongodb.service';
import { PublicService } from './public.service';
import { IfStmt } from '@angular/compiler';

@Injectable({
  providedIn: "root",
})
export class WriteService extends WriteBaseService {
  uploadPercent: number;
  picUploadPercent: number;
  downloadURL: Observable<any>;
  fileValue: any;
  docs: any[];
  rptDocs: any[];
  public newComment = false;
  public editComment = false;
  setIndex = -1;
  Comment = { name: "", id: "", byId: "", by: "", createdOn: "", photoUrl: "" };
  deleteComment = false;
  constructor(
    /* private afs: AngularFirestore,  */ private is: InitialiseService,
    private usd: UserDataService,
    public modalService: NgbModal,
    public router: Router,
    private as: ActivatedRoute,
    private ns: NotificationService,
    /*  private storage: AngularFireStorage, */ public pub: PublicService,
    public es: EntServiceService,
    public ps: ProjServiceService,
    public rs: RouteService,
    private http: HttpService,
    public ob: OpenBooksService,
    public tsk: TaskService,
    private prot: HttpClient,
    private mdb: MongodbService,
    @Optional() config?: ServicesConfig
  ) {
    super();
    // console.log(moment().format('HH:mm'));
    if (
      usd.workTaskNotices?.length > 0 &&
      moment().format("dddd") === "Wednesday" &&
      usd.acc.taskNotice !== moment().toISOString()
    ) {
      usd.myDocument.update({ taskNotice: moment().toISOString() });
      const mailData = {
        by: {
          id: usd?.acc?.id,
          name: usd?.acc?.name,
          email: usd?.acc?.bus_email || usd?.acc.email,
          photoUrl: usd?.acc?.photoURL || "",
        },
        name: usd.acc.name,
        email: usd?.acc?.bus_email || usd.acc.email,
        subjet: "Notice",
        text: "Notice",
      };
      this.commonMail(mailData);
    }
  }

  taskCount(task) {
    if (task.upcount) {
      task.upcount += 1;
    } else {
      task.upcount = 1;
    }
    this.usd.afs
      .doc(`Users/${this.usd.userId}/tasks/${task.id}`)
      .update({ upcount: task.upcount })
      .then(() => {
        //  console.log('upcount updated');
      })
      .catch((er) => console.log("failed to update task upcount", er));
  }

  tutorialOnOff(v: boolean) {
    this.usd.myDocument
      .update({ showTutorial: v })
      .then(() => {
        //  console.log('showTutorial set to true');
      })
      .catch((er) => console.log(er));
  }

  sodo(task, champion, unit, classification) {
    task.by = this.usd.acc.name;
    task.byId = this.usd.acc?.id;
    if (champion?.name !== "" && champion?.email !== "") {
      //  console.log(champion.name && champion.email);
      task.champion = champion;
      //  console.log('Creating in Champ');
      const user = {
        by: {
          id: this.usd?.acc?.id,
          name: this.usd?.acc?.name,
          email: this.usd?.acc?.bus_email || this.usd?.acc.email,
          photoUrl: this.usd?.acc?.photoURL || "",
        },
        task: task,
        name: champion.name,
        email: champion.email,
        subjet: "New Task Notice",
        text: "New Task Notice",
      };
      this.sendChampemail(user);
    }
    return this.is.getTask();
  }

  justify(task, unit, classification) {
    if (this.is?.toolSet === "Enterprise") {
      task.companyId = this.usd.setCompany.id;
      task.companyName = this.usd.setCompany.name;
    }
    if (this.is?.toolSet === "Project") {
      task.projectId = this.usd.setProject.id;
      task.projectName = this.usd.setProject.name;
      task.project = {
        id: this.usd.setProject.id,
        name: this.usd.setProject.name,
      };
      task.company = {
        id: this.usd.setProject.companyId,
        name: this.usd.setProject.companyName,
      };
    }
    if (task.endDate !== "") {
      task.endDate = moment(task.endDate, "YYYY-MM-DD").format("L");
    } else {
      task.endDate = "";
    }
    if (task.startDate !== "") {
      task.startDate = moment(task.startDate, "YYYY-MM-DD").format("L");
    } else {
      task.startDate = "";
    }
    if (!task?.supervisor) {
      task.supervisor = {};
    } else {
      console.log(task.supervisor);
    }
    if (!task?.equipment) {
      task.equipment = [];
    } else {
      console.log(task.equipment);
    }
    if (!task?.section) {
      task.section = {};
    } else {
      console.log(task.section);
    }
    if (task.department) {
      const indx = this.es.departments.findIndex(
        (dpt) => dpt.name === dpt.name
      );
      if (indx > -1) {
      } else {
        this.saveDept(task.department);
      }
    }
    if (task.section && task.section.sectionNo) {
      task.section = {
        Bills: task.section.Bills,
        No: task.section.No,
        companyId: task.section.companyId,
        companyName: task.section.companyName,
        id: task.section.id,
        name: task.section.name,
        no: task.section.no,
        projectId: task.section.projectId,
        projectName: task.section.projectName,
        sectionId: task.section.sectionId,
        sectionName: task.section.sectionName,
        sectionNo: task.section.sectionNo,
        type: task.section.type,
      };
    }
    if (!task.projectId && !task.companyId) {
      const indx = this.usd.types.findIndex((tsk) => tsk.name === task.name);
      if (indx > -1) {
      } else {
        const typ = {
          name: task.name,
          unit: task.unit,
          by: this.usd.acc.name,
          byId: this.usd.acc.id,
          createdOn: task.createdOn,
        };
        this.addtype(typ);
      }
    } else if (!task.projectId && task.companyId) {
      task.classification = {
        name: "Work",
        createdOn: new Date().toISOString(),
        id: "colourWorkId",
        plannedTime: "",
        actualTime: "",
        Varience: "",
      };
      const indx = this.es?.Rates.findIndex((tsk) => tsk.name === task.name);
      if (indx > -1) {
      } else {
        const typ = {
          name: task.name,
          unit: task.unit,
          by: this.usd.acc.name,
          byId: this.usd.acc.id,
          createdOn: task.createdOn,
          company: {id: task.companyId, name: task.companyName},
        };
        this.addEntStandards(typ);
      }
    } else if (task.projectId) {
      const indx = this.ps?.rates.findIndex((tsk) => tsk.name === task.name);
      if (indx > -1) {
      } else {
        const typ = {
          name: task.name,
          unit: task.unit,
          by: this.usd.acc.name,
          byId: this.usd.acc.id,
          rate: this.usd.acc.id,
          createdOn: task.createdOn,
        };
        this.addRates(typ, this.usd.setProject);
      }
    }
    if (classification && classification.name !== "") {
      task.classification = this.addTaskClass(classification);
    }
    if (unit && unit.id !== "" && unit.name !== "") {
      task.unit = unit.id;
      const uniFnd = (this.is.SIunits || this.is.allunits).find(
        (u) => u.name === unit.name || u.id === unit.id
      );
      if (!uniFnd) {
        this.usd.afs.collection(`units`).add(unit).then((Ref) => {
          const unitid = Ref.id;
          unit.uid = unitid; unit.unicode = "";
          this.usd.afs.doc(`units/${unitid}`).update({ uid: unitid })
          .then(() => console.log(unit, "updated successfully"))
          .catch((er) => console.log(`faled,${er.message}`));
          console.log("unit added");
        }).catch((er) => console.log(er));
      }
    }
    if (task.projectId && task.companyId) {
      const newClassification = {
        name: "Work", createdOn: new Date().toISOString(),
        id: "colourWorkId", plannedTime: "", actualTime: "", Varience: "",
      };
      task.classification = newClassification;
    }
    return task;
  }

  taskDates(task) {
    console.log(task.startDate, task.endDate);
    // const format = "L";
    const MMDDYYYY = "MM/DD/YYYY";
    const YYYYMMDD = "YYYY-MM-DD";
    const isEndMMDDYYYY = moment(task.endDate, MMDDYYYY, true).isValid();
    const isStartMMDDYYYY = moment(task.startDate, MMDDYYYY, true).isValid();
    const isEndYYYYMMDD = moment(task.endDate, YYYYMMDD, true).isValid();
    const isStartYYYYMMDD = moment(task.startDate, YYYYMMDD, true).isValid();
    if (isEndMMDDYYYY) {
      console.log("End", task.endDate);
    } else if (isEndYYYYMMDD) {
      task.endDate = moment(task.endDate, "YYYY-MM-DD").format("L");
      console.log("End", task.endDate);
    } else {
      // task.endDate = '';
      console.log("End", task.endDate);
    }
    if (isStartMMDDYYYY) {
      console.log("Start", task.startDate);
    } else if (isStartYYYYMMDD) {
      task.startDate = moment(task.startDate, "YYYY-MM-DD").format("L");
      console.log("Start", task.startDate);
    } else {
      // task.startDate = '',
      console.log("Start", task.startDate);
    }
    return task;
  }

  addTask(rawtask, champion, unit, classification) {
    rawtask.champion = {
      name: rawtask.champion.name || "",
      id: rawtask.champion.id, email: rawtask.champion.email || "",
      bus_email: rawtask.champion.bus_email || rawtask.champion.email,
      phoneNumber: rawtask.champion.phoneNumber || "",
      photoURL: rawtask.champion.photoURL || "",
      address: rawtask.champion.address || "",
      nationalId: rawtask.champion.nationalId || "",
      nationality: rawtask.champion.nationality || "",
    };
    let task = this.justify(rawtask, unit, classification);
    task.by = this.usd.acc.name;
    task.byId = this.usd.acc?.id;
    task.createdOn = new Date().toISOString();
    task.update = new Date().toISOString();
    console.log(task.startDate, task.endDate);
    // set task under a user
    let newTaskId;
    const tasksRef = this.usd.afs.collection("tasks");
    const userRef = this.usd.afs.collection(`Users/${task.byId}/tasks`);
    console.log(task.champion);
    tasksRef.add(task).then((Ref) => {
      newTaskId = Ref.id; task.id = Ref.id;
      // createdTask.id = Ref.id;
      tasksRef.doc(newTaskId).update({ id: newTaskId });
      // set task under a champion
      /* if task has participants */
      this.addParticipants(task);
      userRef.doc(newTaskId).set(task).then(() => {}).catch((er) => console.log(er));
      if (champion?.name !== "" && champion?.email !== "") {
        console.log(champion.name, champion.email);
        task.champion = champion;
        //  console.log(task.champion);
        this.updateTimeLine(task); this.taskMailer(task, champion);
      } else if (task.champion && task.champion.id !== "") {
        console.log("running else");
        this.taskingDeps(task); this.updateTimeLine(task);
      }
    })
    .then(() => {}).catch((er) => console.log(er));
  }

  addParticipants(task: any) {
    if (task.participants?.length > 0) {
      task.participants.forEach((element) => {
        // actualData: [], workHours: [], days: [], actualQty: 0, amount: 0, workHoursRpt: []
        this.usd.afs.doc(`Users/${element.id}/tasks/${task.id}`).set(task).then(() => {
          this.usd.afs.doc(`Users/${element.id}/tasks/${task.id}`).update({
            actualData: [], workHours: [], workHoursRpt: [], days: [],
          });
        })
        .catch((error) => {
          // console.log('Error byId document updating Completion, document does not exists trying Again', error);
        });
      });
    }
  }

  taskingDeps(task) {
    console.log(`Creating for taskingDeps`);
    if (task.byId !== task.champion.id) {
      console.log(`Creating for Champ ${task?.champion?.name}`);
      this.usd.afs
        .doc(`Users/${task.champion.id}/tasks/${task.id}`)
        .set(task)
        .then(() => {
          const notice = task;
          console.log("running champion notice");
          notice.noti = "taskNotification";
          notice.read = false;
          notice.notRing = false;
          notice.noticeDate = moment().toISOString();
          // this.usd.afs.collection('Users').doc(task.champion.id).collection('taskNotification').doc(newTaskId).set(notice).then(() => {
          this.usd.afs
            .doc(`Users/${task.champion.id}/allNotice/${task.id}`)
            .set(notice)
            .then(() => {})
            .catch((err) => {
              // console.log('Error logged: Task sending failed', err);
              this.usd.afs
                .doc(`Users/${task.champion.id}/allNotice/${task.id}`)
                .set(notice)
                .then(() => {
                  // console.log('The task has been sent to' + ' ' + createdTask.champion.name + ' ' + ' on 2nd attempt');
                })
                .catch((errz) => {
                  console.log(
                    "Error logged: Task sending failed on 2nd attempt",
                    errz
                  );
                });
            });
          const mailData = {
            by: {
              id: this.usd?.acc?.id,
              name: this.usd?.acc?.name,
              email: this.usd?.acc?.bus_email || this.usd?.acc.email,
              photoUrl: this.usd?.acc?.photoURL || "",
            },
            task: task,
            name: task.champion.name,
            email: task.champion.bus_email || task.champion.email,
            subjet: "New Task Notice",
            text: "New Task Notice",
          };
          this.emailChamp(mailData);
        });
    }
    if (this.is?.toolSet === "client") {
      let admin;
      task.companyId = this.usd.setCompany.id;
      task.companyName = this.usd.setCompany.name;
      if (this.usd.setClient?.managers.length > 0) {
        admin = this.usd.setClient?.managers;
        task.participants = admin;
        this.managersTasking(task, admin);
      } else {
        this.notifyManagers(task, this.usd.setClient);
      }
      // end of clent update
    }
    if (this.is?.toolSet === "Project Companies") {
      let admin;
      this.usd.afs
        .doc<Task>(`Enterprises/${this.usd.projectTeam.id}/tasks/${task?.id}`)
        .set(task);
      if (this.usd.projectTeam?.managers.length > 0) {
        admin = this.usd.projectTeam?.managers;
        this.managersTasking(task, admin);
      } else {
        this.notifyManagers(task, this.usd.projectTeam);
      }
    }
    setTimeout(() => {
      if (this.is.toolSet === "Personal") {
      } else if (this.is.toolSet === "Enterprise") {
        this.es.getComptasks(task);
      } else if (this.is.toolSet === "Project") {
        this.ps.returnAllProjectTasks();
      }
    }, 3000);
  }

  taskMailer(task, champion) {
    const mailData = {
      by: {
        id: this.usd?.acc?.id,
        name: this.usd?.acc?.name,
        email: this.usd?.acc?.bus_email || this.usd?.acc.email,
        photoUrl: this.usd?.acc?.photoURL || "",
      },
      task: task,
      name: champion.name,
      email: champion.email,
      subjet: "New Task Notice",
      text: "New Task Notice",
    };
    if (!task.projectId && !task.companyId) {
      //  console.log('sending in Personal');
      this.sendChampemail(mailData);
    } else if (!task.projectId && task.companyId) {
      //  console.log('sending in Enterprise');
      this.sendEntChampemail(mailData);
    } else if (task.projectId && task.companyId) {
      //  console.log('sending in Project');
      this.sendPrjChampemail(mailData);
    } else if (task.projectId && !task.companyId) {
      this.sendChampemail(mailData);
    }
  }

  updateTimeLine(task) {
    if (this.is?.toolSet === "Enterprise") {
      this.usd?.myTasks?.push(task);
      this.es.tasks?.push(task);
      this.es.tasks?.sort((a, b) => b.update.localeCompare(a.update));
      this.usd?.myTasks.sort((a, b) => b.update.localeCompare(a.update));
      this.es.allActions = this.es.tasks;
    } else if (this.is?.toolSet === "Project") {
      this.ps.allActions?.push(task);
      this.usd?.myTasks?.push(task);
      this.ps.allActions?.sort((a, b) => b.update.localeCompare(a.update));
      this.usd?.myTasks?.sort((a, b) => b.update.localeCompare(a.update));
    } else if (this.is?.toolSet === "Personal") {
      this.usd?.myTasks?.push(task);
      this.usd?.myTasks?.sort((a, b) => b.update.localeCompare(a.update));
    } else {
      this.usd?.myTasks?.push(task);
      this.usd?.myTasks?.sort((a, b) => b.update.localeCompare(a.update));
    }
  }

  tasker(task, unit) {
    task.by = this.usd.acc.name;
    task.byId = this.usd.acc?.id;
    if (this.is.toolSet === "Project Companies") {
      task.companyId = task.champCompany.id; task.companyName = task.champCompany.name;
      task.projectId = this.usd.setProject.id; task.projectName = this.usd.setProject.name;
    }
    if (this.is.toolSet === "proj-team") {
      task.champCompany = this.usd.projectTeam; task.companyId = this.usd.setProject.companyId;
      task.companyName = this.usd.setProject.companyName; task.projectId = this.usd.setProject.id;
      task.projectName = this.usd.setProject.name;
    }
    if (this.is.toolSet === "Customers" && !task.companyId) {
      task.companyId = this.usd.setCompany.id; task.companyName = this.usd.setCompany.name;
    }
    task.createdOn = new Date().toISOString();
    task.update = new Date().toISOString();
    const tasksRef = this.usd.afs.collection("tasks");
    const userRef = this.usd.afs.collection(`Users/${task.byId}/tasks`);
    let newTaskId;
    if (!task.projectId && !task.companyId) {
      const indx = this.usd.types.findIndex((tsk) => tsk.name === task.name);
      if (indx > -1) {
      } else {
        const typ = {
          name: task.name, unit: task.unit, by: this.usd.acc.name, byId: this.usd.acc.id, createdOn: task.createdOn,
        };
        this.addtype(typ);
      }
    } else if (!task.projectId && task.companyId) {
      const indx = this.es?.Rates.findIndex((tsk) => tsk.name === task.name);
      if (indx > -1) {} else {
        const typ = {
          name: task.name, unit: task.unit, by: this.usd.acc.name, byId: this.usd.acc.id, createdOn: task.createdOn,
          company: {id: task.companyId, name: task.companyName},
        };
        this.addEntStandards(typ);
      }
    } else if (task.projectId || task.companyId) {
      const indx = this.ps?.rates.findIndex((tsk) => tsk.name === task.name);
      if (indx > -1) {} else {
        const typ = {
          name: task.name, unit: task.unit, by: this.usd.acc.name,
          byId: this.usd.acc.id, rate: this.usd.acc.id, createdOn: task.createdOn,
        };
        this.addRates(typ, this.usd.setProject);
      }
    }
    if (unit && unit.id !== "" && unit.name !== "") {
      task.unit = unit.id;
      this.usd.afs.doc(`units/${unit.id}`).set(unit).then(() => console.log("unit added"))
        .catch((er) => console.log(er));
    }
    // console.log('Task', task);
    const createdTask = task;
    // console.log('running else');
    userRef.add(createdTask).then((Ref) => { newTaskId = Ref.id; createdTask.id = Ref.id; }).then(() => {
      userRef.doc(newTaskId).update({ id: newTaskId });
      // set task under a root
      tasksRef.doc(newTaskId).set(createdTask).then(() => {console.log('task saved in root')}).catch((er) => console.log(er));
      this.addParticipants(createdTask);
      if (task.champion && task.champion.id !== "") {
        // console.log('Adding Champions Task');
        this.usd.afs.doc(`Users/${task.champion.id}/tasks/${newTaskId}`).set(createdTask).then(() => {
          const mailData = {
            by: {
              id: this.usd?.acc?.id, name: this.usd?.acc?.name, email: this.usd?.acc?.bus_email || this.usd?.acc.email,
              photoUrl: this.usd?.acc?.photoURL || "",
            },
            task: task, name: task.champion.name, email: task.champion.email, subjet: "New Task Notice", text: "New Task Notice",
          };
          this.emailChamp(mailData);
        });
      }
      // set task under a company
      if (this.is?.toolSet === "client") {
        console.log(task);
        let admin;
        if (this.es.clientAttens.length > 0 && task.champCompany.id !== this.usd.setCompany.id && task.champCompany.id === this.usd.setClient.id) {
          createdTask.companyId = this.usd.setCompany.id;
          createdTask.companyName = this.usd.setCompany.name;
          admin = this.usd.setClient?.managers;
          task.participants = admin;
          this.managersTasking(task, admin);
        } else {
          this.notifyManagers(task, this.usd.setClient);
        }
        // end of clent update
      }
      if (this.is?.toolSet === "Customers") {
        let admin;
        if (
          task.champCompany?.managers &&
          task.champCompany?.managers.length > 0
        ) {
          admin = task.champCompany?.managers;
          task.participants = admin;
          this.managersTasking(task, admin);
        } else {
          this.notifyManagers(task, task.champCompany);
        }
        // end of clent update
      }
      if (this.is?.toolSet === "Project Companies") {
        let admin;
        if (
          task.champCompany?.managers &&
          task.champCompany?.managers.length > 0
        ) {
          admin = task.champCompany?.managers;
          this.managersTasking(task, admin);
        } else {
          this.notifyManagers(task, task.champCompany);
        }
      }
      if (this.is.toolSet === "proj-team") {
        let admin;
        task.champCompany = this.usd.projectTeam;
        if (
          task.champCompany?.managers &&
          task.champCompany?.managers.length > 0
        ) {
          admin = task.champCompany?.managers;
          this.managersTasking(task, admin);
        } else {
          this.notifyManagers(task, task.champCompany);
        }
      }
    });
  }

  notifyManagers(task, cmp) {
    let admin;
    this.usd.afs.collection<any>(`Enterprises/${cmp.id}/Participants/`, (ref) => ref.where("hierarchy", "==", "Executive").limit(3))
      .valueChanges().subscribe((exe) => {
        if (exe.length > 0) {
          admin = exe;
          task.participants = admin;
          this.usd.afs
            .collection<any>(`Enterprises/${cmp.id}/Participants/`, (ref) =>
              ref.where("hierarchy", "==", "Middle management").limit(3)
            )
            .valueChanges()
            .subscribe((ads) => {
              if (ads.length > 0) {
                admin.concat(ads);
                task.participants = admin;
                this.managersTasking(task, admin);
              } else {
                this.managersTasking(task, admin);
              }
            });
        } else {
          let admins;
          this.usd.afs
            .collection<any>(`Enterprises/${cmp.id}/Participants/`, (ref) =>
              ref.where("hierarchy", "==", "Middle management").limit(3)
            )
            .valueChanges()
            .subscribe((ads) => {
              if (ads.length > 0) {
                admins = ads;
                task.participants = admins;
                this.managersTasking(task, admin);
              } else {
                this.ns.showNotification("save_CusFail", "top", "right", cmp);
              }
            });
        }
      });
  }

  managersTasking(task, managers: any[]) {
    if (managers && managers?.length > 0) {
      managers.forEach((element) => {
        if (this.usd.userId !== element.id) {
          this.usd.afs
            .doc(`Users/${element.id}/tasks/${task.id}`)
            .set(task)
            .then(() => {
              console.log(`${element.name}, tasked`)
            })
            .catch((er) => {});
        }
      });
      const maillist = [];
      managers.forEach((element) => {
        element.email = element.bus_email || element.email;
        maillist.push(element.email);
      });
      Promise.all(maillist).then(() => {
        // task.maillist = maillist;
        const mailData = {
          by: {
            id: this.usd?.acc?.id, name: this.usd?.acc?.name,
            email: this.usd?.acc?.bus_email || this.usd?.acc.email,
            photoUrl: this.usd?.acc?.photoURL || "",
          },
          task: task, maillist: maillist, subjet: "New Task Notice", text: "New Task Notice",
        };
        this.emailGroup(mailData);
      });
    }
  }

  deleteTask(x, dir) {
    const newTaskId = x.id;
    console.log(newTaskId, x.id, x);
    if (x.id && x.id !== "") {
      this.delTask(x, dir);
    } else {
      this.usd.afs
        .collection(`Users/${this.usd.userId}/tasks`, (ref) =>
          ref.where("description", "==", x?.description)
        )
        .snapshotChanges()
        .pipe(
          map((b) =>
            b.map((a) => {
              const data = a.payload.doc.data() as any;
              data.id = a.payload.doc.id;
              data.tag = "sub";
              if (
                data.description === x.description &&
                data.name === x.name &&
                data.byId === x.byId &&
                data.UpdatedOn === x.UpdatedOn
              ) {
                console.log(
                  `task found ${data?.description || data?.name}  ${
                    x.description || x?.name
                  }, id ${data.id}`
                );
                this.delTask(data, dir);
              }
              return data;
            })
          )
        );
    }
  }

  delTask(task, dir) {
    if (this.is?.toolSet === "Enterprise") {
      const psnTasks = this.usd?.myTasks?.filter((tsk) => tsk.id !== task.id);
      const entTasks = this.es.tasks?.filter((tsk) => tsk.id !== task.id);
      this.es.allActions = entTasks;
      this.usd.myTasks = psnTasks;
    } else if (this.is?.toolSet === "Project") {
      const prjTasks = this.ps.allActions?.filter((tsk) => tsk.id !== task.id);
      const psnTasks = this.usd?.myTasks?.filter((tsk) => tsk.id !== task.id);
      this.usd.myTasks = psnTasks;
      this.ps.allActions = prjTasks;
    } else if (this.is?.toolSet === "Personal") {
      const psnTasks = this.usd?.myTasks?.filter((tsk) => tsk.id !== task.id);
      this.usd.myTasks = psnTasks;
    } else {
    }
    if (task.champion.id === task.byId) {
      if (!task.champion?.id) {
      } else {
        this.usd.afs
          .collection("Users")
          .doc(task.champion.id)
          .ref.get()
          .then((usd) => {
            if (usd.exists) {
              this.usd.afs
                .doc(`Users/${task.champion.id}/tasks/${task.id}`)
                .delete()
                .then(() => {
                  this.usd.afs
                    .doc(`Users/${task.champion.id}/tasksArchive/${task.id}`)
                    .set(task);
                  this.ns.showNotification("deletedTask", "top", "right", task);
                  setTimeout(() => {
                    if (dir === "Bck") {
                      this.is.goBack();
                    }
                  }, 2000);
                })
                .catch((er) => console.log(er));
            }
          });
      }
      this.deleteAll_Task(task);
    } else {
      if (task.byId !== "") {
        const part = {
          name: task.by,
          id: task.byId,
        };
        this.notifyPartsTaskDeleted(part, task);
        const userRef = this.usd.afs.doc(`Users/${task.byId}/tasks/${task.id}`);
        // set task under a user
        if (!task.champion?.id) {
        } else {
          this.usd.afs
            .collection("Users")
            .doc(task.champion.id)
            .ref.get()
            .then((usd) => {
              if (usd.exists) {
                const champRef = this.usd.afs
                  .collection("Users")
                  .doc(task.champion.id)
                  .collection("tasks")
                  .doc(task.id);
                // set task under a user
                champRef
                  .delete()
                  .then(() => {})
                  .then(() => {
                    this.usd.afs
                      .collection("Users")
                      .doc(task.champion.id)
                      .collection("tasksArchive")
                      .doc(task.id)
                      .set(task);
                    // this.usd.dataCall();
                    this.ns.showNotification(
                      "deletedTask",
                      "top",
                      "right",
                      task
                    );
                    setTimeout(() => {
                      if (dir === "Bck") {
                        this.is.goBack();
                      }
                    }, 2000);
                  })
                  .catch((er) => console.log(er));
              }
            });
        }
        userRef.delete().then(() => {
          this.deleteAll_Task(task);
        });
        this.usd.afs
          .collection("Users")
          .doc(task.byId)
          .collection("tasksArchive")
          .doc(task.id)
          .set(task);
      } else {
        const champRef = this.usd.afs
          .collection("Users")
          .doc(task.champion.id)
          .collection("tasks")
          .doc(task.id);
        champRef
          .delete()
          .then(() => {
            this.deleteAll_Task(task);
          })
          .then(() => {
            this.ns.showNotification("deletedTask", "top", "right", task);
            setTimeout(() => {
              if (dir === "Bck") {
                this.is.goBack();
              }
            }, 2000);
          })
          .catch((er) => console.log(er));
      }
    }
  }

  ackTaskDeleted(task) {
    task.read = true;
    // this.usd.afs.doc(`Users/${this.usd.acc.id}/allNotice/${task.id}`).update(task).then(() => { });
    // console.log(task);
    this.usd.afs.doc(`Users/${this.usd.acc.id}/allNotice/${task.id}`).update({ read: true }).then(() => {
      this.usd.totalNotices = this.usd?.totalNotices.filter((x) => x.id !== task.id);
      // console.log(task, 'removed', this.usd.totalNotices?.length , 'left');
    });
  }

  notifyPartsTaskDeleted(part, task) {
    task.read = false; task.noticeDate = moment().toISOString();
    task.noti = "DeletedTaskPrtNotice";
    if (part?.id !== task?.champion?.id) {
      this.usd.afs.doc(`Users/${part?.id}/allNotice/${task.id}`).set(task).then(() => {});
    }
  }

  deleteAll_Task(task) {
    this.usd.afs.doc(`tasks/${task.id}`).delete();
    this.usd.afs.doc(`tasksArchive/${task.id}`).set(task);
    if (task.projectId !== "") {
      // set task under a project
      this.usd.afs
        .collection("Projects")
        .doc(task.projectId)
        .collection("tasks")
        .doc(task.id)
        ?.delete();
      this.usd.afs.collection("tasksArchive").doc(task.id).set(task);
    }
    if (task.companyId !== "") {
      // set task under a company
      this.usd.afs
        .collection("Enterprises")
        .doc(task.companyId)
        .collection("tasks")
        .doc(task.id)
        .delete();
    }
    if (task.champCompany && task.champCompany?.id !== "") {
      this.usd.afs
        .doc<Task>(`Enterprises/${task.champCompany.id}/tasks/${task.id}`)
        .delete();
    }
    if (task.participants) {
      // console.log(task.participants);
      task.participants.forEach((element) => {
        this.notifyPartsTaskDeleted(element, task);
        this.usd.afs
          .doc(`Users/${element.id}/tasks/${task.id}`)
          .delete()
          .then(() => {
            // console.log(element.name, 'participant document updated');
          })
          .catch((error) => {
            // console.log('Error updating Completion, document does not exists trying Again', error);
          });
      });
    }
    if (
      task.supervisor?.id &&
      task.supervisor?.id !== "" &&
      task.supervisor?.id !== task.byId
    ) {
      this.usd.afs
        .doc(`Users/${task.supervisor.id}/tasks/${task.id}`)
        .delete()
        .then(() => {
          // console.log('supervisor document updated');
        })
        .catch((error) => {
          // console.log('Error updating Completion, document does not exists trying Again', error);
          this.usd.afs
            .doc(`Users/${task.supervisor.id}/tasks/${task.id}`)
            .delete()
            .then(() => {
              // console.log('error resolved');
            });
        });
    }
  }

  addtype(typ) {
    this.usd.afs
      .collection(`typeOfTasks`)
      .add(typ)
      .then((ref) => {
        const id = ref.id;
        this.usd.afs
          .doc(`typeOfTasks/${id}`)
          .update({ id: id })
          .then(() => console.log("typeOfTasks added"));
      })
      .catch((er) => {
        // console.log(er)
        this.usd.afs
          .collection(`typeOfTasks`)
          .add(typ)
          .then((ref) => {
            const id = ref.id;
            this.usd.afs
              .doc(`typeOfTasks/${id}`)
              .update({ id: id })
              .then(() => console.log("typeOfTasks added on second trial"));
          });
      });
  }

  unPlannedTAsk(task, dmData) {
    // const timesheetDocId = String(moment(new Date()).format('DD-MM-YYYY'));
    // console.log(task);
    task.selectedWork = true;
    task.startDate = moment().format("L");
    task.startTime = moment().format("HH:mm");
    task.type = "unPlanned";
    task.champion = this.usd.acc;
    task.createdOn = new Date().toString();
    task.unit = "items";
    task.rate = "";
    task.byId = this.usd.acc?.id;
    task.by = this.usd.acc.name;
    task.startTime = moment().format("HH:mm");
    const indx = this.usd.types.findIndex((tsk) => (tsk.name = task.name));
    if (indx > -1) {
    } else {
      const typ = {
        name: task.name,
        unit: task.unit,
        by: this.usd.acc.name,
        byId: this.usd.acc.id,
        createdOn: task.createdOn,
      };
      this.addtype(typ);
    }
    this.usd.afs
      .collection("Users")
      .doc(task.champion.id)
      .collection("tasks")
      .add(task)
      .then((ref) => {
        const newTaskId = ref.id;
        task.id = ref.id;
        this.usd.afs.collection("tasks").doc(newTaskId).set(task);
        const dayActs = this.usd.afs.doc(
          `Users/${task.champion.id}/tasks/${newTaskId}`
        );
        dayActs.set(task).then(() => {
          dayActs
            .update({
              actualStart: task.startDate,
              selectedWork: true,
            })
            .then(() => {
              // console.log('dayActs coll update successful');
              if (dmData && dmData.qty > 0) {
                const actual: any = {
                  updateTime: moment().toString(),
                  qty: dmData.qty,
                };
                this.saveActual(actual, task, task);
              } else {
                this.upDateTime(task);
              }
              // this.usd.getMyTasks();;
            })
            .catch((error) => {
              // console.log('Error updating dayActs coll, document does not exists', error);
              dayActs.set(task).then(() => {
                dayActs.update({
                  id: newTaskId,
                  actualStart: task.startDate,
                  selectedWork: true,
                });
                // this.usd.getMyTasks();;
              });
            });
        });
      });
    return {
      name: "",
      description: "",
      update: "",
      champion: this.is.getUserChampion(),
      projectName: "",
      supervisor: this.is.getUserChampion(),
      toDos: [],
      createdOn: "",
      projectId: "",
      byId: "",
      projectType: "",
      department: "",
      startTime: "",
      finishTime: "",
      rate: "",
      type: "",
      UpdatedOn: "",
      selectedWeekWork: false,
      selectedWork: false,
      departmentId: "",
      startDate: "",
      startMonth: "",
      startYear: "",
      endDate: "",
      finishMonth: "",
      finishYear: "",
      by: "",
      companyName: "",
      companyId: "",
      trade: "",
      section: null,
      complete: false,
      id: "",
      participants: [],
      status: "",
      docs: [],
      selectedWeekly: false,
      classification: null,
      unit: "",
      workStatus: "",
      targetQty: "",
      actualData: [],
      actualQty: 0,
      amount: 0,
    };
  }

  addTypTsk(task) {
    const typ = {
      name: task.name,
      unit: task.unit,
      by: this.usd.acc.name,
      byId: this.usd.acc.id,
      createdOn: new Date().toString(),
    };
    this.usd.afs
      .collection(`typeOfTasks`)
      .add(typ)
      .then((ref) => {
        const id = ref.id;
        this.usd.afs
          .doc(`typeOfTasks/${id}`)
          .update({ id: id })
          .then(() => console.log("typeOfTasks added"));
      })
      .catch((er) => {
        // console.log(er)
        this.usd.afs
          .collection(`typeOfTasks`)
          .add(typ)
          .then((ref) => {
            const id = ref.id;
            this.usd.afs
              .doc(`typeOfTasks/${id}`)
              .update({ id: id })
              .then(() => console.log("typeOfTasks added on second trial"));
          });
      });
    return this.is.getTask();
  }

  deleteTypTsk(tsk) {
    this.usd.afs
      .doc(`typeOfTasks/${tsk.id}`)
      .delete()
      .then(() => console.log("type of task deleted"));
  }

  setDiaryAction(e: { target: { checked: any } }, action) {
    // setDiaryAction(action) {
    // console.log(this.tsk.diaryTask.description || this.tsk.diaryTask.name, this.tsk.diaryTask.id, `Status Checked ${this.tsk.diaryTask.selectedWork}`);
    // console.log(action.description || action.name, action.id, `Status Checked ${action.selectedWork}`);
    if (!action.startTime) {
      this.tsk.diaryTask.startTime = moment().format("HH:mm");
    }
    if (!action.finishTime) {
      this.tsk.diaryTask.finishTime = "";
    }
    if (!this.tsk.diaryTask.targetQty) {
      this.tsk.diaryTask.targetQty = "";
    }
    // console.log(usd);
    if (e.target.checked) {
      this.tsk.diaryTask.selectedWork = true;
      if (this.tsk.diaryTask.tag === "std") {
        this.updateSDT(this.tsk.diaryTask);
      } else if (this.tsk.diaryTask.tag === "sub") {
        // console.log(this.tsk.diaryTask)
        this.checkingTask(this.tsk.diaryTask);
      }
    } else {
      // console.log(this.tsk.diaryTask.name + ' ' + 'Task unchecked');
      this.tsk.diaryTask.selectedWork = false;
      if (this.tsk.diaryTask.tag === "std") {
        this.updateSDT(this.tsk.diaryTask);
      } else {
        this.checkingTask(this.tsk.diaryTask);
      }
    }
  }

  updateSDT(tsk) {
    const usd = this.usd.acc;
    this.usd.afs
      .doc(`Users/${usd.id}/myStandards/${this.tsk.diaryTask.id}`)
      .update(this.tsk.diaryTask)
      .then(() => {
        this.usd.runDiary();
      })
      .catch((er) => {
        // console.log('er ', action.id);
      });
  }

  checkingTask(action) {
    if (action.champion?.id && action.champion?.id === this.usd.userId) {
      // console.log('Before Update ', action.id);
      this.usd.afs
        .doc(`Users/${action.champion?.id}/tasks/${action.id}`)
        .update(action)
        .then(() => {
          this.taskCount(action);
          // console.log('After champion Update ', action.id);
        })
        .catch((ziError) => {
          // console.log('ziError ', action.id);
          this.usd.afs
            .doc(`Users/${action.champion?.id}/tasks/${action.id}`)
            .set(action)
            .then(() => {
              // console.log('tasks document .updated');
            });
        });
    } else {
      // console.log('Before Update ', action.id);
      this.usd.afs
        .doc(`Users/${this.usd.userId}/tasks/${action.id}`)
        .update(action)
        .then(() => {
          // console.log('After champion Update ', action.id);
        })
        .catch((ziError) => {
          // console.log('ziError ', action.id);
          this.usd.afs
            .doc(`Users/${this.usd.userId}/tasks/${action.id}`)
            .set(action)
            .then(() => {
              // console.log('tasks document .updated');
            });
        });
    }
  }

  adminDeleteStd(action) {
    if (action.tag === "std") {
      this.usd.afs
        .doc(`Users/${this.usd.userId}/myStandards/${action.id}`)
        .delete()
        .then(() => {})
        .catch((er) => {
          // console.log(er);
        });
    }
  }

  setDiaryWork(e: { target: { checked: any } }, action: Task) {
    // console.log(action.name, action.description);
    const dd = new Date().toISOString();
    const usd = action.champion;
    // console.log(usd);
    // action.update = dd;
    if (e.target.checked) {
      // console.log(action.name + ' ' + 'Task checked');
      const selectedWeekly = true;
      action.selectedWeekly = selectedWeekly;
      // action.update = dd;
      const selectedTsk = action;
      // console.log(action);
      const usd = this.usd.acc;
      this.usd.afs
        .doc(`Users/${usd?.id}/tasks/${selectedTsk.id}`)
        .update({ update: dd, selectedWeekly: selectedWeekly })
        .catch((er) => {
          this.usd.afs
            .doc(`Users/${usd?.id}/tasks/${selectedTsk.id}`)
            .set(selectedTsk)
            .then(() => {});
        });
      this.usd.afs
        .doc(`Users/${action.champion?.id}/tasks/${selectedTsk.id}`)
        .update({ update: dd, selectedWeekly: selectedWeekly })
        .catch((er) => {
          this.usd.afs
            .doc(`Users/${action.champion?.id}/tasks/${selectedTsk.id}`)
            .set(selectedTsk)
            .then(() => {});
        });
    } else {
      // console.log(action.name + ' ' + 'Task unchecked');
      const selectedWeekly = false;
      action.selectedWeekly = selectedWeekly;
      action.update = dd;
      this.usd.afs
        .doc(`Users/${usd?.id}/tasks/${action.id}`)
        .update({ update: dd, selectedWeekly: selectedWeekly })
        .catch((er) => {
          this.usd.afs
            .doc(`Users/${usd?.id}/tasks/${action.id}`)
            .set(action)
            .then(() => {});
        });
      this.usd.afs
        .doc(`Users/${action.champion?.id}/tasks/${action.id}`)
        .update({ update: dd, selectedWeekly: selectedWeekly })
        .catch((er) => {
          this.usd.afs
            .doc(`Users/${action.champion?.id}/tasks/${action.id}`)
            .set(action)
            .then(() => {});
        });
    }
  }

  saveProject(project: any, setCompany) {
    const pUser = {
      name: project.champion.name,
      email: project.champion.email,
      bus_email: project.champion.bus_email,
      id: project.champion.id,
      phoneNumber: project.champion.phoneNumber,
      photoURL: project.champion.photoURL,
      address: project.champion.address,
      nationalId: project.champion.nationalId,
      nationality: project.champion.nationality,
    };
    // console.log(project);
    // adding company details
    // console.log(setCompany)
    project.companyName = setCompany.name;
    project.companyId = setCompany.id;
    project.createdOn = new Date().toISOString();
    // console.log(project.createdOn)
    project.by = this.usd.acc.name;
    project.byId = this.usd.acc?.id;
    // console.log(project);
    // console.log(pUser);
    // console.log(setCompany);
    // console.log(project.roles);
    project.id = "";
    // this.ps.addProject(pUser, project, company);
    const championId = project.champion.id;
    let projectId = "";
    const dref = this.usd.afs.collection("Projects");
    const entRef = this.usd.afs
      .collection("Enterprises")
      .doc(setCompany.id)
      .collection("projects");
    const myProRef = this.usd.afs
      .collection("Users")
      .doc(project.byId)
      .collection("projects");
    const champProRef = this.usd.afs
      .collection("Users")
      .doc(championId)
      .collection("projects");
    const nrouter = this.router;

    myProRef
      .add(project)
      .then((pref) => {
        // Add project to users collection of projects
        // console.log(pref.id);
        projectId = pref.id; /// Id of the newly created project
        project.id = pref.id;
        if (project.type === "Enterprise") {
          // console.log(projectId);
          entRef.doc(projectId).set(project);
          champProRef.doc(projectId).set(project);
          dref.doc(projectId).set(project);
          myProRef.doc(projectId).update({ id: projectId });
          dref
            .doc(projectId)
            .collection("Participants")
            .doc(pUser.id)
            .set(pUser);
          this.usd.afs
            .doc(
              `Projects/${project.id}/enterprises/${project.companyId}/labour/${pUser.id}`
            )
            .set(pUser);
          dref
            .doc(projectId)
            .collection("enterprises")
            .doc(setCompany.id)
            .set(setCompany);
          // console.log('project Id updated');
          // console.log('enterprise project');
        }
      })
      .then(() => {
        this.ns.showNotification("project", "top", "right", project);
        this.usd.getProjects();
        nrouter.navigate(["/views/project/" + project.name]);
      });
    return {
      name: "",
      type: "",
      by: "",
      byId: "",
      companyName: "",
      companyId: "",
      champion: null,
      createdOn: "",
      id: "",
      location: "",
      sector: "",
      completion: "",
    };
  }

  savePProject(project) {
    const pUser = this.usd.acc;
    // console.log(project);
    project.companyName = "";
    project.companyId = "";
    project.createdOn = new Date().toISOString();
    // console.log(project.createdOn)
    project.by = this.usd.acc.name;
    project.byId = this.usd.acc?.id;
    // console.log(project);
    // console.log(pUser);
    project.champion = this.usd.acc;
    // this.ps.addProject(pUser, project, company);
    const championId = project.champion.id;
    let projectId = "";
    const dref = this.usd.afs.collection("Projects");
    const myProRef = this.usd.afs
      .collection("Users")
      .doc(project.byId)
      .collection("projects");
    const nrouter = this.router;
    myProRef
      .add(project)
      .then((pref) => {
        // Add project to users collection of projects
        // console.log(pref.id);
        projectId = pref.id; /// Id of the newly created project
        project.id = pref.id;
        // console.log(projectId);
        dref.doc(projectId).set(project);
        myProRef.doc(projectId).update({ id: projectId });
        dref.doc(projectId).collection("Participants").doc(pUser.id).set(pUser);
        // console.log('project Id updated');
        // console.log('enterprise project');
      })
      .then(() => {
        this.usd.getProjects();
        nrouter.navigate(["/views/project/" + project.name]);
        this.ns.showNotification("project", "top", "right", project);
      });
    return {
      name: "",
      type: "",
      by: "",
      byId: "",
      companyName: "",
      companyId: "",
      champion: null,
      createdOn: "",
      id: "",
      location: "",
      sector: "",
      completion: "",
    };
  }

  updateAction(workAction: Task) {
    try {
      // console.log('ActionItem' + ' ' + workAction.name + ' ' + 'updated');
      // console.log(workAction);
      const champId = this.usd.acc?.id;
      const item = workAction;
      // console.log(item);
      const dataId = item.id + moment().format("dd");
      // console.log(dataId);
      const timesheetworktime = String(moment(new Date().getTime()));
      const work = {
        WorkingTime: moment().toString(),
        name: item.description || item.name,
        id: item.id,
      };
      const chamRef = this.usd.afs.doc(`Users/${champId}/tasks/${item.id}`);
      const dd = moment().toString();
      if (workAction.projectId !== "") {
        this.usd.afs
          .doc(`Users/${this.usd.userId}/projects/${workAction.projectId}`)
          .update({ update: dd })
          /* .then(() => console.log('update time updated', dd)) */
          .catch((er) => console.log(er));
      }
      chamRef.ref.get().then((doc) => {
        if (doc.exists) {
          chamRef
            .collection("workTime")
            .doc(timesheetworktime)
            .set(work)
            .then(() => {
              // console.log('Update successful');
            })
            .catch((error) => {
              // console.log('Error updating user, document does not exists', error);
            });
        } else {
          chamRef
            .set(item)
            .then(() => {
              // console.log('Update successful');
            })
            .catch((error) => {
              // console.log('Error updating user, document does not exists', error);
            });
          chamRef
            .collection("workTime")
            .doc(timesheetworktime)
            .set(work)
            .then(() => {
              // console.log('Update successful');
            })
            .catch((error) => {
              // console.log('Error updating user, document does not exists', error);
            });
        }
      });
    } catch (error) {
      // console.log('Err', error);
    }
  }

  updateTaskSec(setTask) {
    if (!setTask.id && setTask.id === "") {
    } else {
      if (setTask.champion?.id) {
        this.usd.afs
          .doc(`Users/${this.usd.acc?.id}/tasks/${setTask.id}`)
          .update({ section: setTask.section })
          .then(() => {
            this.taskCount(setTask);
            // console.log('document updated');
            /* ----------------------- Set every Other Node --------------------------- */
            if (setTask.byId !== setTask.champion.id) {
              this.usd.afs
                .doc(`Users/${setTask.byId}/tasks/${setTask.id}`)
                .update({ section: setTask.section })
                .then(() => {
                  // console.log('byId document updated');
                })
                .catch((error) => {
                  // console.log('Error byId document updating Completion, document does not exists trying Again', error);
                  this.usd.afs
                    .doc(`Users/${setTask.byId}/tasks/${setTask.id}`)
                    .set(setTask)
                    .then(() => {
                      // console.log('document updated');
                    });
                });
            }
            if (setTask.participants?.length > 0) {
              setTask.participants.forEach((element) => {
                this.notifyPartsTaskEdited(element, setTask);
                this.usd.afs
                  .doc(`Users/${element.id}/tasks/${setTask.id}`)
                  .update({ section: setTask.section })
                  .then(() => {
                    // console.log('supervisor.id document updated');
                  })
                  .catch((error) => {
                    // console.log('Error byId document updating Completion, document does not exists trying Again', error);
                    this.usd.afs
                      .doc(`Users/${element.id}/tasks/${setTask.id}`)
                      .set(setTask)
                      .then(() => {
                        // console.log('document updated');
                      });
                  });
              });
            }
            if (setTask.supervisor.id !== "") {
              this.usd.afs
                .doc(`Users/${setTask.supervisor.id}/tasks/${setTask.id}`)
                .update({ section: setTask.section })
                .then(() => {
                  // console.log('supervisor.id document updated');
                })
                .catch((error) => {
                  // console.log('Error byId document updating Completion, document does not exists trying Again', error);
                  this.usd.afs
                    .doc(`Users/${setTask.supervisor.id}/tasks/${setTask.id}`)
                    .set(setTask)
                    .then(() => {
                      // console.log('document updated');
                    });
                });
            }
            this.usd.afs
              .collection("tasks")
              .doc(setTask.id)
              .update({ section: setTask.section })
              .then(() => {
                // console.log('document updated');
              })
              .catch((error) => {
                // console.log('Error updating Completion, document does not exists trying Again', error);
                this.usd.afs
                  .collection("tasks")
                  .doc(setTask.id)
                  .set(setTask)
                  .then(() => {
                    // console.log('document updated');
                  });
              });
            // if (setTask.companyId !== '') {
            //   const compRefII = this.usd.afs.collection('Enterprises').doc(setTask.companyId).collection('tasks').doc(setTask.id);
            //   compRefII.update({ 'section': setTask.section }).then(() => {
            //     // console.log('document updated');
            //   }).catch((error) => {
            //     // console.log('Error updating Completion, document does not exists trying Again', error);
            //     compRefII.set(setTask).then(() => {
            //       // console.log('document updated');
            //     })
            //   });
            // }
            // if (setTask.champCompany && setTask.champCompany?.id !== '') {
            //   this.usd.afs.doc(`Enterprises/${setTask.champCompany.id}/tasks/${setTask.id}`).update({ 'section': setTask.section }).then(() => {
            //     // console.log('compChamp updated');
            //   }).catch(err => {
            //     // console.log(err)
            //     this.usd.afs.doc(`Enterprises/${setTask.champCompany.id}/tasks/${setTask.id}`).set(setTask).then(() => {
            //       // console.log('document updated');
            //     })
            //   });
            // }
            // if (setTask.projectId !== '') {
            //   const prjectRef = this.usd.afs.collection('Projects').doc(setTask.projectId).collection('tasks').doc(setTask.id);
            //   prjectRef.update({ 'section': setTask.section }).then(() => {
            //     // console.log('document updated');
            //   }).catch((error) => {
            //     // console.log('Error updating Completion, document does not exists trying Again', error);
            //     prjectRef.set(setTask).then(() => {
            //       // console.log('document updated');
            //     })
            //   });
            // }
            /* --------------------- End Set every Other Node -------------------------- */
          })
          .catch((error) => {
            // console.log('Error updating Completion, document does not exists trying Again', error);
            this.usd.afs
              .doc(`Users/${this.usd.acc?.id}/tasks/${setTask.id}`)
              .set(setTask)
              .then(() => {
                // console.log('Re-added done');
                /* ----------------------- Set every Other Node --------------------------- */
                if (setTask.byId !== setTask.champion.id) {
                  const part = {
                    name: setTask.by,
                    id: setTask.byId,
                  };
                  this.notifyPartsTaskEdited(part, setTask);
                  this.usd.afs
                    .doc(`Users/${setTask.byId}/tasks/${setTask.id}`)
                    .update({ section: setTask.section })
                    .then(() => {
                      // console.log('byId document updated');
                    })
                    .catch((errors) => {
                      // console.log('Error byId document updating Completion, document does not exists trying Again', errors);
                      this.usd.afs
                        .doc(`Users/${setTask.byId}/tasks/${setTask.id}`)
                        .set(setTask)
                        .then(() => {
                          // console.log('document updated');
                        });
                    });
                }
                if (setTask.participants?.length > 0) {
                  setTask.participants.forEach((element) => {
                    this.usd.afs
                      .doc(`Users/${element.id}/tasks/${setTask.id}`)
                      .update({ section: setTask.section })
                      .then(() => {
                        // console.log('supervisor.id document updated');
                      })
                      .catch((errors) => {
                        // console.log('Error byId document updating Completion, document does not exists trying Again', errors);
                        this.usd.afs
                          .doc(`Users/${element.id}/tasks/${setTask.id}`)
                          .set(setTask)
                          .then(() => {
                            // console.log('document updated');
                          });
                      });
                  });
                }
                if (setTask.supervisor && setTask.supervisor.id !== "") {
                  this.usd.afs
                    .doc(`Users/${setTask.supervisor.id}/tasks/${setTask.id}`)
                    .update({ section: setTask.section })
                    .then(() => {
                      // console.log('supervisor.id document updated');
                    })
                    .catch((errors) => {
                      // console.log('Error byId document updating Completion, document does not exists trying Again', errors);
                      this.usd.afs
                        .doc(
                          `Users/${setTask.supervisor.id}/tasks/${setTask.id}`
                        )
                        .set({ section: setTask.section })
                        .then(() => {
                          // console.log('document updated');
                        });
                    });
                }
                this.usd.afs
                  .collection("tasks")
                  .doc(setTask.id)
                  .update({ section: setTask.section })
                  .then(() => {
                    // console.log('document updated');
                  })
                  .catch((errors) => {
                    // console.log('Error updating Completion, document does not exists trying Again', errors);
                    this.usd.afs
                      .collection("tasks")
                      .doc(setTask.id)
                      .set(setTask)
                      .then(() => {
                        // console.log('document updated');
                      });
                  });
              })
              .catch((errz) => console.log("err", errz));
          });
      }
    }
  }

  // setEditedSub(rawtask: any, startDate, endDate) {
  setEditedSub(rawtask: any) {
    rawtask.update = new Date().toISOString();
    rawtask.type = "Staged";
    const setTask: any = this.taskDates(rawtask);
    const tskIndfnd = this.usd?.myTasks.find((x) => x.id === setTask.id);
    if (tskIndfnd > -1) {
      this.usd.myTasks[tskIndfnd] = setTask;
    }
    if (this.is?.toolSet === "Project") {
      const Indfnd = this.ps.allActions.find((x) => x.id === setTask.id);
      if (Indfnd > -1) {
        this.ps.allActions[Indfnd] = setTask;
      }
    } else if (this.is?.toolSet === "Enterprise") {
      const Indfnd = this.es.allActions.find((x) => x.id === setTask.id);
      if (Indfnd > -1) {
        this.es.allActions[Indfnd] = setTask;
      }
    } else {
    }
    console.log(setTask);
    // console.log('the actionItem -->' + setTask.name);
    if (!setTask.id && setTask.id === "") {
    } else {
      if (setTask.champion?.id) {
        this.usd.afs.doc(`Users/${this.usd.acc?.id}/tasks/${setTask.id}`).update(setTask).then(() => {
            this.taskCount(setTask);
            // console.log('document updated');
            /* ----------------------- Set every Other Node --------------------------- */
            if (setTask.byId !== setTask.champion.id) {
              this.usd.afs
                .doc(`Users/${setTask.byId}/tasks/${setTask.id}`)
                .update(setTask)
                .then(() => {
                  // console.log('byId document updated');
                })
                .catch((error) => {
                  // console.log('Error byId document updating Completion, document does not exists trying Again', error);
                  // this.usd.afs.doc(`Users/${setTask.byId}/tasks/${setTask.id}`).set(setTask).then(() => {
                  //   // console.log('document updated');
                  // })
                });
            }
            if (setTask.participants?.length > 0) {
              const filteredParts = setTask.participants.filter(i => i.id !== this.usd?.acc?.id);
              // setTask.participants.forEach((element) => {
                filteredParts.forEach((element) => {
                this.notifyPartsTaskEdited(element, setTask);
                this.usd.afs.doc(`Users/${element.id}/tasks/${setTask.id}`)
                  .update(setTask).then(() => {
                    // console.log('supervisor.id document updated');
                  }).catch((error) => {
                    // console.log('Error byId document updating Completion, document does not exists trying Again', error);
                    this.usd.afs.doc(`Users/${element.id}/tasks/${setTask.id}`).set(setTask).then(() => {
                        // console.log('document updated');
                    });
                  });
              });
            }
            if (setTask.supervisor.id !== "") {
              this.usd.afs.doc(`Users/${setTask.supervisor.id}/tasks/${setTask.id}`).update(setTask).then(() => {
                  // console.log('supervisor.id document updated');
                }).catch((error) => {
                  // console.log('Error byId document updating Completion, document does not exists trying Again', error);
                  this.usd.afs.doc(`Users/${setTask.supervisor.id}/tasks/${setTask.id}`).set(setTask).then(() => {
                    // console.log('document updated');
                  });
                });
            }
            this.usd.afs.collection("tasks").doc(setTask.id)
              .update({
                description: setTask.description, name: setTask.name, companyName: setTask.companyName,
                companyId: setTask.companyId, projectName: setTask.projectName, projectId: setTask.projectId,
                section: setTask.section, startDate: setTask.startDate, endDate: setTask.endDate,
              }).then(() => {
                // console.log('document updated');
              })
              .catch((error) => {
                // console.log('Error updating Completion, document does not exists trying Again', error);
                this.usd.afs.collection("tasks").doc(setTask.id).set(setTask).then(() => {
                  // console.log('document updated');
                });
              });
            /* --------------------- End Set every Other Node -------------------------- */
          })
          .catch((error) => {
            // console.log('Error updating Completion, document does not exists trying Again', error);
            this.usd.afs.doc(`Users/${this.usd.acc?.id}/tasks/${setTask.id}`).set(setTask)
              .then(() => {
                // console.log('Re-added done');
                /* ----------------------- Set every Other Node --------------------------- */
                if (setTask.byId !== setTask.champion.id) {
                  const part = {
                    name: setTask.by,
                    id: setTask.byId,
                  };
                  this.notifyPartsTaskEdited(part, setTask);
                  this.usd.afs.doc(`Users/${setTask.byId}/tasks/${setTask.id}`).update(setTask)
                    .then(() => {
                      // console.log('byId document updated');
                    })
                    .catch((errors) => {
                      // console.log('Error byId document updating Completion, document does not exists trying Again', errors);
                      this.usd.afs.doc(`Users/${setTask.byId}/tasks/${setTask.id}`).set(setTask)
                        .then(() => {
                          // console.log('document updated');
                        });
                    });
                }
                if (setTask.participants?.length > 0) {
                  setTask.participants.forEach((element) => {
                    this.usd.afs.doc(`Users/${element.id}/tasks/${setTask.id}`).update(setTask)
                      .then(() => {
                        // console.log('supervisor.id document updated');
                      })
                      .catch((errors) => {
                        // console.log('Error byId document updating Completion, document does not exists trying Again', errors);
                        this.usd.afs.doc(`Users/${element.id}/tasks/${setTask.id}`).set(setTask)
                          .then(() => {
                            // console.log('document updated');
                          });
                      });
                  });
                }
                if (setTask.supervisor && setTask.supervisor.id !== "") {
                  this.usd.afs.doc(`Users/${setTask.supervisor.id}/tasks/${setTask.id}`).update(setTask)
                    .then(() => {
                      // console.log('supervisor.id document updated');
                    })
                    .catch((errors) => {
                      // console.log('Error byId document updating Completion, document does not exists trying Again', errors);
                      this.usd.afs.doc(`Users/${setTask.supervisor.id}/tasks/${setTask.id}`).set(setTask)
                        .then(() => {
                          // console.log('document updated');
                        });
                    });
                }
                this.usd.afs
                  .collection("tasks")
                  .doc(setTask.id)
                  .update({
                    description: setTask.description, name: setTask.name, companyName: setTask.companyName,
                    companyId: setTask.companyId, projectName: setTask.projectName, projectId: setTask.projectId,
                    section: setTask.section, startDate: setTask.startDate, endDate: setTask.endDate,
                  })
                  .then(() => {
                    // console.log('document updated');
                  })
                  .catch((errors) => {
                    // console.log('Error updating Completion, document does not exists trying Again', errors);
                    this.usd.afs.collection("tasks").doc(setTask.id).set(setTask)
                      .then(() => {
                        // console.log('document updated');
                      });
                  });
              })
              .catch((errz) => console.log("err", errz));
          });
      }
    }
  }

  ackTaskEdited(task) {
    task.read = true;
    this.usd.afs.doc(`Users/${this.usd.acc.id}/allNotice/${task.id}`).update({ read: true })
      .then(() => {
        // this.rs.viewTask(task);
        this.usd.totalNotices = this.usd?.totalNotices.filter(
          (x) => x.id !== task.id
        );
        this.usd.tasksComs(task);
        this.tsk.task = task;
        this.rs.viewTask(task);
        // this.rs.commentTask(task);
      });
  }

  notifyPartsTaskEdited(part, task) {
    task.editBy = {
      name: this.usd.acc.name,
      id: this.usd.acc.id,
      photoUrl: this.usd.acc.photoUrl
        ? this.usd.acc.photoUrl
        : this.usd.acc.userImg
        ? this.usd.acc.userImg
        : "",
      email: this.usd.acc.email || this.usd.acc.bus_email,
    };
    task.read = false;
    task.noti = "EditedTaskPrtNotice";
    task.noticeDate = moment().toISOString();
    if (part?.id !== task?.champion?.id) {
      this.usd.afs
        .doc(`Users/${part?.id}/allNotice/${task.id}`)
        .set(task)
        .then(() => {});
    }
  }

  taskCorrection(setTask) {
    // console.log(setTask);
    setTask.update = new Date().toISOString();
    /* ----------------------- Set every Other Node --------------------------- */
    if (setTask.byId !== setTask.champion.id) {
      this.usd.afs
        .doc(`Users/${setTask.byId}/tasks/${setTask.id}`)
        .update(setTask)
        .then(() => {
          // console.log('byId document updated');
        })
        .catch((error) => {
          // console.log('Error byId document updating Completion, document does not exists trying Again', error);
          this.usd.afs
            .doc(`Users/${setTask.byId}/tasks/${setTask.id}`)
            .set(setTask)
            .then(() => {
              // console.log('document updated');
            });
        });
    }
    if (setTask.participants?.length > 0) {
      setTask.participants.forEach((element) => {
        this.usd.afs
          .doc(`Users/${element.id}/tasks/${setTask.id}`)
          .update(setTask)
          .then(() => {
            // console.log('supervisor.id document updated');
          })
          .catch((error) => {
            // console.log('Error byId document updating Completion, document does not exists trying Again', error);
            this.usd.afs
              .doc(`Users/${element.id}/tasks/${setTask.id}`)
              .set(setTask)
              .then(() => {
                // console.log('document updated');
              });
          });
      });
    }
    if (setTask.supervisor && setTask.supervisor.id !== "") {
      this.usd.afs
        .doc(`Users/${setTask.supervisor.id}/tasks/${setTask.id}`)
        .update(setTask)
        .then(() => {
          // console.log('supervisor.id document updated');
        })
        .catch((error) => {
          // console.log('Error byId document updating Completion, document does not exists trying Again', error);
          this.usd.afs
            .doc(`Users/${setTask.supervisor.id}/tasks/${setTask.id}`)
            .set(setTask)
            .then(() => {
              // console.log('document updated');
            });
        });
    }
  }

  updateComplete(task) {
    task.complete = true;
    task.update = new Date().toISOString();
    console.log(task.description || task.name, "setting complete true", task);
    // const updateOb = { 'complete': true, 'update': task.update }
    if (task.tag === "sub") {
      this.usd.afs
        .doc(`Users/${this.usd.acc?.id}/tasks/${task.id}`)
        .update(task)
        .then(() => {
          // console.log('users tasks 1127 document updated');
          this.taskCount(task);
          // this.usd.getMyTasks();;
          if (this.is?.toolSet === "Enterprise") {
            const psnTasks = this.usd?.myTasks?.filter(
              (tsk) => tsk.id !== task.id
            );
            const entTasks = this.es.tasks?.filter((tsk) => tsk.id !== task.id);
            this.es.allActions = entTasks;
            this.usd.myTasks = psnTasks;
          } else if (this.is?.toolSet === "Project") {
            const prjTasks = this.ps.allActions?.filter(
              (tsk) => tsk.id !== task.id
            );
            const psnTasks = this.usd?.myTasks?.filter(
              (tsk) => tsk.id !== task.id
            );
            this.usd.myTasks = psnTasks;
            this.ps.allActions = prjTasks;
          } else if (this.is?.toolSet === "Personal") {
            const psnTasks = this.usd?.myTasks?.filter(
              (tsk) => tsk.id !== task.id
            );
            this.usd.myTasks = psnTasks;
          } else {
          }
          if (this.is.toolSet === "Personal") {
            this.ns.showNotification("Complete", "top", "right", task);
          }
          /* ----------------------- Set every Other Node --------------------------- */
          this.usd.afs
            .collection("tasks")
            .doc(task.id)
            .update(task)
            .then(() => {
              // console.log('document updated');
            })
            .catch((error) => {
              // console.log('Error updating Completion, document does not exists trying Again', error);
              this.usd.afs
                .collection("tasks")
                .doc(task.id)
                .set(task)
                .then(() => {
                  // console.log('error resolved');
                });
            });
          if (task.byId !== "" && task.byId !== task.champion?.id) {
            const part = {
              name: task.by,
              id: task.byId,
            };
            this.notifyPartsTaskComplete(part, task);
            this.usd.afs
              .doc(`Users/${task.byId}/tasks/${task.id}`)
              .update(task)
              .then(() => {
                // console.log('byId document updated');
              })
              .catch((error) => {
                // console.log('Error updating Completion, document does not exists trying Again', error);
                this.usd.afs
                  .doc(`Users/${task.byId}/tasks/${task.id}`)
                  .set(task)
                  .then(() => {
                    // console.log('error resolved');
                  });
              });
          } else if (task.champion?.id && task.champion?.id !== "") {
            this.usd.afs
              .doc(`Users/${task.champion?.id}/tasks/${task.id}`)
              .update(task)
              .then(() => {
                // console.log('byId document updated');
              })
              .catch((error) => {
                // console.log('Error updating Completion, document does not exists trying Again', error);
                this.usd.afs
                  .doc(`Users/${task.champion?.id}/tasks/${task.id}`)
                  .set(task)
                  .then(() => {
                    // console.log('error resolved');
                  });
              });
          }
          // if (task.participants?.length !== 0) {
          if (task.participants) {
            // console.log(task.participants);
            task.participants.forEach((element) => {
              this.notifyPartsTaskComplete(element, task);
              this.usd.afs
                .doc(`Users/${element.id}/tasks/${task.id}`)
                .update(task)
                .then(() => {
                  // console.log(element.name, 'participant document updated');
                })
                .catch((error) => {
                  // console.log('Error updating Completion, document does not exists trying Again', error);
                });
            });
          }
          if (
            task.supervisor?.id &&
            task.supervisor?.id !== "" &&
            task.supervisor?.id !== task.byId
          ) {
            this.usd.afs
              .doc(`Users/${task?.supervisor?.id}/tasks/${task.id}`)
              .update(task)
              .then(() => {
                // console.log('supervisor document updated');
                this.notifyPartsTaskComplete(task.supervisor, task);
              })
              .catch((error) => {
                // console.log('Error updating Completion, document does not exists trying Again', error);
                this.usd.afs
                  .doc(`Users/${task.supervisor?.id}/tasks/${task.id}`)
                  .set(task)
                  .then(() => {
                    // console.log('error resolved');
                  });
              });
          }
          /* --------------------- End Set every Other Node -------------------------- */
        })
        .catch((error) => {
          // console.log('Error updating Completion, document does not exists trying Again', error);
        });
    } else if (task.tag === "std") {
      const standards = this.usd.afs.doc(
        `Users/${task.champion?.id}/myStandards/${task.id}`
      );
      standards.update(task).then(() => {}).catch((error) => {
        console.log(error);
      });
    }
  }

  setComplete(task: any) {
    task.complete = true;
    task.update = new Date().toISOString();
    console.log(task);
    if (task.tag === "sub") {
      this.usd.afs
        .collection("tasks")
        .doc(task.id)
        .update(task)
        .then(() => {
          if (this.is?.toolSet === "Enterprise") {
            const psnTasks = this.usd?.myTasks?.filter(
              (tsk) => tsk.id !== task.id
            );
            const entTasks = this.es.tasks?.filter((tsk) => tsk.id !== task.id);
            this.es.allActions = entTasks;
            this.usd.myTasks = psnTasks;
          } else if (this.is?.toolSet === "Project") {
            const prjTasks = this.ps.allActions?.filter(
              (tsk) => tsk.id !== task.id
            );
            const psnTasks = this.usd?.myTasks?.filter(
              (tsk) => tsk.id !== task.id
            );
            this.usd.myTasks = psnTasks;
            this.ps.allActions = prjTasks;
          } else if (this.is?.toolSet === "Personal") {
            const psnTasks = this.usd?.myTasks?.filter(
              (tsk) => tsk.id !== task.id
            );
            this.usd.myTasks = psnTasks;
          } else {
          }
          // console.log('document updated');
        })
        .catch((error) => {
          // console.log('Error updating Completion, document does not exists trying Again', error);
          this.usd.afs
            .collection("tasks")
            .doc(task.id)
            .set(task)
            .then(() => {
              // console.log('error resolved');
            });
        });
      if (task.byId !== "" && task.byId !== task.champion?.id) {
        const part = {
          name: task.by,
          id: task.byId,
        };
        this.notifyPartsTaskComplete(part, task);
        this.usd.afs
          .doc(`Users/${task.byId}/tasks/${task.id}`)
          .update(task)
          .then(() => {
            // console.log('byId document updated');
          })
          .catch((error) => {
            // console.log('Error updating Completion, document does not exists trying Again', error);
            this.usd.afs
              .doc(`Users/${task.byId}/tasks/${task.id}`)
              .set(task)
              .then(() => {
                // console.log('error resolved');
              });
          });
      } else if (task.champion?.id && task.champion?.id !== "") {
        this.usd.afs
          .doc(`Users/${task.champion?.id}/tasks/${task.id}`)
          .update(task)
          .then(() => {
            // console.log('byId document updated');
          })
          .catch((error) => {
            // console.log('Error updating Completion, document does not exists trying Again', error);
            this.usd.afs
              .doc(`Users/${task.champion?.id}/tasks/${task.id}`)
              .set(task)
              .then(() => {
                // console.log('error resolved');
              });
          });
      }
      // if (task.participants?.length !== 0) {
      if (task.participants) {
        // console.log(task.participants);
        task.participants.forEach((element) => {
          this.notifyPartsTaskComplete(element, task);
          this.usd.afs
            .doc(`Users/${element.id}/tasks/${task.id}`)
            .update(task)
            .then(() => {
              // console.log(element.name, 'participant document updated');
            })
            .catch((error) => {
              // console.log('Error updating Completion, document does not exists trying Again', error);
            });
        });
      }
      if (
        task.supervisor?.id &&
        task.supervisor?.id !== "" &&
        task.supervisor?.id !== task.byId
      ) {
        this.usd.afs
          .doc(`Users/${task?.supervisor?.id}/tasks/${task.id}`)
          .update(task)
          .then(() => {
            // console.log('supervisor document updated');
            this.notifyPartsTaskComplete(task.supervisor, task);
          })
          .catch((error) => {
            // console.log('Error updating Completion, document does not exists trying Again', error);
            this.usd.afs
              .doc(`Users/${task.supervisor?.id}/tasks/${task.id}`)
              .set(task)
              .then(() => {
                // console.log('error resolved');
              });
          });
      }
    } else if (task.tag === "std") {
      const standards = this.usd.afs.doc(
        `Users/${task.champion?.id}/myStandards/${task.id}`
      );
      standards
        .update(task)
        .then(() => {
          // console.log('Standard set Complete successful,'); /* update successful (document exists) */
        })
        .catch((error) => {
          console.log(error);
        });
    }
    if (task.byId !== "" && task.byId !== task.champion?.id) {
      let part: any = { name: task.by, id: task.byId };
      const drFound = this.usd.myContacts.find(
        (doc) => doc.id === task.supervisor.id
      );
      if (drFound && part?.id !== task?.champion?.id) {
        part = drFound;
        const mailData = {
          by: {
            id: this.usd?.acc?.id,
            name: this.usd?.acc?.name,
            email: this.usd?.acc?.bus_email || this.usd?.acc.email,
            photoUrl: this.usd?.acc?.photoURL || "",
          },
          task: {
            description: task.description,
            name: task.name,
            id: task.id,
            champion: task.champion,
            createdOn: moment(task.createdOn).local().format("LLL"),
            projectName: task.projectName,
            companyName: task.companyName,
            by: task.by,
            byId: task.byId,
          },
          name: part.name,
          email: part.bus_email || part.email,
          subjet: "Completed Task Notice",
          text: "Completed Task Notice",
        };
        // console.log(mail);
        this.emailCompltdTask(mailData);
      }
    }
    if (task.participants && task.participants?.length > 0) {
      // console.log(task.participants);
      task.participants.forEach((element) => {
        const mail = element;
        mail.task = {
          description: task.description,
          name: task.name,
          id: task.id,
          champion: task.champion,
          createdOn: moment(task.createdOn).local().format("LLL"),
          projectName: task.projectName,
          companyName: task.companyName,
          by: task.by,
          byId: task.byId,
        };
        mail.email = mail.bus_email || mail.email;
        // console.log(mail);
        this.emailCompltdTask(mail);
      });
    }
    if (
      task.supervisor?.id &&
      task.supervisor?.id !== "" &&
      task.supervisor?.id !== task.byId
    ) {
      const drFound = this.usd.myContacts.find(
        (doc) => doc.id === task.supervisor?.id
      );
      const part = task.supervisor;
      if (drFound && part?.id !== task?.champion?.id) {
        task.supervisor = drFound;
        const mail = drFound;
        mail.task = {
          description: task.description,
          name: task.name,
          id: task.id,
          champion: task.champion,
          createdOn: moment(task.createdOn).local().format("LLL"),
          projectName: task.projectName,
          companyName: task.companyName,
          by: task.by,
          byId: task.byId,
        };
        mail.email = mail.bus_email || mail.email;
        // console.log(mail);
        this.emailCompltdTask(mail);
        return;
      }
    }
  }
  setCompleteArch(task) {
    task.complete = true;
    task.update = new Date().toISOString();
    console.log(task.description || task.name, "setting complete true", task);
    // const updateOb = { 'complete': true, 'update': task.update }
    if (task.tag === "sub") {
      this.usd.afs
        .doc(`Users/${this.usd.acc?.id}/tasks/${task.id}`)
        .update(task)
        .then(() => {
          console.log("users tasks 1127 document updated");
          this.taskCount(task);
          // this.usd.getMyTasks();;
          if (this.is?.toolSet === "Enterprise") {
            const psnTasks = this.usd?.myTasks?.filter(
              (tsk) => tsk.id !== task.id
            );
            const entTasks = this.es.tasks?.filter((tsk) => tsk.id !== task.id);
            this.es.allActions = entTasks;
            this.usd.myTasks = psnTasks;
          } else if (this.is?.toolSet === "Project") {
            const prjTasks = this.ps.allActions?.filter(
              (tsk) => tsk.id !== task.id
            );
            const psnTasks = this.usd?.myTasks?.filter(
              (tsk) => tsk.id !== task.id
            );
            this.usd.myTasks = psnTasks;
            this.ps.allActions = prjTasks;
          } else if (this.is?.toolSet === "Personal") {
            const psnTasks = this.usd?.myTasks?.filter(
              (tsk) => tsk.id !== task.id
            );
            this.usd.myTasks = psnTasks;
          } else {
          }
          if (this.is.toolSet === "Personal") {
            this.ns.showNotification("Complete", "top", "right", task);
          }
          /* ----------------------- Set every Other Node --------------------------- */
          this.usd.afs
            .collection("tasks")
            .doc(task.id)
            .update(task)
            .then(() => {
              // console.log('document updated');
            })
            .catch((error) => {
              // console.log('Error updating Completion, document does not exists trying Again', error);
              this.usd.afs
                .collection("tasks")
                .doc(task.id)
                .set(task)
                .then(() => {
                  // console.log('error resolved');
                });
            });
          if (task.byId !== "" && task.byId !== task.champion?.id) {
            const part = {
              name: task.by,
              id: task.byId,
            };
            this.notifyPartsTaskComplete(part, task);
            this.usd.afs
              .doc(`Users/${task.byId}/tasks/${task.id}`)
              .update(task)
              .then(() => {
                // console.log('byId document updated');
              })
              .catch((error) => {
                // console.log('Error updating Completion, document does not exists trying Again', error);
                this.usd.afs
                  .doc(`Users/${task.byId}/tasks/${task.id}`)
                  .set(task)
                  .then(() => {
                    // console.log('error resolved');
                  });
              });
          } else if (task.champion?.id && task.champion?.id !== "") {
            this.usd.afs
              .doc(`Users/${task.champion?.id}/tasks/${task.id}`)
              .update(task)
              .then(() => {
                // console.log('byId document updated');
              })
              .catch((error) => {
                // console.log('Error updating Completion, document does not exists trying Again', error);
                this.usd.afs
                  .doc(`Users/${task.champion?.id}/tasks/${task.id}`)
                  .set(task)
                  .then(() => {
                    // console.log('error resolved');
                  });
              });
          }
          // if (task.participants?.length !== 0) {
          if (task.participants) {
            // console.log(task.participants);
            task.participants.forEach((element) => {
              this.notifyPartsTaskComplete(element, task);
              this.usd.afs
                .doc(`Users/${element.id}/tasks/${task.id}`)
                .update(task)
                .then(() => {
                  // console.log(element.name, 'participant document updated');
                })
                .catch((error) => {
                  // console.log('Error updating Completion, document does not exists trying Again', error);
                });
            });
          }
          if (
            task.supervisor?.id &&
            task.supervisor?.id !== "" &&
            task.supervisor?.id !== task.byId
          ) {
            this.usd.afs
              .doc(`Users/${task?.supervisor?.id}/tasks/${task.id}`)
              .update(task)
              .then(() => {
                // console.log('supervisor document updated');
                this.notifyPartsTaskComplete(task.supervisor, task);
              })
              .catch((error) => {
                // console.log('Error updating Completion, document does not exists trying Again', error);
                this.usd.afs
                  .doc(`Users/${task.supervisor?.id}/tasks/${task.id}`)
                  .set(task)
                  .then(() => {
                    // console.log('error resolved');
                  });
              });
          }
          /* --------------------- End Set every Other Node -------------------------- */
        })
        .catch((error) => {
          // console.log('Error updating Completion, document does not exists trying Again', error);
        });
    } else if (task.tag === "std") {
      const standards = this.usd.afs.doc(
        `Users/${task.champion?.id}/myStandards/${task.id}`
      );
      standards
        .update(task)
        .then(() => {
          // console.log('Standard set Complete successful,'); /* update successful (document exists) */
        })
        .catch((error) => {
          console.log(error);
        });
    }
    if (task.byId !== "" && task.byId !== task.champion?.id) {
      let part: any = { name: task.by, id: task.byId };
      const drFound = this.usd.myContacts.find(
        (doc) => doc.id === task.supervisor.id
      );
      if (drFound && part?.id !== task?.champion?.id) {
        part = drFound;
        const mailData = {
          by: {
            id: this.usd?.acc?.id,
            name: this.usd?.acc?.name,
            email: this.usd?.acc?.bus_email || this.usd?.acc.email,
            photoUrl: this.usd?.acc?.photoURL || "",
          },
          task: {
            description: task.description,
            name: task.name,
            id: task.id,
            champion: task.champion,
            createdOn: moment(task.createdOn).local().format("LLL"),
            projectName: task.projectName,
            companyName: task.companyName,
            by: task.by,
            byId: task.byId,
          },
          name: part.name,
          email: part.bus_email || part.email,
          subjet: "Completed Task Notice",
          text: "Completed Task Notice",
        };
        // console.log(mail);
        this.emailCompltdTask(mailData);
      }
    }
    if (task.participants && task.participants?.length > 0) {
      // console.log(task.participants);
      task.participants.forEach((element) => {
        const mail = element;
        mail.task = {
          description: task.description,
          name: task.name,
          id: task.id,
          champion: task.champion,
          createdOn: moment(task.createdOn).local().format("LLL"),
          projectName: task.projectName,
          companyName: task.companyName,
          by: task.by,
          byId: task.byId,
        };
        mail.email = mail.bus_email || mail.email;
        // console.log(mail);
        this.emailCompltdTask(mail);
      });
    }
    if (
      task.supervisor?.id &&
      task.supervisor?.id !== "" &&
      task.supervisor?.id !== task.byId
    ) {
      const drFound = this.usd.myContacts.find(
        (doc) => doc.id === task.supervisor?.id
      );
      const part = task.supervisor;
      if (drFound && part?.id !== task?.champion?.id) {
        task.supervisor = drFound;
        const mail = drFound;
        mail.task = {
          description: task.description,
          name: task.name,
          id: task.id,
          champion: task.champion,
          createdOn: moment(task.createdOn).local().format("LLL"),
          projectName: task.projectName,
          companyName: task.companyName,
          by: task.by,
          byId: task.byId,
        };
        mail.email = mail.bus_email || mail.email;
        // console.log(mail);
        this.emailCompltdTask(mail);
        return;
      }
    }
  }

  ackTaskComplete(task) {
    task.read = true;
    this.usd.afs
      .doc(`Users/${this.usd.acc.id}/allNotice/${task.id}`)
      .update({ read: task.read })
      .then(() => {})
      .catch((er) => console.log(er));
  }

  notifyPartsTaskComplete(part, task) {
    task.read = false;
    task.noticeDate = moment().toISOString();
    task.noti = "CompletedTaskPrtNotice";
    if (part?.id !== task?.champion?.id) {
      this.usd.afs
        .doc(`Users/${part?.id}/allNotice/${task.id}`)
        .set(task)
        .then(() => {})
        .catch((er) => console.log(er));
    }
  }

  remTodo(task, et) {
    // console.log(task.name, 'deleting sub');
    // if (task.tag === 'sub') {
    this.usd.afs
      .doc(`Users/${this.usd.acc?.id}/tasks/${task.id}`)
      .update({ toDos: this.usd.firebase.firestore.FieldValue.arrayRemove(et) })
      .then(() => {
        // console.log('deleted')
        // console.log('users tasks 1294 document updated');
        /* ----------------------- Set every Other Node --------------------------- */
        this.usd.afs
          .collection("tasks")
          .doc(task.id)
          .update({
            toDos: this.usd.firebase.firestore.FieldValue.arrayRemove(et),
          })
          /* .then(() => console.log('deleted')) */
          .catch((error) => {
            // console.log('Error capture', error)
          });
        if (task.byId !== task.champion?.id) {
          this.usd.afs
            .doc(`Users/${task.byId}/tasks/${task.id}`)
            .update({
              toDos: this.usd.firebase.firestore.FieldValue.arrayRemove(et),
            })
            /* .then(() => console.log('deleted')) */
            .catch((error) => {
              /* console.log('Error capture', error) */
            });
        }
        // if (task.participants?.length !== 0) {
        if (task.participants) {
          // console.log(task.participants);
          task.participants.forEach((element) => {
            this.usd.afs
              .doc(`Users/${element.id}/tasks/${task.id}`)
              .update({
                toDos: this.usd.firebase.firestore.FieldValue.arrayRemove(et),
              })
              /* .then(() => console.log('deleted')) */
              .catch((error) => {
                // console.log('Error capture', error)
              });
          });
        }
        if (
          task.supervisor?.id &&
          task.supervisor?.id !== "" &&
          task.supervisor?.id !== task.byId
        ) {
          this.usd.afs
            .doc(`Users/${task.supervisor.id}/tasks/${task.id}`)
            .update({
              toDos: this.usd.firebase.firestore.FieldValue.arrayRemove(et),
            })
            /* .then(() => console.log('deleted')) */
            .catch((error) => {
              // console.log('Error capture', error)
            });
        }
        /* --------------------- End Set every Other Node -------------------------- */
      })
      .catch((error) => {
        // console.log('Error updating Completion, document does not exists trying Again', error);
      });
    // }
  }

  upDateTime(workAction: any) {
    // console.log('ActionItem' + ' ' + workAction.name + ' ' + 'updated');
    // workAction.UpdatedOn = moment().toString();
    // console.log('2. theTime is', (moment().format('HH:mm')));
    const tHours = moment(new Date(), "HH:mm").hours().toLocaleString();
    const tMinutes = moment(new Date(), "HH:mm").minutes().toLocaleString();
    const fullTym = moment().format("HH:mm");
    const actionSet = workAction;
    // console.log(workAction);
    // const cleaningTime = this.aclear();
    if (
      workAction.actualStart === "" ||
      workAction.actualStart === undefined ||
      workAction.actualStart === null
    ) {
      workAction.actualStart = String(moment().toString());
      workAction.actualEnd = String(moment().add(0.5, "h").toString());
    }
    if (
      String(moment(actionSet.actualStart, "DD-MM-YYYY")) !==
      moment().format("DD-MM-YYYY")
    ) {
      actionSet.actualStart = String(moment().toString());
      actionSet.actualEnd = String(moment().add(0.5, "h").toString());
    } else {
      workAction.actualEnd = moment().add(0.5, "h").toString();
    }
    const item = workAction;
    // console.log(item);
    const dataId = item.id + moment().format("dd");
    const dd = moment().toString();
    // console.log(dataId);
    /* classification reports */
    const work = {
      id: fullTym,
      name: "responded",
      tHours: tHours,
      tMinutes: tMinutes,
      time: moment().toString(),
      hours: 0.5,
    };
    const dayTime: any = {
      date: moment().toString(),
      hours: 0.5,
    };
    // console.log(item?.dayTime, moment(item?.dayTime?.date).format('L'), moment().format('L'));
    if (
      item?.dayTime &&
      moment(item?.dayTime?.date).format("L") === moment().format("L")
    ) {
      // console.log(item.dayTime, item.dayTime.dayTotal, 'in if');
      if (item.dayTime?.dayTotal > 0) {
        // console.log(item.dayTime, item.dayTime.dayTotal, 'in 2nd if');
        item.dayTime.dayTotal += dayTime.hours;
      } else if (item.dayTime?.hours > 0) {
        // console.log(item.dayTime, 'in 2nd else');
        item.dayTime.dayTotal = item.dayTime.hours + dayTime.hours;
      } else {
        // console.log(item.dayTime, 'in 2nd else');
        item.dayTime.dayTotal = dayTime.hours;
      }
    } else {
      item.dayTime = dayTime;
      item.dayTime.dayTotal = dayTime.hours;
      // console.log(item.dayTime, 'in 1st else');
    }
    const timesheetworktime = String(moment(new Date().getTime()));
    try {
      const timesheetDocId = String(moment(new Date()).format("DD-MM-YYYY"));
      const timeData = {
        name: timesheetDocId,
        id: timesheetDocId,
      };
      const userTaskRef = this.usd.myDocument.collection("tasks").doc(item.id);
      if (item.tag === "sub") {
        if (item.projectId !== "") {
          this.usd.afs
            .doc(`Users/${this.usd.userId}/projects/${item.projectId}`)
            .update({ update: dd })
            /* .then(() => console.log('update time updated', dd)) */
            .catch((er) => console.log(er));
        }
        userTaskRef.ref.get().then((dck) => {
          this.taskCount(item);
          if (dck.exists) {
            userTaskRef
              .update({
                dayTime: item.dayTime,
                workHours:
                  this.usd.firebase.firestore.FieldValue.arrayUnion(work),
                actualStart: item.actualStart,
              })
              .then(() => {
                console.log("Array update Complete");
              })
              .catch((er) => {
                item.workHours = [work];
                userTaskRef.update({ workHours: item.workHours });
                /* .then(() => console.log('Attribute added')); */
              });
            userTaskRef
              .collection("workTime")
              .doc(timesheetworktime)
              .set(work)
              .then(() => {
                this.usd.getTimeSheetReport(item, this.usd.acc);
              });
          } else {
            userTaskRef.set(item).then(() => {
              this.usd.getTimeSheetReport(item, this.usd.acc);
              userTaskRef
                .collection("tasks")
                .doc(item.id)
                .collection("workTime")
                .doc(timesheetworktime)
                .set(work);
            });
          }
        });
        const timeSheetPath = this.usd.myDocument
          .collection("TimeSheets")
          .doc(timesheetDocId);
        timeSheetPath.ref.get().then((dck) => {
          if (dck.exists) {
            timeSheetPath
              .collection("workTime")
              .doc(timesheetworktime)
              .set(work);
          } else {
            timeSheetPath.set(timeData).then(() => {
              timeSheetPath
                .collection("workTime")
                .doc(timesheetworktime)
                .set(work);
            });
          }
        });
      } else if (item.tag === "std") {
        // console.log(item.tag);
        this.usd.myDocument
          .collection("myStandards")
          .doc(item.id)
          .collection("workTime")
          .doc(timesheetworktime)
          .set(work);
      }
    } catch (error) {
      // console.log('Err', error);
    }
  }

  unattendedModal() {
    // console.log('timing 2');
    const timesheetDocId = String(moment().format("DD-MM-YYYY"));
    const intWorkItem = this.is.getWorkItem();
    const tHours = moment(new Date(), "HH:mm").hours().toLocaleString();
    const tMinutes = moment(new Date(), "HH:mm").minutes().toLocaleString();
    const fullTym = tHours + " : " + tMinutes;
    const timesheetworktime = String(moment(new Date().getTime()));

    this.modalService.dismissAll();
    // console.log(12000);
    const work = {
      name: "Lapsed " + " " + fullTym,
      id: fullTym,
      tHours: tHours,
      tMinutes: tMinutes,
      time: moment().toString(),
      reason: "Lapsed",
      hours: 0.5,
    };
    const lapsedWork = intWorkItem;
    lapsedWork.name = "Lapsed";
    lapsedWork.id = timesheetworktime;
    // this.modalRef2.close()
    const dmData = this.usd.afs
      .collection("Users")
      .doc(this.usd.acc?.id)
      .collection("TimeSheets")
      .doc(timesheetDocId)
      .collection("tasks")
      .doc("lapsed");
    const dmData2 = this.usd.afs
      .collection("Users")
      .doc(this.usd.acc?.id)
      .collection("TimeSheets")
      .doc(timesheetDocId)
      .collection("workTime")
      .doc(timesheetworktime);
    // dmData.set(lapsedWork);
    dmData.ref.get().then((tdae) => {
      if (tdae.exists) {
        // console.log('Found Yuh');
        dmData2.set(work);
        dmData
          .collection("lapses")
          .doc(fullTym)
          .set(work)
          .then(() => {
            dmData.update({
              workHours:
                this.usd.firebase.firestore.FieldValue.arrayUnion(work),
            });
          })
          .catch((error) => {
            // console.log('Error updating lapsedWork, document does not exists', error);
            dmData
              .set(lapsedWork)
              .then(() => {
                dmData
                  .update({
                    workHours:
                      this.usd.firebase.firestore.FieldValue.arrayUnion(work),
                  })
                  .then(() => {
                    // console.log('lapsedWork update successful');
                  })
                  .catch((errr) => {
                    // console.log('Error updating lapsedWork, document does not exists', errr);
                    dmData.set(lapsedWork);
                    dmData.update({
                      workHours:
                        this.usd.firebase.firestore.FieldValue.arrayUnion(work),
                    });
                  });
              })
              .catch((err) => {
                // console.log('Error updating lapsedWork, document does not exists', err);
                dmData.update({
                  workHours:
                    this.usd.firebase.firestore.FieldValue.arrayUnion(work),
                });
              });
          });
      } else {
        // console.log('Lost Yuh');
        dmData
          .set(lapsedWork)
          .then(() => {
            dmData
              .collection("lapses")
              .doc(fullTym)
              .set(work)
              .then(() => {
                dmData.update({
                  workHours:
                    this.usd.firebase.firestore.FieldValue.arrayUnion(work),
                });
              })
              .catch((error) => {});
            dmData
              .update({
                workHours:
                  this.usd.firebase.firestore.FieldValue.arrayUnion(work),
              })
              .then(() => {
                // console.log('lapsedWork update successful');
              })
              .catch((errr) => {
                // console.log('Error updating lapsedWork, document does not exists', errr);
                dmData.set(lapsedWork);
                dmData.update({
                  workHours:
                    this.usd.firebase.firestore.FieldValue.arrayUnion(work),
                });
              });
          })
          .catch((err) => {
            // console.log('Error updating lapsedWork, document does not exists', err);
            dmData.update({
              workHours:
                this.usd.firebase.firestore.FieldValue.arrayUnion(work),
            });
          });
      }
    });
    // console.log('lapsedWork set');
    dmData
      .collection("lapses")
      .doc(fullTym)
      .set(work)
      .then(() => {})
      .catch((error) => {
        // console.log('Error updating lapsedWork, document does not exists', error);
        dmData
          .set(lapsedWork)
          .then(() => {
            dmData
              .update({
                workHours:
                  this.usd.firebase.firestore.FieldValue.arrayUnion(work),
              })
              .then(() => {
                // console.log('lapsedWork update successful');
              })
              .catch((errr) => {
                // console.log('Error updating lapsedWork, document does not exists', errr);
                dmData.set(lapsedWork);
                dmData.update({
                  workHours:
                    this.usd.firebase.firestore.FieldValue.arrayUnion(work),
                });
              });
          })
          .catch((err) => {
            // console.log('Error updating lapsedWork, document does not exists', err);
            dmData.update({
              workHours:
                this.usd.firebase.firestore.FieldValue.arrayUnion(work),
            });
          });
      });
    const newClassification = {
      name: "Unresponded",
      createdOn: fullTym,
      id: "unresponded",
      plannedTime: "",
      actualTime: "",
      Varience: "",
    };
    const classReport2 = this.usd.afs
      .collection("Users")
      .doc(this.usd.acc?.id)
      .collection("ClassTimeSheetsSum")
      .doc(timesheetDocId);
    const myClassWrkRpt = classReport2
      .collection("classifications")
      .doc("unresponded");
    myClassWrkRpt.set(newClassification);
    // myClassWrkRpt.collection('lapses').doc('lapsed').set(lapsedWork);
    myClassWrkRpt
      .collection("woukHours")
      .doc(fullTym)
      .set(work)
      .then(() => {
        // console.log('lapsedWork update successful');
      })
      .catch((errr) => {
        myClassWrkRpt.collection("woukHours").doc(fullTym).set(work);
      });
  }

  saveActual(actual: actualData, task, constantTask) {
    // console.log(actual.qty);
    actual.updateTime = moment().toString();
    actual.by = task.champion.name;
    actual.byId = task.champion.id;
    const champId = task.champion.id;
    const h: any = {
      date: actual.updateTime,
      qty: actual.qty,
    };
    // console.log(h);
    const item = task;
    if (item?.lastEntry) {
      if (task.lastEntry?.dayTotal && task.lastEntry?.dayTotal > 0) {
        item.lastEntry.dayTotal += h.qty;
        item.lastEntry.qty = h.qty;
        item.lastEntry.date = h.date;
      } else {
        item.lastEntry.dayTotal = h.qty;
        item.lastEntry.qty = h.qty;
        item.lastEntry.date = h.date;
      }
    } else {
      h.dayTotal = h.qty;
      item.lastEntry = h;
    }
    if (task.actsTotal) {
      item.actsTotal += actual.qty;
    } else {
      item.actsTotal = actual.qty;
    }
    const timesheetworktime = String(moment(new Date().getTime()));
    // setTimeout(() => {
    try {
      if (item.tag === "sub") {
        /* -------------------------- Root Task Node ----------------------------*/
        this.usd.afs
          .doc(`tasks/${item.id}`)
          .update({
            lastEntry: item.lastEntry,
            updateTime: actual.updateTime,
            actsTotal: task.actsTotal,
          })
          .then(() => {
            // console.log('Root tak upadted');
            this.taskCount(item);
            this.usd.afs
              .doc(`tasks/${item.id}/actionActuals/${timesheetworktime}`)
              .set(actual);
          })
          .catch((er) => {
            this.usd.afs.doc(`tasks/${item.id}`).set(item);
            // .then(() => console.log('Root tak Set'));
          });
        /* -------------------------- Task Champion Node ----------------------------*/
        const chamRef = this.usd.myDocument.collection(`tasks`).doc(item.id);
        chamRef
          .update({
            lastEntry: item.lastEntry,
            updateTime: actual.updateTime,
            actsTotal: task.actsTotal,
          })
          .then(() => {
            // this.usd.getMyTasks();;
            // console.log('tasks Updated');
          })
          .catch((rt) => console.log(rt));
        chamRef.collection("actionActuals").doc(timesheetworktime).set(actual);
        /* -------------------------- End Enterprise Node---------------------------*/
      } else if (item.tag === "std") {
        // console.log(item.tag);
        const standards = this.usd.afs.doc(
          `Users/${champId}/myStandards/${item.id}`
        );
        standards
          .update({
            lastEntry: item.lastEntry,
            updateTime: actual.updateTime,
            actsTotal: task.actsTotal,
          })
          /* .then(() => console.log('tasks Updated')) */
          .catch((rt) => console.log(rt));
        standards
          .collection("actionActuals")
          .doc(timesheetworktime)
          .set(actual)
          .then(() => {
            // console.log('Standard update successful,'); /* update successful (document exists) */
          })
          .catch((error) => {
            console.log(error);
            standards
              .collection("actionActuals")
              .doc(timesheetworktime)
              .set(actual);
          });
      }
    } catch (er) {
      console.log(er);
    }
    // }, 2000)
    if (item.equipment && task.equipment.length !== 0) {
      // console.log(task.equipment);
      // console.log(constantTask.equipment);
      this.usd.myDocument
        .collection("tasks")
        .doc(item.id)
        .update({ equipment: constantTask.equipment })
        /* .then(() => console.log('Task Equipment Show History Updated')) */ .catch(
          (er) => console.log(er)
        );
      task.equipment.forEach((element) => {
        const as = constantTask.equipment.find(
          (tool) => tool.id === element.id
        );
        if (as) {
          // console.log(as);
          this.addEquipmentActuals(as, task);
        }
      });
    }
    return { updateTime: "", qty: null, by: "", byId: "" };
  }

  saveActualArch(actual: actualData, task, constantTask) {
    // console.log(actual.qty);
    actual.updateTime = moment().toString();
    actual.by = task.champion.name;
    actual.byId = task.champion.id;
    // console.log(actual);
    // console.log(task);
    const champId = task.champion.id;
    const h: any = {
      date: actual.updateTime,
      qty: actual.qty,
    };
    // console.log(h);
    const item = task;
    // task.lastEntry = h;
    // console.log(task?.lastEntry, moment(task?.lastEntry?.date).format('L'), moment().format('L'));
    if (
      item?.lastEntry &&
      moment(item?.lastEntry?.date).format("L") === moment().format("L")
    ) {
      // console.log(item?.lastEntry, item?.lastEntry?.dayTotal, 'in if');
      if (task.lastEntry?.dayTotal > 0) {
        // console.log(item.lastEntry, item.lastEntry.dayTotal, 'in 2nd if');
        item.lastEntry.dayTotal += h.qty;
      } else if (task.lastEntry?.qty > 0) {
        // console.log(item.lastEntry, 'in 2nd else');
        item.lastEntry.dayTotal = item.lastEntry.qty + h.qty;
      } else {
        // console.log(item.lastEntry, 'in 2nd else');
        item.lastEntry.dayTotal = h.qty;
      }
    } else {
      // console.log(item.lastEntry, 'in 1st else');
      h.dayTotal = h.qty;
      item.lastEntry = h;
    }
    if (task.actsTotal) {
      item.actsTotal += actual.qty;
    } else {
      item.actsTotal = actual.qty;
    }
    // console.log(item);
    const timesheetworktime = String(moment(new Date().getTime()));
    const dataId = item.id + moment().format("DDDDYYYY");
    // console.log(dataId);
    setTimeout(() => {
      try {
        if (item.tag === "sub") {
          /* -------------------------- Root Task Node ----------------------------*/
          this.usd.afs
            .doc(`tasks/${item.id}`)
            .update({
              lastEntry: item.lastEntry,
              updateTime: actual.updateTime,
              actsTotal: task.actsTotal,
            })
            .then(() => {
              // console.log('Root tak upadted');
              this.taskCount(item);
              this.usd.afs
                .doc(`tasks/${item.id}/actionActuals/${timesheetworktime}`)
                .set(actual);
            })
            .catch((er) => {
              this.usd.afs.doc(`tasks/${item.id}`).set(item);
              // .then(() => console.log('Root tak Set'));
            });
          /* -------------------------- Task Champion Node ----------------------------*/
          const chamRef = this.usd.myDocument.collection("tasks").doc(item.id);
          chamRef
            .update({
              lastEntry: item.lastEntry,
              updateTime: actual.updateTime,
              actsTotal: task.actsTotal,
            })
            .then(() => {
              // this.usd.getMyTasks();;
              // console.log('tasks Updated');
            })
            .catch((rt) => console.log(rt));
          chamRef
            .collection("actionActuals")
            .doc(timesheetworktime)
            .set(actual);
          /* -------------------------- End Enterprise Node---------------------------*/
        } else if (item.tag === "std") {
          // console.log(item.tag);
          const standards = this.usd.afs.doc(
            `Users/${champId}/myStandards/${item.id}`
          );
          standards
            .update({
              lastEntry: item.lastEntry,
              updateTime: actual.updateTime,
              actsTotal: task.actsTotal,
            })
            /* .then(() => console.log('tasks Updated')) */
            .catch((rt) => console.log(rt));
          standards
            .collection("actionActuals")
            .doc(timesheetworktime)
            .set(actual)
            .then(() => {
              // console.log('Standard update successful,'); /* update successful (document exists) */
            })
            .catch((error) => {
              console.log(error);
              standards
                .collection("actionActuals")
                .doc(timesheetworktime)
                .set(actual);
            });
        }
      } catch (er) {
        console.log(er);
      }
    }, 2000);
    if (item.equipment && task.equipment.length !== 0) {
      // console.log(task.equipment);
      // console.log(constantTask.equipment);
      this.usd.myDocument
        .collection("tasks")
        .doc(item.id)
        .update({ equipment: constantTask.equipment })
        /* .then(() => console.log('Task Equipment Show History Updated')) */ .catch(
          (er) => console.log(er)
        );
      task.equipment.forEach((element) => {
        const as = constantTask.equipment.find(
          (tool) => tool.id === element.id
        );
        if (as) {
          // console.log(as);
          this.addEquipmentActuals(as, task);
        }
      });
    }
    return { updateTime: "", qty: null, by: "", byId: "" };
  }

  addEquipmentActuals(tool, task) {
    const dd = moment().toString();
    const tsk: any = {
      description: task.description,
      name: task.name,
      id: task.id,
      champion: task.champion,
      update: dd,
      tool: tool,
      projectId: task.projectId,
      projectName: task.projectName,
    };
    // console.log(isNaN(tool.actsTotal));
    if (isNaN(tool.actsTotal)) {
    } else {
      tsk.toolActualTotal = tool.actsTotal;
      // console.log(tool.actsTotal);
    }
    const acts: any = {
      measurement: tool.actsTotal,
      update: dd,
    };
    if (tool.actuals) {
      tool.actuals.push(acts);
    } else {
      tool.actuals = [];
      tool.actuals.push(acts);
    }
    /* Change to number of units from the last entry upto the new entry */
    this.usd.afs
      .doc(`Equipment/${tool.id}/tasks/${task.id}`)
      .ref.get()
      .then((ref) => {
        if (ref.exists && ref.data().toolActualTotal !== tsk.toolActualTotal) {
          if (isNaN(tool.actsTotal)) {
          } else {
            tsk.toolActualTotal = ref.data().toolActualTotal + tool.actsTotal;
          }
          this.usd.afs
            .doc(`Equipment/${tool.id}/tasks/${task.id}`)
            .update({ toolActualTotal: tsk.toolActualTotal })
            .then(() => {
              this.usd.afs
                .doc(`Equipment/${tool.id}/tasks/${task.id}`)
                .update({
                  toolActuals:
                    this.usd.firebase.firestore.FieldValue.arrayUnion(acts),
                })
                .then(() => {
                  this.ns.showNotification(
                    "Equip_Actuals",
                    "top",
                    "right",
                    tool
                  );
                })
                .catch((er) => {
                  tsk.toolActuals = [acts];
                  this.usd.afs
                    .doc(`Equipment/${tool.id}/tasks/${task.id}`)
                    .set(tsk)
                    .then(() => {
                      this.ns.showNotification(
                        "Equip_Actuals",
                        "top",
                        "right",
                        tool
                      );
                    });
                  // console.log(`Error: no doc found ${er}`)
                });
            })
            .catch((er) => {
              this.usd.afs
                .doc(`Equipment/${tool.id}/tasks/${task.id}`)
                .set(tsk)
                .then(() => {
                  this.usd.afs
                    .doc(`Equipment/${tool.id}/tasks/${task.id}`)
                    .update({
                      toolActuals:
                        this.usd.firebase.firestore.FieldValue.arrayUnion(acts),
                    })
                    .then(() => {
                      this.ns.showNotification(
                        "Equip_Actuals",
                        "top",
                        "right",
                        tool
                      );
                    })
                    .catch((errors) => {
                      tsk.toolActuals = [acts];
                      this.usd.afs
                        .doc(`Equipment/${tool.id}/tasks/${task.id}`)
                        .set(tsk)
                        .then(() => {
                          this.ns.showNotification(
                            "Equip_Actuals",
                            "top",
                            "right",
                            tool
                          );
                        });
                      // console.log(`Error: no doc found ${errors}`)
                    });
                });
            });
          this.usd.afs
            .doc(`Equipment/${tool.id}/tasks/${task.id}`)
            .update({
              toolActuals:
                this.usd.firebase.firestore.FieldValue.arrayUnion(acts),
            })
            .then(() => {
              this.ns.showNotification("Equip_Actuals", "top", "right", tool);
            })
            .catch((er) => {
              tsk.toolActuals = [acts];
              this.usd.afs
                .doc(`Equipment/${tool.id}/tasks/${task.id}`)
                .set(tsk)
                .then(() => {
                  this.ns.showNotification(
                    "Equip_Actuals",
                    "top",
                    "right",
                    tool
                  );
                });
              // console.log(`Error: no doc found ${er}`)
            });
          // } else { }
        } else {
          tsk.toolActuals = [acts];
          this.usd.afs
            .doc(`Equipment/${tool.id}/tasks/${task.id}`)
            .set(tsk)
            .then(() => {
              this.ns.showNotification("Equip_Actuals", "top", "right", tool);
            })
            .catch((er) => {
              // console.log(`Error: doc not set ${er}`)
            });
        }
      });
  }

  addOrder(task, n) {
    const priority = {
      orderNo: n,
      set: true,
    };
    this.usd.diaryTasks[n].priority = priority;
    const dd = new Date().toISOString();
    task.update = dd;
    if (task.tag === "sub") {
      this.usd.afs
        .doc(`Users/${this.usd.userId}/tasks/${task.id}`)
        .update({ priority: priority })
        .then(() => {
          // console.log(task.name, task.description, 'update: task Order Updated string updated');
          // this.usd.dp(this.usd.diaryTasks);
        });
    } else if (task.tag === "std") {
      this.usd.afs
        .doc(`Users/${this.usd.userId}/myStandards/${task.id}`)
        .update({ priority: priority })
        .then(() => {
          // console.log(task.name, task.description, 'update: std Order Updated string updated');
          // this.usd.dp(this.usd.diaryTasks);
        });
    }
  }

  afsSetTask(task) {
    if (task.tag === "sub") {
      this.usd.afs
        .doc(`Users/${this.usd.userId}/tasks/${task.id}`)
        .update(task)
        .then(() => {
          // console.log(task.description, task.name, task.priority.orderNo, 'update: task Order Updated string updated');
        });
    } else if (task.tag === "std") {
      this.usd.afs
        .doc(`Users/${this.usd.userId}/myStandards/${task.id}`)
        .update(task)
        .then(() => {
          // console.log(task.name, task.priority.orderNo, 'update: std Order Updated string updated');
        });
    }
  }

  orderTasks(items) {
    items.forEach((element) => {
      const indx = items.findIndex((tsk) => tsk.id === element.id);
      element.priority.orderNo = indx;
      this.afsSetTask(element);
    });
  }

  stdOrder(tsk: any) {
    const task: any = tsk;
    const priority = {
      orderNo: 0,
      set: true,
    };
    const dd = new Date().toISOString();
    task.update = dd;
    task.priority = priority;
    // console.log(task.name, 'priority set true');
    this.usd.afs
      .doc(`Users/${this.usd.userId}/myStandards/${task.id}`)
      .update({ update: dd, priority: priority })
      .then(() => {
        // console.log(task.name, 'std: Updated Check Diary');
        this.usd.diaryTasks.splice(0, 0, task);
        this.usd.diaryTasks.join();
        this.usd.diaryTasks.forEach((element) => {
          const indx = this.usd.diaryTasks.findIndex(
            (tk) => tk.id === element.id
          );
          // if (indx === 0) {
          //   element.priority.orderNo = (indx + 1);
          // } else if (element.priority.orderNo === (indx + 1)) { } else {
          //   element.priority.orderNo = (indx + 1);
          element.priority.orderNo = indx;
          this.afsSetTask(element);
          // }
          // console.log(element.description || element.name, element.priority.orderNo);
        });
        this.rs.gotoDiary();
      });
  }

  prioritise(tsk: any) {
    const task: any = tsk;
    const dd = new Date().toISOString();
    const priority = {
      orderNo: 0,
      set: true,
    };
    task.priority = priority;
    task.update = dd;
    // console.log(task.description || task.name, 'priority set true');
    // console.log(task.champion);
    this.usd.afs
      .doc(`Users/${this.usd.userId}/tasks/${task.id}`)
      .update({ update: dd, priority: priority })
      .then(() => {
        // console.log('document updated');
        this.taskCount(task);
      })
      .catch((er) => {
        this.usd.afs.doc(`Users/${this.usd.userId}/tasks/${task.id}`).set(task);
        /* .then(() => console.log('error,', er, 'capture and soltion set')); */
      });
    this.usd.diaryTasks.splice(0, 0, task);
    this.usd.diaryTasks.join();
    this.usd.diaryTasks.forEach((element) => {
      const indx = this.usd.diaryTasks.findIndex((tk) => tk.id === element.id);
      element.priority.orderNo = indx;
      this.afsSetTask(element);
      // console.log(element.description || element.name, element.priority.orderNo);
    });
    // this.rs.gotoDiary();
  }

  upDateReport(arr: any[]) {
    // console.log('upDateReport');
    const day = moment().toString();
    arr.forEach((task) => {
      const doc = { hours: task.day.hours, id: day };
      task.day = doc;
      // task.day.date = moment().toISOString();
      const dayFound = task?.days?.find((yad) => yad.id === day);
      const i = task?.days?.findIndex((yad) => yad.id === day);
      this.taskCount(task);
      if (!task?.days) {
        task.days = [doc];
        // console.log(task.day, task.description, task.days);
        this.setask(task);
      } else if (dayFound) {
        task.days[i] = doc;
        // console.log(task.day, task.description, task.days);
        this.setask(task);
      } else if (!dayFound) {
        task.days.push(doc);
        // console.log(task.day, task.description, task.days);
        this.setask(task);
      }
    });
  }

  setask(setTask) {
    if (setTask.day.hours > 0) {
      this.usd.afs
        .doc(`Users/${this.usd.userId}/tasks/${setTask.id}`)
        .update({ days: setTask.days, day: setTask.day })
        .then(() => {
          // console.log(`setTask.days for task: ${setTask.description} updated`);
        })
        .catch((error) => {
          // console.log('Error byId document updating Completion, document does not exists trying Again', error);
          this.usd.afs
            .doc(`Users/${this.usd.userId}/tasks/${setTask.id}`)
            .set(setTask)
            .then(() => {
              // console.log(`setTask.days for task: ${setTask.description} updated`);
            });
        });
    }
  }

  deleteRemoved(setTask, array3) {
    // console.log(array3);
    if (array3.length !== 0) {
      array3.forEach((element) => {
        // console.log(element)
        this.usd.afs
          .doc(`Users/${element.id}/tasks/${setTask.id}`)
          .delete()
          .then(() => {
            // console.log('Task deleted from user', element.name);
          })
          .catch((error) => {
            // console.log('Error deleteing task from ', element.name, ', document does not exists trying Again', error);
          });
      });
    }
  }

  taskUpdate(setTask: any, oldParts: any[]) {
    // const tsk: any  = setTask;
    // tsk.update: string = ''
    // console.log(tsk.update);
    // console.log(new Date().toISOString());
    setTask.update = new Date().toISOString();
    this.deleteRemoved(
      setTask,
      oldParts?.filter((obj) => {
        const ind = setTask.participants.findIndex((y) => y.id === obj.id);
        if (ind > -1) {
        } else {
          return obj;
        }
      })
    );
    // console.log('the actionItem -->' + setTask.name, setTask);
    if (setTask.champion?.id) {
      this.usd.afs
        .doc(`Users/${this.usd.acc?.id}/tasks/${setTask.id}`)
        .set(setTask)
        .then(() => {
          // console.log('document updated');
          /* ----------------------- Set every Other Node --------------------------- */
          if (setTask.byId !== setTask.champion.id) {
            this.usd.afs
              .doc(`Users/${setTask.byId}/tasks/${setTask.id}`)
              .set(setTask)
              .then(() => {
                // console.log('byId document updated');
              })
              .catch((error) => {
                // console.log('Error byId document updating Completion, document does not exists trying Again', error);
              });
          }
          if (setTask.participants?.length > 0) {
            setTask.participants.forEach((element) => {
              // actualData: [], workHours: [], days: [], actualQty: 0, amount: 0, workHoursRpt: []
              this.usd.afs
                .doc(`Users/${element.id}/tasks/${setTask.id}`)
                .set(setTask)
                .then(() => {
                  this.usd.afs
                    .doc(`Users/${element.id}/tasks/${setTask.id}`)
                    .update({
                      actualData: [],
                      workHours: [],
                      workHoursRpt: [],
                      days: [],
                    });
                  /* .then(() => console.log('participants.id document updated for', element.name)) */
                })
                .catch((error) => {
                  // console.log('Error byId document updating Completion, document does not exists trying Again', error);
                });
            });
          }
          if (setTask.supervisor.id !== "") {
            this.usd.afs
              .doc(`Users/${setTask.supervisor.id}/tasks/${setTask.id}`)
              .set(setTask)
              .then(() => {
                // console.log('supervisor.id document updated');
              })
              .catch((error) => {
                // console.log('Error byId document updating Completion, document does not exists trying Again', error);
              });
          }
          this.usd.afs
            .collection("tasks")
            .doc(setTask.id)
            .set(setTask)
            .then(() => {
              // console.log('document updated');
            })
            .catch((error) => {
              // console.log('Error updating Completion, document does not exists trying Again', error);
            });
          /* --------------------- End Set every Other Node -------------------------- */
        })
        .catch((error) => {
          // console.log('Error updating Completion, document does not exists trying Again', error);
        });
    }
  }

  deleteRemTools(task, oldParts) {
    // console.log(oldParts);
    if (task.removedTools && task.removedTools.length > 0) {
      if (oldParts.length > 0) {
        task.removedTools.filter((obj) => {
          const ind = oldParts.findIndex((y) => y.id === obj.id);
          if (ind > -1) {
          } else {
            return obj;
          }
        });
        return task;
      } else {
        if (task.removedTools) {
          // console.log(task);
          return task;
        } else {
          task.removedTools = [];
          // console.log(task);
          return task;
        }
      }
    } else {
      task.removedTools = oldParts;
      // console.log(task);
      return task;
    }
  }

  async equipTask(setTask: any) {
    setTask.equipment = this.usd.selectedHiredEquipment.concat(
      this.usd.selectedEquipment
    );
    // console.log(setTask.equipment.length);
    // console.log(this.usd.taskTools.length);
    const tskTs = this.usd.taskTools.filter((obj) =>
      setTask.equipment.find((y) => y.id !== obj.id)
    );
    // console.log(tskTs);
    // console.log(this.deleteRemTools(setTask, tskTs));
    this.saveTsk(this.deleteRemTools(setTask, tskTs));
  }

  saveTsk(setTask) {
    // const setTask: any = this.usd.taskset;
    // setTask.removedTools = removedTools;
    // console.log(setTask.removedTools);
    // console.log(setTask);
    // console.log(new Date().toISOString());
    // console.log('the actionItem -->' + setTask.name);
    if (setTask.champion?.id) {
      this.usd.afs
        .doc(`Users/${this.usd.acc?.id}/tasks/${setTask.id}`)
        .update(setTask)
        .then(() => {
          // console.log('document updated');
          /* ----------------------- Set every Other Node --------------------------- */
          if (setTask.byId !== setTask.champion.id) {
            this.usd.afs
              .doc(`Users/${setTask.byId}/tasks/${setTask.id}`)
              .update(setTask)
              .then(() => {
                // console.log('byId document updated');
              })
              .catch((error) => {
                // console.log('Error byId document updating Completion, document does not exists trying Again', error);
              });
          }
          if (setTask.participants?.length > 0) {
            setTask.participants.forEach((element) => {
              this.usd.afs
                .doc(`Users/${element.id}/tasks/${setTask.id}`)
                .update(setTask)
                .then(() => {
                  // console.log('supervisor.id document updated');
                })
                .catch((error) => {
                  // console.log('Error byId document updating Completion, document does not exists trying Again', error);
                });
            });
          }
          if (setTask.supervisor.id !== "") {
            this.usd.afs
              .doc(`Users/${setTask.supervisor.id}/tasks/${setTask.id}`)
              .update(setTask)
              .then(() => {
                // console.log('supervisor.id document updated');
              })
              .catch((error) => {
                // console.log('Error byId document updating Completion, document does not exists trying Again', error);
              });
          }
          this.usd.afs
            .collection("tasks")
            .doc(setTask.id)
            .update(setTask)
            .then(() => {
              // console.log('document updated');
            })
            .catch((error) => {
              // console.log('Error updating Completion, document does not exists trying Again', error);
            });
          /* --------------------- End Set every Other Node -------------------------- */
        })
        .catch((error) => {
          // console.log('Error updating Completion, document does not exists trying Again', error);
        });
    }
  }

  addParicipants(task: Task, part, parts) {
    const champPath = `Users/${task.champion.id}/tasks/${task.id}`;
    // console.log('part i')
    this.usd.afs
      .doc(champPath)
      .update({
        participants: this.usd.firebase.firestore.FieldValue.arrayUnion(part),
      })
      .then(() => {
        // console.log('participants updated');
        // console.log('part ii');
        if (parts) {
          // console.log(parts.length);
          parts.forEach((element) => {
            if (task.participants && task.participants.length > -1) {
              this.partcCheck(task, element);
            } else if (task.participants === null) {
              task.participants = [];
              this.partcCheck(task, element);
            } else {
              // console.log('Has failed the above ifs', task);
            }
          });
        } else if (part) {
          // console.log(part);
          if (task.participants && task.participants.length > -1) {
            this.partcCheck(task, part);
          } else if (task.participants === null) {
            task.participants = [];
            this.partcCheck(task, part);
          } else {
            // console.log('Has failed the above ifs', task);
          }
        } else {
          // console.log('Has failed part nor parts');
        }
      })
      .catch((tinoErr) => console.log(tinoErr));
  }

  partcCheck(task, part) {
    const partPath = `Users/${part.id}/tasks/${task.id}`;
    task.participants.push(part);
    this.usd.afs.doc(partPath).set(task);
  }

  remParicipants(task: Task, part) {
    const champPath = `Users/${task.champion.id}/tasks/${task.id}`;
    // console.log('part i')
    this.usd.afs
      .doc(champPath)
      .update({
        participants: this.usd.firebase.firestore.FieldValue.arrayRemove(part),
      })
      .then(() => {
        // console.log('participants updated');
        // console.log('part ii');
        // console.log(part);
        if (task.participants && task.participants.length > -1) {
          this.partdelete(task, part);
          task.participants = task.participants.filter(
            (munhu) => munhu.id === part.id
          );
        } else if (task.participants === null) {
          task.participants = [];
          this.partdelete(task, part);
        } else {
          // console.log('Has failed the above ifs', task);
        }
      })
      .catch((tinoErr) => console.log(tinoErr));
  }

  partdelete(task, part) {
    const partPath = `Users/${part.id}/tasks/${task.id}`;
    task.participants.push(part);
    this.usd.afs.doc(partPath).delete();
  }

  changeChamp(setTask: any, newChamp, old_Champ) {
    setTask.update = new Date().toISOString();
    // console.log('new', newChamp.name, 'old', setTask.champion.name, 'old', old_Champ);
    // console.log('the actionItem -->' + setTask.name);
    const dataId = this.usd.userId + moment().format("DDDDYYYY");
    setTask.champion = newChamp;
    setTask.old_Champ = old_Champ;
    setTask.rel_by = this.usd.acc;
    const mailData = {
      by: {
        id: this.usd?.acc?.id,
        name: this.usd?.acc?.name,
        email: this.usd?.acc?.bus_email || this.usd?.acc.email,
        photoUrl: this.usd?.acc?.photoURL || "",
      },
      task: setTask,
      name: setTask.champion.name,
      email: setTask.champion.bus_email || setTask.champion.email,
      subjet: "New Task Notice",
      text: "New Task Notice",
    };
    if (setTask?.id && setTask.id !== "") {
      this.reallocateSave(old_Champ, newChamp, mailData, setTask, dataId);
    } else {
      if (setTask?.description) {
        this.usd.afs
          .collection(`Users/${old_Champ?.id}/tasks`, (ref) =>
            ref.where("description", "==", setTask.description)
          )
          .snapshotChanges()
          .pipe(
            map((b) =>
              b.map((a) => {
                const data = a.payload.doc.data() as any;
                data.id = a.payload.doc.id;
                return data;
              })
            )
          )
          .subscribe((fndRef) => {
            if (fndRef?.length === 1) {
              setTask.id = fndRef[0]?.id;
              this.reallocateSave(
                old_Champ,
                newChamp,
                mailData,
                setTask,
                dataId
              );
            } else if (fndRef?.length === 1) {
              const fndTask = fndRef.find(
                (doc) =>
                  doc.createdOn === setTask.createdOn &&
                  doc.byId === setTask.byId &&
                  doc.by === setTask.by
              );
              if (fndTask) {
                setTask.id = fndTask?.id;
                this.reallocateSave(
                  old_Champ,
                  newChamp,
                  mailData,
                  setTask,
                  dataId
                );
              }
            } else {
              // console.log('Task not found');
            }
          });
      }
    }
  }

  reallocateSave(old_Champ, newChamp, mailData, setTask, dataId) {
    if (setTask.champion?.id) {
      this.usd.afs
        .doc(`Users/${newChamp?.id}/tasks/${setTask.id}`)
        .set(setTask)
        .then(() => {
          const task_Notice: any = setTask;
          task_Notice.rel_by = this.usd.acc;
          task_Notice.read = false;
          task_Notice.noticeDate = moment().toISOString();
          task_Notice.dataId = dataId;
          if (
            (!old_Champ || old_Champ.name === "") &&
            setTask.champCompany &&
            setTask.champCompany.id !== ""
          ) {
            this.emailNewCmpChamp(mailData);
          } else {
            this.emailNewChamp(mailData);
          }
          if (this.usd.acc.id !== setTask.champion.id) {
            task_Notice.noti = "tasksReallacatedTo";
            this.usd.afs
              .doc(`Users/${newChamp?.id}/allNotice/${dataId}`)
              .set(task_Notice);
          }
          if (old_Champ?.id && old_Champ?.id !== "") {
            if (old_Champ?.id !== this.usd.userId) {
              task_Notice.noti = "tasksReallacatedFrom";
              this.usd.afs
                .doc(`Users/${old_Champ?.id}/allNotice/${dataId}`)
                .set(task_Notice);
            }
            this.usd.afs
              .doc(`Users/${old_Champ?.id}/tasks/${setTask.id}`)
              .delete();
            this.ns.showNotification(
              "task_realocation",
              "top",
              "right",
              setTask
            );
          } else {
            // console.log(' from not set, cause old champ undefined');
          }
          // console.log('document updated');
          /* ----------------------- Set every Other Node --------------------------- */
          this.usd.afs
            .collection("tasks")
            .doc(setTask.id)
            .update({ champion: newChamp })
            .then(() => {
              // console.log('document updated');
            })
            .catch((error) => {
              this.usd.afs
                .collection("tasks")
                .doc(setTask.id)
                .set(setTask)
                .then(() => {});
              // console.log('Error updating Completion, document does not exists trying Again', error);
            });
          if (setTask.byId !== setTask.champion.id) {
            this.usd.afs
              .doc(`Users/${setTask.byId}/tasks/${setTask.id}`)
              .update({ champion: newChamp })
              .then(() => {
                // console.log('document updated');
              })
              .catch((error) => {
                this.usd.afs
                  .doc(`Users/${setTask.byId}/tasks/${setTask.id}`)
                  .set(setTask)
                  .then(() => {});
                // console.log('Error updating changeChamp, document does not exists trying Again', error);
              });
          }
          if (setTask.participants?.length > 0) {
            setTask.participants.forEach((element) => {
              this.usd.afs
                .doc(`Users/${element.id}/tasks/${setTask.id}`)
                .update({ champion: newChamp })
                .then(() => {
                  // console.log('document updated');
                })
                .catch((error) => {
                  this.usd.afs
                    .doc(`Users/${element.id}/tasks/${setTask.id}`)
                    .set(setTask)
                    .then(() => {});
                  // console.log('Error updating changeChamp, document does not exists trying Again', error);
                });
            });
          }
          if (
            setTask.supervisor.id !== "" &&
            setTask.supervisor.id !== setTask.byId
          ) {
            this.usd.afs
              .doc(`Users/${setTask.supervisor.id}/tasks/${setTask.id}`)
              .update({ champion: newChamp })
              .then(() => {
                // console.log('document updated');
              })
              .catch((error) => {
                this.usd.afs
                  .doc(`Users/${setTask.supervisor.id}/tasks/${setTask.id}`)
                  .set(setTask)
                  .then(() => {});
                // console.log('Error updating changeChamp, document does not exists trying Again', error);
              });
          }
          /* --------------------- End Set every Other Node -------------------------- */
        })
        .catch((error) => {
          // console.log('Error updating changeChamp, document does not exists trying Again', error);
        });
    }
  }

  addActionTime(action: Task) {
    this.usd.afs
      .doc(`Users/${action.champion.id}/tasks/${action.id}`)
      .update(action)
      .then(() => {});
  }

  addClassActionTime(action: Task) {
    this.usd.afs
      .doc(`Users/${this.usd.userId}/myStandards/${action.id}`)
      .update(action)
      .then(() => {
        this.taskCount(action);
      });
  }

  setSupervisor(task) {
    // console.log(task.supervisor);
    if (
      task.companyId &&
      task.supervisor &&
      task.supervisor?.name &&
      task.supervisor?.name !== ""
    ) {
      this.usd.afs
        .doc(`Enterprises/${task.companyId}/Participants/${task.champion.id}`)
        .update({ supervisor: task.supervisor })
        .then(() => {
          // console.log('supervisor set')
        });
    }
  }

  ackTaskNudge(nudg, act) {
    console.log(nudg);
    nudg.read = true;
    this.usd.afs.doc(`Users/${this.usd.acc.id}/allNotice/${nudg.id}`).update({ read: true }).then(() => {
        if (act === "open") {
          this.usd.totalNotices = this.usd?.totalNotices.filter(
            (x) => x.id !== nudg.id
          );
          this.usd.tasksComs(nudg.task);
          this.tsk.task = nudg.task;
          this.rs.viewTask(nudg.task);
        }
      });
  }

  nudge(task: Task) {
    const dd = new Date().toISOString();
    const nudg: any = {
      task: task,
      by: this.usd.acc,
      time: dd,
      read: false,
      noti: "nudge",
      id: ''
    };
    // console.log(nudg);
    this.usd.afs.collection(`Users/${task.champion.id}/allNotice`).add(nudg).then((dcRef) => {
        const nudId = dcRef.id;
        // console.log('Nudged', task.champion.name, nudId, nudg);
        nudg.id = dcRef.id;
        this.usd.afs.doc(`Users/${task.champion.id}/allNotice/${nudId}`).update({ id: nudId });
        this.usd.afs.doc(`Users/${task.champion.id}/tasks/${task.id}`).update({ selectedWork: true }).then(() => {})
          .catch(() => {
            task.selectedWork = true;
            this.usd.afs.doc(`Users/${task.champion.id}/tasks/${task.id}`).set(task);
          });
        if (task.supervisor && task.supervisor.name !== "" && task.supervisor.id !== this.usd.acc.id) {
          this.usd.afs.doc(`Users/${task.supervisor.id}/allNotice/${nudId}`).set(nudg)
            .then(() => {
              // console.log('supervisor and champ nudged', nudId)
            });
        }
      });
  }

  uploadFile(event, selectTask) {
    if (event === undefined) {
    } else {
      const file = event.target.files[0];
      const filePath = `Tasks/${selectTask?.id}/${file.name}`;
      // const filePath = 'Tasks' + file.name;
      // this.docs.push(file.name);
      // console.log(filePath);
      const task = this.usd.storage.upload(filePath, file);
      // observe percentage changes
      // console.log('get %');
      task.percentageChanges().subscribe((num: number) => {
        this.uploadPercent = Math.round(num);
        // console.log(this.uploadPercent);
      });
      // get notified when the download URL is available
      task.then(() => {
        // console.log('get url');
        const fileRef = this.usd.storage.ref(filePath);
        task
          .snapshotChanges()
          .pipe(
            finalize(() => {
              // console.log('Done');
              this.downloadURL = fileRef.getDownloadURL();
              this.downloadURL.subscribe((uj) => {
                const str = uj;
                // console.log(str);
                const dsFile = {
                  name: file.name,
                  date: new Date().toString(),
                  downloadURL: str,
                };
                if (
                  this.usd.setTask.description !== "" ||
                  this.usd.setTask.id !== ""
                ) {
                  if (
                    this.usd.setTask.docs &&
                    this.usd.setTask.docs?.length >= 0
                  ) {
                    this.usd.setTask.docs.push(dsFile);
                  } else {
                    this.usd.setTask.docs = [dsFile];
                  }
                }
                this.updateTask(dsFile, selectTask);
              });
              // console.log(this.downloadURL);
            })
          )
          .subscribe();
      });
    }
  }

  deleteFile(file, selectTask) {
    if (file === undefined) {
    } else {
      const filePath = `Tasks/${selectTask?.id}/${file.name}`;

      try {
        // this.usd.storage.storage.refFromURL(file.downloadURL).delete();
        this.usd.storage.ref(filePath).delete();
        const task = this.usd.storage.upload(filePath, file);
        // observe percentage changes
        // console.log('get %');
        task.percentageChanges().subscribe((num: number) => {
          this.uploadPercent = Math.round(num);
          // console.log(this.uploadPercent);
        });
        // get notified when the download URL is available
        task
          .then(() => {
            this.usd.setTask.docs = this.usd.setTask.docs.filter(
              (x) => x.downloadURL !== file?.downloadURL
            );
            this.remFileTask(file, selectTask);
          })
          .catch((err) => {
            console.log(err.code);
            this.usd.setTask.docs = this.usd.setTask.docs.filter(
              (x) => x.downloadURL !== file?.downloadURL
            );
            this.remFileTask(file, selectTask);
          });
      } catch (error) {
        console.log(error.message);
        this.usd.setTask.docs = this.usd.setTask.docs.filter(
          (x) => x.downloadURL !== file?.downloadURL
        );
        this.remFileTask(file, selectTask);
      }
    }
  }

  uploadReport(event, RptData) {
    if (event === undefined) {
    } else {
      const file = event.target.files[0];
      const filePath = `User/${RptData?.by?.id}/Reports/${file.name}`;
      // const filePath = 'Tasks' + file.name;
      // this.docs.push(file.name);
      // console.log(filePath);
      const task = this.usd.storage.upload(filePath, file);
      // observe percentage changes
      // console.log('get %');
      task.percentageChanges().subscribe((num: number) => {
        this.uploadPercent = Math.round(num);
        // console.log(this.uploadPercent);
      });
      // get notified when the download URL is available
      task.then(() => {
        // console.log('get url');
        const fileRef = this.usd.storage.ref(filePath);
        task
          .snapshotChanges()
          .pipe(
            finalize(() => {
              // console.log('Done');
              this.downloadURL = fileRef.getDownloadURL();
              this.downloadURL.subscribe((uj) => {
                const str = uj;
                // console.log(str);
                const dsFile = {
                  name: file.name,
                  date: new Date().toString(),
                  downloadURL: str,
                };
                RptData.doc = dsFile;
                this.saveReport(RptData);
              });
              // console.log(this.downloadURL);
            })
          )
          .subscribe();
      });
    }
  }

  saveReport(n) {
    n.date = new Date().toISOString();
    this.usd.afs.collection(`Users/${n?.by?.id}/reports/`).add(n).then((repRef) => {
      const id = repRef.id;
      n.id = id;
      this.usd.afs.doc(`Users/${n?.by?.id}/reports/${id}`).update({ id: id }).then(() => {
        // console.log('updated');
        this.usd.setReport = n;
        this.rs.routeRpt(n);
      }).catch((er) => console.log(er));
    });
  }

  uploadProjectFile(event, project) {
    if (event === undefined) {
    } else {
      const file = event.target.files[0];
      const filePath = `Projects/${project?.id}/photofeed/${file.name}`;
      const afsPath = `Projects/${project?.id}/photofeed`;
      // console.log(filePath);
      const photoFeedFile = this.usd.storage.upload(filePath, file);
      // observe percentage changes
      // console.log('get %');
      photoFeedFile.percentageChanges().subscribe((num: number) => {
        this.uploadPercent = Math.round(num);
        // console.log(this.uploadPercent);
      });
      // get notified when the download URL is available
      photoFeedFile.then(() => {
        // console.log('get url');
        const fileRef = this.usd.storage.ref(filePath);
        photoFeedFile.snapshotChanges().pipe(
            finalize(() => {
              // console.log('Done');
              this.downloadURL = fileRef.getDownloadURL();
              this.downloadURL.subscribe((uj) => {
                const str = uj;
                // console.log(str);
                const dsFile = {
                  name: file.name, date: new Date().toString(),
                  downloadURL: str, id: "",
                };
                this.usd.afs.collection(afsPath).add(dsFile)
                  .then((fileRef) => {
                    const fileId = fileRef.id;
                    if (fileId) {
                      this.usd.afs.collection(afsPath).doc(fileRef.id).update({ id: fileId })
                        .then(() => console.log("photofeed updated"))
                        .catch((e) =>
                          console.log("photofeed data set failed", e)
                        );
                    }
                  });
              });
              // console.log(this.downloadURL);
            })
          )
          .subscribe();
      });
    }
  }

  uploadProfilePic(nFile) {
    // uploadProfilePic(event) {
    if (nFile === undefined) {
    } else {
      // const file = event.target.files[0];
      const file = nFile;
      // console.log(file);
      const myFile: any = this.usd.acc;
      myFile.oldPhotoUrl = this.usd.acc.photoURL || this.usd.acc.userImg || "";
      const filePath = `faces/${myFile?.id}/${file.name}`;
      // console.log(filePath);
      const locRef = this.usd.storage.upload(filePath, file);
      // observe percentage changes
      // console.log('get %');
      locRef.percentageChanges().subscribe((num: number) => {
        this.picUploadPercent = Math.round(num);
      });
      // get notified when the download URL is available
      locRef.then(() => {
        // console.log('get url');
        const fileRef = this.usd.storage.ref(filePath);
        locRef.snapshotChanges().pipe(
            finalize(() => {
              // console.log('Done');
              fileRef.getDownloadURL().subscribe((uj) => {
                const str = uj;
                myFile.photoURL = str;
                // console.log(str);
                this.usd.myDocument.update(myFile).then(() => {
                  // console.log('photo url updated');
                  setTimeout(() => {
                    // let num;
                    this.picUploadPercent = null;
                    // console.log('Done');
                  }, 300);
                });
              });
            })
          )
          .subscribe();
      });
    }
  }

  uploadEntLogo(nFile) {
    // uploadProfilePic(event) {
    if (!nFile) {
    } else {
      // const file = event.target.files[0];
      const file = nFile;
      // console.log(file);
      const myFile: any = this.usd.setCompany;
      myFile.oldPhotoUrl = this.usd.setCompany.photoURL;
      const filePath = `EntLogo/${myFile?.id}/${file.name}`;
      // console.log(filePath);
      const locRef = this.usd.storage.upload(filePath, file);
      // observe percentage changes
      // console.log('get %');
      locRef.percentageChanges().subscribe((num: number) => {
        this.picUploadPercent = Math.round(num);
        // console.log(this.picUploadPercent);
        // let i = 0;
        // console.log(this.picUploadPercent);
      });
      // get notified when the download URL is available
      locRef.then(() => {
        // console.log('get url');
        const fileRef = this.usd.storage.ref(filePath);
        locRef.snapshotChanges().pipe(
            finalize(() => {
              // console.log('Done');
              fileRef.getDownloadURL().subscribe((uj) => {
                const str = uj;
                this.usd.afs.doc(`Enterprises/${myFile?.id}`).update({ logoURL: str }).then(() => {
                  // console.log('logo url updated');
                  setTimeout(() => {
                    this.picUploadPercent = null;
                    // console.log('Done');
                  }, 300);
                });
              });
            })
          ).subscribe();
      });
    }
  }

  setProfile() {
    this.usd.acc.name = this.usd.firstname + " " + this.usd.lastname;
    this.usd.myDocument.update(this.usd.acc).then(() => console.log("profile Updated")).catch((er) => {
      // console.log('Error updating profile', er);
      this.usd.myDocument.set(this.usd.acc).then(() => console.log("new Document profile set / created"));
    });
  }

  updateTask(objeto, selectTask) {
    return this.usd.afs.doc(`tasks/${selectTask?.id}`).update({
        docs: this.usd.firebase.firestore.FieldValue.arrayUnion(objeto),
      })
      .then(() => {
        // console.log('Updated', objeto.name, 'to', selectTask?.description, selectTask?.name);
      })
      .catch((err) => {
        // console.log('Document not available');
      });
  }

  remFileTask(file, selectTask) {
    return this.usd.afs.doc(`tasks/${selectTask?.id}`).update({
      docs: this.usd.firebase.firestore.FieldValue.arrayRemove(file),
    }).then(() => {
      // console.log('Updated', objeto.name, 'to', selectTask?.description, selectTask?.name);
    }).catch((err) => {
      // console.log('Document not available');
    });
  }

  clearFields() {
    this.fileValue = null;
    this.uploadPercent = null;
    this.docs = [];
  }

  ackTaskComment(task) {
    this.usd.totalNotices = this.usd?.totalNotices.filter(
      (x) => x.id !== task.id
    );
    task.read = true;
    this.usd.afs.doc(`Users/${this.usd.acc.id}/allNotice/${task.id}`).update({ read: true })
    .then(() => {
      // this.rs.viewTask(task);
      // console.log('comments read');
      this.usd.tasksComs(task);
      this.tsk.task = task;
      this.rs.commentTask(task);
    });
  }

  notifyPartsTaskComment(part, task, comment) {
    task.read = false;
    task.notRing = false;
    task.noticeDate = moment().toISOString();
    task.noti = "CommentsTaskPrtNotice";
    task.comment = comment;
    this.usd.afs.doc(`Users/${part?.id}/allNotice/${task.id}`).set(task).then(() => {})
    .catch((er) => {
      // console.log(part.name, 'cooms updated')
    });
  }

  comment(task) {
    this.newComment = !this.newComment;
    // console.log(this.Comment);
    if (this.Comment.name !== "") {
      this.Comment.createdOn = new Date().toISOString();
      this.Comment.by = this.usd.acc.name;
      this.Comment.byId = this.usd.acc?.id;
      // console.log(this.Comment);
      const docRef = this.usd.afs.collection(`tasks/${task.id}/comments`);
      docRef.add(this.Comment).then((doc) => {
        this.taskCount(task);
        docRef.doc(doc.id).update({ id: doc.id })
        .catch((error) => {
          // console.log('Error capture', error)
        });
        // console.log('comment submitted');
        this.Comment = {
          name: "", id: "", byId: "", by: "", createdOn: "", photoUrl: "",
        };
      });
      if (this.Comment.byId !== task.champion?.id) {
        const part = { name: task.champion?.name, id: task.champion?.id };
        // console.log(part?.name, 'comment notified');
        this.notifyPartsTaskComment(part, task, this.Comment);
      }
      if (task.byId !== this.Comment.byId && task.byId !== task.champion?.id) {
        const part = { name: task.by, id: task.byId };
        this.notifyPartsTaskComment(part, task, this.Comment);
        // console.log(part?.name, 'ByID comment notified');
      }
      if (task.participants) {
        // console.log(task.participants);
        task.participants.forEach((element) => {
          if (element.id !== this.Comment.byId && element.id !== task.byId) {
            this.notifyPartsTaskComment(element, task, this.Comment);
            // console.log(element?.name, 'Participants comment notified');
          }
        });
      }
    } else {
      // console.log('No comments found');
    }
  }

  editcomment(task, coment) {
    this.editComment = !this.editComment;
    // console.log(this.Comment);
    if (coment.name !== "") {
      coment.update = {
        updateOn: new Date().toISOString(),
        byId: this.usd.acc?.id,
        by: this.usd.acc.name,
      };
      // console.log(this.Comment);
      const docRef = this.usd.afs.doc(`tasks/${task.id}/comments/${coment.id}`);

      docRef
        .update({ name: coment.name })
        .catch((error) => {
          // console.log('Error capture', error)
        })
        .then((doc) => {
          this.taskCount(task);
          // console.log('comment submitted');
        });
      if (coment.update.byId !== task.champion?.id) {
        const part = { name: task.champion?.name, id: task.champion?.id };
        // console.log(part?.name, 'comment notified');
        this.notifyPartsTaskComment(part, task, coment);
      }
      if (task.byId !== coment.update.byId && task.byId !== task.champion?.id) {
        const part = { name: task.by, id: task.byId };
        this.notifyPartsTaskComment(part, task, coment);
        // console.log(part?.name, 'ByID comment notified');
      }
      if (task.participants) {
        // console.log(task.participants);
        task.participants.forEach((element) => {
          if (element.id !== coment.update.byId && element.id !== task.byId) {
            this.notifyPartsTaskComment(element, task, coment);
            // console.log(element?.name, 'Participants comment notified');
          }
        });
      }
    } else {
      // console.log('No comments found');
    }
  }

  delComment(task, coment) {
    // console.log(this.Comment);
    if (coment.name !== "") {
      const docRef = this.usd.afs.doc(`tasks/${task.id}/comments/${coment.id}`);
      docRef
        .delete()
        .catch((error) => {
          // console.log('Error capture', error)
        })
        .then((doc) => {
          this.taskCount(task);
          // console.log('comment deleted');
        });
    } else {
      // console.log('No comments found');
    }
  }

  // addSub(section, sub): {} {
  addSub(section, sub) {
    // console.log(section, sub);
    sub.companyId = section.companyId;
    sub.companyName = section.companyName;
    sub.projectId = this.usd.setProject.id;
    sub.projectName = this.usd.setProject.name;
    sub.sectionId = section.id;
    sub.sectionNo = section.no;
    sub.sectionName = section.name;
    // console.log(sub);
    const setRef = this.usd.afs.doc(
      `Enterprises/${section.companyId}/projects/${section.projectId}/sections/${section.id}`
    );
    const subRef = this.usd.afs.collection(
      `Enterprises/${section.companyId}/projects/${section.projectId}/subSections`
    );
    subRef
      .add(sub)
      .then((docRef) => {
        subRef.doc(docRef.id).update({ id: docRef.id });
        sub.id = docRef.id;
        // console.log('added');
        setRef
          .update({
            subSections: this.usd.firebase.firestore.FieldValue.arrayUnion(sub),
          })
          .then(() => {
            // console.log('Update successful, document exists');
            // update successful (document exists)
          })
          .catch((error) => {
            // console.log('Error updating user, document does not exists', error);
            // (document does not exists)
            setRef.set(section).then(() => {
              setRef.update({
                subSections:
                  this.usd.firebase.firestore.FieldValue.arrayUnion(sub),
              });
            });
          });
        // });
      })
      .catch((error) => {
        console.log(error);
      });
    return this.is.initSubsection;
  }

  deleteSubSection(section: any, sub: subSection) {
    // console.log(sub);
    // console.log(prj);
    section.subSections = section.subSections.filter(
      (doc) => doc.name !== sub.name
    );
    // console.log(section);
    section.Bills = [];
    const subRef = this.usd.afs.doc(
      `Enterprises/${sub.companyId}/projects/${sub.projectId}/subSections/${sub.id}`
    );
    subRef
      .delete()
      .then(() => {
        // console.log('Update successful, document exists');
      })
      .catch((error) => {
        console.log(error);
      });
    const setRef = this.usd.afs.doc(
      `Enterprises/${section.companyId}/projects/${section.projectId}/sections/${section.id}`
    );
    setRef
      .set(section)
      .then(() => {
        // console.log('Update successful, document exists');
        // update successful (document exists)
      })
      .catch((err) => {
        // console.log('Error updating user, document does not exists', err);
        // (document does not exists)
      });
  }

  editeSubSection(section: superSections, sub: subSection) {
    // console.log(sub);
    // console.log(prj);
    // console.log(section);
    section.Bills = [];
    this.ps?.sections.forEach((elem) => {
      const fndSec = elem.subSections.find((sec) => sec.id === sub.id);
      if (fndSec) {
        this.findTask(sub);
      }
      const subRef = this.usd.afs.doc(
        `Enterprises/${sub.companyId}/projects/${sub.projectId}/subSections/${sub.id}`
      );
      subRef
        .set(sub)
        .then(() => {
          // console.log('Update successful, document exists');
        })
        .catch((error) => {
          console.log(error);
        });
      const setRef = this.usd.afs.doc(
        `Enterprises/${section.companyId}/projects/${section.projectId}/sections/${section.id}`
      );
      setRef
        .set(section)
        .then(() => {
          // console.log('Update successful, document exists');
          // update successful (document exists)
        })
        .catch((err) => {
          // console.log('Error updating user, document does not exists', err);
          // (document does not exists)
        });
    });
    return this.is.initSubsection;
  }

  findTask(sec) {
    // console.log(sec);
    sec?.actions?.forEach((task) => {
      // console.log(task.description, task?.section?.name);
      task.section = sec;
      task.section.actions = [];
      this.secInfoTask(task);
    });
    this.ps.allPrjTasks.subscribe((tasks) => {
      const fndTasks = tasks.filter((tsk) => tsk?.section?.id == sec.id);
      if (fndTasks) {
        fndTasks.forEach((task) => {
          // console.log(task.description, task?.section?.name);
          task.section = sec;
          task.section.actions = [];
          this.updateTaskSec(task);
        });
      }
    });
  }

  /* costFramework */

  secsTaskAdd(task) {
    // console.log(task);
    task.section = {
      Bills: task.section.Bills,
      No: task.section.No,
      companyId: task.section.companyId,
      companyName: task.section.companyName,
      id: task.section.id,
      name: task.section.name,
      no: task.section.no,
      sectionNo: 3,
      type: "subSection",
      projectId: task.section.projectId,
      projectName: task.section.projectName,
      sectionId: task.section.sectionId,
      sectionName: task.section.sectionName,
    };
    if (task.section.type === "subSection") {
      this.usd.afs.collection(`Enterprises/${task.section.companyId}/projects/${task.section.projectId}/subSections/${task.section.id}/workItems`)
        .add(task)
        .then((tRef) => {
          task.id = tRef.id;
          const data_id = tRef.id;
          this.usd.afs.doc(`Enterprises/${task.section.companyId}/projects/${task.section.projectId}/subSections/${task.section.id}/workItems/${data_id}`).update({ id: data_id });
        })
        .catch((er) => {
          // console.log('doc not created', er);
        });
    } else if (task.section.type === "superSection") {
      this.usd.afs
        .collection(
          `Enterprises/${task.section.companyId}/projects/${task.section.projectId}/sections/${task.section.id}/workItems`
        )
        .add(task)
        .then((tRef) => {
          task.id = tRef.id;
          const data_id = tRef.id;
          this.usd.afs.doc(`Enterprises/${task.section.companyId}/projects/${task.section.projectId}/sections/${task.section.id}/workItems/${task.id}`).update({ id: task.id });
        })
        .catch((er) => {
          // console.log('doc not created', er);
        });
    } else {
    }
  }

  secsTaskEdit(task) {
    if (task.section.type === "subSection") {
      this.usd.afs
        .doc(
          `Enterprises/${task.section.companyId}/projects/${task.section.projectId}/subSections/${task.section.id}/workItems/${task.id}`
        )
        .update(task)
        .then(() => {
          console.log("sec task updated");
        })
        .catch((er) => {
          // console.log('doc not updated');
        });
    } else if (task.section.type === "superSection") {
      this.usd.afs
        .doc(
          `Enterprises/${task.section.companyId}/projects/${task.section.projectId}/sections/${task.section.id}/workItems/${task.id}`
        )
        .update(task)
        .then(() => {
          // console.log('sec task updated')
        })
        .catch((er) => {
          // console.log('doc not updated');
        });
    } else {
    }
  }

  secInfoTask(task) {
    if (task.section.type === "subSection") {
      this.usd.afs
        .doc(
          `Enterprises/${task.section.companyId}/projects/${task.section.projectId}/subSections/${task.section.id}/workItems/${task.id}`
        )
        .update({ section: task.section })
        .then(() => {
          console.log("sec task updated");
        })
        .catch((er) => {
          // console.log('doc not updated');
        });
    } else if (task.section.type === "superSection") {
      this.usd.afs
        .doc(
          `Enterprises/${task.section.companyId}/projects/${task.section.projectId}/sections/${task.section.id}/workItems/${task.id}`
        )
        .update({ section: task.section })
        .then(() => {
          // console.log('sec task updated')
        })
        .catch((er) => {
          // console.log('doc not updated');
        });
    } else {
    }
  }

  secsTaskDelete(task) {
    if (task.section.type === "subSection") {
      this.usd.afs
        .doc(
          `Enterprises/${task.section.companyId}/projects/${task.section.projectId}/subSections/${task.section.id}/workItems/${task.id}`
        )
        .delete()
        .then(() => {
          console.log("sec task deleted");
        })
        .catch((er) => {
          // console.log('doc not deleted');
        });
    } else if (task.section.type === "superSection") {
      this.usd.afs
        .doc(
          `Enterprises/${task.section.companyId}/projects/${task.section.projectId}/sections/${task.section.id}/workItems/${task.id}`
        )
        .delete()
        .then(() => {
          // console.log('sec task deleted')
        })
        .catch((er) => {
          // console.log('doc not deleted');
        });
    } else {
    }
  }

  savePlantReturns(asset) {
    const newPlant = asset;
    const prj = { name: this.usd.setProject.name, id: this.usd.setProject.id };
    // console.log(newPlant, prj);
    this.usd.afs
      .doc(
        `Projects/${prj.id}/enterprises/${this.usd.setProject.companyId}/plantReturns/${newPlant.id}`
      )
      .set(newPlant)
      .then(() => {
        this.usd.afs
          .doc(`Projects/${prj.id}/plantReturns/${newPlant.id}`)
          .set(newPlant)
          .then(() => console.log(`Project Plant updated`));
        if (newPlant.type === "owned") {
          this.usd.afs
            .doc(
              `Enterprises/${this.usd.setProject.companyId}/assets/${asset.id}`
            )
            .update({ project: prj })
            .then(() =>
              console.log(`${this.usd.setProject.companyName} updated`)
            );
        } else if (newPlant.type === "hired") {
          this.usd.afs
            .doc(
              `Enterprises/${this.usd.setProject.companyId}/hiredAssets/${asset.id}`
            )
            .update({ project: prj })
            .then(() =>
              console.log(`${this.usd.setProject.companyName} updated`)
            );
        }
        this.usd.afs
          .doc(`Equipment/${newPlant.id}`)
          .ref.get()
          .then((data) => {
            if (data.exists) {
              this.usd.afs
                .doc(
                  `Equipment/${newPlant.id}/projects/${this.usd.setProject.id}`
                )
                .ref.get()
                .then((data1) => {
                  // console.log('Tool project list updated');
                  if (data1.exists) {
                  } else {
                    this.usd.afs
                      .doc(
                        `Equipment/${newPlant.id}/projects/${this.usd.setProject.id}`
                      )
                      .set(prj)
                      .then(() => console.log("Tool project list updated"));
                  }
                })
                .catch((er) => {});
            } else {
              this.usd.afs
                .doc(`Equipment/${newPlant.id}`)
                .set(newPlant)
                .then(() => {
                  // console.log(`Plant added to Root Equipment`);
                  this.usd.afs
                    .doc(
                      `Equipment/${newPlant.id}/projects/${this.usd.setProject.id}`
                    )
                    .ref.get()
                    .then((data1) => {
                      // console.log('Tool project list updated');
                      if (data1.exists) {
                      } else {
                        this.usd.afs
                          .doc(
                            `Equipment/${newPlant.id}/projects/${this.usd.setProject.id}`
                          )
                          .set(prj)
                          .then(() => console.log("Tool project list updated"));
                      }
                    })
                    .catch((er) => {});
                });
            }
          });
      });
  }

  removePlantReturns(asset) {
    const prj = this.usd.setProject;
    this.usd.afs
      .doc(
        `Projects/${prj.id}/enterprises/${prj.companyId}/plantReturns/${asset.id}`
      )
      .delete()
      .catch((er) => console.log(`Failed to delete, ${er}`))
      .then(() => console.log(`${asset.name}, deleted`));
  }

  addRates(task, prj) {
    // console.log(task);
    if (task.name !== "") {
      if (task.id !== null || task.id !== undefined || task !== null) {
        task.createdOn = new Date().toISOString();
        task.by = this.usd.acc.name;
        task.byId = this.usd.acc?.id;
        task.companyName = prj.companyName;
        task.companyId = prj.companyId;
        task.project = { id: prj.id, name: prj.name };
        task.companyId = prj.companyId;
        const nRate = task;
        // console.log(nRate);
        const proComp = this.usd.afs
          .collection("Projects")
          .doc(prj.id)
          .collection("enterprises")
          .doc(task.companyId)
          .collection("Rates");
        const compPro = this.usd.afs
          .collection("Enterprises")
          .doc(task.companyId)
          .collection("Rates");
        compPro.add(task).then((data) => {
          const id = data.id;
          nRate.id = data.id;
          proComp
            .doc(id)
            .set(nRate)
            .then(() => console.log("proComp set"));
          compPro.doc(id).update({ id: id });
        });
      }
    } else {
      // type of work not created
    }
    return {
      name: "",
      id: "",
      unit: "",
      rate: "",
      by: "",
      byId: "",
      companyName: "",
      companyId: "",
      createdOn: "",
    };
  }

  updateRates(task, prj) {
    // console.log(task);
    if (task.name !== "") {
      if (task.id !== null || task.id !== undefined || task !== null) {
        task.weightDate = new Date().toISOString();
        this.usd.afs
          .doc(
            `Projects/${prj.id}/enterprises/${task.companyId}/Rates/${task.id}`
          )
          .update(task);
        this.usd.afs
          .doc(`Enterprises/${task.companyId}/Rates/${task.id}`)
          .update(task);
      }
    } else {
      // type of work not created
    }
    return {
      name: "",
      id: "",
      unit: "",
      rate: "",
      by: "",
      byId: "",
      companyName: "",
      companyId: "",
      createdOn: "",
    };
  }

  weighting(task, prj) {
    // console.log(task);
    if (task.name !== "") {
      if (task.id !== null || task.id !== undefined || task !== null) {
        task.weightDate = new Date().toISOString();
        this.usd.afs
          .doc(
            `Projects/${prj.id}/enterprises/${task.companyId}/Rates/${task.id}`
          )
          .update({ weighting: task.weighting });
      }
    } else {
      // type of work not created
    }
    return {
      name: "",
      id: "",
      unit: "",
      rate: "",
      by: "",
      byId: "",
      companyName: "",
      companyId: "",
      createdOn: "",
    };
  }

  saveweighting(arr, prj) {
    for (let index = 0; index < arr.length; index++) {
      const task = arr[index];
      if (task.name !== "") {
        if (task.id !== null || task.id !== undefined || task !== null) {
          task.weightDate = new Date().toISOString();
          this.usd.afs
            .doc(
              `Projects/${prj.id}/enterprises/${task.companyId}/Rates/${task.id}`
            )
            .update({ weighting: task.weighting });
        }
      } else {
        // type of work not created
      }
    }
  }

  cmpWeighting(team, prj) {
    // console.log('cmpWeighting', team);
    this.usd.afs
      .doc(`Projects/${prj.id}/enterprises/${prj.companyId}`)
      .update({ team: team })
      .then(() => console.log("team updated successfull"))
      .catch((er) => console.log("error updating", er));
  }

  removeRates(task, prj) {
    // console.log(task);
    const proComp = this.usd.afs
      .collection("Projects")
      .doc(prj.id)
      .collection("enterprises")
      .doc(task.companyId)
      .collection("Rates");
    const compPro = this.usd.afs
      .collection("Enterprises")
      .doc(task.companyId)
      .collection("Rates");
    compPro.doc(task.id).delete();
    proComp.doc(task.id).delete();
    return {
      name: "",
      id: "",
      unit: "",
      rate: "",
      by: "",
      byId: "",
      companyName: "",
      companyId: "",
      createdOn: "",
    };
  }

  saveDept(dpt) {
    // console.log(dpt);
    dpt.companyName = this.usd.setCompany.name;
    dpt.companyId = this.usd.setCompany.id;
    dpt.by = this.usd.acc.name;
    dpt.byId = this.usd.acc?.id;
    dpt.createdOn = new Date().toISOString();
    // console.log(dpt);
    const dptRef = this.usd.afs
      .collection<Enterprise>("Enterprises")
      .doc(dpt.companyId)
      .collection("departments");
    dptRef.add(dpt).then((Ref) => {
      console.log(Ref.id);
      const dptId = Ref.id;
      dpt.id = Ref.id;
      dptRef.doc(dptId).update({ id: dptId });
    });
    return {
      name: "",
      by: "",
      byId: "",
      companyName: "",
      companyId: "",
      createdOn: "",
      id: "",
      hod: null,
    };
  }

  editedDept(dpt) {
    // console.log(dpt);
    dpt.lastEditBy = this.usd.acc;
    dpt.editedOn = new Date().toISOString();
    // console.log(dpt);
    if (dpt.id) {
      const dptRef = this.usd.afs.doc(
        `Enterprises/${dpt.companyId}/departments/${dpt.id}`
      );
      dptRef.set(dpt).then(() => {
        // console.log('Dept updated');
        this.ns.showNotification("dpt_updated", "top", "right", dpt);
      });
    }

    return {
      name: "",
      by: "",
      byId: "",
      companyName: "",
      companyId: "",
      createdOn: "",
      id: "",
      hod: null,
    };
  }

  addDpart(dpt): Promise<any> {
    return Promise.resolve(
      (() => {
        // code here
        let any;
        dpt.by = this.usd.acc.name;
        dpt.byId = this.usd.acc?.id;
        dpt.createdOn = new Date().toISOString();
        // console.log(dpt);
        const dptRef = this.usd.afs
          .collection<Enterprise>("Enterprises")
          .doc(dpt.companyId)
          .collection("departments");
        const dfd = dptRef.add(dpt).then((Ref) => {
          console.log(Ref.id);
          const dptId = Ref.id;
          dpt.id = Ref.id;
          dptRef.doc(dptId).update({ id: dptId });
          any = dpt;
          return dpt;
        });
        // console.log(dfd);

        return any; // return whatever you want not neccessory
      })()
    );
  }

  addDept(dpt) {
    // console.log(dpt);
    dpt.by = this.usd.acc.name;
    dpt.byId = this.usd.acc?.id;
    dpt.createdOn = new Date().toISOString();
    // console.log(dpt);
    const dptRef = this.usd.afs
      .collection<Enterprise>("Enterprises")
      .doc(dpt.companyId)
      .collection("departments");
    dptRef.add(dpt).then((Ref) => {
      console.log(Ref.id);
      const dptId = Ref.id;
      dpt.id = Ref.id;
      dptRef.doc(dptId).update({ id: dptId });
    });
    return dpt;
    // return { name: '', by: '', byId: '', companyName: '', companyId: '', createdOn: '', id: '', hod: null }
  }

  removeDept(dpt) {
    // console.log(dpt);
    const dptRef = this.usd.afs
      .collection<Enterprise>("Enterprises")
      .doc(dpt.companyId)
      .collection("departments");
    dptRef.doc(dpt.id).delete();
    return {
      name: "",
      by: "",
      byId: "",
      companyName: "",
      companyId: "",
      createdOn: "",
      id: "",
      hod: null,
    };
  }

  deleteProCop(prj, ent) {
    // console.log(prj, ent);
    this.usd.afs
      .doc(`Enterprises/${ent.id}/projects/${prj.id}`)
      .delete()
      .then(() => {
        this.usd.afs
          .collection(`Projects/${prj.id}/enterprises/${prj.companyId}/labour`)
          .snapshotChanges()
          .pipe(
            map((b) =>
              b.map((a) => {
                const data = a.payload.doc.data() as any;
                data.id = a.payload.doc.id;
                return data;
              })
            )
          )
          .subscribe((staff) => {
            staff.forEach((element) => {
              this.usd.afs
                .doc(`Users/${element.id}/projects/${prj.id}`)
                .delete()
                .then(() => {
                  // console.log('deleted for', element.name);
                });
            });
          });
        return "done";
      });
  }

  deletePro(prj, ent) {
    // console.log(prj, ent);
    this.usd.afs
      .doc(`Enterprises/${ent.id}/projects/${prj.id}`)
      .delete()
      .then(() => {
        this.usd.afs
          .collection(`Projects/${prj.id}/enterprises/${prj.companyId}/labour`)
          .snapshotChanges()
          .pipe(
            map((b) =>
              b.map((a) => {
                const data = a.payload.doc.data() as any;
                data.id = a.payload.doc.id;
                return data;
              })
            )
          )
          .subscribe((staff) => {
            staff.forEach((element) => {
              this.usd.afs
                .doc(`Users/${element.id}/projects/${prj.id}`)
                .delete()
                .then(() => {
                  // console.log('deleted for', element.name);
                });
            });
          });
        this.usd.afs
          .collection(`Projects/${prj.id}/enterprises`)
          .snapshotChanges()
          .pipe(
            map((b) =>
              b.map((a) => {
                const data = a.payload.doc.data() as any;
                data.id = a.payload.doc.id;
                return data;
              })
            )
          )
          .subscribe((ents) => {
            ents.forEach((element) => {
              this.usd.afs
                .doc(`Enterprises/${element.id}/projects/${prj.id}`)
                .delete()
                .then(() => {
                  // console.log('deleted for', element.name);
                });
            });
          });
        return "done";
      })
      .then(() => {
        this.usd.afs.collection("Projects").doc(prj.id).delete();
      })
      .catch((er) => {
        console.log(er);
      });
  }

  deleteProAdmin(prj) {
    const ent = { id: prj.companyId };
    console.log("deleteProAdmin");
    this.usd.afs
      .doc(`Enterprises/${ent.id}/projects/${prj.id}`)
      .delete()
      .then(() => {
        this.usd.afs
          .collection(`Projects/${prj.id}/enterprises/${prj.companyId}/labour`)
          .snapshotChanges()
          .pipe(
            map((b) =>
              b.map((a) => {
                const data = a.payload.doc.data() as any;
                data.id = a.payload.doc.id;
                return data;
              })
            )
          )
          .subscribe((staff) => {
            staff.forEach((element) => {
              this.usd.afs
                .doc(`Users/${element.id}/projects/${prj.id}`)
                .delete()
                .then(() => {
                  // console.log('deleted for', element.name);
                })
                .catch((er) => console.log(er));
            });
          });
        this.usd.afs
          .collection(`Projects/${prj.id}/enterprises`)
          .snapshotChanges()
          .pipe(
            map((b) =>
              b.map((a) => {
                const data = a.payload.doc.data() as any;
                data.id = a.payload.doc.id;
                return data;
              })
            )
          )
          .subscribe((ents) => {
            ents.forEach((element) => {
              this.usd.afs
                .doc(`Enterprises/${element.id}/projects/${prj.id}`)
                .delete()
                .then(() => {
                  // console.log('deleted for', element.name);
                });
            });
          });
        this.is.toolSet === "Enterprise" ? this.es.checkProjects() : "";
        this.usd.getProjects();
        return "done";
      })
      .then(() => {
        this.usd.afs.collection("Projects").doc(prj.id).delete();
      })
      .catch((er) => {
        console.log(er);
      });
  }

  addPersonalStandards(task) {
    task.createdOn = new Date().toString();
    // console.log(task);
    const data = task;
    data.champion = this.usd.acc;
    const standardRef = this.usd.afs
      .collection("Users")
      .doc(data.champion.id)
      .collection("myStandards");
    standardRef.add(data).then((ref) => {
      const id = ref.id;
      standardRef
        .doc(id)
        .update({ id: id })
        .then(() => {
          // this.usd.getMyTasks();; console.log('standard added');
        });
    });
    return {
      name: "",
      createdOn: "",
      id: "",
      period: "",
      classificationName: "",
      classificationId: "",
      unit: "",
      quarter1: "",
      quarter2: "",
      quarter3: "",
      quarter4: "",
      yearlyDate: "",
      monthlyDate: "",
    };
  }

  removePsStandards(task) {
    // console.log(task);
    this.usd.afs
      .doc(`Users/${this.usd.acc?.id}/myStandards/${task.id}`)
      .delete()
      .then(() => {});
    return this.is.getTask();
  }

  addClass(classn: any) {
    // console.log(classn);
    classn.createdOn = new Date().toISOString();
    // this.pns.addClassifications(this.userId, classn);
    const setClass = this.usd.afs
      .collection("Users")
      .doc(this.usd.acc?.id)
      .collection("classifications");
    setClass.add(classn).then((ref) => {
      const id = ref.id;
      // this.ns.showNotification('Classification', 'top', 'right', classn);
      // this.ns.showNotification('timeBudget', 'top', 'right', '');
      setClass
        .doc(id)
        .update({ id: id })
        .then(() => {});
    });
    return {
      name: "",
      createdOn: "",
      plannedTime: "",
      actualTime: "",
      Varience: "",
      id: "",
    };
  }

  addTaskClass(classn: any) {
    // console.log(classn);
    classn.createdOn = new Date().toISOString();
    const index = this.usd.classifications.findIndex(
      (workClass) => workClass.name === classn.name
    );
    if (index < 0) {
      const setClass = this.usd.afs
        .collection("Users")
        .doc(this.usd.acc?.id)
        .collection("classifications");
      setClass.add(classn).then((ref) => {
        const id = ref.id;
        classn.id = ref.id;
        setClass
          .doc(id)
          .update({ id: id })
          .then(() => {});
      });
      return classn;
    }
  }

  removeClass(data) {
    const classRef = this.usd.afs
      .collection("Users")
      .doc(this.usd.acc?.id)
      .collection("classifications")
      .doc(data.id);
    classRef.delete();
  }

  saveAsset(newAsset) {
    // console.log(newAsset);
    newAsset.companyName = this.usd.setCompany.name;
    newAsset.companyId = this.usd.setCompany.id;
    newAsset.by = this.usd.acc.name;
    newAsset.byId = this.usd.acc?.id;
    newAsset.createdOn = new Date().toISOString();
    newAsset.type = "owned";
    this.usd.afs
      .collection(`Enterprises/${this.usd.setCompany.id}/assets`)
      .add(newAsset)
      .then((df) => {
        const id = df.id;
        newAsset.id = df.id;
        this.usd.afs
          .doc(`Enterprises/${this.usd.setCompany.id}/assets/${id}`)
          .update({ id: id })
          .then(() => console.log("Added", newAsset.name));
        this.usd.afs
          .doc(`Equipment/${id}`)
          .set(newAsset)
          .then(() => console.log("Equipment Saved", newAsset.name));
      });
    return {
      name: "",
      assetNumber: "",
      by: "",
      byId: "",
      companyName: "",
      companyId: "",
      createdOn: "",
      cost: "",
    };
  }

  quickAssetAdd(newAsset, task) {
    // console.log(newAsset);
    const prj = {
      name: task.projectName,
      id: task.projectId,
    };
    newAsset.companyName = task.companyName;
    newAsset.companyId = task.companyId;
    newAsset.by = this.usd.acc.name;
    newAsset.byId = this.usd.acc?.id;
    newAsset.createdOn = new Date().toISOString();
    newAsset.type = "owned";
    this.usd.afs
      .collection(`Enterprises/${task.companyId}/assets`)
      .add(newAsset)
      .then((df) => {
        const id = df.id;
        newAsset.id = df.id;
        this.usd.afs
          .doc(`Enterprises/${task.companyId}/assets/${id}`)
          .update({ id: id })
          .then(() => console.log("Added", newAsset.name));
        this.usd.afs
          .doc(`Equipment/${id}`)
          .set(newAsset)
          .then(() => console.log("Equipment Saved", newAsset.name));
        if (task.projectId && task.projectId !== "") {
          this.usd.afs
            .doc(
              `Projects/${task.projectId}/enterprises/${task.companyId}/plantReturns/${newAsset.id}`
            )
            .set(newAsset);
          this.usd.afs
            .doc(`Equipment/${newAsset.id}/projects/${task.projectId}`)
            .update(prj)
            .then(() => console.log("Tool project list updated"));
        }
      });
    return {
      name: "",
      assetNumber: "",
      by: "",
      byId: "",
      companyName: "",
      companyId: "",
      createdOn: "",
      cost: "",
    };
  }

  quickHiredAssetAdd(newAsset, task) {
    // console.log(newAsset);
    const prj = {
      name: task.projectName,
      id: task.projectId,
    };
    newAsset.companyName = task.companyName;
    newAsset.companyId = task.companyId;
    newAsset.by = this.usd.acc.name;
    newAsset.byId = this.usd.acc?.id;
    newAsset.createdOn = new Date().toISOString();
    newAsset.type = "hired";
    this.usd.afs
      .collection(`Enterprises/${task.companyId}/hiredAssets`)
      .add(newAsset)
      .then((df) => {
        const id = df.id;
        newAsset.id = df.id;
        this.usd.afs
          .doc(`Enterprises/${task.companyId}/hiredAssets/${id}`)
          .update({ id: id })
          .then(() => console.log("Added", newAsset.name));
        this.usd.afs
          .doc(`Equipment/${id}`)
          .set(newAsset)
          .then(() => console.log("Equipment Saved", newAsset.name));
        if (task.projectId && task.projectId !== "") {
          this.usd.afs
            .doc(
              `Projects/${task.projectId}/enterprises/${task.companyId}/plantReturns/${newAsset.id}`
            )
            .set(newAsset);
          this.usd.afs
            .doc(`Equipment/${newAsset.id}/projects/${task.projectId}`)
            .update(prj)
            .then(() => console.log("Tool project list updated"));
        }
      });
    return {
      name: "",
      assetNumber: "",
      by: "",
      byId: "",
      companyName: "",
      companyId: "",
      createdOn: "",
      cost: "",
    };
  }

  saveHiredAsset(newAsset) {
    // console.log(newAsset);
    newAsset.companyName = this.usd.setCompany.name;
    newAsset.companyId = this.usd.setCompany.id;
    newAsset.by = this.usd.acc.name;
    newAsset.byId = this.usd.acc?.id;
    newAsset.createdOn = new Date().toISOString();
    newAsset.type = "hired";
    this.usd.afs
      .collection(`Enterprises/${newAsset.companyId}/hiredAssets`)
      .add(newAsset)
      .then((df) => {
        const id = df.id;
        newAsset.id = df.id;
        this.usd.afs
          .doc(`Enterprises/${this.usd.setCompany.id}/hiredAssets/${id}`)
          .update({ id: id })
          .then(() => console.log("Added", newAsset.name));
        this.usd.afs
          .doc(`Equipment/${id}`)
          .set(newAsset)
          .then(() => console.log("Equipment Saved", newAsset.name));
      });
    return {
      name: "",
      assetNumber: "",
      by: "",
      byId: "",
      companyName: "",
      companyId: "",
      createdOn: "",
      cost: "",
    };
  }

  removeAsset(newAsset) {
    // console.log(newAsset);
    this.usd.afs
      .doc(`Enterprises/${newAsset.companyId}/assets/${newAsset.id}`)
      .delete()
      .then(() => {
        // console.log(`Asset ${newAsset.name}, ${newAsset.assetNumber}, hase been deleted`);
      })
      .catch((er) => {
        // console.log(`Asset ${newAsset.name}, ${newAsset.assetNumber}, deletion failed`, er);
      });
    return {
      name: "",
      assetNumber: "",
      by: "",
      byId: "",
      companyName: "",
      companyId: "",
      createdOn: "",
      cost: "",
    };
  }

  saveEditedAsset(asset) {
    // console.log(asset);
    this.usd.afs
      .doc(`Enterprises/${asset.companyId}/assets/${asset.id}`)
      .update(asset)
      .then(() => {
        // console.log('Asset updated');
        this.ns.showNotification("asset_updated", "top", "right", asset);
      })
      .catch((er) => {
        // console.log('Error Updating Asset', er);
        this.ns.showNotification("asset_updated_er", "top", "right", asset);
      });
    return {
      name: "",
      assetNumber: "",
      by: "",
      byId: "",
      companyName: "",
      companyId: "",
      createdOn: "",
      cost: "",
    };
  }

  saveEditedHrdAsset(asset) {
    // console.log(asset);
    this.usd.afs
      .doc(`Enterprises/${asset.companyId}/hiredAssets/${asset.id}`)
      .update(asset)
      .then(() => {
        // console.log('Asset updated');
        this.ns.showNotification("asset_updated", "top", "right", asset);
      })
      .catch((er) => {
        // console.log('Error Updating Asset', er);
        this.ns.showNotification("asset_updated_er", "top", "right", asset);
      });
    return {
      name: "",
      assetNumber: "",
      by: "",
      byId: "",
      companyName: "",
      companyId: "",
      createdOn: "",
      cost: "",
    };
  }

  removeHiredAsset(newAsset) {
    // console.log(newAsset);
    this.usd.afs
      .doc(`Enterprises/${newAsset.companyId}/hiredAssets/${newAsset.id}`)
      .delete();
    return {
      name: "",
      assetNumber: "",
      by: "",
      byId: "",
      companyName: "",
      companyId: "",
      createdOn: "",
      cost: "",
    };
  }

  addEntStandards(task) {
    // console.log(task);
    if (task.name !== "") {
      if (task.id !== null || task.id !== undefined || task !== null) {
        task.createdOn = new Date().toISOString(); task.by = this.usd.acc.name; task.byId = this.usd.acc?.id;
        const nRate = task;
        // console.log(nRate);
        const compPro = this.usd.afs.collection(`Enterprises/${task.company.id}/Rates`);
        compPro.add(task).then((data) => {
          const id = data.id;
          nRate.id = data.id;
          compPro.doc(id).update({ id: id });
        });
      }
    } else {
      // type of work not created
    }
    return this.is.getTask();
  }

  updateEntStandards(task) {
    // console.log(task);
    this.usd.afs
      .doc(`Enterprises/${task.companyId}/Rates/${task.id}`)
      .update(task)
      .then(() => {
        // console.log('rate updated');
        this.ns.showNotification("std_updated", "top", "right", task);
      })
      .catch((er) => {
        // console.log(`Err didnt update, ${er}`);
      });
    return this.is.getTask();
  }

  removeentStds(task) {
    this.usd.afs
      .doc(`Enterprises/${task.companyId}/Rates/${task.id}`)
      .delete()
      .then(() => console.log("Doc removed", task.name));
    return this.is.getTask();
  }

  addTimeBudget(item) {
    const time = item.plannedTime;
    // let data = this.selectedClassification
    this.usd.afs
      .collection("Users")
      .doc(this.usd.acc?.id)
      .collection("classifications")
      .doc(item.id)
      .update({ plannedTime: time })
      .then(() => {
        // console.log('Doc updated')
      })
      .catch((er) => console.log(er));
    this.ns.showNotification("timeBudget", "top", "right", "");
  }

  acceptTask(taskSent) {
    this.usd.afs
      .doc(`Users/${this.usd.acc?.id}/allNotice/${taskSent.id}`)
      .update({ read: true })
      .then(() => {
        this.ns.showNotification("acceptTask", "top", "right", taskSent);
        this.usd.totalNotices = this.usd.totalNotices?.filter(
          (task) => task.id !== taskSent.id
        );
      });
    return this.is.getTask();
  }

  acknowlTsk(taskSent) {
    this.usd.afs
      .doc(`Users/${this.usd.acc?.id}/allNotice/${taskSent.dataId}`)
      .update({ read: true })
      .then(() => {
        this.ns.showNotification("acceptTask", "top", "right", taskSent);
        this.usd.totalNotices = this.usd.totalNotices?.filter(
          (task) => task.id !== taskSent.id
        );
      });
    return this.is.getTask();
  }

  acknowlAct(taskSent) {
    this.usd.afs
      .doc(`Users/${this.usd.acc?.id}/allNotice/${taskSent.dataId}`)
      .update({ read: true })
      .then(() => {
        this.ns.showNotification("acceptTask", "top", "right", taskSent);
        this.usd.totalNotices = this.usd.totalNotices?.filter(
          (task) => task.id !== taskSent.id
        );
      });
    return this.is.getTask();
  }

  userjoinProject(project) {
    // console.log(project);
    try {
      const projectId = project.id;
      const pUser = {
        name: this.usd.acc.name,
        email: this.usd.acc.email,
        id: this.usd.acc.uid || this.usd.acc.id,
        phoneNumber: this.usd.acc?.phoneNumber || "",
        projectId: project.id,
        projectName: project.name,
        companyId: this.usd.setCompany.id,
        companyName: this.usd.setCompany.name,
        hierarchy: this.es.myCompProfile?.hierarchy,
        nationality: this.es.myCompProfile?.nationality || "",
        nationalId: this.es.myCompProfile?.nationalId || "",
        address: this.es.myCompProfile?.address || "",
      };
      const projectsRef = this.usd.afs.doc(`Projects/${projectId}`);
      const companysRef = this.usd.afs.doc(
        `Enterprises/${this.usd.setCompany.id}/projects/${projectId}`
      );
      const myprojectRef = this.usd.afs.doc(
        `Users/${this.usd.userId}/projects/${projectId}`
      );
      console.log(pUser);
      console.log(project);
      // point to project doc
      myprojectRef
        .set(project)
        .then(() => {
          this.is.toolSet === "Enterprise" ? this.es.checkProjects() : "";
          this.is.toolSet === "Enterprise" && this.es.results?.length > 0
            ? (this.es.results.find((x) => x.id === project.id).join = true)
            : "";
          this.usd.getProjects();
          // console.log('Project added')
        })
        .catch((er) => {
          console.log("err found", er);
        });
      if (projectId && this.usd.setCompany.id && pUser.id) {
        const userPath = `Projects/${projectId}/enterprises/${this.usd.setCompany.id}/labour/${pUser.id}`;
        pUser?.hierarchy
          ? this.usd.afs
              .doc(userPath)
              .set(pUser)
              .then(() => {
                // console.log(pUser);
              })
              .catch((error) => {
                // console.log(error)
              })
          : "";
        pUser?.hierarchy
          ? projectsRef
              .collection("Participants")
              .doc(this.usd.userId)
              .set(pUser)
              .then(() => {
                console.log("added to Participants");
              })
              .catch((er) => {
                console.log("err found", er);
              })
          : "";
        pUser?.hierarchy
          ? companysRef
              .collection("labour")
              .doc(this.usd.setCompany.id)
              .set(pUser)
              .then(() => {
                console.log("added to labour");
              })
              .catch((er) => {
                console.log("err found", er);
              })
          : "";
      }
    } catch (error) {
      console.log(error);
    }
  }

  sendRequest(projectToJoin, ent, joining) {
    // const companyId = ent.id;
    // console.log(companyId);
    // console.log(ent);
    // console.log('ById', projectToJoin.byId);
    console.log("projectToJoin", projectToJoin);
    const project = {
      name: projectToJoin.name,
      id: projectToJoin.id,
      location: projectToJoin.location,
      sector: projectToJoin.sector,
      type: projectToJoin.type,
      companyName: projectToJoin.companyName,
      companyId: projectToJoin.companyId,
    };
    const champId = projectToJoin.champion.id;
    const prjchamp = projectToJoin.champion;
    const champById = projectToJoin.byId;
    const by = { id: projectToJoin.byId, name: projectToJoin.by };
    // let partId = this.usd.acc?.id;
    const projectId = projectToJoin.id;
    let champion: any = ent.champion;
    champion.parts = [];
    // champion.project = project;
    champion.project = {
      name: project.name,
      id: project.id,
      sector: project.sector,
    };
    // champion.company = ent;
    champion.company = {
      name: ent.name,
      id: ent.id,
      roles: ent.roles,
      sector: ent.sector,
      telephone: ent.telephone,
    };
    let me: any = this.usd.acc;
    if (champion.id === me.id) {
      me.parts = [prjchamp];
    } else if (prjchamp.id && prjchamp.id !== me.id) {
      champion.parts = [prjchamp, by];
    } else {
      me.parts = [by];
    }
    // me.project = project;
    me.project = { name: project.name, id: project.id, sector: project.sector };
    me.company = {
      name: ent.name,
      id: ent.id,
      roles: ent.roles,
      sector: ent.sector,
      telephone: ent.telephone,
    };
    me.read = false;
    me.noticeDate = moment().toISOString();
    me.noti = "ProjectRequests";
    champion.noti = "ProjectRequests";
    champion.read = false;
    champion.noticeDate = moment().toISOString();
    const champion2dataId =
      (champId || champById) + moment().format("DDDDYYYY");
    me.dataId = champion2dataId;
    champion.dataId = champion2dataId;
    console.log(me, champion);
    this.saveRequest(
      champion,
      prjchamp,
      champion2dataId,
      champById,
      me,
      projectId
    );
    // this.resetForm();
    return !joining;
  }

  saveRequest(champion, prjchamp, champion2dataId, champById, me, projectId) {
    champion ??
      this.usd.afs
        .collection("Users")
        .doc(prjchamp.id)
        .collection("allNotice")
        .doc(champion2dataId)
        .set(champion);
    this.usd.afs
      .collection("Users")
      .doc(champById)
      .collection("allNotice")
      .doc(champion2dataId)
      .set(me);
    this.usd.afs
      .collection("Projects")
      .doc(projectId)
      .collection("allNotice")
      .doc(champion2dataId)
      .set(me);
    this.sendCmpPrjReq(me);
    this.ns.showNotification("inviteCompany", "top", "right", "");
  }

  joinProject(joinmyProject, company) {
    const project = joinmyProject;
    // console.log(project);
    const projectId = project.id;
    // console.log(projectId);
    const pUser = {
      name: this.usd.acc.name,
      email: this.usd.acc.email,
      id: this.usd.acc?.id,
      phoneNumber: this.usd.acc.phoneNumber,
      projectId: project.id,
      projectName: project.name,
      companyId: company.id,
      companyName: company.name,
      hierarchy: "",
      nationality: this.usd.acc.nationality,
      nationalId: this.usd.acc.nationalId,
      address: this.usd.acc.address,
    };

    const projectsRef = this.usd.afs.collection("Projects").doc(projectId);
    const companysRef = this.usd.afs
      .collection("Enterprises")
      .doc(company.id)
      .collection("projects")
      .doc(project.id);
    const allMyProjectsRef = this.usd.afs
      .collection("Users")
      .doc(this.usd.acc?.id)
      .collection("projects")
      .doc(projectId);
    // point to project doc
    try {
      allMyProjectsRef.set(project); // set the project

      const setCompany = projectsRef.collection("enterprises").doc(company.id);
      setCompany.collection("labour").doc(this.usd.acc?.id).set(pUser);
      projectsRef.collection("Participants").doc(this.usd.acc?.id).set(pUser);
      companysRef
        .collection("labour")
        .doc(this.usd.acc?.id)
        .set(pUser)
        .then(() => {
          this.ns.showNotification("Join_Project", "top", "right", project);
        });
    } catch (error) {
      console.log(error);
    }
  }

  declineCompProjectRequest(enterpriseInvited) {
    const scompanyId = enterpriseInvited.company.id;
    const champId = this.usd.userId;
    const dataId = enterpriseInvited.dataId;
    // console.log(dataId);
    this.usd.afs
      .collection("Users")
      .doc(champId)
      .collection("ProjectRequests")
      .doc(dataId)
      .update({ read: true });
    this.usd.afs
      .collection("Enterprises")
      .doc(scompanyId)
      .collection("ProjectRequests")
      .doc(dataId)
      .update({ read: true })
      .then(() => {
        this.usd.totalNotices = this.usd?.totalNotices.filter(
          (x) => x.dataId !== dataId
        );
        // console.log('Deleted');
        // this.dataCall(this.usd.userId);
      });
  }

  acceptProjectRequest(requestingCompany, enterpriseInvited, roles) {
    // enterpriseInvited
    const scompanyId = enterpriseInvited.company.id;
    const projectId = enterpriseInvited.project.id;
    const man = {
      email: enterpriseInvited.email,
      id: enterpriseInvited.id,
      name: enterpriseInvited.name,
      phoneNumber: enterpriseInvited.phoneNumber,
      dataId: enterpriseInvited.dataId,
    };
    enterpriseInvited.acceptor = this.usd.acc;
    // let man = enterpriseInvited;
    const croles = roles;
    let company;
    let project;
    // let champId = this.usd.userId;
    company = enterpriseInvited.company;
    const dataId = enterpriseInvited.dataId;
    company.roles = croles;
    company.champion = requestingCompany.champion;
    project = enterpriseInvited.project;
    // user = man;
    // const mailData = {name: man.name, email: man.bus_email || man.email, user: man, acceptor: this.usd.acc, company: company, project: project }
    const mailData = {
      by: {
        id: this.usd?.acc?.id,
        name: this.usd?.acc?.name,
        email: this.usd?.acc?.bus_email || this.usd?.acc.email,
        photoUrl: this.usd?.acc?.photoURL || "",
      },
      company: company,
      project: project,
      user: man,
      acceptor: this.usd.acc,
      name: man.name,
      email: man.email,
      subjet: "Project request Accepted",
      text: "Project request Accepted",
    };
    this.sendCmpPrjReqAcc(mailData);
    // console.log(projectId)
    project.companyName = enterpriseInvited.company.name;
    project.companyId = enterpriseInvited.company.id;

    const projectsRef = this.usd.afs.collection("Projects");
    const companysRef = this.usd.afs.collection("Enterprises");
    companysRef
      .doc(scompanyId)
      .collection("projects")
      .doc(projectId)
      .set(project);
    const allMyProjectsRef = this.usd.afs.doc(
      `Users/${this.usd.userId}/projects/${projectId}`
    );
    // point to project doc
    allMyProjectsRef.set(project); // set the project
    const setCompany = projectsRef
      .doc(projectId)
      .collection("enterprises")
      .doc(scompanyId);
    setCompany.set(company);
    setCompany.collection("labour").doc(man.id).set(man);
    projectsRef.doc(projectId).collection("Participants").doc(man.id).set(man);
    companysRef
      .doc(scompanyId)
      .collection("projects")
      .doc(projectId)
      .collection("labour")
      .doc(man.id)
      .set(man);
    this.usd.afs
      .doc(`Users/${this.usd.userId}/allNotice/${dataId}`)
      .update({ read: true })
      .then(() => {
        this.usd.totalNotices = this.usd?.totalNotices.filter(
          (x) => x.dataId !== dataId
        );
      });
    this.usd.afs
      .doc(`Enterprises/${scompanyId}/allNotice/${dataId}`)
      .update({ read: true });
    enterpriseInvited.noti = "ProjectReqAccepted";
    this.usd.afs
      .doc(`Users/${man.id}/allNotice/${dataId}`)
      .set(enterpriseInvited);
  }

  ackCmpAppPrj(app) {
    this.usd.afs
      .doc(`Users/${app.id}/allNotice/${app.dataId}`)
      .update({ read: true });
  }

  acceptProjectInvitation(coloursReq) {
    console.log(coloursReq);
    const scompanyId = coloursReq.company.id;
    const projectId = coloursReq.project.id;
    const man = {
      email: coloursReq.email,
      id: coloursReq.id,
      name: coloursReq.name,
      phoneNumber: coloursReq.phoneNumber,
    };
    // let man = coloursReq;
    // console.log(coloursReq.roles);
    // let croles = coloursReq.roles;
    let company;
    let project;
    // const champId = this.usd.userId;
    company = coloursReq.company;
    const dataId = coloursReq.dataId;
    // company.roles = croles;
    company.champion = man;
    project = coloursReq.project;
    // user = man;
    // console.log(projectId)
    project.companyName = coloursReq.company.name;
    project.companyId = coloursReq.company.id;
    if (coloursReq.parts?.length > 1) {
      let fndParts = coloursReq.parts.filter((x) => x.id !== coloursReq.id);
      for (let c = 0; c < fndParts.length; c++) {
        const element = fndParts[c];
        this.usd.afs
          .doc(`Users/${element?.id}/allNotice/${dataId}`)
          .update({ read: true })
          .then(() => console.log("part", element.name, "notice cleared"))
          .catch((er) => console.log(er));
      }
      // let fndPart = coloursReq.parts.find(x => x.id !== coloursReq.id);
      // fndPart ?? this.usd.afs.doc(`Users/${fndPart?.id}/allNotice/${dataId}`).update({ 'read': true }).then(() => console.log('part', fndPart.name, 'notice cleared')).catch(er => console.log(er));
    }
    console.log(project);
    const projectsRef = this.usd.afs.collection("Projects");
    const companysRef = this.usd.afs.collection("Enterprises");
    companysRef
      .doc(scompanyId)
      .collection("projects")
      .doc(projectId)
      .set(project);
    const allMyProjectsRef = this.usd.afs
      .collection("Users")
      .doc(man.id)
      .collection<Project>("projects")
      .doc(projectId);
    // point to project doc
    allMyProjectsRef.set(project); // set the project
    const setCompany = projectsRef
      .doc(projectId)
      .collection("enterprises")
      .doc(scompanyId);
    setCompany.set(company);
    setCompany.collection("labour").doc(man.id).set(man);
    projectsRef.doc(projectId).collection("Participants").doc(man.id).set(man);
    companysRef
      .doc(scompanyId)
      .collection("projects")
      .doc(projectId)
      .collection("labour")
      .doc(man.id)
      .set(man);
    // delete from invitation request
    // console.log(dataId);
    this.usd.afs
      .doc(`Projects/${projectId}/allNotice/${dataId}`)
      .update({ read: true })
      .then(() => {})
      .catch((e) => console.log(e));
    this.usd.afs
      .doc(`Users/${this.usd.userId}/allNotice/${dataId}`)
      .update({ read: true })
      .then(() => {
        this.usd.totalNotices = this.usd?.totalNotices.filter(
          (x) => x.dataId !== dataId
        );
      })
      .catch((e) => console.log(e));
  }

  declineProjectRequest(coloursReq) {
    const scompanyId = coloursReq.company.id;
    const dataId = coloursReq.dataId;
    const projectId = coloursReq.project.id;
    // console.log(dataId);
    const man = {
      email: coloursReq.email,
      id: coloursReq.id,
      name: coloursReq.name,
      phoneNumber: coloursReq.phoneNumber,
    };
    // const mailData = {
    //   user: man,
    //   decliner: this.usd.acc,
    //   company: coloursReq.company,
    //   project: coloursReq.project
    // }
    const mailData = {
      by: {
        id: this.usd?.acc?.id,
        name: this.usd?.acc?.name,
        email: this.usd?.acc?.bus_email || this.usd?.acc.email,
        photoUrl: this.usd?.acc?.photoURL || "",
      },
      decliner: this.usd.acc,
      company: coloursReq.company,
      project: coloursReq.project,
      user: man,
      name: man.name,
      email: man.email,
      subjet: "Declined Project Request",
      text: "decline Project Request",
    };
    // ProjectRequests
    this.usd.afs
      .doc(`Users/${this.usd.userId}/allNotice/${dataId}`)
      .update({ read: true })
      .then(() => {
        this.usd.totalNotices = this.usd?.totalNotices.filter(
          (x) => x.dataId !== dataId
        );
        this.sendCmpPrjReqRej(mailData);
        this.usd.afs
          .doc(`Projects/${projectId}/allNotice/${dataId}`)
          .update({ read: true });
        // console.log('Deleted');
        // this.dataCall(this.usd.userId);
      })
      .catch((e) => {
        const date = dataId.substr(dataId.length - 7);
        const newDataId = this.usd.userId + date;
        // console.log(newDataId, 'date', date, 'userId', this.usd.userId);
        this.usd.afs
          .doc(`Users/${this.usd.userId}/allNotice/${newDataId}`)
          .update({ read: true })
          .then(() => {
            // console.log(newDataId, ' worked')
            this.usd.afs
              .doc(`Projects/${projectId}/allNotice/${dataId}`)
              .update({ read: true });
          })
          .catch((er) => console.log("newDataId failed", er));
      });
  }

  requestEnt(department, selectedCompany, joining) {
    const companyId = selectedCompany.id;
    // console.log(companyId);
    // console.log(selectedCompany);
    let partId = this.usd.acc?.id;
    // console.log(companyId, selectedCompany.champion);
    const newEnterprise = selectedCompany;
    const me: any = {
      name: this.usd.champ.name || "",
      id: this.usd.champ.id,
      address: this.usd.champ.address || "",
      phoneNumber: this.usd.champ.phoneNumber || "",
      bus_email: this.usd.champ.bus_email || "",
      nationality: this.usd.champ.nationality || "",
      nationalId: this.usd.champ.nationalId || "",
      company: selectedCompany,
      department: department || "",
      photoURL: this.usd.champ.photoURL || "",
    };
    me.read = false;
    me.noticeDate = moment().toISOString();
    try {
      let doc;
      if (me.bus_email) {
        doc = { name: me.name, id: me.id, email: me.bus_email || me.email };
      }
      const mailData = {
        by: {
          id: this.usd.acc?.id,
          name: this.usd.acc?.name,
          email: this.usd.acc?.bus_email || this.usd.acc.email,
          photoUrl: this.usd.acc?.photoURL || "",
        },
        name: me.name,
        email: me?.bus_email || me.email,
        subjet: "Notice",
        text: "Notice",
      };
      // this.commonMail(doc);
      this.commonMail(mailData);
      // this.usd.afs.collection('Users').doc(this.usd.acc?.id).update(this.usd.acc);
      this.usd.afs
        .doc(`Users/${partId}/enterprisesRequested/${companyId}`)
        .set(newEnterprise)
        .then(() => {
          me.department = department;
          me.company = newEnterprise;
          me.noti = "EnterprisesRequests";
          // console.log(me, 'check participants array,if updated' + selectedCompany.participants);
          this.usd.afs
            .doc(`Users/${partId}/enterprisesRequested/${companyId}`)
            .set(newEnterprise);
          this.usd.afs
            .doc(`Enterprises/${companyId}/Requests/${partId}`)
            .set(me);
          // this.usd.afs.doc(`Users/${this.usd.userId}/allNotice/${partId}`).set(me);
          this.usd.afs
            .doc(`Users/${newEnterprise.champion.id}/allNotice/${partId}`)
            .set(me);
        })
        .then(() => {
          const mailData = {
            by: {
              id: this.usd.acc?.id,
              name: this.usd.acc?.name,
              email: this.usd.acc?.bus_email || this.usd.acc.email,
              photoUrl: this.usd.acc?.photoURL || "",
            },
            name: newEnterprise.champion.name,
            email:
              newEnterprise.champion?.bus_email || newEnterprise.champion.email,
            subjet: "Notice",
            text: "Notice",
          };
          // this.commonMail(newEnterprise.champion);
          this.commonMail(mailData);
          this.usd.updateAccount();
          this.ns.showNotification(
            "req_Company",
            "top",
            "right",
            selectedCompany
          );
        });
    } catch (error) {
      console.log(error);
    }
    return !joining;
  }

  requestClientManagers(selectedCompany: any) {
    // const companyId = selectedCompany.id;
    // const selectedCompany = this.usd.setClient;
    // console.log(selectedCompany);
    const dataId = this.usd.acc?.id + moment().format("DDDDYYYY");
    // console.log(companyId);
    const newEnterprise = selectedCompany;
    const me: any = {
      name: this.usd.acc.name || "", id: this.usd.acc.id, address: this.usd.acc.address || "",
      phoneNumber: this.usd.acc.phoneNumber || "", bus_email: this.usd.acc.bus_email || "",
      nationality: this.usd.acc.nationality || "", nationalId: this.usd.acc.nationalId || "",
      company: selectedCompany, photoURL: this.usd.acc.photoURL || "", dataId: dataId,
    };
    me.read = false;
    me.noticeDate = moment().toISOString();
    // console.log(me);
    try {
      me.company = newEnterprise;
      me.reqCompany = this.usd.setCompany;
      // console.log(me);
      this.usd.afs.collection<any>(`Enterprises/${newEnterprise.id}/Participants/`,(ref) => ref.where("hierarchy", "==", "Executive").limit(3)).valueChanges().subscribe((exe) => {
          // console.log(exe);
          // console.log(exe.length);
        me.admin = exe;
        if (exe.length > 0) {
          this.usd.afs.collection<any>(`Enterprises/${newEnterprise.id}/Participants/`,(ref) => ref.where("hierarchy", "==", "Middle management").limit(3)).valueChanges().subscribe((ads) => {
            // console.log(ads);
            // console.log(ads.length);
            if (ads.length > 0) {
              me.admin.concat(ads);
              this.sendManReq(me, newEnterprise);
            } else {
              this.sendManReq(me, newEnterprise);
            }
          });
        } else {
          this.usd.afs.collection<any>(`Enterprises/${newEnterprise.id}/Participants/`, (ref) => ref.where("hierarchy", "==", "Middle management").limit(3)).valueChanges().subscribe((ads) => {
              // console.log(ads);
            // console.log(ads.length);
            me.noti = "ManagersRequests";
            if (ads.length > 0) {
              me.admin = ads;
              this.sendManReq(me, newEnterprise);
            } else {
              this.usd.afs.doc(`Users/${newEnterprise.byId}/allNotice/${me.dataId}`).set(me).then(() => {
                this.ns.showNotification("req_Company", "top", "right", newEnterprise);
              }).catch((er) => console.log("ManagersRequests failed", er));
            }
          });
        }
      });
    } catch (error) {
      console.log(error);
    }
  }

  sendManReq(me, newEnterprise) {
    if (me.id !== this.usd.userId) {
      this.usd.afs
        .doc(`Users/${me.id}/allNotice/${me.dataId}`)
        .set(me)
        .then(() => {
          this.ns.showNotification(
            "req_Company",
            "top",
            "right",
            newEnterprise
          );
        })
        .catch((er) => console.log("ManagersRequests failed", er));
    }
  }

  ackManRequest(app) {
    this.usd.afs
      .doc(`Users/${this.usd.userId}/allNotice/${app.dataId}`)
      .update({ read: true })
      .then(() => {
        this.usd.totalNotices = this.usd?.totalNotices.filter(
          (x) => x.dataId !== app.dataId
        );
      })
      .catch((er) => console.log("request not marked read", er));
  }

  clientManagers(client, oldMans) {
    // console.log(client.managers);
    this.usd.afs
      .doc(`Enterprises/${this.usd.setCompany.id}/clients/${client.id}`)
      .update(client)
      .then(() => {
        this.usd.afs
          .doc<any>(`Projects/${this.usd.setProject.id}/clients/${client.id}`)
          .update(client)
          .then(() => {})
          .catch((pcErr) => {
            console.log("failed to update", pcErr);
            this.usd.afs
              .doc<any>(
                `Projects/${this.usd.setProject.id}/clients/${client.id}`
              )
              .set(client);
          });
        // console.log('Team Added');
        this.custManagerNotify(oldMans, client);
      })
      .catch((er) => {
        // console.log(er)
        this.usd.afs
          .doc(`Enterprises/${this.usd.setCompany.id}/clients/${client.id}`)
          .ref.get()
          .then((cus) => {
            if (cus.exists) {
            } else {
              this.usd.afs
                .doc(
                  `Enterprises/${this.usd.setCompany.id}/clients/${client.id}`
                )
                .update(client)
                .then(() => {
                  this.usd.afs
                    .doc<any>(
                      `Projects/${this.usd.setProject.id}/clients/${client.id}`
                    )
                    .update(client)
                    .then(() => {})
                    .catch((pcErr) => {
                      console.log("failed to update", pcErr);
                      this.usd.afs
                        .doc<any>(
                          `Projects/${this.usd.setProject.id}/clients/${client.id}`
                        )
                        .set(client);
                    });
                  this.custManagerNotify(oldMans, client);
                });
            }
          })
          .catch((err) => console.log(err));
      });
  }

  custManagerNotify(oldManagers, client) {
    const championdataId = this.usd.userId + moment().format("DDDDYYYY");
    const applicant: any = {
      usr: this.usd.acc,
      dataId: championdataId,
      client: client,
      noticeDate: moment().toISOString(),
      company: this.usd.setCompany,
      read: false,
    };
    const remMans = oldManagers?.filter((obj) => {
      const ind = client.managers?.findIndex((y) => y.id === obj.id);
      if (ind > -1) {
      } else {
        return obj;
      }
    });
    let j = 0;
    if (remMans.length > 0) {
      applicant.noti = "custRemManagerNotice";
      remMans.forEach((element) => {
        if (this.usd.userId !== element.id) {
          this.usd.afs
            .doc(`Users/${this.usd.userId}/allNotice/${applicant.dataId}`)
            .set(applicant)
            .then(() => {
              j++;
              if (j === remMans.length) {
                // console.log(j, remMans.length);
                this.ns.showNotification("Cus_rem", "top", "right", element);
              }
            })
            .catch((er) => console.log(er));
        }
      });
    }
    const newMans = client.managers.filter((obj) => {
      const ind = oldManagers?.findIndex((y) => y.id === obj.id);
      if (ind > -1) {
      } else {
        return obj;
      }
    });
    let i = 0;
    if (remMans.length > 0) {
      applicant.noti = "custManagerNotice";
      newMans.forEach((element) => {
        if (element.id !== this.usd.userId) {
          this.usd.afs
            .doc(`Users/${element.id}/allNotice/${applicant.dataId}`)
            .set(applicant)
            .then(() => {
              i++;
              if (i === newMans.length) {
                // console.log(i, newMans.length);
                this.ns.showNotification("Cus_man", "top", "right", element);
              }
            })
            .catch((er) => console.log(er));
        }
      });
    }
  }

  cstNoticeClearance(app) {
    this.usd.afs
      .doc(`Users/${this.usd.userId}/allNotice/${app.dataId}`)
      .update({ read: true })
      .then(() => {
        this.usd.totalNotices = this.usd?.totalNotices.filter(
          (x) => x.dataId !== app.dataId
        );
      });
  }

  cstRemNoticeClearance(app) {
    this.usd.afs
      .doc(`Users/${this.usd.userId}/allNotice/${app.dataId}`)
      .update({ read: true })
      .then(() => {
        this.usd.totalNotices = this.usd?.totalNotices.filter(
          (x) => x.dataId !== app.dataId
        );
      });
  }

  teamManagerNotify(oldManagers, team) {
    const dataId = this.usd.userId + moment().format("DDDDYYYY");
    const noti: any = {
      editBy: this.usd.acc,
      project: this.usd.setProject,
      read: false,
      dataId: dataId,
    };
    if (oldManagers && oldManagers?.length > 0) {
      const remMans = oldManagers?.filter((obj) => {
        const ind = team.managers.findIndex((y) => y.id === obj.id);
        if (ind > -1) {
        } else {
          return obj;
        }
      });
      let j = 0;
      if (remMans.length > 0) {
        noti.noti = "companyRemApproval";
        remMans.forEach((element) => {
          if (this.usd.userId !== element.id) {
            this.usd.afs
              .doc(`Users/${element.id}/allNotice/${dataId}`)
              .set(noti)
              .then(() => {
                j++;
                if (j === remMans.length) {
                  // console.log(j, remMans.length);
                  this.ns.showNotification(
                    "Cus_manRem",
                    "top",
                    "right",
                    element
                  );
                }
              })
              .catch((er) => console.log(er));
          }
        });
      }
    }
    const newMans = team.managers?.filter((obj) => {
      const ind = oldManagers?.findIndex((y) => y.id === obj.id);
      if (ind > -1) {
      } else {
        return obj;
      }
    });
    let i = 0;
    if (newMans?.length > 0) {
      noti.noti = "teamManagerNotice";
      newMans.forEach((element) => {
        if (this.usd.userId !== element.id) {
          this.usd.afs
            .doc(`Users/${element.id}/allNotice/${dataId}`)
            .set(noti)
            .then(() => {
              i++;
              if (i === newMans.length) {
                // console.log(i, newMans.length);
                this.ns.showNotification("Cus_man", "top", "right", element);
              }
            })
            .catch((er) => console.log(er));
        }
      });
    }
  }

  clsTeamManNotice(app) {
    // teamManagerNotice
    this.usd.afs
      .doc(`Users/${this.usd.userId}/allNotice/${app.dataId}`)
      .update({ read: true })
      .then(() => {
        this.usd.totalNotices = this.usd?.totalNotices.filter(
          (x) => x.dataId !== app.dataId
        );
        // console.log('teamManagerNotice cleared')
      })
      .catch((er) => console.log("teamManagerNotice failed cls", er));
  }

  clsTeamRemManNotice(app) {
    this.usd.afs
      .doc(`Users/${this.usd.userId}/allNotice/${app.dataId}`)
      .update({ read: true })
      .then(() => {
        this.usd.totalNotices = this.usd?.totalNotices.filter(
          (x) => x.dataId !== app.dataId
        );
        // console.log('teamManagerRemNotice cleared');
      })
      .catch((er) => console.log("teamManagerRemNotice failed cls", er));
  }

  teamManagers(team, oldMans) {
    // console.log(team);
    this.usd.afs
      .doc(`Projects/${this.usd.setProject.id}/enterprises/${team.id}`)
      .update(team)
      .then(() => {
        // console.log(`Team Added`, team);
        this.teamManagerNotify(oldMans, team);
      })
      .catch((er) => {
        console.log(er);
        this.usd.afs
          .doc(`Projects/${this.usd.setProject.id}/enterprises/${team.id}`)
          .ref.get()
          .then((cus) => {
            if (cus.exists) {
            } else {
              this.usd.afs
                .doc(
                  `Projects/${this.usd.setProject.id}/enterprises/${team.id}`
                )
                .update(team)
                .then(() => {
                  this.teamManagerNotify(oldMans, team);
                });
            }
          })
          .catch((err) => console.log(err));
      });
  }

  addManager(team, user) {
    // console.log(team, user);
    team.managers.push(user);
    this.usd.projectTeam.managers = team.managers;
    // this.usd.afs.doc(`Projects/${this.usd.setProject.id}/enterprises/${team.id}`).update({'managers': team.managers}).then(() => {
    this.usd.afs
      .doc(`Projects/${this.usd.setProject.id}/enterprises/${team.id}`)
      .update({
        managers: this.usd.firebase.firestore.FieldValue.arrayUnion(user),
      })
      .then(() => {
        // console.log(`Added`, team);
        this.teamManagerNotify([], team);
      })
      .catch((er) => {
        console.log(er);
        this.usd.afs
          .doc(`Projects/${this.usd.setProject.id}/enterprises/${team.id}`)
          .ref.get()
          .then((cus) => {
            if (cus.exists) {
            } else {
              this.usd.afs
                .doc(
                  `Projects/${this.usd.setProject.id}/enterprises/${team.id}`
                )
                .set(team)
                .then(() => {
                  this.teamManagerNotify([], team);
                });
            }
          })
          .catch((err) => console.log(err));
      });
  }

  updateManagers(team, munhu) {
    // console.log(team.managers, munhu.id);
    // const grp = team.managers.filter(mshandi => mshandi.id !== munhu.id);
    team.managers.find((mshandi) => mshandi.id === munhu.id).booksRole = munhu.booksRole;
    // console.log(team.managers.find(mshandi => mshandi.id === munhu.id).name, 'ava', team.managers.find(mshandi => mshandi.id === munhu.id).booksRole);
    // grp.push(munhu);
    this.usd.afs.doc(`Projects/${this.usd.setProject.id}/enterprises/${team.id}`).update({ managers: team.managers }).then(() => {
        // console.log(`Team Updated`, team);
        // this.teamManagerNotify(oldMans, team);
        this.usd.afs.doc(`Projects/${this.usd.setProject.id}/enterprises/${team.id}/labour/${munhu.id}`).update({'booksRole': munhu.booksRole}).catch(er => {
          this.usd.afs.doc(`Projects/${this.usd.setProject.id}/enterprises/${team.id}/labour/${munhu.id}`).set(munhu);
        });
      }).catch((er) => {
        console.log(er);
        this.usd.afs.doc(`Projects/${this.usd.setProject.id}/enterprises/${team.id}`).ref.get().then((cus) => {
            if (cus.exists) {
            } else {
              this.usd.afs.doc(`Projects/${this.usd.setProject.id}/enterprises/${team.id}`).set(team).then(() => {
                // this.teamManagerNotify(oldMans, team);
                this.usd.afs.doc(`Projects/${this.usd.setProject.id}/enterprises/${team.id}/labour/${munhu.id}`).update({'booksRole': munhu.booksRole}).catch(er => {
                  this.usd.afs.doc(`Projects/${this.usd.setProject.id}/enterprises/${team.id}/labour/${munhu.id}`).set(munhu);
                });
              });
            }
          })
          .catch((err) => console.log(err));
      });
  }

  labrNotify(labour: any[], enterPrise) {
    enterPrise.read = false;
    enterPrise.noti = "prjLabourNotice";
    enterPrise.noticeDate = moment().toISOString();
    labour.forEach((element) => {
      if (this.usd.userId !== element.id) {
        this.usd.afs
          .doc(`Users/${element.id}/allNotice/${enterPrise.data.id}`)
          .set(enterPrise)
          .then(() => {
            // console.log(`${element.name}, Notified`);
            this.ns.showNotification("lbr_notified", "top", "right", element);
          });
      }
    });
  }

  pjNotify(element, reqCompany) {
    if (this.usd.userId !== element.id) {
      const dataId = this.usd.userId + moment().format("DDDDYYYY");
      const app: any = {
        company: reqCompany,
        dataId: dataId,
        noticeDate: moment().toISOString(),
        usr: this.usd.acc,
        noti: "prjExitDsNots",
      };
      this.usd.afs
        .doc(`Users/${element.id}/allNotice/${app.dataId}`)
        .set(app)
        .then(() => {});
    }
  }

  teamAcknowledge(min) {
    const admin = min.admin;
    let i = 0;
    min.noti = "RequestAppNotice";
    (min.noticeDate = moment().toISOString()),
      this.usd.afs.doc(`Users/${min.usr.id}/allNotice/${min.dataId}`).set(min);
    admin.forEach((element) => {
      this.usd.afs
        .doc(`Users/${element.id}/allNotice/${min.dataId}`)
        .update({ read: true })
        .then(() => {
          element.data = min;
          this.sendCmpPrjInvAcc({ element });
          min.noti = "teamAcceptNotice";
          this.usd.afs
            .doc(`Users/${element.id}/allNotice/${min.dataId}`)
            .set(min)
            .then(() => {})
            .catch(() => {});
          i++;
          if (i === admin.length) {
            // console.log(i, admin.length);
          }
        })
        .catch((er) => console.log(er));
    });
  }

  ack_teamReqApproval(min) {
    this.usd.afs
      .doc(`Users/${min.usr.id}/allNotice/${min.dataId}`)
      .update({ read: true });
  }

  acknowleTem(min, d) {
    if (d === "decline") {
      this.usd.afs
        .doc(`Users/${this.usd.userId}/allNotice/${min.dataId}`)
        .update({ read: true })
        .then(() => {
          this.usd.totalNotices = this.usd?.totalNotices.filter(
            (x) => x.dataId !== min.dataId
          );
        })
        .catch((er) => console.log(er));
    } else if (d === "accept") {
      this.usd.afs
        .doc(`Users/${this.usd.userId}/allNotice/${min.dataId}`)
        .update({ read: true })
        .then(() => {
          this.usd.totalNotices = this.usd?.totalNotices.filter(
            (x) => x.dataId !== min.dataId
          );
        })
        .catch((er) => console.log(er));
    }
  }

  accept_TeamInvite(team) {
    team.acceptor = this.usd.acc;
    this.teamAcknowledge(team);
    this.saveTeam(team);
  }

  decline_TeamInvite(min) {
    const admin = min.admin;
    let i = 0;
    (min.noticeDate = moment().toISOString()), (min.decliner = this.usd.acc);
    min.noti = "teamDeclineNotice";
    this.usd.afs.doc(`Users/${min.usr.id}/allNotice/${min.dataId}`).set(min);
    admin.forEach((element) => {
      this.usd.afs
        .doc(`Users/${element.id}/allNotice/${min.dataId}`)
        .update({ read: true })
        .then(() => {
          this.sendCmpPrjInvRej({ element });
          min.noti = "teamDeclineNotice";
          this.usd.afs
            .doc(`Users/${element.id}/allNotice/${min.dataId}`)
            .set(min)
            .then(() => {})
            .catch(() => {});
          i++;
          if (i === admin.length) {
            // console.log(i, admin.length);
          }
        })
        .catch((er) => console.log(er));
    });
  }

  ack_decline_TeamInvite(min) {
    this.usd.afs
      .doc(`Users/${min.usr.id}/allNotice/${min.dataId}`)
      .update({ read: true });
  }

  saveTeam(app) {
    const enterPrise = app.team;
    /* to continue from here */
    /* user responding */
    const reqCompany = app.team;
    const company = app.company;
    const project = app.project;
    // console.log(reqCompany);
    // console.log(app);
    this.usd.afs
      .doc(`Projects/${this.usd.setProject.id}/enterprises/${enterPrise.id}`)
      .set(reqCompany)
      .then(() => {
        // console.log(reqCompany.name, ' saved');
        this.usd.afs
          .doc<any>(`Enterprises/${enterPrise.id}/projects/${project.id}`)
          .set(project)
          .then(() => {
            this.usd.afs
              .collection(
                `Projects/${this.usd.setProject.id}/enterprises`,
                (ref) => ref.where("id", "!=", reqCompany.id)
              )
              .valueChanges()
              .subscribe((ents) => {
                ents.forEach((element: any) => {
                  element.admin = [];
                  this.usd.afs
                    .collection<any>(
                      `Enterprises/${enterPrise.id}/Participants/`,
                      (ref) =>
                        ref.where("hierarchy", "==", "Executive").limit(3)
                    )
                    .valueChanges()
                    .subscribe((exe) => {
                      // console.log(exe);
                      // console.log(exe.length);
                      element.admin = exe;
                      if (exe.length > 0) {
                        this.usd.afs
                          .collection<any>(
                            `Enterprises/${enterPrise.id}/Participants/`,
                            (ref) =>
                              ref
                                .where("hierarchy", "==", "Middle management")
                                .limit(3)
                          )
                          .valueChanges()
                          .subscribe((ads) => {
                            // console.log(ads);
                            // console.log(ads.length);
                            if (ads.length > 0) {
                              element.admin.concat(ads);
                              this.pjNotify(element, reqCompany);
                            } else {
                              this.pjNotify(element, reqCompany);
                            }
                          });
                      } else {
                        this.usd.afs
                          .collection<any>(
                            `Enterprises/${enterPrise.id}/Participants/`,
                            (ref) =>
                              ref
                                .where("hierarchy", "==", "Middle management")
                                .limit(3)
                          )
                          .valueChanges()
                          .subscribe((ads) => {
                            // console.log(ads);
                            // console.log(ads.length);
                            if (ads.length > 0) {
                              element.admin = ads;
                              this.pjNotify(element, reqCompany);
                            } else {
                              this.ns.showNotification(
                                "save_CusFail",
                                "top",
                                "right",
                                enterPrise
                              );
                            }
                          });
                      }
                    });
                });
              });
          });
        app.admin.forEach((element) => {
          this.usd.afs
            .doc<any>(`Enterprises/${enterPrise.id}/Participants/${element.id}`)
            .update({ read: true })
            .then(() => console.log("notice cleared"))
            .catch((er) => {
              console.log(er);
            });
        });
        this.teamAdminNotify(enterPrise.admin);
        this.usd.afs
          .collection(
            `Projects/${this.usd.setProject.id}/enterprises/${company.id}/labour`
          )
          .valueChanges()
          .subscribe((labour) => this.labrNotify(labour, enterPrise));
      })
      .catch((er) =>
        console.log(enterPrise.name, er, " failed to add the company")
      );
  }

  saveIndTeam(app) {
    const enterPrise = app.team;
    /* to continue from here */
    /* user responding */
    const reqCompany = app.team;
    const company = app.company;
    const project = app.project;
    // console.log(reqCompany);
    // console.log(app);
    this.usd.afs
      .doc(`Projects/${this.usd.setProject.id}/enterprises/${enterPrise.id}`)
      .set(reqCompany)
      .then(() => {
        // console.log(reqCompany.name, ' saved');
        this.usd.afs
          .doc<any>(`Enterprises/${enterPrise.id}/projects/${project.id}`)
          .set(project)
          .then(() => {
            this.usd.afs
              .collection(
                `Projects/${this.usd.setProject.id}/enterprises`,
                (ref) => ref.where("id", "!=", reqCompany.id)
              )
              .valueChanges()
              .subscribe((ents) => {
                ents.forEach((element: any) => {
                  element.admin = [];
                  this.usd.afs
                    .collection<any>(
                      `Enterprises/${enterPrise.id}/Participants/`,
                      (ref) =>
                        ref.where("hierarchy", "==", "Executive").limit(3)
                    )
                    .valueChanges()
                    .subscribe((exe) => {
                      // console.log(exe);
                      // console.log(exe.length);
                      element.admin = exe;
                      if (exe.length > 0) {
                        this.usd.afs
                          .collection<any>(
                            `Enterprises/${enterPrise.id}/Participants/`,
                            (ref) =>
                              ref
                                .where("hierarchy", "==", "Middle management")
                                .limit(3)
                          )
                          .valueChanges()
                          .subscribe((ads) => {
                            // console.log(ads);
                            // console.log(ads.length);
                            if (ads.length > 0) {
                              element.admin.concat(ads);
                              this.pjNotify(element, reqCompany);
                            } else {
                              this.pjNotify(element, reqCompany);
                            }
                          });
                      } else {
                        this.usd.afs
                          .collection<any>(
                            `Enterprises/${enterPrise.id}/Participants/`,
                            (ref) =>
                              ref
                                .where("hierarchy", "==", "Middle management")
                                .limit(3)
                          )
                          .valueChanges()
                          .subscribe((ads) => {
                            // console.log(ads);
                            // console.log(ads.length);
                            if (ads.length > 0) {
                              element.admin = ads;
                              this.pjNotify(element, reqCompany);
                            } else {
                              this.ns.showNotification(
                                "save_CusFail",
                                "top",
                                "right",
                                enterPrise
                              );
                            }
                          });
                      }
                    });
                });
              });
          });
        app.admin.forEach((element) => {
          this.usd.afs
            .doc<any>(`Enterprises/${enterPrise.id}/Participants/${element.id}`)
            .update({ read: true })
            .then(() => console.log("notice cleared"))
            .catch((er) => {
              console.log(er);
            });
        });
        this.teamAdminNotify(enterPrise.admin);
        this.usd.afs
          .collection(
            `Projects/${this.usd.setProject.id}/enterprises/${company.id}/labour`
          )
          .valueChanges()
          .subscribe((labour) => this.labrNotify(labour, enterPrise));
      })
      .catch((er) =>
        console.log(enterPrise.name, er, " failed to add the company")
      );
  }

  cmpSaveTeam(app) {
    const enterPrise = app.team;
    /* to continue from here */
    /* user responding */
    const reqCompany = app.team;
    const company = app.company;
    const project = app.project;
    // console.log(reqCompany);
    // console.log(app);
    this.usd.afs
      .doc(`Projects/${project.id}/enterprises/${enterPrise.id}`)
      .set(reqCompany)
      .then(() => {
        // console.log(reqCompany.name, ' saved');
        this.usd.afs
          .doc<any>(`Enterprises/${enterPrise.id}/projects/${project.id}`)
          .set(project)
          .then(() => {
            this.usd.afs
              .collection(`Projects/${project.id}/enterprises`, (ref) =>
                ref.where("id", "!=", reqCompany.id)
              )
              .valueChanges()
              .subscribe((ents) => {
                ents.forEach((element: any) => {
                  element.admin = [];
                  this.usd.afs
                    .collection<any>(
                      `Enterprises/${enterPrise.id}/Participants/`,
                      (ref) =>
                        ref.where("hierarchy", "==", "Executive").limit(3)
                    )
                    .valueChanges()
                    .subscribe((exe) => {
                      // console.log(exe);
                      // console.log(exe.length);
                      element.admin = exe;
                      if (exe.length > 0) {
                        this.usd.afs
                          .collection<any>(
                            `Enterprises/${enterPrise.id}/Participants/`,
                            (ref) =>
                              ref
                                .where("hierarchy", "==", "Middle management")
                                .limit(3)
                          )
                          .valueChanges()
                          .subscribe((ads) => {
                            // console.log(ads);
                            // console.log(ads.length);
                            if (ads.length > 0) {
                              element.admin.concat(ads);
                              this.pjNotify(element, reqCompany);
                            } else {
                              this.pjNotify(element, reqCompany);
                            }
                          });
                      } else {
                        this.usd.afs
                          .collection<any>(
                            `Enterprises/${enterPrise.id}/Participants/`,
                            (ref) =>
                              ref
                                .where("hierarchy", "==", "Middle management")
                                .limit(3)
                          )
                          .valueChanges()
                          .subscribe((ads) => {
                            // console.log(ads);
                            // console.log(ads.length);
                            if (ads.length > 0) {
                              element.admin = ads;
                              this.pjNotify(element, reqCompany);
                            } else {
                              this.ns.showNotification(
                                "save_CusFail",
                                "top",
                                "right",
                                enterPrise
                              );
                            }
                          });
                      }
                    });
                });
              });
          });
        app.admin.forEach((element) => {
          this.usd.afs
            .doc<any>(`Enterprises/${enterPrise.id}/Participants/${element.id}`)
            .update({ read: true })
            .then(() => console.log("notice cleared"))
            .catch((er) => {
              console.log(er);
            });
        });
        this.teamAdminNotify(enterPrise.admin);
        this.usd.afs
          .collection(
            `Projects/${this.usd.setProject.id}/enterprises/${company.id}/labour`
          )
          .valueChanges()
          .subscribe((labour) => this.labrNotify(labour, enterPrise));
      })
      .catch((er) =>
        console.log(enterPrise.name, er, " failed to add the company")
      );
  }

  updateTeam(team) {
    const enterPrise = team;
    /* to continue from here */
    /* user responding */
    const reqCompany = team;
    // console.log(reqCompany, reqCompany.structuralRole);
    this.usd.afs
      .doc(`Projects/${this.usd.setProject.id}/enterprises/${enterPrise.id}`)
      .set(reqCompany)
      .then(() => {
        // console.log(reqCompany.name, ' saved');
        // this.usd.afs.collection(`Projects/${this.usd.setProject.id}/enterprises`, ref => ref.where('id', '!=', reqCompany.id)).valueChanges()
        //   .subscribe(ents => {
        //       ents.forEach((element: any) => {
        //         element.admin = [];
        //         this.usd.afs.collection<any>(`Enterprises/${enterPrise.id}/Participants/`, ref => ref.where('hierarchy', '==', 'Executive')
        //           .limit(3)).valueChanges().subscribe(exe => {
        //             // console.log(exe);
        //             // console.log(exe.length);
        //             element.admin = exe;
        //             if (exe.length > 0) {
        //               this.usd.afs.collection<any>(`Enterprises/${enterPrise.id}/Participants/`, ref => ref.where('hierarchy', '==', 'Middle management')
        //                 .limit(3)).valueChanges().subscribe(ads => {
        //                   // console.log(ads);
        //                   // console.log(ads.length);
        //                   if (ads.length > 0) {
        //                     element.admin.concat(ads);
        //                     this.pjNotify(element, reqCompany);
        //                   } else {
        //                     this.pjNotify(element, reqCompany);
        //                   }
        //                 })
        //             } else {
        //               this.usd.afs.collection<any>(`Enterprises/${enterPrise.id}/Participants/`, ref => ref.where('hierarchy', '==', 'Middle management')
        //                 .limit(3)).valueChanges().subscribe(ads => {
        //                   // console.log(ads);
        //                   // console.log(ads.length);
        //                   if (ads.length > 0) {
        //                     element.admin = ads;
        //                     this.pjNotify(element, reqCompany);
        //                   } else {
        //                     this.ns.showNotification('save_CusFail', 'top', 'right', enterPrise);
        //                   }
        //                 })
        //             }
        //           })
        //       });
        // });
        // this.teamAdminNotify(enterPrise.admin);
      })
      .catch((er) =>
        console.log(enterPrise.name, er, " failed to add the company")
      );
  }

  teamAdminNotify(team) {
    const admin = team.admin;
    let i = 0;
    admin.forEach((element) => {
      if (this.usd.userId !== element.id) {
        this.usd.afs
          .doc(`Users/${element.id}/allNotice/${team.dataId}`)
          .update({ read: true })
          .then(() => {
            i++;
            if (i === admin.length) {
              // console.log(i, admin.length);
              this.ns.showNotification("save_Request", "top", "right", team);
            }
          })
          .catch((er) => console.log(er));
      }
    });
  }

  requestTeam(enterPrise) {
    const championdataId = this.usd.userId + moment().format("DDDDYYYY");
    const cmp = {
      name: this.usd.setProject.companyName,
      id: this.usd.setProject.companyId,
    };
    const applicant: any = {
      usr: this.usd.acc,
      dataId: championdataId,
      team: enterPrise,
      company: cmp,
      noticeDate: moment().toISOString(),
      project: this.usd.setProject,
      read: false,
    };
    const index = this.ps.labour.findIndex((man) => man.id === this.usd.acc.id);
    if (index > -1) {
      applicant.usr = this.ps.labour[index];
    }

    try {
      if (enterPrise.partyType === "Enterprise") {
        this.usd.afs
          .collection<any>(
            `Enterprises/${enterPrise.id}/Participants/`,
            (ref) => ref.where("hierarchy", "==", "Executive").limit(3)
          )
          .valueChanges()
          .subscribe((exe) => {
            // console.log(exe);
            // console.log(exe.length);
            if (exe.length > 0) {
              applicant.admin = exe;
              this.usd.afs
                .collection<any>(
                  `Enterprises/${enterPrise.id}/Participants/`,
                  (ref) =>
                    ref.where("hierarchy", "==", "Middle management").limit(3)
                )
                .valueChanges()
                .subscribe((ads) => {
                  // console.log(ads);
                  // console.log(ads.length);
                  if (ads.length > 0) {
                    applicant.admin.concat(ads);
                    this.teamNotify(applicant, enterPrise);
                  } else {
                    this.teamNotify(applicant, enterPrise);
                  }
                });
            } else {
              this.usd.afs
                .collection<any>(
                  `Enterprises/${enterPrise.id}/Participants/`,
                  (ref) => ref.limit(3)
                )
                .valueChanges()
                .subscribe((ads) => {
                  // console.log(ads);
                  // console.log(ads.length);
                  if (ads.length > 0) {
                    applicant.admin = ads;
                    this.teamNotify(applicant, enterPrise);
                  } else {
                    this.ns.showNotification(
                      "save_CusFail",
                      "top",
                      "right",
                      enterPrise
                    );
                  }
                });
            }
          });
      } else if (enterPrise.partyType === "Individual") {
        this.saveIndTeam(applicant);
      } else {
      }
    } catch (error) {}
    // }).catch(er => console.log(enterPrise.name, er, ' failed to add the company'));
  }

  teamNotify(min, team) {
    min.noti = "teamNotice";
    const admin = min.admin;
    let i = 0;
    admin.forEach((element) => {
      if (this.usd.userId !== element.id) {
        this.usd.afs
          .collection("Users")
          .doc(element.id)
          .collection("allNotice")
          .doc(min.dataId)
          .set(min)
          .then(() => {
            i++;
            if (i === admin.length) {
              // console.log(i, admin.length);
              this.ns.showNotification("save_Request", "top", "right", team);
            }
          })
          .catch((er) => console.log(er));
      }
    });
  }

  saveClient(cusInfo) {
    if (cusInfo.customerType === "Individual") {
      cusInfo.champion.name = cusInfo.name;
      cusInfo.telephone = cusInfo.champion.phoneNumber;
      cusInfo.postalAddress = cusInfo.champion.postalAddress;
      cusInfo.address = cusInfo.champion.address;
      cusInfo.bus_email = cusInfo.champion.bus_email;
      cusInfo.email = cusInfo.champion.bus_email;
      cusInfo.contactPerson = cusInfo.champion;
    }
    if (cusInfo.customerType === "Company") {
    }
    // console.log(cusInfo);
  }

  psnAddClient(client) {
    this.usd.afs
      .doc(`Users/${this.usd.userId}/myCustomers/${client.id}`)
      .set(client)
      .then(() => {
        const dataId = this.usd.userId + moment().format("DDDDYYYY");
        const applicant: any = {
          usr: this.usd.acc,
          dataId: dataId,
          customer: client,
          company: this.usd.acc,
          noticeDate: moment().toISOString(),
          read: false,
        };
        applicant.company.srTag = "ind";
        const index = this.es.staff.findIndex(
          (man) => man.id === this.usd.acc.id
        );
        if (index > -1) {
          applicant.usr = this.es.staff[index];
        }
        const comp: any = this.usd.acc;
        comp.srTag = "ind";
        if (client.srTag === "ent") {
          this.usd.afs
            .doc(`Enterprises/${client.id}/clients/${this.usd.userId}`)
            .set(client);
          this.usd.afs
            .collection<any>(`Enterprises/${client.id}/Participants/`, (ref) =>
              ref.where("hierarchy", "==", "Executive").limit(3)
            )
            .valueChanges()
            .subscribe((exe) => {
              // console.log(exe);
              // console.log(exe.length);
              if (exe.length > 0) {
                applicant.admin = exe;
                this.usd.afs
                  .collection<any>(
                    `Enterprises/${client.id}/Participants/`,
                    (ref) =>
                      ref.where("hierarchy", "==", "Middle management").limit(3)
                  )
                  .valueChanges()
                  .subscribe((ads) => {
                    // console.log(ads);
                    // console.log(ads.length);
                    if (ads.length > 0) {
                      applicant.admin.concat(ads);
                      this.customerNotify(applicant, client);
                    } else {
                      this.customerNotify(applicant, client);
                    }
                  });
              } else {
                this.usd.afs
                  .collection<any>(
                    `Enterprises/${client.id}/Participants/`,
                    (ref) =>
                      ref.where("hierarchy", "==", "Middle management").limit(3)
                  )
                  .valueChanges()
                  .subscribe((ads) => {
                    // console.log(ads);
                    // console.log(ads.length);
                    if (ads.length > 0) {
                      applicant.admin = ads;
                      this.customerNotify(applicant, client);
                    } else {
                      this.ns.showNotification(
                        "save_CusFail",
                        "top",
                        "right",
                        client
                      );
                    }
                  });
              }
            });
        }
        if (client.srTag === "ind") {
          this.usd.afs
            .doc(`Users/${client.id}/myCustomers/${this.usd.acc.id}`)
            .set(comp)
            .catch((er) => console.log(er));
        }
      })
      .catch((er) => console.log(er));
  }

  addClient(client) {
    this.usd.afs
      .doc(`Enterprises/${this.usd.setCompany.id}/clients/${client.id}`).set(client)
      .then(() => {
        const dataId = this.usd.userId + moment().format("DDDDYYYY");
        const applicant: any = {
          usr: this.usd.acc,
          dataId: dataId,
          customer: client,
          company: this.usd.setCompany,
          noticeDate: moment().toISOString(),
          read: false,
        };
        const index = this.es.staff.findIndex(
          (man) => man.id === this.usd.acc.id
        );
        if (index > -1) {
          applicant.usr = this.es.staff[index];
        }
        let comp: any = this.usd.setCompany;
        comp.srTag === "ent";
        if (client.srTag === "ent") {
          this.usd.afs
            .doc(`Enterprises/${client.id}/clients/${this.usd.setCompany.id}`)
            .set(comp)
            .catch((er) => console.log(er));
        }
        if (client.srTag === "ind") {
          this.usd.afs
            .doc(`Users/${client.id}/myCustomers/${this.usd.setCompany.id}`)
            .set(comp)
            .catch((er) => console.log(er));
        }
        this.usd.afs
          .collection<any>(`Enterprises/${client.id}/Participants/`, (ref) =>
            ref.where("hierarchy", "==", "Executive").limit(3)
          )
          .valueChanges()
          .subscribe((exe) => {
            // console.log(exe);
            // console.log(exe.length);
            if (exe.length > 0) {
              applicant.admin = exe;
              this.usd.afs
                .collection<any>(
                  `Enterprises/${client.id}/Participants/`,
                  (ref) =>
                    ref.where("hierarchy", "==", "Middle management").limit(3)
                )
                .valueChanges()
                .subscribe((ads) => {
                  // console.log(ads);
                  // console.log(ads.length);
                  if (ads.length > 0) {
                    applicant.admin.concat(ads);
                    this.customerNotify(applicant, client);
                  } else {
                    this.customerNotify(applicant, client);
                  }
                });
            } else {
              this.usd.afs
                .collection<any>(
                  `Enterprises/${client.id}/Participants/`,
                  (ref) =>
                    ref.where("hierarchy", "==", "Middle management").limit(3)
                )
                .valueChanges()
                .subscribe((ads) => {
                  // console.log(ads);
                  // console.log(ads.length);
                  if (ads.length > 0) {
                    applicant.admin = ads;
                    this.customerNotify(applicant, client);
                  } else {
                    this.ns.showNotification(
                      "save_CusFail",
                      "top",
                      "right",
                      client
                    );
                  }
                });
            }
          });
      })
      .catch((er) => console.log(er));
  }

  addprjClient(client) {
    client.customerjoinDate = moment().toISOString();
    client.party = {
      name: this.usd.setCompany.name,
      id: this.usd.setCompany.id,
    };
    this.usd.afs
      .doc(`Enterprises/${this.usd.setCompany.id}/clients/${client.id}`)
      .set(client)
      .then(() => {
        this.usd.afs
          .doc(
            `Projects/${this.usd.setProject.id}/enterprises/${this.usd.setProject.companyId}/clients/${client.id}`
          )
          .set(client)
          .then(() => {})
          .catch((er) => {
            // console.log('failed', er);
          });
        this.usd.afs
          .doc(`Projects/${this.usd.setProject.id}/clients/${client.id}`)
          .set(client)
          .then(() => console.log("done"))
          .catch((er) => console.log(er));
        const dataId = this.usd.userId + moment().format("DDDDYYYY");
        const applicant: any = {
          usr: this.usd.acc,
          dataId: dataId,
          customer: client,
          company: this.usd.setCompany,
          noticeDate: moment().toISOString(),
          read: false,
        };
        const prj: any = {
          name: this.usd.setProject.name,
          id: this.usd.setProject.id,
          champion: this.usd.setProject.champion,
          guest: true,
          company: {
            name: this.usd.setCompany.name,
            id: this.usd.setCompany.id,
          },
        };
        const index = this.es.staff?.findIndex(
          (man) => man.id === this.usd.acc.id
        );
        if (index > -1) {
          applicant.usr = this.es.staff[index];
        }
        const comp: any = {
          name: this.usd.setCompany.name,
          id: this.usd.setCompany.id,
          project: {
            name: this.usd.setProject.name,
            id: this.usd.setProject.id,
          },
          guest: true,
        };
        const ent = this.usd.setCompany;
        if (client.srTag === "ent") {
          this.usd.afs
            .doc(`Enterprises/${client.id}/clients/${this.usd.setCompany.id}`)
            .set(ent)
            .catch((er) => console.log(er));
        }
        if (client.srTag === "ind") {
          this.usd.afs
            .doc(`Users/${client.id}/myCustomers/${this.usd.setCompany.id}`)
            .set(comp)
            .catch((er) => console.log(er));
          this.usd.afs
            .doc(`Users/${client.id}/projects/${this.usd.setProject.id}`)
            .set(prj)
            .catch((er) => console.log(er));
        }
        this.usd.afs
          .collection<any>(`Enterprises/${client.id}/Participants/`, (ref) =>
            ref.where("hierarchy", "==", "Executive").limit(3)
          )
          .valueChanges()
          .subscribe((exe) => {
            // console.log(exe);
            // console.log(exe.length);
            if (exe.length > 0) {
              applicant.admin = exe;
              this.usd.afs
                .collection<any>(
                  `Enterprises/${client.id}/Participants/`,
                  (ref) =>
                    ref.where("hierarchy", "==", "Middle management").limit(3)
                )
                .valueChanges()
                .subscribe((ads) => {
                  // console.log(ads);
                  // console.log(ads.length);
                  if (ads.length > 0) {
                    applicant.admin.concat(ads);
                    this.customerNotify(applicant, client);
                  } else {
                    this.customerNotify(applicant, client);
                  }
                });
            } else {
              this.usd.afs
                .collection<any>(
                  `Enterprises/${client.id}/Participants/`,
                  (ref) =>
                    ref.where("hierarchy", "==", "Middle management").limit(3)
                )
                .valueChanges()
                .subscribe((ads) => {
                  // console.log(ads);
                  // console.log(ads.length);
                  if (ads.length > 0) {
                    applicant.admin = ads;
                    this.customerNotify(applicant, client);
                  } else {
                    this.ns.showNotification(
                      "save_CusFail",
                      "top",
                      "right",
                      client
                    );
                  }
                });
            }
          });
      })
      .catch((er) => console.log(er));
  }

  addSupplier(client) {
    this.usd.afs
      .doc(`Enterprises/${this.usd.setCompany.id}/suppliers/${client.id}`)
      .set(client)
      .then(() => {
        this.usd.afs
          .doc(
            `Projects/${this.usd.setProject.id}/enterprises/${this.usd.setProject.companyId}/suppliers/${client.id}`
          )
          .set(client)
          .then(() => {})
          .catch((er) => {
            // console.log('failed', er);
          });
        const dataId = this.usd.userId + moment().format("DDDDYYYY");
        const applicant: any = {
          usr: this.usd.acc,
          dataId: dataId,
          customer: client,
          company: {
            name: this.usd.setProject.companyName,
            id: this.usd.setProject.companyId,
          },
          noticeDate: moment().toISOString(),
          read: false,
        };
        const index = this.es.staff?.findIndex(
          (man) => man.id === this.usd.acc.id
        );
        if (index > -1) {
          applicant.usr = this.es.staff[index];
        }
        const comp: any = {
          name: this.usd.setProject.companyName,
          id: this.usd.setProject.companyId,
        };
        // this.usd.afs.doc(`Enterprises/${client.id}/suppliers/${this.usd.setCompany.id}`).set(comp);
        // this.usd.afs.doc(`Enterprises/${client.id}/suppliers/${this.usd.setCompany.id}`).set(comp);
        this.addprjClient(comp); /* to revise */
        this.usd.afs
          .collection<any>(`Enterprises/${client.id}/Participants/`, (ref) =>
            ref.where("hierarchy", "==", "Executive").limit(3)
          )
          .valueChanges()
          .subscribe((exe) => {
            // console.log(exe);
            // console.log(exe.length);
            if (exe.length > 0) {
              applicant.admin = exe;
              this.usd.afs
                .collection<any>(
                  `Enterprises/${client.id}/Participants/`,
                  (ref) =>
                    ref.where("hierarchy", "==", "Middle management").limit(3)
                )
                .valueChanges()
                .subscribe((ads) => {
                  // console.log(ads);
                  // console.log(ads.length);
                  if (ads.length > 0) {
                    applicant.admin.concat(ads);
                    this.customerNotify(applicant, client);
                  } else {
                    this.customerNotify(applicant, client);
                  }
                });
            } else {
              this.usd.afs
                .collection<any>(
                  `Enterprises/${client.id}/Participants/`,
                  (ref) =>
                    ref.where("hierarchy", "==", "Middle management").limit(3)
                )
                .valueChanges()
                .subscribe((ads) => {
                  // console.log(ads);
                  // console.log(ads.length);
                  if (ads.length > 0) {
                    applicant.admin = ads;
                    this.customerNotify(applicant, client);
                  } else {
                    this.ns.showNotification(
                      "save_CusFail",
                      "top",
                      "right",
                      client
                    );
                  }
                });
            }
          });
      })
      .catch((er) => console.log(er));
  }

  addPrjSupplier(client) {
    this.usd.afs
      .doc(`Enterprises/${this.usd.setCompany.id}/suppliers/${client.id}`)
      .set(client)
      .then(() => {
        this.usd.afs
          .doc(
            `Projects/${this.usd.setProject.id}/enterprises/${this.usd.setProject.companyId}/suppliers/${client.id}`
          )
          .set(client)
          .then(() => {})
          .catch((er) => {
            // console.log('failed', er);
          });
        const dataId = this.usd.userId + moment().format("DDDDYYYY");
        const applicant: any = {
          usr: this.usd.acc,
          dataId: dataId,
          customer: client,
          company: this.usd.setCompany,
          noticeDate: moment().toISOString(),
          read: false,
        };
        const index = this.es.staff?.findIndex(
          (man) => man.id === this.usd.acc.id
        );
        if (index > -1) {
          applicant.usr = this.es.staff[index];
        }
        const comp: any = this.usd.setCompany;
        this.usd.afs
          .doc(`Enterprises/${client.id}/suppliers/${this.usd.setCompany.id}`)
          .set(comp);
        this.usd.afs
          .collection<any>(`Enterprises/${client.id}/Participants/`, (ref) =>
            ref.where("hierarchy", "==", "Executive").limit(3)
          )
          .valueChanges()
          .subscribe((exe) => {
            // console.log(exe);
            // console.log(exe.length);
            if (exe.length > 0) {
              applicant.admin = exe;
              this.usd.afs
                .collection<any>(
                  `Enterprises/${client.id}/Participants/`,
                  (ref) =>
                    ref.where("hierarchy", "==", "Middle management").limit(3)
                )
                .valueChanges()
                .subscribe((ads) => {
                  // console.log(ads);
                  // console.log(ads.length);
                  if (ads.length > 0) {
                    applicant.admin.concat(ads);
                    this.customerNotify(applicant, client);
                  } else {
                    this.customerNotify(applicant, client);
                  }
                });
            } else {
              this.usd.afs
                .collection<any>(
                  `Enterprises/${client.id}/Participants/`,
                  (ref) =>
                    ref.where("hierarchy", "==", "Middle management").limit(3)
                )
                .valueChanges()
                .subscribe((ads) => {
                  // console.log(ads);
                  // console.log(ads.length);
                  if (ads.length > 0) {
                    applicant.admin = ads;
                    this.customerNotify(applicant, client);
                  } else {
                    this.ns.showNotification(
                      "save_CusFail",
                      "top",
                      "right",
                      client
                    );
                  }
                });
            }
          });
      })
      .catch((er) => console.log(er));
  }

  removeSupplier(client) {
    this.usd.afs
      .doc(`Enterprises/${this.usd.setCompany.id}/suppliers/${client.id}`)
      .delete()
      .then(() => {})
      .catch((er) => console.log(er));
  }

  removeClient(client) {
    this.usd.afs
      .doc(`Enterprises/${this.usd.setCompany.id}/clients/${client.id}`)
      .delete()
      .then(() => {})
      .catch((er) => console.log(er));
  }

  customerNotify(min, client) {
    min.noti = "companyRemApproval";
    const admin = min.admin;
    let i = 0;
    admin.forEach((element) => {
      const mailData = {
        by: {
          id: this.usd.acc?.id,
          name: this.usd.acc?.name,
          email: this.usd.acc?.bus_email || this.usd.acc.email,
          photoUrl: this.usd.acc?.photoURL || "",
        },
        name: element.name,
        email: element?.bus_email || element.email,
        subjet: "Notice",
        text: "Notice",
      };
      this.commonMail(mailData);
      // this.commonMail(element);
      if (this.usd.userId !== element.id) {
        this.usd.afs
          .collection("Users")
          .doc(element.id)
          .collection("customerNotice")
          .doc(min.dataId)
          .set(min)
          .then(() => {
            i++;
            if (i === admin.length) {
              // console.log(i, admin.length);
              this.ns.showNotification("save_Cus", "top", "right", client);
            }
          })
          .catch((er) => console.log(er));
      }
    });
  }

  cus_acknowledge(min) {
    const admin = min.admin;
    let i = 0;
    // console.log(admin);
    const mailData = {
      by: {
        id: this.usd.acc?.id,
        name: this.usd.acc?.name,
        email: this.usd.acc?.bus_email || this.usd.acc.email,
        photoUrl: this.usd.acc?.photoURL || "",
      },
      name: min.usr?.name,
      email: min.usr?.bus_email || min.usr?.email,
      subjet: "Notice",
      text: "Notice",
    };
    this.commonMail(mailData);
    // this.commonMail(min.usr);
    this.usd.afs
      .doc(`Users/${this.usd.userId}/allNotice/${min.dataId}`)
      .update({ read: true })
      .then(() => {
        this.usd.totalNotices = this.usd?.totalNotices.filter(
          (x) => x.dataId !== min.dataId
        );
        i++;
        if (i === admin.length) {
          // console.log(i, admin.length, 'All clear');
        }
      })
      .catch((er) => console.log(er));
  }

  /* Corrected 22/02/2022 01:16 PM */
  saveNewStaff(staff, company) {
    if (!staff.monthlyPay) {
      staff.monthlyPay = 0;
    }
    const userRef = this.usd.afs.doc(
      `Users/${staff.id}/myenterprises/${company.id}`
    );
    const partRef = this.usd.afs.doc(
      `Enterprises/${company.id}/Participants/${staff.id}`
    );
    partRef.set(staff).then(() => {
      staff.inviteBy = this.usd.acc;
      staff.dataId = this.usd.userId + moment().format("DDDDYYYY");
      // console.log(staff.dataId);
      staff.read = false;
      staff.left = false;
      staff.noti = "staffAddNotice";
      staff.noticeDate = moment().toISOString();
      staff.company = company;
      if (staff.id !== this.usd.acc.id) {
        this.usd.afs
          .doc(`Users/${staff.id}/allNotice/${staff.dataId}`)
          .set(staff)
          .then(() => {
            this.ns.showNotification("staff_Notice", "top", "right", staff);
          });
        this.es.staff.forEach((element) => {
          this.notifyWorkMates(element, staff, company);
        });
        userRef.set(company);
      }
    });
    // console.log(staff);
    return false;
  }

  /* Corrected 22/02/2022 01:16 PM */
  acknowlStaff(staff) {
    this.usd.totalNotices = this.usd?.totalNotices.filter(
      (xd) => xd.dataId !== staff.dataId
    );
    // console.log(staff.dataId);
    this.usd.afs
      .doc(`Users/${this.usd.userId}/allNotice/${staff.dataId}`)
      .update({ read: true })
      .then(() => {
        this.usd.totalNotices = this.usd?.totalNotices.filter(
          (xd) => xd.dataId !== staff.dataId
        );
        this.usd.notifications();
      })
      .catch(() => {
        this.is.months.forEach((element) => {
          const incFound = staff.dataId.includes(element);
          if (incFound) {
            // console.log(incFound);
            staff.dataId = this.is.rewriteData(staff.dataId, element);
            // console.log(staff.dataId);
            this.usd.afs
              .doc(`Users/${this.usd.userId}/allNotice/${staff.dataId}`)
              .update({ read: true, dataId: staff.dataId })
              .then(() => {
                this.usd.totalNotices = this.usd?.totalNotices.filter(
                  (xd) => xd.dataId !== staff.dataId
                );
              })
              .catch(() => {});
          } else {
            this.usd.afs
              .doc(`Users/${this.usd.userId}/allNotice/${staff.dataId}`)
              .update({ read: true })
              .then(() => {
                this.usd.totalNotices = this.usd?.totalNotices.filter(
                  (xd) => xd.dataId !== staff.dataId
                );
              })
              .catch(() => {});
          }
        });
      });
  }

  updateStaff(newEntstaff, company) {
    console.log(newEntstaff);
    newEntstaff.noti = "companyRemApproval";
    if (!newEntstaff.photoURL) {
      newEntstaff.photoURL = newEntstaff.userImg || "";
    }
    if (!newEntstaff.monthlyPay) {
      newEntstaff.monthlyPay = 0;
    }
    const partRef = this.usd.afs.doc(
      `Enterprises/${company.id}/Participants/${newEntstaff.id}`
    );
    partRef.set(newEntstaff).then(() => {
      // console.log(newEntstaff);
      this.usd.afs
        .doc<any>(`Users/${newEntstaff.id}/myenterprises/${company.id}`)
        .update({ hierarchy: newEntstaff.hierarchy });
      /* setting notification to staff */
      newEntstaff.company = {
        name: company.name,
        id: company.id,
        sector: company.sector,
      };
      newEntstaff.dataId = newEntstaff.id + moment().format("DDDDYYYY");
      this.usd.afs
        .doc(`Users/${newEntstaff.id}/allNotice/${newEntstaff.dataId}`)
        .set(newEntstaff)
        .then(() => console.log("notified"));
    });
    return true;
  }

  addLabour(companystaff, project) {
    companystaff.noti = "companyRemApproval";
    companystaff.activeTime = [];
    if (!companystaff.monthlyPay) {
      companystaff.monthlyPay = 0;
    }
    const partRef = this.usd.afs.collection(
      `Projects/${project.id}/enterprises/${project.companyId}/labour`
    );
    partRef
      .doc(companystaff.id)
      .set(companystaff)
      .then(() => {
        // console.log(companystaff);
        /* setting notification to staff */
        companystaff.project = {
          name: project.name,
          id: project.id,
          sector: project.sector,
        };
        companystaff.dataId = this.usd.userId + moment().format("DDDDMMMMYYYY");
        companystaff.read = false;
        companystaff.noticeDate = moment().toISOString();
        // companystaff.inviteBy = this.usd.acc;
        companystaff.project = project;
        companystaff.by = this.usd.acc;
        // if (companystaff.id !== companystaff.by.id) {
        //   this.usd.afs.doc(`Users/${companystaff.id}/allNotice/${companystaff.dataId}`).set(companystaff).then(() => {
        //     this.ns.showNotification('staff_Notice', 'top', 'right', companystaff);
        //     console.log('notified');
        //   })
        // }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  updateLabour(companystaff, project) {
    companystaff.prjJoinDate = moment(companystaff.prjJoinDate).format(
      "ddd MMM DD YYYY"
    );
    companystaff.activeTime = [];
    if (!companystaff.levelOfEffort) {
      companystaff.levelOfEffort = 0;
    }
    this.usd.afs
      .doc(
        `Projects/${project.id}/enterprises/${project.companyId}/labour/${companystaff.id}`
      )
      .update(companystaff)
      .then(() => {
        console.log(`${companystaff.name}, updated`);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  ackLabourAdd(companystaff) {
    this.usd.afs
      .doc(`Users/${this.usd.userId}/allNotice/${companystaff.dataId}`)
      .update({ read: true })
      .then(() => {
        this.usd.totalNotices = this.usd?.totalNotices.filter(
          (x) => x.dataId !== companystaff.dataId
        );
        console.log("Read");
      })
      .catch((error) => {
        console.log(error);
      });
  }

  deleteLabour(companystaff, project) {
    const dataId = this.usd.acc?.id + moment().format("DDDDYYYY");
    const applicant: any = {
      by: {
        name: this.usd.acc.name || "",
        id: this.usd.acc.id,
        address: this.usd.acc.address || "",
        phoneNumber: this.usd.acc.phoneNumber || "",
        bus_email: this.usd.acc.bus_email || "",
        nationality: this.usd.acc.nationality || "",
        nationalId: this.usd.acc.nationalId || "",
        photoURL: this.usd.acc.photoURL || "",
      },
      dataId: dataId,
      project: project,
      noticeDate: moment().toISOString(),
      company: { name: project.companyName, id: project.companyId },
      usr: companystaff,
      read: false,
    };
    const partRef = this.usd.afs.doc(
      `Projects/${project.id}/enterprises/${project.companyId}/labour/${companystaff.id}`
    );
    const partRef2 = this.usd.afs.doc(
      `Enterprises/${project.companyId}/projects/${project.id}/labour/${companystaff.id}`
    );
    this.usd.afs
      .doc<any>(`Users/${companystaff.id}/projects/${project.id}`)
      .update({ left: true })
      .catch((er) => {
        console.log(er);
      });
    partRef2
      .delete()
      .then(() => console.log("userDeleted2"))
      .catch((error) => {
        console.log(error);
      });
    partRef
      .delete()
      .then(() => {
        applicant.noti = "prjRemovedNots";
        this.usd.afs
          .doc(`Users/${companystaff.id}/allNotice/${dataId}`)
          .set(applicant)
          .catch((er) => {
            console.log(er);
          });
        console.log("userDeleted");
      })
      .catch((error) => {
        console.log(error);
      });
  }

  acknowledgePrjDel(not) {
    this.usd.afs
      .doc(`Users/${not.usr.id}/allNotice/${not.dataId}`)
      .update({ read: true })
      .catch((er) => {
        console.log(er);
      });
  }

  deleteStaff(staffDel, company) {
    this.usd.afs
      .doc(`Users/${staffDel.id}/myenterprises/${company.id}`)
      .delete();
    this.usd.afs
      .doc(`Enterprises/${company.id}/Participants/${staffDel.id}`)
      .delete();
    console.log(staffDel, "deleted");
  }

  addContact(ct) {
    // console.log(ct);
    if (ct.id) {
      // console.log(ct.id);
      this.usd.afs
        .doc(`Users/${this.usd.acc?.id}/contacts/${ct.id}`)
        .set(ct)
        .then(() => {
          // console.log(ct.name + ' ' + 'Added');
        })
        .catch((er) => console.log(er));
    }
    return {
      name: "",
      address: "",
      nationalId: "",
      nationality: "",
      phoneNumber: "",
      id: "",
      photoURL: "",
      bus_email: "",
    };
  }

  remContact(ct) {
    if (ct.id) {
      // console.log(ct.id);
      this.usd.afs
        .doc(`Users/${this.usd.acc?.id}/contacts/${ct.id}`)
        .delete()
        .then(() => {
          // console.log(ct.name + ' ' + 'Removed');
        })
        .catch((er) => console.log(er));
    } else {
    }
    return "";
  }

  updateEnterprise(comp) {
    const newRef = this.usd.afs.doc(
      `Users/${this.usd.acc?.id}/myenterprises/${comp.id}`
    );
    const mycompanyRef = this.usd.afs.doc(`Enterprises/${comp.id}`);
    newRef.update(comp).then(() => {
      mycompanyRef.update(comp);
      this.es.staff.forEach((element) => {
        this.usd.afs.doc(`Users/${element.id}/myenterprises/${comp.id}`).update(comp).then(() => console.log(element.name))
          .catch((err) => {
            this.usd.afs.doc(`Users/${element.id}/myenterprises/${comp.id}`).set(comp);
          });
      });
      // console.log('enterprise', comp.name, ' updated');
    })
    .then(() => {
      this.ns.showNotification("comp_updated", "top", "right", comp);
    });
    return this.is.getnewEnterprise();
  }

  useCamp(compUser) {
    
    /* UserData updated collection('Users/userID).update */
    this.usd.myDocument
      .update(compUser)
      .then(() => {
        // console.log('update successful (document exists)');
      })
      .catch((error) => {
        console.log(error);
      });
    if (this.usd.acc.phoneNumber === compUser.phoneNumber) {
      // this.usd.myDocument.update({ 'phoneNumber2': compUser.phoneNumber });
    } else {
      this.usd.myDocument
        .update({
          phoneNumbersOther: this.usd.firebase.firestore.FieldValue.arrayUnion(
            compUser.phoneNumber
          ),
        })
        .then(() => {
          // console.log('update successful (document exists)');
        })
        .catch((error) => {
          console.log(error);
        });
    }

    if (this.usd.acc.nationality === "" || this.usd.acc.nationality === null) {
      if (this.usd.acc.nationality !== compUser.nationality) {
        this.usd.myDocument
          .update({ nationality: compUser.nationality })
          .then(() => {
            // console.log('update successful (document exists)');
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }

    if (this.usd.acc.nationalId === "" || this.usd.acc.nationalId === null) {
      if (this.usd.acc.nationalId !== compUser.nationalId) {
        this.usd.myDocument
          .update({ nationalId: compUser.nationalId })
          .then(() => {
            // console.log('update successful (document exists)');
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }

    if (this.usd.acc.bus_email === "" || this.usd.acc.bus_email === null) {
      if (this.usd.acc.bus_email !== compUser.bus_email) {
        this.usd.myDocument
          .update({ bus_email: compUser.bus_email })
          .then(() => {
            // console.log('update successful (document exists)');
          })
          .catch((error) => {
            // console.log(error);
          });
      }
    } else if (this.usd.acc.bus_email === compUser.bus_email) {
      // console.log('bus_email are same')
    } else {
      this.usd.myDocument
        .update({
          bus_emailsOther: this.usd.firebase.firestore.FieldValue.arrayUnion(
            compUser.bus_email
          ),
        })
        .then(() => {
          // console.log('email update successful (document exists)');
        })
        .catch((error) => {
          // console.log(error);
        });
    }
  }

  saveEnterprise(newEnterprise, compUser) {
    let mycompanyRef; // root enterprise
    // let comp: Enterprise;
    const newRef = this.usd.afs.collection(`Users/${this.usd.userId}/myenterprises`);
    console.log(this.usd.userId);
    const pUser = {
      name: compUser.name, email: this.usd.acc.email, id: compUser.id,
      phoneNumber: compUser.phoneNumber, photoURL: this.usd.acc.photoURL, bus_email: compUser.bus_email,
      nationality: compUser.nationality, nationalId: compUser.nationalId, address: this.usd.acc.address,
      hierarchy: "Executive",
    };

    newEnterprise.by = compUser.name;
    newEnterprise.byId = compUser.id;
    newEnterprise.createdOn = new Date().toISOString();
    newEnterprise.participants = [pUser];
    newEnterprise.champion = pUser;

    console.log(newEnterprise);
    const partId = this.usd.userId;
    const comp = newEnterprise;
    mycompanyRef = this.usd.afs.collection("Enterprises");
    const nrouter = this.router;
    /* this.useCamp(compUser); */
    /* closure "userData update end" */
    newRef.add(comp).then((Ref) => {
      comp.id = Ref.id;
      mycompanyRef.doc(comp.id).set(comp);
      mycompanyRef.doc(`${comp.id}/Participants/${partId}`).set(pUser);
      // console.log('enterprise ');
      newRef.doc(comp.id).update({ id: comp.id }).then(() => {
        this.usd.getCompanies();
        this.ns.showNotification("comp", "top", "right", comp);
      });
    });

    return this.is.getnewEnterprise();
  }

  requestDeleteEnt(company) {
    console.log(company);
    const me: any = {
      name: this.usd.acc.name || "",
      id: this.usd.acc.id,
      address: this.usd.acc.address || "",
      phoneNumber: this.usd.acc.phoneNumber || "",
      bus_email: this.usd.acc.bus_email || "",
      nationality: this.usd.acc.nationality || "",
      nationalId: this.usd.acc.nationalId || "",
      photoURL: this.usd.acc.photoURL || "",
      read: false,
      company: company,
    };
    console.log(me);
    try {
      this.usd.afs
        .collection<any>(`Enterprises/${company.id}/Participants/`, (ref) =>
          ref.where("hierarchy", "==", "Executive").limit(3)
        )
        .valueChanges()
        .subscribe((exe) => {
          // console.log('Executive', exe);
          // console.log(exe.length)
          if (exe.length > 0) {
            me.admin = exe;
            this.usd.afs
              .collection<any>(
                `Enterprises/${company.id}/Participants/`,
                (ref) =>
                  ref.where("hierarchy", "==", "Middle management").limit(3)
              )
              .valueChanges()
              .subscribe((ads) => {
                // console.log('Middle Man Admin', ads);
                if (ads.length > 0) {
                  me.admin.concat(ads);
                  // console.log('Admin', ads);
                  this.adminNotify(me, company);
                } else {
                  this.adminNotify(me, company);
                }
              });
          } else {
            this.usd.afs
              .collection<any>(
                `Enterprises/${company.id}/Participants/`,
                (ref) =>
                  ref.where("hierarchy", "==", "Middle management").limit(3)
              )
              .valueChanges()
              .subscribe((ads) => {
                // console.log('Admin', ads);
                if (ads.length > 0) {
                  me.admin = ads;
                  this.adminNotify(me, company);
                } else {
                  this.ns.showNotification(
                    "req_deleteCompanyfail",
                    "top",
                    "right",
                    company
                  );
                }
              });
          }
        });
    } catch (error) {
      // console.log(error);
    }
    return this.is.getSelectedCompany();
  }

  adminNotify(man, company) {
    const admin = man.admin;
    let i = 0;
    man.noti = "companyRemoveReq";
    admin.forEach((element) => {
      const mailData = {
        by: {
          id: this.usd.acc?.id,
          name: this.usd.acc?.name,
          email: this.usd.acc?.bus_email || this.usd.acc.email,
          photoUrl: this.usd.acc?.photoURL || "",
        },
        name: element.name,
        email: element?.bus_email || element.email,
        subjet: "Notice",
        text: "Notice",
      };
      this.commonMail(mailData);
      // this.commonMail(element);
      if (this.usd.userId !== element.id) {
        this.usd.afs
          .collection("Users")
          .doc(element.id)
          .collection("companyRemoveReq")
          .doc(company.id)
          .set(man)
          .then(() => {
            i++;
            if (i === admin.length) {
              this.usd.afs
                .doc(`Users/${this.usd.userId}/myenterprises/${company.id}`)
                .update({ deleteInit: true })
                .catch((err) => {
                  this.usd.afs
                    .doc(`Users/${this.usd.userId}/allNotice/${company.id}`)
                    .update({ read: true })
                    .then(() => {
                      this.usd.totalNotices = this.usd?.totalNotices.filter(
                        (x) => x.id !== company.id
                      );
                    });
                });
              // console.log(i);
              this.ns.showNotification(
                "req_deleteCompany",
                "top",
                "right",
                company
              );
            }
          });
      }
    });
  }

  remEntApproval(me) {
    me.noti = "companyRemApproval";
    const mailData = {
      by: {
        id: this.usd.acc?.id,
        name: this.usd.acc?.name,
        email: this.usd.acc?.bus_email || this.usd.acc.email,
        photoUrl: this.usd.acc?.photoURL || "",
      },
      name: me.name,
      email: me?.bus_email || me.email,
      subjet: "Notice",
      text: "Notice",
    };
    this.commonMail(mailData);
    // this.commonMail(me);
    this.usd.afs
      .doc(`Users/${me.id}/myenterprises/${me.company.id}`)
      .delete()
      .then(() => {
        this.usd.afs
          .collection<any>(
            `Enterprises/${me.company.id}/Participants/`,
            (ref) => ref.where("hierarchy", "==", "Executive").limit(3)
          )
          .valueChanges()
          .subscribe((users) => {
            if (users.length > 0) {
              let i = 0;
              users.forEach((element) => {
                const mailData = {
                  by: {
                    id: this.usd.acc?.id,
                    name: this.usd.acc?.name,
                    email: this.usd.acc?.bus_email || this.usd.acc.email,
                    photoUrl: this.usd.acc?.photoURL || "",
                  },
                  name: element.name,
                  email: element?.bus_email || element.email,
                  subjet: "Notice",
                  text: "Notice",
                };
                this.commonMail(mailData);
                // this.commonMail(element);
                i++;
                if (this.usd.userId !== element.id) {
                  this.usd.afs
                    .doc(`Users/${element.id}/myenterprises/${me.company.id}`)
                    .delete()
                    .then(() => {
                      // set notification
                      this.usd.afs
                        .doc(`Users/${element.id}/allNotice/${me.company.id}`)
                        .set(me);
                    });
                }
                if (i === users.length) {
                  me.admin.forEach((elem) => {
                    if (this.usd.userId !== elem.id) {
                      this.usd.afs
                        .doc(`Users/${elem.id}/allNotice/${me.company.id}`)
                        .set(me);
                      // console.log(elem.name);
                    }
                  });
                }
                if (i === users.length) {
                  const man: any = me;
                  man.read = false;
                  man.noticeDate = moment().toISOString();
                  man.approval = this.usd.acc;
                  this.usd.afs
                    .doc(`Users/${this.usd.userId}/allNotice/${me.company.id}`)
                    .update({ read: true })
                    .then(() => {
                      this.usd.totalNotices = this.usd?.totalNotices.filter(
                        (x) => x.company.id !== me.company.id
                      );
                    });
                  this.usd.afs
                    .doc(`Users/${me.id}/allNotice/${me.company.id}`)
                    .set(man);
                  this.usd.afs.doc(`Enterprises/${me.company.id}`).delete();
                  // console.log('enterprise deleted');
                }
              });
            }
          });
      });
  }

  clearEntRemApproval(me) {
    this.usd.afs
      .doc(`Users/${this.usd.userId}/allNotice/${me.company.id}`)
      .update({ read: true })
      .then(() => {
        // console.log('Acknowledged and Marked as read');
        this.usd.totalNotices = this.usd?.totalNotices.filter(
          (xd) => xd.company.id !== me.company.id
        );
      })
      .catch((err) => console.log(err));
  }

  remEntDecline(me) {
    me.decliner = this.usd.acc;
    this.usd.afs
      .doc(`Users/${me.id}/companyDecline/${me.company.id}`)
      .set(me)
      .then(() => {
        // notification set
        const mailData = {
          by: {
            id: this.usd.acc?.id,
            name: this.usd.acc?.name,
            email: this.usd.acc?.bus_email || this.usd.acc.email,
            photoUrl: this.usd.acc?.photoURL || "",
          },
          name: me.name,
          email: me?.bus_email || me.email,
          subjet: "Notice",
          text: "Notice",
        };
        this.commonMail(mailData);
        // this.commonMail(me);
        this.setAdminClear(me);
        this.usd.afs
          .doc(`Users/${me.id}/myenterprises/${me.company.id}`)
          .update({ deleteInit: false })
          .then(() => {
            this.usd.afs
              .doc(`Users/${this.usd.userId}/allNotice/${me.company.id}`)
              .update({ read: true })
              .then(() => {
                this.usd.totalNotices = this.usd?.totalNotices.filter(
                  (x) => x.company.id !== me.company.id
                );
              });
          })
          .catch((err) => {
            this.usd.afs
              .doc(`Users/${me.id}/myenterprises/${me.company.id}`)
              .update({ deleteInit: false })
              .then(() => {});
            this.usd.afs
              .doc(`Users/${this.usd.userId}/allNotice/${me.company.id}`)
              .update({ read: true })
              .then(() => {
                this.usd.totalNotices = this.usd?.totalNotices.filter(
                  (x) => x.company.id !== me.company.id
                );
              });
            this.setAdminClear(me);
          });
        // console.log('enterprise Delete declined');
      });
  }

  setAdminClear(man) {
    const admin = man.admin;
    const company = man.company;
    let i = 0;
    admin.forEach((element) => {
      const mailData = {
        by: {
          id: this.usd.acc?.id,
          name: this.usd.acc?.name,
          email: this.usd.acc?.bus_email || this.usd.acc.email,
          photoUrl: this.usd.acc?.photoURL || "",
        },
        name: element.name,
        email: element?.bus_email || element.email,
        subjet: "Notice",
        text: "Notice",
      };
      this.commonMail(mailData);
      // this.commonMail(element);
      if (this.usd.userId !== element.id) {
        i++;
        if (i === admin.length) {
          this.usd.afs
            .doc(`Users/${element.id}/allNotice/${company.id}`)
            .update({ read: true })
            .catch((err) => {
              // console.log(err);
            });
          // console.log(i);
          // this.ns.showNotification('req_deleteCompany', 'top', 'right', company);
        }
      }
    });
  }

  acknowEntDecline(me) {
    // notification clear
    this.usd.afs
      .doc(`Users/${this.usd.userId}/allNotice/${me.company.id}`)
      .update({ read: true })
      .then(() => {
        this.usd.totalNotices = this.usd?.totalNotices.filter(
          (x) => x.company.id !== me.company.id
        );
        this.usd.afs.doc(`Enterprises/${me.company.id}`).delete();
        // console.log('enterprise deleted');
      });
  }

  deleteProject(app, tst) {
    const project = app.project;
    const championId = project.champion?.id;
    const dref = this.usd.afs.collection("Projects").doc(project.id);
    const myProRef = this.usd.afs
      .collection("Users")
      .doc(this.usd.acc?.id)
      .collection("projects")
      .doc(project.id);
    let champProRef;
    if (championId) {
      champProRef = this.usd.afs
        .collection("Users")
        .doc(championId)
        .collection("projects")
        .doc(project.id);
    }
    this.usd.afs
      .collection<Enterprise>(`Projects/${project.id}/enterprises`)
      .valueChanges()
      .subscribe((ents) => {
        this.usd.afs
          .collection<ParticipantData>(`Projects/${project.id}/Participants`)
          .valueChanges()
          .subscribe((labr) => {
            let i = 0;
            labr.forEach((element) => {
              i++;
              this.usd.afs
                .doc(`Users/${element.id}/projects/${project.id}`)
                .delete()
                .then(() => {
                  // console.log(`deleted for ${element.name}`);
                })
                .catch((er) => {
                  // console.log(`Failed, ${er}`);
                });
            });
            let j = 0;
            ents.forEach((element) => {
              j++;
              this.usd.afs
                .doc(`Enterprises/${element.id}/projects/${project.id}`)
                .delete()
                .then(() => {
                  // console.log(`deleted for ${element.name}`);
                })
                .catch((er) => {
                  // console.log(`Failed, ${er}`);
                });
              if (j === ents.length && i === labr.length) {
                dref.ref.get().then((doc) => {
                  if (doc.exists) {
                    dref.delete();
                  }
                });
                if (project.type === "Enterprise") {
                  const entRef = this.usd.afs.doc(
                    `Enterprises/${project.companyId}/projects/${project.id}`
                  );
                  entRef.ref.get().then((doc) => {
                    if (doc.exists) {
                      entRef.delete();
                    }
                  });
                }
              }
            });
          });
      });
    if (tst === "nav") {
      const mailData = {
        by: {
          id: this.usd.acc?.id,
          name: this.usd.acc?.name,
          email: this.usd.acc?.bus_email || this.usd.acc.email,
          photoUrl: this.usd.acc?.photoURL || "",
        },
        name: app.by.name,
        email: app.by?.bus_email || app.by.email,
        subjet: "Notice",
        text: "Notice",
      };
      this.commonMail(mailData);
      // this.commonMail(app.by);
      this.usd.afs
        .doc(`Users/${this.usd.userId}/allNotice/${project.id}`)
        .update({ read: true })
        .then(() => {
          this.usd.totalNotices = this.usd?.totalNotices.filter(
            (x) => x?.project?.id !== project.id
          );
          // console.log('PrjDeleteReq read');
          this.ns.showNotification("projectDelete", "top", "right", project);
        });
    }
    if (championId !== this.usd.userId) {
      myProRef.ref.get().then((doc) => {
        if (doc.exists) {
          myProRef.delete();
        }
      });
      if (championId && champProRef) {
        champProRef.ref.get().then((doc) => {
          if (doc.exists) {
            champProRef.delete();
          }
        });
      }
    } else {
      myProRef.ref.get().then((doc) => {
        if (doc.exists) {
          myProRef.delete();
        }
      });
    }
    return {
      name: "",
      type: "",
      by: "",
      byId: "",
      companyName: "",
      companyId: "",
      champion: null,
      createdOn: "",
      id: "",
      location: "",
      sector: "",
      completion: "",
    };
  }

  editPrjName(project) {
    const championId = project.champion?.id;
    const dref = this.usd.afs.collection("Projects").doc(project.id);
    const myProRef = this.usd.afs
      .collection("Users")
      .doc(this.usd.acc?.id)
      .collection("projects")
      .doc(project.id);
    this.usd.afs
      .collection<Enterprise>(`Projects/${project.id}/enterprises`)
      .valueChanges()
      .subscribe((ents) => {
        // this.usd.afs.collection<ParticipantData>(`Projects/${project.id}/Participants`).valueChanges().subscribe(labr => {
        // let i = 0;
        // labr.forEach(element => {
        //   i++;
        // this.usd.afs.doc(`Users/${element.id}/projects/${project.id}`).update({'name': project.name}).then(() => {
        //   console.log(`updated for ${element.name}`);
        // }).catch(er => {
        //   console.log(`Failed, ${er}`);
        // })
        // });
        let j = 0;
        ents.forEach((element) => {
          j++;
          const lbr = this.usd.afs
            .collection<any>(
              `Projects/${project.id}/enterprises/${element.id}/labour`
            )
            .valueChanges()
            .subscribe((allLbr) => {
              allLbr.forEach((lbor) => {
                this.usd.afs
                  .doc(`Users/${lbor.id}/projects/${project.id}`)
                  .update({ name: project.name })
                  .then(() => {
                    // console.log(`updated for ${lbor.name}`);
                  })
                  .catch((er) => {
                    this.usd.afs
                      .doc(`Users/${lbor.id}/projects/${project.id}`)
                      .set(project)
                      .then(() => console.log("Project Set for", lbor.name));
                    // console.log(`Failed, ${er}`);
                  });
              });
            });
          this.usd.afs
            .doc(`Enterprises/${element.id}/projects/${project.id}`)
            .update({ name: project.name })
            .then(() => {
              // console.log(`updated for ${element.name}`);
            })
            .catch((er) => {
              // console.log(`Failed, ${er}`);
            });
          // if (j === ents.length && i === labr.length) {
          if (j === ents.length) {
            dref.ref.get().then((doc) => {
              if (doc.exists) {
                dref.update({ name: project.name });
              }
            });
            if (project.type === "Enterprise") {
              const entRef = this.usd.afs.doc(
                `Enterprises/${project.companyId}/projects/${project.id}`
              );
              entRef.ref.get().then((doc) => {
                if (doc.exists) {
                  entRef.update({ name: project.name });
                }
              });
            }
          }
        });
        // });
      });
    if (championId !== this.usd.userId) {
      myProRef.ref.get().then((doc) => {
        if (doc.exists) {
          myProRef
            .update({ name: project.name })
            .then(() => {
              // console.log(`updated for ${this.usd.acc.name}`);
            })
            .catch((er) => {
              // console.log(`Failed, ${er}`);
            });
        }
      });
      if (championId) {
        const champProRef = this.usd.afs
          .collection("Users")
          .doc(championId)
          .collection("projects")
          .doc(project.id);
        champProRef.ref.get().then((doc) => {
          if (doc.exists) {
            champProRef
              .update({ name: project.name })
              .then(() => {
                // console.log(`updated for ${project?.champion?.name || project?.championName}`);
              })
              .catch((er) => {
                // console.log(`Failed, ${er}`);
              });
          } else {
            champProRef
              .set(project.name)
              .then(() => {
                // console.log(`updated for ${project?.champion?.name || project?.championName}`);
              })
              .catch((er) => {
                // console.log(`Failed, ${er}`);
              });
          }
        });
      }
    } else {
      myProRef.ref.get().then((doc) => {
        if (doc.exists) {
          myProRef
            .update({ name: project.name })
            .then(() => {
              // console.log(`updated for ${this.usd.acc.name}`);
            })
            .catch((er) => {
              // console.log(`Failed, ${er}`);
            });
        }
      });
    }
    return {
      name: "",
      type: "",
      by: "",
      byId: "",
      companyName: "",
      companyId: "",
      champion: null,
      createdOn: "",
      id: "",
      location: "",
      sector: "",
      completion: "",
    };
  }

  initDeletePrj(project) {
    const me: any = {
      by: this.usd.acc,
      read: false,
      project: project,
    };
    if (this.usd.acc.photoURL) {
      me.photoURL = this.usd.acc.photoURL || "";
    }
    if (project.companyId) {
      try {
        this.usd.afs
          .collection<any>(
            `Enterprises/${project.companyId}/Participants/`,
            (ref) => ref.where("hierarchy", "==", "Executive").limit(3)
          )
          .valueChanges()
          .subscribe((exe) => {
            // console.log(exe, exe.length)
            if (exe.length > 0) {
              me.admin = exe;
              this.usd.afs
                .collection<any>(
                  `Enterprises/${project.companyId}/Participants/`,
                  (ref) =>
                    ref.where("hierarchy", "==", "Middle management").limit(3)
                )
                .valueChanges()
                .subscribe((ads) => {
                  // console.log(ads, ads.length);
                  if (ads.length > 0) {
                    me.admin.concat(ads);
                    this.adminDelNotify(me, project);
                  } else {
                    this.adminDelNotify(me, project);
                  }
                });
            } else {
              this.usd.afs
                .collection<any>(
                  `Enterprises/${project.companyId}/Participants/`,
                  (ref) =>
                    ref.where("hierarchy", "==", "Middle management").limit(3)
                )
                .valueChanges()
                .subscribe((ads) => {
                  // console.log(ads), ads.length);
                  if (ads.length > 0) {
                    me.admin = ads;
                    this.adminDelNotify(me, project);
                  } else {
                    this.ns.showNotification(
                      "req_deletePrjfail",
                      "top",
                      "right",
                      project
                    );
                  }
                });
            }
          });
      } catch (error) {
        // console.log(error);
      }
    } else {
      if (project.byId === this.usd.acc.id) {
      }
    }
    return {
      name: "",
      type: "",
      by: "",
      byId: "",
      companyName: "",
      companyId: "",
      champion: null,
      createdOn: "",
      id: "",
      location: "",
      sector: "",
      completion: "",
    };
  }

  adminDelNotify(man, project) {
    const admin = man.admin;
    let i = 0;
    man.noti = "PrjDeleteReq";
    admin.forEach((element) => {
      if (this.usd.userId !== element.id) {
        const mailData = {
          by: {
            id: this.usd.acc?.id,
            name: this.usd.acc?.name,
            email: this.usd.acc?.bus_email || this.usd.acc.email,
            photoUrl: this.usd.acc?.photoURL || "",
          },
          name: element.name,
          email: element?.bus_email || element.email,
          subjet: "Notice",
          text: "Notice",
        };
        this.commonMail(mailData);
        // this.commonMail(element);
        this.usd.afs
          .collection("Users")
          .doc(element.id)
          .collection("PrjDeleteReq")
          .doc(project.id)
          .set(man)
          .then(() => {
            i++;
            if (i === admin.length) {
              this.usd.afs
                .doc(`Users/${this.usd.userId}/projects/${project.id}`)
                .update({ deleteInit: true });
              // console.log(i);
              this.ns.showNotification(
                "req_deletePrj",
                "top",
                "right",
                project
              );
            }
          });
      }
    });
  }

  cancelPrjDelete(man) {
    const project = man.project;
    const admin = man.admin;
    let i = 0;
    admin.forEach((element) => {
      // if (this.usd.userId !== element.id) {
      const mailData = {
        by: {
          id: this.usd.acc?.id,
          name: this.usd.acc?.name,
          email: this.usd.acc?.bus_email || this.usd.acc.email,
          photoUrl: this.usd.acc?.photoURL || "",
        },
        name: element.name,
        email: element?.bus_email || element.email,
        subjet: "Notice",
        text: "Notice",
      };
      this.commonMail(mailData);
      // this.commonMail(element);
      this.usd.afs
        .doc(`Users/${element.id}/allNotice/${project.id}`)
        .update({ read: true })
        .then(() => {
          i++;
          if (i === admin.length) {
            this.usd.afs
              .doc(`Users/${man.id}/projects/${project.id}`)
              .update({ deleteInit: false });
            // console.log(i);
            this.ns.showNotification(
              "req_dltPrjCancel",
              "top",
              "right",
              project
            );
          }
        });
      // }
    });
    return {
      name: "",
      type: "",
      by: "",
      byId: "",
      companyName: "",
      companyId: "",
      champion: null,
      createdOn: "",
      id: "",
      location: "",
      sector: "",
      completion: "",
    };
  }
  /*  correct Algorithm to remove */
  enterExitReq(comp, stf) {
    const exitStaff: any = {
      name: stf.name,
      id: stf.id,
      address: stf.address || "",
      phoneNumber: stf.phoneNumber || "",
      photoURL: stf.photoURL || "",
      bus_email: stf.bus_email || "",
      nationality: stf.nationality || "",
      nationalId: stf.nationalId || "",
      company: comp,
      read: false,
    };
    if (stf.id === this.usd.acc.id) {
    } else {
      exitStaff.exitInit = this.usd.acc;
    }
    this.usd.afs
      .collection<any>(`Enterprises/${comp.id}/Participants/`, (ref) =>
        ref.where("hierarchy", "==", "Executive").limit(5)
      )
      .valueChanges()
      .subscribe((users) => {
        if (users.length > 0) {
          exitStaff.admin = users;
          this.usd.afs
            .collection<any>(`Enterprises/${comp.id}/Participants/`, (ref) =>
              ref.where("hierarchy", "==", "Middle management").limit(3)
            )
            .valueChanges()
            .subscribe((ads) => {
              // console.log(ads, ads.length);
              if (ads.length > 0) {
                exitStaff.admin.concat(ads);
                this.adNotice(exitStaff);
              } else {
                this.adNotice(exitStaff);
              }
            });
        } else {
          this.usd.afs
            .collection<any>(`Enterprises/${comp.id}/Participants/`, (ref) =>
              ref.where("hierarchy", "==", "Middle management").limit(3)
            )
            .valueChanges()
            .subscribe((ads) => {
              // console.log(ads, ads.length);
              if (ads.length > 0) {
                exitStaff.admin = ads;
                this.adNotice(exitStaff);
              } else {
                this.usd.afs
                  .collection<any>(
                    `Enterprises/${comp.id}/Participants/`,
                    (ref) => ref.limit(3)
                  )
                  .valueChanges()
                  .subscribe((jsStf) => {
                    if (jsStf.length > 0) {
                      exitStaff.admin = ads;
                      this.adNotice(exitStaff);
                    } else if (comp.champion && comp.champion?.id) {
                      exitStaff.admin = [comp.champion];
                      this.adNotice(exitStaff);
                    } else if (comp.byId) {
                      exitStaff.admin = [{ id: comp.byId, name: comp.by }];
                      this.adNotice(exitStaff);
                    }
                  });
              }
            });
        }
      });
    console.log("enterExitReq");
  }

  adNotice(man) {
    man.noti = "employeeExits";
    const ads = man.admin;
    let i = 0;
    if (ads && ads.length > 0) {
      ads.forEach((element) => {
        const mailData = {
          by: {
            id: this.usd.acc?.id,
            name: this.usd.acc?.name,
            email: this.usd.acc?.bus_email || this.usd.acc.email,
            photoUrl: this.usd.acc?.photoURL || "",
          },
          name: element.name,
          email: element?.bus_email || element.email,
          subjet: "Notice",
          text: "Notice",
        };
        this.commonMail(mailData);
        // this.commonMail(element);
        if (element.id !== this.usd.userId) {
          this.usd.afs
            .doc(`Users/${element.id}/allNotice/${man.company.id}`)
            .set(man);
          if (i === ads.length) {
            this.usd.afs
              .doc(`Users/${man.id}/myenterprises/${man.company.id}`)
              .update({ exitInit: true });
          }
        }
        i++;
      });
    } else {
    }
  }

  enterExitInit(comp, stf) {
    const dataId = this.usd.userId + moment().format("DDDDYYYY");
    const me: any = {
      exitor: {
        name: stf.name, id: stf.id, address: stf.address || "", phoneNumber: stf.phoneNumber || "",
        photoURL: stf.photoURL || "", bus_email: stf.bus_email || "", nationality: stf.nationality || "",
        nationalId: stf.nationalId || "",
      },
      company: comp,
      read: false,
      dataId: dataId,
      by: {
        name: this.usd.acc.name, id: this.usd.acc.id, address: this.usd.acc.address || "",
        phoneNumber: this.usd.acc.phoneNumber || "", photoURL: this.usd.acc.photoURL || "",
        bus_email: this.usd.acc.bus_email || "", nationality: this.usd.acc.nationality || "",
        nationalId: this.usd.acc.nationalId || "",
      },
    };
    this.usd.afs
      .collection<any>(`Enterprises/${comp.id}/Participants/`, (ref) =>
        ref.where("hierarchy", "==", "Executive").limit(5)
      )
      .valueChanges()
      .subscribe((users) => {
        if (users.length > 0) {
          me.admin = users;
          this.usd.afs
            .collection<any>(`Enterprises/${comp.id}/Participants/`, (ref) =>
              ref.where("hierarchy", "==", "Middle management").limit(3)
            )
            .valueChanges()
            .subscribe((ads) => {
              // console.log(ads, ads.length);
              if (ads.length > 0) {
                me.admin.concat(ads);
                this.adInitNotice(me);
              } else {
                this.adInitNotice(me);
              }
            });
        } else {
          this.usd.afs
            .collection<any>(`Enterprises/${comp.id}/Participants/`, (ref) =>
              ref.where("hierarchy", "==", "Middle management").limit(3)
            )
            .valueChanges()
            .subscribe((ads) => {
              // console.log(ads, ads.length);
              if (ads.length > 0) {
                me.admin = ads;
                this.adInitNotice(me);
              } else {
                this.usd.afs
                  .collection<any>(
                    `Enterprises/${comp.id}/Participants/`,
                    (ref) => ref.limit(3)
                  )
                  .valueChanges()
                  .subscribe((jsStf) => {
                    if (jsStf.length > 0) {
                      me.admin = ads;
                      this.adInitNotice(me);
                    } else if (comp.champion && comp.champion?.id) {
                      me.admin = [comp.champion];
                      this.adInitNotice(me);
                    } else if (comp.byId) {
                      me.admin = [{ id: comp.byId, name: comp.by }];
                      this.adInitNotice(me);
                    }
                  });
              }
            });
        }
      });
    console.log("enterExitReq");
  }

  adInitNotice(man) {
    man.noti = "employeeExitsInit";
    const ads = man.admin;
    let i = 0;
    if (ads && ads.length > 0) {
      ads.forEach((element) => {
        const mailData = {
          by: {
            id: this.usd.acc?.id,
            name: this.usd.acc?.name,
            email: this.usd.acc?.bus_email || this.usd.acc.email,
            photoUrl: this.usd.acc?.photoURL || "",
          },
          name: element.name,
          email: element?.bus_email || element.email,
          subjet: "Notice",
          text: "Notice",
        };
        this.commonMail(mailData);
        // this.commonMail(element);
        if (element.id !== this.usd.userId) {
          this.usd.afs
            .doc(`Users/${element.id}/allNotice/${man.dataId}`)
            .set(man);
          if (i === ads.length) {
            this.usd.afs
              .doc(`Users/${man.exitor.id}/myenterprises/${man.company.id}`)
              .update({ exitInit: true })
              .catch((er) => console.log(er));
          }
        }
        i++;
      });
    } else {
    }
  }

  exitInitEnterDecline(user) {
    console.log(user);

    const mailData = {
      by: {
        id: this.usd.acc?.id,
        name: this.usd.acc?.name,
        email: this.usd.acc?.bus_email || this.usd.acc.email,
        photoUrl: this.usd.acc?.photoURL || "",
      },
      name: user.name,
      email: user?.bus_email || user.email,
      subjet: "Notice",
      text: "Notice",
    };
    this.commonMail(mailData);
    // this.commonMail(user);
    if (!user.exitor) {
      user.exitor = {
        name: user.name,
        id: user.id,
      };
    }
    this.usd.afs
      .doc(`Users/${user.exitor.id}/myenterprises/${user.company.id}`)
      .update({ exitInit: false })
      .then(() => {
        const ads = user.admin;
        let i = 0;
        if (ads && ads.length > 0) {
          ads.forEach((element) => {
            const mailData = {
              by: {
                id: this.usd.acc?.id,
                name: this.usd.acc?.name,
                email: this.usd.acc?.bus_email || this.usd.acc.email,
                photoUrl: this.usd.acc?.photoURL || "",
              },
              name: element.name,
              email: element?.bus_email || element.email,
              subjet: "Notice",
              text: "Notice",
            };
            this.commonMail(mailData);
            // this.commonMail(element);
            if (!user.dataId) {
              user.dataId = user.company.id;
            }
            this.usd.afs
              .doc(`Users/${element.id}/allNotice/${user.dataId}`)
              .update({ read: true })
              .catch((er) => console.log(er));
            if (i === ads.length) {
              this.usd.afs
                .doc(`Users/${user.exitor.id}/myenterprises/${user.company.id}`)
                .update({ exitInit: true });
            }
            i++;
          });
          // console.log('User Exited');
        }
      });
  }

  exitInitEnterApproval(user) {
    this.usd.afs
      .doc(`Users/${user.id}/myenterprises/${user.company.id}`)
      .update({ left: true })
      .then(() => {
        const mailData = {
          by: {
            id: this.usd.acc?.id,
            name: this.usd.acc?.name,
            email: this.usd.acc?.bus_email || this.usd.acc.email,
            photoUrl: this.usd.acc?.photoURL || "",
          },
          name: user.name,
          email: user?.bus_email || user.email,
          subjet: "Notice",
          text: "Notice",
        };
        this.commonMail(mailData);
        // this.commonMail(user);
        const ads = user.admin;
        if (ads && ads.length > 0) {
          ads.forEach((element) => {
            const mailDatan = {
              by: {
                id: this.usd.acc?.id,
                name: this.usd.acc?.name,
                email: this.usd.acc?.bus_email || this.usd.acc.email,
                photoUrl: this.usd.acc?.photoURL || "",
              },
              name: element.name,
              email: element?.bus_email || element.email,
              subjet: "Notice",
              text: "Notice",
            };
            this.commonMail(mailDatan);
            // this.commonMail(element);
            if (!user.dataId) {
              user.dataId = user.company.id;
            }
            this.usd.afs
              .doc(`Users/${element.id}/allNotice/${user.dataId}`)
              .update({ read: true })
              .catch((er) => console.log(er));
          });
          // console.log('User Exited');
        }
        this.usd.afs
          .doc(`Enterprises/${user.company.id}/Participants/${user.id}`)
          .update({ left: true });
        // console.log('User Exited');
      });
  }

  exitEnterApproval(user) {
    user.approver = this.usd.acc;
    if (!user.exitor) {
      user.exitor = {
        name: user.name,
        id: user.id,
      };
    }
    this.usd.afs
      .doc(
        `Users/${this.usd.acc.id}/allNotice/${user.dataId || user.company.id}`
      )
      .update({ read: true })
      .then(() => {
        this.usd.totalNotices = this.usd?.totalNotices.filter(
          (x) =>
            x.dataId !== user?.dataId || x.company?.id !== user?.company?.id
        );
        // console.log(`read set true`);
      })
      .catch((err) => console.log(err));
    this.clearStaffData(user);
    this.usd.afs
      .doc(`Users/${user.exitor.id}/myenterprises/${user.company.id}`)
      .update({ left: true })
      .then(() => {
        const mailData = {
          by: {
            id: user?.id,
            name: user?.name,
            email: user?.bus_email || user?.acc.email,
            photoUrl: user?.photoURL || "",
          },
          name: user.name,
          email: user.email,
          subjet: "Notice",
          text: "Notice",
        };
        this.commonMail(mailData);
        // this.commonMail(user);
        // console.log('User Exited');
      })
      .catch((er) => console.log(er));
  }

  clearStaffData(user) {
    this.usd.afs
      .doc(`Enterprises/${user.company.id}/Participants/${user.id}`)
      .update({ left: true })
      .then(() => {
        // console.log('Participants', user.name, 'set left');
      })
      .catch((er) => {
        // console.log(er);
      });
    const ads = user.admin;
    if (ads && ads.length > 0) {
      ads.forEach((element) => {
        const mailData = {
          by: {
            id: this.usd.acc?.id,
            name: this.usd.acc?.name,
            email: this.usd.acc?.bus_email || this.usd.acc.email,
            photoUrl: this.usd.acc?.photoURL || "",
          },
          name: element.name,
          email: element?.bus_email || element.email,
          subjet: "Notice",
          text: "Notice",
        };
        this.commonMail(mailData);
        // this.commonMail(element);
        if (!user.dataId) {
          user.dataId = user.company.id;
        }
        const docPath = this.usd.afs.doc(
          `Users/${element.id}/allNotice/${user.dataId || user.company.id}`
        );
        docPath.ref.get().then((doc) => {
          if (doc.exists) {
            docPath.update({ read: true });
          }
        });
      });
      // console.log('User Exited');
    }
    this.usd.afs
      .collection(`Enterprises/${user.company.id}/Participants`)
      .valueChanges()
      .subscribe((allStaff) => {
        user.noti = "employeeExited";
        allStaff.forEach((element: any) => {
          this.usd.afs
            .doc(`Users/${element.id}/allNotice/${user.company.id}`)
            .set(user);
        });
      });
  }

  ackleftStaff(app) {
    this.usd.afs
      .doc(`Users/${this.usd.acc.id}/allNotice/${app.company.id}`)
      .update({ read: true })
      .then(() => {
        this.usd.totalNotices = this.usd?.totalNotices.filter(
          (x) => x.company.id !== app.company.id
        );
      });
  }

  exitEnterDecline(user) {
    console.log(user);
    if (!user.exitor) {
      user.exitor = {
        name: user.name,
        id: user.id,
      };
    }
    this.usd.afs
      .doc(
        `Users/${this.usd.acc.id}/allNotice/${user.dataId || user.company.id}`
      )
      .update({ read: true })
      .then(() => {
        this.usd.totalNotices = this.usd?.totalNotices.filter(
          (x) =>
            x.dataId !== user.dataId || x?.company?.id !== user?.company?.id
        );
      });
    this.usd.afs
      .doc(`Users/${user.exitor.id}/myenterprises/${user.company.id}`)
      .update({ exitInit: false })
      .then(() => {
        const mailData = {
          by: {
            id: this.usd.acc?.id,
            name: this.usd.acc?.name,
            email: this.usd.acc?.bus_email || this.usd.acc.email,
            photoUrl: this.usd.acc?.photoURL || "",
          },
          name: user.name,
          email: user?.bus_email || user.email,
          subjet: "Notice",
          text: "Notice",
        };
        this.commonMail(mailData);
        // this.commonMail(user);
        const ads = user.admin;
        if (ads && ads.length > 0) {
          ads.forEach((element) => {
            const mailData = {
              by: {
                id: this.usd.acc?.id,
                name: this.usd.acc?.name,
                email: this.usd.acc?.bus_email || this.usd.acc.email,
                photoUrl: this.usd.acc?.photoURL || "",
              },
              name: element.name,
              email: element?.bus_email || element.email,
              subjet: "Notice",
              text: "Notice",
            };
            this.commonMail(mailData);
            // this.commonMail(element);
            if (!user.dataId) {
              user.dataId = user.company.id;
            }
            const docPath = this.usd.afs.doc(
              `Users/${element.id}/allNotice/${user.dataId || user.company.id}`
            );
            docPath.ref.get().then((doc) => {
              if (doc.exists) {
                docPath.update({ read: true });
              }
            });
          });
          // console.log('User Exited');
        }
        // console.log('User Exited');
      });
  }

  deleteComp(company) {
    this.usd.afs
      .doc(`Users/${this.usd.userId}/myenterprises/${company.id}`)
      .delete()
      .then(() => {
        this.usd.afs.doc(`Enterprises/${company.id}`).delete();
        // console.log('User Exited');
      });
  }

  acceptRequest(applicant) {
    const companyId = applicant.company.id;
    const deptId = applicant.department.id;
    console.log(applicant);
    const man: any = {
      name: applicant.name, email: applicant.email || "", departmentId: applicant.department.id, department: applicant.department.name || "",
      bus_email: applicant.bus_email || "", id: applicant.id, phoneNumber: applicant.phoneNumber || "",
      address: applicant.address || "", photoURL: applicant.photoURL || "", nationalId: applicant.nationalId || "", nationality: applicant.nationality || "",
    };
    console.log(man, companyId, applicant);
    const company = applicant.company;
    console.log(company);
    const cmpData = { id: company.id, name: company.name };
    const userDoc = this.usd.afs.collection("Users").doc(man.id);
    const mailData = {
      by: {
        id: this.usd.acc?.id,name: this.usd.acc?.name, email: this.usd.acc?.bus_email || this.usd.acc.email, photoUrl: this.usd.acc?.photoURL || "",
      },
      name: man.name, email: man?.bus_email || man.email, subjet: "Notice", text: "Notice",
    };
    this.commonMail(mailData);
    // this.commonMail(man);
    userDoc.collection("myenterprises").doc(companyId).set(cmpData);
    console.log("company set under User Doc");
    const compReff = this.usd.afs.doc(`Enterprises/${companyId}`);
    compReff.update(company);
    console.log("company set under compReff Doc");
    console.log("company deptId ==>" + deptId);
    compReff.collection("Participants").doc(man.id).set(man);
    if (deptId) {
      this.usd.afs.doc(`Enterprises/${companyId}/Requests/${man.id}`).delete();
      compReff.collection(`departments/${deptId}/Participants`).doc(man.id).set(man);
    }
    this.usd.afs.doc(`Users/${man.id}/enterprisesRequested/${companyId}`).delete();
    this.usd.afs.doc(`Users/${this.usd.acc?.id}/allNotice/${man.id}`).update({ read: true }).then(() => {
      this.usd.totalNotices = this.usd?.totalNotices.filter((x) => x.id !== man.id);
      compReff.collection("Participants").valueChanges().subscribe((emp) => {
        man.acceptor = { name: this.usd.acc.name, id: this.usd.acc.id };
        const filterdemp = emp.filter(i => i.id !== this.usd.acc.id)
          // emp.forEach((element) => {
        filterdemp.forEach((element) => {
          this.notifyWorkMates(element, man, cmpData);
        });
      });
      // this.resetForm();
    });
  }

  notifyWorkMates(mate, newMan, company) {
    newMan.company = company;
    newMan.read = false;
    newMan.noticeDate = moment().toISOString();
    console.log(mate, mate.id, newMan.dataId);
    const mailData = {
      by: {
        id: this.usd.acc?.id, name: this.usd.acc?.name, email: this.usd.acc?.bus_email || this.usd.acc.email,
        photoUrl: this.usd.acc?.photoURL || "",
      },
      name: mate.name, email: mate.bus_email || newMan.email, subjet: "Notice", text: "Notice",
    };
    newMan.noti = "staffAddNotice";
    this.commonMail(mailData);
    // this.commonMail(newMan);
    if (newMan.dataId && mate.id !== mailData.by.id) {
      this.usd.afs.doc(`Users/${mate.id}/allNotice/${newMan.dataId}`).set(newMan).then(() => console.log(`${mate.name}, notified`))
        .catch((er) => {
          // console.log(er);
        });
    } else {
      newMan.dataId = this.usd.userId + moment().format("DDDDYYYY");
      this.usd.afs.doc(`Users/${mate.id}/allNotice/${newMan.dataId}`).set(newMan).then(() => console.log(`${mate.name}, notified`)).catch((er) => { /* console.log(er); */  });
    }
  }

  /* Corrected 22/02/2022 01:16 PM */

  ackNewMates(mate) {
    console.log(mate.dataId, mate.noti);
    this.is.months.forEach((element) => {
      if (mate.dataId) {
        const incFound = mate?.dataId?.includes(element);
        if (mate.dataId && incFound) {
          // console.log(incFound);
          const rev = this.is.rewriteData(mate.dataId, element);
          mate.dataId = rev;
          // console.log(mate.dataId, rev);
          if (rev) {
            this.usd.afs.doc(`Users/${this.usd.userId}/allNotice/${rev}`).update({ read: true, dataId: rev }).then(() => {
              this.usd.totalNotices = this.usd?.totalNotices.filter(
                (x) => x.dataId !== rev
              );
              // console.log(`notice read`);
            }).catch((er) => { /* console.log(er); */ });
          } else {
            this.usd.afs.doc(`Users/${this.usd.userId}/allNotice/${mate.dataId}`).update({ read: true }).then(() => {
              this.usd.totalNotices = this.usd?.totalNotices.filter(
                (x) => x.dataId !== mate.dataId
              );
              // console.log(`notice read`)
            }).catch((er) => { /* console.log(er); */ });
          }
        } else {
          this.usd.afs.doc(`Users/${this.usd.userId}/allNotice/${mate.dataId}`).update({ read: true }).then(() => {
            this.usd.totalNotices = this.usd?.totalNotices.filter(
              (x) => x.dataId !== mate.dataId
            );
            // console.log(`notice read`);
          }).catch((er) => { /* console.log(er); */ });
        }
      } else {
        this.usd.afs
          .doc(`Users/${this.usd.userId}/allNotice/${mate.id}`)
          .update({ read: true })
          .then(() => {
            this.usd.totalNotices = this.usd?.totalNotices.filter(
              (x) => x.dataId !== mate.dataId
            );
            // console.log(`notice read`);
          })
          .catch((er) => {
            // console.log(er);
            let nd;
            this.usd.afs
              .doc(`Users/${this.usd.userId}/allNotice/${nd}`)
              .update({ read: true })
              .then(() => {
                this.usd.totalNotices = this.usd?.totalNotices.filter(
                  (x) => x.dataId !== mate.dataId
                );
                // console.log(`resolve undefined notice read`);
              })
              .catch((error) => {
                // console.log(error);
              });
          });
      }
    });
  }

  ackMates(element, mate) {
    console.log(mate.dataId, mate.noti);
    this.is.months.forEach((elem) => {
      const incFound = mate.dataId.includes(elem);
      if (incFound) {
        // console.log(incFound);
        const rev = this.is.rewriteData(mate.dataId, elem);
        mate.dataId = rev;
        // console.log(mate.dataId, rev);
        if (rev) {
          this.usd.afs
            .doc(`Users/${element.id}/allNotice/${rev}`)
            .update({ read: true, dataId: rev })
            .then(() => console.log(`notice read`))
            .catch((er) => {
              // console.log(er);
              mate.read = true;
              this.usd.afs
                .doc(`Users/${element.id}/allNotice/${rev}`)
                .set(mate)
                .then(() => console.log("document resolved"));
            });
        } else {
          this.usd.afs
            .doc(`Users/${element.id}/allNotice/${undefined}`)
            .delete()
            .then(() => console.log(`notice read`));
          this.usd.afs
            .doc(`Users/${element.id}/allNotice/${mate.dataId}`)
            .update({ read: true })
            .then(() => console.log(`notice read`))
            .catch((er) => {
              // console.log(er);
              mate.read = true;
              this.usd.afs
                .doc(`Users/${element.id}/allNotice/${mate.dataId}`)
                .set(mate)
                .then(() => console.log("document resolved"));
            });
        }
        this.usd.afs
          .doc(`Users/${element.id}/allNotice/${undefined}`)
          .delete()
          .then(() => console.log(`notice read`));
      }
    });
  }

  killNoti(staff, mate) {
    staff.forEach((element) => {
      this.ackMates(element, mate);
    });
  }

  exitUserPrjReq(prj) {
    console.log(prj);
    const dat = new Date().toString();
    const dataId = this.usd.userId + moment().format("DDDDYYYY");
    const applicant: any = {
      usr: {
        name: this.usd.acc.name || "",
        id: this.usd.acc.id,
        address: this.usd.acc.address || "",
        phoneNumber: this.usd.acc.phoneNumber || "",
        bus_email: this.usd.acc.bus_email || "",
        nationality: this.usd.acc.nationality || "",
        nationalId: this.usd.acc.nationalId || "",
        photoURL: this.usd.acc.photoURL || "",
      },
      dataId: dataId,
      project: prj,
      date: dat,
      noticeDate: moment().toISOString(),
      company: { name: prj.companyName, id: prj.companyId },
      read: false,
    };
    this.usd.afs
      .collection<any>(`Enterprises/${prj.companyId}/Participants/`, (ref) =>
        ref.where("hierarchy", "==", "Executive").limit(3)
      )
      .valueChanges()
      .subscribe((exe) => {
        // console.log(exe);
        let admin = [];
        // console.log(exe.length);
        if (exe.length > 0) {
          admin = exe;
          applicant.admin = admin;
          this.usd.afs
            .collection<any>(
              `Enterprises/${prj.companyId}/Participants/`,
              (ref) =>
                ref.where("hierarchy", "==", "Middle management").limit(3)
            )
            .valueChanges()
            .subscribe((ads) => {
              // console.log(ads);
              // console.log(ads.length);
              if (ads.length > 0) {
                admin.concat(ads);
                applicant.admin = admin;
                this.sendPrjUserNotice(applicant, "Exit initiated");
              } else {
                this.sendPrjUserNotice(applicant, "Exit initiated");
              }
            });
        } else {
          // let admin;
          this.usd.afs
            .collection<any>(
              `Enterprises/${prj.companyId}/Participants/`,
              (ref) =>
                ref.where("hierarchy", "==", "Middle management").limit(3)
            )
            .valueChanges()
            .subscribe((ads) => {
              // console.log(ads);
              // console.log(ads.length);
              if (ads.length > 0) {
                admin = ads;
                applicant.admin = admin;
                this.sendPrjUserNotice(applicant, "Exit initiated");
              } else {
                this.usd.afs
                  .collection<any>(
                    `Enterprises/${prj.companyId}/Participants/`,
                    (ref) => ref.limit(10)
                  )
                  .valueChanges()
                  .subscribe((ds) => {
                    // console.log(ds);
                    // console.log(ds.length);
                    if (ds.length > 0) {
                      admin = ds;
                      applicant.admin = admin;
                      this.sendPrjUserNotice(applicant, "Exit initiated");
                    }
                  });
              }
            });
        }
      });
  }

  prjUserExitNotice(amte, app) {
    const mailData = {
      by: {
        id: this.usd.acc?.id,
        name: this.usd.acc?.name,
        email: this.usd.acc?.bus_email || this.usd.acc.email,
        photoUrl: this.usd.acc?.photoURL || "",
      },
      name: amte.name,
      email: amte.bus_email || amte.email,
      subjet: "Notice",
      text: "Notice",
    };
    this.commonMail(mailData);
    app.noti = "prjExitNots";
    // this.commonMail(amte);
    this.usd.afs
      .doc(`Users/${amte.id}/allNotice/${app.dataId}`)
      .set(app)
      .then(() => console.log(`${app.usr.name}, notified`))
      .catch((er) => {
        // console.log(er);
      });
  }

  sendPrjUserNotice(app, com) {
    if (app.admin.length > 0) {
      for (let index = 0; index < app.admin.length; index++) {
        const element = app.admin[index];
        this.prjUserExitNotice(element, app);
        if (index + 1 === app.admin.length) {
          this.usd.afs
            .doc(`Users/${this.usd.userId}/projects/${app.project.id}`)
            .update({ exitStatus: true, exitText: com });
        }
      }
    } else {
    }
  }

  exitEntPrjReq(prj) {
    console.log(prj);
    const dat = new Date().toString();
    const dataId = this.usd.userId + moment().format("DDDDYYYY");
    const applicant: any = {
      usr: this.usd.acc,
      dataId: dataId,
      project: prj,
      company: { name: prj.companyName, id: prj.companyId },
      noticeDate: moment().toISOString(),
      read: false,
    };
    this.usd.afs
      .collection<any>(`Enterprises/${prj.companyId}/Participants/`, (ref) =>
        ref.where("hierarchy", "==", "Executive").limit(3)
      )
      .valueChanges()
      .subscribe((exe) => {
        // console.log(exe);
        let admin = [];
        // console.log(exe.length);
        if (exe.length > 0) {
          admin = exe;
          applicant.admin = admin;
          this.usd.afs
            .collection<any>(
              `Enterprises/${prj.companyId}/Participants/`,
              (ref) =>
                ref.where("hierarchy", "==", "Middle management").limit(3)
            )
            .valueChanges()
            .subscribe((ads) => {
              // console.log(ads);
              // console.log(ads.length);
              if (ads.length > 0) {
                admin.concat(ads);
                applicant.admin = admin;
                this.sendNotice(applicant, "Company Exit initiated");
              } else {
                this.sendNotice(applicant, "Company Exit initiated");
              }
            });
        } else {
          // let admin;
          this.usd.afs
            .collection<any>(
              `Enterprises/${prj.companyId}/Participants/`,
              (ref) =>
                ref.where("hierarchy", "==", "Middle management").limit(3)
            )
            .valueChanges()
            .subscribe((ads) => {
              // console.log(ads);
              // console.log(ads.length);
              if (ads.length > 0) {
                admin = ads;
                applicant.admin = admin;
                this.sendNotice(applicant, "Company Exit initiated");
              } else {
                this.usd.afs
                  .collection<any>(
                    `Enterprises/${prj.companyId}/Participants/`,
                    (ref) => ref.limit(10)
                  )
                  .valueChanges()
                  .subscribe((ds) => {
                    // console.log(ds);
                    // console.log(ds.length);
                    if (ds.length > 0) {
                      admin = ds;
                      applicant.admin = admin;
                      this.sendNotice(applicant, "Company Exit initiated");
                    }
                  });
              }
            });
        }
      });
  }

  companyExitNotice(amte, app) {
    const mailData = {
      by: {
        id: this.usd.acc?.id,
        name: this.usd.acc?.name,
        email: this.usd.acc?.bus_email || this.usd.acc.email,
        photoUrl: this.usd.acc?.photoURL || "",
      },
      name: amte.name,
      email: amte?.bus_email || amte.email,
      subjet: "Notice",
      text: "Notice",
    };
    this.commonMail(mailData);
    // this.commonMail(amte);
    app.noti = "prjEntExitNots";
    this.usd.afs
      .doc(`Users/${amte.id}/allNotice/${app.dataId}`)
      .set(app)
      .then(() => console.log(`${app.usr.name}, notified`))
      .catch((er) => {
        // console.log(er);
      });
  }

  sendNotice(app, com) {
    if (app.admin.length > 0) {
      for (let index = 0; index < app.admin.length; index++) {
        const element = app.admin[index];
        this.companyExitNotice(element, app);
        if (index + 1 === app.admin.length) {
          this.usd.afs
            .doc(`Users/${this.usd.userId}/projects/${app.project.id}`)
            .update({ exitStatus: true, exitText: com });
        }
      }
    } else {
    }
  }

  declineCompPrjExit(app) {
    if (app.admin.length > 0) {
      for (let index = 0; index < app.admin.length; index++) {
        const element = app.admin[index];
        this.companyExitDeclineNotice(element, app);
        if (index + 1 === app.admin.length) {
          this.usd.afs
            .doc(`Users/${app.usr.id}/projects/${app.project.id}`)
            .update({
              exitStatus: false,
              exitText: "Company Exit project Declined",
            });
        }
      }
    } else {
    }
  }

  companyExitDeclineNotice(amte, app) {
    const mailData = {
      by: {
        id: this.usd.acc?.id,
        name: this.usd.acc?.name,
        email: this.usd.acc?.bus_email || this.usd.acc.email,
        photoUrl: this.usd.acc?.photoURL || "",
      },
      name: amte.name,
      email: amte.bus_email || amte.email,
      subjet: "Notice",
      text: "Notice",
    };
    this.commonMail(mailData);
    // this.commonMail(amte);
    this.usd.afs
      .doc(`Users/${amte.id}/allNotice/${app.dataId}`)
      .update({ read: true })
      .then(() => console.log(`${app.usr.name}, notice closed`))
      .catch((er) => {
        // console.log(er);
      });
  }

  approveCompPrjExit(app) {
    app.approver = this.usd.acc;

    const mailData = {
      by: {
        id: this.usd.acc?.id,
        name: this.usd.acc?.name,
        email: this.usd.acc?.bus_email || this.usd.acc.email,
        photoUrl: this.usd.acc?.photoURL || "",
      },
      name: app.usr.name,
      email: app.usr?.bus_email || app.usr.email,
      subjet: "Notice",
      text: "Notice",
    };
    this.commonMail(mailData);
    // this.commonMail(app.usr);
    if (app.admin.length > 0) {
      for (let index = 0; index < app.admin.length; index++) {
        const member = app.admin[index];
        this.companyExitApprovedNotice(member, app);
        if (index + 1 === app.admin.length) {
          this.usd.afs
            .doc(`Users/${this.usd.userId}/projects/${app.project.id}`)
            .update({ exitStatus: true, exitText: app.company.name });
          this.usd.afs
            .collection<ParticipantData>(
              `Project/${app.project.id}/enterprises/${app.company.id}/labour`
            )
            .valueChanges()
            .subscribe((labour) => {
              let c = 0;
              labour.forEach((element) => {
                this.usd.afs
                  .doc(`Users/${element.id}/projects/${app.project.id}`)
                  .delete()
                  .then(() => c++);
                if (c === labour.length) {
                  this.usd.afs
                    .doc(
                      `Project/${app.project.id}/enterprises/${app.company.id}`
                    )
                    .delete();
                  this.usd.afs
                    .doc(
                      `Enterprises/${app.company.id}/projects/${app.project.id}`
                    )
                    .delete();
                }
              });
            });
          this.usd.afs
            .collection<ParticipantData>(
              `Enterprises/${app.company.id}/Participants`
            )
            .valueChanges()
            .subscribe((staff) => {
              staff.forEach((element) => {
                this.companystaffPrjNotice(element, app);
              });
            });
        }
      }
    } else {
    }
  }

  ackCompanystaffPrjNotice(app) {
    this.usd.afs
      .doc(`Users/${this.usd.userId}/CompProExit/${app.dataId}`)
      .update({ read: true })
      .then(() => {
        this.usd.totalNotices = this.usd?.totalNotices.filter(
          (x) => x.dataId !== app.dataId
        );
        // console.log(`${app.usr.name},staff notice closed`);
      })
      .catch((er) => {
        // console.log(er);
      });
  }

  companystaffPrjNotice(amte, app) {
    const mailData = {
      by: {
        id: this.usd.acc?.id,
        name: this.usd.acc?.name,
        email: this.usd.acc?.bus_email || this.usd.acc.email,
        photoUrl: this.usd.acc?.photoURL || "",
      },
      name: amte.name,
      email: amte?.bus_email || amte.email,
      subjet: "Notice",
      text: "Notice",
    };
    this.commonMail(mailData);
    // this.commonMail(amte);
    this.usd.afs
      .doc(`Users/${amte.id}/CompProExit/${app.dataId}`)
      .set(app)
      .then(() => console.log(`${app.usr.name}, staff notice set`))
      .catch((er) => {
        // console.log(er);
      });
  }

  companyExitApprovedNotice(amte, app) {
    this.usd.afs
      .doc(`Users/${amte.id}/allNotice/${app.dataId}`)
      .update({ read: true })
      .then(() => console.log(`${app.usr.name}, notice closed`))
      .catch((er) => {
        // console.log(er);
      });
  }

  companyExitDsNotice(amte, app) {
    this.usd.afs
      .doc(`Users/${amte.id}/allNotice/${app.dataId}`)
      .update({ read: true })
      .then(() => {
        // console.log(`${app.usr.name}, cleard`);
        app.rep = this.usd.acc;
        app.noti = "prjExitDsNots";
        this.usd.afs
          .doc(`Users/${amte.id}/allNotice/${app.dataId}`)
          .set(app)
          .then(() => console.log(`${app.name}, notified`))
          .catch((er) => {
            // console.log(er);
          });
      })
      .catch((er) => {
        // console.log(er);
      });
  }

  exitEntAck(app) {
    this.usd.afs
      .doc(`Users/${this.usd.userId}/allNotice/${app.dataId}`)
      .update({ read: true })
      .then(() => {
        this.usd.totalNotices = this.usd?.totalNotices.filter(
          (x) => x.dataId !== app.dataId
        );
        // console.log('logged');
      });
  }

  declinePrjExt(app) {
    const dat = new Date().toString();
    const prj = app.project;
    prj.exitStatus = false;
    prj.exitText = "Declined";
    prj.exitDate = dat;
    app.decliner = this.usd.acc;
    const selectPrj = this.usd.projects.find((proj) => proj.id === prj.id);
    if (selectPrj) {
      app.declinerCompany = {
        name: selectPrj.comopanyName,
        id: selectPrj.id,
      };
    }
    const mailData = {
      by: {
        id: this.usd.acc?.id,
        name: this.usd.acc?.name,
        email: this.usd.acc?.bus_email || this.usd.acc.email,
        photoUrl: this.usd.acc?.photoURL || "",
      },
      name: app.usr.name,
      email: app.usr?.bus_email || app.usr.email,
      subjet: "Notice",
      text: "Notice",
    };
    this.commonMail(mailData);
    // this.commonMail(app.usr);
    this.usd.afs
      .doc(`Users/${this.usd.userId}/allNotice/${app.dataId}`)
      .update({ read: true })
      .then(() => {
        this.usd.totalNotices = this.usd?.totalNotices.filter(
          (x) => x.dataId !== app.dataId
        );
      });
    this.usd.afs
      .doc(`Users/${app.usr.id}/projects/${app.project.id}`)
      .update(prj)
      .then(() => {
        app.admin.forEach((element) => {
          this.companyExitDsNotice(element, app);
        });
        app.noti = "ProjectExtdeclne";
        this.usd.afs
          .doc(`Users/${app.usr.id}/allNotice/${app.dataId}`)
          .set(app)
          .then(() => {});
      });
  }

  approvePrjExt(app) {
    const dat = new Date().toString();
    const prj = app.project;
    prj.exitStatus = false;
    prj.exit = true;
    prj.exitText = "Approved";
    prj.exitDateApproval = dat;
    app.approval = this.usd.acc;
    // this.commonMail(app.usr);

    const mailData = {
      by: {
        id: this.usd.acc?.id,
        name: this.usd.acc?.name,
        email: this.usd.acc?.bus_email || this.usd.acc.email,
        photoUrl: this.usd.acc?.photoURL || "",
      },
      name: app.usr.name,
      email: app.usr?.bus_email || app.usr.email,
      subjet: "Notice",
      text: "Notice",
    };
    this.commonMail(mailData);
    this.usd.afs
      .doc(`Users/${this.usd.userId}/allNotice/${app.dataId}`)
      .update({ read: true })
      .then(() => {
        this.usd.totalNotices = this.usd?.totalNotices.filter(
          (x) => x.dataId !== app.dataId
        );
      });
    this.usd.afs
      .doc(`Users/${app.usr.id}/projects/${app.project.id}`)
      .update(prj)
      .then(() => {
        this.doacts(app);
      })
      .catch((erroer) => {
        // console.log('no doc found', erroer);
        this.usd.afs
          .doc(`Users/${app.usr.id}/projects/${app.project.id}`)
          .set(prj)
          .then(() => {
            this.doacts(app);
          });
      });
  }

  doacts(app) {
    app.admin.forEach((element) => {
      this.compExitAprvdNotice(element, app);

      const mailData = {
        by: {
          id: this.usd.acc?.id,
          name: this.usd.acc?.name,
          email: this.usd.acc?.bus_email || this.usd.acc.email,
          photoUrl: this.usd.acc?.photoURL || "",
        },
        name: element.name,
        email: element?.bus_email || element.email,
        subjet: "Notice",
        text: "Notice",
      };
      this.commonMail(mailData);
      // this.commonMail(element);
    });
    app.noti = "ProjectExtApproval";
    this.usd.afs
      .doc(`Users/${app.usr.id}/allNotice/${app.dataId}`)
      .set(app)
      .then(() => {});
    this.usd.afs
      .collection<any>(`Enterprises/${app.company.id}/Participants/`)
      .valueChanges()
      .subscribe((stf) => {
        stf.forEach((element) => {
          if (element.id !== this.usd.acc.id) {
            this.usd.afs
              .doc(`Users/${element.id}/allNotice/${app.dataId}`)
              .update({ read: true })
              .then(() => console.log(`${element.name}, notified`))
              .catch((er) => {
                // console.log(`${element.name}, ${element.id}, error notifying`, er);
              });
          }
        });
      });
  }

  teamAckPrjExt(app) {
    this.usd.afs
      .doc(`Users/${this.usd.userId}/allNotice/${app.dataId}`)
      .update({ read: true })
      .then(() => {
        this.usd.totalNotices = this.usd?.totalNotices.filter(
          (x) => x.dataId !== app.dataId
        );
        // console.log(`Team notice cleard`);
      });
  }

  ackApprovePrjExt(app) {
    this.usd.afs
      .doc(`Users/${app.usr.id}/allNotice/${app.dataId}`)
      .update({ read: true })
      .then(() => {
        console.log(`notice Read`);
      });
  }

  compExitAprvdNotice(amte, app) {
    this.usd.afs
      .doc(`Users/${amte.id}/allNotice/${app.dataId}`)
      .update({ read: true })
      .then(() => {
        // console.log(`${amte.name}, cleard`);
      })
      .catch((er) => {
        // console.log(er);
      });
    app.noti = "PrjExitApprovalNotice";
    this.usd.afs
      .doc(`Users/${amte.id}/allNotice/${app.dataId}`)
      .set(app)
      .then(() => {
        // console.log(`${amte.name}, notified`);
      })
      .catch((er) => {
        // console.log(er);
      });
  }

  staffAckPrjExt(app) {
    this.usd.afs
      .doc(`Users/${this.usd.userId}/allNotice/${app.dataId}`)
      .update({ read: true })
      .then(() => {
        // console.log(`notice Read`);
        this.usd.totalNotices = this.usd?.totalNotices.filter(
          (x) => x.dataId !== app.dataId
        );
      });
  }

  closeDecline(app) {
    this.usd.afs
      .doc(`Users/${app.usr.id}/allNotice/${app.dataId}`)
      .update({ read: true })
      .then(() => {});
  }

  declineRequest(applicant) {
    const companyId = applicant.company.id;
    const partId = applicant.id;
    this.usd.afs
      .collection("Users")
      .doc(partId)
      .collection("enterprisesRequested")
      .doc(companyId)
      .delete();
    this.usd.afs
      .collection("Enterprises")
      .doc(companyId)
      .collection("Requests")
      .doc(partId)
      .delete();
    this.usd.afs
      .collection("Users")
      .doc(this.usd.acc?.id)
      .collection("allNotice")
      .doc(partId)
      .update({ read: true })
      .then(() => {
        this.usd.totalNotices = this.usd?.totalNotices.filter(
          (x) => x.id !== partId
        );
      });

    const mailData = {
      by: {
        id: this.usd.acc?.id,
        name: this.usd.acc?.name,
        email: this.usd.acc?.bus_email || this.usd.acc.email,
        photoUrl: this.usd.acc?.photoURL || "",
      },
      name: applicant.name,
      email: applicant.email || applicant?.bus_email,
      subjet: "Notice",
      text: "Notice",
    };
    this.commonMail(mailData);
    // this.commonMail(applicant);
    // this.resetForm();
    // this.dataCall(this.usd.userId);
  }

  async emailChamp(data) {
    const task = data.task;
    //  console.log(data);
    if (
      (!task.projectId && !task.companyId) ||
      (!task.project?.id && !task.company?.id)
    ) {
      // console.log('sending in Personal'); task.projectName = 'No Project';
      task.companyName = "No Company";
    } else if (
      (!task.projectId && task.companyId) ||
      (!task.project?.id && task.company?.id)
    ) {
      task.projectName = "No Project";
      console.log(task.projectName && task.projectId);
    } else if (
      (task.projectId && task.companyId) ||
      (task.project?.id && task.company?.id)
    ) {
      // console.log(task.projectId && task.companyId);
    } else if (
      (task.projectId && !task.companyId) ||
      (task.project?.id && !task.company?.id)
    ) {
      task.companyName = "No Company";
      console.log(task.companyName && task.companyId);
    }
    console.log(task.champion?.bus_email || task.champion?.email);
    if (
      (task.champion?.bus_email || task.champion?.email) &&
      task.champion.id !== this.usd.userId
    ) {
      // const sendEmail = await this.http.tasking(' https://us-central1-colors-a07e8.cloudfunctions.net/newTask', task);
      const sendEmail = await this.http.tasking(
        " https://us-central1-colors-a07e8.cloudfunctions.net/newTask",
        data
      );
      sendEmail.subscribe(
        (data) => {
          const res: any = data;
          // console.log(res);
          console.log(
            `👏 > 👏 > 👏 > 👏 ${task.champion.name}'s new Task email sent and the message id is ${res.messageId}`
          );
        },
        (err) => {
          console.log(err);
        },
        () => {}
      );
    }
  }
  /* Project Req Mails */

  async sendCmpPrjReq(noti) {
    // console.log(noti);
    if (noti.bus_email || noti.email) {
      const sendEmail = await this.http.tasking(
        " https://us-central1-colors-a07e8.cloudfunctions.net/sendCmpPrjReq",
        noti.task
      );
      sendEmail.subscribe(
        (data) => {
          const res: any = data;
          // console.log(res);
          console.log(
            `👏 > 👏 > 👏 > 👏 ${noti.task.champion.name}'s new Task email sent and the message id is ${res.messageId}`
          );
        },
        (err) => {
          console.log(err);
        },
        () => {}
      );
    }
  }

  /* Project Req Accepted Mails */

  async sendCmpPrjReqAcc(noti) {
    // console.log(noti);
    if (noti.user.bus_email && noti.user.bus_email !== "") {
      noti.user.email = noti.user.bus_email;
    }
    if (noti.user.email) {
      const sendEmail = await this.http.tasking(
        " https://us-central1-colors-a07e8.cloudfunctions.net/sendCmpPrjReqAcc",
        noti.task
      );
      sendEmail.subscribe(
        (data) => {
          const res: any = data;
          // console.log(res);
          console.log(
            `👏 > 👏 > 👏 > 👏 ${noti.task.champion.name}'s new Task email sent and the message id is ${res.messageId}`
          );
        },
        (err) => {
          console.log(err);
        },
        () => {}
      );
    }
  }
  /* Project Req Rejected Mails */

  async sendCmpPrjReqRej(noti) {
    // console.log(noti);
    if (noti.user.bus_email && noti.user.bus_email !== "") {
      noti.user.email = noti.user.bus_email;
    }
    if (noti.user.email) {
      const sendEmail = await this.http.tasking(
        " https://us-central1-colors-a07e8.cloudfunctions.net/sendCmpPrjReqRej",
        noti.task
      );
      sendEmail.subscribe(
        (data) => {
          const res: any = data;
          // console.log(res);
          console.log(
            `👏 > 👏 > 👏 > 👏 ${noti.task.champion.name}'s new Task email sent and the message id is ${res.messageId}`
          );
        },
        (err) => {
          console.log(err);
        },
        () => {}
      );
    }
  }

  /* Project Invite Mails */
  async emailCompPrjInvite(noti) {
    // console.log(noti);
    if (noti.user.bus_email || noti.user.email) {
      const sendEmail = await this.http.tasking(
        " https://us-central1-colors-a07e8.cloudfunctions.net/emailCompPrjInvite",
        noti.task
      );
      sendEmail.subscribe(
        (data) => {
          const res: any = data;
          // console.log(res);
          console.log(
            `👏 > 👏 > 👏 > 👏 ${noti.task.champion.name}'s new Task email sent and the message id is ${res.messageId}`
          );
        },
        (err) => {
          console.log(err);
        },
        () => {}
      );
    }
  }

  /* Project Invite Accepted Mails */

  async sendCmpPrjInvAcc(noti) {
    // console.log(noti);
    if (noti.user.bus_email || noti.user.email) {
      const sendEmail = await this.http.tasking(
        " https://us-central1-colors-a07e8.cloudfunctions.net/sendCmpPrjInvAcc",
        noti
      );
      sendEmail.subscribe(
        (data) => {
          const res: any = data;
          // console.log(res);
          console.log(
            `👏 > 👏 > 👏 > 👏 ${noti.task.champion.name}'s new Task email sent and the message id is ${res.messageId}`
          );
        },
        (err) => {
          console.log(err);
        },
        () => {}
      );
    }
  }
  /* Project Invite Rejected Mails */

  async sendCmpPrjInvRej(noti) {
    // console.log(noti);
    if (noti.user.bus_email || noti.user.email) {
      const sendEmail = await this.http.tasking(
        " https://us-central1-colors-a07e8.cloudfunctions.net/sendCmpPrjInvRej",
        noti
      );
      sendEmail.subscribe(
        (data) => {
          const res: any = data;
          // console.log(res);
          console.log(
            `👏 > 👏 > 👏 > 👏 ${noti.task.champion.name}'s new Task email sent and the message id is ${res.messageId}`
          );
        },
        (err) => {
          console.log(err);
        },
        () => {}
      );
    }
  }

  /*  common notice */

  async commonMail(noti) {
    // console.log(noti);

    if (noti.bus_email && noti.bus_email !== "") {
      noti.email = noti.bus_email;
    }
    if (noti.email) {
      const sendEmail = await this.http.tasking(
        " https://us-central1-colors-a07e8.cloudfunctions.net/commonMail",
        noti
      );
      sendEmail.subscribe(
        (data) => {
          const res: any = data;
          // console.log(res);
          console.log(
            `👏 > 👏 > 👏 > 👏 ${noti.name}'s new Task email sent and the message id is ${res.messageId}`
          );
        },
        (err) => {
          console.log(err);
        },
        () => {}
      );
    }
  }

  async emailCompltdTask(noti) {
    // if (!noti.task.projectName) { noti.task.projectName = 'No Project'; }
    // if (!noti.task.companyName) { noti.task.companyName = 'No Company'; }
    if (!noti.task.projectId || !noti.task.project?.id) {
      noti.task.projectName = "No Project";
    }
    if (!noti.task.companyId || !noti.task.company?.id) {
      noti.task.companyName = "No Company";
    }
    // console.log(noti);
    const sendEmail = await this.http.sendEmail(
      " https://us-central1-colors-a07e8.cloudfunctions.net/completedTask",
      noti
    );
    sendEmail.subscribe(
      (data) => {
        const res: any = data;
        // console.log(res);
        console.log(
          `👏 > 👏 > 👏 > 👏 ${noti.name}'s Completed Task email sent and the message id is ${res.messageId}`
        );
      },
      (err) => {
        console.log(err);
      },
      () => {}
    );
  }

  async emailNewChamp(data) {
    const task = data.task;
    // console.log(task);
    // if (!task.projectId) { task.projectName = 'No Project'; }
    // if (!task.companyId) { task.companyName = 'No Company'; }
    if (!task.projectId || !task.project?.id) {
      task.projectName = "No Project";
    }
    if (!task.companyId || !task.company?.id) {
      task.companyName = "No Company";
    }
    if (task.champion.bus_email || task.champion.email) {
      const sendEmail = await this.http.tasking(
        " https://us-central1-colors-a07e8.cloudfunctions.net/reallocateTask",
        data
      );
      sendEmail.subscribe(
        (data) => {
          const res: any = data;
          // console.log(res);
          console.log(
            `👏 > 👏 > 👏 > 👏 ${task.champion.name}'s new Task email sent and the message id is ${res.messageId}`
          );
        },
        (err) => {
          console.log(err);
        },
        () => {}
      );
    }
  }

  async emailNewCmpChamp(data) {
    const task = data.task;
    // console.log(task);
    // if (!task.projectId) { task.projectName = 'No Project'; }
    // if (!task.companyId) { task.companyName = 'No Company'; }
    if (!task.projectId || !task.project?.id) {
      task.projectName = "No Project";
    }
    if (!task.companyId || !task.company?.id) {
      task.companyName = "No Company";
    }
    if (task.champion.bus_email || task.champion.email) {
      const sendEmail = await this.http.tasking(
        " https://us-central1-colors-a07e8.cloudfunctions.net/reallocateCmpTask",
        data
      );
      sendEmail.subscribe(
        (data) => {
          const res: any = data;
          // console.log(res);
          console.log(
            `👏 > 👏 > 👏 > 👏 ${task.champion.name}'s new Task email sent and the message id is ${res.messageId}`
          );
        },
        (err) => {
          // console.log(err);
        },
        () => {}
      );
    }
  }

  async emailGroup(task) {
    // console.log(task);
    if (!task.projectId || !task.project?.id) {
      task.projectName = "No Project";
    }
    if (!task.companyId || !task.company?.id) {
      task.companyName = "No Company";
    }
    if (task.champion.bus_email || task.champion.email) {
      const sendEmail = await this.http.tasking(" https://us-central1-colors-a07e8.cloudfunctions.net/emailGroupTask", task);
      sendEmail.subscribe(
        (data) => {
          const res: any = data;
          // console.log(res);
          console.log(`👏 > 👏 > 👏 > 👏 ${task.champion.name}'s new Task email sent and the message id is ${res.messageId}`);
        },
        (err) => {
          console.log(err);
        },
        () => {}
      );
    }
  }

  async sendChampemail(task) {
    // console.log(task);
    // const sendEmail = await this.http.tasking(' https://us-central1-colors-a07e8.cloudfunctions.net/psnNonColours', task);
    const sendEmail = await this.http.tasking(
      " https://us-central1-colors-a07e8.cloudfunctions.net/psnNonColours",
      task
    );
    sendEmail.subscribe(
      (data) => {
        const res: any = data;
        // console.log(res);
        console.log(`👏 > 👏 > 👏 > 👏 ${task.name}'s new Task email sent and the message id is ${res.messageId}`);
      },
      (err) => {
        console.log(err);
      },
      () => {}
    );
  }

  async sendEntChampemail(task) {
    // console.log(task);
    // const sendEmail = await this.http.tasking(' https://us-central1-colors-a07e8.cloudfunctions.net/entNonColours', task);
    const sendEmail = await this.http.tasking(
      " https://us-central1-colors-a07e8.cloudfunctions.net/entNonColours",
      task
    );
    sendEmail.subscribe(
      (data) => {
        const res: any = data;
        // console.log(res);
        console.log(`👏 > 👏 > 👏 > 👏 ${task.name}'s new Task email sent and the message id is ${res.messageId}`);
      },
      (err) => {
        console.log(err);
      },
      () => {}
    );
  }

  async sendPrjChampemail(task) {
    // console.log(task);
    // const sendEmail = await this.http.tasking(' https://us-central1-colors-a07e8.cloudfunctions.net/prjNonColours', task);
    const sendEmail = await this.http.tasking("https://us-central1-colors-a07e8.cloudfunctions.net/prjNonColours", task);
    sendEmail.subscribe((data) => {
        const res: any = data;
        // console.log(res);
        console.log(`👏 > 👏 > 👏 > 👏 ${task.name}'s new Task email sent and the message id is ${res.messageId}`);
      },
      (err) => {
        console.log(err);
      },
      () => {}
    );
  }

  async sendGgl(user) {
    /* providerId google */
    // console.log(user);
    const mailData = {
      by: {
        id: this.usd.acc?.id, name: this.usd.acc?.name, email: this.usd.acc?.bus_email || this.usd.acc.email,
        photoUrl: this.usd.acc?.photoURL || "", 
      },
      user: user, name: user.name, email: user?.bus_email || user.email,
      subjet: "Notice", text: "Notice",
    };
    const sendEmail = await this.http.tasking("https://us-central1-colors-a07e8.cloudfunctions.net/logGgl", mailData);
    sendEmail.subscribe(
      (data) => {
        const res: any = data;
        // console.log(res);
        console.log(`👏 > 👏 > 👏 > 👏 ${mailData.name}'s email sent and the message id is ${res.messageId}`);
      },
      (err) => {
        console.log(err);
      },
      () => {}
    );
  }

  async sendGglPas(user) {
    /* providerId google */
    // console.log(user);
    const mailData = {
      by: {
        id: this.usd.acc?.id, name: this.usd.acc?.name, email: this.usd.acc?.bus_email || this.usd.acc.email,
        photoUrl: this.usd.acc?.photoURL || "",
      },
      user: user, name: user.name, email: user?.bus_email || user.email, subjet: "Notice",
      text: "Notice",
    };
    const sendEmail = await this.http.tasking("https://us-central1-colors-a07e8.cloudfunctions.net/logGglPas", user);
    sendEmail.subscribe(
      (data) => {
        const res: any = data;
        // console.log(res);
        console.log(`👏 > 👏 > 👏 > 👏 ${user.name}'s email sent and the message id is ${res.messageId}`);
      },
      (err) => {
        console.log(err);
      },
      () => {}
    );
  }

  async sendPas(user) {
    /* providerId google */
    // console.log(user);
    const mailData = {
      by: {
        id: this.usd.acc?.id, name: this.usd.acc?.name,
        email: this.usd.acc?.bus_email || this.usd.acc.email,
        photoUrl: this.usd.acc?.photoURL || "",
      },
      user: user, name: user.name, email: user?.bus_email || user.email,
      subjet: "Notice", text: "Notice",
    };
    const sendEmail = await this.http.tasking("https://us-central1-colors-a07e8.cloudfunctions.net/logPas", user);
    sendEmail.subscribe(
      (data) => {
        const res: any = data;
        // console.log(res);
        console.log(`👏 > 👏 > 👏 > 👏 ${user.champion.name}'s email sent and the message id is ${res.messageId}`);
      },
      (err) => {
        console.log(err);
      },
      () => {}
    );
  }

  async sendTwit(user) {
    /* providerId google */
    // console.log(user);
    const mailData = {
      by: {
        id: this.usd.acc?.id,
        name: this.usd.acc?.name,
        email: this.usd.acc?.bus_email || this.usd.acc.email,
        photoUrl: this.usd.acc?.photoURL || "",
      },
      user: user, name: user.name, email: user?.bus_email || user.email,
      subjet: "Notice", text: "Notice",
    };
    const sendEmail = await this.http.tasking("https://us-central1-colors-a07e8.cloudfunctions.net/logTwit", user);
    sendEmail.subscribe(
      (data) => {
        const res: any = data;
        // console.log(res);
        console.log(`👏 > 👏 > 👏 > 👏 ${user.champion.name}'s email sent and the message id is ${res.messageId}`);
      },
      (err) => {
        console.log(err);
      },
      () => {}
    );
  }

  async sendFac(user) {
    /* providerId google */
    // console.log(user);
    const mailData = {
      by: {
        id: this.usd.acc?.id,
        name: this.usd.acc?.name,
        email: this.usd.acc?.bus_email || this.usd.acc.email,
        photoUrl: this.usd.acc?.photoURL || "",
      },
      user: user,
      name: user.name,
      email: user?.bus_email || user.email,
      subjet: "Notice",
      text: "Notice",
    };
    const sendEmail = await this.http.tasking(
      " https://us-central1-colors-a07e8.cloudfunctions.net/logFace",
      user
    );
    sendEmail.subscribe(
      (data) => {
        const res: any = data;
        // console.log(res);
        console.log(
          `👏 > 👏 > 👏 > 👏 ${user.champion.name}'s email sent and the message id is ${res.messageId}`
        );
      },
      (err) => {
        console.log(err);
      },
      () => {}
    );
  }

  prjUpdate() {
    this.usd.setProject.invQuantity.name = String(
      this.usd.setProject.invQuantity.value
    );
    const inRef = this.usd.afs.doc(`Projects/${this.usd.setProject.id}`);
    const remQuantity = this.usd.setProject.invQuantity;
    this.usd.setProject.remQuantity = remQuantity;
    if (!this.ob.data || this.ob.data.length === 0) {
      inRef
        .update({ remQuantity: this.usd.setProject.remQuantity })
        .then(() => {
          /* console.log('remQuantity updated') */
        })
        .catch((er) => console.log(er));
    } else {
      // this.ob.data.forEach(element => {
      //   this.usd.setProject.remQuantity -= element.size.name;
      // });
      for (let index = 0; index < this.ob.data.length; index++) {
        const element = this.ob.data[index];
        // if (element.size.name) {
        /* console.log(element.size.name, isNaN(element.size.name), this.usd.setProject.remQuantity.value); */
        if (!isNaN(element.size.name)) {
          if (this.usd.setProject.remQuantity.value) {
            this.usd.setProject.remQuantity.value -= element.size.name;
            this.usd.setProject.remQuantity.name = String(
              this.usd.setProject.remQuantity.value
            );
          } else {
            this.usd.setProject.remQuantity.value = element.size.name;
            this.usd.setProject.remQuantity.name = String(
              this.usd.setProject.remQuantity.value
            );
          }
        }
        if (this.ob.data.length === index + 1) {
          inRef
            .update({ remQuantity: this.usd.setProject.remQuantity })
            .then(() => {
              /* console.log('remQuantity updated') */
            })
            .catch((er) => console.log(er));
        }
      }
    }
    inRef
      .update({ invQuantity: this.usd.setProject.invQuantity })
      .then(() => {
        /* console.log('invQuantity updated') */
      })
      .catch((er) => console.log(er));
    inRef
      .update({ productType: this.usd.setProject.productType })
      .then(() => {
        /* console.log('productType updated') */
      })
      .catch((er) => console.log(er));
    inRef
      .update({ location: this.usd.setProject.location })
      .then(() => {
        /* console.log('location updated') */
      })
      .catch((er) => console.log(er));
    inRef
      .update({ legalDescription: this.usd.setProject.legalDescription })
      .then(() => {
        /* console.log('legalDescription updated') */
      })
      .catch((er) => console.log(er));
  }

  saveDescription(data) {
    this.usd.setProject.legalDescription.push(data);
    const by = {
      name: this.usd.acc.name,
      id: this.usd.userId,
      date: new Date().toISOString(),
    };
    const inRef = this.usd.afs.doc(`Projects/${this.usd.setProject.id}`);
    inRef
      .update({
        legalDescription:
          this.usd.firebase.firestore.FieldValue.arrayUnion(data),
        descriptionUpdate: by,
      })
      .then(() => {
        /* console.log('legalDescription updated') */
      })
      .catch((er) => console.log(er));
  }

  /* Transactions */

  /* Project */

  prjtransactions(data) {
    // console.log(data);
    if (data.project && data.project?.id !== "") {
      this.usd.afs
        .doc(`Projects/${data.project.id}/transactions/${data?.id}`)
        .set(data)
        .then(() => {
          console.log("Projects transactions set");
        })
        .catch((er) => console.log(er));
    }
  }

  delPrjtrans(data) {
    // console.log(data);
    if (data.project && data.project?.id !== "") {
      this.usd.afs
        .doc(`Projects/${data.project.id}/transactions/${data?.id}`)
        .delete()
        .then(() => {
          /* console.log('Projects transactions delete'); */
        })
        .catch((er) => console.log(er));
    }
  }

  /* Enterprise */

  // cltTransactions(data) {
  //   this.usd.afs.doc(`Enterprises/${this.usd.setCompany.id}/Client/${this.usd.setCompany.id}/transactions/${data?.id}`).set(data).then(() => {
  //     /* console.log('Enterprise Client transactions set'); */
  //   }).catch(er => console.log(er));
  // }

  /* Enterprise */

  mainTransactions(data) {
    // console.log(data);
    this.usd.afs
      .doc(
        `Enterprises/${this.ob.rootProject.companyId}/transactions/${data?.id}`
      )
      .set(data)
      .then(() => {
        /* console.log('Enterprise transactions set'); */
      })
      .catch((er) => console.log(er));
    let admin: any[] = [];
    const exe = this.ob?.rootManagers?.filter(
      (uzr) => uzr?.booksRole === "Approver"
    );
    if (exe.length > 0) {
      admin = exe;
      data.participants = admin;
      const ads = this.ob?.rootManagers?.filter(
        (uzr) => uzr?.booksRole === "Checker"
      );
      if (ads.length > 0) {
        admin.concat(ads);
        data.participants = admin;
        this.rootManagers(data);
      } else {
        this.rootManagers(data);
      }
    }
  }

  rootManagers(data) {
    data.participants.forEach((element) => {
      this.updateManagersTrans(element, data);
    });
  }

  entTransactions(data) {
    // console.log(data);
    this.usd.afs
      .doc(`Enterprises/${this.usd.setCompany.id}/transactions/${data?.id}`)
      .set(data)
      .then(() => {
        /* console.log('Enterprise transactions set'); */
      })
      .catch((er) => console.log(er));
  }

  delEntTrans(data) {
    // console.log(data);
    this.usd.afs
      .doc(`Enterprises/${this.usd.setCompany.id}/transactions/${data?.id}`)
      .delete()
      .then(() => {
        /* console.log('Enterprise transactions delete'); */
      })
      .catch((er) => console.log(er));
  }
  /* Project */

  psntransactions(product, managers, act) {
    /* console.log(act); */
    const tranRef = this.usd.afs.collection(
      `Users/${this.usd.userId}/transactions`
    );
    const invRef = this.usd.afs.collection("inventory");
    const prjRef = this.usd.afs.collection(
      `Projects/${product.project.id}/inventory`
    );
    if (act === "add") {
      const tname =
        product?.type?.value +
        " " +
        String(product?.productNo?.name) +
        " of " +
        product?.parent;
      product.name = tname;
      console.log(managers, product);
      const data: transaction = {
        by: { name: this.usd.acc.name, id: this.usd.userId },
        read: false,
        noti: "inventryNotice",
        name: tname,
        id: "",
        project: product?.project,
        saleType: "",
        company: product?.company,
        product: product,
        parties: [],
        rate: { name: "", verified: false, approved: false },
        amount: { cost: "0.00", paid: 0.0, balance: 0.0, currency: "" },
        transType: "",
        transClass: "",
        date: new Date().toISOString(),
        createdOn: new Date().toISOString(),
      };
      console.log(data);
      tranRef
        .add(data)
        .then((dataRef) => {
          /* console.log('user tansaction created', dataRef.id); */
          const tid = dataRef.id;
          data.id = dataRef.id;
          product.tid = dataRef.id;
          data.product.tid = dataRef.id;
          this.updateTid(product, tid);
          if (managers && managers?.length > 0) {
            managers.forEach((element) => {
              this.updateManagersTrans(element, data);
            });
            const maillist = [];
            managers.forEach((element) =>
              maillist.push(element.bus_email || element.email)
            );
            Promise.all(maillist).then(() => {
              const dataMail: any = data;
              dataMail.maillist = maillist;
              this.transmailGroup(data);
            });
          }
          this.usd.afs
            .doc(`Transactions/${data.id}`)
            .set(data)
            .then(() => {
              /* console.log('rootTrans set successfully') */
            })
            .catch((er) => console.log("failed to set rootTrans"));
          this.entTransactions(data);
          this.prjtransactions(data);
          tranRef
            .doc(dataRef.id)
            .update({ id: tid })
            .then(() => {
              /* console.log('id updated') */
            })
            .catch((er) => console.log(er));
          invRef
            .doc(product.id)
            .update({ tid: tid })
            .then(() => {
              /* console.log('tid updated') */
            })
            .catch((er) => console.log(er));
          prjRef
            .doc(product.id)
            .update({ tid: tid })
            .then(() => {
              /* console.log('tid updated') */
            })
            .catch((er) => console.log(er));
        })
        .catch((er) => console.log(er));
    }
  }

  setMailData(data, managers) {
    if (managers && managers?.length > 0) {
      managers.forEach((element) => {
        this.updateManagersTrans(element, data);
      });
      const maillist = [];
      managers.forEach((element) =>
        maillist.push(element.bus_email || element.email)
      );
      Promise.all(maillist).then(() => {
        // data.maillist = maillist;
        const dataMail: any = data;
        dataMail.maillist = maillist;
        this.transmailGroup(data);
      });
    }
    this.entTransactions(data);
    this.prjtransactions(data);
  }

  psnUpdatetrans(data, managers, act) {
    console.log(act, data);
    if (
      act === "checked" ||
      act === "approved" ||
      act === "delete" ||
      act === "checked" ||
      act === "approved"
    ) {
      const inRef = this.usd.afs.doc(
        `Users/${this.usd.userId}/transactions/${data.id}`
      );
      if (data.id && act === "delete") {
        inRef
          .update(data)
          .then(() => {
            if (managers && managers?.length > 0) {
              // managers.forEach(element => {
              //   this.updateManagersTrans(element, data);
              // })
              const maillist = [];
              managers.forEach((element) =>
                maillist.push(element.bus_email || element.email)
              );
              Promise.all(maillist).then(() => {
                const dataMail: any = data;
                dataMail.maillist = maillist;
                this.transmailGroup(data);
              });
            }
            this.entTransactions(data);
            this.prjtransactions(data);
          })
          .catch((er) => {
            // console.log(er);
            if (data.id) {
              inRef
                .update(data)
                .then(() => {
                  /* console.log('trans set') */
                })
                .catch((err) => console.log(err));
            }
          });
      }
      if (data.id && (act === "checked" || act === "approved")) {
        /* console.log('checked');
     // console.log(data.id); */
        inRef
          .update(data)
          .then(() => {
            this.setMailData(data, managers);
          })
          .catch((er) => {
            console.log(er);
            if (data.id) {
              inRef
                .set(data)
                .then(() => {
                  /* console.log('trans set'); */
                  this.setMailData(data, managers);
                })
                .catch((err) => console.log(err));
            }
          });
      }
      data.id ??
        this.usd.afs
          .doc(`Transactions/${data.id}`)
          .update(data)
          .then(() => {
            /* console.log('rootTrans set successfully') */
          })
          .catch((er) => {
            // console.log('failed to set rootTrans', er);
            if (data.id) {
              inRef
                .update(data)
                .then(() => {
                  /* console.log('trans set') */
                })
                .catch((err) => console.log(err));
            }
          });
    }
    if (act === "edit" || act === "update") {
      // console.log('updated');
      // console.log(data.id);
      const tranRef = this.usd.afs.collection(
        `Users/${this.usd.userId}/transactions`
      );
      const prjRef = this.usd.afs.collection(
        `Projects/${data.project.id}/inventory`
      );
      const invRef = this.usd.afs.collection("inventory");
      const tname =
        data?.product?.type?.value +
        " " +
        data.product?.name +
        " of " +
        data.product?.parent;
      /* console.log(product); */
      const trans: transaction = {
        by: { name: this.usd.acc.name, id: this.usd.userId },
        read: false,
        noti: "inventryNotice",
        name: tname,
        id: "",
        project: data?.project,
        saleType: "",
        company: data.product?.company,
        product: data.product,
        parties: [],
        rate: { name: "", verified: false, approved: false },
        amount: { cost: "0.00", paid: 0.0, balance: 0.0, currency: "" },
        transType: "",
        transClass: "",
        date: new Date().toISOString(),
        createdOn: new Date().toISOString(),
      };
      tranRef
        .add(trans)
        .then((dataRef) => {
          /* console.log('user tansaction created', dataRef.id); */
          const tid = dataRef.id;
          trans.id = dataRef.id;
          trans.product.tid = dataRef.id;
          trans.product.tid = dataRef.id;
          if (managers && managers?.length > 0) {
            managers.forEach((element) => {
              this.updateManagersTrans(element, trans);
            });
            const maillist = [];
            managers.forEach((element) =>
              maillist.push(element.bus_email || element.email)
            );
            Promise.all(maillist).then(() => {
              const dataMail: any = trans;
              dataMail.maillist = maillist;
              this.transmailGroup(trans);
            });
          }

          if (data?.product?.SellStatus?.status === "Reserved") {
            prjRef
              .doc(dataRef.id)
              .update({ "product.SellStatus.tid": tid })
              .then(() => {
                /* console.log('tid updated') */
              })
              .catch((er) => console.log(er));
            invRef
              .doc(data.product.id)
              .update({ "product.SellStatus.tid": tid })
              .then(() => {
                /* console.log('tid updated') */
              })
              .catch((er) => console.log(er));
          } else {
            tranRef
              .doc(dataRef.id)
              .update({ id: tid })
              .then(() => {
                /* console.log('id updated') */
              })
              .catch((er) => console.log(er));
            prjRef
              .doc(data.product.id)
              .update({ tid: tid })
              .then(() => {
                /* console.log('tid updated') */
              })
              .catch((er) => console.log(er));
            invRef
              .doc(data.product.id)
              .update({ tid: tid })
              .then(() => {
                /* console.log('tid updated') */
              })
              .catch((er) => console.log(er));
          }
          this.usd.afs
            .doc(`Transactions/${trans.id}`)
            .set(trans)
            .then(() => {
              /* console.log('rootTrans set successfully') */
            })
            .catch((er) => console.log("failed to set rootTrans"));
          this.entTransactions(trans);
          this.prjtransactions(trans);
        })
        .catch((er) => console.log(er));
    }
  }

  psnNewtransactions(product, managers, act) {
    /* console.log(act); */
    const tranRef = this.usd.afs.collection(
      `Users/${this.usd.userId}/transactions`
    );
    const invRef = this.usd.afs.collection("inventory");
    const prjRef = this.usd.afs.collection(
      `Projects/${product.project.id}/inventory`
    );
    if (act === "add") {
      const tname =
        product?.type?.value + " " + product?.name + " of " + product?.parent;
      /* console.log(product); */
      const data: transaction = {
        by: { name: this.usd.acc.name, id: this.usd.userId },
        read: false,
        noti: "inventryNotice",
        name: tname,
        id: "",
        project: product?.project,
        saleType: "",
        company: product?.company,
        product: product,
        parties: [],
        rate: { name: "", verified: false, approved: false },
        amount: { cost: "0.00", paid: 0.0, balance: 0.0, currency: "" },
        transType: "",
        transClass: "",
        date: new Date().toISOString(),
        createdOn: new Date().toISOString(),
      };
      tranRef
        .add(data)
        .then((dataRef) => {
          /* console.log('user tansaction created', dataRef.id); */
          const tid = dataRef.id;
          data.id = dataRef.id;
          product.tid = dataRef.id;
          data.product.tid = dataRef.id;
          this.updateTid(product, tid);
          if (managers && managers?.length > 0) {
            managers.forEach((element) => {
              this.updateManagersTrans(element, data);
            });
            const maillist = [];
            managers.forEach((element) =>
              maillist.push(element.bus_email || element.email)
            );
            Promise.all(maillist).then(() => {
              const dataMail: any = data;
              dataMail.maillist = maillist;
              this.transmailGroup(data);
            });
          }
          this.usd.afs
            .doc(`Transactions/${data.id}`)
            .set(data)
            .then(() => {
              /* console.log('rootTrans set successfully') */
            })
            .catch((er) => console.log("failed to set rootTrans"));
          this.entTransactions(data);
          this.prjtransactions(data);
          tranRef
            .doc(dataRef.id)
            .update({ id: tid })
            .then(() => {
              /* console.log('id updated') */
            })
            .catch((er) => console.log(er));
          invRef
            .doc(data.product.id)
            .update({ tid: tid })
            .then(() => {
              /* console.log('tid updated') */
            })
            .catch((er) => console.log(er));
          prjRef
            .doc(data.product.id)
            .update({ tid: tid })
            .then(() => {
              /* console.log('tid updated') */
            })
            .catch((er) => console.log(er));
        })
        .catch((er) => console.log(er));
    }
  }

  cancelManagersTrans(element, data) {
    if (this.usd.userId !== element?.id && element?.id) {
      data.read === false;
      this.usd.afs
        .doc(`Users/${element?.id}/transactions/${data.id}`)
        .set(data)
        .then(() => {
          // console.log(`${element.name}, transactions set`);
        })
        .catch((er) => {});
    }
  }

  updateManagersTrans(element, data) {
    if (this.usd.userId !== element?.id && element?.id) {
      data.read === false;
      this.usd.afs.doc(`Users/${element?.id}/transactions/${data.id}`).set(data)
        .then(() => {
          /* console.log(`${element.name}, transactions set`); */
        })
        .catch((er) => {});
    }
  }

  delTrans(element, data) {
    if (this.usd.userId !== element?.id && element?.id) {
      data.read === false;
      this.usd.afs.doc(`Users/${element?.id}/transactions/${data.id}`).delete()
        .then(() => {
          /* console.log(`${element.name}, transactions set`); */
        })
        .catch((er) => {});
    }
  }

  usertransactions(data, user) {
    if (data.byId !== user.id) {
      this.usd.afs.doc(`Users/${user.id}/transactions/${data?.id}`).set(data).then(() => {
          /* console.log(user.id, 'transaction created for', user.name, user); */
      })
      .catch((er) => console.log(er));
    }
  }

  addInventory(inv: Product, unit) {
    inv.by = { name: this.usd.acc.name, id: this.usd.acc.id };
    inv.createdOn = new Date().toISOString();
    inv.update = inv.createdOn;
    inv.project = {
      id: this.usd.setProject.id,
      name: this.usd.setProject.name,
    };
    inv.company = {
      id: this.usd.setProject.companyId,
      name: this.usd.setProject.companyName,
    };
    inv.initiated = {
      by: { name: inv.by.name, id: inv.by.id },
      status: true,
      date: inv.createdOn,
    };
    inv.checked = { by: { name: "", id: "" }, status: false, date: "" };
    inv.approved = { by: { name: "", id: "" }, status: false, date: "" };
    inv.name =
      inv?.type?.value + " " + inv?.productNo?.name + " of " + inv.parent || "";
    inv.description =
      inv?.type?.value + " " + inv?.productNo?.name + " of " + inv.parent || "";
    if (unit && unit.id !== "" && unit.name !== "") {
      inv.unit.name = unit.id;
      const uniFnd = this.is.allunits.find(
        (u) => u.name === unit.name || u.id === unit.id
      );
      // this.usd.afs.collection(`units`, ref => ref.where('name', '==', unit.name)).ref.get().then(unitRef => {
      if (!uniFnd) {
        this.usd.afs
          .collection(`units`)
          .add(unit)
          .then((Ref) => {
            const unitid = Ref.id;
            unit.uid = unitid;
            this.usd.afs
              .doc(`units/${unitid}`)
              .update({ uid: unitid })
              .then(() => console.log(unit, "updated successfully"))
              .catch((er) => console.log(`faled,${er.message}`));
            console.log("unit added");
          })
          .catch((er) => console.log(er));
      }
      // })
    }
    console.log(inv.description, inv);
    if (this.ob.data.length === 0) {
      this.usd.setProject.cumQuantity = inv.size.name;
    } else {
      this.usd.setProject.cumQuantity += inv.size.name;
    }
    inv.transAction = "newInventory";
    const invRef = this.usd.afs.collection("inventory");
    const prjRef = this.usd.afs.collection(
      `Projects/${inv.project.id}/inventory`
    );
    const str = inv.productNo.name;
    if (this.ob.data.find((item, i) => item.productNo.name === str)) {
      this.ns.showNotification("inventory_exist", "top", "right", inv);
    } else {
      console.log(inv);
      prjRef
        .add(inv)
        .then((Ref) => {
          const invId = Ref.id;
          console.log(invId);
          inv.id = Ref.id;
          prjRef
            .doc(invId)
            .update({ id: invId })
            .catch((er) => console.log("invId Error", er));
          console.log(inv);
          invRef
            .doc(invId)
            .set(inv)
            .then(() => {})
            .catch((er) => console.log("invRef set failed", er))
            .then(() => console.log(inv));
          if (
            inv.party &&
            inv.party?.name !== "" &&
            inv.party?.name !== undefined
          ) {
            console.log(inv.party);
            inv = this.ob.showPro(inv);
            this.addEntInventory(inv);
          } else if (
            !inv.party &&
            (!inv.party.name || inv.party?.name === undefined)
          ) {
            const setParty: any = this.ob.setParty;
            setParty.initiated = {
              by: { name: "", id: "" },
              status: false,
              date: "",
            };
            setParty.checked = {
              by: { name: "", id: "" },
              status: false,
              date: "",
            };
            setParty.approved = {
              by: { name: "", id: "" },
              status: false,
              date: "",
            };
            inv.party = setParty;
            console.log(inv.party);
          }
          this.ob.getInventory();
          let admin: any[] = [];
          const exe = this.ps?.managers?.filter(
            (uzr) => uzr?.booksRole === "Approver"
          );
          if (exe.length > 0) {
            admin = exe;
            inv.participants = admin;
            const ads = this.ps?.managers?.filter(
              (uzr) => uzr?.booksRole === "Checker"
            );
            if (ads.length > 0) {
              inv.participants = admin;
              admin.concat(ads);
              this.psntransactions(inv, admin, "add");
            } else {
              this.transManNoti(inv, admin);
            }
          } else {
            this.psntransactions(inv, admin, "add");
          }
        })
        .catch((er) => console.log(er));
    }
  }

  testInvNoti(inv) {
    this.ns.showNotification("inventory_exist", "top", "right", inv);
  }

  addInv(prjRef, invRef, inv) {
    prjRef
      .add(inv)
      .then((Ref) => {
        // console.log('Creating id', Ref.id);
        const invId = Ref.id;
        inv.id = Ref.id;
        prjRef.doc(invId).update({ id: invId });
        invRef
          .doc(invId)
          .set(inv)
          .then(() => {
            /* console.log('invRef set') */
          })
          .catch((er) => console.log("invRef set failed", er));
        this.ob.getInventory();
        let admin: any[] = [];
        const exe = this.ps?.managers?.filter(
          (uzr) => uzr?.booksRole === "Approver"
        );
        if (exe.length > 0) {
          admin = exe;
          // admin = admin.concat(exe);
          inv.participants = admin;
          const ads = this.ps?.managers?.filter(
            (uzr) => uzr?.booksRole === "Checker"
          );
          if (ads.length > 0) {
            admin.concat(ads);
            inv.participants = admin;
            this.psntransactions(inv, admin, "add");
            // this.transManNoti(inv, admin);
          } else {
            this.transManNoti(inv, admin);
          }
        } else {
          /* console.log(exe.length); */
          this.psntransactions(inv, admin, "add");
        }
      })
      .catch((er) => console.log(er));
  }

  updateInventory(inv, x) {
    // console.log('inventory', inv);
    if (inv.party?.name === undefined) {
      inv.party.name = "";
      const setParty: any = inv.party;
      setParty.initiated = {
        by: { name: "", id: "" },
        status: false,
        date: "",
      };
      setParty.checked = { by: { name: "", id: "" }, status: false, date: "" };
      setParty.approved = { by: { name: "", id: "" }, status: false, date: "" };
      inv.party = setParty;
      console.log(inv.party);
    }
    console.log(inv);
    inv.name =
      inv.type.value + " " + String(inv.productNo.name) + " of " + inv.parent;
    inv.tid = "";
    this.usd.afs
      .doc(`Projects/${inv.project.id}/inventory/${inv.id}`)
      .update(inv)
      .then(() => {
        this.usd.afs
          .doc(`inventory/${inv.id}`)
          .update(inv)
          .then(() => console.log("Inv updated"))
          .catch((er) => console.log("Inv updated failed", er));
        const tname = inv?.type?.value + " " + inv?.name + " of " + inv?.parent;
        const trans: transaction = {
          by: { name: inv?.initiated?.by?.name, id: inv?.initiated?.by?.id },
          read: false,
          id: "",
          noti: "inventryNotice",
          name: tname,
          project: inv?.project,
          saleType: "",
          company: inv?.company,
          product: inv,
          parties: [],
          rate: { name: "", verified: false, approved: false },
          amount: { cost: "0.00", paid: 0.0, balance: 0.0, currency: "" },
          transType: "",
          transClass: "",
          date: new Date().toISOString(),
          createdOn: new Date().toISOString(),
        };
        trans.product.transAction = x || "editInv";
        if (trans.product.edited && trans.product.edited.length > 0) {
          trans.product.edited.push({
            name: this.usd.acc.name,
            id: this.usd.acc.id,
            date: new Date().toISOString(),
          });
        } else {
          trans.product.edited = [
            {
              name: this.usd.acc.name,
              id: this.usd.acc.id,
              date: new Date().toISOString(),
            },
          ];
        }
        this.psnUpdatetrans(trans, this.managers(), x || "edit");
      })
      .catch((er) => console.log(er));
  }

  invUpdate(inv) {
    console.log(inv.id, inv);
    if (inv?.id) {
      this.usd.afs
        .doc(`Projects/${inv.project.id}/inventory/${inv?.id}`)
        .update(inv)
        .then(() => {
          this.usd.afs
            .doc(`inventory/${inv.id}`)
            .update(inv)
            .then(() => console.log("Inv updated"))
            .catch((er) => console.log("Inv updated failed", er));
        })
        .catch((er) => console.log(er));
    }
  }

  mongoFind(inv) {}

  updateTid(inv, tid) {
    // console.log('inventory updateTid', inv);
    this.usd.afs
      .doc(`Projects/${inv.project.id}/inventory/${inv.id}`)
      .update({ tid: tid })
      .then(() => {
        /* console.log('prjRef set') */
      })
      .catch((er) => console.log("prjRef update failed", er));
    this.usd.afs
      .doc(`inventory/${inv.id}`)
      .update({ tid: tid })
      .then(() => {
        /* console.log('invRef set') */
      })
      .catch((er) => console.log("invRef update failed", er));
  }

  delInventory(inv) {
    inv.deleted = {
      by: { name: this.usd.acc.name, id: this.usd.userId },
      status: true,
      date: new Date().toISOString(),
    };
    if (inv.project.id === "" && this.usd.setProject.id !== "") {
      inv.project.id = this.usd.setProject.id;
    }
    inv.transAction = "delInventory";
    // console.log('inventory', inv);
    if (inv.id && inv.id !== "") {
      this.usd.afs
        .doc(`Projects/${this.usd.setProject.id}/inventory/${inv.id}`)
        .delete()
        .then(async () => {
          this.usd.afs
            .doc(`inventory/${inv.id}`)
            .delete()
            .then(() => {
              /* console.log('invRef set') */
            })
            .catch((er) => console.log("invRef set failed", er));
          let admin = await this.managers();
          /* console.log(ads);
     // console.log(ads.length); */
          if (admin.length > 0) {
            // admin = admin.concat(exe);
            inv.participants = admin;
            if (inv.tid) {
              this.transDeleManNoti(inv, admin);
            }
          } else {
            /* console.log(exe.length); */
            // this.psnUpdatetrans(inv, exe, 'delete');
            if (inv.tid) {
              const tname =
                inv?.type?.value + " of " + inv?.name + " of " + inv?.parent;
              const trans: transaction = {
                by: {
                  name: inv?.initiated?.by?.name,
                  id: inv?.initiated?.by?.id,
                },
                read: false,
                id: inv.tid,
                noti: "inventryNotice",
                name: tname,
                project: inv?.project,
                saleType: "",
                company: inv?.company,
                product: inv,
                parties: [],
                rate: { name: "", verified: false, approved: false },
                amount: { cost: "0.00", paid: 0.0, balance: 0.0, currency: "" },
                transType: "",
                transClass: "",
                date: new Date().toISOString(),
                createdOn: new Date().toISOString(),
              };
              this.psnUpdatetrans(trans, admin, "delete");
            }
            this.ob.getInventory();
          }
          // })
          // set task under a champion
        })
        .catch((er) => console.log(er));
    } else {
    }
  }

  checkInventory(inv) {
    this.ob.verify.closed = true;
    inv.checked = {
      by: { name: this.usd.acc.name, id: this.usd.userId },
      status: true,
      date: new Date().toISOString(),
    };
    inv.transAction = "checkInventory";
    console.log("inventory", "tid", inv.tid, inv.parent, inv);
    if (inv.party && inv.party.name !== "") {
      inv = this.ob.showPro(inv);
    }
    // const tid = this.ob.obsTransArr.find(trn => trn.product.productNo.name === inv.productNo.name);
    // console.log('inventory', 'tid', inv.tid, tid, inv);
    this.firebaseCheck(inv);
  }

  firebaseCheck(inv) {
    // this.usd.afs.doc(`Projects/${inv.project.id}/inventory/${inv.id}`).update(inv).then(() => {
    this.usd.afs
      .doc(`Projects/${inv.project.id}/inventory/${inv.id}`)
      .update({ checked: inv.checked, transAction: inv.transAction })
      .then(() => {
        // this.usd.afs.doc(`inventory/${inv.id}`).update(inv).then(() => {/* console.log('Checked set') */ }).catch(er => console.log('Checked set failed', er));
        this.usd.afs
          .doc(`inventory/${inv.id}`)
          .update({ checked: inv.checked, transAction: inv.transAction })
          .then(() => {
            console.log("Checked set");
          })
          .catch((er) => console.log("Checked set failed", er));
        if (inv.party && inv.party.name !== "") {
          this.partyCheckInv(inv);
        }
        this.managersTrans(inv);
      })
      .catch((er) => console.log(er));
  }

  async managersTrans(inv) {
    let admin = await this.managers();
    if (admin.length > 0) {
      inv.participants = admin;
      this.transCheckManNoti(inv, admin);
    } else {
      const tname =
        inv?.type?.value + " of " + inv?.name + " of " + inv?.parent;
      const trans: transaction = {
        by: { name: inv?.initiated?.by?.name, id: inv?.initiated?.by?.id },
        read: false,
        id: inv.tid,
        noti: "inventryNotice",
        name: tname,
        project: inv?.project,
        saleType: "",
        company: inv?.company,
        product: inv,
        parties: [],
        rate: { name: "", verified: false, approved: false },
        amount: { cost: "0.00", paid: 0.0, balance: 0.0, currency: "" },
        transType: "",
        transClass: "",
        date: new Date().toISOString(),
        createdOn: new Date().toISOString(),
      };
      this.psnUpdatetrans(trans, admin, "checked");
    }
  }

  managers() {
    let admin;
    const exe = this.ps?.managers?.filter(
      (uzr) => uzr?.booksRole === "Approver"
    );
    const ads = this.ps?.managers?.filter(
      (uzr) => uzr?.booksRole === "Checker"
    );
    if (exe?.length > 0) {
      admin = exe;
      if (ads.length > 0) {
        admin.concat(ads);
        return admin;
      } else {
        return admin;
      }
    } else if (exe && exe?.length === 0 && ads?.length > 0) {
      admin = ads;
      return admin;
    } else {
      return [];
    }
  }

  apprvInventory(inv) {
    inv.approved = {
      by: { name: this.usd.acc.name, id: this.usd.userId },
      status: true,
      date: new Date().toISOString(),
    };
    inv.transAction = "apprvInventory";
    if (inv.party && inv.party.name !== "") {
      inv = this.ob.showPro(inv);
    }
    console.log("inventory", inv);
    this.firebaseApprove(inv);
  }

  firebaseApprove(inv) {
    // this.usd.afs.doc(`Projects/${inv.project.id}/inventory/${inv.id}`).update(inv).then(() => {
    this.usd.afs
      .doc(`Projects/${inv.project.id}/inventory/${inv.id}`)
      .update({ approved: inv.checked, transAction: inv.transAction })
      .then(() => {
        // this.usd.afs.doc(`inventory/${inv.id}`).update(inv).then(() => {/* console.log('Checked set') */ }).catch(er => console.log('Checked set failed', er));
        this.usd.afs
          .doc(`inventory/${inv.id}`)
          .update({ approved: inv.checked, transAction: inv.transAction })
          .then(() => {
            /* console.log('Checked set') */
          })
          .catch((er) => console.log("Checked set failed", er));
        if (inv.party && inv.party.name !== "") {
          console.log(inv.party);
          this.partyApprvInv(inv);
        }
        this.managersTrans(inv);
      })
      .catch((er) => console.log(er));
  }

  addEntInventory(inv) {
    console.log("addEntInventory", inv);

    const prty: agentFootprint = {
      name: inv.party.name,
      id: inv.party.id,
      allocationDate: new Date().toISOString(),
      reAllocationDate: new Date().toISOString(),
    };
    inv.party.initiated = {
      by: { name: this.usd.acc.name, id: this.usd.userId },
      status: true,
      date: new Date().toISOString(),
    };
    inv.party.checked = { by: { name: "", id: "" }, status: false, date: "" };
    inv.party.approved = { by: { name: "", id: "" }, status: false, date: "" };
    if (!inv.footprint || inv.footprint?.length > 0) {
      inv.footprint = [prty];
    } else {
      inv.footprint = [];
      inv.footprint.push(prty);
    }
    console.log("party", inv.party);
    inv.transAction = "initPartyInv";
    /* console.log('inventory', inv); */
    // console.log(inv.party, inv);
    inv.entQuantity = "";
    if (inv.party && inv.party.id !== "") {
      this.usd.afs
        .doc(`Enterprises/${inv.party.id}/inventory/${inv.id}`)
        .set(inv)
        .then(() => {
          this.usd.afs
            .doc(`Projects/${inv.project.id}/inventory/${inv.id}`)
            .update({ party: inv.party })
            .then(() => {
              this.ob.dsDover();
            })
            .catch((er) => console.log("failed to initPartyInv update", er));
          this.usd.afs
            .doc(`inventory/${inv.id}`)
            .update({ party: inv.party })
            .then(() => console.log("initPartyInv set"))
            .catch((er) => {
              // console.log('initPartyInv set failed', er)
              this.usd.afs
                .doc(`inventory/${inv.id}`)
                .set(inv)
                .then(() => console.log("inventory set"))
                .catch((error) => console.log(error));
            });
          /* console.log('approved data updated'); */
        })
        .then(async () => {
          let admin = await this.managers();
          if (admin.length > 0) {
            inv.participants = admin;
            this.partyInvNoti(inv, admin);
          } else {
            this.psntransactions(inv, admin, "apprvInventory");
          }
        })
        .catch((er) => console.log(er));
    }
  }

  addAgentInventory(inv, newAgent) {
    // inv.party = { name: this.usd.acc.name, id: this.usd.userId, date: new Date().toISOString() };
    // console.log(inv.party);
    if (inv.participants === undefined) {
      inv.participants = [];
    }
    if (newAgent) {
      const ag = newAgent;
      // console.log(ag);
      inv.agent.initiated = {
        by: { name: this.usd.acc.name, id: this.usd.userId },
        status: true,
        date: new Date().toISOString(),
      };
      inv.agent.checked = { by: { name: "", id: "" }, status: false, date: "" };
      inv.agent.approved = {
        by: { name: "", id: "" },
        status: false,
        date: "",
      };
      inv.agent = ag;
      inv.transAction = "initPartyInv";
      console.log(newAgent, inv);
      if (inv.party && inv.party.id !== "") {
        this.usd.afs
          .doc(`Enterprises/${inv.party.id}/inventory/${inv.id}`)
          .set(inv)
          .then(() => {
            this.usd.afs
              .doc(`Projects/${inv.project.id}/inventory/${inv.id}`)
              .update({ agent: inv.agent })
              .then(() => {
                this.ob.dsDover();
              })
              .catch((er) => console.log("failed to initPartyInv update", er));
          })
          .then(async () => {
            this.usd.afs
              .doc(`inventory/${inv.id}`)
              .update({ agent: inv.agent })
              .then(() => console.log("initAgenInv set"))
              .catch((er) => {
                this.usd.afs
                  .doc(`inventory/${inv.id}`)
                  .set(inv)
                  .then(() => console.log("inventory set"))
                  .catch((error) => console.log(error));
              });
            let admin = await this.managers();
            if (admin.length > 0) {
              inv.participants = admin;
              this.partyInvNoti(inv, admin);
            } else {
              this.psntransactions(inv, admin, "apprvInventory");
            }
          })
          .catch((er) => console.log(er));
        if (this.ob.results?.length > 0) {
          const indFnd = this.ob?.results.findIndex((x) => x.id === inv.id);
          if (indFnd > -1) {
            this.ob.results[indFnd] = inv;
          }
        }
        if (this.ob.prjInventory?.length > 0) {
          const indFnd = this.ob?.prjInventory.findIndex(
            (x) => x.id === inv.id
          );
          if (indFnd > -1) {
            this.ob.prjInventory[indFnd] = inv;
          }
        }
        if (this.ob.inventoryList?.length > 0) {
          const indFnd = this.ob?.inventoryList.findIndex(
            (x) => x.id === inv.id
          );
          if (indFnd > -1) {
            this.ob.inventoryList[indFnd] = inv;
          }
        }
        if (this.ob.availableInv?.length > 0) {
          const indFnd = this.ob?.availableInv.findIndex(
            (x) => x.id === inv.id
          );
          if (indFnd > -1) {
            this.ob.availableInv[indFnd] = inv;
          }
        }
        if (this.ob.partyInv?.length > 0) {
          const indFnd = this.ob?.partyInv.findIndex((x) => x.id === inv.id);
          if (indFnd > -1) {
            this.ob.partyInv[indFnd] = inv;
          }
        }
      }
    }
  }

  realocateInv(inv) {
    const setParty: any = this.ob.setParty;
    setParty.initiated = {
      by: { name: this.usd.acc.name, id: this.usd.userId },
      status: true,
      date: new Date().toISOString(),
    };
    setParty.checked = { by: { name: "", id: "" }, status: false, date: "" };
    setParty.approved = { by: { name: "", id: "" }, status: false, date: "" };
    inv.transAction = "initPartyInv";
    console.log("inventory", inv, setParty);
    if (setParty && setParty.id !== "") {
      inv.party = setParty;
      this.usd.afs
        .doc(`Enterprises/${setParty.id}/inventory/${inv.id}`)
        .set(inv)
        .then(() => {
          this.usd.afs
            .doc(`Enterprises/${setParty.id}/inventory/${this.ob.oldParty.id}`)
            .delete()
            .then(() => {})
            .catch((er) => console.log("Failed to delete from party"));
          this.usd.afs
            .doc(`Projects/${inv.project.id}/inventory/${inv.id}`)
            .update({ party: setParty, footprint: inv.footprint })
            .then(() => {
              this.ob.dsDover();
            })
            .catch((er) => console.log("failed to initPartyInv update", er));
        })
        .then(async () => {
          this.usd.afs
            .doc(`inventory/${inv.id}`)
            .update({ party: setParty, footprint: inv.footprint })
            .then(() => console.log("initPartyInv set"))
            .catch((er) => {
              this.usd.afs
                .doc(`inventory/${inv.id}`)
                .update({ party: setParty, footprint: inv.footprint })
                .then(() => console.log("inventory set"))
                .catch((error) => console.log(error));
            });
          let admin = await this.managers();
          if (admin.length > 0) {
            inv.participants = admin;
            this.partyInvNoti(inv, admin);
          } else {
            this.psntransactions(inv, admin, "apprvInventory");
          }
        })
        .catch((er) => console.log(er));
    }
  }

  partyCheckInv(inv) {
    inv.party.checked = {
      by: { name: this.usd.acc.name, id: this.usd.userId },
      status: true,
      date: new Date().toISOString(),
    };
    inv.transAction = "checkInventory";
    /* console.log('inventory', inv); */
    this.usd.afs
      .doc(`Projects/${inv.project.id}/inventory/${inv.id}`)
      .update({ "party.checked": inv.party.checked })
      .then(async () => {
        this.ob.dsDover();
        this.usd.afs
          .doc(`Enterprises/${inv.party.id}/inventory/${inv.id}`)
          .update({ "party.checked": inv.party.checked })
          .then(() => console.log("ent inv checked"))
          .catch((err) => {
            this.usd.afs
              .doc(`Enterprises/${inv.party.id}/inventory/${inv.id}`)
              .set(inv)
              .then(() => console.log("ent inv then set"))
              .catch((eror) => console.log("err failed", eror));
          });
        this.usd.afs
          .doc(`inventory/${inv.id}`)
          .update({ "party.checked": inv.party.checked })
          .then(() => console.log("checked set"))
          .catch((er) => console.log("checked set failed", er));
        let admin = await this.managers();
        if (admin.length > 0) {
          inv.participants = admin;
          this.transCheckManNoti(inv, admin);
        } else {
          this.psntransactions(inv, admin, "apprvInventory");
        }
        // })
        // set task under a champion
      })
      .catch((er) => console.log(er));
  }

  partyApprvInv(inv) {
    inv.party.approved = {
      by: { name: this.usd.acc.name, id: this.usd.userId },
      status: true,
      date: new Date().toISOString(),
    };
    inv.transAction = "apprvInventory";
    console.log("inventory", inv);
    this.usd.afs
      .doc(`Projects/${inv.project.id}/inventory/${inv.id}`)
      .update({ "party.approved": inv.party.approved })
      .then(async () => {
        /* console.log('approved data updated'); */ this.ob.dsDover();
        this.usd.afs
          .doc(`Enterprises/${inv.party.id}/inventory/${inv.id}`)
          .update({ "party.approved": inv.party.approved })
          .then(() => console.log("ent inv approved"))
          .catch((err) => {
            this.usd.afs
              .doc(`Enterprises/${inv.party.id}/inventory/${inv.id}`)
              .set(inv)
              .then(() => console.log("ent inv then set"))
              .catch((eror) => console.log("err failed", eror));
          });
        this.usd.afs
          .doc(`inventory/${inv.id}`)
          .update({ "party.approved": inv.party.approved })
          .then(() => console.log("invRef set"))
          .catch((er) => console.log("invRef set failed", er));
        let admin = await this.managers();
        if (admin.length > 0) {
          inv.participants = admin;
          this.transApprovManNoti(inv, admin);
        } else {
          this.psntransactions(inv, admin, "apprvInventory");
        }
        // set task under a champion
      })
      .catch((er) => console.log(er));
  }

  delPartyInventory(inv) {
    // console.log(inv.party);
    const prty = {};
    inv.party = prty;
    if (!inv.footprint || inv.footprint?.length > 0) {
      inv.footprint.push(prty);
    } else {
      inv.footprint = [];
      inv.footprint.push(prty);
    }
    inv.transAction = "initPartyInv";
    // console.log('inventory', inv);
    if (inv.party && inv.party.id !== "") {
      this.usd.afs
        .doc(`Enterprises/${inv.party.id}/inventory/${inv.id}`)
        .delete()
        .then(() => {
          this.usd.afs
            .doc(`Projects/${inv.project.id}/inventory/${inv.id}`)
            .update({ party: inv.party })
            .then(() => {})
            .catch((er) => console.log("failed to initPartyInv update", er));
          // console.log('approved data updated');
        })
        .then(() => {
          this.usd.afs
            .doc(`inventory/${inv.id}`)
            .update({ party: inv.party })
            .then(() => {
              /* console.log('initPartyInv set') */
            })
            .catch((er) => {
              // console.log('initPartyInv set failed', er)
              this.usd.afs
                .doc(`inventory/${inv.id}`)
                .set(inv)
                .then(() => {
                  /* console.log('inventory set') */
                })
                .catch((error) => console.log(error));
            });
        })
        .catch((er) => console.log(er));
    }
  }

  transCheckManNoti(product, managers: any[]) {
    // console.log(product.tid);
    const tname =
      product?.type?.value + " " + product?.name + " of " + product?.parent;
    const trans: transaction = {
      by: {
        name: product?.initiated?.by?.name,
        id: product?.initiated?.by?.id,
      },
      read: false,
      id: product.tid,
      noti: "inventryNotice",
      name: tname,
      project: product?.project,
      saleType: "",
      company: product?.company,
      product: product,
      parties: [],
      rate: { name: "", verified: false, approved: false },
      amount: { cost: "0.00", paid: 0.0, balance: 0.0, currency: "" },
      transType: "",
      transClass: "",
      date: new Date().toISOString(),
      createdOn: new Date().toISOString(),
    };
    this.psnUpdatetrans(trans, managers, "checked");
  }

  transApprovManNoti(product, managers: any[]) {
    // trans.noti = 'inventryNotice';
    const tname =
      product?.type?.value + " " + product?.name + " of " + product?.parent;
    const trans: transaction = {
      by: {
        name: product?.initiated?.by?.name,
        id: product?.initiated?.by?.id,
      },
      read: false,
      id: product.tid,
      noti: "inventryNotice",
      name: tname,
      project: product?.project,
      saleType: "",
      company: product?.company,
      product: product,
      parties: [],
      rate: { name: "", verified: false, approved: false },
      amount: { cost: "0.00", paid: 0.0, balance: 0.0, currency: "" },
      transType: "",
      transClass: "",
      date: new Date().toISOString(),
      createdOn: new Date().toISOString(),
    };

    this.psnUpdatetrans(trans, managers, "approved");
  }

  partyInvNoti(product, managers: any[]) {
    // trans.noti = 'inventryNotice';
    const tname =
      product?.type?.value + " " + product?.name + " of " + product?.parent;
    const trans: transaction = {
      by: {
        name: product?.initiated?.by?.name,
        id: product?.initiated?.by?.id,
      },
      read: false,
      id: product.tid,
      noti: "inventryNotice",
      name: tname,
      project: product?.project,
      saleType: "",
      company: product?.company,
      product: product,
      parties: [],
      rate: { name: "", verified: false, approved: false },
      amount: { cost: "0.00", paid: 0.0, balance: 0.0, currency: "" },
      transType: "",
      transClass: "",
      date: new Date().toISOString(),
      createdOn: new Date().toISOString(),
    };

    this.psntransactions(trans, managers, "initPartyInv");
  }

  transDeleManNoti(product, managers: any[]) {
    // trans.noti = 'inventryNotice';
    const tname =
      product?.type?.value + " " + product?.name + " of " + product?.parent;
    const trans: transaction = {
      by: {
        name: product?.initiated?.by?.name,
        id: product?.initiated?.by?.id,
      },
      read: false,
      id: product.tid,
      noti: "inventryNotice",
      name: tname,
      project: product?.project,
      saleType: "",
      company: product?.company,
      product: product,
      parties: [],
      rate: { name: "", verified: false, approved: false },
      amount: { cost: "0.00", paid: 0.0, balance: 0.0, currency: "" },
      transType: "",
      transClass: "",
      date: new Date().toISOString(),
      createdOn: new Date().toISOString(),
    };
    this.psnUpdatetrans(trans, managers, "delete");
  }

  transManNoti(product, managers: any[]) {
    // trans.noti = 'inventryNotice';
    // const tname = product?.type?.value + ' ' + product?.name + ' of ' + product?.parent;
    // const trans: transaction = { by: { name: product?.initiated?.by?.name, id: product?.initiated?.by?.id }, read: false, id: product.tid, noti: 'inventryNotice', name: tname, project: product?.project, saleType: '', company: product?.company, product: product, parties: [], rate: { name: '', verified: false, approved: false }, amount: { cost: '0.00', paid: 0.00, balance: 0.00, currency: '' }, transType: '', transClass: '', date: new Date().toISOString(), createdOn: new Date().toISOString() }
    // this.psntransactions(trans, managers, 'add');
    this.psntransactions(product, managers, "add");
  }

  saveInvoice() {
    // console.log(this.ob.invoice);
    const data = this.ob.invoice;

    this.unitrans(data, "invoice");
    // this.unitrans(data);
  }

  saveSale() {
    // console.log(this.ob.transaction, 'transType', this.ob.transaction.transType, 'transClass', this.ob.transaction.transClass);
    const trans: any = this.ob.transaction;
    trans.product.rate = trans.rate.name;
    if (
      (!trans.product.party &&
        (!trans.product.party.name ||
          trans.product.party.name === undefined)) ||
      trans.product.party.name === undefined
    ) {
      const setParty: any = this.ob.setParty;
      setParty.initiated = {
        by: { name: "", id: "" },
        status: false,
        date: "",
      };
      setParty.checked = { by: { name: "", id: "" }, status: false, date: "" };
      setParty.approved = { by: { name: "", id: "" }, status: false, date: "" };
      trans.product.party = setParty;
    }
    const data: compTans = {
      read: false,
      noti: "inventryNotice",
      name:
        trans?.product?.type?.value ||
        "" + trans?.product.productNo?.name ||
        "" + " of " + trans?.product?.parent ||
        "",
      id: "",
      project: trans?.project,
      company: trans?.company,
      by: { name: this.usd.acc.name, id: this.usd.userId },
      saleType: trans?.saleType,
      product: trans.product,
      parties: trans.parties,
      rate: { name: trans.rate.name, verified: false, approved: false },
      amount: {
        cost: trans.amount.cost,
        paid: trans.amount.paid,
        balance: trans?.amount?.balance,
        currency: trans?.amount?.currency,
      },
      transType: trans.transType,
      transClass: trans.transClass,
      extrainfo: trans.extrainfo || "",
      date: trans.date,
      component: "Sale",
      paymentMethod: "",
      products: trans.products,
      amountInLieu: trans.amountInLieu,
      quatityInLieu: trans.quatityInLieu,
      Initiated: {
        by: { name: this.usd.acc.name, id: this.usd.userId },
        status: true,
        date: new Date().toISOString(),
      },
      Checked: { by: { name: "", id: "" }, status: false, date: "" },
      Approved: { by: { name: "", id: "" }, status: false, date: "" },
      createdOn: new Date().toISOString(),
    };
    const SellStatus = {
      status: "Sold",
      payment: "Pending ...",
      customer: trans.parties,
      date: trans.date,
    };
    this.updatePartytimes(trans.product, SellStatus, data);
    console.log(`Sale ${trans.product.name}`, data);
    const invRef = this.usd.afs.doc(`inventory/${trans.product.id}`);
    const prjRef = this.usd.afs.doc(
      `Projects/${trans.product.project.id}/inventory/${trans.product.id}`
    );
    invRef.update({ parties: trans.parties, SellStatus: SellStatus }).catch((er) => console.log(er));
    prjRef.update({ parties: trans.parties, SellStatus: SellStatus }).catch((er) => console.log(er));
    // this.unitrans(data);
    this.unitrans(data, "sale");
  }

  updatePartytimes(x, status, trans) {
    if (this.is.updatePartyTimes === true) {
      this.ob.partTrans.push(trans);
      this.ob.partyInv.find((inv) => inv.id === x.id).SellStatus = status;
    }
  }

  resetSale(x) {
    const SellStatus = {
      status: "Available",
      payment: "",
      customer: [],
      date: "",
      reservations: [],
      by: { name: "", id: "" },
      tid: "",
    };
    const invRef = this.usd.afs.doc(`inventory/${x.id}`);
    const prjRef = this.usd.afs.doc(
      `Projects/${x.project.id}/inventory/${x.id}`
    );
    invRef.update({ SellStatus: SellStatus, parties: [] });
    prjRef.update({ SellStatus: SellStatus, parties: [] });
    this.ns.showNotification("sale failed", "top", "right", x);
  }

  deleteSale() {
    console.log(
      this.ob.transaction,
      "transType",
      this.ob.transaction.transType,
      "transClass",
      this.ob.transaction.transClass
    );
    const data: any = this.ob.transaction;
    const SellStatus = {
      status: "Available",
      payment: "",
      customer: [],
      date: "",
      reservations: [],
      by: { name: "", id: "" },
      tid: "",
    };
    data.parties = [];
    const parties = [];
    data.component = "";
    data.searchTrans = "deletedSale";
    if (data.id && data.id !== "") {
      const inRef = this.usd.afs.doc(
        `Users/${this.usd.userId}/transactions/${data.id}`
      );
      const rootTrans = this.usd.afs.doc(`Transactions/${data.id}`);
      rootTrans
        .delete()
        .then(() => {
          if (this.ob.searchValue && this.ob.searchValue !== "") {
            // this.ob?.saleResults.splice(this.ob?.saleResults.findIndex(v => v.id === data.id), 1);
            this.ob.saleResults.filter((v) => v.id !== data.id);
          } else {
            // this.ob?.saletransArr.splice(this.ob?.saletransArr.findIndex(v => v.id === data.id), 1);
            this.ob.saletransArr.filter((v) => v.id !== data.id);
          }
          // this.ob.projectData.salesTrans.splice(this.ob?.saletransArr.findIndex(v => v.id === data.id), 1)
          this.ob.projectData.salesTrans.filter((v) => v.id !== data.id);
          // const invRef = this.usd.afs.doc(`inventory/${data.product.id}`).update({'SellStatus' : SellStatus, 'parties': parties });
          // const prjRef = this.usd.afs.doc(`Projects/${data.product.project.id}/inventory/${data.product.id}`).update({'SellStatus' : SellStatus, 'parties': parties });
          inRef.delete();
          console.log("tansaction deleted");
          // this.updateTid(data.product, tid);
          if (data?.parties && data?.parties?.length > 0) {
            data?.parties?.forEach((element) => {
              const maillist = [];
              if (element.srTag === "ind") {
                this.deleteManagersTrans(element, data);
                maillist.push(element.bus_email || element.email);
              }
              if (element.srTag === "ent") {
                if (
                  element?.contactPerson?.name !== "" &&
                  element?.contactPerson?.id &&
                  element?.contactPerson?.id !== "" &&
                  (element?.contactPerson?.email !== "" ||
                    element?.contactPerson?.bus_email !== "")
                ) {
                  this.deleteManagersTrans(element?.contactPerson, data);
                  maillist.push(element.bus_email || element.email);
                }
                if (
                  element?.champion?.name !== "" &&
                  element?.champion?.id &&
                  element?.champion?.id !== "" &&
                  (element?.champion?.email !== "" ||
                    element?.champion?.bus_email !== "")
                ) {
                  this.deleteManagersTrans(element?.champion, data);
                  maillist.push(element.bus_email || element.email);
                }
              }
              Promise.all(maillist).then(() => {
                const dataMail: any = data;
                dataMail.maillist = maillist;
                this.transmailGroup(data);
              });
            });
          }
          this.delEntTrans(data);
          this.delPrjtrans(data);
          this.ob.prjTrans();
        })
        .catch((er) => console.log(er));
    } else {
      this.fixTransIds(data);
    }
  }

  deleteDublicate() {
    // console.log(this.ob.transaction, 'transType', this.ob.transaction.transType, 'transClass', this.ob.transaction.transClass);
    const data: any = this.ob.transaction;
    data.parties = [];
    const parties = [];
    data.component = "";
    data.searchTrans = "deletedSale";
    const inRef = this.usd.afs.doc(
      `Users/${this.usd.userId}/transactions/${data.id}`
    );
    const rootTrans = this.usd.afs.doc(`Transactions/${data.id}`);
    inRef
      .delete()
      .then(() => {
        rootTrans.delete();
        if (this.ob.searchValue && this.ob.searchValue !== "") {
          this.ob?.saleResults.splice(
            this.ob?.saleResults.findIndex((v) => v.id === data.id),
            1
          );
        } else {
          this.ob?.saletransArr.splice(
            this.ob?.saletransArr.findIndex((v) => v.id === data.id),
            1
          );
        }
        // console.log('user tansaction created');
        if (data?.parties && data?.parties?.length > 0) {
          data?.parties?.forEach((element) => {
            const maillist = [];
            if (element.srTag === "ind") {
              this.deleteManagersTrans(element, data);
              maillist.push(element.bus_email || element.email);
            }
            if (element.srTag === "ent") {
              if (
                element?.contactPerson?.name !== "" &&
                element?.contactPerson?.id &&
                element?.contactPerson?.id !== "" &&
                (element?.contactPerson?.email !== "" ||
                  element?.contactPerson?.bus_email !== "")
              ) {
                this.deleteManagersTrans(element?.contactPerson, data);
                maillist.push(element.bus_email || element.email);
              }
              if (
                element?.champion?.name !== "" &&
                element?.champion?.id &&
                element?.champion?.id !== "" &&
                (element?.champion?.email !== "" ||
                  element?.champion?.bus_email !== "")
              ) {
                this.deleteManagersTrans(element?.champion, data);
                maillist.push(element.bus_email || element.email);
              }
            }
            Promise.all(maillist).then(() => {
              const dataMail: any = data;
              dataMail.maillist = maillist;
              this.transmailGroup(data);
            });
          });
        }
        this.delEntTrans(data);
        this.delPrjtrans(data);
        this.ob.prjTrans();
      })
      .catch((er) => console.log(er));
  }

  deleteManagersTrans(element, data) {
    if (this.usd.userId !== element?.id && element?.id) {
      data.read === false;
      // this.usd.afs.doc(`Users/${element.id}/transNoti/${data.id}`).set(data).then(() => {
      //// console.log(`${element.name}, transNotified`);
      this.usd.afs
        .doc(`Users/${element?.id}/transactions/${data.id}`)
        .delete()
        .then(() => {
          /* console.log(`${element.name}, transactions set`); */
        })
        .catch((er) => {});
    }
  }

  cancelSale(old) {
    const data: any = this.ob.transCancel;
    const newData: any = this.ob.transCancel;
    newData.name = `Sale of ${newData?.product?.name} Cancelled`;
    // newData.oldParties = old;
    newData.oldParties && newData.oldParties?.length >= 0
      ? newData.oldParties.concat(old)
      : (newData.oldParties = old);
    // newData.oldParties = this.ob.oldCustomers;
    const SellStatus = {
      status: "Available",
      payment: "",
      customer: [],
      date: "",
      reservations: [],
      by: { name: "", id: "" },
      tid: "",
    };
    newData.parties = [];
    newData.SellStatus = SellStatus;
    newData.product.SellStatus = SellStatus;
    newData.component = "cancelSale";
    console.log(newData);
    this.usd.afs
      .doc(`inventory/${data.product.id}`)
      .update({
        parties: [],
        tid: "",
        oldParties: data.oldParties,
        SellStatus: SellStatus,
      })
      .catch((er) => console.log("root inventory failed to update parties"));
    this.usd.afs
      .doc(`Projects/${data.product.project.id}/inventory/${data.product.id}`)
      .update({
        parties: [],
        tid: "",
        oldParties: data.oldParties,
        SellStatus: SellStatus,
      })
      .then(() => {
        const inRef = this.usd.afs.doc(
          `Users/${this.usd.userId}/transactions/${data.id}`
        );
        const rootTrans = this.usd.afs.doc(`Transactions/${data.id}`);
        if (this.ob.searchValue && this.ob.searchValue !== "") {
          this.ob?.saleResults.splice(
            this.ob?.saleResults.findIndex((v) => v.id === data.id),
            1
          );
        } else {
          this.ob?.saletransArr.splice(
            this.ob?.saletransArr.findIndex((v) => v.id === data.id),
            1
          );
        }
        this.ob.projectData.salesTrans = this.ob.projectData.salesTrans.filter(
          (sale) => sale?.id !== data?.id
        );
        rootTrans.delete().then(() => {
          console.log("Cancel complete");
          this.ob.uploadPercent = 0;
          this.ob.getInventory();
          this.delEntTrans(data);
          this.delPrjtrans(data);
          this.updatetrans(newData); /*  console.log('newData trans'); */
          if (old && old?.length > 0) {
            this.mailOldClient(old, newData, data);
          }
        });
        inRef.delete();
      })
      .catch((er) => console.log("Project inventory failed to update"));
  }

  mailOldClient(old, newData, data) {
    old?.forEach((element) => {
      const maillist = [];
      if (element.srTag === "ind") {
        this.cancelManagersTrans(element, newData);
        maillist.push(element.bus_email || element.email);
      }
      if (element.srTag === "ent") {
        if (
          element?.contactPerson?.name !== "" &&
          element?.contactPerson?.id &&
          element?.contactPerson?.id !== "" &&
          (element?.contactPerson?.email !== "" ||
            element?.contactPerson?.bus_email !== "")
        ) {
          this.cancelManagersTrans(element?.contactPerson, newData);
          maillist.push(element.bus_email || element.email);
        }
        if (
          element?.champion?.name !== "" &&
          element?.champion?.id &&
          element?.champion?.id !== "" &&
          (element?.champion?.email !== "" ||
            element?.champion?.bus_email !== "")
        ) {
          this.cancelManagersTrans(element?.champion, newData);
          maillist.push(element.bus_email || element.email);
        }
      }
      Promise.all(maillist).then(() => {
        const dataMail: any = data;
        dataMail.maillist = maillist;
        this.transmailGroup(data);
      });
    });
  }

  checkSale() {
    const trans: any = this.ob.saletrans;
    if (trans.id) {
      if (trans?.name !== trans?.product?.name) {
        trans.name = trans?.product?.name;
      }
      const data: compTans = {
        read: false,
        noti: "inventrySaleNotice",
        name: trans?.name,
        id: trans.id,
        extrainfo: trans.extrainfo || "",
        project: trans?.project,
        company: trans?.company,
        by: trans.by,
        saleType: trans?.saleType,
        product: trans.product,
        parties: trans.parties,
        rate: trans.rate,
        amount: trans.amount,
        transType: trans.transType,
        transClass: trans.transClass,
        date: trans.date,
        component: trans.component,
        paymentMethod: trans.paymentMethod,
        products: trans.products,
        amountInLieu: trans.amountInLieu,
        quatityInLieu: trans.quatityInLieu,
        Initiated: trans.Initiated,
        Approved: trans.Approved,
        createdOn: trans.createdOn,
        Checked: {
          by: { name: this.usd.acc.name, id: this.usd.userId },
          status: true,
          date: new Date().toISOString(),
        },
      };
      if (trans.component === "Sale") {
        console.log(trans);
        const fndIndex = this.ob.transArr.findIndex((x) => x.id === trans.id);
        this.ob.transArr[fndIndex] =
          fndIndex > -1 ? data : this.ob.transArr[fndIndex];
        this.ob.allPrjTrans = of(this.ob.transArr);
        const fndSIndex = this.ob.salesTrans.findIndex(
          (x) => x.id === trans.id
        );
        this.ob.salesTrans[fndSIndex] =
          fndSIndex > -1 ? data : this.ob.salesTrans[fndSIndex];
        this.ob.projectData.salesTrans = this.ob.salesTrans;
        if (this.ob.saleResults?.length > 0) {
          const sfndIndes = this.ob.saleResults.findIndex(
            (x) => x.id === trans.id
          );
          this.ob.saleResults[sfndIndes] =
            sfndIndes > -1 ? data : this.ob.saleResults[sfndIndes];
        }
      }
      this.appendtrans(data);
      // } else {
      //   this.fixTransIds(trans);
    }
  }

  approveSale() {
    // console.log(this.ob.transaction, 'transType', this.ob.transaction.transType, 'transClass', this.ob.transaction.transClass);
    const trans: any = this.ob.saletrans;
    const data: compTans = {
      read: false,
      noti: "inventrySaleNotice",
      name: trans?.name,
      id: trans.id,
      extrainfo: trans.extrainfo || "",
      project: trans?.project,
      company: trans?.company,
      by: trans.by,
      saleType: trans?.saleType,
      product: trans.product,
      parties: trans.parties,
      rate: trans.rate,
      amount: trans.amount,
      transType: trans.transType,
      transClass: trans.transClass,
      date: trans.date,
      component: trans.component,
      paymentMethod: trans.paymentMethod,
      products: trans.products,
      amountInLieu: trans.amountInLieu,
      quatityInLieu: trans.quatityInLieu,
      Initiated: trans.Initiated,
      Checked: trans.Checked,
      createdOn: trans.createdOn,
      Approved: {
        by: { name: this.usd.acc.name, id: this.usd.userId },
        status: true,
        date: new Date().toISOString(),
      },
    };
    // console.log(data);
    const inv = trans.product;
    inv.SellStatus = {
      status: "Sold",
      payment: "Pending ...",
      customer: trans.parties,
      date: trans.date,
    };
    // this.updateInventory(inv);
    // const fndIndex = this.ob.salesTs
    // this.ob.saleTrans();
    console.log(trans);
    const fndIndex = this.ob.transArr.findIndex((x) => x.id === trans.id);
    this.ob.transArr[fndIndex] =
      fndIndex > -1 ? data : this.ob.transArr[fndIndex];
    this.ob.allPrjTrans = of(this.ob.transArr);
    const fndSIndex = this.ob.salesTrans.findIndex((x) => x.id === trans.id);
    this.ob.salesTrans[fndSIndex] =
      fndSIndex > -1 ? data : this.ob.salesTrans[fndSIndex];
    this.ob.projectData.salesTrans = this.ob.salesTrans;
    if (this.ob.saleResults?.length > 0) {
      const sfndIndes = this.ob.saleResults.findIndex((x) => x.id === trans.id);
      this.ob.saleResults[sfndIndes] =
        sfndIndes > -1 ? data : this.ob.saleResults[sfndIndes];
    }
    this.ob.sales(this.usd.setProject?.id);
    this.appendtrans(data);
  }

  savePayement() {
    // console.log(this.ob.payTransaction, 'transType', this.ob.payTransaction.transType, 'transClass', this.ob.payTransaction.transClass);
    const trans = this.ob.payTransaction;
    const data: compTans = {
      read: false,
      noti: "inventNotice",
      name:
        trans?.product?.type?.value ||
        "" + trans?.product?.productNo?.name ||
        "" + " of " + trans?.product?.parent ||
        "",
      id: "",
      project: { name: this.usd.setProject.name, id: this.usd.setProject.id },
      company: {
        name: this.usd.setProject.companyName || "",
        id: this.usd.setProject.companyId,
      },
      by: { name: this.usd.acc.name, id: this.usd.userId },
      extrainfo: trans.extrainfo || "",
      saleType: trans?.saleType,
      product: trans.product,
      parties: trans.parties,
      rate: { name: trans.rate.name, verified: false, approved: false },
      amount: {
        cost: trans.amount.cost,
        paid: trans.amount.paid,
        balance: trans.amount.balance,
        currency: trans.amount.currency,
      },
      transType: "Payment",
      transClass: trans.transClass,
      date: trans.date,
      component: "Payment",
      paymentMethod: trans.paymentMethod,
      products: trans.products,
      amountInLieu: trans.amountInLieu,
      quatityInLieu: trans.quatityInLieu,
      Initiated: {
        by: { name: this.usd.acc.name, id: this.usd.userId },
        status: true,
        date: new Date().toISOString(),
      },
      Checked: { by: { name: "", id: "" }, status: false, date: "" },
      Approved: { by: { name: "", id: "" }, status: false, date: "" },
      createdOn: new Date().toISOString(),
    };
    if (!trans?.product?.type?.value && trans?.product?.parent) {
      data.name = trans?.product?.name;
    }
    // console.log(data);
    // this.unitrans(data);
    this.unitrans(data, "payment");
  }

  payementReceipt() {
    // console.log(this.ob.payTransaction, 'transType', this.ob.payTransaction.transType, 'transClass', this.ob.payTransaction.transClass);
    const trans = this.ob.payTransaction;
    const data = {
      read: false,
      noti: "inventNotice",
      name:
        trans?.product?.type?.value ||
        "" + trans?.product?.productNo?.name ||
        "" + " of " + trans?.product?.parent ||
        "",
      id: "",
      project: { name: this.usd.setProject.name, id: this.usd.setProject.id },
      company: {
        name: this.usd.setProject.companyName || "",
        id: this.usd.setProject.companyId,
      },
      by: { name: this.usd.acc.name, id: this.usd.userId },
      extrainfo: trans.extrainfo,
      saleType: trans?.saleType,
      product: trans.product,
      parties: trans.parties,
      rate: { name: trans.rate.name, verified: false, approved: false },
      amount: {
        cost: trans.amount.cost,
        paid: trans.amount.paid,
        balance: trans.amount.balance,
        currency: trans.amount.currency,
      },
      transType: "Payment Receipt",
      transClass: trans.transClass,
      date: trans.date,
      component: "Payment Receipt",
      paymentMethod: trans.paymentMethod,
      products: trans.products,
      amountInLieu: trans.amountInLieu,
      quatityInLieu: trans.quatityInLieu,
      Initiated: {
        by: { name: this.usd.acc.name, id: this.usd.userId },
        status: true,
        date: new Date().toISOString(),
      },
      Checked: { by: { name: "", id: "" }, status: false, date: "" },
      Approved: { by: { name: "", id: "" }, status: false, date: "" },
      createdOn: new Date().toISOString(),
    };
    if (!trans?.product?.type?.value && trans?.product?.parent) {
      data.name = trans?.product?.name;
    }
    // console.log(data.extrainfo, data);
    // this.unitrans(data);
    this.unitrans(data, "payReceipt");
  }

  async deletePayment() {
    const trans: any = this.ob.payTransaction;
    trans.deleted = {
      by: { name: this.usd.acc.name, id: this.usd.userId },
      status: true,
      date: new Date().toISOString(),
    };
    trans.transType = "Payment Delete";
    let admin = await this.managers();
    if (admin.length > 0) {
      trans.participants = admin;
      admin.forEach((element) => {
        this.delTrans(element, trans);
      });
    } else {
    }
  }

  updatetransArch(data) {
    // console.log(data.name, data);
  }

  updatetransMom(data) {
    if ((data.description || data.product.description) && data.name !== data.description) {
      data.name = data.description ? data.description : data.product.description;
    }
    console.log(data.name, data.id, data);
    const rootTrans = this.usd.afs.doc(`Transactions/${data.id}`);
    try {
      rootTrans.update(data).then(() => {console.log('rootTrans set successfully') }).catch((er) => console.log("failed to set rootTrans"));
    } catch (error) {
      // console.log(error);
    }
  }

  updatetrans(data) {
    if ((data.description || data.product.description) && data.name !== data.description) {
      data.name = data.description ? data.description : data.product.description;
    }
    console.log(data.name, data.id, data);
    // const inRef = this.usd.afs.doc(`Users/${this.usd.userId}/transactions/${data.id}`);
    const rootTrans = this.usd.afs.doc(`Transactions/${data.id}`);
    // inRef.update(data).then(() => {
    if (data.parties && data.parties?.length > 0) {
      data.parties.forEach((element) => {
        const maillist = [];
        if (element.srTag === "ind") {
          this.updateManagersTrans(element, data);
          maillist.push(element.bus_email || element.email);
        }
        if (element.srTag === "ent") {
          if (element?.contactPerson?.name !== "" && (element?.contactPerson?.email !== "" || element?.contactPerson?.bus_email !== "")) {
            this.updateManagersTrans(element?.contactPerson, data);
            maillist.push(element.bus_email || element.email);
          }
          if (element?.champion?.name !== "" && (element?.champion?.email !== "" || element?.champion?.bus_email !== "")) {
            this.updateManagersTrans(element?.champion, data);
            maillist.push(element.bus_email || element.email);
          }
        }
        Promise.all(maillist).then(() => {
          const dataMail: any = data;
          dataMail.maillist = maillist;
          this.transmailGroup(data);
        });
      });
    }
    if (data.participants && data.participants?.length > 0) {
      data.participants.forEach((each) => {
        this.updateManagersTrans(each, data);
      });
    }
    this.entTransactions(data);
    this.prjtransactions(data);
    // }).catch((er) => {
    //   console.log('inRef Er' ,er);
    //   inRef.set(data).then(() => { console.log('Resolved inRef Er')}).catch(inRefErr => console.log(inRefErr))
    // });
    data.product.Amount.cost = Number(this.is.numberForm(data.amount.cost));
    const invFnd = this.ob.data.find((x) => x.id === data.product.id);
    if (invFnd && data.product.id) {
      console.log(Number(this.is.numberForm(data.amount?.cost)), invFnd.Amount?.cost);
      invFnd ? this.invUpdate(data.product) : console.log("Not Found");
      // console.log(data.product.Amount.cost, invFnd, data);
    }
  }

  updateTrans(client, data) {
    // console.log(data.name);
    if ((data.description || data.product.description) && data.name !== data.description) {
      data.name = data.description ? data.description : data.product.description;
    }
    const inRef = this.usd.afs.doc(`Users/${client.id}/transactions/${data.id}`);
    const byRef = this.usd.afs.doc(`Users/${data?.by.id}/transactions/${data.id}`);
    const rootTrans = this.usd.afs.doc(`Transactions/${data.id}`);
    try {
      inRef.update(data).then(() => {
        // console.log('user tansaction updated');
        // byRef.update(data);
        if (data.product.initiated?.by.id) {
          this.usd.afs.doc(`Users/${data?.product?.initiated?.by?.id}/transactions/${data.id}`).update(data);
        }
        if (data.product.checked?.by.id) {
          this.usd.afs.doc(`Users/${data?.product?.checked?.by?.id}/transactions/${data.id}`).update(data);
        }
        if (data.product.approved?.by.id) {
          this.usd.afs.doc(`Users/${data?.product?.approved?.by?.id}/transactions/${data.id}`).update(data);
        }
        if (data.Initiated?.by.id) {
          this.usd.afs.doc(`Users/${data?.Initiated?.by?.id}/transactions/${data.id}`).update(data);
        }
        if (data.Checked?.by.id) {
          this.usd.afs.doc(`Users/${data?.product?.Checked?.by?.id}/transactions/${data.id}`).update(data);
        }
        if (data.Approved?.by.id) {
          this.usd.afs.doc(`Users/${data?.product?.Approved?.by?.id}/transactions/${data.id}`).update(data);
        }
        rootTrans.update(data);
        if (data.parties && data.parties?.length > 0) {
          data.parties.forEach((element) => {
            const maillist = [];
            if (element.srTag === "ind") {
              this.updateManagersTrans(element, data);
              maillist.push(element.bus_email || element.email);
            }
            if (element.srTag === "ent") {
              if (element?.contactPerson?.name !== "" && (element?.contactPerson?.email !== "" || element?.contactPerson?.bus_email !== "")) {
                this.updateManagersTrans(element?.contactPerson, data);
                maillist.push(element.bus_email || element.email);
              }
              if (element?.champion?.name !== "" && (element?.champion?.email !== "" || element?.champion?.bus_email !== "")) {
                this.updateManagersTrans(element?.champion, data);
                maillist.push(element.bus_email || element.email);
              }
            }
            Promise.all(maillist).then(() => {
              const dataMail: any = data;
              dataMail.maillist = maillist;
              this.transmailGroup(data);
            });
          });
        }
        this.entTransactions(data);
        this.prjtransactions(data);
      });
    } catch (error) {
      // console.log(error);
    }
  }

  updateSale(data) {
    console.log(data.name, data.id, data);
    if ((data.description || data.product.description) && data.name !== data.description) {
      data.name = data.description ? data.description : data.product.description;
    }
    if (data.id) {
      // if (data.component === 'Sale') {
      //   console.log(data);
      //   const fndIndex = this.ob.transArr.findIndex(x => x.id === data.id);
      //   this.ob.transArr[fndIndex] = fndIndex > -1 ? data : this.ob.transArr[fndIndex];
      //   this.ob.allPrjTrans = of(this.ob.transArr);
      //   const fndSIndex = this.ob.salesTrans.findIndex(x => x.id === data.id);
      //   this.ob.salesTrans[fndSIndex] = fndSIndex > -1 ? data: this.ob.salesTrans[fndSIndex];
      //   this.ob.projectData.salesTrans = this.ob.salesTrans;
      //   if (this.ob.saleResults?.length > 0) {
      //     const sfndIndes = this.ob.saleResults.findIndex(x => x.id === data.id);
      //     this.ob.saleResults[sfndIndes] = sfndIndes > -1 ? data: this.ob.saleResults[sfndIndes];
      //   }
      // }
      data.product.Amount.cost = Number(this.is.numberForm(data.amount.cost));
      const invFnd = this.ob.data.find((x) => x.id === data.product.id);
      if (data.product.id) {
        console.log(
          Number(this.is.numberForm(data.amount?.cost)),
          invFnd.Amount?.cost
        );
        invFnd ? this.invUpdate(data.product) : console.log("Not Found");
        // console.log(data.product.Amount.cost, invFnd, data);
      }
      this.appendtrans(data);
      // } else {
      //   this.fixTransIds(trans);
    }
  }

  saveTrans() {
    // this.ob.transaction.name
    // console.log(this.ob.transaction, 'transType', this.ob.transaction.transType, 'transClass', this.ob.transaction.transClass);
    const trans = this.ob.transaction;
    const data: transaction = {
      read: false,
      noti: "inventryNotice",
      name: trans?.product?.name,
      id: "",
      project: trans?.project,
      company: trans?.company,
      by: { name: this.usd.acc.name, id: this.usd.userId },
      saleType: trans?.saleType,
      product: trans.product,
      parties: trans.parties,
      rate: { name: trans.rate.name, verified: false, approved: false },
      amount: {
        cost: trans.amount.cost,
        paid: trans.amount.paid,
        balance: trans.amount.balance,
        currency: trans.amount.currency,
      },
      transType: trans.transType,
      transClass: trans.transClass,
      date: trans.date,
      createdOn: new Date().toISOString(),
    };
    // console.log(data);
    this.unitrans(data, "save");
  }

  unitrans(data, call) {
    console.log(data, call);
    // const inRef = this.usd.afs.collection(`Users/${this.usd.userId}/transactions`);
    const rootTrans = this.usd.afs.collection(`Transactions`);
    // try {
    rootTrans.add(data).then((dataRef) => {
      console.log("rootTrans tansaction created");
      const tid = dataRef.id;
      data.id = dataRef.id;
      data.product.tid = dataRef.id;
      data.product.tid = dataRef.id;
      rootTrans.doc(data.id).update({ id: tid }).then(() => console.log("rootTrans id updated successfully")).catch((er) => console.log("failed to set rootTrans"));
      // inRef.doc(data.id).set(data).then(() => {
      //     /* console.log('id updated') */
      // }).catch((er) => console.log(er));
      this.prjtransactions(data);
      this.entTransactions(data);
      if (call === "sale") {
        this.ob.salesTrans.push(data);
        console.log("salesTrans pushed");
        this.ob.projectData.salesTrans = this.ob.salesTrans;
        // this.ob.projectData.salesTrans.sort((a ,b) => )
      }
      // this.updateTid(data.product, tid);
      if (data.parties && data.parties?.length > 0) {
        data.parties.forEach((element) => {
          const maillist = [];
          if (element.srTag === "ind") {
            this.updateManagersTrans(element, data);
            maillist.push(element.bus_email || element.email);
          }
          if (element.srTag === "ent") {
            if (
              element?.contactPerson?.name !== "" &&
              element?.contactPerson?.id &&
              element?.contactPerson?.id !== "" &&
              (element?.contactPerson?.email !== "" ||
                element?.contactPerson?.bus_email !== "")
            ) {
              this.updateManagersTrans(element?.contactPerson, data);
              maillist.push(element.bus_email || element.email);
            }
            if (
              element?.champion?.name !== "" &&
              element?.champion?.id &&
              element?.champion?.id !== "" &&
              (element?.champion?.email !== "" ||
                element?.champion?.bus_email !== "")
            ) {
              this.updateManagersTrans(element?.champion, data);
              maillist.push(element.bus_email || element.email);
            }
          }
          Promise.all(maillist).then(() => {
            const dataMail: any = data;
            dataMail.maillist = maillist;
            this.transmailGroup(data);
          });
        });
      }
      if (this.ob.ent?.structuralRole?.id !== "maincontructactive") {
        this.mainTransactions(data);
      }
    })
    .catch((er) => console.log(er));
    // } catch (error) {
    //     console.log(error);
    //     this.prjtransactions(data);
    //     this.resetSale(data.product);
    // }
  }

  fixTransIds(x) {
    if (x.product.productNo.name) {
      this.usd.afs
        .collection<any>(`Transactions`, (ref) =>
          ref.where("product.productNo.name", "==", x.product.productNo.name)
        )
        .snapshotChanges()
        .pipe(
          map((b) =>
            b.map((a) => {
              const data = a.payload.doc.data() as any;
              data.id = a.payload.doc.id;
              return data;
            })
          )
        )
        .subscribe((ds: any[]) => {
          if (
            ds?.length === 1 &&
            ds[0].product.productNo.name === x.product.productNo.name
          ) {
            const id = ds[0].id;
            console.log(id);
            this.usd.afs
              .doc<any>(`Transactions/${ds[0].id}`)
              .update({ id: id })
              .then(() => {
                this.ns.showNotification("transfix", "top", "right", x);
              });
          }
        });
    }
  }

  appendtrans(data) {
    if (data.id) {
      // const inRef = this.usd.afs.doc(`Users/${this.usd.userId}/transactions/${data.id}`);
      const rootTrans = this.usd.afs.doc(`Transactions/${data.id}`);
      // inRef.set(data).then((dataRef) => {
          // console.log('user tansaction created');
      rootTrans.set(data).then(() => {
          /* console.log('rootTrans set successfully') */
      })
      .catch((er) => console.log("failed to set rootTrans"));
      // this.updateTid(data.product, tid);
      if (data.parties && data.parties?.length > 0) {
        data.parties.forEach((element) => {
          const maillist = [];
          if (element.srTag === "ind") {
            this.updateManagersTrans(element, data);
            maillist.push(element.bus_email || element.email);
          }
          if (element.srTag === "ent") {
            if (
              element?.contactPerson?.name !== "" &&
              element?.contactPerson?.id &&
              element?.contactPerson?.id !== "" &&
              (element?.contactPerson?.email !== "" ||
                element?.contactPerson?.bus_email !== "")
            ) {
              this.updateManagersTrans(element?.contactPerson, data);
              maillist.push(element.bus_email || element.email);
            }
            if (
              element?.champion?.name !== "" &&
              element?.champion?.id &&
              element?.champion?.id !== "" &&
              (element?.champion?.email !== "" ||
                element?.champion?.bus_email !== "")
            ) {
              this.updateManagersTrans(element?.champion, data);
              maillist.push(element.bus_email || element.email);
            }
          }
          Promise.all(maillist).then(() => {
            const dataMail: any = data;
            dataMail.maillist = maillist;
            this.transmailGroup(data);
          });
        });
      }
      this.entTransactions(data);
      this.prjtransactions(data);
      // })
      // .catch((er) => console.log(er));
    } else {
      console.log("No ID found");
      this.fixTransIds(data);
    }
  }

  transmailGroup(noti) {
    // console.log(noti);
    if (!noti.projectId || !noti.project?.id) {
      noti.projectName = "No Project";
    }
    if (!noti.companyId || !noti.company?.id) {
      noti.companyName = "No Company";
    }
    if (noti.champion?.bus_email || noti.champion?.email) {
      this.http
        .tasking(
          " https://us-central1-colors-a07e8.cloudfunctions.net/transmailGroup",
          noti
        )
        .subscribe(
          (data) => {
            const res: any = data;
            // console.log(res);
            console.log(
              `👏 > 👏 > 👏 > 👏 ${noti.name}'s email sent and the message id is ${res.messageId}`
            );
          },
          (err) => {
            console.log(err);
          },
          () => {}
        );
    }
  }

  savePhase(phase) {
    phase.createdOn = new Date().toISOString();
    phase.createdBy = { id: this.usd.acc.id, name: this.usd.acc };
    // console.log(prType);
    const phaseCol = this.usd.afs.collection<any>(
      `Projects/${this.usd.setProject.id}/phases`
    );
    phaseCol
      .add(phase)
      .then((ref) => {
        const id = ref.id;
        phaseCol.doc(id).update({ id: id });
        // this.ob.getTransTypes();
      })
      .catch((error) => {
        console.log("Error capture", error);
      });
    // console.log('sub added submitted');
    this.ps.getProductTypes();
    return { name: "", amount: "", unit: "" };
  }

  updatePhase(et) {
    et.updatedOn = new Date().toISOString();
    this.usd.afs
      .doc<any>(`Projects/${this.usd.setProject.id}/phases/${et.id}`)
      .update(et);
    return { name: "", unit: null };
  }

  remPhase(et) {
    this.usd.afs
      .doc<any>(`Projects/${this.usd.setProject.id}/phases/${et.id}`)
      .delete()
      .then(() => {
        /* console.log(`${et.name} deleted`) */
      })
      .catch((er) => console.log(`delete failed, ${er}`));
    return { name: "", unit: null };
  }

  saveProductType(prType) {
    prType.createdOn = new Date().toISOString();
    prType.createdBy = { id: this.usd.acc.id, name: this.usd.acc };
    // console.log(prType);
    const dsTasks = this.usd.afs.collection<any>(
      `Projects/${this.usd.setProject.id}/productType`
    );
    dsTasks
      .add(prType)
      .then((ref) => {
        const id = ref.id;
        dsTasks.doc(id).update({ id: id });
        this.ob.getTransTypes();
      })
      .catch((error) => {
        console.log("Error capture", error);
      });
    // console.log('sub added submitted');
    this.ps.getProductTypes();
    return { name: "", unit: null };
  }

  updateProductType(et) {
    this.usd.afs
      .doc<any>(`Projects/${this.usd.setProject.id}/productType/${et.id}`)
      .update({ name: et.name, unit: et.unit });
    return this.is.initProd();
  }

  updateProductAmount(et) {
    this.usd.afs
      .doc<any>(`Projects/${this.usd.setProject.id}/productType/${et.id}`)
      .update({ amount: et.amount })
      .then(() => {
        /* console.log(`${et.amount} updated`) */
      })
      .catch((er) => console.log(`failed to update amount, ${er}`));
  }

  remProductType(et) {
    this.usd.afs
      .doc<any>(`Projects/${this.usd.setProject.id}/productType/${et.id}`)
      .delete()
      .then(() => {
        /* console.log(`${et.name} deleted`) */
      })
      .catch((er) => console.log(`delete failed, ${er}`));
    return this.is.initProd();
  }

  saveTypeTrans(trans) {
    trans.createdOn = new Date().toISOString();
    trans.createdBy = { id: this.usd.acc.id, name: this.usd.acc };
    // console.log(trans);
    const dsTasks = this.usd.afs.collection<any>(
      `Projects/${this.usd.setProject.id}/transType`
    );
    dsTasks
      .add(trans)
      .then((ref) => {
        const id = ref.id;
        dsTasks.doc(id).update({ id: id });
        this.ob.getTransTypes();
      })
      .catch((error) => {
        // console.log('Error capture', error)
      });
    //! console.log('sub added submitted');
    return { name: "", unit: null };
  }

  updateTypeTrans(et) {
    this.usd.afs
      .doc<any>(`Projects/${this.usd.setProject.id}/transType/${et.id}`)
      .update({ name: et.name, unit: et.unit });
    return { name: "", unit: null };
  }

  remTypeTrans(et) {
    this.usd.afs
      .doc<any>(`Projects/${this.usd.setProject.id}/transType/${et.id}`)
      .delete()
      .then(() => {
        /* console.log(`${et.name} deleted`) */
      })
      .catch((er) => console.log(`delete failed, ${er}`));
    return { name: "", unit: null };
  }

  clientData(cus) {
    //? tInventory
    this.checkInv(cus);
    //? transactions
    this.checkTrn(cus);
    //? afs ent and prj customer list
    this.checkCusList(cus);
    //? afs users list
    this.checkUserDb(cus);
  }

  reserveInv(inv, cus) {
    const action = "Reservation";
    const cusList = [];
    // console.log(inv.SellStatus?.customer);
    inv.SellStatus?.customer?.forEach((elem) => {
      const cusData = {
        name: elem.name, id: elem.id || "", photoURL: elem.photoURL || elem.userImg || "",
        email: elem?.bus_email || elem?.email || elem?.champion.email || elem?.champion.email,
        msg: elem.msg || ""
      };
      cusList.push(cusData);
    });
    // console.log(cusList);
    inv.SellStatus = {
      status: "Reserved", payment: "Pending ...", customer: cusList, date: new Date().toISOString(),
      reservations: [],  by: { name: this.usd.acc.name, id: this.usd.userId }, tid: "",
    };
    if (cus && cus?.name && cus !== "") {
      inv.SellStatus.customer = [cus];
    } else {
      // console.log(inv.SellStatus.customer?.length);
    }
    this.updateInventory(inv, action);
  }

  cancelResInv(inv) {
    const action = "CancelReserve";
    const SellStatus = {
      status: "Available",
      payment: "",
      customer: [],
      date: "",
      reservations: [],
      by: { name: "", id: "" },
      tid: "",
    };
    const newPrd = inv;
    if (inv.SellStatus.reservations?.length > 0) {
      newPrd.SellStatus.customer = [
        inv.SellStatus.reservations[inv.SellStatus.reservations?.length - 1],
      ];
      newPrd.SellStatus = {
        status: "Reserved",
        payment: "Pending ...",
        date: new Date().toISOString(),
        customer: [
          inv.SellStatus.reservations[inv.SellStatus.reservations?.length - 1],
        ],
        reservations: inv.SellStatus.reservations.filter(
          (x) =>
            x.id ===
              inv.SellStatus.reservations[
                inv.SellStatus.reservations?.length - 1
              ].id ||
            x.name ===
              inv.SellStatus.reservations[
                inv.SellStatus.reservations?.length - 1
              ].name
        ),
        by: { name: this.usd.acc.name, id: this.usd.userId },
      };
    } else {
      newPrd.SellStatus = SellStatus;
    }
    if (newPrd?.reserveHistory) {
      inv.SellStatus.cancelBy = {
        name: this.usd.acc.name,
        id: this.usd.userId,
      };
      newPrd.reserveHistory.push(inv.SellStatus);
    } else {
      newPrd.reserveHistory = [inv.SellStatus];
    }
    this.updateInventory(newPrd, action);
  }

  checkInv(cus) {
    const invFnd = this.ob.data.filter((ins) =>
      ins?.parties?.find((item) => item.id === cus.id)
    );
    const invFnd2 = this.ob.data.filter((ins) =>
      ins?.SellStatus?.customer?.find((item) => item.id === cus.id)
    );
    // console.log('Inventory', invFnd?.length, invFnd);
    if (invFnd?.length > 0 && !invFnd2) {
      invFnd?.forEach((element) => {
        if (element?.parties?.length > 0) {
          const ind = element?.parties?.findIndex((i) => i.id === cus.id);
          if (ind > 1) {
            element.parties[ind] = cus;
            element.SellStatus.customer = element.parties;
            this.invUpdate(element);
          }
        }
      });
    } else if (
      (invFnd2?.length > 0 && !invFnd) ||
      (invFnd?.length > 0 && invFnd2?.length > 0)
    ) {
      invFnd2?.forEach((element) => {
        if (element?.SellStatus.customer?.length > 0) {
          const ind = element?.SellStatus.customer.findIndex(
            (i) => i.id === cus.id
          );
          if (ind > 1) {
            element.SellStatus.customer[ind] = cus;
            element.parties = element.SellStatus.customer;
            this.invUpdate(element);
          }
        }
      });
    } else {
      console.log("prts", invFnd2?.length, "Cus", invFnd?.length);
    }
  }

  checkTrn(cus) {
    const tnsFnd = this.ob.obsTransArr.filter((ins) =>
      ins?.parties?.find((item) => item.id === cus.id)
    );
    // console.log('Transactions', tnsFnd?.length, tnsFnd);
    if (tnsFnd?.length > 0) {
      tnsFnd?.forEach((element) => {
        if (element?.parties?.length > 0) {
          const ind = element?.parties?.findIndex((i) => i.id === cus.id);
          if (ind > 1) {
            element.parties[ind] = cus;
            // console.log('checkTrn');
            this.updatetrans(element);
          }
        }
      });
    }
  }

  checkCusList(client) {
    const entRef = this.usd.afs.doc(
      `Enterprises/${this.usd.setCompany.id}/clients/${client.id}`
    );
    const prRef = this.usd.afs.doc(
      `Projects/${this.usd.setProject.id}/enterprises/${this.usd.setProject.companyId}/clients/${client.id}`
    );
    try {
      entRef.ref.get().then((refData) => {
        if (refData.exists) {
          // console.log('Ent CusList', refData.data());
          entRef.update(client);
        }
      });
      prRef.ref.get().then((refData) => {
        if (refData.exists) {
          // console.log('Projects Cuslist', refData.data());
          prRef.update(client);
        }
      });
    } catch (error) {
      console.log(error);
    }
  }

  checkUserDb(client) {
    const userRef = this.usd.afs.doc(`Users/${client.id}`);
    const entRef = this.usd.afs.doc(`Enterprises/${client.id}`);
    try {
      if (client.srTag === "ind") {
        userRef.ref.get().then((refData) => {
          if (refData.exists) {
            // console.log('Users List', refData.data())
            userRef.update(client);
          }
        });
      } else {
        entRef.ref.get().then((refData) => {
          if (refData.exists) {
            // console.log('Ent List', refData.data())
            entRef.update(client);
          }
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  setPubshow(ds) {
    // console.log(ds.name, ds);
    this.usd.afs
      .doc(
        `Projects/${this.pub.setProject.id}/enterprises/${ds.cmpId}/Rates/${ds.id}`
      )
      .update({ pubshow: ds.pubshow })
      .then(() => {
        // console.log('type of task updated successfully', ds.name, 'id', ds.id);
      })
      .catch((er) => console.log(`type of task update failed`, er));
  }
}
