import { Component, OnInit, TemplateRef, ViewChild, Inject, NgZone } from '@angular/core';
import { InitialiseService } from 'app/services/initialise.service';
import {
  Task,
  actionActualData,
  actualData,
  Unit,
} from 'app/models/task-model';
import * as moment from 'moment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserDataService } from 'app/services/user-data.service';

import { Subject, of, concat, Observable } from 'rxjs';
import { catchError, distinctUntilChanged, switchMap, tap, delay, map } from 'rxjs/operators';
import { WriteService } from 'app/services/writes.service';
import {
  asset,
  client,
  companyStaff,
  Department,
  Enterprise,
  Labour,
} from 'app/models/enterprise-model';
import { ProjServiceService } from 'app/services/proj-service.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { EntServiceService } from 'app/services/ent-service.service';
import { IconsService } from 'app/services/icons.service';
import { Project } from 'app/models/project-model';
import { OpenBooksService } from 'app/services/open-books.service';
import { CurrencyPipe } from '@angular/common';
import { TaskService } from 'app/services/task.service';
import { HttpClient } from '@angular/common/http';
import { DOCUMENT } from '@angular/common';
import { ZoomMtg } from '@zoom/meetingsdk';

declare var $: any;

@Component({
  styleUrls: ['./taskplugin.component.css'],
  moduleId: module.id,
  selector: 'app-taskplugin',
  templateUrl: 'taskplugin.component.html',
})

export class TaskPluginComponent implements OnInit {
  yut: { name: ''; unit: ''; rate: '' };
  unit: { name: ''; id: '' };
  uni: { name: ''; id: '' };
  department: Department;
  messageTag: string;
  msgErr: boolean;
  public showList = true;
  public newUnitfrm = false;
  public showColUsers = true;
  // showsCal: boolean;
  // showfCal: boolean;
  public showShaff = false;
  // public showEmaiCheck = true;
  public showListe = true;
  public showListp = true;
  public showsetUser = false;
  public addDpt = false;
  // public AddEquip = false;
  // champion;
  value = '';
  setContact;
  classification;
  addClass;
  public fromClient = false;
  setCompany: any;
  public showChampSelect = false;
  public showSect = true;
  teamLabour: Labour[];
  newAss: asset;
  hiredAss: asset;
  showSelect = true;
  setProject: Project;
  setEnterprise: Enterprise;
  changList = [];
  n = 1;
  startDate: string;
  endDate: string;
  clientAttens: any;
  setCompanyChamp: any;
  newChamp: companyStaff;
  startDateError: { status: boolean; msg: string; };
  endDateError: { status: boolean; msg: string; };
  resCust: { email: string; name: string; };
  /* Transaction */

  payment = null;
  type: { value: '', verified: false, approved: false, amount: "", by: "", byId: "", companyId: "", companyName: "",
  createdBy: { name: '', id: ''},  createdOn: "", id: "",  name: "", rate: "", unit: "" }
  inventryUnit: any = { name: '', approved: false , verified: false };
  timeout: any = null;
  cusResults: any[];
  load: string;
  peopleLoading = false;
  peopleInput$ = new Subject<string>();
  cusFnd: any;
  people$: Observable<any[]>;
  public unitList = true;
  public showColUsers2 = 'showColUsers';
  extrainfo: 'Data';
  editCmp = true;
  public prjCustormers = true;

  listOfItems = [
    { id: 1, name: 'Statements' },
    { id: 2, name: 'Invoices' },
    { id: 3, name: 'Payments' },
    { id: 4, name: 'Technical Support' },
    { id: 5, name: 'Compliant Issues' },
    { id: 6, name: 'Property Details'},
    { id: 7, name: 'Council Approvals'},
  ];

  communicationChannel = [
    { id: 1, name: 'Email' },
    { id: 2, name: 'Voice Call' },
    { id: 3, name: 'WhatsApp' },
  ];


  selectedChannel = this.communicationChannel[0].name;
  queryType = this.listOfItems[0].name;

  authEndpoint = ''
  sdkKey = ''
  meetingNumber = '123456789'
  passWord = ''
  role = 0
  userName = 'Angular'
  userEmail = ''
  registrantToken = ''
  zakToken = ''
  leaveUrl = 'http://localhost:4200'

  constructor(
    public is: InitialiseService,
    public modalService: NgbModal, public tsk: TaskService,
    public usd: UserDataService, public httpClient: HttpClient, @Inject(DOCUMENT) document: any, private ngZone: NgZone,
    public ws: WriteService,
    public ps: ProjServiceService,
    public afs: AngularFirestore,
    public es: EntServiceService,
    public ico: IconsService, public ob: OpenBooksService, private currencyPipe : CurrencyPipe
  ) {

    // console.log(this.usd.acc.name, this.tsk.queryForm.firstName);
  
    this.startDateError = { status: false, msg: '' };
    this.endDateError = { status: false, msg: '' };
    this.usd.task = is.getTask();
    this.usd.editTask = is.getTask();
    // this.champion = is.getUserChampion();
    this.usd.task.champion = usd.acc;
    this.classification = is.getClassInit();
    this.usd.task.champCompany = { name: '', id: '' };


    /* Trans */
    this.ob.inventory = is.initInventory();
    this.ob.setInventory = is.initInventory();
    this.resCust = {
      email: '', name: ''
    }
    this.ob.entInvent = this.is.initEntInventory();
  }
  
  public scrollTo(className: string): void {
    try {
      const elementList = document.querySelectorAll('.' + className);
      const element = elementList[0] as HTMLElement;
      // https://www.w3schools.com/cssref/pr_scroll-behavior.asp
      element.scrollIntoView({ behavior: 'smooth' });
    } catch (error) { }
  }

  startMeeting(signature: any) {

    document.getElementById('zmmtg-root')!.style.display = 'block'

    this.ngZone.runOutsideAngular(() => {
      ZoomMtg.init({
        leaveUrl: this.leaveUrl,
        patchJsMedia: true,
        leaveOnPageUnload: true,
        success: (success: any) => {
          console.log(success)
          ZoomMtg.join({
            signature: signature,
            sdkKey: this.sdkKey,
            meetingNumber: this.meetingNumber,
            passWord: this.passWord,
            userName: this.userName,
            userEmail: this.userEmail,
            tk: this.registrantToken,
            zak: this.zakToken,
            success: (success: any) => {
              console.log(success)
            },
            error: (error: any) => {
              console.log(error)
            }
          })
        },
        error: (error: any) => {
          console.log(error)
        }
      })
    })
  }
  
  getSignature() {
    this.httpClient.post(this.authEndpoint, {
	    meetingNumber: this.meetingNumber,
	    role: this.role
    }).subscribe((data: any) => {
      if(data.signature) {
        console.log(data.signature)
        this.startMeeting(data.signature)
      } else {
        console.log(data)
      }
    })
  }

  contentLoaded() {
    console.log('File loaded');
  }

  showChamp(man) {
    console.log(man);
  }

  onSubmit(queryForm: any) {
    if (queryForm) {
      const queryData = {
        firstName: this.usd.acc.name,
        selectedChannel: queryForm.selectedChannel,
        preferredContact: this.getPreferredContact(queryForm),
        queryType: queryForm.queryType,
        queryDetails: queryForm.queryDetails
      };
      console.log(queryData);
      this.usd.task.champCompany = this.usd.setClient;
      this.usd.task.by = this.usd.acc.name;
      this.usd.task.byId = this.usd.acc.id;
      this.usd.task.description = queryData.queryDetails;
      this.usd.task.queryDetails = queryData;
      this.usd.task.name = 'Query';
      this.usd.task.type = { name: 'Query', id: 'query' };
      this.usd.task.participants = this.usd.setClient?.managers || [];
      this.usd.task.unit = this.is.allunits.find(x => x.name === 'Items').id;
      this.usd.task.createdOn =  new Date().toISOString();
      console.log("Query Data: ", this.usd.task);
      this.ws.tasker(this.usd.task, '');
    }
  }

  getPreferredContact(formData: any) {
    console.log(formData.selectedChannel);
    if (formData.selectedChannel === 'Voice Call') {
      console.log(formData.phoneNumber);
      return formData.phoneNumber;
    } else if (formData.selectedChannel === 'WhatsApp') {
      console.log(formData.whatsApp);
      return formData.whatsApp;
    } else if (formData.selectedChannel === 'Email') {
      console.log(formData.email);
      return formData.email;
    } else {
      return '';
    }
    // return null;
  }

  cusArr(item) {
    return item?.parties?.length > 0 ? item?.parties: item?.SellStatus?.customer?.length > 0 ? item?.SellStatus?.customer: []
  }

  setSp() {
    const sup = {
      name: this.usd.supervisor.name,
      id: this.usd.supervisor.id
    }
    this.usd.selectTask.supervisor = sup;
    console.log(this.usd.selectTask?.supervisor);
  }

  setHudle() {
    console.log(this.setCompanyChamp);
    if (this.setCompanyChamp === 'host') {
      console.log(this.setCompanyChamp);
      this.usd.task.champion = this.is.companystaff;
      this.fromClient = false;
      this.setChamp(this.usd.setCompany);
    } else if (this.setCompanyChamp === 'client') {
      console.log(this.setCompanyChamp);
      this.usd.task.champion = this.is.companystaff;
      this.fromClient = true;
      this.setChamp(this.usd.setClient);
    }
  }

  getList() {
    const num = this.n;
    this.usd.selectTask.champion = this.usd.editTask.champion;
    console.log(num);
    if (num === 2) {
      this.changList = this.usd.myContacts;
    } else if (num === 3) {
      console.log(
        this.usd.selectTask.companyId,
        this.usd.selectTask.companyName
      );
      this.afs
        .collection(`Enterprises/${this.usd.selectTask.companyId}/Participants`)
        .valueChanges()
        .subscribe((lbr) => {
          this.changList = lbr;
        });
    } else if (num === 4) {
      console.log(
        'EnterPrise',
        this.usd.selectTask.companyId,
        this.usd.selectTask.companyName
      );
      console.log(
        'Project',
        this.usd.selectTask.projectId,
        this.usd.selectTask.projectName
      );
      this.afs
        .collection(
          `Projects/${this.usd.selectTask.projectId}/enterprises/${this.usd.selectTask.companyId}/labour`
        )
        .valueChanges()
        .subscribe((lbr) => {
          this.changList = lbr;
        });
    } else if (num === 1) {
        console.log(num);
    } else {
    }
  }

  setEditedSub(startDate, endDate) {
    if (this.setEnterprise) {
      this.usd.editTask.companyId = this.setEnterprise.id;
      this.usd.editTask.companyName = this.setEnterprise.name;
    }
    if (this.setProject) {
      this.usd.editTask.projectId = this.setProject.id;
      this.usd.editTask.projectName = this.setProject.name;
    }
    if (this.usd.editTask.complete === true) {
      this.usd.editTask.complete = false;
    }
    this.ws.setEditedSub(this.usd.editTask);
    // this.ws.setEditedSub(this.usd.editTask,startDate, endDate);
  }

  getCompRates() {
    this.usd.editTask.companyId = this.setEnterprise.id;
    this.usd.editTask.companyName = this.setEnterprise.name;
    this.afs
      .collection(`Enterprises/${this.setEnterprise.id}/Rates`, (ref) =>
        ref.orderBy('name', 'asc')
      )
      .valueChanges()
      .subscribe((rates) => {
        this.usd.Rts = rates;
        console.log(rates);
      });
  }

  getPrjRates() {
    this.usd.editTask.projectId = this.setProject.id;
    this.usd.editTask.projectName = this.setProject.name;
    this.afs
      .collection(
        `Projects/${this.setProject.id}/enterprises/${this.usd.editTask.companyId}/Rates`,
        (ref) => ref.orderBy('name', 'asc')
      )
      .valueChanges()
      .subscribe((rates) => {
        this.usd.Rts = rates;
        console.log(rates);
      });
  }

  showSection() {
    console.log(this.usd.task.section);
  }

  setChamp(comp) {
    this.usd.task.champCompany = { name: comp?.name, id: comp?.id };
  }

  pageChampion() {
    this.usd.task.champion = this.usd.setContact;
  }

  getRate() {
    console.log(this.yut.unit);
    this.usd.editTask.name = this.yut.name;
    this.usd.task.name = this.yut.name;
    console.log(this.usd.task);
    if (this.yut.rate) {
      this.usd.task.rate = this.yut.rate;
      this.usd.editTask.rate = this.yut.rate;
      this.usd.task.unit = this.yut.unit;
    } else {
      this.usd.task.rate = '';
    }
  }

  nit(unit) {
    console.log(unit);
  }

  getUnit(unit) {
    this.usd.task.unit = unit.id;
  }

  showDept() {
    // console.log(dv.department);
    if (this.usd.task.champion && this.usd.task.champion?.name) {
      console.log(this.usd.task.champion?.name);
      console.log(this.usd.task.champion.department);
      this.usd.task.department = this.usd.task.champion.department;
      this.usd.task.companyId = this.usd.setCompany.id;
      this.usd.task.companyName = this.usd.setCompany.name;
      this.usd.task.departmentId = this.usd.task.champion.departmentId;
      const indDpt = this.es.departments.findIndex(
        (dpt) => dpt.id === this.usd.task.champion.departmentId
      );
      if (indDpt > -1) {
        this.department = this.es.departments[indDpt];
      }
      if (!this.usd.task.department) {
        this.usd.task.department = '';
      }
      if (!this.usd.task.departmentId) {
        this.usd.task.departmentId = '';
      }
      this.messageTag = '';
      this.msgErr = false;
    } else {
      this.messageTag = 'Error';
      this.msgErr = true;
    }
  }

  initReallocate() {
    if (
      this.usd.selectTask.companyId !== '' &&
      this.usd.selectTask.companyName !== '' &&
      this.usd.selectTask.projectId !== ''
    ) {
      console.log(
        'EnterPrise',
        this.usd.selectTask.companyId,
        this.usd.selectTask.companyName
      );
      console.log(
        'Project',
        this.usd.selectTask.projectId,
        this.usd.selectTask.projectName
      );
      this.afs
        .collection(
          `Projects/${this.usd.selectTask.projectId}/enterprises/${this.usd.selectTask.companyId}/labour`
        )
        .valueChanges()
        .subscribe((lbr) => {
          this.changList = lbr;
          this.n = 3;
        });
    } else if (
      this.usd.selectTask.companyId !== '' &&
      this.usd.selectTask.companyName !== '' &&
      this.usd.selectTask.projectId === ''
    ) {
      console.log(
        this.usd.selectTask.companyId,
        this.usd.selectTask.companyName
      );
      this.afs
        .collection(`Enterprises/${this.usd.selectTask.companyId}/Participants`)
        .valueChanges()
        .subscribe((lbr) => {
          this.changList = lbr;
          this.n = 2;
        });
    } else if (
      this.usd.selectTask.companyId === '' &&
      this.usd.selectTask.companyName === '' &&
      this.usd.selectTask.projectId === ''
    ) {
      this.changList = this.usd.myContacts;
    } else {
    }
  }

  taskDept() {
    // console.log(dv.department);
    if (this.department.name) {
      this.usd.task.department = this.department.name;
      this.usd.task.companyId = this.usd.setCompany.id;
      this.usd.task.companyName = this.usd.setCompany.name;
      this.usd.task.departmentId = this.department.id;
    }
  }

  ngOnInit() {
    if(this.is.module = 'transactions') {
      this.ob.initTeam();
    }
    this.uni = this.ob.initUnit();
    // this.loadCustomers();
    // this.people$.subscribe(x => console.log(x.length));
    this.peopleInput$.pipe(
      distinctUntilChanged(),
      switchMap(term => this.ob.value = term)
    );
    const $sidebar = $('.sidebar');
    const $off_canvas_sidebar = $('.off-canvas-sidebar');
    const window_width = window.outerWidth;
    if (window_width > 767) {
      if ($('.fixed-plugin .dropdown').hasClass('show-dropdown')) {
        $('.fixed-plugin .dropdown').addClass('open');
      }
    }

    //  Init Bootstrap Select Picker
    if ($('.selectpicker').length !== 0) {
      $('.selectpicker').selectpicker({
        iconBase: 'nc-icon',
        tickIcon: 'nc-check-2',
      });
    }

    $('.fixed-plugin a').click(function (event) {
      if ($(this).hasClass('switch-trigger')) {
        if (event.stopPropagation) {
          event.stopPropagation();
        } else if (window.event) {
          window.event.cancelBubble = true;
        }
      }
    });

    $('.fixed-plugin .background-color span').click(function () {
      $(this).siblings().removeClass('active');
      $(this).addClass('active');

      const new_color = $(this).data('color');
      if ($sidebar.length !== 0) {
        $sidebar.attr('data-color', new_color);
      }

      if ($off_canvas_sidebar.length !== 0) {
        $off_canvas_sidebar.attr('data-color', new_color);
      }
    });

    $('.fixed-plugin .active-color span').click(function () {
      $(this).siblings().removeClass('active');
      $(this).addClass('active');

      const new_color = $(this).data('color');

      if ($sidebar.length !== 0) {
        $sidebar.attr('data-active-color', new_color);
      }

      if ($off_canvas_sidebar.length !== 0) {
        $off_canvas_sidebar.attr('data-active-color', new_color);
      }
    });
    
  }

  checkDates() {
    console.log(this.usd.task.startDate);
    console.log(this.usd.task.endDate);
    if (moment(this.usd.task.endDate).isBefore(moment(this.usd.task.startDate))) {
      moment(this.usd.task.startDate);
      this.startDateError = {
        status: true, msg: "date can't be after Finish date"
      };
    } else { this.startDateError = { status: false, msg: '' }; }
    if (moment(this.usd.task.startDate).isAfter(moment(this.usd.task.endDate))) {
      console.log(moment(this.usd.task.startDate).diff(moment(this.usd.task.endDate)));
      this.endDateError = {
        status: true,  msg: 'select a date after Start date'
      };
    } else { this.endDateError = { status: false, msg: '' }; }
  }


  /* Transaction */

  
  onKeySearch(event: any) {
    // console.log(event);
    this.ob.value = event.target.value;
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      if (event.keyCode != 13) {
        // console.log(this.ob.value);
        this.searchCus(this.ob.value);
      }
    }, 2500);
  }

  trackByFn(item) {
    return item.id;
  }

  setProduct() {
    this.ob.inventory.type.value = this.type.name
    this.ob.inventory.unit.name = this.type.unit
    console.log(this.ob.inventory.type);
  }

  editSetProduct(){
    this.ob.inventory.type.value = this.is.type.name
    this.ob.inventory.unit.name = this.is.type.unit
    this.ob.inventory.description = (this.ob.inventory?.type?.value + ' ' + this.ob.inventory?.productNo?.name + ' of ' + this.ob.inventory.parent || '');
    console.log(this.ob.inventory.type);
  }

  setUnit(x) {
    console.log(x, this.inventryUnit);
    this.ob.inventory.unit.name = this.inventryUnit.id
  }

  searchCusEr(value, arr) {
    console.log(value);
    if(value) {
      // return st.concat(ind).pipe(delay(500));
      const ds = of(arr);
      return ds.pipe(delay(500));
    }
  }

  checkLoad() {
    if ((this.cusResults?.length > 0) && (this.ob.list?.length > 0) && (!this.ob.value || this.ob.value === '')) {
      this.peopleLoading = false;
    } else if(this.ob.value === '') {
      this.peopleLoading = false;
    } else {
      this.peopleLoading = true;
    }
  }

  private loadCustomers(arr) {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.people$ = concat(
        of([]), // default items
        this.peopleInput$.pipe(
            distinctUntilChanged(),
            // tap(() => this.peopleLoading = true),
            // switchMap(term => this.getPeople(term).pipe(
            switchMap(term => this?.searchCusEr(term, arr).pipe(
                catchError(() => of([])),
                tap(() => this.peopleLoading = false)
              ))
        ));
    }, 1000);
  }
  
  searchCus(value) {
    // console.log(value);
    // this.entResults = [];
    this.peopleLoading = true;
    // this.afs.collection<any>('Enterprises', ref => ref.where('name', ">=", value.toUpperCase()).where('name', "<=", value.toUpperCase() + "\uf8ff"))
    // this.afs.collection<any>('Enterprises', ref => ref.where('name', ">=", value.toLowerCase()))
    this.afs.collection<any>('Enterprises')
    .valueChanges().subscribe(customers => {
      // console.log('Enterprises');
      customers?.forEach(cmp => cmp.srTag = 'ent');
      // this.afs.collection<any>('Users', ref => ref.where('name', ">=", value.toUpperCase()).where('name', "<=", value.toUpperCase() + "\uf8ff"))
      this.afs.collection<any>('Users')
      .valueChanges().subscribe(people => {
        // console.log('Users');
        people?.forEach(cmp => cmp.srTag = 'ind');
        const fndData = customers.concat(people);
        this.filterSearch(fndData, value);
        this.loadCustomers(fndData);
      })
    })
    // console.log(result);
  }

  filterSearch(coll: any[], value,) {
    let word = value; const results = [];
    coll?.forEach(cus => {
      // const name = cus?.name?.toLowerCase();
      if (word !== '' || ' ') {
        // console.log('In Enterprise');
        word = word.toLowerCase();
        // console.log(word);
        // if (name?.includes(word)) {
        const exist = this.ob?.clients?.find((item) => item.id === cus.id);
        if (exist) {
          cus.join = true;
          results.push(cus);
        } else {
          cus.join = false;
          results.push(cus);
        }
        // console.log(results?.length);
        this.load = '';
        this.cusResults = results;
        if(results?.length >= 0) {
          this.peopleLoading = false
        }
      }
    });
    // return of(results)
  }

  getPeople(term: string = null): Observable<any[]> {
    console.log(term);
    let items = this.getMockPeople();
    if (term) {
        items = items.filter(x => x.name.toLocaleLowerCase().indexOf(term.toLocaleLowerCase()) > -1);
    }
    return of(items).pipe(delay(200));
  }

  getMockPeople() {
    return [
        {
            'id': '5a15b13c36e7a7f00cf0d7cb',
            'index': 2,
            'isActive': true,
            'picture': 'http://placehold.it/32x32',
            'age': 23,
            'name': 'Karyn Wright',
            'gender': 'female',
            'company': 'ZOLAR',
            'email': 'karynwright@zolar.com',
            'phone': '+1 (851) 583-2547'
        },
        {
            'id': '5a15b13c2340978ec3d2c0ea',
            'index': 3,
            'isActive': false,
            'picture': 'http://placehold.it/32x32',
            'age': 35,
            'name': 'Rochelle Estes',
            'disabled': true,
            'gender': 'female',
            'company': 'EXTRAWEAR',
            'email': 'rochelleestes@extrawear.com',
            'phone': '+1 (849) 408-2029'
        },
        {
            'id': '5a15b13c663ea0af9ad0dae8',
            'index': 4,
            'isActive': false,
            'picture': 'http://placehold.it/32x32',
            'age': 25,
            'name': 'Mendoza Ruiz',
            'gender': 'male',
            'company': 'ZYTRAX',
            'email': 'mendozaruiz@zytrax.com',
            'phone': '+1 (904) 536-2020'
        },
        {
            'id': '5a15b13cc9eeb36511d65acf',
            'index': 5,
            'isActive': false,
            'picture': 'http://placehold.it/32x32',
            'age': 39,
            'name': 'Rosales Russell',
            'gender': 'male',
            'company': 'ELEMANTRA',
            'email': 'rosalesrussell@elemantra.com',
            'phone': '+1 (868) 473-3073'
        },
        {
            'id': '5a15b13c728cd3f43cc0fe8a',
            'index': 6,
            'isActive': true,
            'picture': 'http://placehold.it/32x32',
            'age': 32,
            'name': 'Marquez Nolan',
            'gender': 'male',
            'company': 'MIRACLIS',
            'disabled': true,
            'email': 'marqueznolan@miraclis.com',
            'phone': '+1 (853) 571-3921'
        },
        {
            'id': '5a15b13ca51b0aaf8a99c05a',
            'index': 7,
            'isActive': false,
            'picture': 'http://placehold.it/32x32',
            'age': 28,
            'name': 'Franklin James',
            'gender': 'male',
            'company': 'CAXT',
            'email': 'franklinjames@caxt.com',
            'phone': '+1 (868) 539-2984'
        },
        {
            'id': '5a15b13cc3b9381ffcb1d6f7',
            'index': 8,
            'isActive': false,
            'picture': 'http://placehold.it/32x32',
            'age': 24,
            'name': 'Elsa Bradley',
            'gender': 'female',
            'company': 'MATRIXITY',
            'email': 'elsabradley@matrixity.com',
            'phone': '+1 (994) 583-3850'
        },
        {
            'id': '5a15b13ce58cb6ff62c65164',
            'index': 9,
            'isActive': true,
            'picture': 'http://placehold.it/32x32',
            'age': 40,
            'name': 'Pearson Thompson',
            'gender': 'male',
            'company': 'EZENT',
            'email': 'pearsonthompson@ezent.com',
            'phone': '+1 (917) 537-2178'
        },
        {
            'id': '5a15b13c90b95eb68010c86e',
            'index': 10,
            'isActive': true,
            'picture': 'http://placehold.it/32x32',
            'age': 32,
            'name': 'Ina Pugh',
            'gender': 'female',
            'company': 'MANTRIX',
            'email': 'inapugh@mantrix.com',
            'phone': '+1 (917) 450-2372'
        },
        {
            'id': '5a15b13c2b1746e12788711f',
            'index': 11,
            'isActive': true,
            'picture': 'http://placehold.it/32x32',
            'age': 25,
            'name': 'Nguyen Elliott',
            'gender': 'male',
            'company': 'PORTALINE',
            'email': 'nguyenelliott@portaline.com',
            'phone': '+1 (905) 491-3377'
        },
        {
            'id': '5a15b13c605403381eec5019',
            'index': 12,
            'isActive': true,
            'picture': 'http://placehold.it/32x32',
            'age': 31,
            'name': 'Mills Barnett',
            'gender': 'male',
            'company': 'FARMEX',
            'email': 'millsbarnett@farmex.com',
            'phone': '+1 (882) 462-3986'
        },
        {
            'id': '5a15b13c67e2e6d1a3cd6ca5',
            'index': 13,
            'isActive': true,
            'picture': 'http://placehold.it/32x32',
            'age': 36,
            'name': 'Margaret Reynolds',
            'gender': 'female',
            'company': 'ROOFORIA',
            'email': 'margaretreynolds@rooforia.com',
            'phone': '+1 (935) 435-2345'
        },
        {
            'id': '5a15b13c947c836d177aa85c',
            'index': 14,
            'isActive': false,
            'picture': 'http://placehold.it/32x32',
            'age': 29,
            'name': 'Yvette Navarro',
            'gender': 'female',
            'company': 'KINETICA',
            'email': 'yvettenavarro@kinetica.com',
            'phone': '+1 (807) 485-3824'
        },
        {
            'id': '5a15b13c5dbbe61245c1fb73',
            'index': 15,
            'isActive': false,
            'picture': 'http://placehold.it/32x32',
            'age': 20,
            'name': 'Elisa Guzman',
            'gender': 'female',
            'company': 'KAGE',
            'email': 'elisaguzman@kage.com',
            'phone': '+1 (868) 594-2919'
        },
        {
            'id': '5a15b13c38fd49fefea8db80',
            'index': 16,
            'isActive': false,
            'picture': 'http://placehold.it/32x32',
            'age': 33,
            'name': 'Jodie Bowman',
            'gender': 'female',
            'company': 'EMTRAC',
            'email': 'jodiebowman@emtrac.com',
            'phone': '+1 (891) 565-2560'
        },
        {
            'id': '5a15b13c9680913c470eb8fd',
            'index': 17,
            'isActive': false,
            'picture': 'http://placehold.it/32x32',
            'age': 24,
            'name': 'Diann Booker',
            'gender': 'female',
            'company': 'LYRIA',
            'email': 'diannbooker@lyria.com',
            'phone': '+1 (830) 555-3209'
        }
    ]
}

  transformEntry(element){
    this.ob.party.entryValue = this.currencyPipe.transform(this.ob.party.entryValue, '$');
    element.target.value = this.ob.party.entryValue;
  }

  transformExit(element){
    this.ob.party.exitValue = this.currencyPipe.transform(this.ob.party.exitValue, '$');
    element.target.value = this.ob.party.exitValue;
  }

  verifyStand() {
    this.ob.verify.productNo = !this.ob.verify.productNo;
    // console.log(this.ob.setInventory.productNo.verified);
    this.checkForm();
  }

  verifyArea() {
    this.ob.verify.size = !this.ob.verify.size;
    // console.log(this.ob.setInventory.size.verified);
    this.checkForm();
  }

  verifyUnit() {
    this.ob.verify.unit = !this.ob.verify.unit;
    // console.log(this.ob.setInventory.unit.verified);
    this.checkForm();
  }
   
  checkForm() {
    if (!(this.ob?.verify?.productNo) && !(this.ob?.verify?.size) && !(this.ob?.verify?.unit)) {
      // console.log(false);
      return false;
    } else {
      return true;
    }
  }

  logDs(x) {
    // console.log(x.extrainfo, this.ob.payTransaction.extrainfo);
  }
  
  saleVerifyArea() {
    this.ob.transaction.product.size.verified = !this.ob.transaction.product.size.verified;
    // console.log(this.ob.setInventory.size.verified);
    this.checkForm();
  }

  saleVerifyUnit() {
    this.ob.transaction.product.unit.verified = !this.ob.transaction.product.unit.verified;
    // console.log(this.ob.setInventory.unit.verified);
    this.checkForm();
  }
  
  checkSaleForm() {
    if (!(this.ob?.transaction.product?.productNo?.verified) && !(this.ob?.transaction?.product?.size?.verified) && !(this.ob?.transaction?.product?.unit?.verified)) {
      // console.log(false);
      return false;
    } else {
      return true;
    }
  }

  transformRate(element) {
    this.ob.inventory.rate.value = this.ob.inventory.rate.name;
    this.ob.inventory.rate.name = this.currencyPipe.transform(this.ob.inventory.rate.name, '$');
    element.target.value = this.ob.inventory.rate.name;
    console.log(this.ob.inventory.rate.name);
    // this.numberForm(this.ob.inventory.rate.name);
  }

  numberForm(nbm: string) {
    const count = (nbm.match(/,/g) || []).length;
    let word = nbm.replace('$', '').replace('.00', '')
    console.log(word, count);
    for (let index = 0; index < count; index++) {
      word = word.replace(',', '');
      if (index + 1 === count) {
        console.log(Number(word), count);
        return word;
      }
    }
  }

  initValues() {
    console.log(this.usd.setProject.contractSum);
  }

  delInventory(inv) {
    this.ws.delInventory(inv)
  }

  setNewPayment() {
    this.payment = 'newPay';
    console.log('newPay');
  }
  
  setInsPayment() {
    this.payment = 'installment';
    console.log('installment');
  }
  
  transformLieuAmount(element) {
    this.ob.payTransaction.amountInLieu = this.currencyPipe.transform(this.ob.payTransaction.amountInLieu, '$');
    element.target.value = this.ob.payTransaction.amountInLieu;
  }
}
