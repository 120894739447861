2
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

const baseUrl = 'https://us-central1-functions-sqlserver.cloudfunctions.net/app/transactions';
// const baseUrl2 = 'https://us-central1-functions-sqlserver.cloudfunctions.net/app/customertrans';
// const baseUrl2 = 'https://us-central1-colors-a07e8.cloudfunctions.net/app/customertrans';
const baseUrl2 = 'https://us-central1-colors-a07e8.cloudfunctions.net/app/prjcustomer';

@Injectable({
  providedIn: 'root'
})

export class MySqlService {
  headers = new HttpHeaders();
  
  constructor(public http: HttpClient) {
    this.headers = this.headers.set('Content-Type', 'application/json; charset=utf-8');
    this.headers = this.headers.set('Access-Control-Allow-Origin', '*');
  }

  async connect() {
  }
  
  gettrans(params: any): Observable<any> {
    return this.http.get(baseUrl2, { params });
  }

  checkMail(params: any): Observable<any> {
    return this.http.get(baseUrl2, { params });
  }

  getAllTransactions(params: any): Observable<any> {
    return this.http.get(baseUrl, { params });
  }

  get(id): Observable<any> {
    return this.http.get(`${baseUrl}/${id}`);
  }

  findByTitle(name): Observable<any> {
    return this.http.get(`${baseUrl}?name=${name}`);
  }

}
