import { Injectable, KeyValueDiffer, KeyValueDiffers } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { ActivatedRoute, Router } from '@angular/router';
import { client, companyStaff, Enterprise, Labour } from 'app/models/enterprise-model';
import { Project, Section } from 'app/models/project-model';
import { rate, Task } from 'app/models/task-model';
import { Observable } from 'rxjs';
import { filter } from 'rxjs-compat/operator/filter';
import { map } from 'rxjs/operators';
import { NotificationService } from './notification.service';
import { UserDataService } from './user-data.service';
import { WriteService } from './writes.service';
import * as moment from 'moment';
import { RouteService } from './route.service';
import { InitialiseService } from './initialise.service';

@Injectable({
  providedIn: 'root'
})

export class ProjServiceService {
  sections = [];
  allsections = [];
  equipment = [];
  rates: rate[] = [];
  labour = [];
  results = [];
  entResults = [];
  prjResults = [];
  entsuggestions = [];
  pjsuggestions = [];
  assets: any;
  compStaff = [];
  allUsers = [];
  allPrjTasks: Observable<any[]>;
  teamresults: Enterprise[] = [];
  team: any[] = [];
  allActions;
  PrjTasksArr = [];
  dailyplanTasks = [];
  // projectWorkPlan: Observable<any[]>
  projectWorkPlan: any[];
  BankTasks;
  compostTaskList = [];
  setProject;
  TeamBankTasks;
  // teamsReport;
  valueEarned: number;
  // totalPlantCost: any;
  proCompanyIndex: string;
  totalLaborCost = 0;
  numberOfWorkingDays: any;
  rptSections: any[];
  Rts: Observable<rate[]>;
  psCompany: any;
  searching = false;
  // Rts: any;
  differ: KeyValueDiffer<string, any>;
  suppliers: any[];
  clients: any[];
  myAcc: any;
  managers: any;
  productTypes: any[];
  cusResults: any[];
  typeOfTasks: Observable<unknown[]>;
  phases: any[];
  partyCliets: any[];

  constructor(private differs: KeyValueDiffers, public afs: AngularFirestore, private usd: UserDataService, public router: Router, private as: ActivatedRoute,
    public afAuth: AngularFireAuth, private ns: NotificationService, public rs: RouteService, public is: InitialiseService) {
    this.pjsuggestions = [];
    this.entsuggestions = [];
    this.differ = this.differs.find({}).create();
    this.writingToProjects();
  }

  checkSections(prj) {
    if (this.allsections.length > 0 && this.allsections[1].projectId === prj.id) {
    } else {
      // this.projectAllSecs(prj);
    }
  }

  sortStringNumbers(list) {
    const clearString = str => str.replace(/\d+/g, n => n.padStart(8, "0"));
    const nc = (a,b) => clearString(a.No).localeCompare(clearString(b.No));
    // console.log(list.map(ne).sort()); // intermediate values
    // console.log(list.sort(nc)); 
  }
  
  getProjectSecs(prj: Project) {
    let allSuper
    if (prj.type === 'Enterprise') {
      this.afs.collection<any>(`Enterprises/${prj.companyId}/projects/${prj.id}/sections`, ref =>
        ref.orderBy('no', 'asc')).valueChanges().subscribe((sections) => {
          sections = sections.filter(x => x !== null);
          if (sections.length === 0) {
            this.addWorkSection(prj);
          } else if (sections.length > 5) {
            const oldSections = sections
            for (let index = 0; index < oldSections.length; index++) {
              const element = oldSections[index];
              this.afs.doc(`Enterprises/${prj.companyId}/projects/${prj.id}/sections/${element.id}`).delete().then(() => {
              })
              if (index === oldSections.length) {
                this.addWorkSection(prj);
              }
            }
          } else {
            this.afs.collection(`Enterprises/${prj.companyId}/projects/${prj.id}/subSections`, ref =>
              ref.orderBy('no', 'asc')).valueChanges().subscribe((subs: any) => {
                subs = subs.filter(x => x !== null);
                subs.forEach(elem => {
                  if (elem.sectionNo) {
                    const secInd = sections.findIndex(workClass => workClass.name === elem.sectionName);
                    if (secInd > -1) {
                      elem.sectionNo = sections[secInd].no;
                      elem.No = String(elem.sectionNo + '.' + elem.no);
                    }
                    elem.No = String(elem.sectionNo + '.' + elem.no);
                  }
                });
                sections = this.mainSections(sections, subs);
                this.allsections = sections.concat(subs).sort((a, b) => a.No.localeCompare(b.No));
                this.sortStringNumbers(this.allsections);
                this.usd.allsections = this.allsections;
              })
          }
        })
    } else if (prj.type === 'Personal') {
      this.afs.collection(`Users/${prj.byId}/projects/${prj.id}/sections`, ref => ref.orderBy('no', 'asc')).valueChanges().subscribe((sections: any[]) => {
        sections = sections.filter(x => x !== null);
        if (sections.length === 0) {
          this.addWorkSection(prj);
        } else {
          this.sections = sections;
          this.rptSections = sections;
          allSuper = sections;
          this.afs.collection(`Users/${prj.byId}/projects/${prj.id}/subSections`, ref => ref.orderBy('no', 'asc')).
            valueChanges().subscribe((subs: any) => {
              subs = subs.filter(x => x !== null);
              subs.forEach(elem => {
                if (elem.sectionNo) {
                  const secInd = sections.findIndex(workClass => workClass.name === elem.sectionName);
                  if (secInd > -1) {
                    elem.sectionNo = sections[secInd].no;
                    elem.No = String(elem.sectionNo + '.' + elem.no);
                  }
                  elem.No = String(elem.sectionNo + '.' + elem.no);
                }
              });
              sections = this.mainSections(sections, subs);
              // this.allsections = sections.concat(subs).sort((a, b) => a.No.localeCompare(b.No));
              this.allsections = sections.concat(subs).sort((a, b) => Number(a.No ) - Number(b.No));
              this.sortStringNumbers(this.allsections);
              this.usd.allsections = this.allsections;
            })
        }
      })
    }
  }

getCurRate(actions){
  actions.forEach(stask => {
    this.rates.find(x => x.name === stask.name)?.rate || 1;
  });
  return actions
}

  projectAllSecs(prj: Project) {
    let allSuper
    if (prj.type === 'Enterprise') {
      this.afs.collection<any>(`Enterprises/${prj.companyId}/projects/${prj.id}/sections`, ref =>
        ref.orderBy('no', 'asc')).valueChanges().subscribe((sections) => {
          sections = sections.filter(x => x !== null);
          if (sections.length === 0) {
            this.addWorkSection(prj);
          } else {
            // console.log(sections.length);
            this.progressStatus();
            this.sections = sections;
            this.rptSections = sections;
            allSuper = sections;
            sections.forEach(element => {
              element.No = String(element.no);
              this.getSectTasks(element).subscribe(dt => {
                // element.actions = dt.sort((a, b) => a?.createdOn.localeCompare(b?.createdOn));
                element.actions = dt.sort((a, b) => b?.createdOn.localeCompare(a?.createdOn));
                // element.actions = this.getCurRate(dt.sort((a, b) => b?.createdOn.localeCompare(a?.createdOn)));
              })
            });
            this.afs.collection(`Enterprises/${prj.companyId}/projects/${prj.id}/subSections`, ref =>
              ref.orderBy('no', 'asc')).valueChanges().subscribe((subs: any) => {
                subs = subs.filter(x => x !== null);
                subs.forEach(elem => {
                  if (elem.sectionNo) {
                    const secInd = sections.findIndex(workClass => workClass.name === elem.sectionName);
                    if (secInd > -1) {
                      elem.sectionNo = sections[secInd].no;
                      elem.No = String(elem.sectionNo + '.' + elem.no);
                    }
                    elem.No = String(elem.sectionNo + '.' + elem.no);
                  }
                  this.getSectTasks(elem).subscribe(dt => {
                    // elem.actions = dt;
                    // elem.actions = dt.sort((a, b) => a?.createdOn.localeCompare(b?.createdOn));
                    // elem.actions = dt.sort((a, b) => b?.createdOn.localeCompare(a?.createdOn));
                    // elem.actions = this.getCurRate(dt.sort((a, b) => b?.createdOn.localeCompare(a?.createdOn)));
                    elem.actions = dt.sort((a, b) => b?.createdOn.localeCompare(a?.createdOn));
                    // console.log(dt);
                  })
                });
                sections = this.mainSections(sections, subs);
                this.allsections = sections.concat(subs).sort((a, b) => Number(a.No ) - Number(b.No));
                this.sortStringNumbers(this.allsections);
                this.usd.allsections = this.allsections;
              })
          }
        })
    } else if (prj.type === 'Personal') {
      // console.log(prj)
      this.afs.collection(`Users/${prj.byId}/projects/${prj.id}/sections`, ref => ref.orderBy('no', 'asc')).valueChanges().subscribe((sections: any[]) => {
        sections = sections.filter(x => x !== null);
        if (sections.length === 0) {
          this.addWorkSection(prj);
        } else {
          // console.log(sections.length);
          this.sections = sections;
          this.rptSections = sections;
          allSuper = sections;
          sections.forEach(element => {
            element.No = String(element.no);
            this.getSectTasks(element).subscribe(dt => {
              // dt.forEach(element => {
              //   element.rate = this.rates.find(dc => dc.name === element.name).rate;
              // });
              // element.actions = dt;
              // element.actions = dt.sort((a, b) => a?.createdOn.localeCompare(b?.createdOn));
              // element.actions = dt.sort((a, b) => b?.createdOn.localeCompare(a?.createdOn));
              // element.actions = this.getCurRate(dt.sort((a, b) => b?.createdOn.localeCompare(a?.createdOn)));
              element.actions = dt.sort((a, b) => b?.createdOn.localeCompare(a?.createdOn));
            })
          });
          this.afs.collection(`Users/${prj.byId}/projects/${prj.id}/subSections`, ref => ref.orderBy('no', 'asc')).
            valueChanges().subscribe((subs: any) => {
              subs = subs.filter(x => x !== null);
              subs.forEach(elem => {
                if (elem.sectionNo) {
                  const secInd = sections.findIndex(workClass => workClass.name === elem.sectionName);
                  if (secInd > -1) {
                    elem.sectionNo = sections[secInd].no;
                    elem.No = String(elem.sectionNo + '.' + elem.no);
                  }
                  elem.No = String(elem.sectionNo + '.' + elem.no);
                }
                this.getSectTasks(elem).subscribe(dt => {
                  // elem.actions = dt;
                  // elem.actions = dt.sort((a, b) => a?.createdOn.localeCompare(b?.createdOn));
                  // elem.actions = dt.sort((a, b) => b?.createdOn.localeCompare(a?.createdOn));
                  // elem.actions = this.getCurRate(dt.sort((a, b) => b?.createdOn.localeCompare(a?.createdOn)));
                  elem.actions = dt.sort((a, b) => b?.createdOn.localeCompare(a?.createdOn));
                  // console.log(dt);
                })
              });
              sections = this.mainSections(sections, subs);
              // console.log(sections);
              // this.allsections = sections.concat(subs).sort((a, b) => a.No.localeCompare(b.No));
              this.allsections = sections.concat(subs).sort((a, b) => Number(a.No ) - Number(b.No));
              this.sortStringNumbers(this.allsections);
              // console.log(this.allsections);
            })
        }
      })
    }
  }

  mainSections(sections, subs) {
    sections.forEach(element => {
      element.subSections = subs.filter(scs => scs.sectionName === element.name)
    });
    return sections
  }

  getSectTasks(section) {
    if (section.id && section.type === 'subSection') {
      // return this.afs.collection(`Enterprises/${section.companyId}/projects/${section.projectId}/subSections/${section.id}/workItems`).valueChanges()
      const collection = this.is.callSnapshot(this.afs.collection(`Enterprises/${section.companyId}/projects/${section.projectId}/subSections/${section.id}/workItems`));
      collection.subscribe(actions => {
        // console.log(actions?.length);
        const NoIdsArr = actions.filter(x => x.id === '');
        NoIdsArr?.length > 0 ? this.fixActsNoIds(section, NoIdsArr): '';
      })
      return collection;
    } else if (section.id && section.type === 'superSection') {
      // return this.afs.collection(`Enterprises/${section.companyId}/projects/${section.projectId}/sections/${section.id}/workItems`).valueChanges();
      const collection = this.is.callSnapshot(this.afs.collection(`Enterprises/${section.companyId}/projects/${section.projectId}/sections/${section.id}/workItems`));
      collection.subscribe(actions => {
        // console.log(actions?.length);
        const NoIdsArr = actions.filter(x => x.id === '');
        NoIdsArr?.length > 0 ? this.fixActsNoIds(section, NoIdsArr): '';
      })
      return collection;
    } else { return Observable.of([]) }
  }


  fixActsNoIds(element, dt) {
    for (let n = 0; n < dt.length; n++) {
      const act = dt[n];
      if ((!act.id || act.id === '') && element.id && element.type === 'subSection') {
       this.afs.doc(`Enterprises/${element.companyId}/projects/${element.projectId}/subSections/${element.id}/workItems/${act.setid}`).update({id: act.setid}).then(() => console.log(act.description || act.name, ' has been updated'));
      } else if (element.id && element.type === 'superSection') {
        this.afs.doc(`Enterprises/${element.companyId}/projects/${element.projectId}/sections/${element.id}/workItems/${act.setid}`).update({id: act.setid}).then(() => console.log(act.description || act.name, ' has been updated'));
      }
    }
  }

  getSheduleWrks(task: Task) {
    let Rts;
    this.afs.collection(`Projects/${task.projectId}/enterprises/${task.companyId}/Rates`, ref => ref.orderBy('name', 'asc'))
      .snapshotChanges().pipe(map(b => b.map(a => {
        const data = a.payload.doc.data() as any;
        data.id = a.payload.doc.id;
        return data;
      }))).subscribe(rates => {
        rates = rates.filter(x => x !== null);
        Rts = rates;
        // console.log(rates);
      });
    return Rts;
  }

  searchProject(value, d) {
    this.afs.collection<Project>('Projects').valueChanges().subscribe(allProjs => {
      allProjs = allProjs.filter(x => x !== null);
      this.layWord(allProjs, value, d);
    })
  }

  searchEnterprise(value, d) {
    this.afs.collection('Enterprises').valueChanges().subscribe(ents => {
      ents = ents.filter(x => x !== null);
      this.layWord(ents, value, d);
    })
  }

  layWord(coll: any[], value, d) {
    let word = value;
    const words = coll.filter(wor => ((wor.name.toLowerCase()).includes(word.toLowerCase())));
    // console.log(words);
    word = word.toLowerCase();
    if (word && value.length > 2) {
      if (d === 'ent') {
        words.forEach(pj => {
          const exist = this.usd.enterprises.find((item) => item.id === pj.id);
          if (exist) {
            pj.join = true;
            // console.log('true');
          } else {
            // console.log('false');
            pj.join = false;
          }
        });
        this.entsuggestions = words;
        // console.log(this.entsuggestions);
      } else {
        words.forEach(pj => {
          const exist = this.usd.projects.find((item) => item.id === pj.id);
          if (exist) {
            pj.join = true;
            // console.log('true');
          } else {
            // console.log('false');
            pj.join = false;
          }
        });
        this.pjsuggestions = words;
        // console.log(this.pjsuggestions);
      }
      this.results = words;
    } else {
      this.entsuggestions = [];
      this.pjsuggestions = [];
    }
    return words;
  }

  getCompanyAssets(companyId) {
    this.assets = this.afs.collection('Enterprises').doc(companyId).collection('assets')
      .snapshotChanges().pipe(map(b => b.map(a => {
        const data = a.payload.doc.data() as any;
        data.id = a.payload.doc.id;
        return data;
      })))
    return this.assets;
  }

  getCompanyHiredAssets(companyId) {
    return this.afs.collection('Enterprises').doc(companyId).collection('hiredAssets')
      .snapshotChanges().pipe(map(b => b.map(a => {
        const data = a.payload.doc.data() as any;
        data.id = a.payload.doc.id;
        return data;
      })))
  }

  searchresult(value, d) {
    // console.log(d);
    this.afs.collection('Projects').valueChanges().subscribe(projects => {
      projects = projects.filter(x => x !== null);
      this.searchWord(projects, value);
    })
  }

  searchEnts(value, d) {
    // console.log(d);
    this.afs.collection('Enterprises').valueChanges().subscribe(ents => {
      ents = ents.filter(x => x !== null);
      this.searchComp(ents, value, d);
    })
  }

  searchWord(coll: any[], value) {
    let word = value; const results = [];
    coll.forEach(pj => {
      const name = pj?.name?.toLowerCase();
      if (word !== '' || ' ') {
        // console.log('In Projects');
        word = word.toLowerCase();
        if (name.includes(word)) {
          // pj.name = pj.name.charAt(0).toUpperCase() + pj.name.slice(1);
          const exist = this.usd.projects.find((item) => item.id === pj.id && item.companyId == pj.companyId);
          if (exist) {
            pj.join = true;
            results.push(pj);
            // console.log('true');
          } else {
            // console.log('false');
            pj.join = false;
            results.push(pj);
          }
          this.prjResults = results;
        }
      }
      // this.results
      return this.prjResults;
      // return this.results;
    });
  }

  searchComp(coll: any[], value, d) {
    let word = value; const results = [];
    coll.forEach(pj => {
      const name = pj?.name?.toLowerCase();
      if (word !== '' || ' ') {
        // console.log('In Enterprise');
        word = word.toLowerCase();
        if (name.includes(word)) {
          // pj.name = pj.name.charAt(0).toUpperCase() + pj.name.slice(1);
          const exist = this.usd.enterprises.find((item) => item.id === pj.id);
          if (exist) {
            pj.join = true;
            results.push(pj);
            // console.log('true');
          } else {
            // console.log('false');
            pj.join = false;
            results.push(pj);
          }
          this.entResults = results;
        }
      }
      // this.results
      return this.entResults;
    });
  }

  searchCus(value) {
    // console.log(d);
    // this.entResults = [];
    this.afs.collection<any>('Enterprises').valueChanges().subscribe(customers => {
      customers?.forEach(cmp => cmp.srTag = 'ent');
      this.afs.collection<any>('Users').valueChanges().subscribe(people => {
        people?.forEach(cmp => cmp.srTag = 'ind');
        this.filterSearch(customers.concat(people), value);
      })
    })
  }

  filterSearch(coll: any[], value,) {
    let word = value; const results = [];
    coll.forEach(cus => {
      const name = cus?.name?.toLowerCase();
      if (word !== '' || ' ') {
        // console.log('In Enterprise');
        word = word.toLowerCase();
        if (name?.includes(word)) {
          const exist = this.usd?.myCustomers?.find((item) => item.id === cus.id);
          if (exist) {
            cus.join = true;
            results.push(cus);
          } else {
            cus.join = false;
            results.push(cus);
          }
          console.log(results?.length);
          this.cusResults = results;
        }
      }
      // this.results
      return this.cusResults;
    });
  }

  saveProject(prj, ent) {
    let company;
    const project = prj
    // console.log(project);
    // adding company details
    // console.log(ent)
    project.companyName = ent.name;
    project.companyId = ent.id;
    project.createdOn = new Date().toISOString();
    // console.log(project.createdOn)
    project.by = this.usd.acc.name;
    project.byId = this.usd.acc?.id;
    // console.log(project);

    if (project.champion.id === '') {
      project.champion = this.usd.acc;
    } else {
      // console.log(project.champion);
    }
    company = ent
    // console.log(company.name);
    // console.log(ent);
    // console.log(company.roles);

    // this.addProject(pUser, project, company);
    const championId = project.champion.id;
    const dref = this.afs.collection('Projects')
    const entRef = this.afs.collection('Enterprises').doc(company.id).collection('projects');
    const myProRef = this.afs.collection('Users').doc(this.usd.acc?.id).collection('projects');
    const champProRef = this.afs.collection('Users').doc(championId).collection('projects');
    const nrouter = this.router;

    entRef.add(project).then(pref => {
      // myProRef.add(project).then(pref => {
      // Add project to users collection of projects
      // console.log(pref.id);
      project.id = pref.id;
      project.type = 'Enterprise';
      // console.log(project.type);
      // Id of the newly created project
      // project.id = pref.id;
      // console.log(project.id);
      myProRef.doc(project.id).set(project).then(() => {
        // console.log('set in entRef')
      }).catch(er => {
        // console.log('Didnt set in entRef', er)
      });
      // entRef.doc(project.id).set(project).then(() => console.log('set in entRef')).catch(er => console.log('Didnt set in entRef', er));
      dref.doc(project.id).set(project).then(() => {
        // console.log('set in dref')
      }).catch(er => {
        // console.log('Didnt set in dref', er)
      });
      if (this.usd.acc?.id !== championId) {
        champProRef.doc(project.id).set(project);
      }
      this.usd.getProjects();
    }).then(() => {
      entRef.doc(project.id).update({ 'id': project.id });
      dref.doc(project.id).collection('Participants').doc(project.champion.id).set(project.champion);
      this.afs.doc(`Projects/${project.id}/enterprises/${project.companyId}/labour/${project.champion.id}`).set(project.champion);
      dref.doc(project.id).collection('enterprises').doc(company.id).set(company);
      // console.log('project Id updated');
      // console.log('enterprise project');
    }).then(() => {
      nrouter.navigate(['/views/project', project.name]);
    });
    return {
      name: '', type: '', by: '', byId: '', companyName: '', companyId: '', champion: null, createdOn: '', id: '',
      location: '', sector: '', completion: ''
    };
  }

  getLabour() {
    this.afs.collection<companyStaff>(`Enterprises/${this.usd.setProject.companyId}/Participants`).valueChanges().subscribe(users => {
      this.compStaff = users;
    })
  }

  getTeamLabour(team) {
    return this.afs.collection<Labour>(`Projects/${this.usd.setProject.id}/enterprises/${team.id}/labour`, ref =>
      ref.orderBy('name', 'asc')).valueChanges();
  }

  colors(word, p) {
    this.afs.collection<companyStaff>(`Enterprises/${this.usd.setProject.companyId}/Participants`).valueChanges().subscribe(allUsers => {
      // this.afs.collection<any>('Users', ref => ref.where('name', '>=', word)).valueChanges().subscribe(allUsers => {
      if (p === 'Project') {
        this.serchPrjColors(allUsers, word);
      } else {
        // console.log('non');
      }
    });
  }

  checkLegality(user) {
    const lgd = this.labour.find(usd => usd.id === this.usd.userId);
    if ((lgd?.hierarchy === 'Executive' && user.hierarchy === 'Executive') || (lgd?.hierarchy === 'Executive' && user.hierarchy === 'Middle management') || (lgd?.hierarchy === 'Executive' && user.hierarchy === 'Operations') || (lgd?.hierarchy === 'Executive' && user.hierarchy === 'None')  || (lgd?.hierarchy === 'Executive' && user.hierarchy === '')) {
      this.rs.gotoStaff(user);
      this.clearView();
    // console.log('Executive');      
      return '';
    } else if ((lgd?.hierarchy === 'Middle management' && user.hierarchy === 'Middle management') || (lgd?.hierarchy === 'Middle management' && user.hierarchy === 'Operations') || (lgd?.hierarchy === 'Middle management' && user.hierarchy === 'None')  || (lgd?.hierarchy === 'Middle management' && user.hierarchy === '')  || (lgd?.hierarchy === 'Middle management' && user.hierarchy === '')) {
      this.rs.gotoStaff(user);
      this.clearView();
    // console.log('Middle management');      
      return '';
    } else if (lgd?.hierarchy === 'Operations' && user.hierarchy === 'Operations') {
      this.rs.gotoStaff(user);
      this.clearView();
    // console.log('Operations');      
      return '';
    } else if (lgd?.hierarchy === 'Operations' && user.hierarchy === '') {
      this.rs.gotoStaff(user);
      this.clearView();
    // console.log('empty string');      
      return '';
    } else {
    // console.log(lgd?.hierarchy, user.hierarchy);
      this.clearView();
      return 'Access Denied';
    }
  }

  clearView() {
    setTimeout(() => {
      this.labour.forEach(element => {
        element.viewable = ''
      });
      this.usd.setContact.viewable = ''
    }, 4000);
  }

  serchPrjColors(allUsers, value) {
    let word = value; const results = [];
    allUsers.forEach(man => {
      if (man.name) {
        // // man.name = man.name.toLowerCase();
        const name = man?.name?.toLowerCase();
        if (word !== '' || ' ') {
          word = word.toLowerCase();
          if (name?.includes(word)) {
            // man.name = man.name.charAt(0).toUpperCase() + man.name.slice(1);
            results.push(man);
            // console.log(results);
            this.allUsers = results;
            const exist = this.labour.find((item) => item.id === man.id);
            if (exist) {
              man.join = true;
              // console.log('true');
            } else {
              // console.log('false');
              man.join = false;
            }
          }
        }
      }
      return this.allUsers;
    });
  }
 
  writingToProjects() {
    // console.log('writing sections to projects');
    this.afs.collection('Projects')
      // .valueChanges()
      .snapshotChanges().pipe(map(b => b.map(a => {
        const data = a.payload.doc.data() as any;
        data.id = a.payload.doc.id;
        return data;
      })))
      .subscribe((yur: any[]) => {
        // yur.forEach(element => {
        // console.log(element.name);
        // this.addWorkSection(element);
        // });
        yur.forEach(element => {
          this.afs.collection('Projects').doc(element.id).collection('enterprises').snapshotChanges()
            .pipe(map(b => b.map(a => {
              const data = a.payload.doc.data() as any;
              data.id = a.payload.doc.id;
              return data;
            })))
          if (element.type === 'Enterprise') {
            this.postAdSection(element);
          }
        });
      })
  }

  addSections(project, company) {
    const projectId = project.id;
    // this.classesArray = [];
    // console.log( `Adding sections to project ${project.name}`);
    const sect1 = {
      name: 'Project Initiation and Preparation', createdOn: new Date().toISOString(), type: 'superSection', id: 'projectInitialization',
      no: 1, projectId: projectId, projectName: project.name, companyId: company.id,
      companyName: company.name, Bills: null
    };
    const sect5 = {
      name: 'Project Fund Raising', createdOn: new Date().toISOString(), type: 'superSection', id: 'projectfunding',
      no: 2, projectId: projectId, projectName: project.name, companyId: company.id, companyName: company.name,
      Bills: null
    };
    const sect2 = {
      name: 'Implementation Stage', createdOn: new Date().toISOString(), type: 'superSection', id: 'projectImplementation',
      no: 3, projectId: projectId, projectName: project.name, companyId: company.id, companyName: company.name,
      Bills: null
    };
    const sect3 = {
      name: 'Operation and Maintenance', createdOn: new Date().toISOString(), type: 'superSection', id: 'operationMaintenance',
      no: 4, projectId: projectId, projectName: project.name, companyId: company.id, companyName: company.name,
      Bills: null
    };
    const sect4 = {
      name: 'Divestment', createdOn: new Date().toISOString(), type: 'superSection', id: 'projectDivestiment',
      no: 5, projectId: projectId, projectName: project.name, companyId: company.id, companyName: company.name,
      Bills: null
    };

    // let value1, value2, value3, value4, value5;
    if (project.type === 'Personal') {
      const pDoc = this.afs.collection('Projects').doc(projectId).collection('sections');
      const entRef = this.afs.collection('Users').doc(project.byId).collection('projects').doc(projectId).collection('sections');
      pDoc.doc(sect1.id).set(sect1).then(() => console.log(sect1.name + ' ' + 'isSet'));
      entRef.doc(sect1.id).set(sect1).then(() => console.log(sect1.name + ' ' + 'isSet'));
      pDoc.doc(sect2.id).set(sect2).then(() => console.log(sect2.name + ' ' + 'isSet'));
      entRef.doc(sect2.id).set(sect2).then(() => console.log(sect2.name + ' ' + 'isSet'));
      pDoc.doc(sect3.id).set(sect3).then(() => console.log(sect3.name + ' ' + 'isSet'));
      entRef.doc(sect3.id).set(sect3).then(() => console.log(sect3.name + ' ' + 'isSet'));
      pDoc.doc(sect4.id).set(sect4).then(() => console.log(sect4.name + ' ' + 'isSet'));
      entRef.doc(sect4.id).set(sect4).then(() => console.log(sect4.name + ' ' + 'isSet'));
      pDoc.doc(sect5.id).set(sect5).then(() => console.log(sect5.name + ' ' + 'isSet'));
      entRef.doc(sect5.id).set(sect5).then(() => console.log(sect5.name + ' ' + 'isSet'));
    } else {
      const dref2 = this.afs.collection('Projects').doc(projectId).collection('enterprises').doc(company.id).collection('sections');
      const entRef = this.afs.collection('Enterprises').doc(company.id).collection('projects').doc(projectId).collection('sections');
      dref2.doc(sect1.id).set(sect1).then(() => console.log(sect1.name + ' ' + 'isSet'));
      entRef.doc(sect1.id).set(sect1).then(() => console.log(sect1.name + ' ' + 'isSet'));
      dref2.doc(sect2.id).set(sect2).then(() => console.log(sect2.name + ' ' + 'isSet'));
      entRef.doc(sect2.id).set(sect2).then(() => console.log(sect2.name + ' ' + 'isSet'));
      dref2.doc(sect3.id).set(sect3).then(() => console.log(sect3.name + ' ' + 'isSet'));
      entRef.doc(sect3.id).set(sect3).then(() => console.log(sect3.name + ' ' + 'isSet'));
      dref2.doc(sect4.id).set(sect4).then(() => console.log(sect4.name + ' ' + 'isSet'));
      entRef.doc(sect4.id).set(sect4).then(() => console.log(sect4.name + ' ' + 'isSet'));
      dref2.doc(sect5.id).set(sect5).then(() => console.log(sect5.name + ' ' + 'isSet'));
      entRef.doc(sect5.id).set(sect5).then(() => console.log(sect5.name + ' ' + 'isSet'));
    }
  }

  addWorkSection(project) {
    const projectId = project.id;
    project.companyId = project.companyId || project.company?.id;
    // this.classesArray = [];
    // console.log( `Adding sections to project ${project.name}`);
    const sect1 = {
      name: 'Project Initiation and Preparation', createdOn: new Date().toISOString(), type: 'superSection', id: 'projectInitialization',
      no: 1, projectId: projectId, projectName: project.name, companyId: project.companyId,
      companyName: project.companyName, Bills: null
    };
    const sect5 = {
      name: 'Project Fund Raising', createdOn: new Date().toISOString(), type: 'superSection', id: 'projectfunding',
      no: 2, projectId: projectId, projectName: project.name, companyId: project.companyId, companyName: project.companyName,
      Bills: null
    };
    const sect2 = {
      name: 'Implementation Stage', createdOn: new Date().toISOString(), type: 'superSection', id: 'projectImplementation',
      no: 3, projectId: projectId, projectName: project.name, companyId: project.companyId, companyName: project.companyName,
      Bills: null
    };
    const sect3 = {
      name: 'Operation and Maintenance', createdOn: new Date().toISOString(), type: 'superSection', id: 'operationMaintenance',
      no: 4, projectId: projectId, projectName: project.name, companyId: project.companyId, companyName: project.companyName,
      Bills: null
    };
    const sect4 = {
      name: 'Divestment', createdOn: new Date().toISOString(), type: 'superSection', id: 'projectDivestiment',
      no: 5, projectId: projectId, projectName: project.name, companyId: project.companyId, companyName: project.companyName,
      Bills: null
    };

    // let value1, value2, value3, value4, value5;
    if (project.type === 'Personal') {
      const pDoc = this.afs.collection('Projects').doc(projectId).collection('sections');
      const entRef = this.afs.collection('Users').doc(project.byId).collection('projects').doc(projectId).collection('sections');
      pDoc.doc(sect1.id).set(sect1).then(() => console.log(sect1.name + ' ' + 'isSet'));
      entRef.doc(sect1.id).set(sect1).then(() => console.log(sect1.name + ' ' + 'isSet'));
      pDoc.doc(sect2.id).set(sect2).then(() => console.log(sect2.name + ' ' + 'isSet'));
      entRef.doc(sect2.id).set(sect2).then(() => console.log(sect2.name + ' ' + 'isSet'));
      pDoc.doc(sect3.id).set(sect3).then(() => console.log(sect3.name + ' ' + 'isSet'));
      entRef.doc(sect3.id).set(sect3).then(() => console.log(sect3.name + ' ' + 'isSet'));
      pDoc.doc(sect4.id).set(sect4).then(() => console.log(sect4.name + ' ' + 'isSet'));
      entRef.doc(sect4.id).set(sect4).then(() => console.log(sect4.name + ' ' + 'isSet'));
      pDoc.doc(sect5.id).set(sect5).then(() => console.log(sect5.name + ' ' + 'isSet'));
      entRef.doc(sect5.id).set(sect5).then(() => console.log(sect5.name + ' ' + 'isSet'));
    } else {
      const dref2 = this.afs.collection('Projects').doc(projectId).collection('enterprises').doc(project?.companyId || project?.company?.id).collection('sections');
      const entRef = this.afs.collection('Enterprises').doc(project?.companyId || project?.company?.id).collection('projects').doc(projectId).collection('sections');
      dref2.doc(sect1.id).set(sect1).then(() => console.log(sect1.name + ' ' + 'isSet'));
      entRef.doc(sect1.id).set(sect1).then(() => console.log(sect1.name + ' ' + 'isSet'));
      dref2.doc(sect2.id).set(sect2).then(() => console.log(sect2.name + ' ' + 'isSet'));
      entRef.doc(sect2.id).set(sect2).then(() => console.log(sect2.name + ' ' + 'isSet'));
      dref2.doc(sect3.id).set(sect3).then(() => console.log(sect3.name + ' ' + 'isSet'));
      entRef.doc(sect3.id).set(sect3).then(() => console.log(sect3.name + ' ' + 'isSet'));
      dref2.doc(sect4.id).set(sect4).then(() => console.log(sect4.name + ' ' + 'isSet'));
      entRef.doc(sect4.id).set(sect4).then(() => console.log(sect4.name + ' ' + 'isSet'));
      dref2.doc(sect5.id).set(sect5).then(() => console.log(sect5.name + ' ' + 'isSet'));
      entRef.doc(sect5.id).set(sect5).then(() => console.log(sect5.name + ' ' + 'isSet'));
    }
  }

  addWorkSectionArvh(project) {
    const projectId = project.id;
    // this.classesArray = [];
    // console.log(`Adding sections to project ${project.name}`);
    const sect1 = {
      name: 'Project Initiation and Preparation', createdOn: new Date().toISOString(), type: 'superSection', id: 'projectInitialization',
      no: 1, projectId: projectId, projectName: project.name, companyId: project.companyId,
      companyName: project.companyName, Bills: null
    };
    const sect5 = {
      name: 'Project Fund Raising', createdOn: new Date().toISOString(), type: 'superSection', id: 'projectfunding',
      no: 2, projectId: projectId, projectName: project.name, companyId: project.companyId, companyName: project.companyName,
      Bills: null
    };
    const sect2 = {
      name: 'Implementation Stage', createdOn: new Date().toISOString(), type: 'superSection', id: 'projectImplementation',
      no: 3, projectId: projectId, projectName: project.name, companyId: project.companyId, companyName: project.companyName,
      Bills: null
    };
    const sect3 = {
      name: 'Operation and Maintenance', createdOn: new Date().toISOString(), type: 'superSection', id: 'operationMaintenance',
      no: 4, projectId: projectId, projectName: project.name, companyId: project.companyId, companyName: project.companyName,
      Bills: null
    };
    const sect4 = {
      name: 'Divestment', createdOn: new Date().toISOString(), type: 'superSection', id: 'projectDivestiment',
      no: 5, projectId: projectId, projectName: project.name, companyId: project.companyId, companyName: project.companyName,
      Bills: null
    };

    let value1, value2, value3, value4, value5;
    if (project.type === 'Personal') {
      const pDoc = this.afs.collection('Projects').doc(projectId).collection('sections');
      const entRef = this.afs.collection('Users').doc(project.byId).collection('projects').doc(projectId).collection('sections');
    // console.log(this.sections);
      const index1 = this.sections.findIndex(workClass => workClass.name === sect1.name);
      if (index1 > -1) {
        value1 = this.sections[index1].name;
      } else {
        if (value1 === sect1.name) {
        // console.log(sect1.name + ' ' + 'found');
        } else {
          pDoc.doc(sect1.id).set(sect1);
          entRef.doc(sect1.id).set(sect1);
        // console.log(sect1.name + ' ' + 'isSet');
        }
      }
      const index2 = this.sections.findIndex(workClass => workClass.name === sect2.name);
      if (index2 > -1) {
        value2 = this.sections[index2].name;
      } else {
        if (value2 === sect2.name) {
        // console.log(sect2.name + ' ' + 'found');
        } else {
          pDoc.doc(sect2.id).set(sect2);
          entRef.doc(sect2.id).set(sect2);
          // myProRef.doc(sect2.id).set(sect2);
        // console.log(sect2.name + ' ' + 'isSet');
        }
      }
      const index3 = this.sections.findIndex(workClass => workClass.name === sect3.name);
      if (index3 > -1) {
        value3 = this.sections[index3].name;
      } else {
        if (value3 === sect3.name) {
        // console.log(sect3.name + ' ' + 'found');
        } else {
          pDoc.doc(sect3.id).set(sect3);
          entRef.doc(sect3.id).set(sect3);
          // myProRef.doc(sect3.id).set(sect3);
        // console.log(sect3.name + ' ' + 'isSet');
        }
      }
      const index4 = this.sections.findIndex(workClass => workClass.name === sect4.name);
      if (index4 > -1) {
        value4 = this.sections[index4].name;
      } else {
        if (value4 === sect4.name) {
        // console.log(sect4.name + ' ' + 'found');
          // setClass.update(sect4);
        } else {
          pDoc.doc(sect4.id).set(sect4);
          entRef.doc(sect4.id).set(sect4);
          // myProRef.doc(sect4.id).set(sect4);
        // console.log(sect4.name + ' ' + 'isSet');
        }
      }
      const index5 = this.sections.findIndex(workClass => workClass.name === sect5.name);
      if (index5 > -1) {
        value5 = this.sections[index5].name;
      } else {
        if (value5 === sect5.name) {
        // console.log(sect5.name + ' ' + 'found');
        } else {
          pDoc.doc(sect5.id).set(sect5);
          entRef.doc(sect5.id).set(sect5);
          // myProRef.doc(sect2.id).set(sect2);
        // console.log(sect5.name + ' ' + 'isSet');
        }
      }
    } else {
      const dref2 = this.afs.collection('Projects').doc(projectId).collection('enterprises').doc(project?.companyId || project?.company?.id).collection('sections');
      const entRef = this.afs.collection('Enterprises').doc(project?.companyId || project?.company?.id).collection('projects').doc(projectId).collection('sections');
    // console.log(this.sections);
      const index1 = this.sections.findIndex(workClass => workClass.name === sect1.name);
      if (index1 > -1) {
        value1 = this.sections[index1].name;
      } else {
        if (value1 === sect1.name) {
        // console.log(sect1.name + ' ' + 'found');
        } else {
          dref2.doc(sect1.id).set(sect1);
          entRef.doc(sect1.id).set(sect1);
        // console.log(sect1.name + ' ' + 'isSet');
        }
      }
      const index2 = this.sections.findIndex(workClass => workClass.name === sect2.name);
      if (index2 > -1) {
        value2 = this.sections[index2].name;
      } else {
        if (value2 === sect2.name) {
        // console.log(sect2.name + ' ' + 'found');
        } else {
          dref2.doc(sect2.id).set(sect2);
          entRef.doc(sect2.id).set(sect2);
          // myProRef.doc(sect2.id).set(sect2);
        // console.log(sect2.name + ' ' + 'isSet');
        }
      }
      const index3 = this.sections.findIndex(workClass => workClass.name === sect3.name);
      if (index3 > -1) {
        value3 = this.sections[index3].name;
      } else {
        if (value3 === sect3.name) {
        // console.log(sect3.name + ' ' + 'found');
        } else {
          dref2.doc(sect3.id).set(sect3);
          entRef.doc(sect3.id).set(sect3);
          // myProRef.doc(sect3.id).set(sect3);
        // console.log(sect3.name + ' ' + 'isSet');
        }
      }
      const index4 = this.sections.findIndex(workClass => workClass.name === sect4.name);
      if (index4 > -1) {
        value4 = this.sections[index4].name;
      } else {
        if (value4 === sect4.name) {
        // console.log(sect4.name + ' ' + 'found');
          // setClass.update(sect4);
        } else {
          dref2.doc(sect4.id).set(sect4);
          entRef.doc(sect4.id).set(sect4);
          // myProRef.doc(sect4.id).set(sect4);
        // console.log(sect4.name + ' ' + 'isSet');
        }
      }
      const index5 = this.sections.findIndex(workClass => workClass.name === sect5.name);
      if (index5 > -1) {
        value5 = this.sections[index5].name;
      } else {
        if (value5 === sect5.name) {
        // console.log(sect5.name + ' ' + 'found');
        } else {
          dref2.doc(sect5.id).set(sect5);
          entRef.doc(sect5.id).set(sect5);
          // myProRef.doc(sect2.id).set(sect2);
        // console.log(sect5.name + ' ' + 'isSet');
        }
      }
    }
  }

  postAdSection(project) {
    const projectId = project.id;
    // this.classesArray = [];
    // console.log(`Adding sections to project ${project.name}`);
    const sect1 = {
      name: 'Project Initiation and Preparation', createdOn: new Date().toISOString(), type: 'superSection', id: 'projectInitialization',
      no: 1, projectId: projectId, projectName: project.name, companyId: project.companyId,
      companyName: project.companyName, Bills: null
    };
    const sect5 = {
      name: 'Project Fund Raising', createdOn: new Date().toISOString(), type: 'superSection', id: 'projectfunding',
      no: 2, projectId: projectId, projectName: project.name, companyId: project.companyId, companyName: project.companyName,
      Bills: null
    };
    const sect2 = {
      name: 'Implementation Stage', createdOn: new Date().toISOString(), type: 'superSection', id: 'projectImplementation',
      no: 3, projectId: projectId, projectName: project.name, companyId: project.companyId, companyName: project.companyName,
      Bills: null
    };
    const sect3 = {
      name: 'Operation and Maintenance', createdOn: new Date().toISOString(), type: 'superSection', id: 'operationMaintenance',
      no: 4, projectId: projectId, projectName: project.name, companyId: project.companyId, companyName: project.companyName,
      Bills: null
    };
    const sect4 = {
      name: 'Divestment', createdOn: new Date().toISOString(), type: 'superSection', id: 'projectDivestiment',
      no: 5, projectId: projectId, projectName: project.name, companyId: project.companyId, companyName: project.companyName,
      Bills: null
    };

    let value1, value2, value3, value4, value5;
    if (project?.companyId || project?.company?.id) {
      // console.log(project.name);
      const dref2 = this.afs.collection('Projects').doc(projectId).collection('enterprises').doc(project?.companyId || project?.company?.id).collection('sections');
      const entRef = this.afs.collection('Enterprises').doc(project?.companyId || project?.company?.id).collection('projects').doc(projectId).collection('sections');
      // console.log(this.sections);
      const index1 = this.sections.findIndex(workClass => (workClass.name === sect1.name && workClass.no === sect1.no));
      if (index1 > -1) {
        value1 = this.sections[index1].name;
      } else {
        if (value1 === sect1.name) {
        // console.log(sect1.name + ' ' + 'found');
          dref2.doc(sect1.id).set(sect1);
          entRef.doc(sect1.id).set(sect1);
        // console.log(value1 + ' ' + 'isSet');
        }
      }
      const index2 = this.sections.findIndex(workClass => (workClass.name === sect2.name && workClass.no === sect2.no));
      if (index2 > -1) {
        value2 = this.sections[index2].name;
      } else {
        if (value2 === sect2.name) {
        // console.log(sect2.name + ' ' + 'found');
          dref2.doc(sect2.id).set(sect2);
          entRef.doc(sect2.id).set(sect2);
        // console.log(value2 + ' ' + 'isSet');
        }
      }
      const index3 = this.sections.findIndex(workClass => (workClass.name === sect3.name && workClass.no === sect3.no));
      if (index3 > -1) {
        value3 = this.sections[index3].name;
      } else {
        if (value3 === sect3.name) {
        // console.log(sect3.name + ' ' + 'found');
          dref2.doc(sect3.id).set(sect3);
          entRef.doc(sect3.id).set(sect3);
        // console.log(value3 + ' ' + 'isSet');
        }
      }
      const index4 = this.sections.findIndex(workClass => workClass.name === sect4.name && workClass.no === sect4.no);
      if (index4 > -1) {
        value4 = this.sections[index4].name;
      } else {
        if (value4 === sect4.name) {
        // console.log(sect4.name + ' ' + 'found');
          dref2.doc(sect4.id).set(sect4);
          entRef.doc(sect4.id).set(sect4);
        // console.log(value4 + ' ' + 'isSet');
        }
      }
      const index5 = this.sections.findIndex(workClass => (workClass.name === sect5.name && workClass.no === sect5.no));
      if (index5 > -1) {
        value5 = this.sections[index5].name;
      } else {
        if (value5 === sect5.name) {
        // console.log(sect5.name + ' ' + 'found');
          dref2.doc(sect5.id).set(sect5);
          entRef.doc(sect5.id).set(sect5);
        // console.log(value5 + ' ' + 'isSet');
        }
      }
    }
  }

  searchTeam(value) {
    this.afs.collection('Enterprises').valueChanges().subscribe(companies => {
      this.filterTeam(companies, value);
    })
  }

  filterTeam(coll: any[], value) {
    // console.log(this.usd.setProject.id);
    let word = value; const teamresults = [];
    coll.forEach(man => {
      // man.name = man.name.toLowerCase();
        const name = man?.name?.toLowerCase();
      if (word !== '' || ' ') {
        word = word.toLowerCase();
        if (name?.includes(word)) {
          // man.name = man.name.charAt(0).toUpperCase() + man.name.slice(1);
          this.teamresults = teamresults;
          this.afs.doc(`Projects/${this.usd.setProject.id}/enterprises/${man.id}`).ref.get().then
            (pop => {
              if (pop.exists) {
                man.join = true;
                teamresults.push(man);
                // console.log('true');
              } else {
                // console.log('false');
                man.join = false;
                teamresults.push(man);
              }
            })
        }
      }
      return this.teamresults;
    });
  }
  
  searchCmps(value) {
    try {
      this.searching = true;
      this.afs.collection('Enterprises').valueChanges().subscribe(companies => {
        // this.filterParties(companies, value);
        this.filterTeam(companies, value);
        if (companies) {
          this.searching = false;
        }
      })
    } catch (error) {
      this.searching = false;
      console.log(error);
    }
  }

  filterParties(coll: any[], value) {
    // console.log(this.usd.setProject.id);
    let word = value; const teamresults = [];
    coll.forEach(man => {
      // man.name = man.name.toLowerCase();
        const name = man?.name?.toLowerCase();
      if (word !== '' || ' ') {
        word = word.toLowerCase();
        if (name?.includes(word)) {
          // man.name = man.name.charAt(0).toUpperCase() + man.name.slice(1);
          this.teamresults = teamresults;
          this.afs.doc(`Projects/${this.usd.setProject.id}/parties/${man.id}`).ref.get().then
            (pop => {
              if (pop.exists) {
                man.join = true;
                teamresults.push(man);
                // console.log('true');
              } else {
                // console.log('false');
                man.join = false;
                teamresults.push(man);
              }
            })
        }
      }
      return this.teamresults;
    });
  }

  searchParties(value) {
    try {
      this.searching = true;
      this.afs.collection('Enterprises').valueChanges().subscribe(companies => {
        companies.forEach((cmp: any) => cmp.partyType = 'Enterprise');
        this.afs.collection('Users').valueChanges().subscribe(Users => {
          Users.forEach((user: any) => user.partyType = 'Individual');
          // this.filterParties(companies, value);
          const partiesFnd = companies.concat(Users);
          this.orderParties(partiesFnd, value);
          if (companies) {
            this.searching = false;
          }
        })
      })
    } catch (error) {
      this.searching = false;
      console.log(error);
    }
  }

  orderParties(coll: any[], value) {
    // console.log(this.usd.setProject.id);
    let word = value; const teamresults = [];
    coll.forEach(man => {
      // man.name = man.name.toLowerCase();
        const name = man?.name?.toLowerCase();
      if (word !== '' || ' ') {
        word = word.toLowerCase();
        if (name?.includes(word)) {
          // man.name = man.name.charAt(0).toUpperCase() + man.name.slice(1);
          this.teamresults = teamresults;
          const prtyFnd = this.team.find(prty => prty.id === man.id);
          if (prtyFnd) {
            man.join = true;
            teamresults.push(man);
          } else {
            man.join = false;
            teamresults.push(man);
          }
        }
      }
      return this.teamresults;
    });
  }
  // Project Reports query Start Here

  returnAllProjectTasks() {
    this.setProject = this.usd.setProject;
    // return this.afs.collection<any>(`Projects/${this.usd.setProject.id}/tasks`).valueChanges();
    return this.afs.collection<any>(`tasks`, ref => ref.where('projectId', '==', this.usd.setProject.id)).valueChanges();
  }

  async returnDailyPlanTasks() {
    this.dailyplanTasks = [];
    let dads;
    this.returnAllProjectTasks().subscribe((Tasks: any[]) => {
      console.log(Tasks.length)
      this.compostTaskList = Tasks;
      const prjTasks = Tasks;
      // dailyplanTasks Array
      dads = prjTasks.filter(tsk => (tsk.complete === false && tsk.selectedWork === true));
      this.dailyplanTasks = dads;
      this.projectWorkPlan = [];
      prjTasks.forEach(element => {
        if(element?.champion?.id) {
          this.usd.afs.doc<any>(`Users/${element.champion.id}/tasks/${element.id}`).ref.get().then(tskRef => {
            if(tskRef.exists) {
              element.selectedWork = tskRef.data().selectedWork;
              if(element.selectedWork && element.complete === false){
                // console.log(element.desctiption, element.selectedWork);
                this.projectWorkPlan.push(element)
              }
            }
          });
        }
      })
      // this.projectWorkPlan = this.returnAllProjectTasks().pipe (map(items => items.filter(tsk => (tsk.complete === false && tsk.selectedWork === true))));
      // this.projectWorkPlan = prjTasks.filter(tsk => (tsk.complete === false && tsk.selectedWork === true));
      // this.getprojectWorkPlan(prjTasks);
    })
  }

  async getProjectTeam() {
    // const team = this.team;
    this.team.forEach((element: any) => {
      // console.log(this.getCompanySecs(element));
      element = this.getCompanySecs(element);
    });
  }

  getCompanySecs(ent) {
    const cmp = ent;
    const prj = this.usd.setProject;
    // if (prj.type === 'Enterprise' && cmp.id !== this.usd.setProject.companyId) {
      this.afs.collection<any>(`Enterprises/${cmp.id}/projects/${prj.id}/sections`, ref => ref.orderBy('no', 'asc')).valueChanges()
      .subscribe((sections) => {
        sections = sections.filter(x => x !== null);
        if (sections.length === 0) {
          const proj = {
            name: prj.name, id: prj.id, companyId: ent.id, companyName: ent.name
          }
          this.addWorkSection(proj);
        }
        sections.forEach(element => {
          element.No = String(element.no);
          this.getSectTasks(element).subscribe(dt => {
            // element.actions = this.getCurRate(dt.sort((a, b) => b?.createdOn.localeCompare(a?.createdOn)));
            element.actions = dt.sort((a, b) => b?.createdOn.localeCompare(a?.createdOn));
          })
        });
        cmp.sections = sections;
        // console.log(cmp.sections);
        this.afs.collection(`Enterprises/${cmp.id}/projects/${prj.id}/subSections`, ref => ref.orderBy('no', 'asc')).valueChanges()
        .subscribe((subs: any) => {
          subs = subs.filter(x => x !== null);
          subs.forEach(elem => {
            if (elem.sectionNo) {
              const secInd = sections.findIndex(workClass => workClass.name === elem.sectionName);
              if (secInd > -1) {
                elem.sectionNo = sections[secInd].no;
                elem.No = String(elem.sectionNo + '.' + elem.no);
              }
              elem.No = String(elem.sectionNo + '.' + elem.no);
            }
            this.getSectTasks(elem).subscribe(dt => {
              // elem.actions = this.getCurRate(dt.sort((a, b) => b?.createdOn.localeCompare(a?.createdOn)));
              elem.actions = dt.sort((a, b) => b?.createdOn.localeCompare(a?.createdOn));
            });
          });
          sections = this.getMainSecs(sections, subs);
          sections.forEach(element => {
            element.subSections.forEach(sub => {
              if (sub.actions && sub.actions.length > 0) {
                element?.actions?.forEach(tsk => {
                  const fnd = sub.actions.findIndex(act => act.name === tsk.name);
                  if (fnd == -1) {
                    element?.actions?.push(element);
                  } else {
                    tsk.targetQty += sub.actions[fnd].targetQty;
                    if (sub.actions[fnd].arrTasks.length > 0) {
                      tsk.arrTasks.concate(sub.actions[fnd].arrTasks)
                    }
                  }
                });
              } else {

              }
            });
          });
        })

      })
    // } else if (prj.type === 'Enterprise' && cmp.id === this.usd.setProject.companyId) {
    //   cmp.sections = this.sections;
    // }
    return cmp;
  }

  getSecTasks(section) {
    if (section.id && section.type === 'subSection') {
      return this.afs.collection(`Enterprises/${section.companyId}/projects/${section.projectId}/subSections/${section.id}/workItems`).
        valueChanges();
    } else if (section.id && section.type === 'superSection') {
      return this.afs.collection(`Enterprises/${section.companyId}/projects/${section.projectId}/sections/${section.id}/workItems`).
        valueChanges();
    } else { return Observable.of([]) }
  }

  getMainSecs(sections, subs) {
    sections.forEach(element => {
      element.subSections = subs.filter(scs => scs.sectionName === element.name)
    });
    return sections
  }

  returnAllProjectPlant() {
    return this.afs.collection(`Projects/${this.usd.setProject.id}/plantReturns`).valueChanges();
  }


































  getSectionz() {
    const mySections = this.sections;
    // console.log(mySections);
    return mySections;
  }

  getTasks() {
    // console.log(this.PrjTasksArr.length);
    const prJtasks = this.PrjTasksArr.filter(task => ((task.name || task.name !== '') && (task.section && task.section.id !== '')));
    // console.log(prJtasks.length);
    return prJtasks;
  }

  progressStatus() {
    const company: any = {
      name: this.usd.setProject.companyName,
      id: this.usd.setProject.companyId,

    }
    company.tasksList = [];
    this.psCompany = company
    this.getSectionz().forEach(element => {
      element.tasks = this.getTasks().filter(task => task.section?.name === element?.name);
      this.getTaskActuals(element);
    });
    this.sections = this.getSectionz();
  }

  getTaskActuals(sec) {
    sec.tasks.forEach(element => {
      const i = sec?.actions?.findIndex(task => (task.name === element.name && task.section.name === sec.name));
      if (i == -1) {
        sec?.actions?.push(element);
      }
    });
    // const tasksList = [];
    sec?.actions?.forEach(element => {
      if (element.invQuantity) { element.targetQty = element.invQuantity; };
      let newVal = 0;
      element.arrTasks = this.getTasks().filter(task => (task.name === element.name && task.section.name === sec.name));
      this.sortPeriod(element);
      // console.log(sec.name, element.name, element.arrTasks.length, element.arrTasks);
      element.arrTasks.forEach(el => {
        if (!(el.actsTotal)) {
          el.actsTotal = 0;
        }
        newVal += el.actsTotal;
        element.actsTotal = newVal;
        // console.log(`${el.description}, ${el.name}, ${el?.actsTotal}, ${element.actsTotal}`);
      });
      this.getAllTypes(element);
    });
  }

  getAllTypes(element) {
    const tskIndex = this.psCompany.tasksList.findIndex((task: any) => (task.name === element.name));
    const tsk = this.psCompany.tasksList.find((task: any) => (task.name === element.name));
    if (element.weightingRpt && tsk) {
      // console.log(tsk.actsTotal, element.actsTotal);
      this.psCompany.tasksList[tskIndex].actsTotal += element.actsTotal;
      this.psCompany.tasksList[tskIndex].targetQty += element.targetQty;
      this.progress(this.psCompany.tasksList);
    } else {
      element.wRpt = element.actsTotal;
      const type = {
        name: element.name, targetQty: element.targetQty, actsTotal: element.actsTotal, weighting: element.weighting, wRpt: element.wRpt,
      }
      this.psCompany.tasksList.push(type);
      this.progress(this.psCompany.tasksList);
    }
  }

  progress(tasks: any[]) {
  // console.log(tasks.length);
    this.psCompany.totalWeightingRpt = 0;
    for (let i = 0; i < tasks.length; i++) {
      const rd = tasks[i];
      if (rd.targetQty && !Number.isNaN(rd.targetQty)) {
        // console.log(rd.name, rd.id, 'totalWeightingRpt', this.psCompany.totalWeightingRpt, 'actsTotal', rd.actsTotal, 'targetQty',
        // rd.targetQty, 'weighting', rd.weighting, 'rpt', ((rd.wRpt / rd.targetQty) * rd.weighting));
        this.psCompany.totalWeightingRpt += ((rd.actsTotal / rd.targetQty) * rd.weighting);
      }
      if (i === (tasks.length - 1)) {
        this.afs.doc(`Projects/${this.usd.setProject.id}/enterprises/${this.usd.setProject.companyId}`)
        .update({'totalWeightingRpt' : this.psCompany.totalWeightingRpt}).then(() => {
          // console.log(i, (tasks.length - 1), 'rpt updated')
        });
      }
    }
  }

  getWeighting(task) {
    const rateFound = this.rates.find(rte => rte.name === task.name);
    if (rateFound) {
      return rateFound.weighting;
    } else {
      return 1;
    }
  }

  sortPeriod(task) {
  // console.log(task);
    task.weighting = this.getWeighting(task);
    this.usd.setProject.weighting = 0
    const setProject = this.usd.setProject;
  // console.log(task.section.name, task.name, startDate(), endDate(), actualls());
    const tDae = moment().format('L');
    task.startDate = startDate();
    task.endDate = endDate();
    task.actsTotal = actualls();
    const period = moment(endDate()).diff(moment(startDate()), 'd');
    task.ToDate = moment(tDae).diff(moment(startDate()), 'd');
  // console.log(task.section.name, task.name, task.startDate, task.endDate, tDae, 'task.Period', period, 'task.ToDate', task.ToDate, 'Fraction', (task.ToDate / period));
    if ((moment(endDate()).diff(moment(startDate()), 'd') === 0 && !task.actsTotal) ||
    (moment(endDate()).diff(moment(startDate()), 'd') === 0 && task.actsTotal === 0)) {
      task.Period = 1; // task.varience = 0; task.plannedPrg = 100;
    // console.log(task.section.name, task.name, task.startDate, task.endDate, tDae, 'task.Period', period, 'task.ToDate', task.ToDate, 'Fraction', (task.ToDate / period));
      cal();
    } else if (moment(endDate()).diff(moment(startDate()), 'd') === 0 && task.actsTotal && task.actsTotal > 0) {
      task.Period = 1;
    // console.log(task.section.name, task.name, task.startDate, task.endDate, tDae, 'task.Period', period, 'task.ToDate', task.ToDate, 'Fraction', (task.ToDate / period));
      calculation();
    } else if (moment(endDate()).diff(moment(startDate()), 'd') > 1) {
      task.Period = moment(endDate()).diff(moment(startDate()), 'd');
    // console.log(task.section.name, task.name, task.startDate, task.endDate, tDae, 'task.Period', task.Period, period,
      // 'task.ToDate', task.ToDate,'Fraction', (task.ToDate / period));
      calculation();
    }

    function calculation() {
    // console.log('today between Start and finish day', task.section.name, task.name, task.startDate, task.endDate, tDae,
      // 'task.Period', period, 'task.ToDate', task.ToDate, '%', Math.round(100 * (task.ToDate / task.Period)));
      task.plannedPrg = Math.round(100 * (task.ToDate / task.Period));
      if (task.plannedPrg > 100) {
        task.plannedPrg = 100;
      } else if (task.plannedPrg < 0) {
        task.plannedPrg = 0
      } else { }
      if (!task.targetQty) {
        task.targetQty = task.actsTotal;
        const dd = (100 * (task.actsTotal / task.targetQty));
        // setProject.weighting += ((task.actsTotal / task.targetQty) * task.weighting);
        task.weightingRpt = ((task.actsTotal / task.targetQty) * task.weighting);
        task.actualPrg = Math.round(dd);
        // console.log('today between Start and finish day, task.actualPrg', dd, 'task.plannedPrg', task.plannedPrg,
        // task.weighting, task.weightingRpt);
      } else {
        const dd = (100 * (task.actsTotal / task.targetQty));
        setProject.weighting += ((task.actsTotal / task.targetQty) * task.weighting);
        task.weightingRpt = ((task.actsTotal / task.targetQty) * task.weighting);
        task.actualPrg = Math.round(dd);
        // console.log('today between Start and finish day, task.actualPrg', dd, 'task.plannedPrg', task.toDate,
        // task.Period, task.plannedPrg, task.weighting, task.weightingRpt);
      }
      if (task.actualPrg > 100) {
        task.actualPrg = 100;
      }
      // console.log('today between Start and finish day, task.actualPrg', task.actualPrg, 'task.plannedPrg', task.plannedPrg);
      task.varience = Math.round((((task.actualPrg - task.plannedPrg) / 100) * task.Period));
    }

    function cal() {
      // let weight = 0;
      if (moment(tDae).isAfter(moment(task.endDate, 'LL')) || moment(tDae).isAfter(moment(task.endDate, 'L'))) {
        if (moment(startDate()).isSame(moment(endDate(), 'LL')) || moment(startDate()).isSame(moment(endDate(), 'L'))) {
          task.plannedPrg = 100;
          sameDatesNotPeriod();
        } else {
        // console.log('today after Finish Date');
        // console.log(task.startDate, task.endDate, tDae, 'task.Period', task.Period, 'task.ToDate', task.ToDate, 'varience', task.varience, 'task.actualPrg', task.actualPrg, 'task.plannedPrg', task.plannedPrg);
          task.plannedPrg = 100;
          task.actualPrg = 0;
          task.varience = Math.round((((task.plannedPrg - task.actualPrg) / 100) * task.Period));
        }
      } else if (moment(tDae).isBefore(moment(startDate(), 'LL')) || moment(tDae).isBefore(moment(startDate(), 'L'))) {
        if (moment(startDate()).isSame(moment(endDate(), 'LL')) || moment(startDate()).isSame(moment(endDate(), 'L'))) {
          task.plannedPrg = 0;
          sameDatesNotPeriod();
        } else {
        // console.log('today is before Task Start Date');
          task.plannedPrg = 100;
          task.actualPrg = 0;
          task.varience = Math.round((((task.plannedPrg - task.actualPrg) / 100) * task.Period));
        // console.log(task.startDate, task.endDate, tDae, 'task.Period', task.Period, 'task.ToDate', task.ToDate, 'varience', task.varience, 'task.actualPrg', task.actualPrg, 'task.plannedPrg', task.plannedPrg);
        }
      }
    }

    function sameDatesNotPeriod() {
      task.actualPrg = 0;
      task.varience = Math.round((((task.plannedPrg - task.actualPrg) / 100) * task.Period));
    // console.log(task.startDate, task.endDate, tDae, 'task.Period', task.Period, 'task.ToDate', task.ToDate, 'varience', task.varience, 'task.actualPrg', task.actualPrg, 'task.plannedPrg', task.plannedPrg);
    }

    function actualls() {
      let actsSum = 0;
      task.arrTasks.forEach(element => actsSum += element.actsTotal)
      return actsSum;
    }

    function startDate(): string {
      task.arrTasks = sortStartDates(task.arrTasks).sort((a, b) => {
        if (a?.startDate && b?.startDate) {
          return a?.startDate.localeCompare(b?.startDate);
        }
      });
      task.startDate = task.arrTasks[0]?.startDate;
      if (!task.startDate) {
      // console.log(task.arrTasks.length);
        if (task.arrTasks.length <= 1) {
          task.startDate = moment().format('L');
        // console.log(task.startDate);
        }
      } else {
      // console.log(task.startDate);
      }
      task.arrTasks.forEach(element => {

      // console.log('Type of Task', task.name, 'Task', element.description, 'Start', element.startDate, 'Finish', element.endDate);
      });
      return task.startDate;
    }

    function sortStartDates(arrTasks) {
      arrTasks.forEach(element => {
        // console.log('Type of Task', task.name, 'Task', element.description, 'Start', element.startDate, 'Finish', element.endDate);
        if (element.startDate) {
          if (moment(element.startDate, 'LL').isValid() || moment(element.startDate, 'L').isValid()) {
            // console.log(element.description, task.startDate);
          } else {
            // console.log(element.description, task.startDate);
          }
          // console.log(task.startDate);
        } else if (!element.startDate || element.startDate === '') {
          task.startDate = moment().format('L');
        }
      });
      return arrTasks;
    }

    function sortEndDates(arrTasks) {
      arrTasks.forEach(element => {
      // console.log('Type of Task', task.name, 'Task', element.description, 'Start', element.startDate, 'Finish', element.endDate);
        if (element.endDate) {
          if (moment(element.startDate, 'LL').isValid() || moment(element.startDate,'L').isValid()) {
          // console.log(element.description, task.startDate);
          } else {
          // console.log(element.description, task.endDate);
          }
        // console.log(element.description, task.endDate);
        } else if (!element.endDate || element.endDate === '') {
          task.endDate = moment().format('L');
        }
      });
      return arrTasks;
    }

    function endDate() {
      task.arrTasks = sortEndDates(task.arrTasks).sort((a, b) => {
        if (b?.endDate && a?.endDate) {
          return b?.endDate.localeCompare(a?.endDate);
        }
      });
      task.endDate = task.arrTasks[0]?.endDate;
      if (!task.endDate) {
      // console.log(task.arrTasks.length);
        if (task.arrTasks.length <= 1) {
          task.endDate = moment().format('L');
        }
      } else {
      // console.log(task.endDate);
      }
      return task.endDate;
    }
  }

  
  projectElem(tasks: any[], type) {
    console.log(type)
    tasks.forEach(element => {
      if (element.projectId === this.usd.setProject.id) {
        if (!element.projectName || (element.projectName !== this.usd.setProject.name)) {
          element.projectName = this.usd.setProject.name;
          this.updateTaskPrj(element)
        }      
      }      
    });
  }
  
  updateTaskPrj(setTask) {
    // console.log(`the actionItem --> ${setTask.description || setTask.name}` , setTask);
    if (!setTask.id && setTask.id === '') { } else {
      if (setTask.champion?.id) {
          /* ----------------------- Set every Other Node --------------------------- */
        if (setTask.byId && setTask.byId !== '' && setTask?.byId !== setTask.champion.id) {
          this.afs.doc(`Users/${setTask.byId}/tasks/${setTask.id}`).update({'projectName': setTask.projectName }).then(() => {
            console.log('byId document updated');
          }).catch((error) => {
            console.log('Error byId document updating Completion, document does not exists trying Again', error);
            this.afs.doc(`Users/${setTask.byId}/tasks/${setTask.id}`).set(setTask).then(() => {
              console.log('document updated');
            })
          });
        }
        if (setTask?.participants?.length > 0) {
          setTask.participants.forEach(element => {
            this.afs.doc(`Users/${element.id}/tasks/${setTask.id}`).update({'projectName': setTask.projectName }).then(() => {
              console.log('supervisor.id document updated');
            }).catch((error) => {
              console.log('Error byId document updating Completion, document does not exists trying Again', error);
              this.afs.doc(`Users/${element.id}/tasks/${setTask.id}`).set(setTask).then(() => {
                console.log('document updated');
              })
            });
          });
        } if (setTask?.supervisor && setTask?.supervisor?.id !== '') {
          this.afs.doc(`Users/${setTask.supervisor.id}/tasks/${setTask.id}`).update({'projectName': setTask.projectName }).then(() => {
            console.log('supervisor.id document updated');
          }).catch((error) => {
            console.log('Error byId document updating Completion, document does not exists trying Again', error);
            // this.afs.doc(`Users/${setTask.supervisor.id}/tasks/${setTask.id}`).set(setTask).then(() => {
            //   console.log('document updated');
            // })
          });
        }
        const taskRoot = this.afs.doc(`tasks/${setTask.id}`);
        taskRoot.update({'projectName': setTask.projectName }).then(() => {
          console.log('document updated');
        }).catch((error) => {
          console.log('Error updating Completion, document does not exists trying Again', error);
          taskRoot.set(setTask).then(() => {
            console.log('document updated');
          })
        });
        if (setTask?.companyId !== '') {
          const compRefII = this.afs.doc(`Enterprises/${setTask.companyId}/tasks/${setTask.id}`);
          // const compRefII = this.afs.collection('Enterprises').doc(setTask.companyId).collection('tasks').doc(setTask.id);
          compRefII.update({'projectName': setTask.projectName }).then(() => {
            console.log('document updated');
          }).catch((error) => {
            console.log('Error updating Completion, document does not exists trying Again', error);
            compRefII.set(setTask).then(() => {
              console.log('document updated');
            })
          });
        }
        if (setTask?.champCompany && setTask?.champCompany?.id !== '') {
          this.afs.doc(`Enterprises/${setTask.champCompany.id}/tasks/${setTask.id}`).update({'projectName': setTask.projectName }).then(() => {
            console.log('compChamp updated');
          }).catch(err => {
            console.log(err)
            this.afs.doc(`Enterprises/${setTask.champCompany.id}/tasks/${setTask.id}`).set(setTask).then(() => {
              console.log('document updated');
            })
          });
        }
        if (setTask.projectId !== '') {
          // const prjectRef = this.afs.collection('Projects').doc(setTask.projectId).collection('tasks').doc(setTask.id);
          const prjectRef = this.afs.doc(`Projects/${setTask.projectId}/tasks/${setTask.id}`);
          prjectRef.update({'projectName': setTask.projectName }).then(() => {
            console.log('document updated');
          }).catch((error) => {
            console.log('Error updating Completion, document does not exists trying Again', error);
            prjectRef.set(setTask).then(() => {
              console.log('document updated');
            })
          });
        }
          /* --------------------- End Set every Other Node -------------------------- */

      }
    }
  }

  getProductTypes() {
    return this.usd.afs.collection<any>(`Projects/${this.usd.setProject.id}/productType`).valueChanges();
  }
  
  getPhases() {
    return this.usd.afs.collection<any>(`Projects/${this.usd.setProject.id}/phases`,ref => ref.orderBy('createdOn', 'asc')).valueChanges();;
  }
}