import { Injectable } from '@angular/core';
import {
  faBook, faCar, faPaperclip, faCheck, faComment, faComments, faCommentAlt, faCommentDots, faCommentSlash, faMoneyCheck, faTag, faTags,
  faAddressCard, faTimesCircle, faTimes, faTrashAlt, faTrash, faEdit, faPalette, faWindowClose, faExternalLinkSquareAlt, faExternalLinkAlt,
  faHandPointUp, faSave, faHome, faSign, faSignInAlt, faEllipsisH, faEllipsisV, faSignOutAlt, faSearch, faReplyAll, faMinus, faLockOpen,
  faSpinner, faEject, faBuilding, faObjectUngroup, faMale, faInfo, faInfoCircle, faClipboard, faObjectGroup, faCarAlt, faTextHeight,
  faTextWidth, faFile, faCalculator, faClock, faMoneyBill, faCog, faCogs, faWind, faFileInvoice, faFilePdf, faBookReader, faMehBlank, faFolderMinus,
  faPiggyBank, faDollarSign, faWrench, faReply, faTools, faToolbox, faSearchPlus, faMinusCircle, faBellSlash, faBell, faExclamationTriangle,
  faExclamation, faExclamationCircle, faFileCsv, faFileExcel, faFileImage, faFileImport, faFileArchive, faFileAlt, faFileAudio, faFileCode,
  faFileContract, faFileDownload, faFileExport, faFileMedical, faFileMedicalAlt, faFilePowerpoint, faFilePrescription, faFileSignature,
  faFileUpload, faFileVideo, faFileWord, faSearchDollar, faPencilRuler, faPenSquare, faPaperPlane, faPenAlt, faUserMinus, faUserTimes,
  faUserEdit, faSms, faArrowAltCircleRight, faArrowAltCircleDown, faArrowAltCircleLeft, faArrowAltCircleUp, faArrowCircleDown, faArrowsAltV,
  faArrowCircleLeft, faArrowCircleRight, faArrowCircleUp, faArrowDown, faArrowLeft, faArrowRight, faArrowUp, faArrowsAlt, faArrowsAltH,
  faLongArrowAltRight, faCartArrowDown, faLongArrowAltDown, faLongArrowAltLeft, faLongArrowAltUp, faExpandArrowsAlt, faQuestionCircle,
  faQuestion, faMailBulk, faUsers, faShoppingCart, faShoppingBag, faShoppingBasket, faTrailer, faSadCry, faHeartBroken, faHeartbeat, faBusinessTime,
  faTicketAlt, faPlus, faPlusCircle, faPlusSquare, faUserPlus, faCartPlus, faFolderPlus, faCalendarPlus, faCheckCircle, faCheckDouble, faCheckSquare,
  faStop, faStopCircle, faBan, faBandAid, faFileInvoiceDollar, faPallet, faUserLock, faLock, faPhoneAlt, faPhone, faMobile, faGlobe, faGlobeAfrica,
  faGlobeAmericas, faGlobeAsia, faGlobeEurope,  faUser, faUserAlt, faUserCheck, faUserNinja, faUserTag, faEnvelope, faCity, faLandmark, faSort,
  faWeight, faWeightHanging, faPrint, faShareAlt, faShare, faShareAltSquare, faShareSquare, faStreetView, faBinoculars, faMapPin, faEnvelopeOpen,
  faEnvelopeOpenText, faEnvelopeSquare, faEye, faSearchLocation, faFilter, faPaste, faDirections, faDrumstickBite, faSwatchbook, faPlane, faPlaneArrival,
  faPlaneDeparture, faHandPaper, faAdjust, faWifi, faPlaneSlash, faHistory, faBackward, faForward
} from '@fortawesome/free-solid-svg-icons';
import { faAngular, faFontAwesome, faFacebook, faGoogle, faTwitter, } from '@fortawesome/free-brands-svg-icons';

@Injectable({
  providedIn: 'root'
})
export class IconsService {
  /* icons */
  faCheckCircle = faCheckCircle; faCheckDouble = faCheckDouble; faCheckSquare = faCheckSquare; faTicketAlt = faTicketAlt; faPhoneAlt = faPhoneAlt;
  faPhone = faPhone; faMobile = faMobile; faGlobe = faGlobe; faGlobeAfrica = faGlobeAfrica; faGlobeAmericas = faGlobeAmericas; faUserNinja = faUserNinja;
  faGlobeAsia = faGlobeAsia; faGlobeEurope = faGlobeEurope;  faUser = faUser; faUserAlt = faUserAlt; faUserCheck = faUserCheck; faUserTag = faUserTag;
  faExpandArrowsAlt = faExpandArrowsAlt; faLongArrowAltLeft = faLongArrowAltLeft; faLongArrowAltUp = faLongArrowAltUp; faLockOpen = faLockOpen;
  faCartArrowDown = faCartArrowDown; faLongArrowAltDown = faLongArrowAltDown; faArrowsAltH = faArrowsAltH; faBan = faBan; faBandAid = faBandAid;
  faLongArrowAltRight = faLongArrowAltRight; faArrowUp = faArrowUp; faArrowsAlt = faArrowsAlt; faArrowLeft = faArrowLeft; faUserLock = faUserLock;
  faArrowRight = faArrowRight; faArrowCircleUp = faArrowCircleUp; faArrowDown = faArrowDown; faArrowCircleLeft = faArrowCircleLeft;
  faArrowCircleRight = faArrowCircleRight; faArrowAltCircleDown = faArrowAltCircleDown; faArrowAltCircleRight = faArrowAltCircleRight;
  faArrowAltCircleLeft = faArrowAltCircleLeft; faArrowAltCircleUp = faArrowAltCircleUp; faArrowCircleDown = faArrowCircleDown; faCity = faCity;
  faArrowsAltV = faArrowsAltV; faUserMinus = faUserMinus; faUserTimes = faUserTimes; faUserEdit = faUserEdit; faMinus = faMinus; faEye = faEye;
  faSms = faSms; faAngular = faAngular; faFontAwesome = faFontAwesome; faBook = faBook; faCar = faCar; faFacebook = faFacebook; faLandmark = faLandmark;
  faGoogle = faGoogle; faTwitter = faTwitter; faPaperclip = faPaperclip; faTimesCircle = faTimesCircle; faTimes = faTimes; faLock = faLock;
  faComment = faComment; faComments = faComments; faCommentAlt = faCommentAlt; faCommentSlash = faCommentSlash;faFileInvoiceDollar = faFileInvoiceDollar;
  faCommentDots = faCommentDots; faMoneyCheck = faMoneyCheck; faTag = faTag; faTags = faTags; faAddressCard = faAddressCard; faStreetView = faStreetView
  faCheck = faCheck; faTrashAlt = faTrashAlt; faTrash = faTrash; faEdit = faEdit; faPalette = faPalette; faWindowClose = faWindowClose;
  faExternalLinkSquareAlt = faExternalLinkSquareAlt; faExternalLinkAlt = faExternalLinkAlt; faHandPointUp = faHandPointUp; faSave = faSave;
  faHome = faHome; faSign = faSign; faSignInAlt = faSignInAlt; faPlus = faPlus; faPlusCircle = faPlusCircle; faEllipsisH = faEllipsisH;
  faEllipsisV = faEllipsisV; faSignOutAlt = faSignOutAlt; faSearch = faSearch; faReplyAll = faReplyAll; faSpinner = faSpinner; faFilter = faFilter;
  faEject = faEject; faBuilding = faBuilding; faObjectUngroup = faObjectUngroup; faMale = faMale; faInfo = faInfo; faStop = faStop; faStopCircle = faStopCircle;
  faInfoCircle = faInfoCircle; faCog = faCog; faCogs = faCogs; faClipboard = faClipboard; faObjectGroup = faObjectGroup; faEnvelope = faEnvelope;
  faCarAlt = faCarAlt; faTextHeight = faTextHeight; faTextWidth = faTextWidth; faFile = faFile; faCalculator = faCalculator; faSort = faSort;
  faClock = faClock; faMehBlank = faMehBlank; faPiggyBank = faPiggyBank; faMoneyBill = faMoneyBill; faDollarSign = faDollarSign; faSwatchbook = faSwatchbook;
  faWind = faWind; faFileInvoice = faFileInvoice; faFilePdf = faFilePdf; faBookReader = faBookReader; faWrench = faWrench; faWeightHanging = faWeightHanging;
  faReply = faReply; faTools = faTools; faToolbox = faToolbox; faSearchPlus = faSearchPlus; faMinusCircle = faMinusCircle; faWeight = faWeight;
  faBellSlash = faBellSlash; faBell = faBell; faExclamationTriangle = faExclamationTriangle; faExclamation = faExclamation; faPrint = faPrint;
  faExclamationCircle = faExclamationCircle; faFileCsv = faFileCsv; faPencilRuler = faPencilRuler; faPenSquare = faPenSquare; faPaste = faPaste;
  faFileExcel = faFileExcel; faFileImage = faFileImage; faFileImport = faFileImport; faFileArchive = faFileArchive; faFileAlt = faFileAlt;
  faFileAudio = faFileAudio; faFileCode = faFileCode; faFileContract = faFileContract; faFileDownload = faFileDownload; faPallet = faPallet;
  faFileExport = faFileExport; faFileMedical = faFileMedical; faFileMedicalAlt = faFileMedicalAlt; faFilePowerpoint = faFilePowerpoint; faFolderMinus = faFolderMinus;
  faFilePrescription = faFilePrescription; faFileSignature = faFileSignature; faFileUpload = faFileUpload; faFileVideo = faFileVideo; faDrumstickBite = faDrumstickBite;
  faFileWord = faFileWord; faSearchDollar = faSearchDollar; faPaperPlane = faPaperPlane; faPenAlt = faPenAlt; faShoppingCart = faShoppingCart;
  faShoppingBag = faShoppingBag; faShoppingBasket = faShoppingBasket; faQuestionCircle = faQuestionCircle; faQuestion = faQuestion; faDirections = faDirections;
  faMailBulk = faMailBulk; faUsers = faUsers; faTrailer = faTrailer; faSadCry = faSadCry; faHeartBroken = faHeartBroken; faHeartbeat = faHeartbeat;
  faBusinessTime = faBusinessTime;  faPlusSquare =  faPlusSquare; faUserPlus = faUserPlus; faCartPlus = faCartPlus; faFolderPlus = faFolderPlus; faCalendarPlus = faCalendarPlus;
  faShareAlt = faShareAlt; faShare = faShare; faShareAltSquare = faShareAltSquare;faShareSquare = faShareSquare;  faBinoculars = faBinoculars; faMapPin = faMapPin;
  faEnvelopeOpen = faEnvelopeOpen; faEnvelopeOpenText = faEnvelopeOpenText; faEnvelopeSquare = faEnvelopeSquare; faSearchLocation = faSearchLocation;
  faPlane = faPlane; faPlaneArrival = faPlaneArrival; faPlaneDeparture = faPlaneDeparture; faHandPaper = faHandPaper; faAdjust = faAdjust; faWifi = faWifi; 
  faHistory = faHistory; faPlaneSlash = faPlaneSlash; faBackward = faBackward; faForward = faForward;
  constructor() { }
}
