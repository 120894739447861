import { Routes } from '@angular/router';
import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';
import { ColoursPanelComponent } from './layouts/colours-panel/colours-panel.component';

export const AppRoutes: Routes = [{
    path: '',
    redirectTo: '/pages/welcome',
    // redirectTo: '/task24-7/tasks',
    pathMatch: 'full',
}, {
    path: '',
    component: AdminLayoutComponent,
    children: [{
        path: '',
        loadChildren: './dashboard/dashboard.module#DashboardModule'
    }, {
        path: 'components',
        loadChildren: './components/components.module#ComponentsModule'
    }, {
        path: 'forms',
        loadChildren: './forms/forms.module#Forms'
    }, {
        path: 'tables',
        loadChildren: './tables/tables.module#TablesModule'
    }, {
        path: 'maps',
        loadChildren: './maps/maps.module#MapsModule'
    }, {
    //     path: 'nyeredzi-map',
    //     loadChildren: './nyeredzi-map/nyeredzi-map.module#NyeredziMapModule'
    // }, {
    //     path: 'charts',
    //     loadChildren: './charts/charts.module#ChartsModule'
    // }, {
        path: 'calendar',
        loadChildren: './calendar/calendar.module#CalendarModule'
    }, {
        path: '',
        loadChildren: './userpage/user.module#UserModule'
    }, {
        path: '',
        loadChildren: './timeline/timeline.module#TimelineModule'
    }, {
        path: '',
        loadChildren: './widgets/widgets.module#WidgetsModule'
    }]
}, {
    path: '',
    component: AuthLayoutComponent,
    children: [{
        path: 'pages',
        loadChildren: './pages/pages.module#PagesModule'
    }, {
        path: 'views',
        loadChildren: './views/views.module#ViewsModule'
    }]
}, {
    path: '',
    component: ColoursPanelComponent,
    children: [{
        path: 'task24-7',
        loadChildren: './views/task24/task24.module#Task24Module'
    }, {
        path: 'charts',
        loadChildren: './charts/charts.module#ChartsModule'
    }, {
        // path: 'transactions',
        path: 'openbooks',
        loadChildren: './views/transactions/transactions.module#TransactionsModule'
    }, {
        path: 'personal',
        loadChildren: './personal/personal.module#PersonalModule'
    }, {
        path: '',
        loadChildren: './amcharts/amcharts.module#AmchartsModule'
    }, {
        path: '',
        loadChildren: './chat/chat.module#ChatModule'
    }, {
        path: '',
        loadChildren: './m4charts/m4charts.module#M4chartsModule'
    }, {
        path: '',
        loadChildren: './issues/issues.module#IssuesModule'
    }]
}
];

/* packages removed */

    // "@types/jasmine": "3.5.7",