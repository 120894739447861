import { Message } from '../models/message';
import { Chat } from '../models/chat';
import { Observable } from 'rxjs';
import { ServicesConfig } from './services-config';
import { Injectable, Optional } from '@angular/core';

export abstract class WriteBaseService {
  constructor(@Optional() config?: ServicesConfig) {
    if (config) {
      console.log('Config:', config);
    }
  }


  // abstract addSub(part: any, link: any): {};
  abstract addDept(part: any): {};

}
