import { Injectable, Optional, TemplateRef, ViewChild } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
// import { FirebaseAuthService } from './this.usd.firebase-auth.service';
import { Router } from '@angular/router';
import { map, tap, switchMap, flatMap } from 'rxjs/operators';
// import { Observable, combineLatest, of, merge } from 'rxjs';
import { Observable, combineLatest, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ChatBaseService } from './chat-base.service';
import { Message } from '../models/message';
import { Chat } from '../models/chat';
import { ServicesConfig } from './services-config';
import * as moment from 'moment';
// import * as this.usd.firebase from 'this.usd.firebase/app';
// import 'this.usd.firebase/firestore';
import { User } from '../models/user';
import { UserDataService } from './user-data.service';
import { InitialiseService } from './initialise.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Enterprise } from 'app/models/enterprise-model';
import { merge } from 'jquery';
@Injectable({
  providedIn: 'root'
})
export class ChatService extends ChatBaseService {
  userDictionary = {};
  contact: any;
  chat$: any;

  @ViewChild('template1') template1: TemplateRef<any>;
  @ViewChild('template2') template2: TemplateRef<any>;
  modalRef: any;
  modalRef2: any;
  chatsinstances: any[];
  getData: boolean;
  prtRef: AngularFirestoreDocument<any>;
  ndRef: AngularFirestoreDocument<any>;
  otherPart: any;
  ndRefpath: string;
  prtRefpath: string;
  constructor( /* private afs: AngularFirestore, private auth: FirebaseAuthService, */ private router: Router, public usd: UserDataService,
    public is: InitialiseService, public modalService: NgbModal, @Optional() config?: ServicesConfig) {
    super(config);
    this.contact = this.is.initContact();
  }

  getHistory(chatId: string): Observable<any> {
    // this.findMyChats(chatId);
    if (chatId !== undefined) {
      this.clearMsgCount(this.usd.setContact.id);
      return this.chatHistory(chatId);
    } else if(this.usd.setContact.chatId === undefined) {
      this.clearMsgCount(this.usd.setContact.id);
      this.usd.afs.doc(`Users/${this.usd.setContact.id}/contacts/${this.usd.acc?.id}`).ref.get().then(userRef => {
        if (userRef.exists && userRef.data().chatId) {
          const chatID = userRef.data().chatId;
          this.usd.afs.doc(`Users/${this.usd.acc?.id}/contacts/${this.usd.setContact.id}`).update({'chatId': chatID}).then(() => {
            this.usd.setContact.chatId = chatID;
            return this.getHistory(chatID);
          })
        }
      });
    } else {
      // participants
      this.create(this.usd.setContact, 'noLink');
    }
  }

  getHistoryAlt(chatId: string): Observable<any> {
    const partB = (this.is.toolSet === 'Customers') ? this.usd.setClient: this.usd.setContact;
    if (chatId !== undefined) {
      this.clearMsgCountAlt(partB.id);
      return this.chatHistoryAlt(chatId);
    } else if(this.usd.setContact.cusChatId === undefined) {
      this.clearMsgCountAlt(partB.id);
      this.prtRef.ref.get().then(userRef => {
        if (userRef.exists && userRef.data().cusChatId) {
          const chatID = userRef.data().cusChatId;
          this.ndRef.update({'cusChatId': chatID}).then(() => {
            this.usd.setContact.cusChatId = chatID;
            return this.chatHistoryAlt(chatID);
          })
        }
      });
    } else {
      // participants
      this.cubChat(this.usd.setContact, 'noLink');
    }
  }

  findMyChats(chatId) {
    console.log([this.usd.setContact.id, this.usd.userId]);
    const results = this.checkEcvents(this.usd.acc.id, this.usd.setContact.id);
    let messages: any[] = [];
    results.subscribe((data: any) => {
      // console.log(data); // Array of documents that match the query
      let filteredData = data.filter(x => x !== undefined && x?.messages !== undefined);
      filteredData = filteredData.filter(x => x?.messages.length !== 0);
      this.chatsinstances = filteredData;
      const sndFilter = filteredData.filter(x => ((x.id !== chatId) || (x.chatId !== chatId)))
      const findChat = this.chatsinstances.find(x => ((x.id === chatId) || (x.chatId === chatId)));
      console.log(sndFilter?.length, filteredData?.length);
      for (let b = 0; b < filteredData.length; b++) {
        // const element = filteredData[b];
        const mgs = filteredData[b].messages;
        for (let x = 0; x < mgs.length; x++) {
          const mx = mgs[x];
          messages.push(mx);
        }
        if (b === filteredData.length -1) {
          console.log(messages);
          messages.forEach(txt => {
            const same = findChat.messages.find(i => ((i.createdAt.nanoseconds === txt.createdAt.nanoseconds)));
            if (same) {
              console.log('Same', same.content, txt.content, txt.createdAt.nanoseconds, same.createdAt.nanoseconds)
            } else {
              console.log('Not same', txt, txt.content);
              this.updateChats(chatId, txt)
            }
          })
        }
      }    
    })
  }
  
  updateChats(chatId, data) {
    const path = `chats/${chatId}`
    const ref = this.usd.afs.doc<any>(path);
    return ref.update({ messages: this.usd.firebase.firestore.FieldValue.arrayUnion(data) }).then(() => {
      // console.log(data, 'upadated');
    });
  }

  chatHistory(chatId) {
    return this.usd.afs.doc<Chat>(`chats/${chatId}`).snapshotChanges().pipe(map(doc => {
      const data: any = doc.payload.data();
      data.messages.sort((a,b) => moment(a?.createdAt).diff(moment(b?.createdAt)));
      if ((data.participants[0] === this.usd.acc.id && data.participants[1] === this.usd.setContact.id) || (data.participants[0] === this.usd.setContact.id && data.participants[1] === this.usd.acc.id)) {
        return { id: doc.payload.id, ...data };
      } else {
        this.create(this.usd.setContact, 'noLink');
      }
    }));
  }

  chatHistoryAlt(chatId) {
    return this.usd.afs.doc<Chat>(`chats/${chatId}`).snapshotChanges().pipe(map(doc => {
      const data: any = doc.payload.data(); data.id = doc.payload.id;
      const pdata = data.participants.filter(x => x !== '');
      let uniqueArray = Array.from(new Set(pdata));
      if (data.participants?.length !== uniqueArray?.length) {
        data.participants = uniqueArray;
        this.usd.afs.doc<Chat>(`chats/${chatId}`).update({ 'participants': data.participants });
      }
      // console.log(data);
      data.messages.sort((a,b) => moment(a?.createdAt).diff(moment(b?.createdAt)));      
      const myId = data.participants.find(x => x === this.usd.acc.id);
      if (!data.ownerId || !myId) {
        (data.ownerId && data.participants.find(x => x === data.ownerId)) ? data.participants.push(data.ownerId): null;
        (this.usd.acc.id && data.participants.find(x => x !== this.usd.acc.id)) ? data.participants.push(this.usd.acc.id): null;
        const pdata = data.participants.filter(x => x === '');
        if (data.participants?.length !== pdata?.length) {
         this.usd.afs.doc<Chat>(`chats/${chatId}`).update({ 'participants': data.participants });
        }
        return { id: doc.payload.id, ...data };
      } else {
        return { id: doc.payload.id, ...data };
      }
    }));
  }

  checkEcvents(string1: string, string2: string) {
    const collectionRef = this.usd.afs.collection('chats', ref => ref.where('participants', 'array-contains', string1))
      return collectionRef.snapshotChanges().pipe(map((b) => b.map((a) => {
      const data = a.payload.doc.data() as any;
      data.id = a.payload.doc.id;
      const cht = data.participants as any[];
      // const chat = data as any;
      if(cht?.length === 2 && this.containsBothTags(cht, string1, string2) === true) { /* console.log(chat); */ return data }
      // return data;
    })));
  }

  containsBothTags(arr: string[], string1: string, string2: string ): boolean {
    // console.log(arr);
    return [string1, string2].every(tag => arr.includes(tag));
  }

  async create(part, link): Promise<boolean> {
    // Fetch user and wait for result
    const { name } = await this.usd.acc;
    console.log(name);
    const { id } = await this.usd.acc;
    const userRef = this.usd.afs.doc(`Users/${id}/contacts/${part.id}`);
    const partRef = this.usd.afs.doc(`Users/${part.id}/contacts/${this.usd.acc?.id}`);
    const docRef = this.usd.afs.collection('chats');
    this.prtRef = partRef;
    this.ndRef = userRef;
    console.log(id);
    const data: Chat = {
      createdAt: this.usd.firebase.firestore.Timestamp.now(), count: 0,
      messages: [], participants: [this.usd.acc?.id, part.id], ownerId: id, typing: []
    };
    const newContact = {
      email: this.usd.acc.email, bus_email: this.usd.acc.bus_email, name: this.usd.acc.name, id: this.usd.acc?.id,
      chatId: '', phoneNumber: this.usd.acc.phoneNumber
    };
    const prtContact = {
      email: part.email, bus_email: part.bus_email, name: part.name, id: part?.id,
      chatId: '', phoneNumber: part.phoneNumber
    };
    console.log(data);
    console.log(this.usd.acc);
    const partFnd = this.usd.myContacts.find(i => i.id === part.id);
    if (partFnd & partFnd.chatId) {
      return this.openChat(partFnd, link);
    } else  { 
      userRef.ref.get().then(usr => {
        if (usr.exists) {
          const usData = usr.data();
          if (usData.chatId) {
            this.assignDetails(usData, link)
            console.log('Chat exists', usData);
            this.addContact(docRef, usData, data, link, usr);
          } else {
            docRef.add(data).then(dat => {
              newContact.chatId = dat.id;
              partRef.update({ 'chatId': dat.id }).then(() => console.log('Chat updated')).catch(er => {
                console.log(newContact);
                partRef.ref.get().then(prt => {
                  if (prt.exists) { } else {
                    partRef.set(newContact).then(() => console.log('Chat Set'));
                  }
                })
              });
              userRef.update({ 'chatId': dat.id }).then(() => {
                console.log('Chat Updated');
                return this.openChat(dat, link);
              });
            })
          }
          return this.openChat(usData, link);
        } else {
          partRef.ref.get().then(prt => {
            if (prt.exists) {
              const usData = prt.data();
              this.assignDetails(part, link)
              console.log('Chat exists', usData);
              if (usData.chatId) {
                this.doAdds(docRef, usData, data, link, prt);
                prtContact.chatId = usData.chatId;
                console.log(prtContact);
                userRef.ref.get().then(prt => {
                  if (prt.exists) { } else {
                    userRef.set(prtContact).then(() => console.log('Chat Set'));
                  }
                })
              } else {
                docRef.add(data).then(dat => {
                  partRef.update({ 'chatId': dat.id }).then(() => console.log('Chat created')).catch(er => { })
                  userRef.update({ 'chatId': dat.id }).then(() => {
                    // Route to new chat in chat component
                    console.log('Chat Updated');
                    return this.openChat(dat, link);
                  });
                })
              }
            } else {
              // Add new chat data to firestore and wait for result
              const newContact = {
                email: part.email, bus_email: part.bus_email, name: part.name, id: part.id, chatId: '', phoneNumber: part.phoneNumber
              };
              this.elseCreate(docRef, userRef, partRef, data, newContact, link);
            }
          })
        }
    // }
      })
    }
  }

// : Promise<boolean>
async cubChatArch(part, link) {
  console.log('cubChat', this.is.toolSet);
  // Fetch user and wait for result
  let partB
  this.otherPart = part;
  const docRef = this.usd.afs.collection('chats');
  let data: Chat = { createdAt: this.usd.firebase.firestore.Timestamp.now(), count: 0, messages: [], participants: [this.usd.userId, part.id], ownerId: '', typing: [] };
  let host: any;
  let prtContact: any
  if (this.is.toolSet === 'Customers') {
    partB = this.usd.setCompany; data.ownerId = partB.id; data.participants.push(partB.id);
    console.log('Customer', part)
    host = this.customerType(part, partB);
    prtContact = this.seTChatId(partB);
    console.log('Customer chat ','host', host, 'customer', prtContact);
    this.customerChat(part, prtContact, host, link, data, docRef);
  } if (this.is.toolSet === 'client') {
    // partB = this.usd.setClient; data.ownerId = partB.id; data.participants.push(partB.id);
    partB = part; data.ownerId = partB.id; data.participants.push(partB.id);
    host = this.customerType(part, partB);
    prtContact = this.seTChatId(partB);
    console.log('client chat ','host', host, 'client', prtContact);
    this.customerChat(part, prtContact, host, link, data, docRef);
  } else {
    // Normal chat
    partB = part; 
    data.ownerId = data.ownerId ? data.ownerId : partB.id; data.participants.push(partB.id);
    console.log(partB);
    host = this.customerType(part, partB);
    prtContact = this.seTChatId(partB);
    console.log('else Normal chat ','host', host, 'otherpart', prtContact);
    this.customerChat(part, prtContact, host, link, data, docRef);
    
  }
}

async cubChat(part, link) {
  console.log('cubChat', this.is.toolSet);
  // Fetch user and wait for result
  let partB
  this.otherPart = part;
  const docRef = this.usd.afs.collection('chats');
  let data: Chat = { createdAt: this.usd.firebase.firestore.Timestamp.now(), count: 0, messages: [], participants: [this.usd.userId, part.id], ownerId: '', typing: [] };
  let host: any;
  let prtContact: any
  if (this.usd.ClientChat === 'Myclient') {
    partB = this.usd.acc; data.ownerId = partB.id; data.participants.push(partB.id);
    // partB = this.usd.setCompany; data.ownerId = partB.id; data.participants.push(partB.id);
    console.log('Customer', part, partB)
    // host = this.customerType(part, partB);
    // prtContact = this.seTChatId(partB);
    prtContact = this.customerType(part, partB);
    host = this.seTChatId(partB);
    console.log('Customer chat ','host', host, 'customer', prtContact);
    this.customerChat(part, prtContact, host, link, data, docRef);
  } if (this.is.toolSet === 'entClient') {
    // partB = this.usd.setClient; data.ownerId = partB.id; data.participants.push(partB.id);
    partB = part; data.ownerId = partB.id; data.participants.push(partB.id);
    // host = this.customerType(part, partB);
    // prtContact = this.seTChatId(partB);
    prtContact = this.customerType(part, partB);
    host = this.seTChatId(partB);
    console.log('client chat ','host', host, 'client', prtContact);
    this.customerChat(part, prtContact, host, link, data, docRef);
  } else {
    // Normal chat
    partB = part; 
    data.ownerId = data.ownerId ? data.ownerId : partB.id; data.participants.push(partB.id);
    console.log(partB);
    // host = this.customerType(part, partB);
    // prtContact = this.seTChatId(partB);
    prtContact = this.customerType(part, partB);
    host = this.seTChatId(partB);
    console.log('else Normal chat ','host', host, 'otherpart', prtContact);
    this.customerChat(part, prtContact, host, link, data, docRef);
    
  }
}

  contactChat(part, link, docRef, userRef, partRef) {
    console.log('contactChat');
    
    const data: Chat = {
      createdAt: this.usd.firebase.firestore.Timestamp.now(), count: 0,
      messages: [], participants: [this.usd.acc?.id, part.id], ownerId: this.usd.acc?.id, typing: []
    };
    const newContact = {
      email: this.usd.acc.email, bus_email: this.usd.acc.bus_email, name: this.usd.acc.name, id: this.usd.acc?.id,
      chatId: '', phoneNumber: this.usd.acc.phoneNumber
    };
    const prtContact = {
      email: part.email, bus_email: part.bus_email, name: part.name, id: part?.id,
      chatId: '', phoneNumber: part.phoneNumber
    };
    console.log(data);
    console.log(this.usd.acc);
    const partFnd = this.usd.myContacts.find(i => i.id === part.id);
    if (partFnd & partFnd.chatId) {
      return this.openChat(partFnd, link);
    } else  { 
      userRef.ref.get().then(usr => {
        if (usr.exists) {
          const usData = usr.data();
          if (usData.chatId) {
            this.assignDetails(usData, link)
            console.log('Chat exists', usData);
            this.addContact(docRef, usData, data, link, usr);
          } else {
            docRef.add(data).then(dat => {
              newContact.chatId = dat.id;
              partRef.update({ 'chatId': dat.id }).then(() => console.log('Chat updated')).catch(er => {
                console.log(newContact);
                partRef.ref.get().then(prt => {
                  if (prt.exists) { } else {
                    partRef.set(newContact).then(() => console.log('Chat Set'));
                  }
                })
              });
              userRef.update({ 'chatId': dat.id }).then(() => {
                console.log('Chat Updated');
                return this.openChat(dat, link);
              });
            })
          }
          return this.openChat(usData, link);
        } else {
          partRef.ref.get().then(prt => {
            if (prt.exists) {
              const usData = prt.data();
              this.assignDetails(part, link)
              console.log('Chat exists', usData);
              if (usData.chatId) {
                this.doAdds(docRef, usData, data, link, prt);
                prtContact.chatId = usData.chatId;
                console.log(prtContact);
                userRef.ref.get().then(prt => {
                  if (prt.exists) { } else {
                    userRef.set(prtContact).then(() => console.log('Chat Set'));
                  }
                })
              } else {
                docRef.add(data).then(dat => {
                  partRef.update({ 'chatId': dat.id }).then(() => console.log('Chat created')).catch(er => { })
                  userRef.update({ 'chatId': dat.id }).then(() => {
                    // Route to new chat in chat component
                    console.log('Chat Updated');
                    return this.openChat(dat, link);
                  });
                })
              }
            } else {
              // Add new chat data to firestore and wait for result
              const newContact = {
                email: part.email, bus_email: part.bus_email, name: part.name, id: part.id, chatId: '', phoneNumber: part.phoneNumber
              };
              this.elseCreate(docRef, userRef, partRef, data, newContact, link);
            }
          })
        }
    // }
      })
    }
  }

  customerChat(part, prtContact, host, link, data, docRef) {
    console.log('part: ', part, 'host', host, 'customer', prtContact);
    console.log('cusChatId', part.cusChatId);
    // if (part & part.cusChatId) {
    if (part.cusChatId) {
      console.log('cusChatId', part.cusChatId);
      return this.openChat(part, link);
    } else  { 
      this.prtRef.ref.get().then(usr => {
        if (usr.exists) {
          const usData = usr.data();
          if (usData.cusChatId) {
            this.assignDetails(usData, link)
            console.log('Chat exists', usData);
            this.addCusChat(docRef, usData, data, link, usr);
          } else {
            docRef.add(data).then(dat => {
              host.cusChatId = dat.id;
              this.ndRef.update({ 'cusChatId': dat.id }).then(() => console.log('Chat updated')).catch(er => {
                console.log(host);
                this.ndRef.ref.get().then(prt => {
                  if (prt.exists) { } else {
                    this.ndRef.set(host).then(() => console.log('Chat Set'));
                  }
                })
              })
              this.prtRef.update({ 'cusChatId': dat.id }).then(() => {
                console.log('Chat Updated');
                return this.openChat(dat, link);
              });
            })
          }
          return this.openChat(usData, link);
        } else {
          this.ndRef.ref.get().then(prt => {
            if (prt.exists) {
              const usData = prt.data();
              this.assignDetails(part, link)
              console.log('Chat exists', usData);
              if (usData.cusChatId) {
                this.doAdds(docRef, usData, data, link, prt);
                prtContact.cusChatId = usData.cusChatId;
                console.log(prtContact);
                this.prtRef.ref.get().then(prt => {
                  if (prt.exists) { } else {
                    this.prtRef.set(prtContact).then(() => console.log('Chat Set'));
                  }
                })
              } else {
                docRef.add(data).then(dat => {
                  this.ndRef.update({ 'cusChatId': dat.id }).then(() => console.log('Chat created')).catch(er => { })
                  this.prtRef.update({ 'cusChatId': dat.id }).then(() => {
                    // Route to new chat in chat component
                    console.log('Chat Updated');
                    return this.openChat(dat, link);
                  });
                })
              }
            } else {
              // Add new chat data to firestore and wait for result
              const newContact = {
                email: part.email, bus_email: part.bus_email, name: part.name, id: part.id, cusChatId: '', phoneNumber: part.phoneNumber
              };
              // this.elseCreate(docRef, this.prtRef, this.ndRef, data, newContact, link);
            }
          })
        }
    // }
      })
    }
  }

  seTChatId (part) {
    if (this.is.toolSet === 'Customers' || this.is.toolSet === 'client') {
      if (part.srTag === 'ind') {
        return { email: part?.email || '', bus_email: part.bus_email || '', name: part.name || '', id: part?.id || '', cusChatId: part?.chatId || part?.cusChatId || '', phoneNumber: part?.phoneNumber || '' };
      }
      if (part.srTag === 'ent') {
        return { email: part?.champion?.email || '', bus_email: part?.champion.bus_email || '', name: part?.champion?.name || '', company: { name: part.name || '', id: part?.id || '' }, id: part?.champion?.id || '', cusChatId: part?.chatId || part?.cusChatId || '', phoneNumber: part.phoneNumber || '' };
      }
    } else {
      return { email: part?.email || '', bus_email: part?.bus_email || '', name: part?.name || '', id: part?.id || '', chatId: part?.chatId || part?.cusChatId || '', phoneNumber: part?.phoneNumber || '' };
    }
  } 

  customerType(customer, me) {
    console.log('Customer: ', customer , "logged in user: ", this.usd.acc,  "me", me, "itool set: ", this.usd.ClientChat );
    if (this.usd.ClientChat === 'entClient' || this.usd.ClientChat === 'Myclient') {
      console.log('if', this.usd.ClientChat);
      return this.checkCustomer(customer, me);
    } else {
      if (customer.srTag) {
       return this.checkCustomer(customer, me);
      } else {
        console.log("itool set not customers or client: ", this.usd.ClientChat);
        console.log("customer:", customer.srTag);
        this.ndRef = this.usd.afs.doc(`Users/${me?.id}/contacts/${customer.id}`);
        this.prtRef = this.usd.afs.doc(`Users/${customer.id}/contacts/${me?.id}`);
        const newContact = {
          email: this.usd.acc.email, bus_email: this.usd.acc.bus_email, name: this.usd.acc.name, id: this.usd.acc?.id,
          chatId: '', phoneNumber: this.usd.acc.phoneNumber
        };
        return newContact;
      }
    }
  }

  checkCustomerArch(customer, me) {
    if (customer.srTag === 'ind') {
      console.log("Individual customer:",customer.srTag);
      this.prtRef = this.usd.afs.doc(`Users/${customer?.id}/myCustomers/${me?.id}`);
      this.ndRef = this.usd.afs.doc(`Enterprises/${me.id}/clients/${customer?.id}`);
      return { email: this.usd.acc.email, bus_email: this.usd.acc.bus_email, name: this.usd.acc.name, id: this.usd.acc?.id, cusChatId: customer.cusChatId || '', phoneNumber: this.usd.acc.phoneNumber };
    } else if (customer.srTag === 'ent' ) {
      console.log(customer.srTag);
      console.log("enterprise customer:",customer.srTag, me);
      this.prtRef = this.usd.afs.doc(`Enterprises/${customer.id}/clients/${me.id}`);
      this.ndRef = this.usd.afs.doc(`Enterprises/${me.id}/clients/${customer.id}`);
      return { name: customer.name, id: customer.id, champion: customer.champion, by: customer.by, byId: customer.byid, cusChatId: customer.cusChatId || '', managers: customer.managers };
    } else {
      console.log('Customer has no srTag', customer);
    }
  }
  
  
  checkCustomer(customer, me) {
    console.log(this.usd.ClientChat, 'customer', customer, 'me', me);
    if (this.usd.ClientChat === 'Myclient') {
      // if (this.is.chatToolset === 'client') {
      if (customer.srTag === 'ind') {
        console.log("Individual customer:",customer.srTag);
        this.prtRef = this.usd.afs.doc(`Users/${customer?.id}/myCustomers/${this.usd.acc?.id}`);
        this.ndRef = this.usd.afs.doc(`Users/${me?.id}/myCustomers/${customer?.id}`);
        this.prtRefpath = 'Users/${customer?.id}/myCustomers/${me?.id}';
        this.ndRefpath = 'Users/${me?.id}/myCustomers/${customer?.id}';
        return customer;
        // return { email: this.usd.acc.email, bus_email: this.usd.acc.bus_email, name: this.usd.acc.name, id: this.usd.acc?.id, cusChatId: customer.cusChatId || '', phoneNumber: this.usd.acc.phoneNumber };
      } else {
        console.log(customer.srTag);
        console.log("enterprise customer:",customer.srTag, me);
        console.log('Customer: ', customer , "logged in user: ", this.usd.acc,  "me", me, "itool set: ", this.usd.ClientChat );
        this.prtRef = this.usd.afs.doc(`Enterprises/${customer.id}/clients/${this.usd.acc.id}`);
        this.ndRef = this.usd.afs.doc(`Users/${me?.id}/myCustomers/${customer?.id}`);
        // this.ndRef = this.usd.afs.doc(`Enterprises/${me.id}/clients/${customer.id}`);
        console.log(this.prtRef);
        
        this.prtRefpath = 'Enterprises/${customer.id}/clients/${me.id}';
        this.ndRefpath = 'Users/${me?.id}/myCustomers/${customer?.id}';
        return { name: customer.name, id: customer.id, champion: customer.champion, by: customer.by, byId: customer.byid, cusChatId: customer.cusChatId || '', managers: customer.managers };
      }
    } else if (this.usd.ClientChat === 'entClient') {
      if (customer.srTag === 'ind') {
        console.log("Individual customer:",customer.srTag);
        this.prtRef = this.usd.afs.doc(`Users/${customer?.id}/myCustomers/${me?.id}`);
        this.ndRef = this.usd.afs.doc(`Enterprises/${me.id}/clients/${customer.id}`);
        this.prtRefpath = 'Users/${customer?.id}/myCustomers/${me?.id}';
        this.ndRefpath = 'Enterprises/${me.id}/clients/${customer.id}';
        return { email: this.usd.acc.email, bus_email: this.usd.acc.bus_email, name: this.usd.acc.name, id: this.usd.acc?.id, cusChatId: customer.cusChatId || '', phoneNumber: this.usd.acc.phoneNumber };
      } else {
        console.log(customer.srTag);
        console.log("enterprise customer:",customer.srTag, me);
        this.prtRef = this.usd.afs.doc(`Enterprises/${customer.id}/clients/${me.id}`);
        this.ndRef = this.usd.afs.doc(`Enterprises/${me.id}/clients/${customer.id}`);
        this.prtRefpath = 'Enterprises/${customer.id}/clients/${me.id}';
        this.ndRefpath = 'Enterprises/${me.id}/clients/${customer?.id}';
        return { name: customer.name, id: customer.id, champion: customer.champion, by: customer.by, byId: customer.byid, cusChatId: customer.cusChatId || '', managers: customer.managers };
      }
      // console.log("Individual customer:",customer.srTag);
      // this.prtRef = this.usd.afs.doc(`Users/${customer?.id}/myCustomers/${me?.id}`);
      // this.ndRef = this.usd.afs.doc(`Enterprises/${me.id}/clients/${customer?.id}`);
      // this.prtRefpath = 'Users/${customer?.id}/myCustomers/${me?.id}';
      // this.ndRefpath = 'Enterprises/${me.id}/clients/${customer?.id}';
      // return { email: this.usd.acc.email, bus_email: this.usd.acc.bus_email, name: this.usd.acc.name, id: this.usd.acc?.id, cusChatId: customer.cusChatId || '', phoneNumber: this.usd.acc.phoneNumber };
    } else {
      console.log('Customer has no chatToolset', this.is.chatToolset, this.usd.ClientChat);
    }
  }

  checkCustomerArchw(customer, me) {
    if (this.is.toolSet === 'Personal' || this.is.toolSet === 'Enterprise' || this.is.toolSet === 'Project') {
      console.log("Individual customer:",customer.srTag);
      this.prtRef = this.usd.afs.doc(`Users/${customer?.id}/myCustomers/${me?.id}`);
      this.ndRef = this.usd.afs.doc(`Enterprises/${me.id}/clients/${customer?.id}`);
      return { email: this.usd.acc.email, bus_email: this.usd.acc.bus_email, name: this.usd.acc.name, id: this.usd.acc?.id, cusChatId: customer.cusChatId || '', phoneNumber: this.usd.acc.phoneNumber };
    } else if (this.is.toolSet === 'Customers') {
      if (customer.srTag === 'ind') {
        console.log("Individual customer:",customer.srTag);
        this.prtRef = this.usd.afs.doc(`Users/${customer?.id}/myCustomers/${me?.id}`);
        this.ndRef = this.usd.afs.doc(`Enterprises/${me.id}/clients/${customer?.id}`);
        return { email: this.usd.acc.email, bus_email: this.usd.acc.bus_email, name: this.usd.acc.name, id: this.usd.acc?.id, cusChatId: customer.cusChatId || '', phoneNumber: this.usd.acc.phoneNumber };
      } else {
        console.log(customer.srTag);
        console.log("enterprise customer:",customer.srTag, me);
        this.prtRef = this.usd.afs.doc(`Enterprises/${customer.id}/clients/${me.id}`);
        this.ndRef = this.usd.afs.doc(`Enterprises/${me.id}/clients/${customer.id}`);
        return { name: customer.name, id: customer.id, champion: customer.champion, by: customer.by, byId: customer.byid, cusChatId: customer.cusChatId || '', managers: customer.managers };
      }
      //  if (customer.srTag === 'ent' )
    } else {
      console.log('Customer has no srTag', customer);
    }
  }

  getChannel(prt, link) {
    // console.log('company', this.usd.setCompany.name);
    console.log(prt, link);
    if (prt.srTag === 'ind') {
      // this.cusChat(prt, link);
      console.log('individual customer in getChannel: ', prt);
      // this.cubChattwo(prt, link);
      this.cubChat(prt, link);
    } else if (prt.srTag === 'ent') {
      console.log('ent customer in getChannel: ', prt.name, 'srTag', prt.srTag);
      this.cubChat(prt, link);
    } else {}
  }

  assignDetails(part, link) {
    if (link === 'link') {
      this.usd.setContact = part;
      this.usd.setContact.link = 'link';
      console.log(this.usd.setContact.name, link);
    } else {
      this.is.chatSet = true;
      this.usd.setContact = part;
      this.usd.setContact.link = '';
    }
  }

  addContact(docRef, usData, data, link, usr) {
    docRef.doc(usData.chatId).ref.get().then(cht => {
      if (cht.exists) {
        console.log(cht.id, cht.data());
        const chatData = cht.data()
        chatData.chatId = usData.chatId;
        if (!cht.id) {
          cht.data().id = usData.chatId;
          docRef.doc(usData.chatId).update({ 'id': usData.chatId, 'chatId': usData.chatId })
        }
        if (!usr.exists) {
          const other = cht.data().participants.find(ur => ur !== this.usd.userId)
          // this.usd.afs.doc(`Users/${this.usd.userId}/contacts/${other}`).ref.get().then(yuw => {

          // })
        }
        return this.openChat(chatData, link);
      } else {
        docRef.add(data).then(dft => {
          const idi = dft.id;
          docRef.doc(idi).update({ 'id': idi, 'chatId': idi }).then(() => {
            return this.openChat(dft, link);
          })
        })
      }
    })
  }
  
  addCusChat(docRef, usData, data, link, usr) {
    docRef.doc(usData.cusChatId).ref.get().then(cht => {
      if (cht.exists) {
        console.log(cht.id, cht.data());
        const chatData = cht.data()
        chatData.cusChatId = usData.cusChatId;
        if (!cht.id) {
          usData.cusChatId = (usData.cusChatId && usData.cusChatId !== '') ? usData.cusChatId: cht.id;
          docRef.doc(usData.cusChatId).update({ 'id': usData.cusChatId, 'cusChatId': usData.cusChatId, 'participants': data.participants })
        }
        if (!usr.exists) {
          // const other = cht.data().participants.find(ur => ur !== this.usd.userId)
          // this.usd.afs.doc(`Users/${this.usd.userId}/contacts/${other}`).ref.get().then(yuw => {

          // })
        }
        return this.openChat(chatData, link);
      } else {
        docRef.add(data).then(dft => {
          const idi = dft.id;
          docRef.doc(idi).update({ 'id': idi, 'cusChatId': idi }).then(() => {
            return this.openChat(dft, link);
          })
        })
      }
    })
  }

  doAdds(docRef, usData, data, link, prt) {
    docRef.doc(usData.chatId).ref.get().then(cht => {
      if (cht.exists) {
        console.log(cht.id, cht.data());
        if (!cht.id) {
          cht.data().id = usData.chatId;
          docRef.doc(usData.chatId).update({ 'id': usData.chatId })
        }
        if (!prt.exists) {
          const other = cht.data().participants.find(ur => ur === this.usd.userId)
          this.usd.afs.doc(`Users/${usData.id}/contacts/${other}`).ref.get().then(yuw => {
            console.log('My doc added to his contacts');
          })
        }
        return this.openChat(cht.data(), link);
      } else {
        docRef.add(data).then(dft => {
          const idi = dft.id;
          docRef.doc(idi).update({ 'chatId': idi }).then(() => {
            return this.openChat(dft, link);
          })
        })
      }
    })
  }

  openChat(data, link) {
    console.log("Received chat data in open chat: ", data, "Link: ", link );
    if (link === 'link') {
      // if (this.is.toolSet === 'Enterprise' || this.is.toolSet === 'Customers' || this.is.toolSet === 'client') {
      if (this.is.toolSet === 'Customers' || this.is.toolSet === 'client') {
        if(data?.cusChatId){
          return this.router.navigate(['chats', data.cusChatId]);
        }
      } else {
        if(data?.cusChatId){
          return this.router.navigate(['chats', data.cusChatId]);
        }
        if(data?.chatId){
          return this.router.navigate(['chats', data.chatId]);
        }
      }
    } else {
      this.is.chatSet = true;
      this.usd.setContact.link = '';
    }
    // if (link === 'link') {
    //   this.is.chatSet = true;
    //   if(data?.chatId){
    //     return this.router.navigate(['chats', data.chatId]);
    //   }
    // } else {
    //   this.is.chatSet = true;
    //   this.usd.setContact.link = '';
    // }
  }

  openChatArch(data, link) {
    console.log("Received chat data in open chat: ", data, "Link: ", link );
    if (link === 'link') {
      this.is.chatSet = true;
      if(data?.chatId){
        return this.router.navigate(['chats', data.chatId]);
      }
    } else {
      this.is.chatSet = true;
      this.usd.setContact.link = '';
    }
  }

  elseCreate(docRef: AngularFirestoreCollection<any>, userRef: AngularFirestoreDocument<any>, partRef, data, part, link) {
    console.log('elseCreate');
    docRef.add(data).then(dat => {
      part.chatId = dat.id;
      docRef.doc(part.chatId).update({ 'chatId': part.chatId });
      partRef.set(part).then(() => console.log('my contact set')).catch(er => { console.log(er) });
      userRef.set(part).then(() => {
        // Route to new chat in chat component
        console.log('Chat created', part.chatId);
        return this.openChat(part, link);
      }).catch(error => {
        console.log(error); userRef.set(part);
        return this.openChat(dat, link);
      });
    })
  }

  async sendIsTyping(chatId: string): Promise<void> {
    const { id } = await this.usd.acc;
    if (id) {
      const ref = this.usd.afs.collection('chats').doc(chatId);
      return ref.update({
        typing: this.usd.firebase.firestore.FieldValue.arrayUnion(id)
      });
    }
  }

  async deleteIsTyping(chatId: string): Promise<void> {
    const { id } = await this.usd.acc;
    if (id) {
      const ref = this.usd.afs.collection('chats').doc(chatId);
      return ref.update({
        typing: this.usd.firebase.firestore.FieldValue.arrayRemove(id)
      });
    }
  }

  async sendMessage(chatId: string, content: string): Promise<void> {
    console.log('sendMessage');
    const { id } = await this.usd.acc;
    const flag = { status: 'new', fromId: id };
    const data = { id, content, createdAt: this.usd.firebase.firestore.Timestamp.now() };
    const other = this.chat$.participants.find(ur => ur !== this.usd.userId)
    if (id) {
      const path = `chats/${chatId}`
      const ref = this.usd.afs.doc<any>(path);
      return ref.update({
        messages: this.usd.firebase.firestore.FieldValue.arrayUnion(data),
        flag: flag,
      }).then(() => {
        this.msgCount(other, chatId)
      });
    }
  }
  
  async sendMessageAlt(chatId: string, content: string): Promise<void> {
    console.log('sendMessageAlt');
    // const { id } = this.otherPart;
    const { id } = await this.usd.acc;
    const { srTag } = this.otherPart;
    const flag = { status: 'new', fromId: id };
    const data = { id, content, createdAt: this.usd.firebase.firestore.Timestamp.now() };
    console.log(chatId, this.chat$);
    if (id && chatId === this.chat$.id) {
      const path = `chats/${chatId}`
      const ref = this.usd.afs.doc<any>(path);
      return ref.update({ messages: this.usd.firebase.firestore.FieldValue.arrayUnion(data), flag: flag }).then(() => {
        console.log('Message set');
        // this.msgCountAlt(other, chatId)
        // if (srTag === 'ent') {
          console.log(srTag, 'ndRef', 'path', this.prtRefpath);
          this.msgCountAlt(chatId, this.prtRef);
        // } else if (srTag === 'ind') {
        //   console.log(srTag, 'prtRef', 'path', this.ndRefpath);
        //   this.msgCountAlt(chatId, this.ndRef);
        // } else {}
      });
    }
  }

  msgCountAlt(chatId, partRef: AngularFirestoreDocument<any>) {
    console.log('msgCountAlt');
    const dd = moment().toString();
    // const total = msgRef.data().msgNo + 1;
    // console.log(total);
    partRef.update({ 'msgNo': 1, 'msgUpdate': dd }).then(() => console.log('Flagegd'));
    // partRef.ref.get().then(msgRef => {
    //   if (msgRef.exists) {
    //     console.log(msgRef.data(), msgRef.data().msgNo);
    //     if (msgRef.data().cusChatId && (msgRef.data().cusChatId === chatId)) {
    //       if (msgRef.data().msgNo && msgRef.data().msgNo >= 0) {
    //         const total = msgRef.data().msgNo + 1;
    //         console.log(total);
    //         partRef.update({ 'msgNo': total, 'msgUpdate': dd })
    //         .then(() => console.log('Number increment'));
    //       } else {
    //         msgRef.data().msgNo = 1;
    //         partRef.update({ 'msgNo': 1, 'msgUpdate': dd }).then(() => console.log('1st Number'));
    //       }
    //     } else {
    //       if (msgRef.data().msgNo && msgRef.data().msgNo >= 0) {
    //         const total = msgRef.data().msgNo + 1;
    //         console.log(total);
    //         partRef.update({ 'cusChatId': chatId, 'msgNo': total, 'msgUpdate': dd })
    //         .then(() => console.log('Number increment'));
    //       } else {
    //         msgRef.data().msgNo = 1;
    //         partRef.update({  'cusChatId': chatId, 'msgNo': 1, 'msgUpdate': dd })
    //         .then(() => console.log('1st Number'));
    //       }
    //     }
    //   }
    // })
  }

  msgCount(otherPart, chatId) {
    console.log('msgCount', otherPart);
    const dd = moment().toString();
    this.usd.afs.doc(`Users/${otherPart}/contacts/${this.usd.userId}`).ref.get().then(msgRef => {
      if (msgRef.exists) {
        console.log(msgRef.data(), msgRef.data().msgNo);
        if (msgRef.data().chatId && (msgRef.data().chatId === chatId)) {
          if (msgRef.data().msgNo && msgRef.data().msgNo >= 0) {
            const total = msgRef.data().msgNo + 1;
            console.log(total);
            this.usd.afs.doc(`Users/${otherPart}/contacts/${this.usd.userId}`).update({ 'msgNo': total, 'msgUpdate': dd })
            .then(() => console.log('Number increment'));
          } else {
            msgRef.data().msgNo = 1;
            this.usd.afs.doc(`Users/${otherPart}/contacts/${this.usd.userId}`).update({ 'msgNo': 1, 'msgUpdate': dd }).then(() => console.log('1st Number'));
          }
        } else {
          if (msgRef.data().msgNo && msgRef.data().msgNo >= 0) {
            const total = msgRef.data().msgNo + 1;
            console.log(total);
            this.usd.afs.doc(`Users/${otherPart}/contacts/${this.usd.userId}`).update({ 'chatId': chatId, 'msgNo': total, 'msgUpdate': dd })
            .then(() => console.log('Number increment'));
          } else {
            msgRef.data().msgNo = 1;
            this.usd.afs.doc(`Users/${otherPart}/contacts/${this.usd.userId}`).update({  'chatId': chatId, 'msgNo': 1, 'msgUpdate': dd })
            .then(() => console.log('1st Number'));
          }
        }
      }
    })
  }

  clearMsgCount(otherPart) {
    console.log(otherPart);
    this.usd.setContact.msgNo = 0
    this.usd.afs.doc(`Users/${this.usd.userId}/contacts/${otherPart}`).update({ 'msgNo': 0 }).then(() => console.log('Msg count Reset'));
  }

  clearMsgCountAlt(otherPart) {
    const partB = (this.is.toolSet === 'Enterprise' || this.is.toolSet === 'Customers') ? this.usd.setClient: this.usd.setContact;
    // console.log(otherPart);
    // if (partB.msgNo > 0) {
    //   partB.msgNo = 0;
    //   this.ndRef.update({ 'msgNo': 0 }).then(() => console.log('Msg count Reset'));
    // }
    if (this.is.chatToolset === 'client') {
      if (partB.srTag === 'ind') {
        // this.prtRef.update({ 'msgNo': 0 }).then(() => console.log('Msg count Reset'));
        this.usd.afs.doc(`Users/${this.usd.userId}/myCustomers/${otherPart}`).update({ 'msgNo': 0 }).then(() => console.log('Msg count Reset'));
      } else {
        // this.ndRef.update({ 'msgNo': 0 }).then(() => console.log('ndRef Msg count Reset'));
        if (this.usd.ClientChat === 'Myclient') {
          this.usd.afs.doc(`Users/${this.usd.userId}/myCustomers/${partB.id}`).update({ 'msgNo': 0 }).then(() => console.log('ndRef Msg count Reset'));
        } else if (this.usd.ClientChat === 'entClient') {
          this.usd.afs.doc(`Enterprises/${this.usd.setCompany.id}/clients/${partB.id}`).update({ 'msgNo': 0 }).then(() => console.log('ndRef Msg count Reset'));
        } else {
          
        }
      }
    }
  }

  async deleteMessage(chat: Chat, msg: Message) {
    const { id } = await this.usd.acc;
    const ref = this.usd.afs.collection('chats').doc(chat.id);
    if (chat.id === id || msg.id === id) {
      delete msg.user;
      return ref.update({
        messages: this.usd.firebase.firestore.FieldValue.arrayRemove(msg)
      });
    }
  }

  buildChat(chat$: Observable<any>): Observable<any> {
    let chat: any;
    return chat$.pipe(
      switchMap(c => {
        chat = c;
        const ids = Array.from(new Set(c.participants.map((message: any) => message)));
        const users = this.fetchUsers(ids);
        // users
        return users.length ? combineLatest(users) : of([]);
      }), map(usrs => {
        const users = usrs.filter(x => x !== undefined);
        // console.log(users);
        this.usd.chatParts = users
        this.buildUserDictionary(users);
        // Augment message data with newly fetched user data
        chat.messages = chat.messages.sort((a,b) => moment(a?.createdAt.toDate()).diff(moment(b?.createdAt.toDate())));
        chat.messages = chat.messages.map((message: any) => {
          return {  ...message, createdAt: moment(message.createdAt.toDate()), user: this.userDictionary[message.id] };
        });
        return chat;
      })
    );
  }

  private buildUserDictionary(users: unknown[]) {
    // console.log(this.userDictionary);
    // console.log(users);
    users.forEach(user => (this.userDictionary[(user as User).id] = user));
  }

  private fetchUsers(ids: unknown[]): Observable<any>[] {
    const a: Observable<any>[] = ids.map(id => this.usd.afs.doc(`Enterprises/${id}`).valueChanges());
    const b: Observable<any>[] = ids.map(id => this.usd.afs.doc(`Users/${id}`).valueChanges());
    let c = merge(a, b) as Observable<any>[];
    return c;
  }

  getUserById(typerId) {
    return this.userDictionary[typerId];
  }

  openLgg(template: TemplateRef<any>) {
    this.modalRef2 = this.modalService.open(template, { size: 'lg' })
  }
}
