import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { InitialiseService } from './initialise.service';
import { UserDataService } from './user-data.service';

@Injectable({
  providedIn: 'root'
})
export class RouteService {
  domain: string;
  constructor(private router: Router, private usd: UserDataService, public is: InitialiseService) {
    this.domain = 'openbooks';
   }

  gotoDiary() {
    this.router.navigate(['/task24-7/diary']);
  }

  gotoEntList() {
    this.router.navigate(['/task24-7/enterprises']);
  }

  gotoObHome() {
    this.router.navigate(['/openbooks/home']);
  }

  gotoObEntList() {
    this.router.navigate(['/openbooks/enterprises']);
  }

  gotoPrjList() {
    this.router.navigate(['./task24-7/projects']);
  }
  
  gotoClientDash() {
    this.router.navigate(['./views/report-pub-dashboard']);
  }

  viewTask(task) {
    // console.log(task);
    if(task){
      this.router.navigate(['./task24-7/task/' + task.description || task.name || task.id]);
    }
  }

  commentTask(task) {
    // console.log(task);
    if(task){
      this.router.navigate(['./task24-7/task-comments/' + task.description || task.name || task.id]);
    }
  }

  gotoStaff(user) {
    console.log(user);
    if (this.is.module === 'tasking') {
      if (this.is.toolSet === 'Enterprise') {
        // console.log('./task24-7/ent-user/', user.id);
        this.router.navigate(['./task24-7/ent-user/' + user.id]);
      } else if (this.is.toolSet === 'Project') {
        // console.log('./task24-7/proj-user/', user.id);
        this.router.navigate(['./task24-7/proj-user/' + user.name]);
      }
    } else if (this.is.module === 'transactions') {
      if (this.is.toolSet === 'Enterprise') {
        // console.log('./openbooks/ent-user/', user.id);
        this.router.navigate(['./openbooks/ent-user/' + user.id]);
      } else if (this.is.toolSet === 'Project') {
        // console.log('./openbooks/proj-user/', user.id);
        this.router.navigate(['./openbooks/project-party/' + user.name]);
      }
    } else {

    }
  }

  gotoContacts() {
    this.router.navigate(['./task24-7/contacts']);
  }

  gettoStatusReport(path, project) {
    this.gotoProject(project);
    setTimeout(() => {
      this.dynamicRoute(path);
    }, 4000);
  }

  dynamicRoute(path) {
    this.router.navigate([path]);
  }

  gotoBooksEntList() {
    this.router.navigate(['/openbooks/enterprises']);
  }

  gotoBooksPrjList() {
    this.router.navigate(['./openbooks/projects']);
  }

  gotoProject(project) {
    // console.log(project);
    if(project && project.name){
      this.router.navigate(['./task24-7/project/' + project.name]);
    }
  }

  gotoBooksProject(project) {
    // console.log(project);
    if(project && project.name){
      this.router.navigate(['./openbooks/project/' + project.name]);
    }
  }

  projectRouteArch(prj) {
    // console.log(this.is.module);
    if(prj?.guest && prj?.guest === true) {
      this.usd.setProject = prj;
      this.router.navigate(['./openbooks/project-customer-page']);
    } else {
      if (this.is.module === 'transactions'){
        this.gotoBooksProject(prj);
      } 
      if (this.is.module === 'tasking') {
        this.gotoProject(prj);
      }
    }
  }

  projectRoute(prj) {
    // navigator.clipboard.writeText(prj.name).then().catch(e => console.error(e));
    // console.log(this.is.module);
    if(prj?.guest && prj?.guest === true) {
      this.usd.setProject = prj;
      this.usd.setCompany = prj.company ? prj.company : { name: prj.companyName, id: prj.companyId } ;
      this.router.navigate(['./openbooks/project-customer-page']);
    }
  }

  routeRpt(rpt) {
    // console.log(rpt);
    // this.router.navigate(['./task24-7/report/' + rpt]);
    this.router.navigate(['./task24-7/saved-report/' + rpt.name]);
  }

  checkHistory() {
    if(window.history.length > 0) {
      
    }
  }

}
